import React from "react";
import TableComponent from "../../components/TtgProgrammeTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { ttgProgrammeActions } from "../../store/actions/ttgProgramme.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import { getOrganisations } from "../../helpers";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";

const requestTimeout = 20000;

class TtgProgrammeListContainer extends React.Component {
  state = {
    deleteModalOpen: false,
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    ttgProgrammeDeletionId: "",
    searchCriteria: {
      size: 20,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    orgsSearchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    searchBarQuery: ""
  };

  componentDidMount = async () => {
    const sCrit = this.state.orgsSearchCriteria;
    this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    this.timerID = setTimeout(() => {
      if (
        this.props.ttgProgrammeRequestStatus === undefined &&
        this.props.ttgProgrammeRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearTtgProgrammeRequest();
  }

  handleDelete = () => {
    this.props.deleteTtgProgramme(
      this.state.ttgProgrammeDeletionId,
      this.props.headers
    );
  };

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    this.props.clearTtgProgrammeRequest();
    await this.handleFetchData(this.state);
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteria };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteria: searchCriteria });
  };

  handleFetchData = async state => {
    const sCrit = this.state.searchCriteria;
    let sortObject = {};
    if (state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }
    sCrit.fields = [
      "firstName.normalizer",
      "surname.normalizer",
      "pNumber.normalizer",
      "scroNumber.normalizer"
    ];
    sCrit.query = this.state.searchBarQuery;
    await this.props.updateSearchCriteria(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      state.page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sortObject,
      sCrit.activeOnly
    );
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "deleteTtgProgrammeModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  toggleConfirmDeleteModalVisibility = id => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      ttgProgrammeDeletionId: id
    });
  };

  render = () => {
    const loading = this.props.loadingPage || this.props.orgsLoadingPage;

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.requestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          <RequestFeedback
            requestStatus={this.props.ttgProgrammeRequestStatus}
            requestMade={this.props.ttgProgrammeRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.ttgProgrammeRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={
              strings.form.feedback.ttgProgrammeRequestUnknown
            }
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            ttgProgrammeListData={this.props.ttgProgrammeListData}
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            deleteModalOpen={this.state.deleteModalOpen}
            handleModalConfirm={this.handleModalConfirm}
            handleRefresh={this.handleRefresh}
            pageSize={this.state.searchCriteria.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalResults={this.props.totalResults}
            updatePageIndex={this.updatePageIndex}
            roleId={this.props.roleId}
            organisationList={getOrganisations(this.props.organisationListData)}
            searchBarQuery={this.state.searchBarQuery}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            area={this.props.area}
          />
        </div>
      </div>
    );
  };
}

TtgProgrammeListContainer.propTypes = {
  area: PropTypes.string.isRequired,
  clearTtgProgrammeRequest: PropTypes.func,
  deleteTtgProgramme: PropTypes.func,
  error: PropTypes.string.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  getOrganisations: PropTypes.func,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  organisationListData: PropTypes.array.isRequired,
  orgsLoadingPage: PropTypes.bool.isRequired,
  result: PropTypes.string.isRequired,
  roleId: PropTypes.string.isRequired,
  totalResults: PropTypes.number.isRequired,
  ttgProgrammeListData: PropTypes.any,
  ttgProgrammeRequestMade: PropTypes.bool.isRequired,
  ttgProgrammeRequestStatus: PropTypes.bool,
  updateSearchCriteria: PropTypes.func
};

const mapStateToProps = state => {
  const { ttgProgrammes, auth, amendments } = state;
  const { roleId, area } = auth;
  const {
    ttgProgrammeListData,
    loadingPage,
    ttgProgrammeToDelete,
    ttgProgrammeRequestStatus,
    ttgProgrammeRequestMade,
    result,
    error,
    totalResults,
    errorDetails
  } = ttgProgrammes;
  const {
    organisationListData,
    loadingPage: orgsLoadingPage
  } = state.organisations;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails
  } = amendments;
  return {
    ttgProgrammeListData,
    loadingPage,
    ttgProgrammeToDelete,
    ttgProgrammeRequestStatus,
    ttgProgrammeRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails,
    organisationListData,
    orgsLoadingPage,
    area,
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTtgProgrammes: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        ttgProgrammeActions.getTtgProgrammes(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        ttgProgrammeActions.updateSearchCriteria(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    deleteTtgProgramme: (id, headers) => {
      dispatch(ttgProgrammeActions.deleteTtgProgramme(id, headers));
    },
    clearTtgProgrammeRequest: () => {
      dispatch(ttgProgrammeActions.clearTtgProgrammeRequest());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TtgProgrammeListContainer);
