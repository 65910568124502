import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    fieldValues: { other: "Other", mentor: "mentor" },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    header: {
      login: "Login",
      caseloadList: "Caseload",
      createCaseload: "New Caseload",
      editCaseload: "Edit Caseload",
      viewCaseload: "Caseload Details",
      loading: "Loading Information"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        administration: "Administration",
        caseloadDetails: "Caseload Details"
      },
      label: {
        crmId: "CRM ID",
        owner: "Owner",
        date: "Date",
        type: "Type",
        amount: "Amount",
        issuedByOrg: "Issued By Organisation",
        issuedBy: "Issued By",
        otherType: "Other Type"
      },
      placeholder: {
        stateReason: "State your reason...",
        crmId: "",
        owner: "- select -",
        date: "- select -",
        type: "- select -",
        amount: "",
        issuedByOrg: "- select -",
        issuedBy: "- select -",
        otherType: ""
      },
      feedback: {
        processing: "Processing request...",
        caseloadRequestSuccess: "Caseload created successfully!",
        caseloadRequestRefreshPrompt:
          "Use the refresh function to see an updated caseload list.",
        caseloadRequestFailure: "Caseload creation failed.",
        caseloadRequestUnknown:
          "Something went wrong with the caseload request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        yes: "Yes",
        no: "No"
      }
    },
    modal: {
      createCaseloadMessage: "Save caseload?",
      modifyCaseloadMessage: "Are you sure you want to save these changes?",
      deleteCaseloadMessage:
        "Are you sure you want to delete this caseload? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this caseload's status to active?",
      setInactiveMessage: "Set this caseload's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addCaseloadLabel: "New Caseload",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    tooltip: {},
    table: {
      header: {
        specialist: "Specialist Name",
        surname: "Customer Surname",
        firstName: "Customer Forename",
        pNumber: "Prison Number",
        prison: "Prison",
        journeyStatus: "Journey Status",
        accommodationNeed: "Accommodation need identified?",
        dateOfEngagement: "Last Attempted Contact",
        dateOfEngagementSuccessful: "Last Successful Contact",
        daysSinceLastContact: "Days Since Last Attempted Contact"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    }
  }
});
