import { API } from "../../services";
import { auditActionTypes } from "./audit.actionTypes";

const api = new API();

const removeEmptyFields = details => {
  for (let property in details) {
    if (!details[property]) {
      delete details[property];
    } else if (typeof details[property] === "object") {
      removeEmptyFields(details[property]);
    }
  }
};

const createAudit = (details, headers) => {
  removeEmptyFields(details);
  const request = () => {
    return { type: auditActionTypes.CREATE_AUDIT_REQUEST };
  };
  const success = response => {
    return { type: auditActionTypes.CREATE_AUDIT_SUCCESS, response };
  };
  const failure = error => {
    return { type: auditActionTypes.CREATE_AUDIT_FAILURE, error };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "createAudit" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.createAudit(details).then(
        response => {
          dispatch(success(response));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const getAudits = (
  headers,
  queryString,
  size,
  from,
  clear,
  sort = {},
  fields
) => {
  const request = () => {
    return { type: auditActionTypes.GET_AUDITS_REQUEST };
  };
  const success = response => {
    return {
      type: auditActionTypes.GET_AUDITS_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: auditActionTypes.GET_AUDITS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getAudits" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (clear) {
        // If supposed to clear, replicate blank result
        dispatch(success({ hits: { hits: [] } }));
        resolve();
      } else {
        api.getAudits(queryString, size, from, sort, fields).then(
          response => {
            dispatch(success(response));
            resolve(response);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort
) => {
  const request = () => {
    return { type: auditActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: auditActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };

  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    sort: sort,
    fields: fields
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(getAudits(headers, queryString, size, from, clear, sort, fields));
  };
};

const clearAuditRequest = () => {
  const request = () => {
    return { type: auditActionTypes.CLEAR_CREATE_AUDIT_REQUEST };
  };

  const success = () => {
    return { type: auditActionTypes.CLEAR_CREATE_AUDIT_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

export const auditActions = {
  createAudit,
  getAudits,
  updateSearchCriteria,
  clearAuditRequest
};
