import React from "react";
import TableComponent from "../../components/TaskTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { taskActions } from "../../store/actions/task.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import moment from "moment";
import { TasksWidget } from "../../components";
import { taskTitleMappings } from "../../resources/TaskTitleMappings";

const requestTimeout = 20000;

class TaskListContainer extends React.Component {
  state = {
    deleteModalOpen: false,
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    taskDeletionId: "",
    searchCriteria: {
      size: 100,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    showCleared: false,
    startDate: "",
    endDate: "",
    searchBarQuery: "",
    activeIndex: 0
  };

  componentDidMount = async () => {
    this.timerID = setTimeout(() => {
      if (
        this.props.taskRequestStatus === undefined &&
        this.props.taskRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearTaskRequest();
  }

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    await this.props.clearTaskRequest();
    await this.handleFetchData(this.state);
  };

  handleDateChange = (e, data) => {
    this.setState({ [data.id]: data.value }, () => this.handleDateRefresh());
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteria };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteria: searchCriteria });
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleDateRefresh = () => {
    if (this.state.startDate && this.state.endDate) {
      this.handleRefresh();
    }
  };

  handleFetchData = async state => {
    const sCrit = this.state.searchCriteria;
    let sortObject = {};
    if (state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }
    sCrit.fields = [
      "crmId.normalizer",
      "dueDate.normalizer",
      "customerForename.normalizer",
      "customerSurname.normalizer"
    ];
    sCrit.query = this.state.searchBarQuery;

    let today = moment().local().format("DD/MM/YYYY");
    let oneMonthAfter = moment().add(1, "months").local().format("DD/MM/YYYY");
    let startDate = this.state.startDate ? this.state.startDate : today;
    let endDate = this.state.endDate ? this.state.endDate : oneMonthAfter;

    await this.props.updateSearchCriteria(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      state.page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sortObject,
      state.showCleared,
      startDate,
      endDate
    );
  };

  handleWidgetClicked = (e, type, filter) => {
    this.props.setTaskDataSelected({ type: type, filter: filter });
  };

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = this.state.activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  filterTableData = () => {
    if (this.props.taskDataSelected.filter === "all") {
      return this.props.taskListDataByType[this.props.taskDataSelected.type];
    } else if (this.props.taskDataSelected.filter === "stillToComplete") {
      return this.props.taskListDataByDate[
        this.props.taskDataSelected.type
      ].due.concat(
        this.props.taskListDataByDate[this.props.taskDataSelected.type].dueSoon
      );
    } else if (this.props.taskDataSelected.filter === "due") {
      return this.props.taskListDataByDate[this.props.taskDataSelected.type]
        .dueVerySoon;
    } else return this.props.taskListData;
  };

  toggleCleared = () => {
    this.setState(
      prevState => ({
        showCleared: !prevState.showCleared
      }),
      () => {
        this.handleRefresh();
      }
    );
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "deleteTaskModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  toggleConfirmDeleteModalVisibility = id => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      taskDeletionId: id
    });
  };

  render = () => {
    const loading = this.props.loadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <RequestFeedback
            requestStatus={this.props.taskRequestStatus}
            requestMade={this.props.taskRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.taskRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={strings.form.feedback.taskRequestUnknown}
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <h2>{strings.tasks.header}</h2>
          <TasksWidget
            taskListDataByDate={this.props.taskListDataByDate}
            handleWidgetClicked={this.handleWidgetClicked}
            handleAccordionClick={this.handleAccordionClick}
            activeIndex={this.state.activeIndex}
          ></TasksWidget>
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            taskListData={this.filterTableData()}
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            deleteModalOpen={this.state.deleteModalOpen}
            handleModalConfirm={this.handleModalConfirm}
            handleRefresh={this.handleRefresh}
            pageSize={this.state.searchCriteria.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalResults={this.props.totalResults}
            updatePageIndex={this.updatePageIndex}
            roleId={this.props.roleId}
            toggleCleared={this.toggleCleared}
            showCleared={this.state.showCleared}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            taskTableTitle={taskTitleMappings[this.props.taskDataSelected.type]}
          />
        </div>
      </div>
    );
  };
}

TaskListContainer.propTypes = {
  taskListData: PropTypes.array.isRequired,
  taskToDelete: PropTypes.string.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getTasks: PropTypes.func.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { tasks, auth } = state;
  const { roleId } = auth;
  const {
    taskListData,
    loadingPage,
    taskToDelete,
    taskRequestStatus,
    taskRequestMade,
    result,
    error,
    totalResults,
    errorDetails,
    taskListDataByType,
    taskListDataByDate,
    taskDataSelected
  } = tasks;
  return {
    taskListData,
    loadingPage,
    taskToDelete,
    taskRequestStatus,
    taskRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails,
    taskListDataByType,
    taskListDataByDate,
    taskDataSelected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTasks: (
      headers,
      queryString,
      size,
      from,
      clear,
      sort,
      activeOnly,
      fields
    ) => {
      dispatch(
        taskActions.getTasks(
          headers,
          queryString,
          size,
          from,
          clear,
          sort,
          activeOnly,
          fields
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      startDate,
      endDate
    ) => {
      dispatch(
        taskActions.updateSearchCriteria(
          headers,
          data,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          startDate,
          endDate
        )
      );
    },
    clearTaskRequest: () => {
      dispatch(taskActions.clearTaskRequest());
    },
    setTaskDataSelected: data => {
      dispatch(taskActions.setTaskDataSelected(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskListContainer);
