import { newsActionTypes } from "../actions/news.actionTypes";

const initialState = {
  loadingPage: false,
  selectedNewsData: {},
  newsListData: [{}],
  totalResults: 0,
  newsToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  newsRequestStatus: undefined,
  newsRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let details;
  let newTotal;
  switch (action.type) {
    case newsActionTypes.CREATE_NEWS_REQUEST:
      return {
        ...state,
        newsRequestStatus: undefined,
        newsRequestMade: true
      };
    case newsActionTypes.CREATE_NEWS_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        newsRequestStatus: true
      };
    case newsActionTypes.CREATE_NEWS_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        newsRequestStatus: false
      };

    case newsActionTypes.CLEAR_CREATE_NEWS_REQUEST:
      return { ...state };
    case newsActionTypes.CLEAR_CREATE_NEWS_SUCCESS:
      return {
        ...state,
        newsRequestMade: false,
        newsRequestStatus: undefined
      };
    case newsActionTypes.CLEAR_CREATE_NEWS_FAILURE:
      return { ...state };

    case newsActionTypes.UPDATE_NEWS_REQUEST:
      return {
        ...state,
        loadingPage: true,
        newsRequestStatus: undefined,
        newsRequestMade: true
      };
    case newsActionTypes.UPDATE_NEWS_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        newsRequestStatus: true,
        selectedNewsData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case newsActionTypes.UPDATE_NEWS_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        newsRequestStatus: false,
        selectedNewsData: action.initialDetails,
        errorDetails: details
      };

    case newsActionTypes.DELETE_NEWS_REQUEST:
      return {
        ...state,
        newsRequestStatus: undefined,
        newsRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case newsActionTypes.DELETE_NEWS_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        newsRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case newsActionTypes.DELETE_NEWS_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        newsRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case newsActionTypes.GET_NEWS_REQUEST:
      return { ...state, loadingPage: true };
    case newsActionTypes.GET_NEWS_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
      } else {
        rawHits = [];
      }
      return {
        ...state,
        newsListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case newsActionTypes.GET_NEWS_FAILURE:
      return { ...state, newsListData: [{}], loadingPage: false };
    case newsActionTypes.GET_NEWS_BY_PROGRAMME_REQUEST:
      return { ...state, loadingPage: true };
    case newsActionTypes.GET_NEWS_BY_PROGRAMME_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Body.news) {
        newTotal = action.response.data.Body.total;
        rawHits = action.response.data.Body.news.slice();
        rawHits.forEach((result, index) => {
          let newNews = Object.assign({}, result);
          rawHits[index] = {};
          rawHits[index]["_source"] = newNews;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        NewsListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case newsActionTypes.GET_NEWS_BY_PROGRAMME_FAILURE:
      return { ...state, newsListData: [{}], loadingPage: false };

    case newsActionTypes.GET_NEWS_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        newsRequestStatus: undefined
      };
    case newsActionTypes.GET_NEWS_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        newsRequestStatus: true,
        selectedNewsData: action.response.Body,
        loadingPage: false
      };
    case newsActionTypes.GET_NEWS_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedNewsData: {},
        newsRequestStatus: false,
        newsRequestMade: true
      };

    case newsActionTypes.UPDATE_SELECTED_NEWS_REQUEST:
      return { ...state };
    case newsActionTypes.UPDATE_SELECTED_NEWS_SUCCESS:
      return {
        ...state,
        selectedNewsData: {
          ...state.selectedNewsData,
          [action.key]: action.value
        }
      };
    case newsActionTypes.UPDATE_SELECTED_NEWS_FAILURE:
      return { ...state };

    case newsActionTypes.CLEAR_SELECTED_NEWS_REQUEST:
      return { ...state };
    case newsActionTypes.CLEAR_SELECTED_NEWS_SUCCESS:
      return { ...state, selectedNewsData: {} };
    case newsActionTypes.CLEAR_SELECTED_NEWS_FAILURE:
      return { ...state };

    case newsActionTypes.CANCEL_SELECTED_NEWS_UPDATE_REQUEST:
      return { ...state };
    case newsActionTypes.CANCEL_SELECTED_NEWS_UPDATE_SUCCESS:
      return { ...state, selectedNewsData: action.data };
    case newsActionTypes.CANCEL_SELECTED_NEWS_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};
