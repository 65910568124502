import { ttgEngagementActionTypes } from "../actions/ttgEngagement.actionTypes";

const initialState = {
  loadingPage: false,
  selectedTtgEngagementData: {},
  ttgEngagementListData: [{}],
  totalResults: 0,
  ttgEngagementToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  ttgEngagementRequestStatus: undefined,
  ttgEngagementRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let details;
  let newTotal;
  switch (action.type) {
    case ttgEngagementActionTypes.CREATE_TTG_ENGAGEMENT_RECORD_REQUEST:
      return {
        ...state,
        ttgEngagementRequestStatus: undefined,
        ttgEngagementRequestMade: true
      };
    case ttgEngagementActionTypes.CREATE_TTG_ENGAGEMENT_RECORD_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        ttgEngagementRequestStatus: true
      };
    case ttgEngagementActionTypes.CREATE_TTG_ENGAGEMENT_RECORD_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        ttgEngagementRequestStatus: false
      };

    case ttgEngagementActionTypes.CLEAR_CREATE_TTG_ENGAGEMENT_RECORD_REQUEST:
      return { ...state };
    case ttgEngagementActionTypes.CLEAR_CREATE_TTG_ENGAGEMENT_RECORD_SUCCESS:
      return {
        ...state,
        ttgEngagementRequestMade: false,
        ttgEngagementRequestStatus: undefined
      };
    case ttgEngagementActionTypes.CLEAR_CREATE_TTG_ENGAGEMENT_RECORD_FAILURE:
      return { ...state };

    case ttgEngagementActionTypes.UPDATE_TTG_ENGAGEMENT_RECORD_REQUEST:
      return {
        ...state,
        loadingPage: true,
        ttgEngagementRequestStatus: undefined,
        ttgEngagementRequestMade: true
      };
    case ttgEngagementActionTypes.UPDATE_TTG_ENGAGEMENT_RECORD_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        ttgEngagementRequestStatus: true,
        selectedTtgEngagementData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case ttgEngagementActionTypes.UPDATE_TTG_ENGAGEMENT_RECORD_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        ttgEngagementRequestStatus: false,
        selectedTtgEngagementData: action.initialDetails,
        errorDetails: details
      };

    case ttgEngagementActionTypes.DELETE_TTG_ENGAGEMENT_RECORD_REQUEST:
      return {
        ...state,
        ttgEngagementRequestStatus: undefined,
        ttgEngagementRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case ttgEngagementActionTypes.DELETE_TTG_ENGAGEMENT_RECORD_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        ttgEngagementRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case ttgEngagementActionTypes.DELETE_TTG_ENGAGEMENT_RECORD_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        ttgEngagementRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case ttgEngagementActionTypes.GET_TTG_ENGAGEMENT_RECORDS_REQUEST:
      return { ...state, loadingPage: true, deleteSuccessful: false };
    case ttgEngagementActionTypes.GET_TTG_ENGAGEMENT_RECORDS_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newTtgEngagement = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newTtgEngagement;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        ttgEngagementListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case ttgEngagementActionTypes.GET_TTG_ENGAGEMENT_RECORDS_FAILURE:
      return { ...state, ttgEngagementListData: [{}], loadingPage: false };

    case ttgEngagementActionTypes.GET_TTG_ENGAGEMENT_RECORD_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        ttgEngagementRequestStatus: undefined
      };
    case ttgEngagementActionTypes.GET_TTG_ENGAGEMENT_RECORD_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        ttgEngagementRequestStatus: true,
        selectedTtgEngagementData: action.response.Body,
        loadingPage: false
      };
    case ttgEngagementActionTypes.GET_TTG_ENGAGEMENT_RECORD_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedTtgEngagementData: {},
        ttgEngagementRequestStatus: false,
        ttgEngagementRequestMade: true
      };

    case ttgEngagementActionTypes.UPDATE_SELECTED_TTG_ENGAGEMENT_RECORD_REQUEST:
      return { ...state };
    case ttgEngagementActionTypes.UPDATE_SELECTED_TTG_ENGAGEMENT_RECORD_SUCCESS:
      return {
        ...state,
        selectedTtgEngagementData: {
          ...state.selectedTtgEngagementData,
          [action.key]: action.value
        }
      };
    case ttgEngagementActionTypes.UPDATE_SELECTED_TTG_ENGAGEMENT_RECORD_FAILURE:
      return { ...state };

    case ttgEngagementActionTypes.CLEAR_SELECTED_TTG_ENGAGEMENT_RECORD_REQUEST:
      return { ...state };
    case ttgEngagementActionTypes.CLEAR_SELECTED_TTG_ENGAGEMENT_RECORD_SUCCESS:
      return { ...state, selectedTtgEngagementData: {} };
    case ttgEngagementActionTypes.CLEAR_SELECTED_TTG_ENGAGEMENT_RECORD_FAILURE:
      return { ...state };

    case ttgEngagementActionTypes.CANCEL_SELECTED_TTG_ENGAGEMENT_RECORD_UPDATE_REQUEST:
      return { ...state };
    case ttgEngagementActionTypes.CANCEL_SELECTED_TTG_ENGAGEMENT_RECORD_UPDATE_SUCCESS:
      return { ...state, selectedTtgEngagementData: action.data };
    case ttgEngagementActionTypes.CANCEL_SELECTED_TTG_ENGAGEMENT_RECORD_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};
