import { amendmentActionTypes } from "../actions/amendment.actionTypes";

const initialState = {
  loadingPage: false,
  result: "",
  error: "",
  errorDetails: {},
  permissionStatus: "",
  requestId: "",
  deleteReason: "",
  amendmentReason: "",
  amendmentRequestStatus: undefined,
  amendmentRequestMade: false,
  showNotification: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case amendmentActionTypes.REQUEST_DELETE_PERMISSION_REQUEST:
      return {
        ...state,
        amendmentRequestStatus: undefined,
        amendmentRequestMade: true
      };
    case amendmentActionTypes.REQUEST_DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        requestId: action.response.data.Data.requestId,
        amendmentRequestStatus: true,
        result: action.response.data.Reason,
        showNotification: action.showNotification
      };
    case amendmentActionTypes.REQUEST_DELETE_PERMISSION_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        amendmentRequestStatus: false,
        showNotification: action.showNotification
      };

    case amendmentActionTypes.GET_DELETE_PERMISSION_STATUS_REQUEST:
      return {
        ...state,
        amendmentRequestStatus: undefined
      };
    case amendmentActionTypes.GET_DELETE_PERMISSION_STATUS_SUCCESS:
      return {
        ...state,
        amendmentRequestStatus: true,
        permissionStatus: action.response.Data.status,
        deleteReason: action.response.Data.requestDetails.reason
      };
    case amendmentActionTypes.GET_DELETE_PERMISSION_STATUS_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        amendmentRequestStatus: false
      };
    case amendmentActionTypes.SET_AMENDMENT_STATUS_ACTIONED_REQUEST:
      return {
        ...state,
        amendmentRequestMade: true,
        loadingPage: true
      };
    case amendmentActionTypes.SET_AMENDMENT_STATUS_ACTIONED_SUCCESS:
      return {
        ...state,
        requestId: "",
        permissionStatus: "Approved",
        result: action.response.Reason,
        amendmentRequestStatus: true,
        showNotification: action.showNotification,
        loadingPage: false
      };
    case amendmentActionTypes.SET_AMENDMENT_STATUS_ACTIONED_FAILURE:
      return {
        ...state,
        error: action.response.Reason,
        showNotification: action.showNotification,
        amendmentRequestStatus: false,
        loadingPage: false
      };

    case amendmentActionTypes.UPDATE_AMENDMENT_PERMISSION_STATUS_REQUEST:
      return {
        ...state,
        amendmentRequestMade: true
      };
    case amendmentActionTypes.UPDATE_AMENDMENT_PERMISSION_STATUS_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        amendmentRequestStatus: true,
        showNotification: action.showNotification
      };
    case amendmentActionTypes.UPDATE_AMENDMENT_PERMISSION_STATUS_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Status,
        errorDetails: action.error.response.data.Reason,
        showNotification: action.showNotification,
        amendmentRequestStatus: false
      };
    case amendmentActionTypes.CREATE_AMENDMENT_REQUEST_REQUEST:
      return {
        ...state,
        amendmentRequestStatus: undefined,
        amendmentRequestMade: true,
        showNotification: action.showNotification
      };
    case amendmentActionTypes.CREATE_AMENDMENT_REQUEST_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        amendmentRequestStatus: true,
        showNotification: action.showNotification
      };
    case amendmentActionTypes.CREATE_AMENDMENT_REQUEST_FAILURE:
      let details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        amendmentRequestStatus: false,
        showNotification: action.showNotification
      };

    case amendmentActionTypes.CLEAR_AMENDMENT_REQUEST:
      return {
        ...state
      };
    case amendmentActionTypes.CLEAR_AMENDMENT_SUCCESS:
      return {
        ...state,
        amendmentRequestMade: false,
        amendmentRequestStatus: undefined,
        result: "",
        showNotification: false
      };
    case amendmentActionTypes.CLEAR_AMENDMENT_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
};
