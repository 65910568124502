import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";

import { ValidatedFormDropdown, ValidatedFormInput } from "../validation";
import "../css/ProgrammeComponents.scss";
import { yesNoOptions } from "../../resources";

const MentalHealthFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">{strings.form.header.mentalHealthHeader}</div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hasMentalHealthIssues}
          viewData={props.selectedProgrammeData.hasMentalHealthIssues}
          dropdownLabel={strings.form.label.hasMentalHealthIssues}
          dropdownName="hasMentalHealthIssues"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.hasMentalHealthIssues}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.hasMentalHealthIssues}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.mentalHealthMedication}
          inputLabel={strings.form.label.mentalHealthMedication}
          inputName="mentalHealthMedication"
          inputPlaceholder={strings.form.placeholder.mentalHealthMedication}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.mentalHealthMedication}
          required={false}
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.takingMentalHealthMedication}
          viewData={props.selectedProgrammeData.takingMentalHealthMedication}
          dropdownLabel={strings.form.label.takingMentalHealthMedication}
          dropdownName="takingMentalHealthMedication"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={
            strings.form.placeholder.takingMentalHealthMedication
          }
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={
            props.validationResults.takingMentalHealthMedication
          }
          required={false}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />

        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.mentalHealthMedicationQuantity}
          inputLabel={strings.form.label.mentalHealthMedicationQuantity}
          inputName="mentalHealthMedicationQuantity"
          inputPlaceholder={
            strings.form.placeholder.mentalHealthMedicationQuantity
          }
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={
            props.validationResults.mentalHealthMedicationQuantity
          }
          required={false}
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
      </Form.Group>
    </div>
  </div>
);

MentalHealthFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default MentalHealthFields;
