import React from "react";
import ReactTable from "react-table";
import { TableFunctions } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";
import { permissions } from "../../App/resources";
import { convertFromIso } from "../helpers/index";

class TableComponent extends React.Component {
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      if (column.id !== "delete" && column.id !== "active") {
        return {
          onClick: () => {
            if (rowInfo) {
              this.props.history.push(
                "/dummyRecords/view/" + rowInfo.original.dummyId
              );
            }
          }
        };
      }
    }
    return {}; // getTdProps requires a return statement
  };

  mapSearchResultsToRows = () => {
    const { dummyRecordListData } = this.props;
    let data = [];
    if (dummyRecordListData) {
      dummyRecordListData.forEach((result, index) => {
        if (result["_source"]) {
          data.push(result["_source"]);
        }
      });
    }
    return data;
  };

  render = () => {
    let data = this.mapSearchResultsToRows();
    let pages = Math.ceil(this.props.totalResults / this.props.pageSize);
    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };

    const columns = [
      {
        Header: strings.table.header.crmId,
        accessor: "crmId",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "cjSpecialist",
        Header: strings.table.header.cjSpecialist,
        accessor: dummy => {
          if (
            Object.entries(dummy).length > 0 &&
            this.props.userList.length > 0
          ) {
            let specialist = this.props.userList.find(
              user => dummy.cjSpecialist === user.value
            )
              ? this.props.userList.find(
                  user => dummy.cjSpecialist === user.value
                ).text
              : "";
            return specialist;
          } else {
            return "";
          }
        },
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "created",
        Header: strings.table.header.created,
        accessor: dummy => (dummy.created ? convertFromIso(dummy.created) : ""),
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },

      {
        Header: strings.table.header.journeyStatus,
        accessor: "journeyStatus",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      }
    ];
    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          handleRefresh={this.props.handleRefresh}
          headerList={strings.header.dummyRecords}
          buttonAddLabel={strings.button.addDummyRecordLabel}
          permissionsCreate={permissions.dummyRecords.create}
          headerClass="pageHeader"
          refreshButtonClass="refreshButton"
          addButtonClass="addButton"
          addButtonLink={"/ttgRecord"}
          handleChange={this.props.handleChange}
          searchBarQuery={this.props.searchBarQuery}
          handleSubmit={this.props.handleSubmit}
          from={"dummyRecords"}
        />
        <div>
          <ReactTable
            manual
            data={data}
            pages={pages}
            defaultPageSize={this.props.pageSize}
            showPagination={true}
            getTdProps={this.getTdProps}
            onFetchData={(state, instance) => {
              this.props.handleFetchData(state);
              this.props.updatePageIndex(state);
            }}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText={strings.table.text.noResults}
            previousText="<"
            nextText=">"
            pageText=""
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  dummyRecordListData: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  handleFetchData: PropTypes.func,
  handleRefresh: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  searchBarQuery: PropTypes.string.isRequired,
  totalResults: PropTypes.number.isRequired,
  updatePageIndex: PropTypes.func.isRequired
};

export default TableComponent;
