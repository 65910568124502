import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import { ValidatedFormDropdown } from "../validation";
import "../css/ProgrammeComponents.scss";
import { yesNoOptions } from "../../resources";

const GeneralhealthFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">{strings.form.header.generalHealthHeader}</div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hasHealthIssues}
          viewData={props.selectedProgrammeData.hasHealthIssues}
          dropdownLabel={strings.form.label.hasHealthIssues}
          dropdownName="hasHealthIssues"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.hasHealthIssues}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.hasHealthIssues}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hasGPRegistered}
          viewData={props.selectedProgrammeData.hasGPRegistered}
          dropdownLabel={strings.form.label.hasGPRegistered}
          dropdownName="hasGPRegistered"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.hasGPRegistered}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.hasGPRegistered}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hasDentistRegistered}
          viewData={props.selectedProgrammeData.hasDentistRegistered}
          dropdownLabel={strings.form.label.hasDentistRegistered}
          dropdownName="hasDentistRegistered"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.hasDentistRegistered}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.hasDentistRegistered}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
      </Form.Group>
    </div>
  </div>
);

GeneralhealthFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default GeneralhealthFields;
