import moment from "moment";
import { caseloadActionTypes } from "../actions/caseload.actionTypes";

const initialState = {
  loadingPage: false,
  caseloadListData: [{}],
  totalResults: 0,
  result: "",
  error: "",
  errorDetails: {},
  caseloadRequestStatus: undefined,
  caseloadRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let newTotal;
  let currentDate = moment();
  let engagementDate;
  let dateDifference;
  switch (action.type) {
    case caseloadActionTypes.GET_CASELOADS_REQUEST:
      return { ...state, loadingPage: true };
    case caseloadActionTypes.GET_CASELOADS_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
        rawHits.forEach((hit, count) => {
          if (hit._source.dateOfEngagement) {
            engagementDate = moment(hit._source.dateOfEngagement);
            dateDifference = currentDate.diff(engagementDate, "days");
            rawHits[count]._source.daysSinceLastContact = dateDifference;
            if (dateDifference <= 5) {
              rawHits[count]._source.color = "green";
            } else if (dateDifference >= 10) {
              rawHits[count]._source.color = "red";
            } else {
              rawHits[count]._source.color = "orange";
            }
          }
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        caseloadListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case caseloadActionTypes.GET_CASELOADS_FAILURE:
      return { ...state, caseloadListData: [{}], loadingPage: false };

    case caseloadActionTypes.CLEAR_CREATE_CASELOAD_REQUEST:
      return { ...state };
    case caseloadActionTypes.CLEAR_CREATE_CASELOAD_SUCCESS:
      return {
        ...state,
        caseloadRequestMade: false,
        caseloadRequestStatus: undefined
      };
    case caseloadActionTypes.CLEAR_CREATE_CASELOAD_FAILURE:
      return { ...state };

    default:
      return state;
  }
};
