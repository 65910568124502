import React from "react";
import TableComponent from "../../components/TtgEngagementTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { ttgEngagementActions } from "../../store/actions/ttgEngagement.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";

const requestTimeout = 20000;

class TtgEngagementListContainer extends React.Component {
  state = {
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    searchCriteria: {
      size: 20,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false,
      type: undefined
    },
    searchBarQuery: ""
  };

  componentDidMount = async () => {
    this.timerID = setTimeout(() => {
      if (
        this.props.ttgEngagementRequestStatus === undefined &&
        this.props.ttgEngagementRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearTtgEngagementRequest();
  }

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    await this.props.clearTtgEngagementRequest();
    await this.handleFetchData(this.state);
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteria };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteria: searchCriteria });
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleFetchData = async state => {
    const sCrit = this.state.searchCriteria;
    let sortObject = {};
    if (state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }
    if (this.props.needId) {
      sCrit.fields = ["needId"];
      sCrit.query = this.props.needId;
    } else {
      sCrit.fields = [
        "firstName.normalizer",
        "surname.normalizer",
        "crmId.normalizer"
      ];
      sCrit.query = this.state.searchBarQuery;
      sCrit.programmeId = localStorage.getItem("ttgProgrammeId");
    }
    await this.props.updateSearchCriteria(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      state.page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sortObject,
      sCrit.activeOnly,
      sCrit.programmeId
    );
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };

  render = () => {
    const loading = this.props.loadingPage;

    let parentPage;
    if (localStorage.getItem("ttgProgrammeName")) {
      parentPage = localStorage.getItem("ttgProgrammeName");
    }

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.requestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          <RequestFeedback
            requestStatus={this.props.ttgEngagementRequestStatus}
            requestMade={this.props.ttgEngagementRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.ttgEngagementRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={
              strings.form.feedback.ttgEngagementRequestUnknown
            }
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            ttgEngagementListData={this.props.ttgEngagementListData}
            handleRefresh={this.handleRefresh}
            pageSize={this.state.searchCriteria.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalResults={this.props.totalResults}
            updatePageIndex={this.updatePageIndex}
            roleId={this.props.roleId}
            area={this.props.area}
            searchBarQuery={this.state.searchBarQuery}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            hideSearchBar={this.props.hideSearchBar}
            parentPage={parentPage}
            returnToParent={() => {
              this.props.history.push(
                "/ttgProgrammes/view/" + localStorage.getItem("ttgProgrammeId")
              );
            }}
          />
        </div>
      </div>
    );
  };
}

TtgEngagementListContainer.propTypes = {
  ttgEngagementListData: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getTtgEngagements: PropTypes.func.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { ttgEngagements, auth, amendments } = state;
  const { roleId } = auth;
  const {
    ttgEngagementListData,
    loadingPage,
    ttgEngagementRequestStatus,
    ttgEngagementRequestMade,
    result,
    error,
    totalResults,
    errorDetails
  } = ttgEngagements;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails
  } = amendments;
  return {
    ttgEngagementListData,
    loadingPage,
    ttgEngagementRequestStatus,
    ttgEngagementRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails,
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTtgEngagements: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      ttgProgrammeId
    ) => {
      dispatch(
        ttgEngagementActions.getTtgEngagements(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          ttgProgrammeId
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      ttgProgrammeId
    ) => {
      dispatch(
        ttgEngagementActions.updateSearchCriteria(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          ttgProgrammeId
        )
      );
    },
    clearTtgEngagementRequest: () => {
      dispatch(ttgEngagementActions.clearTtgEngagementRequest());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TtgEngagementListContainer);
