import React from "react";
import PropTypes from "prop-types";
import { Button, Form } from "semantic-ui-react";
import { AuditListContainer } from "../../Audit/containers/";
import {
  strings,
  yesNoOptions,
  methodOptions,
  stageOfReferralOptions,
  categoryOptions,
  outcomeOfSpecialistReferralOptions,
  reasonOptions,
  providerOptions
} from "../resources";
import {
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormTextArea,
  ValidatedFormInput,
  ValidatedMultipleEngagements
} from "./validation";
import { ComponentHeader, BasicModal } from ".";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { dropdownHelper, convertFromIso } from "../helpers";
import FileManager from "../../File/components/FileManager";
import FormBoxWithHeader from "../../App/components/FormBoxWithHeader";

class ModifySupportReferral extends React.Component {
  returnToParent = () => {
    if (localStorage.getItem("programmeId")) {
      this.props.history.push(
        "/programmes/view/" + localStorage.getItem("programmeId")
      );
    } else if (localStorage.getItem("referralId")) {
      this.props.history.push(
        "/referrals/view/" + localStorage.getItem("referralId")
      );
    }
  };

  renderEditButton = () => {
    const {
      selectedSupportReferralData,
      handleModeSwitch,
      isDisabled
    } = this.props;

    // const modalTextInput = (
    //   <Input
    //     placeholder={strings.form.placeholder.stateReason}
    //     fluid
    //     onChange={this.props.handleModalInputChange}
    //   ></Input>
    // );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful &&
          (!isDisabled ? (
            <>
              <Button.Group>
                <Button
                  className="editButton"
                  primary
                  onClick={() => {
                    handleModeSwitch("edit");
                  }}
                  disabled={
                    !selectedSupportReferralData.active ||
                    this.props.showAuditLog
                  }
                >
                  {strings.button.editLabel}
                </Button>
              </Button.Group>
            </>
          ) : (
            <span className="errorText">
              {strings.form.errorText.notShared}
            </span>
          ))}
        {/* {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={
                  this.props.toggleDeletionRequestSupportReferralModalVisibility
                }
                type="button"
                disabled={
                  this.props.selectedSupportReferralData.requestId ||
                  this.props.showAuditLog
                }
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.deletionRequestSupportReferralModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={
                  this.props.toggleDeletionRequestSupportReferralModalVisibility
                }
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )} */}
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={
                  this.props.toggleDeletionRequestSupportReferralModalVisibility
                }
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteSupportReferralMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.deletionRequestSupportReferralModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={
                  this.props.toggleDeletionRequestSupportReferralModalVisibility
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };
  renderCancelSaveButton = () => {
    const {
      selectedSupportReferralData,
      handleModeSwitch,
      handleModalConfirm,
      toggleCancelChangesModalVisibility,
      toggleConfirmSaveModalVisibility,
      initialSupportReferralData,
      validateForm,
      confirmSaveModalOpen,
      mode,
      cancelChangesModalOpen,
      isDisabled
    } = this.props;

    const cancelSaveButtons = (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={validateForm}
            disabled={
              (JSON.stringify(selectedSupportReferralData) ===
                JSON.stringify(initialSupportReferralData) &&
                !this.props.hasFileChanges) ||
              isDisabled
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
            disabled={isDisabled}
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );

    return cancelSaveButtons;
  };

  renderAuditButton = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="auditButton"
            onClick={() => {
              this.props.handleToggleAudit();
            }}
          >
            {!this.props.showAuditLog
              ? strings.button.auditLabel
              : strings.button.formLabel}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderFormAdministration = () => {
    const {
      selectedSupportReferralData,
      handleChange,
      mode,
      formInvalid,
      validationResults,
      getOrganisations
    } = this.props;

    return (
      <FormBoxWithHeader
        header={strings.form.header.supportReferralAdmin}
        isFormWrapper
      >
        <Form.Group>
          {mode !== "create" && (
            <ValidatedFormInput
              formInvalid={formInvalid}
              inputData={selectedSupportReferralData.crmId}
              inputLabel={strings.form.label.crmId}
              inputName="crmId"
              inputPlaceholder={strings.form.placeholder.crmId}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.crmId}
              disabled
              mode={mode}
            />
          )}
          <ValidatedFormDropdown
            formInvalid={formInvalid}
            inputData={selectedSupportReferralData.owner}
            viewData={
              dropdownHelper.getNameFromValues(
                selectedSupportReferralData.owner,
                getOrganisations()
              ) || ""
            }
            dropdownLabel={strings.form.label.owner}
            dropdownName="owner"
            dropdownOptions={getOrganisations() || []}
            dropdownPlaceholder={strings.form.placeholder.owner}
            formFieldWidth={8}
            handleChange={handleChange}
            validationResult={validationResults.owner}
            dropdownDisabled
            mode={mode}
          />
        </Form.Group>
      </FormBoxWithHeader>
    );
  };

  renderFormGeneral = () => {
    const {
      selectedSupportReferralData,
      handleChange,
      mode,
      formInvalid,
      validationResults
    } = this.props;

    return (
      <FormBoxWithHeader
        header={strings.form.header.supportReferralGeneral}
        isFormWrapper
      >
        <Form.Group>
          <ValidatedFormDropdown
            formInvalid={formInvalid}
            inputData={selectedSupportReferralData.category}
            viewData={selectedSupportReferralData.category}
            dropdownLabel={strings.form.label.category}
            dropdownName="category"
            dropdownOptions={categoryOptions}
            dropdownPlaceholder={strings.form.placeholder.category}
            formFieldWidth={8}
            handleChange={handleChange}
            validationResult={validationResults.category}
            mode={mode}
          />
          <ValidatedFormDropdown
            formInvalid={formInvalid}
            inputData={selectedSupportReferralData.stageOfReferral}
            viewData={selectedSupportReferralData.stageOfReferral}
            dropdownLabel={strings.form.label.stageOfReferral}
            dropdownName="stageOfReferral"
            dropdownOptions={stageOfReferralOptions}
            dropdownPlaceholder={strings.form.placeholder.stageOfReferral}
            formFieldWidth={8}
            handleChange={handleChange}
            validationResult={validationResults.stageOfReferral}
            mode={mode}
          />
        </Form.Group>

        <Form.Group>
          <ValidatedFormDropdown
            formInvalid={formInvalid}
            inputData={selectedSupportReferralData.provider}
            viewData={
              dropdownHelper.getNameFromValues(
                selectedSupportReferralData.provider,
                providerOptions
              ) || ""
            }
            dropdownLabel={strings.form.label.provider}
            dropdownName="provider"
            dropdownOptions={providerOptions}
            dropdownPlaceholder={strings.form.placeholder.provider}
            formFieldWidth={8}
            handleChange={handleChange}
            validationResult={validationResults.provider}
            mode={mode}
          />
          <ValidatedDateInput
            inputId="dateReferred"
            formInvalid={formInvalid}
            inputLabel={strings.form.label.dateReferred}
            inputName="dateReferred"
            inputPlaceholder={strings.form.placeholder.dateReferred}
            inputDateFormat="DD/MM/YYYY"
            inputData={
              selectedSupportReferralData.dateReferred
                ? convertFromIso(selectedSupportReferralData.dateReferred)
                : ""
            }
            iconPosition="left"
            required
            formFieldWidth={8}
            handleChange={handleChange}
            validationResult={validationResults.dateReferred}
            mode={mode}
          />
        </Form.Group>

        <Form.Group>
          <ValidatedFormDropdown
            formInvalid={formInvalid}
            inputData={selectedSupportReferralData.referralAccepted}
            viewData={selectedSupportReferralData.referralAccepted}
            dropdownLabel={strings.form.label.referralAccepted}
            dropdownName="referralAccepted"
            dropdownOptions={yesNoOptions}
            dropdownPlaceholder={strings.form.placeholder.referralAccepted}
            formFieldWidth={8}
            handleChange={handleChange}
            validationResult={validationResults.referralAccepted}
            mode={mode}
            required={false}
          />
          <ValidatedFormTextArea
            formInvalid={formInvalid}
            inputData={selectedSupportReferralData.reasonForReferral}
            inputLabel={strings.form.label.reasonForReferral}
            inputName="reasonForReferral"
            inputPlaceholder={strings.form.placeholder.reasonForReferral}
            formFieldWidth={8}
            handleChange={handleChange}
            validationResult={validationResults.reasonForReferral}
            mode={mode}
          />
        </Form.Group>

        <Form.Group>
          <ValidatedFormDropdown
            formInvalid={formInvalid}
            inputData={selectedSupportReferralData.outcomeOfSpecialistReferral}
            viewData={selectedSupportReferralData.outcomeOfSpecialistReferral}
            dropdownLabel={strings.form.label.outcomeOfSpecialistReferral}
            dropdownName="outcomeOfSpecialistReferral"
            dropdownOptions={outcomeOfSpecialistReferralOptions}
            dropdownPlaceholder={
              strings.form.placeholder.outcomeOfSpecialistReferral
            }
            formFieldWidth={8}
            handleChange={handleChange}
            validationResult={validationResults.outcomeOfSpecialistReferral}
            mode={mode}
            required={false}
          />
          <ValidatedFormDropdown
            formInvalid={this.props.formInvalid}
            inputData={this.props.selectedSupportReferralData.reason}
            viewData={this.props.selectedSupportReferralData.reason}
            dropdownLabel={strings.form.label.reason}
            dropdownName="reason"
            dropdownOptions={reasonOptions}
            dropdownPlaceholder={strings.form.placeholder.reason}
            formFieldWidth={8}
            handleChange={this.props.handleChange}
            validationResult={this.props.validationResults.reason}
            required={
              selectedSupportReferralData.outcomeOfSpecialistReferral ===
                "Unsuitable Referral" ||
              selectedSupportReferralData.outcomeOfSpecialistReferral ===
                "Customer refused service"
            }
            mode={mode}
          />
        </Form.Group>

        <Form.Group>
          <ValidatedFormTextArea
            formInvalid={formInvalid}
            inputData={selectedSupportReferralData.reasonDetails}
            inputLabel={strings.form.label.reasonDetails}
            inputName="reasonDetails"
            inputPlaceholder={strings.form.placeholder.reasonDetails}
            formFieldWidth={8}
            handleChange={handleChange}
            required={false}
            validationResult={validationResults.reasonDetails}
            mode={mode}
          />
          <ValidatedDateInput
            inputId="startDate"
            formInvalid={formInvalid}
            inputLabel={strings.form.label.startDate}
            inputName="startDate"
            inputPlaceholder={strings.form.placeholder.startDate}
            inputDateFormat="DD/MM/YYYY"
            inputData={
              selectedSupportReferralData.startDate
                ? convertFromIso(selectedSupportReferralData.startDate)
                : ""
            }
            iconPosition="left"
            required={
              selectedSupportReferralData.outcomeOfSpecialistReferral ===
              "Customer Start"
            }
            formFieldWidth={8}
            handleChange={handleChange}
            validationResult={validationResults.startDate}
            mode={mode}
          />
        </Form.Group>

        <Form.Group>
          <ValidatedDateInput
            inputId="endDate"
            formInvalid={formInvalid}
            inputLabel={strings.form.label.endDate}
            inputName="endDate"
            inputPlaceholder={strings.form.placeholder.endDate}
            inputDateFormat="DD/MM/YYYY"
            inputData={
              selectedSupportReferralData.endDate
                ? convertFromIso(selectedSupportReferralData.endDate)
                : ""
            }
            iconPosition="left"
            formFieldWidth={8}
            handleChange={handleChange}
            required={false}
            validationResult={validationResults.endDate}
            mode={mode}
          />
        </Form.Group>
      </FormBoxWithHeader>
    );
  };

  renderFormEngagementDetails = () => {
    const {
      selectedSupportReferralData,
      mode,
      formInvalid,
      validationResults,
      handleEngagementChange,
      addEngagement,
      removeEngagement,
      handleModalConfirm,
      deleteEngagementModalOpen,
      toggleDeleteEngagementModalVisibility
    } = this.props;

    return (
      <>
        <div className="infoHeader" style={{ overflow: "auto" }}>
          {strings.form.header.supportReferralEngagementList}
          <Button
            onClick={addEngagement}
            className="engagementsButton"
            primary
            inverted
          >
            {strings.button.addEngagement}
          </Button>
        </div>
        <ValidatedMultipleEngagements
          strings={strings}
          mode={mode}
          engagements={selectedSupportReferralData.engagements}
          methodOptions={methodOptions}
          yesNoOptions={yesNoOptions}
          formInvalid={formInvalid}
          validationResults={validationResults}
          handleEngagementChange={handleEngagementChange}
          addEngagement={addEngagement}
          removeEngagement={removeEngagement}
          handleModalConfirm={handleModalConfirm}
          deleteEngagementModalOpen={deleteEngagementModalOpen}
          toggleDeleteEngagementModalVisibility={
            toggleDeleteEngagementModalVisibility
          }
        ></ValidatedMultipleEngagements>
      </>
    );
  };

  renderFormNotes = () => {
    const {
      selectedSupportReferralData,
      handleChange,
      mode,
      formInvalid,
      validationResults
    } = this.props;

    return (
      <FormBoxWithHeader
        header={strings.form.header.supportReferralNotes}
        isFormWrapper
      >
        <Form.Group>
          <ValidatedFormTextArea
            formInvalid={formInvalid}
            inputData={selectedSupportReferralData.notes}
            inputLabel={strings.form.label.notes}
            inputName="notes"
            inputPlaceholder={strings.form.placeholder.notes}
            formFieldWidth={8}
            handleChange={handleChange}
            validationResult={validationResults.notes}
            mode={mode}
            required={false}
          />
          {(this.props.hasPageLoaded || this.props.mode !== "create") &&
            this.props.selectedSupportReferralData.supportReferralId && (
              <FileManager
                folder={strings.folder.supportReferralFiles}
                headers={this.props.headers}
                allowEdit={true}
                filePath={`${strings.entity.supportReferral}s/`}
                filesLabel={strings.form.fileUpload}
                disabled={this.props.mode === "view"}
                entityId={
                  this.props.selectedSupportReferralData.supportReferralId
                }
                entity={strings.entity.supportReferral}
                filesHaveChanged={this.props.filesHaveChanged}
              />
            )}
        </Form.Group>
      </FormBoxWithHeader>
    );
  };

  render = () => {
    const { mode, pageTitle, requestStatus, formInvalid } = this.props;

    let parentPage;
    if (localStorage.getItem("programmeName")) {
      parentPage = localStorage.getItem("programmeName");
    } else if (localStorage.getItem("referralName")) {
      parentPage = localStorage.getItem("referralName");
    }

    return (
      <div className="container">
        <ComponentHeader
          parentPage={parentPage}
          returnToParent={this.returnToParent}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderEditButton()}
          cancelSaveButtons={this.renderCancelSaveButton()}
          auditLog={this.renderAuditButton()}
          permissionsUpdate={permissions.supportReferrals.update}
          permissionsCreate={permissions.supportReferrals.create}
          formInvalid={formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />

        {this.props.showAuditLog ? (
          <AuditListContainer
            embedded={true}
            headers={this.props.headers}
            history={this.props.history}
            entityId={this.props.selectedSupportReferralData.supportReferralId}
            hideSearchBar={true}
            record={true}
          />
        ) : (
          <Form id="supportReferralForm" className="infoBlock stickyForm">
            <fieldset disabled={mode === "view"}>
              {this.renderFormAdministration()}
              {this.renderFormGeneral()}
              {this.renderFormEngagementDetails()}
              {this.renderFormNotes()}
            </fieldset>
          </Form>
        )}
      </div>
    );
  };
}

ModifySupportReferral.propTypes = {
  mode: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  selectedSupportReferralData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  initialSupportReferralData: PropTypes.object.isRequired,
  deletePermissionStatus: PropTypes.string,
  handleModalInputChange: PropTypes.func,
  deletionReason: PropTypes.object,
  isDisabled: PropTypes.bool.isRequired
};

export default withRouter(ModifySupportReferral);
