export const taskTypes = [
  "customerTransfer",
  "duplicateWarning",
  "baselinePrerelease",
  "baselineFourWeek",
  "baselineExit",
  "govFourWeek",
  "govMidpoint",
  "govExit",
  "signupMeeting",
  "prereleaseMeeting",
  "midpointMeeting",
  "exitMeeting",
  "feedbackPrerelease",
  "feedbackFourWeeks",
  "feedbackExit",
  "changeAmendment",
  "deleteAmendment",
  "gateLiberation",
  "mentorAssigned",
  "supportServiceReferralAssignedSAMH",
  "ttgEngagementFollowup",
  "weeklyEngagement",
  "monthlyEngagement",
  "accommodationNeed",
  "financeNeed",
  "employmentEducationTrainingNeed",
  "victimsDomesticAbuseNeed",
  "sexWorkerNeed",
  "otherNeed"
];
