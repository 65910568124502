import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      fullName: "GCR",
      entity: "customers",
      deletion: "deletion"
    },
    header: {
      login: "Login",
      customerList: "Customers",
      createCustomer: "New Customer",
      editCustomer: "Edit Customer",
      viewCustomer: "Customer Details",
      amendCustomer: "Enter desired amendments",
      loading: "Loading Information"
    },
    form: {
      header: {
        customerInfo: "Customer Information",
        contactInfo: "Contact Information",
        customersLink: "Customers",
        clientsLink: "Clients",
        supportLink: "Support",
        settingsLink: "Settings"
      },
      label: {
        firstName: "First Name",
        surname: "Last Name",
        snumber: "SCRO Number",
        pNumber: "Prison Number",
        libAddress1: "Address Line 1",
        libAddress2: "Address Line 2",
        libTown: "Town/City",
        libPostcode: "Postcode",
        latestRiskRating: "Latest Risk Rating",
        liberationAddress: "Liberation Address",
        referralFrom: "Referral From",
        referralTo: "Referral To",
        dateOfReferral: "Date of Referral Meeting",
        referralInformationNeed: "Referral Information (Need)",
        referralInformationContact: "Referral Information (Contact)",
        referralInformationNotes: "Referral Information (Notes)",
        outcomeOfReferral: "Outcome of Referral Meeting",
        dateOfOutcomeOfReferral: "Date of Outcome of Referral",
        outcomeOfReferralNotes: "Outcome of Referral (notes)",
        phone1: "Primary Phone",
        phone2: "Secondary Phone",
        email: "Email Address",
        nin: "NI Number",
        EDLDate: "EDL Date",
        HDCDate: "HDC Date",
        prisonLocation: "Location in Prison",
        noOfConvictions: "No. of Convictions",
        convictionDate: "Conviction Dates",
        riskInfo: "Risk Information",
        consent: "Has Given Consent for data to be stored/shared?",
        area: "Geography"
      },
      placeholder: {
        stateReason: "State your reason...",
        firstName: "",
        surname: "",
        searchBar: "Search",
        snumber: "",
        pNumber: "",
        libAddress1: "",
        libAddress2: "",
        libTown: "",
        libPostcode: "",
        latestRiskRating: "",
        referralFrom: "",
        referralTo: "",
        dateOfReferral: "",
        referralInformationNeed: "",
        referralInformationContact: "",
        referralInformationNotes: "",
        outcomeOfReferral: "",
        outcomeOfReferralNotes: "",
        dateOfOutcomeOfReferral: "",
        liberationAddress: "",
        phone1: "",
        phone2: "",
        email: "",
        nin: "",
        EDLDate: "",
        HDCDate: "",
        prisonLocation: "",
        noOfConvictions: "",
        convictionDate: "",
        riskInfo: "",
        area: "- select -",
        consent: "- select -"
      },
      feedback: {
        processing: "Processing request...",
        customerRequestSuccess: "Customer created successfully!",
        customerRequestRefreshPrompt:
          "Use the refresh function to see an updated customers list.",
        customerRequestFailure: "Customer creation failed.",
        status: "Status",
        success: "Success",
        failure: "Failure",
        customerRequestUnknown:
          "Something went wrong with the customer request."
      },
      fileUpload: "Upload files"
    },
    modal: {
      createCustomerMessage: "Save customer?",
      modifyCustomerMessage: "Are you sure you want to save these changes?",
      deleteCustomerMessage:
        "Are you sure you want to delete this customer? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this customer's status to active?",
      setInactiveMessage: "Set this customer's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:",
      amendmentMessage:
        "Please confirm the amendments that you are requesting and provide a reason for them in the text box below."
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      addCustomerLabel: "New Customer",
      createCustomerReferral: "Create a Referral",
      requestDeleteLabel: "Request Delete Permission",

      requestAmendmentLabel: "Request Amendment(s)",
      submitAmendmentLabel: "Submit Amendment Request",
      resetLabel: "Go back to form selection",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    tooltip: {
      editCustomer: "Edit Customer",
      deleteCustomer: "Delete Customer",
      toggleStatus: "Click to change status",
      customerTypeWarning: "Customer Type cannot be changed after creation.",
      ninFormatHeader: "National Insurance Format",
      ninFormatContent:
        "Please enter the NIN in the same format as 'QQ123456C'. The following characters are not allowed. First Letter: DFIQUV. Second Letter: DFIQUVO. Final Letter must be: ABCD."
    },
    table: {
      header: {
        firstName: "First Name",
        surname: "Last Name",
        nin: "NI Number",
        snum: "SCRO Number",
        address: "Address",
        active: "Active",
        createdDate: "Created",
        updatedDate: "Updated",
        riskRating: "Risk Rating",
        libPostcode: "Post Code",
        phone1: "Phone Number",
        email: "Email",
        pNumber: "Prison Number",
        scroNumber: "S Number"
      },
      text: {
        noResults: "No results found."
      }
    },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    folder: {
      customerFiles: "customerFiles"
    },
    entity: {
      customer: "customer"
    }
  }
});
