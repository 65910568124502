const canUserModifyRecord = (data = {}, orgOfLoggedInUser) => {
  if (
    data.hasOwnProperty("referralId") &&
    !data.hasOwnProperty("programmeId")
  ) {
    return (
      data.owner === orgOfLoggedInUser ||
      (data.sharedWith &&
        data.sharedWith.length > 0 &&
        data.sharedWith.includes(orgOfLoggedInUser)) ||
      (data.supportPartnerSharedWith &&
        data.supportPartnerSharedWith.includes(orgOfLoggedInUser) &&
        window.location.href.includes("supportreferrals"))
    );
  } else {
    return (
      data.owner === orgOfLoggedInUser ||
      (data.sharedWith &&
        data.sharedWith.includes(orgOfLoggedInUser) &&
        window.location.href.includes("supportreferrals"))
    );
  }
};

export default canUserModifyRecord;
