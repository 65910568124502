import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import { AuditListContainer } from "../../Audit/containers/";
import {
  strings,
  activityTypeOptions,
  activityEngagedOptions,
  onNRSProgrammeActivityTypeOptions
} from "../resources";
import {
  ValidatedFormInput,
  ValidatedDateInput,
  ValidatedFormDropdown,
  ValidatedMultipleCategories,
  ValidatedFormTextArea
} from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from ".";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { convertFromIso, dropdownHelper } from "../helpers";

class ModifyActivity extends React.Component {
  returnToSubParentPage = () => {
    this.props.history.push(
      "/engagements/view/" + this.props.selectedActivityData.engagementId
    );
  };

  returnToParent = () => {
    if (localStorage.getItem("programmeId")) {
      this.props.history.push(
        "/programmes/view/" + localStorage.getItem("programmeId")
      );
    } else if (localStorage.getItem("referralId")) {
      this.props.history.push(
        "/referrals/view/" + localStorage.getItem("referralId")
      );
    }
  };

  renderModifyButtons = () => {
    const { handleModeSwitch, deleteSuccessful, isDisabled } = this.props;

    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      />
    );

    return (
      <div className="containerButtons">
        {!deleteSuccessful &&
          (!isDisabled ? (
            <>
              <Button.Group>
                <Button
                  className="editButton"
                  primary
                  onClick={() => {
                    handleModeSwitch("edit");
                  }}
                  disabled={this.props.showAuditLog}
                >
                  {strings.button.editLabel}
                </Button>
              </Button.Group>
            </>
          ) : (
            <span className="errorText">
              {strings.form.errorText.notShared}
            </span>
          ))}

        {this.props.deletePermissionStatus !== strings.states.approved &&
          !deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={this.props.toggleEnableDisableActivityModalVisibility}
                type="button"
                disabled={
                  this.props.selectedActivityData.requestId ? true : false
                }
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableActivityModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={
                  this.props.toggleEnableDisableActivityModalVisibility
                }
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderDeleteButton = () => {
    const { deleteSuccessful, isDisabled } = this.props;
    return (
      <div className="containerButtons">
        {this.props.deletePermissionStatus === strings.states.approved &&
          !deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={this.props.toggleEnableDisableActivityModalVisibility}
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteActivityMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableActivityModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={
                  this.props.toggleEnableDisableActivityModalVisibility
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderAuditButton = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="auditButton"
            onClick={() => {
              this.props.handleToggleAudit();
            }}
          >
            {!this.props.showAuditLog
              ? strings.button.auditLabel
              : strings.button.formLabel}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderCancelSaveButtons = () => {
    const {
      handleModeSwitch,
      selectedActivityData,
      handleModalConfirm,
      initialActivityData,
      confirmSaveModalOpen,
      supportReferralModalOpen,
      toggleConfirmSaveModalVisibility,
      toggleCancelChangesModalVisibility,
      toggleSupportReferralModalVisibility,
      mode,
      cancelChangesModalOpen,
      isDisabled,
      isSupportPartnerReferral
    } = this.props;

    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={
              isSupportPartnerReferral
                ? () =>
                    this.props.validateForm(
                      this.props.supportReferralConstraints,
                      this.props.selectedSupportReferralData
                    )
                : () =>
                    this.props.validateForm(
                      this.props.activityConstraints,
                      this.props.selectedActivityData
                    )
            }
            disabled={
              JSON.stringify(selectedActivityData) ===
                JSON.stringify(initialActivityData) || isDisabled
            }
          >
            {isSupportPartnerReferral
              ? strings.button.submitSupportReferral
              : strings.button.saveLabel}
          </Button>
        </Button.Group>

        <Button.Group>
          {!isSupportPartnerReferral && (
            <Button
              className="saveAddNewButton"
              primary
              inverted
              onClick={() =>
                this.props.validateFormAndAddNew(
                  this.props.activityConstraints,
                  this.props.selectedActivityData
                )
              }
              disabled={
                JSON.stringify(selectedActivityData) ===
                  JSON.stringify(initialActivityData) || isDisabled
              }
            >
              {strings.button.saveAddNew}
            </Button>
          )}
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
          <BasicModal
            confirmMessage={strings.modal.supportReferralMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="supportReferralModal"
            modalOpen={supportReferralModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleSupportReferralModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
            disabled={isDisabled}
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };

  renderForm = () => {
    const {
      selectedActivityData,
      selectedBaselineData,
      handleModalConfirm,
      mode,
      formInvalid,
      handleChange,
      validationResults,
      handleCategoryChange,
      toggleDeleteModal,
      addCategory,
      deleteModalOpen,
      organisationList,
      supportServiceSelectionRequired,
      canAddOutcomes,
      isSupportPartnerReferral
    } = this.props;

    const isCreate = mode === "create";
    const displayPreviousCrmId =
      !isCreate && selectedActivityData.previousCrmId;
    const displayOtherSupportService =
      selectedActivityData.supportService === strings.form.text.other;
    const supportServiceOptions = displayOtherSupportService
      ? [
          ...organisationList,
          {
            key: organisationList.length + 1,
            value: strings.form.text.other,
            text: strings.form.text.other
          }
        ]
      : organisationList;

    const activityOptions =
      (this.props.programmeDetails || localStorage.getItem("programmeId")) &&
      this.props.selectedActivityData.area === strings.areas.NRS &&
      ((this.props.programmeDetails &&
        this.props.programmeDetails.journeyStatus) ||
        (this.props.selectedProgrammeData &&
          this.props.selectedProgrammeData.journeyStatus))
        ? onNRSProgrammeActivityTypeOptions
        : activityTypeOptions;

    return (
      <>
        <div className="infoBlock">
          <div className="infoHeader">{strings.form.header.activityInfo}</div>
          <div className="infoWrapper">
            <Form.Group>
              {displayPreviousCrmId && (
                <ValidatedFormInput
                  formInvalid={formInvalid}
                  inputData={selectedActivityData.previousCrmId}
                  inputLabel={strings.form.label.previousCrmId}
                  inputName="previousCrmId"
                  inputPlaceholder={strings.form.placeholder.previousCrmId}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.previousCrmId}
                  disabled
                  mode={mode}
                />
              )}
            </Form.Group>
            <Form.Group>
              {!isCreate && (
                <ValidatedFormInput
                  formInvalid={formInvalid}
                  inputData={selectedActivityData.crmId}
                  inputLabel={strings.form.label.crmId}
                  inputName="crmId"
                  inputPlaceholder={strings.form.placeholder.crmId}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.crmId}
                  disabled
                  mode={mode}
                />
              )}
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedActivityData.owner}
                viewData={
                  dropdownHelper.getNameFromValues(
                    selectedActivityData.owner,
                    this.props.getOrganisations()
                  ) || ""
                }
                dropdownLabel={strings.form.label.owner}
                dropdownName="owner"
                dropdownDisabled
                dropdownOptions={this.props.getOrganisations() || []}
                dropdownPlaceholder={strings.form.placeholder.owner}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.owner}
                mode={mode}
              />
            </Form.Group>
            <Form.Group>
              {!isSupportPartnerReferral && (
                <ValidatedDateInput
                  inputId="dateOfActivity"
                  formInvalid={formInvalid}
                  inputLabel={strings.form.label.dateOfActivity}
                  inputName="dateOfActivity"
                  inputPlaceholder={strings.form.placeholder.dateOfActivity}
                  inputDateFormat="DD/MM/YYYY"
                  inputData={
                    selectedActivityData.dateOfActivity
                      ? convertFromIso(selectedActivityData.dateOfActivity)
                      : ""
                  }
                  iconPosition="left"
                  required
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.dateOfActivity}
                  mode={mode}
                  maxDate={new Date()}
                />
              )}
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedActivityData.type}
                viewData={selectedActivityData.type || ""}
                dropdownLabel={strings.form.label.type}
                dropdownName="type"
                dropdownOptions={activityOptions}
                dropdownPlaceholder={strings.form.placeholder.type}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.type}
                mode={mode}
              />
            </Form.Group>

            {supportServiceSelectionRequired && (
              <>
                <Form.Group>
                  <ValidatedFormDropdown
                    formInvalid={formInvalid}
                    inputData={selectedActivityData.supportServiceEngaged}
                    viewData={selectedActivityData.supportServiceEngaged}
                    dropdownLabel={strings.form.label.supportServiceEngaged}
                    dropdownName="supportServiceEngaged"
                    dropdownOptions={activityEngagedOptions}
                    dropdownPlaceholder={
                      strings.form.placeholder.supportServiceEngaged
                    }
                    formFieldWidth={8}
                    handleChange={handleChange}
                    validationResult={validationResults.supportServiceEngaged}
                    mode={mode}
                  />
                  <ValidatedFormDropdown
                    formInvalid={formInvalid}
                    inputData={selectedActivityData.supportService}
                    viewData={
                      dropdownHelper.getNameFromValues(
                        selectedActivityData.supportService,
                        supportServiceOptions
                      ) || ""
                    }
                    dropdownLabel={strings.form.label.supportService}
                    dropdownName="supportService"
                    dropdownOptions={supportServiceOptions}
                    dropdownPlaceholder={
                      strings.form.placeholder.supportService
                    }
                    formFieldWidth={8}
                    handleChange={handleChange}
                    validationResult={validationResults.supportService}
                    dropdownDisabled={
                      selectedActivityData.supportServiceEngaged !==
                        strings.fieldValues.yes || displayOtherSupportService
                    }
                    mode={mode}
                  />
                </Form.Group>
                <Form.Group>
                  <div className="fieldPusher" />
                  {displayOtherSupportService && (
                    <ValidatedFormTextArea
                      formInvalid={formInvalid}
                      inputData={selectedActivityData.otherSupportService}
                      inputLabel={strings.form.label.otherSupportService}
                      inputName="otherSupportService"
                      inputPlaceholder={
                        strings.form.placeholder.otherSupportService
                      }
                      formFieldWidth={8}
                      handleChange={handleChange}
                      validationResult={validationResults.otherSupportService}
                      required={false}
                      mode={mode}
                      disabled
                    />
                  )}
                </Form.Group>
              </>
            )}
          </div>
        </div>

        {canAddOutcomes && (
          <div className="infoBlock">
            <div className="infoHeader">
              {strings.form.header.activityCategoriesOutcomes}
            </div>
            <div className="infoWrapper">
              <ValidatedMultipleCategories
                strings={strings}
                selectedActivityData={selectedActivityData}
                selectedBaselineData={selectedBaselineData}
                validationResults={validationResults}
                formInvalid={formInvalid}
                handleCategoryChange={handleCategoryChange}
                mode={mode}
                toggleDeleteModal={toggleDeleteModal}
                addCategory={addCategory}
                handleModalConfirm={handleModalConfirm}
                deleteModalOpen={deleteModalOpen}
                dropdownOptions={this.props.activeCategories}
                outcomesList={this.props.outcomesList}
                defaultOutcomes={this.props.defaultOutcomes}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  render = () => {
    const { mode, pageTitle, requestStatus } = this.props;

    const activitiesForm = this.renderForm();

    let parents = [];

    let parentPage;
    if (localStorage.getItem("programmeName")) {
      parentPage = localStorage.getItem("programmeName");
    } else if (localStorage.getItem("referralName")) {
      parentPage = localStorage.getItem("referralName");
    }
    if (parentPage) {
      parents.push({
        parentPage,
        returnToParent: this.returnToParent
      });
    }

    let subParentPage = this.props.selectedActivityData.engagementCrmId;
    parents.push({
      parentPage: subParentPage,
      returnToParent: this.returnToSubParentPage
    });

    return (
      <div className="container">
        <ComponentHeader
          parentPage={parents}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderModifyButtons()}
          cancelSaveButtons={this.renderCancelSaveButtons()}
          auditLog={this.renderAuditButton()}
          deleteButton={this.renderDeleteButton()}
          permissionsUpdate={permissions.activities.update}
          permissionsCreate={permissions.activities.create}
          permissionsDelete={permissions.activities.delete}
          formInvalid={this.props.formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />
        {this.props.showAuditLog ? (
          <AuditListContainer
            embedded={true}
            headers={this.props.headers}
            history={this.props.history}
            entityId={this.props.selectedActivityData.activityId}
            hideSearchBar={true}
            record={true}
          />
        ) : (
          <Form id="activityForm" className="stickyForm">
            {mode === "view" && <fieldset disabled>{activitiesForm}</fieldset>}
            {mode !== "view" && <fieldset>{activitiesForm}</fieldset>}
          </Form>
        )}
      </div>
    );
  };
}

ModifyActivity.propTypes = {
  mode: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  selectedActivityData: PropTypes.object.isRequired,
  selectedBaselineData: PropTypes.object,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  initialActivityData: PropTypes.object.isRequired,
  organisationList: PropTypes.array,
  deletePermissionStatus: PropTypes.string,
  handleModalInputChange: PropTypes.func,
  deletionReason: PropTypes.object,
  isDisabled: PropTypes.bool.isRequired
};

export default withRouter(ModifyActivity);
