import moment from "moment";
import { strings, dropdownOptions } from "../resources";

export const initialiseActionsTableAndNeedsFields = selectedAreaOfNeedData => {
  let actionsTable = {};
  let areaOfNeedFields = {};

  let needsToMaintain = selectedAreaOfNeedData.areaOfNeed
    ? selectedAreaOfNeedData.areaOfNeed
    : [];

  for (const value of Object.values(dropdownOptions.areaOfNeedOptions)) {
    if (needsToMaintain.includes(value.value)) {
      actionsTable[`${value.value}`] =
        selectedAreaOfNeedData.actionsTable[`${value.value}`];
      areaOfNeedFields[`${value.value}`] =
        selectedAreaOfNeedData.areaOfNeedFields[`${value.value}`];
      // Clear dates relating to status that was not selected
      actionsTable[`${value.value}`].forEach(action => {
        if (action.actionStatus === strings.form.text.open) {
          action.actionClosedDate = null;
        } else {
          action.actionStartedDate = null;
        }
      });
    } else {
      actionsTable[`${value.value}`] = [
        {
          actions: "",
          actionStatus: strings.form.text.open,
          actionStartedDate: null,
          actionClosedDate: null,
          notes: ""
        }
      ];
      areaOfNeedFields[`${value.value}`] = {
        outcomeOfAreaOfNeed: strings.form.text.workInProgress,
        reasonForOutcome: "",
        followUpDate: moment(new Date()).add(7, "days").format("YYYY-MM-DD")
      };
    }
  }

  return {
    actionsTable: actionsTable,
    areaOfNeedFields: areaOfNeedFields
  };
};
