export const assuranceActionTypes = {
  CREATE_ASSURANCE_REQUEST: "CREATE_ASSURANCE_REQUEST",
  CREATE_ASSURANCE_SUCCESS: "CREATE_ASSURANCE_SUCCESS",
  CREATE_ASSURANCE_FAILURE: "CREATE_ASSURANCE_FAILURE",

  CLEAR_CREATE_ASSURANCE_REQUEST: "CLEAR_CREATE_ASSURANCE_REQUEST",
  CLEAR_CREATE_ASSURANCE_FAILURE: "CLEAR_CREATE_ASSURANCE_FAILURE",
  CLEAR_CREATE_ASSURANCE_SUCCESS: "CLEAR_CREATE_ASSURANCE_SUCCESS",

  DELETE_ASSURANCE_REQUEST: "DELETE_ASSURANCE_REQUEST",
  DELETE_ASSURANCE_SUCCESS: "DELETE_ASSURANCE_SUCCESS",
  DELETE_ASSURANCE_FAILURE: "DELETE_ASSURANCE_FAILURE",

  UPDATE_SELECTED_ASSURANCE_DATA_REQUEST:
    "UPDATE_SELECTED_ASSURANCE_DATA_REQUEST",
  UPDATE_SELECTED_ASSURANCE_DATA_SUCCESS:
    "UPDATE_SELECTED_ASSURANCE_DATA_SUCCESS",
  UPDATE_SELECTED_ASSURANCE_DATA_FAILURE:
    "UPDATE_SELECTED_ASSURANCE_DATA_FAILURE",

  UPDATE_SELECTED_ASSURANCE_REQUEST: "UPDATE_SELECTED_ASSURANCE_REQUEST",
  UPDATE_SELECTED_ASSURANCE_SUCCESS: "UPDATE_SELECTED_ASSURANCE_SUCCESS",
  UPDATE_SELECTED_ASSURANCE_FAILURE: "UPDATE_SELECTED_ASSURANCE_FAILURE",

  CLEAR_SELECTED_ASSURANCE_REQUEST: "CLEAR_SELECTED_ASSURANCE_REQUEST",
  CLEAR_SELECTED_ASSURANCE_SUCCESS: "CLEAR_SELECTED_ASSURANCE_SUCCESS",
  CLEAR_SELECTED_ASSURANCE_FAILURE: "CLEAR_SELECTED_ASSURANCE_FAILURE",

  CANCEL_SELECTED_ASSURANCE_UPDATE_REQUEST:
    "CANCEL_SELECTED_ASSURANCE_UPDATE_REQUEST",
  CANCEL_SELECTED_ASSURANCE_UPDATE_SUCCESS:
    "CANCEL_SELECTED_ASSURANCE_UPDATE_SUCCESS",
  CANCEL_SELECTED_ASSURANCE_UPDATE_FAILURE:
    "CANCEL_SELECTED_ASSURANCE_UPDATE_FAILURE",

  GET_ASSURANCES_REQUEST: "GET_ASSURANCES_REQUEST",
  GET_ASSURANCES_SUCCESS: "GET_ASSURANCES_SUCCESS",
  GET_ASSURANCES_FAILURE: "GET_ASSURANCES_FAILURE",

  UPDATE_ASSURANCE_REQUEST: "UPDATE_ASSURANCE_REQUEST",
  UPDATE_ASSURANCE_SUCCESS: "UPDATE_ASSURANCE_SUCCESS",
  UPDATE_ASSURANCE_FAILURE: "UPDATE_ASSURANCE_FAILURE",

  GET_ASSURANCE_BY_ID_REQUEST: "GET_ASSURANCE_BY_ID_REQUEST",
  GET_ASSURANCE_BY_ID_SUCCESS: "GET_ASSURANCE_BY_ID_SUCCESS",
  GET_ASSURANCE_BY_ID_FAILURE: "GET_ASSURANCE_BY_ID_FAILURE",

  GENERATE_ASSURANCE_ID_REQUEST: "GENERATE_ASSURANCE_ID_REQUEST",
  GENERATE_ASSURANCE_ID_SUCCESS: "GENERATE_ASSURANCE_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE"
};
