import moment from "moment-timezone";
import { Auth } from "aws-amplify";
import { User, SystemHeaders } from "../classes";
import { userPool } from "../../_config/aws";
import { systemHeaders } from "../../_config/systemHeaders";
import uuidv4 from "uuid/v4";
import publicIp from "public-ip";

const generateSystemHeaders = ({ userId, group, username, jwtToken }) => {
  return new Promise((resolve, reject) => {
    let headers = Object.assign({}, systemHeaders, {
      systemHeader_client: "TWG",
      systemHeader_sysref: "TWGCRMWebApp",
      systemHeader_subsysref: "UserLogin",
      systemHeader_userid: userId,
      systemHeader_permissions: group,
      systemHeader_username: username,
      systemHeader_date: moment()
        .tz("Europe/London")
        .format("DD-MM-YYYY HH:mm:ss"),
      systemHeader_correlationId: "1",
      systemHeader_parents: "None",
      Authorization: jwtToken,
      sessionId: uuidv4()
    });

    try {
      SystemHeaders.store({
        headers: headers
      });
      // Remove authorization header before it is put into the Redux store
      // Done to prevent discrepancies from the same data being held in different places
      delete headers.Authorization;
      resolve(headers);
    } catch (error) {
      reject(error);
    }
  });
};

const setSystemHeader = (header, value) => {
  return new Promise((resolve, reject) => {
    // load headers from localStorage
    let state = SystemHeaders.load();

    // set (or update) the header
    state.headers[header] = value;

    // update "systemHeader_date" header with current timestamp
    state.headers["systemHeader_date"] = moment()
      .tz("Europe/London")
      .format("DD-MM-YYYY HH:mm:ss");

    try {
      // save headers to localStorage and return them
      SystemHeaders.store(state);
      resolve(state.headers);
    } catch (error) {
      reject(error);
    }
  });
};

const loadSystemHeaders = () => {
  return SystemHeaders.load();
};

const signUp = (
  username,
  password,
  email,
  roleId,
  signature_path,
  given_name,
  family_name,
  area
) => {
  return new Promise((resolve, reject) => {
    Auth.signUp({
      username,
      password,
      attributes: {
        email,
        "custom:roleId": roleId,
        given_name,
        family_name,
        "custom:area": area
      }
    })
      .then(user => {
        resolve(user);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

const signIn = async (username, password) => {
  const ipAddress = await publicIp.v4();
  localStorage.setItem("ipAddress", ipAddress);
  return new Promise((resolve, reject) => {
    const userPromise = Auth.signIn(username, password);
    const userAttributesPromise = userPromise.then(user => {
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        reject("new password is needed");
      }
      return Auth.userAttributes(user);
    });
    const currentSessionPromise = userPromise.then(user => {
      return Auth.currentSession();
    });
    Promise.all([userPromise, userAttributesPromise, currentSessionPromise])
      .then(values => {
        const user = values[0];
        const userId = values[1].find(item => item.Name === "sub");
        const roleId = values[1].find(item => item.Name === "custom:roleId");
        const givenName = values[1].find(item => item.Name === "given_name");
        const familyName = values[1].find(item => item.Name === "family_name");
        const area = values[1].find(item => item.Name === "custom:area");
        const jwtToken = values[2].getIdToken().getJwtToken();
        const organisationOfLoggedInUser = values[1].find(
          item => item.Name === "custom:organisationId"
        );
        const userDetails = {
          userId: userId.Value,
          username: user.getUsername(),
          group: roleId.Value,
          givenName: givenName.Value,
          familyName: familyName.Value,
          jwtToken: jwtToken,
          area: area.Value,
          organisationOfLoggedInUser: organisationOfLoggedInUser.Value
        };
        resolve(userDetails);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const signOut = () => {
  return new Promise((resolve, reject) => {
    Auth.signOut().catch(error => {
      console.log(error);
      reject(error);
    });

    try {
      let cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.signOut();
      }
      SystemHeaders.purge();
      localStorage.removeItem("programmeId");
      localStorage.removeItem("programmeName");
      localStorage.removeItem("parentReferralId");
      localStorage.removeItem("referralId");
      localStorage.removeItem("referralName");
      localStorage.removeItem("engagementId");
      localStorage.removeItem("engagementCrmId");
      localStorage.removeItem("ipAddress");
      localStorage.removeItem("feedbackLocked");
      localStorage.removeItem("pageLimit");
      localStorage.removeItem("timeLimit");
      localStorage.removeItem("retrievedEntities");
      localStorage.removeItem("ttgProgrammeId");
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

const getCurrentUserSession = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        resolve(session);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

const getCurrentAuthenticatedUser = () => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        resolve(user);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const refreshTokens = () => {
  return new Promise((resolve, reject) => {
    try {
      let cognitoUser = User.refreshTokens();
      resolve(cognitoUser);
    } catch (error) {
      reject(error);
    }
  });
};

const changePassword = (oldPassword, newPassword, newPasswordConfirmation) => {
  return new Promise((resolve, reject) => {
    try {
      User.changePassword(oldPassword, newPassword, newPasswordConfirmation);
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const authService = {
  generateSystemHeaders,
  setSystemHeader,
  loadSystemHeaders,
  refreshTokens,
  signUp,
  signIn,
  signOut,
  getCurrentUserSession,
  getCurrentAuthenticatedUser,
  changePassword
};
