import React from "react";
import ModifyTask from "../../components/ModifyTask";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources/Strings";
import { taskActions } from "../../store/actions/task.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { referralActions } from "../../../Referrals/store/actions/referral.actions";
import { supportReferralActions } from "../../../SupportReferral/store/actions/supportReferral.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { customerActions } from "../../../Customers/store/actions/customer.actions";
import { strings as programmeStrings } from "../../../Programmes/resources/Strings";
import { strings as referralStrings } from "../../../Referrals/resources/Strings";
import { updateSharedWithProperties } from "../../helpers";

import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { convertToIso } from "../../helpers/index";
class TaskEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableTaskModalOpen: false,
    enableDisableTaskApprovalModalOpen: false,
    deleteModalOpen: false,
    initialTaskData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    clearTaskReason: "",
    approvalSelection: "",
    rejectionReason: "",
    stringsObject: {}
  };

  componentDidMount = async () => {
    await this.props.getTaskById(
      this.props.match.params.id,
      this.props.headers
    );
    if (
      this.props.selectedTaskData.linkedRecordId &&
      this.props.selectedTaskData.linkedRecordId !== strings.tasks.na &&
      this.props.selectedTaskData.type === "deleteAmendment"
    ) {
      await this.props.getDeletePermissionStatus(
        this.props.selectedTaskData.linkedRecordId
      );
      this.props.clearAmendmentRequest();
    }

    this.setState({
      stringsObject: {
        ...programmeStrings.form.label,
        ...referralStrings.form.label,

        ...{ updaterName: "Updater Name" }
      }
    });

    let form = document.getElementById("taskForm");
    if (form) {
      form.setAttribute("novalidate", true);
    }

    if (
      this.props.selectedTaskData.details &&
      this.props.selectedTaskData.details.referralId &&
      this.props.selectedTaskData.details.programmeId
    ) {
      this.props.getReferralById(
        this.props.selectedTaskData.details.referralId,
        this.props.headers,
        true
      );
      this.props.getProgrammeById(
        this.props.selectedTaskData.details.programmeId,
        this.props.headers,
        true
      );
    }

    this.props.clearTaskRequest();
  };

  componentWillUnmount() {
    this.props.clearTaskRequest();
    this.props.clearAmendmentRequest();
    this.props.clearReferralRequest();
    this.props.clearCustomerRequest();
    this.props.clearProgrammeRequest();
  }

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedTaskData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.activities.modifyTask
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    await this.props.updateTask(
      this.props.match.params.id,
      this.props.selectedTaskData,
      this.props.headers,
      this.state.initialTaskData
    );
    this.setState({
      initialTaskData: this.props.selectedTaskData
    });
    this.handleModeSwitch();
    window.setTimeout(() => {
      this.props.clearTaskRequest();
    }, 10000);
  };

  handleCancel = () => {
    this.props.history.goBack();
    this.setState({ formInvalid: false });
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedTask(data.name, convertToIso(data.value));
    } else {
      await this.props.updateSelectedTask(data.name, data.value);
    }
  };

  handleDelete = async () => {
    await this.props.deleteTask(
      this.props.selectedTaskData.taskId,
      this.state.clearTaskReason !== ""
        ? this.state.clearTaskReason
        : undefined,
      this.props.headers
    );

    await this.props.getTaskById(
      this.props.match.params.id,
      this.props.headers
    );
  };

  handleChangeModal = (event, data) => {
    if (data.name === "clearReason") {
      this.setState({ clearTaskReason: data.value });
    }
    if (data.name === "rejectionReason")
      this.setState({ rejectionReason: data.value });
  };

  approveDuplicateRequest = () => {
    if (this.state.approvalSelection === strings.fieldValues.approved) {
      if (this.props.selectedTaskData.details.area === strings.areas.NRS) {
        this.props.createReferral(
          this.props.selectedTaskData.details,
          this.props.headers
        );
      } else if (
        this.props.selectedTaskData.details.area === strings.areas.NRNE
      ) {
        this.props.updateReferral(
          this.props.selectedTaskData.details.referralId,
          this.props.selectedTaskData.details,
          this.props.headers,
          {},
          false,
          true
        );
      }
      this.setState({ clearTaskReason: "Duplicate Approved" }, () =>
        this.handleDelete()
      );
    } else {
      this.setState({ clearTaskReason: "Duplicate Rejected" }, () =>
        this.handleDelete()
      );
    }
    window.setTimeout(() => {
      this.props.clearReferralRequest();
      this.props.clearTaskRequest();
    }, 10000);
  };

  clearTaskAndUpdateReason = async reason => {
    await this.props.updateSelectedTask("cleared", true);
    await this.props.updateSelectedTask("clearReason", reason);
  };

  assignmentRequest = async () => {
    if (this.state.approvalSelection === strings.fieldValues.rejected) {
      this.props.updateReferral(
        this.props.selectedTaskData.linkedRecordId,
        {
          communityMentor: "reject",
          rejectionReason: this.state.rejectionReason
        },
        this.props.headers,
        {},
        true
      );
      await this.props.updateSelectedTask(
        "rejectionReason",
        this.state.rejectionReason
      );
      await this.props.updateSelectedTask("cleared", true);
      await this.props.updateSelectedTask("clearReason", "Assignment Rejected");
    } else {
      await this.props.updateSelectedTask("cleared", true);
      await this.props.updateSelectedTask("clearReason", "Assignment Accepted");
    }
    await this.props.updateTask(
      this.props.match.params.id,
      this.props.selectedTaskData,
      this.props.headers,
      this.state.initialTaskData
    );
    window.setTimeout(() => {
      this.props.clearTaskRequest();
      this.props.clearReferralRequest();
    }, 10000);
  };

  amendmentRequest = async () => {
    if (this.state.approvalSelection === strings.fieldValues.rejected) {
      await this.props.updateSelectedTask(
        "rejectionReason",
        this.state.rejectionReason
      );
      await this.props.updateSelectedTask("cleared", true);
      await this.props.updateSelectedTask("clearReason", "Amendment Rejected");
      await this.props.updateAmendmentPermissionStatus(
        this.props.selectedTaskData.linkedRecordId,
        this.state.approvalSelection,
        this.state.headers,
        null,
        this.state.rejectionReason
      );

      await this.props.updateTask(
        this.props.match.params.id,
        this.props.selectedTaskData,
        this.props.headers,
        this.state.initialTaskData
      );
    } else if (this.state.approvalSelection === strings.fieldValues.approved) {
      switch (this.props.selectedTaskData.details.entity) {
        case "Referral":
          this.props.updateReferral(
            this.props.selectedTaskData.details.recordId,
            this.props.selectedTaskData.details.newValues,
            this.props.headers,
            {},
            true,
            true
          );
          window.setTimeout(() => {
            this.props.clearReferralRequest();
          }, 10000);
          break;
        case "Programme":
          this.props.updateProgramme(
            this.props.selectedTaskData.details.recordId,
            this.props.selectedTaskData.details.newValues,
            this.props.headers,
            {},
            true,
            true
          );
          window.setTimeout(() => {
            this.props.clearProgrammeRequest();
          }, 10000);
          break;
        case "Customer":
          let details = {
            pNumber: this.props.selectedTaskData.prisonNumber,
            ...this.props.selectedTaskData.details.newValues
          };
          this.props.updateCustomer(
            this.props.selectedTaskData.details.recordId,
            details,
            this.props.headers,
            {},
            true,
            true
          );
          window.setTimeout(() => {
            this.props.clearCustomerRequest();
          }, 10000);
          break;
        default:
          break;
      }
      await this.props.updateSelectedTask("cleared", true);
      await this.props.updateSelectedTask("clearReason", "Amendment Accepted");
      await this.props.setAmendmentStatusActioned(
        this.props.selectedTaskData.linkedRecordId,
        this.props.headers
      );

      await this.props.updateTask(
        this.props.match.params.id,
        this.props.selectedTaskData,
        this.props.headers,
        this.state.initialTaskData
      );
    }

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearTaskRequest();
    }, 10000);
  };

  supportReferralRequest = async () => {
    let supportReferralBody = this.props.selectedTaskData.details;
    if (this.state.approvalSelection === strings.fieldValues.rejected) {
      supportReferralBody.reasonDetails = this.state.rejectionReason;
      supportReferralBody.referralAccepted = "No";
      this.props.updateSupportReferral(
        this.props.selectedTaskData.details.supportReferralId,
        supportReferralBody,
        this.props.headers,
        this.props.selectedTaskData.details,
        true,
        true
      );
      await this.props.updateSelectedTask(
        "rejectionReason",
        this.state.rejectionReason
      );
      await this.props.updateSelectedTask(
        "clearReason",
        "Support Referral Rejected"
      );
    } else {
      let referralBody = {
        supportPartnerSharedWith: this.props.selectedReferralData
          .supportPartnerSharedWith
      };
      let programmeBody = {
        sharedWith: this.props.selectedProgrammeData.sharedWith
      };
      supportReferralBody.referralAccepted = "Yes";
      this.props.updateSupportReferral(
        this.props.selectedTaskData.details.supportReferralId,
        supportReferralBody,
        this.props.headers,
        this.props.selectedTaskData.details,
        true,
        true
      );
      await this.props.updateSelectedTask(
        "clearReason",
        "Support Referral Accepted"
      );

      let {
        updatedProgrammeBody,
        updatedReferralBody
      } = updateSharedWithProperties(
        supportReferralBody,
        referralBody,
        programmeBody
      );

      this.props.updateProgramme(
        this.props.selectedTaskData.details.programmeId,
        updatedProgrammeBody,
        this.props.headers,
        this.props.selectedProgrammeData,
        true,
        false,
        true
      );
      this.props.updateReferral(
        this.props.selectedTaskData.details.referralId,
        updatedReferralBody,
        this.props.headers,
        this.props.selectedReferralData,
        true,
        false,
        true
      );

      window.setTimeout(() => {
        this.props.clearProgrammeRequest();
        this.props.clearReferralRequest();
      }, 10000);
    }
    await this.props.updateSelectedTask("cleared", true);

    await this.props.updateTask(
      this.props.match.params.id,
      this.props.selectedTaskData,
      this.props.headers,
      this.state.initialTaskData
    );

    window.setTimeout(() => {
      this.props.clearSupportReferralRequest();
      this.props.clearTaskRequest();
    }, 10000);
  };

  handleApprovalModal = e => {
    this.props.updateAmendmentPermissionStatus(
      this.props.selectedTaskData.linkedRecordId,
      this.state.approvalSelection,
      this.state.headers,
      null,
      this.state.rejectionReason
    );
    this.handleDelete();
    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearTaskRequest();
    }, 10000);
  };

  handleApprovalDropdown = (e, value) => {
    this.setState({
      approvalSelection: value.value
    });
  };

  handleApprovalButton = () => {
    this.setState(
      {
        approvalSelection: strings.fieldValues.approved
      },
      () => {
        this.handleModalConfirm("amendmentReviewModal");
      }
    );
  };

  handleDeleteApprovalButton = () => {
    this.setState(
      {
        approvalSelection: strings.fieldValues.approved
      },
      () => {
        this.handleModalConfirm("approveRequestModal");
      }
    );
  };

  handleRejectionButton = () => {
    this.setState({
      approvalSelection: strings.fieldValues.rejected
    });
  };

  resetDataOnModalClose = () => {
    this.setState({
      approvalSelection: "",
      clearTaskReason: "",
      rejectionReason: ""
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "deleteTaskModal":
        this.handleDelete();
        break;
      case "approveRequestModal":
        this.handleApprovalModal();
        break;
      case "approveDuplicateModal":
        this.approveDuplicateRequest();
        break;
      case "assignmentModal":
        this.assignmentRequest();
        break;
      case "supportReferralModal":
        this.supportReferralRequest();
        break;
      case "amendmentReviewModal":
        this.amendmentRequest();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableTaskModalVisibility = () => {
    this.setState({
      enableDisableTaskModalOpen: !this.state.enableDisableTaskModalOpen
    });
  };

  toggleEnableDisableTaskApprovalModalVisibility = () => {
    this.setState({
      enableDisableTaskApprovalModalOpen: !this.state
        .enableDisableTaskApprovalModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialTaskData) !==
        JSON.stringify(this.props.selectedTaskData)
      ) {
        await this.props.cancelSelectedTaskUpdate(this.state.initialTaskData);
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialTaskData:
          JSON.parse(JSON.stringify(this.props.selectedTaskData)) ||
          this.props.selectedTaskData
      });
    }
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.customerLoadingPage ||
      this.props.referralLoadingPage ||
      this.props.programmeLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          {this.props.showNotification && (
            <RequestFeedback
              requestStatus={this.props.taskRequestStatus}
              requestMade={this.props.taskRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={strings.form.feedback.taskRequestUnknown}
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          {this.props.showAmendmentNotification && (
            <RequestFeedback
              className={
                this.props.showNotification ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.amendmentRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          {this.props.showReferralNotification && (
            <RequestFeedback
              className={
                this.props.showNotification
                  ? "smallerFeedbackMargin"
                  : "largeSpaceAbove"
              }
              requestStatus={this.props.referralRequestStatus}
              requestMade={this.props.referralRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.referralResult}
              failureMessage={this.props.referralError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.referralRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.referralErrorDetails}
            />
          )}
          {this.props.showCustomerNotification && (
            <RequestFeedback
              className={
                this.props.showNotification ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.customerRequestStatus}
              requestMade={this.props.customerRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.customerResult}
              failureMessage={this.props.customerError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.customerRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.customerErrorDetails}
            />
          )}

          {this.props.showProgrammeNotification && (
            <RequestFeedback
              className={
                this.props.showNotification ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.programmeRequestStatus}
              requestMade={this.props.programmeRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.programmeResult}
              failureMessage={this.props.programmeError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.programmeRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.programmeErrorDetails}
            />
          )}

          {this.props.showSupportReferralNotification && (
            <RequestFeedback
              className={
                this.props.showNotification ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.supportReferralRequestStatus}
              requestMade={this.props.supportReferralRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.supportReferralResult}
              failureMessage={this.props.supportReferralError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.programmeRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.supportReferralErrorDetails}
            />
          )}

          <Divider hidden />

          <ModifyTask
            requestStatus={this.props.taskRequestStatus}
            mode={this.state.mode}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editTask
                : strings.header.viewTask
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnableDisableTaskModalVisibility={
              this.toggleEnableDisableTaskModalVisibility
            }
            toggleEnableDisableTaskApprovalModalVisibility={
              this.toggleEnableDisableTaskApprovalModalVisibility
            }
            selectedTaskData={this.props.selectedTaskData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            handleApprovalModal={this.handleApprovalModal}
            deleteModalOpen={this.state.deleteModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enableDisableTaskModalOpen={this.state.enableDisableTaskModalOpen}
            enableDisableTaskApprovalModalOpen={
              this.state.enableDisableTaskApprovalModalOpen
            }
            initialTaskData={this.state.initialTaskData}
            formInvalid={this.state.formInvalid}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            cancelSelectedTaskUpdate={this.props.cancelSelectedTaskUpdate}
            handleChangeModal={this.handleChangeModal}
            clearTaskReason={this.state.clearTaskReason}
            handleApprovalDropdown={this.handleApprovalDropdown}
            approvalSelection={this.state.approvalSelection}
            deleteReason={this.props.deleteReason}
            rejectionReason={this.state.rejectionReason}
            resetDataOnModalClose={this.resetDataOnModalClose}
            stringsObject={this.state.stringsObject}
            handleApprovalButton={this.handleApprovalButton}
            handleRejectionButton={this.handleRejectionButton}
            handleDeleteApprovalButton={this.handleDeleteApprovalButton}
          />
        </div>
      </div>
    );
  };
}

TaskEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedTaskData: PropTypes.object.isRequired,
  updateSelectedTask: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  getTaskById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  deleteTask: PropTypes.func.isRequired,
  updateAmendmentPermissionStatus: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    tasks,
    amendments,
    referrals,
    customers,
    programmes,
    supportReferrals
  } = state;
  const {
    loadingPage,
    selectedTaskData,
    taskRequestStatus,
    taskRequestMade,
    result,
    error,
    errorDetails,
    showNotification
  } = tasks;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails,
    deleteReason,
    showNotification: showAmendmentNotification
  } = amendments;
  const {
    selectedReferralData,
    loadingPage: referralLoadingPage,
    referralRequestStatus,
    referralRequestMade,
    result: referralResult,
    error: referralError,
    errorDetails: referralErrorDetails,
    showNotification: showReferralNotification
  } = referrals;
  const {
    loadingPage: customerLoadingPage,
    customerRequestStatus,
    customerRequestMade,
    result: customerResult,
    error: customerError,
    errorDetails: customerErrorDetails,
    showNotification: showCustomerNotification
  } = customers;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage,
    programmeRequestStatus,
    programmeRequestMade,
    result: programmeResult,
    error: programmeError,
    errorDetails: programmeErrorDetails,
    showNotification: showProgrammeNotification
  } = programmes;
  const {
    loadingPage: supportReferralLoadingPage,
    supportReferralRequestStatus,
    supportReferralRequestMade,
    result: supportReferralResult,
    error: supportReferralError,
    errorDetails: supportReferralErrorDetails,
    showNotification: showSupportReferralNotification
  } = supportReferrals;

  return {
    loadingPage,
    selectedTaskData,
    taskRequestStatus,
    taskRequestMade,
    result,
    error,
    errorDetails,
    deleteReason,
    showNotification,
    referralLoadingPage,
    referralRequestStatus,
    referralRequestMade,
    referralResult,
    referralError,
    referralErrorDetails,
    showReferralNotification,
    requestId,
    permissionStatus,
    supportReferralLoadingPage,
    supportReferralRequestStatus,
    supportReferralRequestMade,
    supportReferralResult,
    supportReferralError,
    supportReferralErrorDetails,
    showSupportReferralNotification,
    amendmentRequestStatus,
    amendmentRequestMade,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails,
    showAmendmentNotification,
    customerLoadingPage,
    customerRequestStatus,
    customerRequestMade,
    customerResult,
    customerError,
    showCustomerNotification,
    customerErrorDetails,
    programmeLoadingPage,
    programmeRequestStatus,
    programmeRequestMade,
    programmeResult,
    programmeError,
    programmeErrorDetails,
    showProgrammeNotification,
    selectedProgrammeData,
    selectedReferralData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTask: (id, data, headers, initialTaskData) => {
      dispatch(taskActions.updateTask(id, data, headers, initialTaskData));
    },
    getTaskById: async (id, headers) => {
      await dispatch(taskActions.getTaskById(id, headers));
    },
    updateSelectedTask: (key, value) => {
      dispatch(taskActions.updateSelectedTask(key, value));
    },
    cancelSelectedTaskUpdate: data => {
      dispatch(taskActions.cancelSelectedTaskUpdate(data));
    },
    clearTaskRequest: () => {
      dispatch(taskActions.clearTaskRequest());
    },
    deleteTask: async (id, clearReason, headers) => {
      await dispatch(taskActions.deleteTask(id, clearReason, headers));
    },
    updateAmendmentPermissionStatus: async (
      id,
      approvalStatus,
      headers,
      showNotification,
      rejectionReason = null
    ) => {
      await dispatch(
        amendmentActions.updateAmendmentPermissionStatus(
          id,
          approvalStatus,
          headers,
          showNotification,
          rejectionReason
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    setAmendmentStatusActioned: (id, headers) => {
      dispatch(amendmentActions.setAmendmentStatusActioned(id, headers));
    },
    getReferralById: async (id, headers, isSupportReferralTask) => {
      await dispatch(
        referralActions.getReferralById(id, headers, isSupportReferralTask)
      );
    },
    createReferral: (data, headers) => {
      dispatch(referralActions.createReferral(data, headers));
    },
    updateReferral: (
      id,
      data,
      headers,
      initialReferralData,
      showNotification = true,
      isApproved,
      isSupportReferralTask
    ) => {
      dispatch(
        referralActions.updateReferral(
          id,
          data,
          headers,
          initialReferralData,
          showNotification,
          isApproved,
          isSupportReferralTask
        )
      );
    },
    updateSupportReferral: (
      id,
      data,
      headers,
      initialSupportReferralData,
      showNotification = true,
      isSupportReferralTask
    ) => {
      dispatch(
        supportReferralActions.updateSupportReferral(
          id,
          data,
          headers,
          initialSupportReferralData,
          showNotification,
          isSupportReferralTask
        )
      );
    },
    updateCustomer: (
      id,
      data,
      headers,
      initialCustomerData,
      showNotification = true,
      isApproved
    ) => {
      dispatch(
        customerActions.updateCustomer(
          id,
          data,
          headers,
          initialCustomerData,
          showNotification,
          isApproved
        )
      );
    },
    getProgrammeById: async (id, headers, isSupportReferralTask) => {
      await dispatch(
        programmeActions.getProgrammeById(id, headers, isSupportReferralTask)
      );
    },
    updateProgramme: (
      id,
      data,
      headers,
      initialProgrammeData,
      showNotification = true,
      isApproved,
      isSupportReferralTask
    ) => {
      dispatch(
        programmeActions.updateProgramme(
          id,
          data,
          headers,
          initialProgrammeData,
          showNotification,
          isApproved,
          isSupportReferralTask
        )
      );
    },

    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    clearReferralRequest: () => {
      dispatch(referralActions.clearReferralRequest());
    },
    clearProgrammeRequest: () => {
      dispatch(programmeActions.clearProgrammeRequest());
    },
    clearCustomerRequest: () => {
      dispatch(customerActions.clearCustomerRequest());
    },
    clearSupportReferralRequest: () => {
      dispatch(supportReferralActions.clearSupportReferralRequest());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskEditContainer);
