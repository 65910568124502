import React from "react";
import ModifyTtgEngagement from "../../components/ModifyTtgEngagement";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { ttgEngagementActions } from "../../store/actions/ttgEngagement.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { authActions } from "../../../App/store/actions/auth.actions";
import { auditActions } from "../../../Audit/store/actions/audit.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";
import {
  convertToIso,
  getUsers,
  incrementObject,
  handleLogout,
  textFormattingHelpers
} from "../../helpers";

class TtgEngagementEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableTtgEngagementModalOpen: false,
    initialTtgEngagementData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    ttgEngagementType: this.props.match.params.ttgEngagementType,
    addActivities: false,
    shouldSaveFiles: false,
    hasFileChanges: false,
    deletionReason: undefined,
    fetchedPermissionStatus: false,
    permissionStatus: "",
    userList: [],
    showAuditLog: false,
    addNew: false
  };

  componentDidMount = async () => {
    await this.handleFetchData();
    try {
      await this.props.getTtgEngagementById(
        this.props.match.params.id,
        this.props.headers
      );
      if (!incrementObject(this.props.match.params.id)) {
        this.props.lockUser(this.props.header);
        handleLogout(
          this.props.userId,
          this.props.username,
          this.props.headers,
          this.props.createAudit,
          this.props.logout
        );
      }
    } catch (ex) {
      throw ex;
    } finally {
      if (
        this.state.mode === "view" &&
        this.props.selectedTtgEngagementData.requestId
      ) {
        await this.props.getDeletePermissionStatus(
          this.props.selectedTtgEngagementData.requestId
        );
        await this.setState(
          { permissionStatus: this.props.permissionStatus },
          async () => {
            if (this.state.permissionStatus === strings.states.rejected) {
              await this.props.updateSelectedTtgEngagement("requestId", null);
              this.props.updateTtgEngagement(
                this.props.selectedTtgEngagementData.ttgEngagementId,
                this.props.selectedTtgEngagementData,
                this.props.headers,
                this.state.initialTtgEngagementData,
                false
              );
            }
          }
        );
        this.props.clearAmendmentRequest();
      }

      this.setState({
        fetchedPermissionStatus: true,
        initialTtgEngagementData: this.props.selectedTtgEngagementData,
        userList: getUsers(this.props.userListData)
      });

      let form = document.getElementById("ttgEngagementForm");
      if (form) {
        form.setAttribute("novalidate", true);
        //set the form to have the novalidate attribute to suppress the default html validation tooltips
      }
    }
  };

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    sCrit.query = "ttg";
    sCrit.fields = ["area.normalizer"];
    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedTtgEngagementData.ttgEngagementId,
      this.state.deletionReason.value,
      strings.amendments.fullName,
      this.props.selectedTtgEngagementData.crmId,
      this.props.headers,
      this.props.selectedTtgEngagementData
    );
    this.props.updateSelectedTtgEngagement("requestId", this.props.requestId);
    this.props.updateTtgEngagement(
      this.props.selectedTtgEngagementData.ttgEngagementId,
      this.props.selectedTtgEngagementData,
      this.props.headers,
      this.state.initialTtgEngagementData
    );
    this.setState({
      initialTtgEngagementData: this.props.selectedTtgEngagementData
    });

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearTtgEngagementRequest();
    });
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteTtgEngagement(
      this.props.selectedTtgEngagementData.ttgEngagementId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      await this.props.setAmendmentStatusActioned(
        this.props.selectedTtgEngagementData.requestId,
        this.props.headers,
        true,
        {
          entity: strings.amendments.entity,
          id: this.props.selectedTtgEngagementData.ttgEngagementId,
          type: strings.amendments.deletion
        }
      );

      this.props.history.push("/ttgEngagements");
    }

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearTtgEngagementRequest();
    });
  };

  componentWillUnmount() {
    this.props.clearTtgEngagementRequest();
    this.props.clearSelectedTtgEngagement();
    this.props.clearAmendmentRequest();
  }

  validateFormAndAddNew = async () => {
    this.setState({ addNew: true });
    this.validateForm();
  };

  validateForm = async () => {
    let details = JSON.parse(
      JSON.stringify(this.props.selectedTtgEngagementData)
    );
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.ttgEngagements.modifyTtgEngagement
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  filesHaveChanged = () => {
    this.setState({ hasFileChanges: true });
  };

  handleSubmit = async () => {
    this.setState({ shouldSaveFiles: true });

    const data = textFormattingHelpers.decodeData(
      this.props.selectedTtgEngagementData,
      this.state.initialTtgEngagementData,
      textFields
    );

    await this.props.updateTtgEngagement(
      this.props.match.params.id,
      data,
      this.props.headers,
      this.state.initialTtgEngagementData
    );

    this.setState({ shouldSaveFiles: false });
    if (this.state.addNew) {
      this.props.history.push(
        `/ttgEngagements/create/${this.props.selectedTtgEngagementData.needId}`
      );
    } else {
      this.handleModeSwitch();
    }
    window.setTimeout(() => {
      this.props.clearTtgEngagementRequest();
    }, 10000);
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedTtgEngagement(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedTtgEngagement(data.name, data.value);
    }
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedTtgEngagementData.active
    };
    await this.props.updateTtgEngagement(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialTtgEngagementData
    );
  };

  handleToggleAudit = () => {
    const isAudit = this.state.showAuditLog;
    this.setState({ showAuditLog: !isAudit });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;

      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableTtgEngagementModalVisibility = () => {
    this.setState({
      enableDisableTtgEngagementModalOpen: !this.state
        .enableDisableTtgEngagementModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialTtgEngagementData) !==
        JSON.stringify(this.props.selectedTtgEngagementData)
      ) {
        await this.props.cancelSelectedTtgEngagementUpdate(
          this.state.initialTtgEngagementData
        );
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialTtgEngagementData: JSON.parse(
          JSON.stringify(this.props.selectedTtgEngagementData)
        )
      });
    }
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      !this.state.fetchedPermissionStatus ||
      this.props.amendmentLoadingPage;

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.requestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          {this.props.showNotification && (
            <RequestFeedback
              className={
                this.props.amendmentRequestStatus ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.ttgEngagementRequestStatus}
              requestMade={this.props.ttgEngagementRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.ttgEngagementRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyTtgEngagement
            mode={this.state.mode}
            requestStatus={this.props.ttgEngagementRequestStatus}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editTtgEngagement
                : strings.header.viewTtgEngagement
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnableDisableTtgEngagementModalVisibility={
              this.toggleEnableDisableTtgEngagementModalVisibility
            }
            selectedTtgEngagementData={this.props.selectedTtgEngagementData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enableDisableTtgEngagementModalOpen={
              this.state.enableDisableTtgEngagementModalOpen
            }
            initialTtgEngagementData={this.state.initialTtgEngagementData}
            formInvalid={this.state.formInvalid}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            shouldSaveFiles={this.state.shouldSaveFiles}
            filesHaveChanged={this.filesHaveChanged}
            hasFileChanges={this.state.hasFileChanges}
            headers={this.props.headers}
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            userList={this.state.userList}
            role={this.props.roleId}
            handleToggleAudit={this.handleToggleAudit}
            showAuditLog={this.state.showAuditLog}
            validateFormAndAddNew={this.validateFormAndAddNew}
          />
        </div>
      </div>
    );
  };
}

TtgEngagementEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedTtgEngagementData: PropTypes.object.isRequired,
  updateSelectedTtgEngagement: PropTypes.func.isRequired,
  updateTtgEngagement: PropTypes.func.isRequired,
  getTtgEngagementById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cancelSelectedTtgEngagementUpdate: PropTypes.func,
  clearSelectedTtgEngagement: PropTypes.func,
  clearTtgEngagementRequest: PropTypes.func,
  deleteSuccessful: PropTypes.any,
  deleteTtgEngagement: PropTypes.func,
  error: PropTypes.string,
  getDeletePermissionStatus: PropTypes.func,
  getUsers: PropTypes.func,
  permissionStatus: PropTypes.string,
  requestDeletePermission: PropTypes.func,
  requestId: PropTypes.any,
  result: PropTypes.string,
  roleId: PropTypes.string,
  setAmendmentStatusActioned: PropTypes.func,
  showNotification: PropTypes.bool,
  ttgEngagementRequestMade: PropTypes.bool,
  ttgEngagementRequestStatus: PropTypes.bool,
  userListData: PropTypes.array
};

const mapStateToProps = state => {
  const { ttgEngagements, auth, amendments, users } = state;
  const {
    loadingPage,
    selectedTtgEngagementData,
    ttgEngagementRequestStatus,
    ttgEngagementRequestMade,
    result,
    error,
    errorDetails,
    deleteSuccessful,
    showNotification
  } = ttgEngagements;
  const { roleId, userId, username } = auth;
  const { userListData } = users;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails,
    loadingPage: amendmentLoadingPage
  } = amendments;
  return {
    loadingPage,
    selectedTtgEngagementData,
    ttgEngagementRequestStatus,
    ttgEngagementRequestMade,
    result,
    error,
    roleId,
    errorDetails,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    userListData,
    amendmentRequestStatus,
    amendmentRequestMade,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails,
    userId,
    username,
    amendmentLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTtgEngagement: async (
      id,
      data,
      headers,
      initialTtgEngagementData,
      showNotification = true
    ) => {
      await dispatch(
        ttgEngagementActions.updateTtgEngagement(
          id,
          data,
          headers,
          initialTtgEngagementData,
          showNotification
        )
      );
    },
    getTtgEngagementById: async (id, headers) => {
      await dispatch(ttgEngagementActions.getTtgEngagementById(id, headers));
    },
    updateSelectedTtgEngagement: (key, value) => {
      dispatch(ttgEngagementActions.updateSelectedTtgEngagement(key, value));
    },
    cancelSelectedTtgEngagementUpdate: data => {
      dispatch(ttgEngagementActions.cancelSelectedTtgEngagementUpdate(data));
    },
    clearTtgEngagementRequest: () => {
      dispatch(ttgEngagementActions.clearTtgEngagementRequest());
    },
    clearSelectedTtgEngagement: () => {
      dispatch(ttgEngagementActions.clearSelectedTtgEngagement());
    },

    requestDeletePermission: async (
      id,
      reason,
      entity,
      recordIdentifier,
      headers,
      body
    ) => {
      await dispatch(
        amendmentActions.requestDeletePermission(
          id,
          reason,
          entity,
          recordIdentifier,
          headers,
          body
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteTtgEngagement: async (id, headers) => {
      await dispatch(ttgEngagementActions.deleteTtgEngagement(id, headers));
    },
    setAmendmentStatusActioned: async (
      id,
      headers,
      sendNotification,
      entityDetails
    ) => {
      await dispatch(
        amendmentActions.setAmendmentStatusActioned(
          id,
          headers,
          sendNotification,
          entityDetails
        )
      );
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    lockUser: headers => {
      dispatch(userActions.lockUser(headers));
    },
    logout: hasExceeded => {
      dispatch(authActions.signOut(hasExceeded));
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TtgEngagementEditContainer);
