import React from "react";
import ModifyCost from "../../components/ModifyCost";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { costActions } from "../../store/actions/cost.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import PropTypes from "prop-types";
import {
  convertToIso,
  getOrganisations,
  canUserModifyRecord
} from "../../helpers";
import uuidv4 from "uuid/v4";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";

class CostCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableCostModalOpen: false,
    formInvalid: false,
    validationResults: {},
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    isPrePopulated: false,
    hasPageLoaded: false
  };

  componentDidMount = async () => {
    this.props.updateSelectedCost("costId", uuidv4());
    this.props.getProgrammeById(localStorage.getItem("programmeId"));
    await this.handleFetchData();
    let form = document.getElementById("costForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    await this.props.updateSelectedCost(
      "programmeId",
      localStorage.getItem("programmeId")
    );
    this.props.updateSelectedCost("issuedBy", this.props.userId);
    this.props.updateSelectedCost(
      "issuedByOrg",
      this.props.organisationOfLoggedInUser
    );
    this.setState({ hasPageLoaded: true });
  };

  componentDidUpdate = () => {
    if (
      Object.keys(this.props.selectedProgrammeData).length > 0 &&
      !this.state.isPrePopulated
    ) {
      if (this.props.selectedProgrammeData.owner) {
        this.props.updateSelectedCost(
          "owner",
          this.props.selectedProgrammeData.owner
        );
      }
      if (this.props.selectedProgrammeData.area) {
        this.props.updateSelectedCost(
          "area",
          this.props.selectedProgrammeData.area
        );
      }
      if (this.props.selectedProgrammeData.firstName) {
        this.props.updateSelectedCost(
          "firstName",
          this.props.selectedProgrammeData.firstName
        );
      }
      if (this.props.selectedProgrammeData.surname) {
        this.props.updateSelectedCost(
          "surname",
          this.props.selectedProgrammeData.surname
        );
      }
      if (this.props.selectedProgrammeData.pNumber) {
        this.props.updateSelectedCost(
          "pNumber",
          this.props.selectedProgrammeData.pNumber
        );
      }
      if (this.props.selectedProgrammeData.scroNumber) {
        this.props.updateSelectedCost(
          "scroNumber",
          this.props.selectedProgrammeData.scroNumber
        );
      }

      this.setState({ isPrePopulated: true });
    }
  };

  componentWillUnmount() {
    this.props.clearSelectedCost();
  }

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      true
    );
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedCostData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.costs.modifyCost
    );

    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    await this.props.createCost(
      this.props.selectedCostData,
      this.props.headers
    );
    this.props.history.push("/costs");
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearCostRequest();
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedCost(data.name, convertToIso(data.value));
    } else {
      await this.props.updateSelectedCost(data.name, data.value);
    }
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableCostModalVisibility = () => {
    this.setState({
      enableDisableCostModalOpen: !this.state.enableDisableCostModalOpen
    });
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.programmeLoadingPage ||
      this.props.orgsLoadingPage ||
      this.props.usersLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <ModifyCost
            mode="create"
            pageTitle={strings.header.createCost}
            selectedCostData={this.props.selectedCostData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            enableDisableCostModalOpen={this.state.enableDisableCostModalOpen}
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableCostModalVisibility={
              this.toggleEnableDisableCostModalVisibility
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            initialCostData={{}}
            getOrganisations={this.getOrganisations}
            hasPageLoaded={this.state.hasPageLoaded}
            headers={this.props.headers}
            area={this.props.selectedProgrammeData.area}
            userList={this.props.userListData}
            isDisabled={this.isDisabled()}
          />
        </div>
      </div>
    );
  };
}

CostCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedCostData: PropTypes.object.isRequired,
  updateSelectedCost: PropTypes.func.isRequired,
  clearSelectedCost: PropTypes.func.isRequired,
  createCost: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { costs, auth, organisations, referrals, programmes, users } = state;
  const { loadingPage, selectedCostData } = costs;
  const { organisationOfLoggedInUser, userId } = auth;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const { selectedReferralData, referralRequestStatus } = referrals;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  const { userListData, loadingPage: usersLoadingPage } = users;

  return {
    loadingPage,
    selectedCostData,
    organisationListData,
    selectedReferralData,
    selectedProgrammeData,
    referralRequestStatus,
    userListData,
    organisationOfLoggedInUser,
    userId,
    programmeLoadingPage,
    orgsLoadingPage,
    usersLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createCost: async (data, headers) => {
      await dispatch(costActions.createCost(data, headers));
    },
    updateSelectedCost: (key, value) => {
      dispatch(costActions.updateSelectedCost(key, value));
    },
    clearSelectedCost: () => {
      dispatch(costActions.clearSelectedCost());
    },
    clearCostRequest: () => {
      dispatch(costActions.clearCostRequest());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CostCreateContainer);
