import { API } from "../../services";
import { assessmentSGActionTypes } from "./assessmentSG.actionTypes";

const api = new API();

const createAssessmentSG = (details, headers) => {
  const request = details => {
    return {
      type: assessmentSGActionTypes.CREATE_ASSESSMENTSG_REQUEST,
      details
    };
  };
  const success = response => {
    return {
      type: assessmentSGActionTypes.CREATE_ASSESSMENTSG_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: assessmentSGActionTypes.CREATE_ASSESSMENTSG_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "createAssessmentSG" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };
    return new Promise((resolve, reject) => {
      api.createAssessmentSG(body).then(
        response => {
          dispatch(success(response));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const clearAssessmentSGRequest = () => {
  const request = () => {
    return { type: assessmentSGActionTypes.CLEAR_CREATE_ASSESSMENTSG_REQUEST };
  };

  const success = () => {
    return { type: assessmentSGActionTypes.CLEAR_CREATE_ASSESSMENTSG_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateAssessmentSG = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return {
      type: assessmentSGActionTypes.UPDATE_ASSESSMENTSG_REQUEST,
      details
    };
  };
  const success = response => {
    return {
      type: assessmentSGActionTypes.UPDATE_ASSESSMENTSG_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: assessmentSGActionTypes.UPDATE_ASSESSMENTSG_FAILURE,
      error,
      initialDetails
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "updateAssessmentSG" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };

    api.updateAssessmentSG(id, body).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const deleteAssessmentSG = (id, headers) => {
  const request = () => {
    return { type: assessmentSGActionTypes.DELETE_ASSESSMENTSG_REQUEST };
  };
  const success = response => {
    return {
      type: assessmentSGActionTypes.DELETE_ASSESSMENTSG_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: assessmentSGActionTypes.DELETE_ASSESSMENTSG_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteAssessmentSG" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteAssessmentSG(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getAssessmentSG = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly
) => {
  const request = () => {
    return { type: assessmentSGActionTypes.GET_ASSESSMENTSG_REQUEST };
  };
  const success = response => {
    return {
      type: assessmentSGActionTypes.GET_ASSESSMENTSG_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: assessmentSGActionTypes.GET_ASSESSMENTSG_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getAssessmentSG" });

  return dispatch => {
    dispatch(request());
    if (clear) {
      // If supposed to clear, replicate blank result
      dispatch(success({ hits: { hits: [] } }));
    } else {
      api
        .getAssessmentSG(queryString, size, from, fields, sort, activeOnly)
        .then(
          response => {
            dispatch(success(response));
          },
          error => dispatch(failure(error))
        );
    }
  };
};

const getAssessmentSGByProgramme = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly,
  programmeId,
  type
) => {
  const request = () => {
    return {
      type: assessmentSGActionTypes.GET_ASSESSMENTSG_BY_PROGRAMME_REQUEST
    };
  };
  const success = response => {
    return {
      type: assessmentSGActionTypes.GET_ASSESSMENTSG_BY_PROGRAMME_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: assessmentSGActionTypes.GET_ASSESSMENTSG_BY_PROGRAMME_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "getAssessmentSGByProgramme"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (clear) {
        // If supposed to clear, replicate blank result
        dispatch(success({ hits: { hits: [] } }));
        resolve({ hits: { hits: [] } });
      } else {
        api
          .getAssessmentSGByProgramme(
            queryString,
            size,
            from,
            fields,
            sort,
            activeOnly,
            programmeId,
            type
          )
          .then(
            response => {
              dispatch(success(response));
              resolve(response);
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          );
      }
    });
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly,
  programmeId,
  type
) => {
  const request = () => {
    return { type: assessmentSGActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: assessmentSGActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };
  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly,
    programmeId: programmeId
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    if (programmeId) {
      dispatch(
        getAssessmentSGByProgramme(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          programmeId,
          type
        )
      );
    } else {
      dispatch(
        getAssessmentSG(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    }
  };
};

const getAssessmentSGById = (id, headers) => {
  const request = () => {
    return { type: assessmentSGActionTypes.GET_ASSESSMENTSG_BY_ID_REQUEST };
  };
  const success = response => {
    return {
      type: assessmentSGActionTypes.GET_ASSESSMENTSG_BY_ID_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: assessmentSGActionTypes.GET_ASSESSMENTSG_BY_ID_FAILURE,
      error
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getAssessmentSGById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getAssessmentSGById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedAssessmentSG = (key, value) => {
  const request = (key, value) => {
    return {
      type: assessmentSGActionTypes.UPDATE_SELECTED_ASSESSMENTSG_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: assessmentSGActionTypes.UPDATE_SELECTED_ASSESSMENTSG_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return {
      type: assessmentSGActionTypes.UPDATE_SELECTED_ASSESSMENTSG_FAILURE
    };
  };

  return dispatch => {
    dispatch(request(key, value));
    return new Promise((resolve, reject) => {
      if (key) {
        dispatch(success(key, value));
        resolve()
      } else {
        dispatch(failure());
        reject()
      }
    })
  };
};

const cancelSelectedAssessmentSGUpdate = initialAssessmentSGData => {
  const request = () => {
    return {
      type: assessmentSGActionTypes.CANCEL_SELECTED_ASSESSMENTSG_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type: assessmentSGActionTypes.CANCEL_SELECTED_ASSESSMENTSG_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialAssessmentSGData));
  };
};

const clearSelectedAssessmentSG = () => {
  const request = () => {
    return {
      type: assessmentSGActionTypes.CLEAR_SELECTED_ASSESSMENTSG_REQUEST
    };
  };
  const success = () => {
    return {
      type: assessmentSGActionTypes.CLEAR_SELECTED_ASSESSMENTSG_SUCCESS
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

export const assessmentSGActions = {
  createAssessmentSG,
  updateAssessmentSG,
  deleteAssessmentSG,
  getAssessmentSGById,
  getAssessmentSG,
  updateSearchCriteria,
  updateSelectedAssessmentSG,
  clearAssessmentSGRequest,
  cancelSelectedAssessmentSGUpdate,
  getAssessmentSGByProgramme,
  clearSelectedAssessmentSG
};
