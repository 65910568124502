export const organisationTypeOptions = [
  { key: 1, text: "Support Service", value: "Support Service" },
  { key: 2, text: "Employer", value: "Employer" },
  { key: 3, text: "End to End Partner", value: "End to End Partner" },
  { key: 4, text: "Support Partner", value: "Support Partner" },
  { key: 5, text: "Lead Partner", value: "Lead Partner" }
];

export const localAuthorityAreaOptions = [
  { key: 1, text: "Aberdeen City", value: "Aberdeen City" },
  { key: 2, text: "Aberdeenshire", value: "Aberdeenshire" },
  { key: 3, text: "Angus", value: "Angus" },
  { key: 4, text: "Argyll & Bute", value: "Argyll & Bute" },
  { key: 5, text: "Clackmannanshire", value: "Clackmannanshire" },
  { key: 6, text: "Dumfries & Galloway", value: "Dumfries & Galloway" },
  { key: 7, text: "Dundee City", value: "Dundee City" },
  { key: 8, text: "East Ayrshire", value: "East Ayrshire" },
  { key: 9, text: "East Dunbartonshire", value: "East Dunbartonshire" },
  { key: 10, text: "East Lothian", value: "East Lothian" },
  { key: 11, text: "East Renfrewshire", value: "East Renfrewshire" },
  { key: 12, text: "Edinburgh City", value: "Edinburgh City" },
  { key: 13, text: "Falkirk", value: "Falkirk" },
  { key: 14, text: "Fife", value: "Fife" },
  { key: 15, text: "Glasgow City", value: "Glasgow City" },
  { key: 16, text: "Highland", value: "Highland" },
  { key: 17, text: "Inverclyde", value: "Inverclyde" },
  { key: 18, text: "Midlothian", value: "Midlothian" },
  { key: 19, text: "Moray", value: "Moray" },
  { key: 20, text: "North Ayrshire", value: "North Ayrshire" },
  { key: 21, text: "North Lanarkshire", value: "North Lanarkshire" },
  { key: 22, text: "Orkney", value: "Orkney" },
  { key: 24, text: "Perth & Kinross", value: "Perth & Kinross" },
  { key: 25, text: "Renfrewshire", value: "Renfrewshire" },
  { key: 26, text: "Scottish Borders", value: "Scottish Borders" },
  { key: 27, text: "Shetland Islands", value: "Shetland Islands" },
  { key: 28, text: "South Ayrshire", value: "South Ayrshire" },
  { key: 29, text: "South Lanarkshire", value: "South Lanarkshire" },
  { key: 30, text: "Stirling", value: "Stirling" },
  { key: 31, text: "West Dunbartonshire", value: "West Dunbartonshire" },
  { key: 32, text: "West Lothian", value: "West Lothian" },
  { key: 33, text: "Western Isles", value: "Western Isles" }
];

export const prisonOptions = [
  { key: 1, text: "HMP Addiewell", value: "HMP Addiewell" },
  { key: 2, text: "HMP Barlinnie", value: "HMP Barlinnie" },
  { key: 3, text: "HMP Dumfries", value: "HMP Dumfries" },
  { key: 4, text: "HMP Edinburgh", value: "HMP Edinburgh" },
  { key: 5, text: "HMP Glenochil", value: "HMP Glenochil" },
  { key: 6, text: "HMP Grampian", value: "HMP Grampian" },
  { key: 7, text: "HMP Greenock", value: "HMP Greenock" },
  { key: 8, text: "HMP Inverness", value: "HMP Inverness" },
  { key: 9, text: "HMP Perth", value: "HMP Perth" },
  { key: 10, text: "HMP Kilmarnock", value: "HMP Kilmarnock" },
  { key: 11, text: "HMP Polmont", value: "HMP Polmont" },
  { key: 12, text: "HMP Low Moss", value: "HMP Low Moss" },
  { key: 13, text: "HMP Castle Huntly", value: "HMP Castle Huntly" }
];

export const mainCategoryOptions = [
  { key: 1, text: "Mental Health", value: "Mental Health" },
  { key: 2, text: "Physical Health", value: "Physical Health" },
  { key: 3, text: "Substance Misuse", value: "Substance Misuse" },
  { key: 4, text: "Accommodation", value: "Accommodation" },
  { key: 5, text: "Relationships", value: "Relationships" },
  { key: 6, text: "Finance", value: "Finance" },
  { key: 7, text: "Employment", value: "Employment" },
  { key: 8, text: "Education & Training", value: "Education & Training" },
  { key: 9, text: "Attitudes & Behaviours", value: "Attitudes & Behaviours" },
  { key: 10, text: "Social & Leisure", value: "Social & Leisure" }
];

export const sicCodeOptions = [
  { key: 1, text: "A - Agriculture, Forestry and Fishing", value: "A" },
  { key: 2, text: "B - Mining and Quarrying", value: "B" },
  { key: 3, text: "C - Manufacturing", value: "C" },
  {
    key: 4,
    text: "D - Electricity, Gas, Steam and Air Conditioning Supply",
    value: "D"
  },
  {
    key: 5,
    text:
      "E - Water Supply; Sewerage, Waste Management and Remediation Activities",
    value: "E"
  },
  { key: 6, text: "F - Construction", value: "F" },
  {
    key: 7,
    text:
      "G - Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles",
    value: "G"
  },
  { key: 8, text: "H - Transportation and Storage", value: "H" },
  { key: 9, text: "I - Accommodation and Food Service Acitivies", value: "I" },
  { key: 10, text: "J - Information and Communication", value: "J" },
  { key: 11, text: "K - Financial and Insurance Activities", value: "K" },
  { key: 12, text: "L - Real Estate Activities", value: "L" },
  {
    key: 13,
    text: "M - Professional, Scientific and Technical Activities",
    value: "M"
  },
  {
    key: 14,
    text: "N - Administrative and Support Service Activities",
    value: "N"
  },
  {
    key: 15,
    text: "O - Public Administration and Defence; Compulsory Social Security",
    value: "O"
  },
  { key: 16, text: "P - Education", value: "P" },
  { key: 17, text: "Q - Human Health and Social Work Activities", value: "Q" },
  { key: 18, text: "R - Arts, Entertainment and Recreation", value: "R" },
  { key: 19, text: "S - Other Service Activities", value: "S" },
  {
    key: 20,
    text:
      "T - Activities of Households as Employers; Undifferentiated Goods-And-Services Producing Activities of Households for Own Use",
    value: "T"
  },
  {
    key: 21,
    text: "U - Activities of Extraterritorial Organisations and Bodies",
    value: "U"
  }
];

export const socCodeOptions = [
  { key: 1, text: "1 - Managers, Directors and Senior Officials", value: "1" },
  { key: 2, text: "2 - Professional Occupations", value: "2" },
  {
    key: 3,
    text: "3 - Associate Professional and Technical Occupations",
    value: "3"
  },
  {
    key: 4,
    text: "4 - Administrative and Secretarial Occupations",
    value: "4"
  },
  { key: 5, text: "5 - Skilled Trades Occupations", value: "5" },
  {
    key: 6,
    text: "6 - Caring, Leisure and Other Service Occupations",
    value: "6"
  },
  { key: 7, text: "7 - Sales and Customer Service Occupations", value: "7" },
  { key: 8, text: "8 - Process, Plant and Machine Operatives", value: "8" },
  { key: 9, text: "9 - Elementary Occupations", value: "9" }
];
