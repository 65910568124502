/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const newEntry = /* GraphQL */ `
  subscription NewEntry($userId: String!) {
    newEntry(userId: $userId) {
      id
      created
      userId
      details
      entity
    }
  }
`;
