import axios from "./axios-supportReferrals";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createSupportReferral = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  };

  updateSupportReferral = (id, details, isSupportReferralTask) => {
    const config = {
      headers: this.headers,
      params: {
        isSupportReferralTask: isSupportReferralTask
      }
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating supportReferral: ", error.message);
          reject(error);
        });
    });
  };

  deleteSupportReferral = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, null, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting supportReferral: ", error.message);
          reject(error);
        });
    });
  };

  getSupportReferrals = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    programmeId,
    referralId
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly,
      programmeId: programmeId,
      referralId: referralId
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/list-supportReferrals/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting supportReferrals: ", error.message);
          reject(error);
        });
    });
  };

  getSupportReferralById = id => {
    const config = {
      headers: {
        ...this.headers,
        referral: localStorage.getItem("referralId")
          ? localStorage.getItem("referralId")
          : localStorage.getItem("parentReferralId"),
        programme: localStorage.getItem("programmeId")
      }
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting supportReferral: ", error.message);
          reject(error);
        });
    });
  };
}
