import React from "react";
import { Button, Grid, Message } from "semantic-ui-react";
import { strings } from "../resources";
import PropTypes from "prop-types";
import { ShowIfAuthorised } from ".";
import { listObjectValues } from "../helpers/objectHelpers";
import { connect } from "react-redux";

class ComponentHeader extends React.Component {
  render = () => {
    const validationMessage = (
      <Message negative className="ValidationMessage">
        <h4>{strings.error.validationMessage}</h4>
        {this.props.validationResults &&
          listObjectValues(this.props.validationResults).map((result, idx) => {
            if (
              (typeof result[0] === "object" && !Array.isArray(result[0])) ||
              (typeof result === "object" && !Array.isArray(result))
            ) {
              return <li key={idx}>{strings.error.miscError}</li>;
            } else {
              return <li key={idx}>{result}</li>;
            }
          })}
      </Message>
    );
    let buttonClassName = "redirectButton";
    if (
      this.props.returnToList ||
      this.props.returnToParent ||
      Array.isArray(this.props.parentPage)
    ) {
      buttonClassName += " redirectButtonUnderline";
    }

    const stickyHeaderClass = this.props.sticky
      ? this.props.sidebarOpen
        ? "stickyHeader stickyHeader--sidebarOpen"
        : "stickyHeader stickyHeader--sidebarClosed"
      : "";

    return (
      <>
        <Grid
          columns={2}
          stackable
          className={`ComponentHeader ${stickyHeaderClass}`}
        >
          <Grid.Column width={4}>
            <div className="pageHeader">
              {this.props.parentPage &&
                (typeof this.props.parentPage === "string" ? (
                  <>
                    <Button
                      className={buttonClassName}
                      onClick={this.props.returnToParent}
                    >
                      {this.props.parentPage}
                    </Button>
                    <div className="headingDivider">/</div>
                  </>
                ) : (
                  this.props.parentPage.map((page, key) => (
                    <React.Fragment key={key}>
                      <Button
                        className={
                          page.className ? page.className : buttonClassName
                        }
                        onClick={page.returnToParent}
                      >
                        {page.parentPage}
                      </Button>
                      <div className="headingDivider">/</div>
                    </React.Fragment>
                  ))
                ))}
              {this.props.headerList && (
                <>
                  <Button
                    className={buttonClassName}
                    onClick={this.props.returnToList}
                  >
                    {this.props.headerList}
                  </Button>
                  <div className="headingDivider">/</div>
                </>
              )}
              {this.props.pageTitle}
              {this.props.journeyCount &&
                strings.header.journey + this.props.journeyCount}
            </div>
          </Grid.Column>
          <Grid.Column className="FormButtons" width={12}>
            {this.props.mode === "view" && this.props.auditLog}
            {this.props.permissionsAmend && (
              <ShowIfAuthorised allowedRoles={this.props.permissionsAmend}>
                {this.props.mode === "view" && this.props.amendmentButton}
              </ShowIfAuthorised>
            )}
            {this.props.permissionsDelete && (
              <ShowIfAuthorised allowedRoles={this.props.permissionsDelete}>
                {this.props.mode === "view" && this.props.deleteButton}
              </ShowIfAuthorised>
            )}

            <ShowIfAuthorised
              allowedRoles={
                this.props.permissionsActivate
                  ? [
                      ...this.props.permissionsUpdate,
                      ...this.props.permissionsActivate
                    ]
                  : [...this.props.permissionsUpdate]
              }
            >
              {this.props.reportRun && this.props.renderSaveCSVButton}

              {this.props.mode === "view" && this.props.additionalButtons}

              {this.props.mode === "view" && this.props.modifyButtons}

              {this.props.mode === "edit" && this.props.cancelSaveButtons}
            </ShowIfAuthorised>
            {this.props.permissionsAmend && (
              <ShowIfAuthorised allowedRoles={this.props.permissionsAmend}>
                {this.props.mode === "amend" &&
                  this.props.amendmentCancelSaveButtons}
              </ShowIfAuthorised>
            )}

            <ShowIfAuthorised allowedRoles={this.props.permissionsCreate}>
              {this.props.mode === "create" && this.props.cancelSaveButtons}
            </ShowIfAuthorised>
          </Grid.Column>
        </Grid>
        <div>{this.props.formInvalid && validationMessage}</div>
      </>
    );
  };
}

ComponentHeader.propTypes = {
  returnToParent: PropTypes.func,
  parentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  returnToList: PropTypes.func,
  pageTitle: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  modifyButtons: PropTypes.object.isRequired,
  cancelSaveButtons: PropTypes.object.isRequired,
  headerList: PropTypes.string,
  permissionsUpdate: PropTypes.array.isRequired,
  permissionsCreate: PropTypes.array.isRequired,
  permissionsActivate: PropTypes.array,
  journeyCount: PropTypes.number,
  additionalButtons: PropTypes.object
};

const mapStateToProps = state => {
  const { sidebarOpen } = state.app;

  return { sidebarOpen };
};
export default connect(mapStateToProps)(ComponentHeader);
