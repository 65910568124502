import React from "react";
import { Button, Icon, Form, Accordion } from "semantic-ui-react";
import { BasicModal } from "../";
import PropTypes from "prop-types";
import { convertFromIso } from "../../helpers/dateHelpers";

import {
  ValidatedFormInput,
  ValidatedDateInput,
  ValidatedFormDropdown,
  ValidatedFormTextArea
} from ".";

function ValidatedMultipleEngagements({
  strings,
  methodOptions,
  yesNoOptions,
  formInvalid,
  engagements,
  handleEngagementChange,
  validationResults,

  mode,
  handleModalConfirm,
  deleteEngagementModalOpen,
  toggleDeleteEngagementModalVisibility
}) {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  return (
    <>
      {engagements &&
        engagements.map((engagement, idx) => {
          const dateOfEngagementName = `dateOfEngagement-${idx}`,
            methodName = `method-${idx}`,
            successfulEngagementName = `successfulEngagement-${idx}`,
            whyNotSuccessfulName = `whyNotSuccessful-${idx}`,
            engagementDetailsName = `engagementDetails-${idx}`,
            hasCustomerProgressedName = `hasCustomerProgressed-${idx}`;

          return (
            <React.Fragment key={engagement.id}>
              <Accordion>
                <Accordion.Title
                  active={activeIndex === idx}
                  index={idx}
                  onClick={handleClick}
                >
                  <div className="infoHeader" style={{ overflow: "auto" }}>
                    <Icon name="dropdown" />
                    {`${
                      strings.form.header.supportReferralEngagementProgress
                    }: ${
                      engagements[idx].dateOfEngagement
                        ? convertFromIso(engagements[idx].dateOfEngagement)
                        : ""
                    }`}
                    {engagements.length > 0 && (
                      <Button
                        primary
                        style={{ float: "right" }}
                        onClick={e => {
                          e.stopPropagation();
                          toggleDeleteEngagementModalVisibility(engagement.id);
                        }}
                      >
                        <Icon name="trash" />
                      </Button>
                    )}
                  </div>
                </Accordion.Title>

                <Accordion.Content active={activeIndex === idx}>
                  <div className="infoWrapper">
                    <Form.Group>
                      <ValidatedDateInput
                        inputId={dateOfEngagementName}
                        formInvalid={formInvalid}
                        inputLabel={strings.form.label.dateOfEngagement}
                        inputName={dateOfEngagementName}
                        inputPlaceholder={
                          strings.form.placeholder.dateOfEngagement
                        }
                        inputDateFormat="DD/MM/YYYY"
                        inputData={
                          engagements[idx].dateOfEngagement
                            ? convertFromIso(engagements[idx].dateOfEngagement)
                            : ""
                        }
                        iconPosition="left"
                        required
                        formFieldWidth={8}
                        handleChange={handleEngagementChange}
                        validationResult={
                          validationResults.engagements
                            ? validationResults.engagements[idx]
                              ? validationResults.engagements[idx]
                                  .dateOfEngagement
                              : undefined
                            : undefined
                        }
                        mode={mode}
                      />
                      <ValidatedFormDropdown
                        formInvalid={formInvalid}
                        inputData={engagements[idx].method}
                        viewData={engagements[idx].method}
                        dropdownLabel={strings.form.label.method}
                        dropdownName={methodName}
                        dropdownOptions={methodOptions}
                        dropdownPlaceholder={strings.form.placeholder.method}
                        formFieldWidth={8}
                        handleChange={handleEngagementChange}
                        validationResult={
                          validationResults.engagements
                            ? validationResults.engagements[idx]
                              ? validationResults.engagements[idx].method
                              : undefined
                            : undefined
                        }
                        mode={mode}
                      />
                    </Form.Group>
                    <Form.Group>
                      <ValidatedFormDropdown
                        formInvalid={formInvalid}
                        inputData={engagements[idx].successfulEngagement}
                        viewData={engagements[idx].successfulEngagement}
                        dropdownLabel={strings.form.label.successfulEngagement}
                        dropdownName={successfulEngagementName}
                        dropdownOptions={yesNoOptions}
                        dropdownPlaceholder={
                          strings.form.placeholder.successfulEngagement
                        }
                        formFieldWidth={8}
                        handleChange={handleEngagementChange}
                        validationResult={
                          validationResults.engagements
                            ? validationResults.engagements[idx]
                              ? validationResults.engagements[idx]
                                  .successfulEngagement
                              : undefined
                            : undefined
                        }
                        mode={mode}
                        required={true}
                      />
                      <ValidatedFormInput
                        formInvalid={formInvalid}
                        inputData={engagements[idx].whyNotSuccessful}
                        inputLabel={strings.form.label.whyNotSuccessful}
                        inputName={whyNotSuccessfulName}
                        inputPlaceholder={
                          strings.form.placeholder.whyNotSuccessful
                        }
                        formFieldWidth={8}
                        handleChange={handleEngagementChange}
                        validationResult={
                          validationResults.engagements
                            ? validationResults.engagements[idx]
                              ? validationResults.engagements[idx]
                                  .whyNotSuccessful
                              : undefined
                            : undefined
                        }
                        mode={mode}
                        required={
                          engagements[idx].successfulEngagement ===
                          strings.form.text.no
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <ValidatedFormTextArea
                        formInvalid={formInvalid}
                        inputData={engagements[idx].engagementDetails}
                        inputLabel={strings.form.label.engagementDetails}
                        inputName={engagementDetailsName}
                        inputPlaceholder={
                          strings.form.placeholder.engagementDetails
                        }
                        formFieldWidth={8}
                        handleChange={handleEngagementChange}
                        validationResult={
                          validationResults.engagements
                            ? validationResults.engagements[idx]
                              ? validationResults.engagements[idx]
                                  .engagementDetails
                              : undefined
                            : undefined
                        }
                        mode={mode}
                        required={
                          engagements[idx].successfulEngagement ===
                          strings.form.text.yes
                        }
                      />
                      <ValidatedFormDropdown
                        formInvalid={formInvalid}
                        inputData={engagements[idx].hasCustomerProgressed}
                        viewData={engagements[idx].hasCustomerProgressed}
                        dropdownLabel={strings.form.label.hasCustomerProgressed}
                        dropdownName={hasCustomerProgressedName}
                        dropdownOptions={yesNoOptions}
                        dropdownPlaceholder={
                          strings.form.placeholder.hasCustomerProgressed
                        }
                        formFieldWidth={8}
                        handleChange={handleEngagementChange}
                        validationResult={
                          validationResults.engagements
                            ? validationResults.engagements[idx]
                              ? validationResults.engagements[idx]
                                  .hasCustomerProgressed
                              : undefined
                            : undefined
                        }
                        mode={mode}
                        required={
                          engagements[idx].successfulEngagement ===
                          strings.form.text.yes
                        }
                      />
                    </Form.Group>
                  </div>
                </Accordion.Content>
              </Accordion>
            </React.Fragment>
          );
        })}
      <BasicModal
        confirmMessage={strings.modal.deleteEngagementMessage}
        handleModalConfirm={handleModalConfirm}
        modalClassName="deleteEngagementModal"
        modalOpen={deleteEngagementModalOpen}
        showNoButton={true}
        showOkButton={false}
        showYesButton={true}
        toggleModal={toggleDeleteEngagementModalVisibility}
        noLabel={strings.button.noLabel}
        yesLabel={strings.button.yesLabel}
        okLabel={strings.button.okLabel}
      />
    </>
  );
}

ValidatedMultipleEngagements.propTypes = {
  addEngagement: PropTypes.func.isRequired,
  removeEngagement: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired,
  deleteEngagementModalOpen: PropTypes.bool.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  handleEngagementChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  mode: PropTypes.string,
  toggleDeleteEngagementModalVisibility: PropTypes.func.isRequired,
  validationResults: PropTypes.object
};

export default ValidatedMultipleEngagements;
