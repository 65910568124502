import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";

import { ValidatedFormTextArea } from "../validation";
import "../css/ProgrammeComponents.scss";

const HobbiesFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">{strings.form.header.hobbiesHeader}</div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedFormTextArea
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hobbiesAndInterests}
          inputLabel={strings.form.label.hobbiesAndInterests}
          inputName="hobbiesAndInterests"
          inputPlaceholder={strings.form.placeholder.hobbiesAndInterests}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.hobbiesAndInterests}
          required={false}
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
      </Form.Group>
    </div>
  </div>
);

HobbiesFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default HobbiesFields;
