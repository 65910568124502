export const userActionTypes = {
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

  CLEAR_CREATE_USER_REQUEST: "CLEAR_CREATE_USER_REQUEST",
  CLEAR_CREATE_USER_FAILURE: "CLEAR_CREATE_USER_FAILURE",
  CLEAR_CREATE_USER_SUCCESS: "CLEAR_CREATE_USER_SUCCESS",

  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",

  UPDATE_SELECTED_USER_DATA_REQUEST: "UPDATE_SELECTED_USER_DATA_REQUEST",
  UPDATE_SELECTED_USER_DATA_SUCCESS: "UPDATE_SELECTED_USER_DATA_SUCCESS",
  UPDATE_SELECTED_USER_DATA_FAILURE: "UPDATE_SELECTED_USER_DATA_FAILURE",

  UPDATE_SELECTED_USER_REQUEST: "UPDATE_SELECTED_USER_REQUEST",
  UPDATE_SELECTED_USER_SUCCESS: "UPDATE_SELECTED_USER_SUCCESS",
  UPDATE_SELECTED_USER_FAILURE: "UPDATE_SELECTED_USER_FAILURE",

  CLEAR_SELECTED_USER_REQUEST: "CLEAR_SELECTED_USER_REQUEST",
  CLEAR_SELECTED_USER_SUCCESS: "CLEAR_SELECTED_USER_SUCCESS",
  CLEAR_SELECTED_USER_FAILURE: "CLEAR_SELECTED_USER_FAILURE",

  CANCEL_SELECTED_USER_UPDATE_REQUEST: "CANCEL_SELECTED_USER_UPDATE_REQUEST",
  CANCEL_SELECTED_USER_UPDATE_SUCCESS: "CANCEL_SELECTED_USER_UPDATE_SUCCESS",
  CANCEL_SELECTED_USER_UPDATE_FAILURE: "CANCEL_SELECTED_USER_UPDATE_FAILURE",

  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  GET_USER_BY_ID_REQUEST: "GET_USER_BY_ID_REQUEST",
  GET_USER_BY_ID_SUCCESS: "GET_USER_BY_ID_SUCCESS",
  GET_USER_BY_ID_FAILURE: "GET_USER_BY_ID_FAILURE",

  GET_PRISONMENTOR_BY_ID_REQUEST: "GET_PRISONMENTOR_BY_ID_REQUEST",
  GET_PRISONMENTOR_BY_ID_SUCCESS: "GET_PRISONMENTOR_BY_ID_SUCCESS",
  GET_PRISONMENTOR_BY_ID_FAILURE: "GET_PRISONMENTOR_BY_ID_FAILURE",

  GENERATE_USER_ID_REQUEST: "GENERATE_USER_ID_REQUEST",
  GENERATE_USER_ID_SUCCESS: "GENERATE_USER_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE",

  CLEAR_SELECTED_PRISONMENTOR_REQUEST: "CLEAR_SELECTED_PRISONMENTOR_REQUEST",
  CLEAR_SELECTED_PRISONMENTOR_SUCCESS: "CLEAR_SELECTED_PRISONMENTOR_SUCCESS",
  CLEAR_SELECTED_PRISONMENTOR_FAILURE: "CLEAR_SELECTED_PRISONMENTOR_FAILURE",

  LOCK_USER_REQUEST: "LOCK_USER_REQUEST",
  LOCK_USER_SUCCESS: "LOCK_USER_SUCCESS",
  LOCK_USER_FAILURE: "LOCK_USER_FAILURE"
};
