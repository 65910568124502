import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import { strings, outcomeOptions } from "../resources";
import {
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormTextArea,
  ValidatedFormInput
} from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from ".";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { dropdownHelper, convertFromIso } from "../helpers";
import { AuditListContainer } from "../../Audit/containers";

class ModifyNews extends React.Component {
  returnToList = () => {
    this.props.history.push("/news");
  };

  returnToParent = () => {
    this.props.history.push(
      "/programmes/view/" + localStorage.getItem("programmeId")
    );
  };

  renderModifyButtons = () => {
    const { handleModeSwitch, isDisabled } = this.props;

    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      ></Input>
    );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful &&
          (!isDisabled ? (
            <>
              <Button.Group>
                <Button
                  className="editButton"
                  primary
                  onClick={() => {
                    handleModeSwitch("edit");
                  }}
                  disabled={this.props.showAuditLog}
                >
                  {strings.button.editLabel}
                </Button>
              </Button.Group>
            </>
          ) : (
            <span className="errorText">
              {strings.form.errorText.notShared}
            </span>
          ))}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={this.props.toggleEnableDisableNewsModalVisibility}
                type="button"
                disabled={this.props.selectedNewsData.requestId}
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableNewsModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={this.props.toggleEnableDisableNewsModalVisibility}
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )}
      </div>
    );
  };
  renderCancelSaveButtons = () => {
    const {
      selectedNewsData,
      handleModeSwitch,
      handleModalConfirm,
      toggleCancelChangesModalVisibility,
      toggleConfirmSaveModalVisibility,
      initialNewsData,
      validateForm,
      confirmSaveModalOpen,
      mode,
      cancelChangesModalOpen,
      isDisabled
    } = this.props;

    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={validateForm}
            disabled={
              JSON.stringify(selectedNewsData) ===
                JSON.stringify(initialNewsData) || isDisabled
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
            disabled={isDisabled}
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };

  renderDeleteButton = () => {
    const { isDisabled } = this.props;

    return (
      <div className="containerButtons">
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={this.props.toggleEnableDisableNewsModalVisibility}
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteNewsMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableNewsModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={this.props.toggleEnableDisableNewsModalVisibility}
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderAuditButton = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="auditButton"
            onClick={() => {
              this.props.handleToggleAudit();
            }}
          >
            {!this.props.showAuditLog
              ? strings.button.auditLabel
              : strings.button.formLabel}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderForm = () => {
    const {
      selectedNewsData,
      handleChange,
      mode,
      formInvalid,
      validationResults
    } = this.props;

    return (
      <>
        {mode !== "create" && (
          <div className="infoBlock">
            <div className="infoHeader">
              {strings.form.header.administration}
            </div>
            <div className="infoWrapper">
              <Form.Group>
                <ValidatedFormInput
                  formInvalid={formInvalid}
                  inputData={selectedNewsData.crmId}
                  inputLabel={strings.form.label.crmId}
                  inputName="crmId"
                  inputPlaceholder={strings.form.placeholder.crmId}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.crmId}
                  disabled={mode === "edit"}
                  mode={mode}
                />
              </Form.Group>
            </div>
          </div>
        )}
        <div className="infoBlock">
          <div className="infoHeader">{strings.form.header.newsDetails}</div>
          <div className="infoWrapper">
            <Form.Group>
              <ValidatedDateInput
                inputId="date"
                formInvalid={formInvalid}
                inputLabel={strings.form.label.date}
                inputName="date"
                inputPlaceholder={strings.form.placeholder.date}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  selectedNewsData.date
                    ? convertFromIso(selectedNewsData.date)
                    : ""
                }
                iconPosition="left"
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.date}
                mode={mode}
                required={true}
              />
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedNewsData.outcome}
                viewData={
                  dropdownHelper.getNameFromValues(
                    selectedNewsData.outcome,
                    outcomeOptions
                  ) || ""
                }
                dropdownLabel={strings.form.label.outcome}
                dropdownName="outcome"
                dropdownOptions={outcomeOptions}
                dropdownPlaceholder={strings.form.placeholder.outcome}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.outcome}
                mode={mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormTextArea
                formInvalid={formInvalid}
                inputData={selectedNewsData.notes}
                inputName="notes"
                inputLabel={strings.form.label.notes}
                inputPlaceholder={strings.form.placeholder.notes}
                formFieldWidth={8}
                handleChange={handleChange}
                required={false}
                mode={mode}
              />
            </Form.Group>
          </div>
        </div>
      </>
    );
  };

  render = () => {
    const { mode, formInvalid, pageTitle, requestStatus } = this.props;

    let parentPage = localStorage.getItem("programmeName")
      ? localStorage.getItem("programmeName")
      : undefined;

    return (
      <div className="container">
        <ComponentHeader
          parentPage={parentPage}
          returnToParent={this.returnToParent}
          returnToList={this.returnToList}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderModifyButtons()}
          cancelSaveButtons={this.renderCancelSaveButtons()}
          auditLog={this.renderAuditButton()}
          deleteButton={this.renderDeleteButton()}
          headerList={strings.header.newsList}
          permissionsUpdate={permissions.news.update}
          permissionsCreate={permissions.news.create}
          permissionsDelete={permissions.news.delete}
          formInvalid={formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />
        {this.props.showAuditLog ? (
          <AuditListContainer
            embedded={true}
            headers={this.props.headers}
            history={this.props.history}
            entityId={this.props.selectedNewsData.newsId}
            hideSearchBar={true}
            record={true}
          />
        ) : (
          <Form id="newsForm" className="stickyForm">
            <fieldset disabled={mode === "view"}>{this.renderForm()}</fieldset>
          </Form>
        )}
      </div>
    );
  };
}

ModifyNews.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedNewsData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  initialNewsData: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleModalInputChange: PropTypes.func
};

export default withRouter(ModifyNews);
