import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      entity: "dummyRecord",
      deletion: "deletion",
      fullName: "Dummy Record"
    },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    header: {
      dummyRecordList: "Dummy Records",
      dummyRecords: "Dummy Records",
      createDummyRecord: "Create New Dummy Record",
      editDummyRecord: "Edit Dummy Record",
      viewDummyRecord: "Dummy Record Details"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      feedback: {
        processing: "Processing request...",
        dummyRecordRequestSuccess: "DummyRecord created successfully!",
        dummyRecordRequestRefreshPrompt:
          "Use the refresh function to see an updated dummyRecords list.",
        dummyRecordRequestFailure: "DummyRecord creation failed.",
        dummyRecordRequestUnknown:
          "Something went wrong with the dummyRecord request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        no: "No"
      }
    },
    modal: {
      createDummyRecordMessage: "Save dummyRecord?",
      modifyDummyRecordMessage: "Are you sure you want to save these changes?",
      deleteDummyRecordMessage:
        "Are you sure you want to delete this dummyRecord? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this dummyRecord's status to active?",
      setInactiveMessage: "Set this dummyRecord's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addDummyRecordLabel: "New Dummy Record",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    tooltip: {},
    table: {
      header: {
        cjSpecialist: "CJ Specialist",
        created: "Date Created",
        crmId: "CRM ID",
        journeyStatus: "Journey Status"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    entity: {
      dummy: "dummy"
    },
    folder: {
      dummyFiles: "dummyFiles"
    }
  }
});
