import React from "react";
import { Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { assessmentSGActions } from "../../store/actions/assessmentSG.actions";
import PropTypes from "prop-types";
import AssessmentSGCreateContainer from "../AssessmentSGCreateContainer/AssessmentSGCreateContainer";
import AssessmentSGEditContainer from "../AssessmentSGEditContainer/AssessmentSGEditContainer";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { strings } from "../../resources";

class AssessmentSGHandler extends React.Component {
  state = {
    globalProgrammeId: localStorage.getItem("programmeId"),
    type: "SGA",
    searchCriteria: {
      size: 20,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false,
      type: "SGA"
    },
    programmeArea: undefined
  };

  componentDidMount = async () => {
    await this.props.getProgrammeById(
      this.state.globalProgrammeId,
      this.props.headers
    );
    this.setState({ programmeArea: this.props.selectedProgrammeData.area });
    if (!localStorage.getItem("programmeId")) {
      this.props.history.goBack();
    }
    const sCrit = this.state.searchCriteria;
    sCrit.programmeId = this.state.globalProgrammeId;
    await this.props.getAssessmentSGByProgramme(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      sCrit.from,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly,
      sCrit.programmeId,
      sCrit.type
    );
  };

  componentWillUnmount() {
    this.props.clearSelectedAssessmentSG();
    this.props.clearProgrammeRequest();
  }

  render = () => {
    let assessmentSG = null;
    if (this.props.isFullyLoaded && this.state.programmeArea) {
      if (this.state.programmeArea === strings.areas.NRS) {
        if (!this.props.assessmentId) {
          assessmentSG = (
            <AssessmentSGCreateContainer
              programmeId={this.state.globalProgrammeId}
              history={this.props.history}
              headers={this.props.headers}
              selectedAssessmentSGData={{}}
            />
          );
        } else if (this.props.isFullyLoaded) {
          assessmentSG = (
            <AssessmentSGEditContainer
              programmeId={this.state.globalProgrammeId}
              id={this.props.assessmentId}
              history={this.props.history}
              headers={this.props.headers}
            />
          );
        }
      } else {
        assessmentSG = (
          <Segment basic inverted>
            <p>{strings.error.wrongArea}</p>
          </Segment>
        );
      }
    } else {
      assessmentSG = null;
    }
    return assessmentSG;
  };
}
AssessmentSGHandler.propTypes = {
  loadingPage: PropTypes.bool.isRequired,
  getAssessmentSGByProgramme: PropTypes.func.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { assessmentSG, auth, programmes } = state;
  const { roleId } = auth;
  const { selectedProgrammeData } = programmes;
  const {
    assessmentId,
    loadingPage,
    assessmentSGRequestStatus,
    assessmentSGRequestMade,
    result,
    error,
    totalResults,
    errorDetails,
    isFullyLoaded
  } = assessmentSG;
  return {
    assessmentId,
    loadingPage,
    assessmentSGRequestStatus,
    assessmentSGRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails,
    isFullyLoaded,
    selectedProgrammeData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAssessmentSGByProgramme: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      programmeId,
      type
    ) => {
      await dispatch(
        assessmentSGActions.getAssessmentSGByProgramme(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          programmeId,
          type
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      programmeId,
      type
    ) => {
      dispatch(
        assessmentSGActions.updateSearchCriteria(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          programmeId,
          type
        )
      );
    },
    clearSelectedAssessmentSG: () => {
      dispatch(assessmentSGActions.clearSelectedAssessmentSG());
    },
    getProgrammeById: async (id, headers) => {
      await dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearProgrammeRequest: () => {
      dispatch(programmeActions.clearProgrammeRequest());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentSGHandler);
