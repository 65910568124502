import React from "react";
import ModifyBaseline from "../../components/ModifyBaseline";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { baselineActions } from "../../store/actions/baseline.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { authActions } from "../../../App/store/actions/auth.actions";
import { auditActions } from "../../../Audit/store/actions/audit.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  convertToIso,
  canUserModifyRecord,
  getOrganisations,
  incrementObject,
  handleLogout
} from "../../helpers";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";

class BaselineEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableBaselineModalOpen: false,
    initialBaselineData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    deletionReason: undefined,
    fetchedPermissionStatus: false,
    permissionStatus: "",
    hasCancelled: false,
    showAuditLog: false
  };

  componentDidMount = async () => {
    const sCrit = this.state.searchCriteria;

    await this.props.getBaselineById(this.props.id, this.props.headers);
    if (!incrementObject(this.props.id)) {
      this.props.lockUser(this.props.header);
      handleLogout(
        this.props.userId,
        this.props.username,
        this.props.headers,
        this.props.createAudit,
        this.props.logout
      );
    }
    this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    if (
      this.state.mode === "view" &&
      this.props.selectedBaselineData.requestId
    ) {
      await this.props.getDeletePermissionStatus(
        this.props.selectedBaselineData.requestId
      );
      await this.setState(
        { permissionStatus: this.props.permissionStatus },
        async () => {
          if (this.state.permissionStatus === strings.states.rejected) {
            await this.props.updateSelectedBaseline("requestId", null);
            this.props.updateBaseline(
              this.props.selectedBaselineData.baselineId,
              this.props.selectedBaselineData,
              this.props.headers,
              this.state.initialBaselineData,
              false
            );
          }
        }
      );
      window.setTimeout(() => {
        this.props.clearAmendmentRequest();
        this.props.clearBaselineRequest();
      }, 10000);
    }
    this.setState({
      fetchedPermissionStatus: true,
      globalProgrammeId: localStorage.getItem("programmeId"),
      initialBaselineData: JSON.parse(
        JSON.stringify(this.props.selectedBaselineData)
      )
    });
    let form = document.getElementById("engagementForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    await this.props.getProgrammeById(
      this.state.globalProgrammeId,
      this.props.headers
    );
  };

  componentWillUnmount() {
    this.props.clearBaselineRequest();
    this.props.clearSelectedBaseline();
    this.props.clearAmendmentRequest();
  }

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedBaselineData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.assessments.modifyBA
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    await this.props.updateBaseline(
      this.props.selectedBaselineData.assessmentId,
      this.props.selectedBaselineData,
      this.props.headers,
      this.state.initialBaselineData
    );
    this.setState({
      initialBaselineData: JSON.parse(
        JSON.stringify(this.props.selectedBaselineData)
      )
    });
    this.handleModeSwitch();

    window.setTimeout(() => {
      this.props.clearBaselineRequest();
    }, 10000);
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedBaselineData.assessmentId,
      this.state.deletionReason.value,
      strings.amendments.fullName,
      this.props.selectedBaselineData.crmId,
      this.props.headers,
      this.props.selectedBaselineData
    );
    this.props.updateSelectedBaseline("requestId", this.props.requestId);
    this.props.updateBaseline(
      this.props.selectedBaselineData.assessmentId,
      this.props.selectedBaselineData,
      this.props.headers,
      this.state.initialBaselineData
    );
    this.setState({
      initialBaselineData: JSON.parse(
        JSON.stringify(this.props.selectedBaselineData)
      )
    });

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearBaselineRequest();
    }, 10000);
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteBaseline(
      this.props.selectedBaselineData.assessmentId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      this.props.setAmendmentStatusActioned(
        this.props.selectedBaselineData.requestId
      );

      this.props.history.push(
        `/programmes/view/${localStorage.getItem("programmeId")}`
      );
    }

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearBaselineRequest();
    });
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedBaseline(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedBaseline(data.name, data.value);
    }
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedBaselineData.active
    };
    await this.props.updateBaseline(
      this.props.id,
      body,
      this.props.headers,
      this.state.initialBaselineData
    );
  };

  handleToggleAudit = () => {
    const isAudit = this.state.showAuditLog;
    this.setState({ showAuditLog: !isAudit });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableBaselineModalVisibility = () => {
    this.setState({
      enableDisableBaselineModalOpen: !this.state.enableDisableBaselineModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialBaselineData) !==
        JSON.stringify(this.props.selectedBaselineData)
      ) {
        await this.props.cancelSelectedBaselineUpdate(
          this.state.initialBaselineData
        );
        this.setState({ hasCancelled: true });
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialBaselineData: JSON.parse(
          JSON.stringify(this.props.selectedBaselineData)
        )
      });
    }
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  resetCancelledState = () => {
    this.setState(() => ({ hasCancelled: false }));
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      !this.state.fetchedPermissionStatus ||
      this.props.orgsLoadingPage ||
      this.props.programmeLoadingPage ||
      this.props.amendmentLoadingPage;

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.requestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          {this.props.showNotification && (
            <RequestFeedback
              className={
                this.props.amendmentRequestStatus ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.baselineRequestStatus}
              requestMade={this.props.baselineRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.baselineRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyBaseline
            mode={this.state.mode}
            role={this.props.roleId}
            requestStatus={this.props.baselineRequestStatus}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editBaseline
                : strings.header.viewBaseline
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnableDisableBaselineModalVisibility={
              this.toggleEnableDisableBaselineModalVisibility
            }
            selectedBaselineData={this.props.selectedBaselineData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enableDisableBaselineModalOpen={
              this.state.enableDisableBaselineModalOpen
            }
            initialBaselineData={this.state.initialBaselineData}
            formInvalid={this.state.formInvalid}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            updateSelectedBaseline={this.props.updateSelectedBaseline}
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            isDisabled={this.isDisabled()}
            getOrganisations={this.getOrganisations}
            hasCancelled={this.state.hasCancelled}
            resetCancelledState={this.resetCancelledState}
            headers={this.props.headers}
            handleToggleAudit={this.handleToggleAudit}
            showAuditLog={this.state.showAuditLog}
          />
        </div>
      </div>
    );
  };
}

BaselineEditContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedBaselineData: PropTypes.object.isRequired,
  updateSelectedBaseline: PropTypes.func.isRequired,
  updateBaseline: PropTypes.func.isRequired,
  getBaselineById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { baselines, auth, amendments, programmes, organisations } = state;
  const {
    loadingPage,
    selectedBaselineData,
    baselineRequestStatus,
    baselineRequestMade,
    result,
    error,
    errorDetails,
    deleteSuccessful,
    showNotification
  } = baselines;
  const { loadingPage: orgsLoadingPage, organisationListData } = organisations;
  const { roleId, organisationOfLoggedInUser, userId, username } = auth;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails,
    loadingPage: amendmentLoadingPage
  } = amendments;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  return {
    loadingPage,
    selectedBaselineData,
    baselineRequestStatus,
    baselineRequestMade,
    result,
    error,
    roleId,
    errorDetails,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    selectedProgrammeData,
    organisationOfLoggedInUser,
    orgsLoadingPage,
    organisationListData,
    programmeLoadingPage,
    amendmentRequestStatus,
    amendmentRequestMade,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails,
    userId,
    username,
    amendmentLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateBaseline: (
      id,
      data,
      headers,
      initialBaselineData,
      showNotification = true
    ) => {
      dispatch(
        baselineActions.updateBaseline(
          id,
          data,
          headers,
          initialBaselineData,
          showNotification
        )
      );
    },
    getBaselineById: async (id, headers) => {
      await dispatch(baselineActions.getBaselineById(id, headers));
    },
    updateSelectedBaseline: (key, value) => {
      dispatch(baselineActions.updateSelectedBaseline(key, value));
    },
    cancelSelectedBaselineUpdate: data => {
      dispatch(baselineActions.cancelSelectedBaselineUpdate(data));
    },
    clearBaselineRequest: () => {
      dispatch(baselineActions.clearBaselineRequest());
    },
    clearSelectedBaseline: () => {
      dispatch(baselineActions.clearSelectedBaseline());
    },
    requestDeletePermission: async (
      id,
      reason,
      entity,
      recordIdentifier,
      headers,
      body
    ) => {
      await dispatch(
        amendmentActions.requestDeletePermission(
          id,
          reason,
          entity,
          recordIdentifier,
          headers,
          body
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteBaseline: async (id, headers) => {
      await dispatch(baselineActions.deleteBaseline(id, headers));
    },
    setAmendmentStatusActioned: (id, headers) => {
      dispatch(amendmentActions.setAmendmentStatusActioned(id, headers));
    },
    getProgrammeById: async (id, headers) => {
      await dispatch(programmeActions.getProgrammeById(id, headers));
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    lockUser: headers => {
      dispatch(userActions.lockUser(headers));
    },
    logout: hasExceeded => {
      dispatch(authActions.signOut(hasExceeded));
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaselineEditContainer);
