import { isEmpty } from "./objectHelpers";
import { strings } from "../../Referrals/resources";

const getListOfMentors = (
  area,
  userListData,
  organisation,
  refStats,
  assignedCommunityMentor,
  assignedPrisonMentor
) => {
  let communityMentorCaseLoads = refStats;
  //only add the ones of the correct role type to the list and are in the correct area.
  //If organisation is not set, return all, else return only ones of matching org
  let data = [];
  if (userListData) {
    userListData.forEach(result => {
      const userBody = result["_source"];
      if (userBody) {
        if (
          (hasAreaAndMentorRole(userBody, area) ||
            hasAreaAndPrisonBasedMentorRole(userBody, area) ||
            hasAreaAndTeamLeadRole(userBody, area) ||
            hasAreaAndManagerRole(userBody, area)) &&
          !organisation
        ) {
          data.push(userBody);
        } else if (
          (hasAreaAndMentorRole(userBody, area) &&
            hasValue(userBody, "organisationId", organisation)) ||
          hasAreaAndPrisonBasedMentorRole(userBody, area) ||
          hasAreaAndTeamLeadRole(userBody, area) ||
          hasAreaAndManagerRole(userBody, area)
        ) {
          data.push(userBody);
        }
      }
    });
  }

  if (
    !isEmpty(assignedCommunityMentor) &&
    !isEmpty(assignedPrisonMentor) &&
    assignedCommunityMentor.userId === assignedPrisonMentor.userId
  ) {
    data.push(assignedCommunityMentor);
  } else if (
    !isEmpty(assignedCommunityMentor) &&
    !isEmpty(assignedPrisonMentor)
  ) {
    data.push(assignedCommunityMentor);
    data.push(assignedPrisonMentor);
  } else if (!isEmpty(assignedCommunityMentor)) {
    data.push(assignedCommunityMentor);
  } else if (!isEmpty(assignedPrisonMentor)) {
    data.push(assignedPrisonMentor);
  }

  let dropdownFields = [];
  data.forEach((object, key) => {
    //format the data in an appropriate format for a dropdown list
    dropdownFields.push({
      key: key,
      text: object.firstName + " " + object.surname,
      value: object.userId,
      caseloadmax: object.caseloadMax,
      organisation: object.organisationId,
      prison: object.prison,
      localauthorityarea: object.localAuthorityArea,
      caseload: 0,
      roleid: object.roleId
    });
  });

  //add caseloads to the mentors
  if (
    dropdownFields &&
    dropdownFields.length >= 0 &&
    communityMentorCaseLoads &&
    communityMentorCaseLoads.length >= 0
  ) {
    dropdownFields.forEach(user => {
      communityMentorCaseLoads.forEach(communityMentor => {
        if (user.value === communityMentor.key) {
          user.caseload = communityMentor.doc_count;
        }
      });
    });
  }

  return dropdownFields;
};

const hasValue = (obj, key, value) => {
  return obj.hasOwnProperty(key) && obj[key] === value;
};

const hasAreaAndRole = (source, area, role) => {
  return hasValue(source, "area", area) && hasValue(source, "roleId", role);
};

const hasAreaAndMentorRole = (source, area) => {
  return hasAreaAndRole(source, area, strings.fieldValues.mentor);
};

const hasAreaAndTeamLeadRole = (source, area) => {
  return hasAreaAndRole(source, area, strings.fieldValues.teamLead);
};

const hasAreaAndManagerRole = (source, area) => {
  return hasAreaAndRole(source, area, strings.fieldValues.manager);
};

const hasAreaAndPrisonBasedMentorRole = (source, area) => {
  return hasAreaAndRole(source, area, strings.fieldValues.prisonBasedMentor)
}

export default getListOfMentors;
