import { supportReferralActionTypes } from "../actions/supportReferral.actionTypes";

const initialState = {
  loadingPage: false,
  selectedSupportReferralData: {
    engagements: []
  },
  supportReferralListData: [{}],
  totalResults: 0,
  supportReferralToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  supportReferralRequestStatus: undefined,
  supportReferralRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let details;
  let newTotal;
  switch (action.type) {
    case supportReferralActionTypes.CREATE_SUPPORTREFERRAL_REQUEST:
      return {
        ...state,
        supportReferralRequestStatus: undefined,
        supportReferralRequestMade: true
      };
    case supportReferralActionTypes.CREATE_SUPPORTREFERRAL_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        supportReferralRequestStatus: true
      };
    case supportReferralActionTypes.CREATE_SUPPORTREFERRAL_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        supportReferralRequestStatus: false
      };

    case supportReferralActionTypes.CLEAR_CREATE_SUPPORTREFERRAL_REQUEST:
      return { ...state };
    case supportReferralActionTypes.CLEAR_CREATE_SUPPORTREFERRAL_SUCCESS:
      return {
        ...state,
        supportReferralRequestMade: false,
        supportReferralRequestStatus: undefined
      };
    case supportReferralActionTypes.CLEAR_CREATE_SUPPORTREFERRAL_FAILURE:
      return { ...state };

    case supportReferralActionTypes.UPDATE_SUPPORTREFERRAL_REQUEST:
      return {
        ...state,
        loadingPage: true,
        supportReferralRequestStatus: undefined,
        supportReferralRequestMade: true
      };
    case supportReferralActionTypes.UPDATE_SUPPORTREFERRAL_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        supportReferralRequestStatus: true,
        selectedSupportReferralData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case supportReferralActionTypes.UPDATE_SUPPORTREFERRAL_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        supportReferralRequestStatus: false,
        selectedSupportReferralData: action.initialDetails,
        errorDetails: details
      };

    case supportReferralActionTypes.DELETE_SUPPORTREFERRAL_REQUEST:
      return {
        ...state,
        supportReferralRequestStatus: undefined,
        supportReferralRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case supportReferralActionTypes.DELETE_SUPPORTREFERRAL_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        supportReferralRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case supportReferralActionTypes.DELETE_SUPPORTREFERRAL_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        supportReferralRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case supportReferralActionTypes.GET_SUPPORTREFERRALS_REQUEST:
      return { ...state, loadingPage: true, deleteSuccessful: false };
    case supportReferralActionTypes.GET_SUPPORTREFERRALS_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newSupportReferral = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newSupportReferral;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        supportReferralListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case supportReferralActionTypes.GET_SUPPORTREFERRALS_FAILURE:
      return { ...state, supportReferralListData: [{}], loadingPage: false };

    case supportReferralActionTypes.GET_SUPPORTREFERRAL_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        supportReferralRequestStatus: undefined
      };
    case supportReferralActionTypes.GET_SUPPORTREFERRAL_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        supportReferralRequestStatus: true,
        selectedSupportReferralData: action.response.Body.Item,
        loadingPage: false
      };
    case supportReferralActionTypes.GET_SUPPORTREFERRAL_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedSupportReferralData: {},
        supportReferralRequestStatus: false,
        supportReferralRequestMade: true
      };

    case supportReferralActionTypes.UPDATE_SELECTED_SUPPORTREFERRAL_REQUEST:
      return { ...state };
    case supportReferralActionTypes.UPDATE_SELECTED_SUPPORTREFERRAL_SUCCESS:
      return {
        ...state,
        selectedSupportReferralData: {
          ...state.selectedSupportReferralData,
          [action.key]: action.value
        }
      };
    case supportReferralActionTypes.UPDATE_SELECTED_SUPPORTREFERRAL_FAILURE:
      return { ...state };

    case supportReferralActionTypes.CLEAR_SELECTED_SUPPORTREFERRAL_REQUEST:
      return { ...state };
    case supportReferralActionTypes.CLEAR_SELECTED_SUPPORTREFERRAL_SUCCESS:
      return { ...state, selectedSupportReferralData: {} };
    case supportReferralActionTypes.CLEAR_SELECTED_SUPPORTREFERRAL_FAILURE:
      return { ...state };

    case supportReferralActionTypes.CANCEL_SELECTED_SUPPORTREFERRAL_UPDATE_REQUEST:
      return { ...state };
    case supportReferralActionTypes.CANCEL_SELECTED_SUPPORTREFERRAL_UPDATE_SUCCESS:
      return { ...state, selectedSupportReferralData: action.data };
    case supportReferralActionTypes.CANCEL_SELECTED_SUPPORTREFERRAL_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};
