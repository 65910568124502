import { API } from "../../services";
import { caseloadActionTypes } from "./caseload.actionTypes";

const api = new API();

const getCaseloads = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly
) => {
  const request = () => {
    return { type: caseloadActionTypes.GET_CASELOADS_REQUEST };
  };
  const success = response => {
    return { type: caseloadActionTypes.GET_CASELOADS_SUCCESS, response };
  };
  const failure = error => {
    return { type: caseloadActionTypes.GET_CASELOADS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getCaseload" });

  return dispatch => {
    dispatch(request());
    if (clear) {
      // If supposed to clear, replicate blank result
      dispatch(success({ hits: { hits: [] } }));
    } else {
      api.getCaseloads(queryString, size, from, fields, sort, activeOnly).then(
        response => {
          dispatch(success(response));
        },
        error => dispatch(failure(error))
      );
    }
  };
};

const clearCaseloadRequest = () => {
  const request = () => {
    return { type: caseloadActionTypes.CLEAR_CREATE_CASELOAD_REQUEST };
  };

  const success = () => {
    return { type: caseloadActionTypes.CLEAR_CREATE_CASELOAD_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly
) => {
  const request = () => {
    return { type: caseloadActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: caseloadActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };
  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(
      getCaseloads(
        headers,
        queryString,
        size,
        from,
        clear,
        fields,
        sort,
        activeOnly
      )
    );
  };
};

export const caseloadActions = {
  getCaseloads,
  updateSearchCriteria,
  clearCaseloadRequest
};
