export const removeFromList = (response, entityDetails = {}) => {
  if (!response) {
    return response;
  }

  let hits, total;
  if (response.Data) {
    hits = response.Data.hits.hits;
    total = response.Data.hits.total;
  } else if (response.Body) {
    hits = response.Body.hits.hits;
    total = response.Body.hits.total;
  } else {
    return response;
  }

  const { id, type } = entityDetails;

  if (type === "deletion" && id && total > 0) {
    const index = hits.findIndex(row => row._id === id);
    if (typeof index === "number") {
      hits.splice(index, 1);
      total -= 1;
    }
  }
  localStorage.removeItem("entityDetails");

  return response;
};

export default {
  removeFromList
};
