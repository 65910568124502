import React from "react";
import {
  Button,
  Dimmer,
  Form,
  Loader,
  Segment,
  Popup,
  Icon
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { authActions } from "../../store/actions/auth.actions";
import { strings } from "../../resources";
import "./css/login.scss";
import logo from "../../assets/images/logo_dark.png";
import { Auth } from "aws-amplify";

class PasswordResetContainer extends React.Component {
  state = {
    code: "",
    email: "",
    password: "",
    codeSent: false,
    confirmed: false,
    confirmPassword: "",
    isConfirming: false,
    isSendingCode: false,
    error: ""
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isLoggedIn) {
      return { url: "/mywork" };
    } else {
      return { url: "" };
    }
  }

  componentDidUpdate() {
    if (this.state.url) {
      this.props.history.push(this.state.url);
    }
  }

  validateCodeForm() {
    return this.state.email.length > 0;
  }

  validatePasswordResetForm() {
    return (
      this.state.code.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = (event, data) => {
    if (data !== undefined) {
      this.setState({
        [data.name]: data.value
      });
    } else {
      console.log(strings.error.formField);
    }
  };

  handleRequestCode = async event => {
    event.preventDefault();
    try {
      this.setState({ isSendingCode: true });
      await Auth.forgotPassword(this.state.email);
      this.setState({ codeSent: true, error: "" });
    } catch (e) {
      console.log(e.message);
      this.setState({
        isSendingCode: false,
        error: strings.error.passwordCodeSent
      });
    }
  };

  handleConfirmCode = async event => {
    event.preventDefault();

    if (!this.validatePasswordResetForm()) {
      this.setState({ error: strings.error.passwordValidated });
      return;
    }

    this.setState({ isConfirming: true });
    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.code,
        this.state.password
      );
      this.setState({ confirmed: true });
    } catch (e) {
      console.log(e.message);
      this.setState({
        isConfirming: false,
        error: e.message
      });
    }
  };

  renderRequestCodeForm() {
    return (
      <Dimmer.Dimmable
        as={Segment}
        blurring
        dimmed={this.props.loggingIn}
        className="loginPanel"
      >
        <Dimmer inverted active={this.props.loggingIn}>
          <Loader size="large">Signing in..</Loader>
        </Dimmer>
        <Segment className="loginControls">
          <Form className="ui large form">
            <Form.Group>
              <Form.Input
                name="email"
                icon="user"
                iconPosition="left"
                onChange={this.handleChange}
                placeholder={strings.form.placeholder.email}
                required
                value={this.state.email || ""}
                width={16}
              />
            </Form.Group>
          </Form>
          {!this.state.codeSent && (
            <Button
              className="ui fluid submit button"
              id="LoginButton"
              content="Submit"
              onClick={this.handleRequestCode}
            />
          )}
          {this.state.error && (
            <div className="ui negative message">{this.state.error}</div>
          )}
        </Segment>
      </Dimmer.Dimmable>
    );
  }

  renderConfirmationForm() {
    return (
      <Dimmer.Dimmable as={Segment} blurring dimmed={this.props.loggingIn}>
        <Dimmer inverted active={this.props.loggingIn}>
          <Loader size="large">{strings.label.signingIn}</Loader>
        </Dimmer>
        <div>
          <Form className="ui large form">
            <div className="ui segment passwordReset">
              <p>
                Please check your email ({this.state.email}) for the
                confirmation code.
              </p>
              <Form.Group>
                <Form.Input
                  className="ui left icon input"
                  type="text"
                  name="code"
                  icon="hashtag"
                  iconPosition="left"
                  onChange={this.handleChange}
                  placeholder={strings.form.placeholder.confirmationCode}
                  required
                  value={this.state.code || ""}
                  width={16}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  className="ui left icon input"
                  type="password"
                  name="password"
                  icon="lock"
                  iconPosition="left"
                  onChange={this.handleChange}
                  placeholder={strings.form.placeholder.newPassword}
                  required
                  value={this.state.password || ""}
                  width={16}
                />
                <Popup
                  content={strings.tooltip.passwordFormatContent}
                  key="password"
                  header={strings.tooltip.passwordFormatHeader}
                  trigger={
                    <Icon className="resetPasswordTooltip" name="info circle" />
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  className="ui left icon input"
                  type="password"
                  name="confirmPassword"
                  icon="lock"
                  iconPosition="left"
                  onChange={this.handleChange}
                  placeholder={strings.form.placeholder.confirmPassword}
                  required
                  value={this.state.confirmPassword || ""}
                  width={16}
                />
              </Form.Group>
              <button
                className="ui fluid large submit button"
                id="LoginButton"
                onClick={this.handleConfirmCode}
              >
                {strings.button.submit}
              </button>
            </div>
            {this.state.error && (
              <div className="ui negative message">{this.state.error}</div>
            )}
          </Form>
        </div>
      </Dimmer.Dimmable>
    );
  }

  renderSuccessMessage() {
    return (
      <div className="success">
        <p>Your password has been reset.</p>
        <p>
          <Link to="/login">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className="Login">
        <div className="ui middle aligned center aligned grid">
          <div id="LoginColumn" className="column">
            <br />
            <h2 className="ui image header">
              <center>
                <div className="field">
                  <img
                    className="image"
                    src={logo}
                    alt={strings.label.twg}
                    width="80%"
                    height="80%"
                  />
                </div>
              </center>
              <br />
              <div className="loginTitle">{strings.label.passwordReset}</div>
            </h2>
            {!this.state.codeSent
              ? this.renderRequestCodeForm()
              : !this.state.confirmed
              ? this.renderConfirmationForm()
              : this.renderSuccessMessage()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  const { loggingIn, isLoggedIn, headers, error } = auth;

  return {
    loggingIn,
    isLoggedIn,
    headers,
    error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: (email, password) => {
      dispatch(authActions.signIn(email, password));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PasswordResetContainer)
);
