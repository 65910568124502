import React from "react";
import { connect } from "react-redux";
import { baselineActions } from "../../store/actions/baseline.actions";
import BaselineCreateContainer from "../BaselineCreateContainer/BaselineCreateContainer";
import BaselineEditContainer from "../BaselineEditContainer/BaselineEditContainer";

class BaselineHandler extends React.Component {
  state = {
    globalProgrammeId: localStorage.getItem("programmeId"),
    type: "BA",
    searchCriteria: {
      size: 20,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false,
      type: "BA"
    }
  };

  componentDidMount = async () => {
    if (!localStorage.getItem("programmeId")) {
      this.props.history.goBack();
    }
    const sCrit = this.state.searchCriteria;
    sCrit.programmeId = this.state.globalProgrammeId;
    await this.props.getBaselinesByProgramme(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      sCrit.from,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly,
      sCrit.programmeId,
      sCrit.type
    );
  };

  componentWillUnmount = () => {
    this.props.clearSelectedBaseline();
  };

  render = () => {
    let baselineContainer = null;
    if (!this.props.baselineId && this.props.isFullyLoaded) {
      baselineContainer = (
        <BaselineCreateContainer
          programmeId={this.state.globalProgrammeId}
          history={this.props.history}
          headers={this.props.headers}
          selectedBaselineData={{}}
        />
      );
    } else if (this.props.isFullyLoaded) {
      baselineContainer = (
        <BaselineEditContainer
          programmeId={this.state.globalProgrammeId}
          id={this.props.baselineId}
          history={this.props.history}
          headers={this.props.headers}
        />
      );
    }
    return baselineContainer;
  };
}
const mapStateToProps = state => {
  const { baselines, auth } = state;
  const { roleId } = auth;
  const {
    baselineId,
    loadingPage,
    baselineRequestStatus,
    baselineRequestMade,
    result,
    error,
    totalResults,
    errorDetails,
    isFullyLoaded
  } = baselines;
  return {
    baselineId,
    loadingPage,
    baselineRequestStatus,
    baselineRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails,
    isFullyLoaded
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBaselinesByProgramme: async (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      programmeId,
      type
    ) => {
      await dispatch(
        baselineActions.getBaselinesByProgramme(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          programmeId,
          type
        )
      );
    },
    clearSelectedBaseline: () => {
      dispatch(baselineActions.clearSelectedBaseline());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaselineHandler);
