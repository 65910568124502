import axios from "./axios-amendments";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  requestDeletePermission = (id, reason, entity, recordIdentifier, body) => {
    const config = {
      headers: this.headers
    };
    const query = {
      recordId: id,
      requestType: "DELETE",
      requestDetails: {
        recordIdentifier: recordIdentifier,
        entity: entity,
        reason: reason,
        body: body
      }
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error requesting delete permission: ", error.message);
          reject(error);
        });
    });
  };

  getDeletePermissionStatus = id => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error requesting permission: ", error.message);
          reject(error);
        });
    });
  };

  setAmendmentStatusActioned = id => {
    const config = {
      headers: this.headers
    };

    const body = {
      status: "Actioned"
    };

    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(body), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error setting status: ", error.message);
          reject(error);
        });
    });
  };

  updateAmendmentPermissionStatus = (id, approvalStatus, rejectionReason) => {
    const config = {
      headers: this.headers
    };

    const body = {
      status: approvalStatus,
      rejectionReason: rejectionReason
    };

    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(body), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error setting status: ", error.message);
          reject(error);
        });
    });
  };

  createAmendmentRequest = (
    id,
    oldValues,
    newValues,
    recordIdentifier,
    entity,
    reason
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      recordId: id,
      requestType: "AMENDMENT",
      requestDetails: {
        oldValues: oldValues,
        newValues: newValues,
        recordIdentifier: recordIdentifier,
        entity: entity,
        reason: reason
      }
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error creating amendment request: ", error.message);
          reject(error);
        });
    });
  };

  getAmendmentRequest = id => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting amendment request: ", error.message);
          reject(error);
        });
    });
  };

  setAmendmentRequestStatusActioned;
}
