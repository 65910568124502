export const reportActionTypes = {
  SAVE_REPORT_REQUEST: "SAVE_REPORT_REQUEST",
  SAVE_REPORT_SUCCESS: "SAVE_REPORT_SUCCESS",
  SAVE_REPORT_FAILURE: "SAVE_REPORT_FAILURE",

  CLEAR_CREATE_REPORT_REQUEST: "CLEAR_CREATE_REPORT_REQUEST",
  CLEAR_CREATE_REPORT_FAILURE: "CLEAR_CREATE_REPORT_FAILURE",
  CLEAR_CREATE_REPORT_SUCCESS: "CLEAR_CREATE_REPORT_SUCCESS",

  DELETE_REPORT_REQUEST: "DELETE_REPORT_REQUEST",
  DELETE_REPORT_SUCCESS: "DELETE_REPORT_SUCCESS",
  DELETE_REPORT_FAILURE: "DELETE_REPORT_FAILURE",

  UPDATE_SELECTED_REPORT_DATA_REQUEST: "UPDATE_SELECTED_REPORT_DATA_REQUEST",
  UPDATE_SELECTED_REPORT_DATA_SUCCESS: "UPDATE_SELECTED_REPORT_DATA_SUCCESS",
  UPDATE_SELECTED_REPORT_DATA_FAILURE: "UPDATE_SELECTED_REPORT_DATA_FAILURE",

  UPDATE_SELECTED_REPORT_REQUEST: "UPDATE_SELECTED_REPORT_REQUEST",
  UPDATE_SELECTED_REPORT_SUCCESS: "UPDATE_SELECTED_REPORT_SUCCESS",
  UPDATE_SELECTED_REPORT_FAILURE: "UPDATE_SELECTED_REPORT_FAILURE",

  CLEAR_SELECTED_REPORT_REQUEST: "CLEAR_SELECTED_REPORT_REQUEST",
  CLEAR_SELECTED_REPORT_SUCCESS: "CLEAR_SELECTED_REPORT_SUCCESS",
  CLEAR_SELECTED_REPORT_FAILURE: "CLEAR_SELECTED_REPORT_FAILURE",

  CANCEL_SELECTED_REPORT_UPDATE_REQUEST:
    "CANCEL_SELECTED_REPORT_UPDATE_REQUEST",
  CANCEL_SELECTED_REPORT_UPDATE_SUCCESS:
    "CANCEL_SELECTED_REPORT_UPDATE_SUCCESS",
  CANCEL_SELECTED_REPORT_UPDATE_FAILURE:
    "CANCEL_SELECTED_REPORT_UPDATE_FAILURE",

  GET_REPORTS_REQUEST: "GET_REPORTS_REQUEST",
  GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
  GET_REPORTS_FAILURE: "GET_REPORTS_FAILURE",

  GET_REPORT_BY_ID_REQUEST: "GET_REPORT_BY_ID_REQUEST",
  GET_REPORT_BY_ID_SUCCESS: "GET_REPORT_BY_ID_SUCCESS",
  GET_REPORT_BY_ID_FAILURE: "GET_REPORT_BY_ID_FAILURE",

  GENERATE_REPORT_REQUEST: "GENERATE_REPORT_REQUEST",
  GENERATE_REPORT_SUCCESS: "GENERATE_REPORT_SUCCESS",
  GENERATE_REPORT_FAILURE: "GENERATE_REPORT_FAILURE",

  UPLOAD_REFERRALS_REQUEST: "UPLOAD_REFERRALS_REQUEST",
  UPLOAD_REFERRALS_SUCCESS: "UPLOAD_REFERRALS_SUCCESS",
  UPLOAD_REFERRALS_FAILURE: "UPLOAD_REFERRALS_FAILURE",

  SEARCH_REPORT_REFERRALS_REQUEST: "SEARCH_REPORT_REFERRALS_REQUEST",
  SEARCH_REPORT_REFERRALS_SUCCESS: "SEARCH_REPORT_REFERRALS_SUCCESS",
  SEARCH_REPORT_REFERRALS_FAILURE: "SEARCH_REPORT_REFERRALS_FAILURE",

  CLEAR_SAVED_REPORT_SUCCESS: "CLEAR_SAVED_REPORT_SUCCESS",

  STORE_SPECIALIST_OPTIONS_SUCCESS: "STORE_SPECIALIST_OPTIONS_SUCCESS",

  DOWNLOAD_CSV_REQUEST: "DOWNLOAD_CSV_AUDIT_REQUEST",
  DOWNLOAD_CSV_SUCCESS: "DOWNLOAD_CSV_AUDIT_SUCCESS",
  DOWNLOAD_CSV_FAILURE: "DOWNLOAD_CSV_AUDIT_FAILURE"
};
