import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import {
  strings,
  methodOfEngagementOptions,
  yesNoOptions,
  typeOfEngagementOptions
} from "../resources";
import {
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormTextArea,
  ValidatedFormInput
} from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from ".";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { convertFromIso, dropdownHelper } from "../helpers";
import { AuditListContainer } from "../../Audit/containers";

class ModifyTtgEngagement extends React.Component {
  returnToList = () => {
    this.props.history.push("/ttgEngagements");
  };

  renderEditButton = () => {
    const { selectedTtgEngagementData, handleModeSwitch } = this.props;

    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      ></Input>
    );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful && (
          <>
            <Button.Group>
              <Button
                className="editButton"
                primary
                onClick={() => {
                  handleModeSwitch("edit");
                }}
                disabled={this.props.showAuditLog}
              >
                {strings.button.editLabel}
              </Button>
            </Button.Group>
          </>
        )}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={
                  this.props.toggleEnableDisableTtgEngagementModalVisibility
                }
                type="button"
                disabled={selectedTtgEngagementData.requestId ? true : false}
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableTtgEngagementModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={
                  this.props.toggleEnableDisableTtgEngagementModalVisibility
                }
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderDeleteButton = () => {
    return (
      <div className="containerButtons">
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={
                  this.props.toggleEnableDisableTtgEngagementModalVisibility
                }
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteTtgEngagementMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableTtgEngagementModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={
                  this.props.toggleEnableDisableTtgEngagementModalVisibility
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderAuditButton = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="auditButton"
            onClick={() => {
              this.props.handleToggleAudit();
            }}
          >
            {!this.props.showAuditLog
              ? strings.button.auditLabel
              : strings.button.formLabel}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderCancelSaveButton = () => {
    const {
      selectedTtgEngagementData,
      handleModeSwitch,
      handleModalConfirm,
      toggleCancelChangesModalVisibility,
      toggleConfirmSaveModalVisibility,
      initialTtgEngagementData,
      confirmSaveModalOpen,
      mode,
      cancelChangesModalOpen,
      validateForm
    } = this.props;

    const cancelSaveButtons = (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={validateForm}
            disabled={
              JSON.stringify(selectedTtgEngagementData) ===
                JSON.stringify(initialTtgEngagementData) &&
              !this.props.hasFileChanges
            }
          >
            {strings.button.saveLabel}
          </Button>
        </Button.Group>
        <Button.Group>
          <Button
            className="saveAddNewButton"
            primary
            inverted
            onClick={this.props.validateFormAndAddNew}
            disabled={
              JSON.stringify(selectedTtgEngagementData) ===
              JSON.stringify(initialTtgEngagementData)
            }
          >
            {strings.button.saveAddNew}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
    return cancelSaveButtons;
  };
  renderForm = () => {
    const {
      selectedTtgEngagementData,
      handleChange,
      mode,
      formInvalid,
      validationResults,
      userList
    } = this.props;

    return (
      <>
        <div className="infoHeader">
          {strings.form.header.ttgEngagementInfo}
        </div>
        <div className="infoWrapper">
          <Form.Group>
            {mode !== "create" && (
              <ValidatedFormInput
                formInvalid={formInvalid}
                inputData={selectedTtgEngagementData.crmId}
                inputLabel={strings.form.label.crmId}
                inputName="crmId"
                inputPlaceholder={strings.form.placeholder.crmId}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.crmId}
                mode="view"
                disabled={mode === "edit"}
              />
            )}
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedTtgEngagementData.cjSpecialist}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedTtgEngagementData.cjSpecialist,
                  userList
                ) || ""
              }
              dropdownLabel={strings.form.label.cjSpecialist}
              dropdownName="cjSpecialist"
              dropdownOptions={userList}
              dropdownPlaceholder={strings.form.placeholder.cjSpecialist}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.cjSpecialist}
              dropdownSearch={true}
              mode={mode}
            />
            <ValidatedDateInput
              inputId="dateOfEngagement"
              formInvalid={formInvalid}
              inputLabel={strings.form.label.dateOfEngagement}
              inputName="dateOfEngagement"
              inputPlaceholder={strings.form.placeholder.dateOfEngagement}
              inputDateFormat="DD/MM/YYYY"
              inputData={
                selectedTtgEngagementData.dateOfEngagement
                  ? convertFromIso(selectedTtgEngagementData.dateOfEngagement)
                  : ""
              }
              iconPosition="left"
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.dateOfEngagement}
              mode={mode}
              disabled={mode !== "create"}
              maxDate={new Date()}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={formInvalid}
              inputData={selectedTtgEngagementData.firstName}
              inputLabel={strings.form.label.firstName}
              inputName="firstName"
              inputPlaceholder={strings.form.placeholder.firstName}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.firstName}
              mode={mode}
            />
            <ValidatedFormInput
              formInvalid={formInvalid}
              inputData={selectedTtgEngagementData.surname}
              inputLabel={strings.form.label.surname}
              inputName="surname"
              inputPlaceholder={strings.form.placeholder.surname}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.surname}
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedTtgEngagementData.methodOfEngagement}
              viewData={selectedTtgEngagementData.methodOfEngagement}
              dropdownLabel={strings.form.label.methodOfEngagement}
              dropdownName="methodOfEngagement"
              dropdownOptions={methodOfEngagementOptions}
              dropdownPlaceholder={strings.form.placeholder.methodOfEngagement}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.methodOfEngagement}
              mode={mode}
            />
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedTtgEngagementData.typeOfEngagement}
              viewData={selectedTtgEngagementData.typeOfEngagement}
              dropdownLabel={strings.form.label.typeOfEngagement}
              dropdownName="typeOfEngagement"
              dropdownOptions={typeOfEngagementOptions}
              dropdownPlaceholder={strings.form.placeholder.typeOfEngagement}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.typeOfEngagement}
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedTtgEngagementData.engagementSuccessful}
              viewData={selectedTtgEngagementData.engagementSuccessful}
              dropdownLabel={strings.form.label.engagementSuccessful}
              dropdownName="engagementSuccessful"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={
                strings.form.placeholder.engagementSuccessful
              }
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.engagementSuccessful}
              mode={mode}
            />
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedTtgEngagementData.areaOfNeedWorkedOn}
              viewData={selectedTtgEngagementData.areaOfNeedWorkedOn}
              dropdownLabel={strings.form.label.areaOfNeedWorkedOn}
              dropdownName="areaOfNeedWorkedOn"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.areaOfNeedWorkedOn}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.areaOfNeedWorkedOn}
              mode={mode}
              required={false}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormTextArea
              formInvalid={formInvalid}
              inputData={selectedTtgEngagementData.notes}
              inputLabel={strings.form.label.notes}
              inputName="notes"
              inputPlaceholder={strings.form.placeholder.notes}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.notes}
              mode={mode}
              required={false}
            />
          </Form.Group>
        </div>
      </>
    );
  };

  render = () => {
    const { mode, pageTitle, requestStatus, formInvalid } = this.props;

    return (
      <div className="container">
        <ComponentHeader
          returnToList={this.returnToList}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderEditButton()}
          cancelSaveButtons={this.renderCancelSaveButton()}
          auditLog={this.renderAuditButton()}
          deleteButton={this.renderDeleteButton()}
          headerList={strings.header.ttgEngagementList}
          permissionsUpdate={permissions.ttgEngagements.update}
          permissionsCreate={permissions.ttgEngagements.create}
          permissionsDelete={permissions.ttgEngagements.delete}
          formInvalid={formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />
        {this.props.showAuditLog ? (
          <AuditListContainer
            embedded={true}
            headers={this.props.headers}
            history={this.props.history}
            entityId={this.props.selectedTtgEngagementData.ttgEngagementId}
            hideSearchBar={true}
            record={true}
          />
        ) : (
          <Form id="ttgEngagementForm" className="infoBlock stickyForm">
            <fieldset disabled={mode === "view"}>{this.renderForm()}</fieldset>
          </Form>
        )}
      </div>
    );
  };
}

ModifyTtgEngagement.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedTtgEngagementData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  initialTtgEngagementData: PropTypes.object.isRequired,
  deletePermissionStatus: PropTypes.string,
  handleModalInputChange: PropTypes.func,
  deletionReason: PropTypes.object,
  deleteSuccessful: PropTypes.bool,
  enableDisableTtgEngagementModalOpen: PropTypes.bool,
  handleModeSwitch: PropTypes.func,
  hasFileChanges: PropTypes.bool,
  history: PropTypes.object,
  toggleEnableDisableTtgEngagementModalVisibility: PropTypes.func,
  userList: PropTypes.array,
  validateFormAndAddNew: PropTypes.func
};

export default withRouter(ModifyTtgEngagement);
