import axios from "./axios-users";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createUser = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error creating user: ", error.response.data);
          reject(error);
        });
    });
  };

  lockUser = () => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/lock-user/", "", config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating user: ", error.message);
          reject(error);
        });
    });
  };

  updateUser = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating user: ", error.message);
          reject(error);
        });
    });
  };

  deleteUser = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, null, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting user: ", error.message);
          reject(error);
        });
    });
  };

  getUsers = (queryString, size, from, fields, sort, activeOnly) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly
    };

    return new Promise((resolve, reject) => {
      axios
        .post("/list-users/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting users: ", error.message);
          reject(error);
        });
    });
  };

  getUserById = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting user: ", error.message);
          reject(error);
        });
    });
  };
}
