export const amendmentActionTypes = {
  REQUEST_DELETE_PERMISSION_REQUEST: "REQUEST_DELETE_PERMISSION_REQUEST",
  REQUEST_DELETE_PERMISSION_SUCCESS: "REQUEST_DELETE_PERMISSION_SUCCESS",
  REQUEST_DELETE_PERMISSION_FAILURE: "REQUEST_DELETE_PERMISSION_FAILURE",

  GET_DELETE_PERMISSION_STATUS_REQUEST: "GET_DELETE_PERMISSION_STATUS_REQUEST",
  GET_DELETE_PERMISSION_STATUS_SUCCESS: "GET_DELETE_PERMISSION_STATUS_SUCCESS",
  GET_DELETE_PERMISSION_STATUS_FAILURE: "GET_DELETE_PERMISSION_STATUS_FAILURE",

  SET_AMENDMENT_STATUS_ACTIONED_REQUEST:
    "SET_AMENDMENT_STATUS_ACTIONED_REQUEST",
  SET_AMENDMENT_STATUS_ACTIONED_SUCCESS:
    "SET_AMENDMENT_STATUS_ACTIONED_SUCCESS",
  SET_AMENDMENT_STATUS_ACTIONED_FAILURE:
    "SET_AMENDMENT_STATUS_ACTIONED_FAILURE",

  UPDATE_AMENDMENT_PERMISSION_STATUS_REQUEST:
    "UPDATE_AMENDMENT_PERMISSION_STATUS_REQUEST",
  UPDATE_AMENDMENT_PERMISSION_STATUS_SUCCESS:
    "UPDATE_AMENDMENT_PERMISSION_STATUS_SUCCESS",
  UPDATE_AMENDMENT_PERMISSION_STATUS_FAILURE:
    "UPDATE_AMENDMENT_PERMISSION_STATUS_FAILURE",

  CREATE_AMENDMENT_REQUEST_REQUEST: "CREATE_AMENDMENT_REQUEST_REQUEST",
  CREATE_AMENDMENT_REQUEST_SUCCESS: "CREATE_AMENDMENT_REQUEST_SUCCESS",
  CREATE_AMENDMENT_REQUEST_FAILURE: "CREATE_AMENDMENT_REQUEST_FAILURE",

  GET_AMENDMENT_REQUEST_REQUEST: "GET_AMENDMENT_REQUEST_REQUEST",
  GET_AMENDMENT_REQUEST_SUCCESS: "GET_AMENDMENT_REQUEST_SUCCESS",
  GET_AMENDMENT_REQUEST_FAILURE: "GET_AMENDMENT_REQUEST_FAILURE",

  CLEAR_AMENDMENT_REQUEST: "CLEAR_AMENDMENT_REQUEST",
  CLEAR_AMENDMENT_SUCCESS: "CLEAR_AMENDMENT_SUCCESS",
  CLEAR_AMENDMENT_FAILURE: "CLEAR_AMENDMENT_FAILURE"
};
