import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    header: {
      login: "Login",
      outcomeList: "Outcomes",
      loading: "Loading Information"
    },
    form: {
      feedback: {
        processing: "Processing request...",
        outcomeRequestRefreshPrompt:
          "Use the refresh function to see an updated outcomes list.",
        outcomeRequestUnknown: "Something went wrong with the outcome request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      }
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      saveAddNew: "Save & Add New",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    tooltip: {},
    table: {
      header: {
        outcomeName: "Outcome Name",
        claimed: "Date Claimed",
        achieved: "Achieved",
        workingTowards: "Working Towards"
      },
      text: {
        noResults: "No results found."
      }
    },
    fieldValues: {
      yes: "Yes",
      no: "No"
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    }
  }
});
