export const areaOfNeedActionTypes = {
  CREATE_AREA_OF_NEED_REQUEST: "CREATE_AREA_OF_NEED_REQUEST",
  CREATE_AREA_OF_NEED_SUCCESS: "CREATE_AREA_OF_NEED_SUCCESS",
  CREATE_AREA_OF_NEED_FAILURE: "CREATE_AREA_OF_NEED_FAILURE",

  CLEAR_CREATE_AREA_OF_NEED_REQUEST: "CLEAR_CREATE_AREA_OF_NEED_REQUEST",
  CLEAR_CREATE_AREA_OF_NEED_FAILURE: "CLEAR_CREATE_AREA_OF_NEED_FAILURE",
  CLEAR_CREATE_AREA_OF_NEED_SUCCESS: "CLEAR_CREATE_AREA_OF_NEED_SUCCESS",

  DELETE_AREA_OF_NEED_REQUEST: "DELETE_AREA_OF_NEED_REQUEST",
  DELETE_AREA_OF_NEED_SUCCESS: "DELETE_AREA_OF_NEED_SUCCESS",
  DELETE_AREA_OF_NEED_FAILURE: "DELETE_AREA_OF_NEED_FAILURE",

  UPDATE_SELECTED_AREA_OF_NEED_REQUEST: "UPDATE_SELECTED_AREA_OF_NEED_REQUEST",
  UPDATE_SELECTED_AREA_OF_NEED_SUCCESS: "UPDATE_SELECTED_AREA_OF_NEED_SUCCESS",
  UPDATE_SELECTED_AREA_OF_NEED_FAILURE: "UPDATE_SELECTED_AREA_OF_NEED_FAILURE",

  CLEAR_SELECTED_AREA_OF_NEED_REQUEST: "CLEAR_SELECTED_AREA_OF_NEED_REQUEST",
  CLEAR_SELECTED_AREA_OF_NEED_SUCCESS: "CLEAR_SELECTED_AREA_OF_NEED_SUCCESS",
  CLEAR_SELECTED_AREA_OF_NEED_FAILURE: "CLEAR_SELECTED_AREA_OF_NEED_FAILURE",

  CANCEL_SELECTED_AREA_OF_NEED_UPDATE_REQUEST:
    "CANCEL_SELECTED_AREA_OF_NEED_UPDATE_REQUEST",
  CANCEL_SELECTED_AREA_OF_NEED_UPDATE_SUCCESS:
    "CANCEL_SELECTED_AREA_OF_NEED_UPDATE_SUCCESS",
  CANCEL_SELECTED_AREA_OF_NEED_UPDATE_FAILURE:
    "CANCEL_SELECTED_AREA_OF_NEED_UPDATE_FAILURE",

  GET_AREA_OF_NEEDS_REQUEST: "GET_AREA_OF_NEEDS_REQUEST",
  GET_AREA_OF_NEEDS_SUCCESS: "GET_AREA_OF_NEEDS_SUCCESS",
  GET_AREA_OF_NEEDS_FAILURE: "GET_AREA_OF_NEEDS_FAILURE",

  UPDATE_AREA_OF_NEED_REQUEST: "UPDATE_AREA_OF_NEED_REQUEST",
  UPDATE_AREA_OF_NEED_SUCCESS: "UPDATE_AREA_OF_NEED_SUCCESS",
  UPDATE_AREA_OF_NEED_FAILURE: "UPDATE_AREA_OF_NEED_FAILURE",

  GET_AREA_OF_NEED_BY_ID_REQUEST: "GET_AREA_OF_NEED_BY_ID_REQUEST",
  GET_AREA_OF_NEED_BY_ID_SUCCESS: "GET_AREA_OF_NEED_BY_ID_SUCCESS",
  GET_AREA_OF_NEED_BY_ID_FAILURE: "GET_AREA_OF_NEED_BY_ID_FAILURE",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE"
};
