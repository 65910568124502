import LocalizedStrings from "react-localization";

export const enums = new LocalizedStrings({
  en: {
    role: {
      PULSION: "pulsion",
      ICT: "ict",
      MENTOR: "mentor",
      TEAMLEAD: "teamLead",
      MANAGER: "manager",
      MANAGERPARTNER: "managerPartner",
      SSP: "ssp",
      HEAD: "hoo",
      ASSURANCE: "assurance",
      SUPPORT: "support",
      PRISONBASEDMENTOR: "prisonBasedMentor"
    }
  }
});
