import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import { strings, dropdownOptions } from "../resources";
import { AuditListContainer } from "../../Audit/containers/";
import {
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormTextArea,
  ValidatedFormInput,
  ValidatedMultipleActions
} from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from ".";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { dropdownHelper, convertFromIso } from "../helpers";

class ModifyAreaOfNeed extends React.Component {
  returnToList = () => {
    this.props.history.push("/areaOfNeed");
  };

  renderEditButton = () => {
    const {
      selectedAreaOfNeedData,
      handleModeSwitch,
      handleAddEngagement
    } = this.props;
    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      ></Input>
    );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful && (
          <>
            <Button.Group>
              <Button
                className="editButton"
                primary
                onClick={() => {
                  handleModeSwitch("edit");
                }}
                disabled={this.props.showAuditLog}
              >
                {strings.button.editLabel}
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                className="addButton"
                primary
                inverted
                onClick={handleAddEngagement}
                type="button"
                disabled={!selectedAreaOfNeedData.active}
              >
                {strings.button.addEngagement}
              </Button>
            </Button.Group>
          </>
        )}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={
                  this.props.toggleEnableDisableAreaOfNeedModalVisibility
                }
                type="button"
                disabled={
                  this.props.selectedAreaOfNeedData.requestId !== undefined
                }
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableAreaOfNeedModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={
                  this.props.toggleEnableDisableAreaOfNeedModalVisibility
                }
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderDeleteButton = () => {
    return (
      <div className="containerButtons">
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={
                  this.props.toggleEnableDisableAreaOfNeedModalVisibility
                }
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteAreaOfNeedMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableAreaOfNeedModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={
                  this.props.toggleEnableDisableAreaOfNeedModalVisibility
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderAuditButton = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="auditButton"
            onClick={() => {
              this.props.handleToggleAudit();
            }}
          >
            {!this.props.showAuditLog
              ? strings.button.auditLabel
              : strings.button.formLabel}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderCancelSaveButton = () => {
    const {
      selectedAreaOfNeedData,
      handleModeSwitch,
      handleModalConfirm,
      toggleCancelChangesModalVisibility,
      initialAreaOfNeedData,
      validateForm,
      mode,
      cancelChangesModalOpen,
      confirmSaveModalOpen,
      toggleConfirmSaveModalVisibility
    } = this.props;

    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={validateForm}
            disabled={
              JSON.stringify(selectedAreaOfNeedData) ===
              JSON.stringify(initialAreaOfNeedData)
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };

  multipleActions = () => {
    const {
      selectedAreaOfNeedData,
      mode,
      formInvalid,
      validationResults,
      handleAreaOfNeedChange
    } = this.props;
    return (
      this.props.selectedAreaOfNeedData.areaOfNeed &&
      this.props.selectedAreaOfNeedData.areaOfNeed.map((need, tableId) => {
        return (
          <Fragment key={tableId}>
            <h1>{need}</h1>
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={
                  selectedAreaOfNeedData.areaOfNeedFields[need]
                    .outcomeOfAreaOfNeed
                }
                viewData={
                  selectedAreaOfNeedData.areaOfNeedFields[need]
                    .outcomeOfAreaOfNeed
                }
                dropdownLabel={strings.form.label.outcomeOfAreaOfNeed}
                dropdownName={"outcomeOfAreaOfNeed"}
                dropdownOptions={dropdownOptions.outcomeOfAreaOfNeedOptions}
                dropdownPlaceholder={
                  strings.form.placeholder.outcomeOfAreaOfNeed
                }
                formFieldWidth={8}
                handleChange={(e, data) => {
                  handleAreaOfNeedChange(e, data, need);
                }}
                validationResult={
                  validationResults.needFields
                    ? validationResults.needFields[need]
                      ? validationResults.needFields[need].outcomeOfAreaOfNeed
                      : undefined
                    : undefined
                }
                mode={mode}
                required={true}
              />
              {selectedAreaOfNeedData.areaOfNeedFields[need]
                .outcomeOfAreaOfNeed === strings.form.text.workInProgress && (
                <ValidatedDateInput
                  inputId="followUpDate"
                  formInvalid={formInvalid}
                  inputLabel={strings.form.label.followUpDate}
                  inputName="followUpDate"
                  inputPlaceholder={strings.form.placeholder.followUpDate}
                  inputDateFormat="DD/MM/YYYY"
                  inputData={
                    selectedAreaOfNeedData.areaOfNeedFields[need].followUpDate
                      ? convertFromIso(
                          selectedAreaOfNeedData.areaOfNeedFields[need]
                            .followUpDate
                        )
                      : ""
                  }
                  iconPosition="left"
                  formFieldWidth={8}
                  handleChange={(e, data) => {
                    handleAreaOfNeedChange(e, data, need);
                  }}
                  validationResult={
                    validationResults.needFields
                      ? validationResults.needFields[need]
                        ? validationResults.needFields[need].followUpDate
                        : undefined
                      : undefined
                  }
                  mode={mode}
                  required={true}
                />
              )}
              {selectedAreaOfNeedData.areaOfNeedFields[need]
                .outcomeOfAreaOfNeed === strings.form.text.positive && (
                <ValidatedDateInput
                  inputId="dateOfPositiveOutcome"
                  formInvalid={formInvalid}
                  inputLabel={strings.form.label.dateOfPositiveOutcome}
                  inputName="dateOfPositiveOutcome"
                  inputPlaceholder={
                    strings.form.placeholder.dateOfPositiveOutcome
                  }
                  inputDateFormat="DD/MM/YYYY"
                  inputData={
                    selectedAreaOfNeedData.areaOfNeedFields[need]
                      .dateOfPositiveOutcome
                      ? convertFromIso(
                          selectedAreaOfNeedData.areaOfNeedFields[need]
                            .dateOfPositiveOutcome
                        )
                      : ""
                  }
                  iconPosition="left"
                  formFieldWidth={8}
                  handleChange={(e, data) => {
                    handleAreaOfNeedChange(e, data, need);
                  }}
                  validationResult={
                    validationResults.needFields
                      ? validationResults.needFields[need]
                        ? validationResults.needFields[need]
                            .dateOfPositiveOutcome
                        : undefined
                      : undefined
                  }
                  mode={mode}
                  required={true}
                />
              )}
              {selectedAreaOfNeedData.areaOfNeedFields[need]
                .outcomeOfAreaOfNeed === strings.form.text.neutral && (
                <ValidatedDateInput
                  inputId="dateOfNeutralOutcome"
                  formInvalid={formInvalid}
                  inputLabel={strings.form.label.dateOfNeutralOutcome}
                  inputName="dateOfNeutralOutcome"
                  inputPlaceholder={
                    strings.form.placeholder.dateOfNeutralOutcome
                  }
                  inputDateFormat="DD/MM/YYYY"
                  inputData={
                    selectedAreaOfNeedData.areaOfNeedFields[need]
                      .dateOfNeutralOutcome
                      ? convertFromIso(
                          selectedAreaOfNeedData.areaOfNeedFields[need]
                            .dateOfNeutralOutcome
                        )
                      : ""
                  }
                  iconPosition="left"
                  formFieldWidth={8}
                  handleChange={(e, data) => {
                    handleAreaOfNeedChange(e, data, need);
                  }}
                  validationResult={
                    validationResults.needFields
                      ? validationResults.needFields[need]
                        ? validationResults.needFields[need]
                            .dateOfNeutralOutcome
                        : undefined
                      : undefined
                  }
                  mode={mode}
                  required={true}
                />
              )}
            </Form.Group>
            <Form.Group>
              <ValidatedFormTextArea
                formInvalid={formInvalid}
                inputData={
                  selectedAreaOfNeedData.areaOfNeedFields[need].reasonForOutcome
                }
                inputLabel={strings.form.label.reasonForOutcome}
                inputName="reasonForOutcome"
                inputPlaceholder={strings.form.placeholder.reasonForOutcome}
                formFieldWidth={8}
                handleChange={(e, data) => {
                  handleAreaOfNeedChange(e, data, need);
                }}
                required={
                  selectedAreaOfNeedData.areaOfNeedFields[need]
                    .outcomeOfAreaOfNeed === strings.form.text.neutral ||
                  selectedAreaOfNeedData.areaOfNeedFields[need]
                    .outcomeOfAreaOfNeed === strings.form.text.positive
                }
                validationResult={
                  validationResults.needFields
                    ? validationResults.needFields[need]
                      ? validationResults.needFields[need].reasonForOutcome
                      : undefined
                    : undefined
                }
                mode={mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedMultipleActions
                selectedAreaOfNeedData={this.props.selectedAreaOfNeedData}
                validationResults={this.props.validationResults}
                formInvalid={this.props.formInvalid}
                handleActionChange={this.props.handleActionChange}
                allowNumericalInput={this.props.allowNumericalInput}
                mode={this.props.mode}
                toggleDeleteModal={this.props.toggleDeleteModal}
                addAction={this.props.addAction}
                handleModalConfirm={this.props.handleModalConfirm}
                deleteModalOpen={this.props.deleteModalOpen}
                actionsString={strings.form.label.actions}
                actionsPlaceholder={strings.form.placeholder.actions}
                actionStatusString={strings.form.label.actionStatus}
                actionStatusPlaceholder={strings.form.placeholder.actionStatus}
                actionStartedDateString={strings.form.label.actionStartedDate}
                actionClosedDateString={strings.form.label.actionClosedDate}
                actionsOtherString={strings.form.label.actionsOther}
                actionsOtherPlaceholder={strings.form.placeholder.actionsOther}
                actionDatePlaceholder={
                  strings.form.placeholder.actionClosedDate
                }
                actionDateString={strings.form.label.actionDate}
                notesString={strings.form.label.notes}
                notesPlaceholder={strings.form.placeholder.notes}
                deleteActionMessage={strings.modal.deleteActionMessage}
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
                dropdownOptions={dropdownOptions}
                openString={strings.form.text.open}
                closedString={strings.form.text.closed}
                areaOfNeed={need}
                tableId={tableId}
                strings={strings}
                handleDropdownOptionAddition={
                  this.props.handleDropdownOptionAddition
                }
                customDropdownOptions={this.props.customDropdownOptions}
                otherString={strings.form.text.other}
              ></ValidatedMultipleActions>
            </Form.Group>
          </Fragment>
        );
      })
    );
  };

  renderForm = () => {
    const {
      selectedAreaOfNeedData,
      handleChange,
      mode,
      formInvalid,
      validationResults,
      userList
    } = this.props;

    return (
      <>
        <div className="infoHeader">{strings.form.header.areaOfNeedInfo}</div>
        <div className="infoWrapper">
          <Form.Group>
            {mode !== "create" && (
              <ValidatedFormInput
                formInvalid={formInvalid}
                inputData={selectedAreaOfNeedData.crmId}
                inputLabel={strings.form.label.crmId}
                inputName="crmId"
                inputPlaceholder={strings.form.placeholder.crmId}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.crmId}
                mode="view"
                disabled={mode === "edit"}
              />
            )}
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedAreaOfNeedData.cjSpecialist}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedAreaOfNeedData.cjSpecialist,
                  userList
                ) || ""
              }
              dropdownLabel={strings.form.label.cjSpecialist}
              dropdownName="cjSpecialist"
              dropdownOptions={userList}
              dropdownPlaceholder={strings.form.placeholder.cjSpecialist}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.cjSpecialist}
              dropdownSearch={true}
              required
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedAreaOfNeedData.areaOfNeed || []}
              viewData={selectedAreaOfNeedData.areaOfNeed}
              dropdownLabel={strings.form.label.areaOfNeed}
              dropdownName="areaOfNeed"
              dropdownOptions={dropdownOptions.areaOfNeedOptions}
              dropdownPlaceholder={strings.form.placeholder.areaOfNeed}
              dropdownMultiple={true}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.areaOfNeed}
              mode={mode}
              required
            />
          </Form.Group>
          {this.multipleActions()}
        </div>
      </>
    );
  };

  render = () => {
    const { mode, pageTitle, requestStatus, formInvalid } = this.props;

    return (
      <div className="container">
        <ComponentHeader
          returnToList={this.returnToList}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderEditButton()}
          cancelSaveButtons={this.renderCancelSaveButton()}
          auditLog={this.renderAuditButton()}
          headerList={strings.header.areaOfNeedList}
          permissionsUpdate={permissions.areaOfNeed.update}
          permissionsCreate={permissions.areaOfNeed.create}
          permissionsDelete={permissions.areaOfNeed.delete}
          formInvalid={formInvalid}
          validationResults={this.props.validationResults}
          sticky
          deleteButton={this.renderDeleteButton()}
        />

        {this.props.showAuditLog ? (
          <AuditListContainer
            embedded={true}
            headers={this.props.headers}
            history={this.props.history}
            entityId={this.props.selectedAreaOfNeedData.areaOfNeedId}
            hideSearchBar={true}
            record={true}
          />
        ) : (
          <Form id="areaOfNeedForm" className="infoBlock stickyForm">
            <fieldset disabled={mode === "view"}>{this.renderForm()}</fieldset>
          </Form>
        )}
      </div>
    );
  };
}

ModifyAreaOfNeed.propTypes = {
  addAction: PropTypes.func,
  allowNumericalInput: PropTypes.func,
  cancelChangesModalOpen: PropTypes.bool,
  confirmSaveModalOpen: PropTypes.bool,
  customDropdownOptions: PropTypes.array,
  deleteModalOpen: PropTypes.bool,
  deletePermissionStatus: PropTypes.string,
  deleteSuccessful: PropTypes.bool,
  deletionReason: PropTypes.object,
  enableDisableAreaOfNeedModalOpen: PropTypes.any,
  formInvalid: PropTypes.bool,
  handleActionChange: PropTypes.func,
  handleAddEngagement: PropTypes.func,
  handleAreaOfNeedChange: PropTypes.func,
  handleChange: PropTypes.func,
  handleDropdownOptionAddition: PropTypes.func,
  handleModalConfirm: PropTypes.func,
  handleModalInputChange: PropTypes.func,
  handleModeSwitch: PropTypes.func,
  handleToggleAudit: PropTypes.func,
  headers: PropTypes.object,
  history: PropTypes.object,
  initialAreaOfNeedData: PropTypes.object,
  mode: PropTypes.string,
  pageTitle: PropTypes.string,
  requestStatus: PropTypes.bool,
  selectedAreaOfNeedData: PropTypes.object,
  showAuditLog: PropTypes.bool,
  toggleCancelChangesModalVisibility: PropTypes.func,
  toggleConfirmSaveModalVisibility: PropTypes.func,
  toggleDeleteModal: PropTypes.func,
  toggleEnableDisableAreaOfNeedModalVisibility: PropTypes.func,
  userList: PropTypes.array,
  validateForm: PropTypes.func,
  validateFormAndAddNew: PropTypes.func,
  validationResults: PropTypes.object
};

export default withRouter(ModifyAreaOfNeed);
