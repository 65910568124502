import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Modal } from "semantic-ui-react";
import { strings } from "../resources";
import ReactTable from "react-table";
import { convertFromIso } from "../helpers/dateHelpers";

const DownloadsListModal = props => {
  const getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      return {
        onClick: () => {
          if (rowInfo) {
            window.open(rowInfo.original.details, "_blank");
          }
        }
      };
    }
  };

  const handleClose = () => {
    props.toggleModal();
  };

  const parseFileName = url => {
    if (url) {
      let fileName = decodeURIComponent(url.split("/")[5]);
      fileName = fileName.split("?")[0];

      return fileName;
    }
  };

  const parseFileFormat = url => {
    const fileName = parseFileName(url);
    const dotIndex = fileName.lastIndexOf(".");

    return fileName.substring(dotIndex + 1).toUpperCase();
  };

  const columns = [
    {
      id: "entity",
      Header: strings.downloads.headers.entity,
      accessor: row => strings.downloads.entities.reports,
      headerStyle: { textAlign: "left" },
      width: 150
    },
    {
      id: "fileFormat",
      Header: strings.downloads.headers.fileFormat,
      accessor: row => parseFileFormat(row.details),
      headerStyle: { textAlign: "left" },
      width: 70
    },
    {
      id: "fileName",
      Header: strings.downloads.headers.fileName,
      accessor: row => parseFileName(row.details),
      headerStyle: { textAlign: "left" }
    },
    {
      id: "created",
      Header: strings.downloads.headers.created,
      accessor: row => (row.created ? convertFromIso(row.created) : ""),
      headerStyle: { textAlign: "left" },
      width: 150
    },
    {
      id: "isNew",
      sortable: false,
      accessor: row => {
        if (row.isNew) {
          return <Icon name="plus" color="green" />;
        } else {
          return <></>;
        }
      },
      width: 35
    }
  ];

  return (
    <Modal
      className={props.modalClassName}
      open={props.modalOpen}
      onClose={handleClose}
    >
      <Modal.Header>{strings.downloads.headers.myDownloads}</Modal.Header>
      <Modal.Content className="modalContent">
        <p>{strings.downloads.modalMessage}</p>
        <div>
          <ReactTable
            data={props.downloadData}
            showPagination={false}
            getTdProps={getTdProps}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText={strings.text.noResultsFound}
            pageSize={20}
            defaultSorted={[
              {
                id: "created",
                desc: true
              }
            ]}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          color="green"
          onClick={handleClose}
          style={{ display: props.showOkButton ? "" : "none" }}
        >
          <Icon name="checkmark" /> {props.okLabel}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

DownloadsListModal.propTypes = {
  downloadData: PropTypes.array.isRequired,
  modalClassName: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  showOkButton: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  noLabel: PropTypes.string.isRequired,
  yesLabel: PropTypes.string.isRequired,
  okLabel: PropTypes.string.isRequired
};

export default DownloadsListModal;
