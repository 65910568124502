import React from "react";
import { Modal, Divider, Icon, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { strings } from "../resources";
import { checkFormatIso, convertFromIso } from "../helpers";

function ComparisonModal(props) {
  const [open, setOpen] = React.useState(false);
  let leftColumn = [],
    rightColumn = [];

  if (props.selectedTaskData.details.oldValues) {
    let oldValues = props.selectedTaskData.details.oldValues;

    Object.entries(oldValues).forEach((element, count) => {
      if (!element[0].includes("_initial")) {
        leftColumn.push(
          <p key={count}>
            {props.stringsObject[element[0]]
              ? props.stringsObject[element[0]]
              : element[0]}
            {" : "}
            {typeof element[1] === "string"
              ? checkFormatIso(element[1])
                ? convertFromIso(element[1])
                : element[1]
              : JSON.stringify(element[1])}
          </p>
        );
      }
    });
  }
  if (props.selectedTaskData.details.newValues) {
    let newValues;
    try {
      newValues = props.selectedTaskData.details.newValues;
    } catch (e) {
      newValues = props.selectedTaskData.details.newValues;
    }

    delete newValues.updated;

    if (typeof newValues === "object") {
      Object.entries(newValues).forEach((element, count) => {
        if (!element[0].includes("_initial")) {
          rightColumn.push(
            <p key={count}>
              {props.stringsObject[element[0]]
                ? props.stringsObject[element[0]]
                : element[0]}
              {" : "}
              {typeof element[1] === "string"
                ? checkFormatIso(element[1])
                  ? convertFromIso(element[1])
                  : element[1]
                : JSON.stringify(element[1])}
            </p>
          );
        }
      });
    } else {
      rightColumn.push(newValues);
    }
  }
  const header = () => {
    return (
      <>
        <Modal.Header>{strings.modal.amendmentComparison} </Modal.Header>
      </>
    );
  };
  const modalContent = () => {
    return (
      <>
        <Modal.Content>
          <Modal.Content className="comparisonModal">
            <Modal.Description>{leftColumn}</Modal.Description>
          </Modal.Content>
          <Divider vertical>
            <Icon className="diffArrow" name="arrow right" />
          </Divider>
          <Modal.Content className="comparisonModal">
            <Modal.Description>{rightColumn}</Modal.Description>
          </Modal.Content>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="red"
            onClick={() => {
              setOpen(true);
              props.handleRejectionButton();
            }}
            style={{ display: props.showOkButton ? "" : "none" }}
          >
            <Icon name="x" /> {strings.button.reject}
          </Button>
          <Button
            basic
            color="green"
            onClick={() => {
              props.handleApprovalButton();
              props.handleClose();
            }}
            style={{ display: props.showOkButton ? "" : "none" }}
          >
            <Icon name="checkmark" /> {strings.button.approve}
          </Button>
        </Modal.Actions>
        {nestedModal}
      </>
    );
  };

  const nestedModal = (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        props.resetDataOnModalClose();
      }}
    >
      <Modal.Header>{strings.form.label.rejectionReason}</Modal.Header>
      <Modal.Content>
        <Modal.Description>{props.children}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          color="red"
          onClick={() => {
            setOpen(false);
            props.resetDataOnModalClose();
          }}
        >
          <Icon name="x" /> {strings.button.cancelLabel}
        </Button>
        <Button
          basic
          color="green"
          onClick={() => {
            props.handleModalConfirm(props.modalClassName);
            props.handleClose();
          }}
          disabled={props.inputValue.length < 1}
          style={{ display: props.showOkButton ? "" : "none" }}
          closeIcon
        >
          <Icon name="checkmark" /> {strings.button.submit}
        </Button>
      </Modal.Actions>
    </Modal>
  );

  return (
    <>
      <Modal
        open={props.modalOpen}
        onClose={() => props.handleClose()}
        closeIcon
      >
        {header()}
        {modalContent()}
      </Modal>
    </>
  );
}

ComparisonModal.propTypes = {
  oldDate: PropTypes.string,
  newDate: PropTypes.string,
  newValues: PropTypes.string,
  modalOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

export default ComparisonModal;
