import React from "react";
import ReactTable from "react-table";
import { TableFunctions } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";
import { permissions } from "../../App/resources";
import { convertFromIso } from "../helpers/index";

class TableComponent extends React.Component {
  state = {
    entityType: "",
    entityId: ""
  };
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      if (column.id !== "delete" && column.id !== "active") {
        return {
          onClick: () => {
            if (rowInfo) {
              this.props.history.push(
                "/areaOfNeed/view/" + rowInfo.original.needId
              );
            }
          }
        };
      }
    }
    return {}; // getTdProps requires a return statement
  };

  componentDidMount = () => {
    this.getEntityId();
  };

  getEntityId = () => {
    if (localStorage.getItem("ttgProgrammeId") || this.props.ttgProgrammeId) {
      this.setState({
        entityId:
          localStorage.getItem("ttgProgrammeId") || this.props.ttgProgrammeId,
        entityType: "/ttgProgramme/"
      });
    } else {
      this.props.history.goBack();
    }
  };

  mapSearchResultsToRows = () => {
    const { areaOfNeedListData } = this.props;
    let data = [];
    if (areaOfNeedListData) {
      areaOfNeedListData.forEach((result, index) => {
        if (result["_source"]) {
          data.push(result["_source"]);
        }
      });
    }
    return data;
  };

  render = () => {
    let data = this.mapSearchResultsToRows();
    let pages = Math.ceil(this.props.totalResults / this.props.pageSize);
    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };

    const columns = [
      {
        id: "cjSpecialist",
        Header: strings.table.header.cjSpecialist,
        accessor: ref => {
          if (
            Object.entries(ref).length > 0 &&
            this.props.userList.length > 0
          ) {
            let specialist = this.props.userList.find(
              user => ref.cjSpecialist === user.value
            )
              ? this.props.userList.find(
                  user => ref.cjSpecialist === user.value
                ).text
              : "";
            return specialist;
          } else {
            return "";
          }
        },
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "created",
        Header: strings.table.header.created,
        accessor: need => (need.created ? convertFromIso(need.created) : ""),
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.crmId,
        accessor: "crmId",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "areaOfNeed",
        Header: strings.table.header.areaOfNeed,
        accessor: areaOfNeed => {
          return Array.isArray(areaOfNeed.areaOfNeed)
            ? areaOfNeed.areaOfNeed.join(", ")
            : areaOfNeed.areaOfNeed;
        },
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40)
      }
    ];
    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          handleRefresh={this.props.handleRefresh}
          headerList={strings.header.areaOfNeed}
          buttonAddLabel={strings.button.addAreaOfNeedLabel}
          permissionsCreate={permissions.areaOfNeed.create}
          headerClass="pageHeader"
          refreshButtonClass="refreshButton"
          addButtonClass="addButton"
          addButtonLink={
            "/areaOfNeed/create/" + this.state.entityId + this.state.entityType
          }
          handleChange={this.props.handleChange}
          searchBarQuery={this.props.searchBarQuery}
          handleSubmit={this.props.handleSubmit}
          parentPage={this.props.parentPage}
          returnToParent={this.props.returnToParent}
          hideSearchBar={this.props.hideSearchBar}
        />

        <div>
          <ReactTable
            manual
            data={data}
            pages={pages}
            defaultPageSize={this.props.pageSize}
            showPagination={true}
            getTdProps={this.getTdProps}
            onFetchData={(state, instance) => {
              this.props.handleFetchData(state);
              this.props.updatePageIndex(state);
            }}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText={strings.table.text.noResults}
            previousText="<"
            nextText=">"
            pageText=""
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  areaOfNeedListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  updatePageIndex: PropTypes.func.isRequired
};

export default TableComponent;
