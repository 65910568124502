import { strings } from "../resources";

export default function updateSharedWithProperties(
  supportReferralBody,
  referralBody,
  programmeBody
) {
  // Updates Referral and Programme supportPartnerSharedWith/sharedWith properties based on whether referral/programme record has already been shared with the provider
  let updatedReferralBody;
  let updatedProgrammeBody;
  if (supportReferralBody.provider === strings.fieldValues.SAMH) {
    updatedProgrammeBody = updateProgrammeSharedWithProperty(
      programmeBody,
      strings.fieldValues.SAMH
    );
    updatedReferralBody = updateReferralSupportPartnerSharedWithProperty(
      referralBody,
      strings.fieldValues.SAMH
    );
  } else if (
    supportReferralBody.provider === strings.fieldValues.familiesOutside
  ) {
    updatedProgrammeBody = updateProgrammeSharedWithProperty(
      programmeBody,
      strings.fieldValues.familiesOutside
    );
    updatedReferralBody = updateReferralSupportPartnerSharedWithProperty(
      referralBody,
      strings.fieldValues.familiesOutside
    );
  } else {
    updatedReferralBody = referralBody;
    updatedProgrammeBody = programmeBody;
  }

  return {
    updatedReferralBody: updatedReferralBody,
    updatedProgrammeBody: updatedProgrammeBody
  };
}

function updateReferralSupportPartnerSharedWithProperty(referralBody, org) {
  let updatedReferralBody = referralBody;
  if (!referralBody.supportPartnerSharedWith) {
    updatedReferralBody.supportPartnerSharedWith = [org];
  } else if (referralBody.supportPartnerSharedWith.includes(org) === false) {
    updatedReferralBody.supportPartnerSharedWith.push(org);
  }
  return updatedReferralBody;
}

function updateProgrammeSharedWithProperty(programmeBody, org) {
  let updatedProgrammeBody = programmeBody;
  if (!programmeBody.sharedWith) {
    updatedProgrammeBody.sharedWith = [org];
  } else if (programmeBody.sharedWith.includes(org) === false) {
    updatedProgrammeBody.sharedWith.push(org);
  }
  return updatedProgrammeBody;
}
