import React from "react";
import { Modal, Divider, Icon, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { strings, enums } from "../resources";
import { checkFormatIso, convertFromIso } from "../helpers";
class ComparisonModal extends React.Component {
  render = () => {
    let leftColumn = [],
      rightColumn = [],
      header = "",
      modalContent;
    if (this.props.auditObject.actionType === strings.fieldValues.dbChange) {
      if (this.props.auditObject.oldValues) {
        let oldValues = JSON.parse(this.props.auditObject.oldValues);

        delete oldValues.updated;

        Object.entries(oldValues).forEach((element, count) => {
          if (
            !enums.metadata.includes(element[0]) &&
            !element[0].includes("_initial")
          ) {
            leftColumn.push(
              <p key={count}>
                {this.props.stringsObject[element[0]]
                  ? this.props.stringsObject[element[0]]
                  : element[0]}
                {" : "}
                {typeof element[1] === "string"
                  ? checkFormatIso(element[1])
                    ? convertFromIso(element[1])
                    : element[1]
                  : JSON.stringify(element[1])}
              </p>
            );
          }
        });
      }
      if (this.props.auditObject.newValues) {
        let newValues;
        try {
          newValues = JSON.parse(this.props.auditObject.newValues);
        } catch (e) {
          newValues = this.props.auditObject.newValues;
        }

        delete newValues.updated;

        if (typeof newValues === "object") {
          Object.entries(newValues).forEach((element, count) => {
            if (
              !enums.metadata.includes(element[0]) &&
              !element[0].includes("_initial")
            ) {
              rightColumn.push(
                <p key={count}>
                  {this.props.stringsObject[element[0]]
                    ? this.props.stringsObject[element[0]]
                    : element[0]}
                  {" : "}
                  {typeof element[1] === "string"
                    ? checkFormatIso(element[1])
                      ? convertFromIso(element[1])
                      : element[1]
                    : JSON.stringify(element[1])}
                </p>
              );
            }
          });
        } else {
          rightColumn.push(newValues);
        }
      }

      header = (
        <Modal.Header>{`${this.props.auditObject.dbActionType} - ${
          this.props.auditObject.table
        }${
          this.props.auditObject.crmId
            ? " - " + this.props.auditObject.crmId
            : ""
        }`}</Modal.Header>
      );
      modalContent = (
        <Modal.Content>
          <Modal.Content className="comparisonModal">
            <h3>{this.props.auditObject.oldDate}</h3>
            <Modal.Description>{leftColumn}</Modal.Description>
          </Modal.Content>
          <Divider vertical>
            <Icon className="diffArrow" name="arrow right" />
          </Divider>
          <Modal.Content className="comparisonModal">
            <h3>{this.props.auditObject.newDate}</h3>
            <Modal.Description>{rightColumn}</Modal.Description>
          </Modal.Content>
        </Modal.Content>
      );
    } else {
      header = (
        <Modal.Header>{`${this.props.auditObject.actionType} - ${
          this.props.auditObject.page ? this.props.auditObject.page + " - " : ""
        }${this.props.auditObject.username}`}</Modal.Header>
      );
      modalContent = (
        <Modal.Content className="comparisonModal">
          <Modal.Description>
            <p>{strings.modal.ipAddress + this.props.auditObject.ipAddress}</p>
          </Modal.Description>

          <Button
            className="sessionButton"
            onClick={() => {
              this.props.viewSession(this.props.auditObject.sessionId);
            }}
          >
            {`View this session for ${this.props.auditObject.username}`}
          </Button>
        </Modal.Content>
      );
    }
    return (
      <Modal open={this.props.modalOpen} onClose={this.props.handleClose}>
        {header}
        {modalContent}
      </Modal>
    );
  };
}

ComparisonModal.propTypes = {
  oldDate: PropTypes.string,
  newDate: PropTypes.string,
  newValues: PropTypes.string,
  modalOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

export default ComparisonModal;
