const outcomeOptions = [
  { key: 0, text: "Mental Health", value: "Mental Health" },
  { key: 1, text: "Physical Health", value: "Physical Health" },
  { key: 2, text: "Substance Misuse", value: "Substance Misuse" },
  { key: 3, text: "Accommodation", value: "Accommodation" },
  { key: 4, text: "Relationships", value: "Relationships" },
  { key: 5, text: "Finance", value: "Finance" },
  { key: 6, text: "Employment", value: "Employment" },
  { key: 7, text: "Education & training", value: "Education & training" },
  { key: 8, text: "Attitudes & behaviours", value: "Attitudes & behaviours" },
  { key: 9, text: "Social & Leisure", value: "Social & Leisure" }
];

export { outcomeOptions };
