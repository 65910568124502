import React from "react";
import ModifyTtgRecord from "../../../TtgRecord/components/ModifyTtgRecord";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { ttgProgrammeActions } from "../../store/actions/ttgProgramme.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { authActions } from "../../../App/store/actions/auth.actions";
import { auditActions } from "../../../Audit/store/actions/audit.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { fileActions } from "../../../File/store/actions/file.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  getOrganisations,
  convertToIso,
  canUserModifyRecord,
  getUsers,
  incrementObject,
  handleLogout,
  commitFileChanges,
  textFormattingHelpers
} from "../../helpers/index";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";

class TtgProgrammeEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    shareTtgProgrammeModalOpen: false,
    enableDisableTtgProgrammeModalOpen: false,
    initialTtgProgrammeData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    hasChoseForm: false,
    hasFileChanges: false,
    deletionReason: undefined,
    permissionStatus: "",
    showAuditLog: false,
    userList: []
  };

  chooseForm = async () => {
    this.setState({ hasChoseForm: true });
  };

  componentDidMount = async () => {
    await this.props.getTtgProgrammeById(
      this.props.match.params.id,
      this.props.headers
    );
    localStorage.setItem("ttgProgrammeId", this.props.match.params.id);
    localStorage.setItem(
      "ttgProgrammeName",
      `${this.props.selectedTtgProgrammeData.firstName} ${this.props.selectedTtgProgrammeData.surname}`
    );
    localStorage.setItem(
      "ttgProgrammeArea",
      this.props.selectedTtgProgrammeData.area
    );
    if (!incrementObject(this.props.match.params.id)) {
      this.props.lockUser(this.props.header);
      handleLogout(
        this.props.userId,
        this.props.username,
        this.props.headers,
        this.props.createAudit,
        this.props.logout
      );
    }
    await this.handleFetchData();
    let form = document.getElementById("ttgProgrammeForm");
    if (form) form.setAttribute("novalidate", true);

    this.setState({
      initialTtgProgrammeData: this.props.selectedTtgProgrammeData,
      userList: getUsers(this.props.userListData)
    });
    //set the form to have the novalidate attribute to suppress the default html validation tooltips

    if (
      this.state.mode === "view" &&
      this.props.selectedTtgProgrammeData.requestId
    ) {
      await this.props.getDeletePermissionStatus(
        this.props.selectedTtgProgrammeData.requestId
      );
      await this.setState(
        { permissionStatus: this.props.permissionStatus },
        async () => {
          if (this.state.permissionStatus === strings.states.rejected) {
            await this.props.updateSelectedTtgProgramme("requestId", null);
            this.props.updateTtgProgramme(
              this.props.selectedTtgProgrammeData.ttgProgrammeId,
              this.props.selectedTtgProgrammeData,
              this.props.headers,
              this.state.initialTtgProgrammeData,
              false
            );
          }
        }
      );
      this.props.clearAmendmentRequest();
    }
    this.setState({
      initialTtgProgrammeData: this.props.selectedTtgProgrammeData
    });
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  componentWillUnmount() {
    this.props.clearTtgProgrammeRequest();
    this.props.clearSelectedTtgProgramme();
    this.props.clearAmendmentRequest();
  }

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getUsers(
      this.props.headers,
      "ttg",
      10000,
      0,
      sCrit.clear,
      ["area.normalizer"],
      sCrit.sort,
      sCrit.activeOnly
    );
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  validateForm = async () => {
    let details = JSON.parse(
      JSON.stringify(this.props.selectedTtgProgrammeData)
    );
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.ttgProgrammes.modifyTtgProgramme
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  allowNumericalInput = e => {
    e = e || window.event;
    const charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedTtgProgrammeData.ttgProgrammeId,
      this.state.deletionReason.value,
      strings.amendments.fullName,
      this.props.selectedTtgProgrammeData.crmId,
      this.props.headers,
      this.props.selectedTtgProgrammeData
    );
    this.props.updateSelectedTtgProgramme("requestId", this.props.requestId);
    this.props.updateTtgProgramme(
      this.props.selectedTtgProgrammeData.ttgProgrammeId,
      this.props.selectedTtgProgrammeData,
      this.props.headers,
      this.state.initialTtgProgrammeData
    );
    this.setState({
      initialTtgProgrammeData: this.props.selectedTtgProgrammeData
    });

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearTtgProgrammeRequest();
    });
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteTtgProgramme(
      this.props.selectedTtgProgrammeData.ttgProgrammeId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      await this.props.setAmendmentStatusActioned(
        this.props.selectedTtgProgrammeData.requestId,
        this.props.headers,
        true,
        {
          entity: strings.amendments.entity,
          id: this.props.selectedTtgProgrammeData.ttgProgrammeId,
          type: strings.amendments.deletion
        }
      );

      this.props.history.push("/ttgProgrammes");
    }
    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearTtgProgrammeRequest();
    });
  };

  handleSubmit = async () => {
    if (this.state.hasFileChanges) {
      await commitFileChanges(
        this.props.commitChanges,
        this.props.localData,
        this.props.remoteData,
        strings.folder.ttgProgrammeFiles,
        strings.entity.ttgProgramme,
        this.props.selectedTtgProgrammeData.ttgProgrammeId
      );
    }

    const data = textFormattingHelpers.decodeData(
      this.props.selectedTtgProgrammeData,
      this.state.initialTtgProgrammeData,
      textFields
    );

    await this.props.updateTtgProgramme(
      this.props.match.params.id,
      data,
      this.props.headers,
      this.state.initialTtgProgrammeData
    );
    this.handleModeSwitch();
    window.setTimeout(() => {
      this.props.clearTtgProgrammeRequest();
    });
  };

  resetDataOnModalClose = async () => {
    await this.props.updateSelectedTtgProgramme(
      "sharedWith",
      this.state.initialTtgProgrammeData.sharedWith
    );
  };

  handleShare = async () => {
    let body = { sharedWith: this.props.selectedTtgProgrammeData.sharedWith };

    await this.props.updateTtgProgramme(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialTtgProgrammeData
    );
    this.setState({
      initialTtgProgrammeData: this.props.selectedTtgProgrammeData
    });
  };

  handleChange = (event, data) => {
    // Prevents "whoMetAtGate" from showing when "wantsCollected" is "Yes"
    if (data.name === "wantsCollected") {
      this.props.updateSelectedTtgProgramme("alternativeArrangements", "");
    }
    if (data.type === "checkbox") {
      this.props.updateSelectedTtgProgramme(data.name, data.checked);
    } else if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      this.props.updateSelectedTtgProgramme(
        data.name,
        convertToIso(data.value)
      );
    } else {
      this.props.updateSelectedTtgProgramme(data.name, data.value);
    }
  };

  resetForm = async () => {
    this.setState({ hasChoseForm: false });
    await this.props.updateSelectedTtgProgramme("engagementType", undefined);
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedTtgProgrammeData.active
    };

    await this.props.updateTtgProgramme(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialTtgProgrammeData
    );
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "shareModal":
        this.handleShare();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;

      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleShareTtgProgrammeModalVisibility = () => {
    this.setState({
      shareTtgProgrammeModalOpen: !this.state.shareTtgProgrammeModalOpen
    });
  };

  toggleEnableDisableTtgProgrammeModalVisibility = () => {
    this.setState({
      enableDisableTtgProgrammeModalOpen: !this.state
        .enableDisableTtgProgrammeModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialTtgProgrammeData) !==
        JSON.stringify(this.props.selectedTtgProgrammeData)
      ) {
        await this.props.cancelSelectedTtgProgrammeUpdate(
          this.state.initialTtgProgrammeData
        );
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialTtgProgrammeData: JSON.parse(
          JSON.stringify(this.props.selectedTtgProgrammeData)
        )
      });
    }
  };

  handleToggleAudit = () => {
    const isAudit = this.state.showAuditLog;
    this.setState({ showAuditLog: !isAudit });
  };

  filesHaveChanged = () => {
    this.setState({ hasFileChanges: true });
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.state.initialTtgProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.orgsLoadingPage ||
      this.props.usersLoadingPage ||
      this.props.amendmentLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.requestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          {this.props.showNotification && (
            <RequestFeedback
              className={
                this.props.amendmentRequestStatus ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.ttgProgrammeRequestStatus}
              requestMade={this.props.ttgProgrammeRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.ttgProgrammeRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyTtgRecord
            requestStatus={this.props.ttgProgrammeRequestStatus}
            mode={this.state.mode}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editTtgProgramme
                : strings.header.viewTtgProgramme
            }
            selectedTtgRecordData={this.props.selectedTtgProgrammeData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            history={this.props.history}
            shouldSaveFiles={this.state.shouldSaveFiles}
            filesHaveChanged={this.filesHaveChanged}
            hasFileChanges={this.state.hasFileChanges}
            headers={this.props.headers}
            hasPageLoaded={this.state.hasPageLoaded}
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            toggleEnableDisableRecordModalVisibility={
              this.toggleEnableDisableTtgProgrammeModalVisibility
            }
            enableDisableRecordModalOpen={
              this.state.enableDisableTtgProgrammeModalOpen
            }
            handleModeSwitch={this.handleModeSwitch}
            initialTtgRecordData={{}}
            handleToggleAudit={this.handleToggleAudit}
            showAuditLog={this.state.showAuditLog}
            userList={this.state.userList}
          />
        </div>
      </div>
    );
  };
}

TtgProgrammeEditContainer.propTypes = {
  cancelSelectedTtgProgrammeUpdate: PropTypes.func,
  clearSelectedTtgProgramme: PropTypes.func,
  clearTtgProgrammeRequest: PropTypes.func,
  deleteSuccessful: PropTypes.bool.isRequired,
  deleteTtgProgramme: PropTypes.func,
  error: PropTypes.string.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  getDeletePermissionStatus: PropTypes.func,
  getOrganisations: PropTypes.func,
  getTtgProgrammeById: PropTypes.func,
  getUsers: PropTypes.func,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  match: PropTypes.object,
  organisationListData: PropTypes.array.isRequired,
  organisationOfLoggedInUser: PropTypes.string.isRequired,
  orgsLoadingPage: PropTypes.bool.isRequired,
  permissionStatus: PropTypes.string.isRequired,
  requestDeletePermission: PropTypes.func,
  requestId: PropTypes.string.isRequired,
  result: PropTypes.string.isRequired,
  selectedTtgProgrammeData: PropTypes.object,
  setAmendmentStatusActioned: PropTypes.func,
  showNotification: PropTypes.bool.isRequired,
  ttgProgrammeRequestMade: PropTypes.bool.isRequired,
  ttgProgrammeRequestStatus: PropTypes.bool,
  updateSelectedTtgProgramme: PropTypes.func,
  updateTtgProgramme: PropTypes.func,
  userListData: PropTypes.array.isRequired,
  usersLoadingPage: PropTypes.bool
};

const mapStateToProps = state => {
  const { ttgProgrammes, amendments, organisations, users, file } = state;
  const { roleId, organisationOfLoggedInUser, userId, username } = state.auth;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const { userListData, loadingPage: usersLoadingPage } = users;
  const {
    loadingPage,
    selectedTtgProgrammeData,
    ttgProgrammeRequestStatus,
    ttgProgrammeRequestMade,
    result,
    error,
    errorDetails,
    deleteSuccessful,
    showNotification
  } = ttgProgrammes;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails,
    loadingPage: amendmentLoadingPage
  } = amendments;
  const { localData, remoteData } = file;

  return {
    loadingPage,
    selectedTtgProgrammeData,
    ttgProgrammeRequestStatus,
    ttgProgrammeRequestMade,
    result,
    error,
    errorDetails,
    organisationListData,
    userListData,
    roleId,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    organisationOfLoggedInUser,
    usersLoadingPage,
    orgsLoadingPage,
    amendmentRequestMade,
    amendmentRequestStatus,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails,
    userId,
    username,
    localData,
    remoteData,
    amendmentLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTtgProgramme: (
      id,
      data,
      headers,
      initialTtgProgrammeData,
      showNotification = true
    ) => {
      dispatch(
        ttgProgrammeActions.updateTtgProgramme(
          id,
          data,
          headers,
          initialTtgProgrammeData,
          showNotification
        )
      );
    },
    getTtgProgrammeById: async (id, headers) => {
      await dispatch(ttgProgrammeActions.getTtgProgrammeById(id, headers));
    },
    updateSelectedTtgProgramme: (key, value) => {
      dispatch(ttgProgrammeActions.updateSelectedTtgProgramme(key, value));
    },
    cancelSelectedTtgProgrammeUpdate: data => {
      dispatch(ttgProgrammeActions.cancelSelectedTtgProgrammeUpdate(data));
    },
    clearTtgProgrammeRequest: () => {
      dispatch(ttgProgrammeActions.clearTtgProgrammeRequest());
    },
    clearSelectedTtgProgramme: () => {
      dispatch(ttgProgrammeActions.clearSelectedTtgProgramme());
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getOrganisations: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },

    requestDeletePermission: async (
      id,
      reason,
      entity,
      recordIdentifier,
      headers,
      body
    ) => {
      await dispatch(
        amendmentActions.requestDeletePermission(
          id,
          reason,
          entity,
          recordIdentifier,
          headers,
          body
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteTtgProgramme: async (id, headers) => {
      await dispatch(ttgProgrammeActions.deleteTtgProgramme(id, headers));
    },
    setAmendmentStatusActioned: async (
      id,
      headers,
      sendNotification,
      entityDetails
    ) => {
      await dispatch(
        amendmentActions.setAmendmentStatusActioned(
          id,
          headers,
          sendNotification,
          entityDetails
        )
      );
    },
    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    lockUser: headers => {
      dispatch(userActions.lockUser(headers));
    },
    logout: hasExceeded => {
      dispatch(authActions.signOut(hasExceeded));
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    },
    commitChanges: (
      localData,
      remoteData,
      filePath,
      headers,
      entity,
      entityId,
      folder
    ) => {
      return dispatch(
        fileActions.commitChanges(
          localData,
          remoteData,
          filePath,
          headers,
          entity,
          entityId,
          folder
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TtgProgrammeEditContainer);
