export const textFields = [
  "stage1Q3",
  "stage1Q5",
  "stage1Q7",
  "stage1Q9",
  "stage1Q11",
  "stage2Q3",
  "stage2Q5",
  "stage2Q7",
  "stage2Q9",
  "stage2Q11",
  "stage2Q13",
  "stage2Q15",
  "stage3Q3",
  "stage3Q5",
  "stage3Q7",
  "stage3Q9",
  "stage3Q11",
  "stage3Q13",
  "stage3Q15",
  "additionalQ5"
];
