import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    header: {
      ttgRecordList: "Record",
      createTtgRecord: "Create Record"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        ttgRecordInfo: "Record Information"
      },
      label: {
        journeyStatus: "Journey Status",
        dateBcst1: "BCST part 1 completed",
        dateBcst2: "BCST part 2 signed & locked date",
        hasBcst2: "BCST part 2 signed & locked",
        hasBcst2Reason: "Reason BCST part 2 not signed & locked",
        hasBcst2ReasonOther: "Other Reason",
        cjSpecialist: "CJ Specialist",
        crmId: "CRM ID",
        firstName: "First Name",
        surname: "Last Name",
        markerRecord: "Marker Record?",

        dateOfBirth: "Date of Birth",
        pNumber: "Prison Number",
        prison: "Prison",
        otherPrison: "Other Prison",

        releaseDate: "Release Date",
        reasonForExit: "Reason for exit",
        reasonForOtherExit: "Reason for other exit",
        dateOfExit: "Date of Exit",
        sentIdentifiedNeeds:
          "Email to team in new establishment sent informing of identified needs?",
        transferDetails: "Transfer Details",
        dateEmailSent: "Date Email Sent (transfer)",
        hdcEligibilityDate: "HDC Eligibility Date",
        location: "Location (probation)",
        customerLocalAuthorityArea:
          "Customer Local Authority area (on release)",
        otherCustomerLocalAuthorityArea: "Other Customer LA (on release)",
        consent: "Agree to data protection",
        goodNewsStories: "Agree to New Routes good news stories",
        newRoutesMarketing: "Agree to New Routes Marketing",
        doNotApproach: "Anyone the customer does not wish to be approached",
        whoNotApproach: "Who not to approach",
        latestRiskRating: "Risk (from Delius)",
        areasOfNeedIdentified: "Areas of Need Identified?",
        accommodationNeed: "Accommodation Need?",
        accommodationNeedDate: "Accommodation Need Date Identified?",
        financeBenefitAndDebtNeed: "Finance, benefit & debt need?",
        financeBenefitAndDebtNeedDate:
          "Finance, benefit & debt need Date Identified",
        educationTrainingEmploymentNeed:
          "Education, Training Employment (ETE) need?",
        educationTrainingEmploymentNeedDate: "ETE Need Date Identified",
        domesticAbuseNeed: "Domestic Abuse need?",
        domesticAbuseNeedDate: "Domestic Abuse Need Date Identified",
        sexWorkNeed: "Sex work need?",
        sexWorkNeedDate: "Sex work Need Date Identified",
        otherNeed: "Other need?",
        otherNeedIdentified: "Other Need Identified",
        otherNeedDate: "Other Need Date Identified",

        resettlementPlanStarted: "Resettlement Plan Started",
        resettlementPlanCompleted: "Resettlement Plan Completed",
        contactDetails: "Contact Details",
        notes: "Notes"
      },
      placeholder: {
        journeyStatus: "- select -",
        dateBcst1: "",
        dateBcst2: "",
        hasBcst2: "- select -",
        hasBcst2Reason: "",
        hasBcst2ReasonOther: "Add a reason...",
        cjSpecialist: "- select -",
        crmId: "",
        firstName: "",
        surname: "",
        markerRecord: "- select -",
        dateOfBirth: "",
        pNumber: "",
        prison: "- select -",
        otherPrison: "",
        releaseDate: "",
        reasonForExit: "- select -",
        reasonForOtherExit: "",
        dateOfExit: "",
        sentIdentifiedNeeds: "- select -",
        transferDetails: "",
        dateEmailSent: "",
        hdcEligibilityDate: "",
        location: "- select -",
        customerLocalAuthorityArea: "- select -",
        otherCustomerLocalAuthorityArea: "",
        consent: "- select -",
        goodNewsStories: "- select -",
        newRoutesMarketing: "- select -",
        doNotApproach: "- select -",
        whoNotApproach: "",
        latestRiskRating: "- select -",
        areasOfNeedIdentified: "- select -",
        accommodationNeed: "- select -",
        accommodationNeedDate: "",
        financeBenefitAndDebtNeed: "- select -",
        financeBenefitAndDebtNeedDate: "",
        educationTrainingEmploymentNeed: "- select -",
        educationTrainingEmploymentNeedDate: "",
        domesticAbuseNeed: "- select -",
        domesticAbuseNeedDate: "",
        sexWorkNeed: "- select -",
        sexWorkNeedDate: "",
        otherNeed: "- select -",
        otherNeedIdentified: "",
        otherNeedDate: "",

        resettlementPlanStarted: "",
        resettlementPlanCompleted: "",
        contactDetails: "",
        notes: ""
      },
      dropDownOptions: {
        exit: "Exit",
        noStart: "No start",
        onProgramme: "On Programme",
        onProgrammeRemand: "On Programme (remand)",
        other: "Other",
        transferred: "Transferred",
        hmpDurham: "HMP Durham"
      },
      text: {
        yes: "Yes",
        no: "No",
        other: "Other",
        ttg: "TTG"
      },
      fileUpload: "Upload files",
      fields: {
        accommodationNeed: "accommodationNeed",
        accommodationNeedDate: "accommodationNeedDate",
        financeBenefitAndDebtNeed: "financeBenefitAndDebtNeed",
        financeBenefitAndDebtNeedDate: "financeBenefitAndDebtNeedDate",
        educationTrainingEmploymentNeed: "educationTrainingEmploymentNeed",
        educationTrainingEmploymentNeedDate:
          "educationTrainingEmploymentNeedDate",
        domesticAbuseNeed: "domesticAbuseNeed",
        domesticAbuseNeedDate: "domesticAbuseNeedDate",
        sexWorkNeed: "sexWorkNeed",
        sexWorkNeedDate: "sexWorkNeedDate",
        otherNeed: "otherNeed",
        otherNeedDate: "otherNeedDate",
        markerRecord: "markerRecord",
        prison: "prison",
        areasOfNeedIdentified: "areasOfNeedIdentified",
        area: "area"
      }
    },
    modal: {
      deleteTtgRecordMessage:
        "Are you sure you want to delete thisRecord? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    tooltip: {},
    table: {
      header: {
        cjSpecialist: "CJ Specialist",
        created: "Date Created",
        crmId: "CRM ID",
        journeyStatus: "Journey Status",
        dateBcst1: "BCST part 1 completed",
        dateBcst2: "BCST part 2 signed & locked date",
        hasBcst2: "BCST part 2 signed & locked",
        hasBcst2Reason: "Reason BCST part 2 not signed & locked",
        hasBcst2ReasonOther: "Other Reason",
        updated: "Last Updated"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    folder: {
      dummyFiles: "dummyFiles",
      ttgProgrammeFiles: "ttgProgrammeFiles"
    },
    entity: {
      dummy: "dummy",
      ttgProgramme: "ttgProgramme"
    }
  }
});
