import React from "react";
import ModifyCustomer from "../../components/ModifyCustomer";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  strings,
  referralToOptions,
  referralFromOptions,
  textFields
} from "../../resources";
import { customerActions } from "../../store/actions/customer.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { fileActions } from "../../../File/store/actions/file.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { authActions } from "../../../App/store/actions/auth.actions";
import { auditActions } from "../../../Audit/store/actions/audit.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  convertToIso,
  allowNumericalInput,
  commitFileChanges,
  diffObjects,
  incrementObject,
  handleLogout,
  textFormattingHelpers
} from "../../helpers/";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";

class CustomerEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    confirmSubmitAmendmentModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableCustomerModalOpen: false,
    initialCustomerData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    referralToOptions: undefined,
    referralFromOptions: undefined,
    hasFileChanges: false,
    deletionReason: undefined,
    amendmentReason: undefined,
    fetchedPermissionStatus: false,
    permissionStatus: "",
    showAuditLog: false
  };

  componentDidMount = async () => {
    try {
      await this.props.getCustomerById(
        this.props.match.params.id,
        this.props.headers
      );
      if (!incrementObject(this.props.match.params.id)) {
        this.props.lockUser(this.props.header);
        handleLogout(
          this.props.userId,
          this.props.username,
          this.props.headers,
          this.props.createAudit,
          this.props.logout
        );
      }
      //set the form to have the novalidate attribute to suppress the default html validation tooltips

      if (
        this.state.mode === "view" &&
        this.props.selectedCustomerData.requestId
      ) {
        await this.props.getDeletePermissionStatus(
          this.props.selectedCustomerData.requestId
        );
        if (this.props.permissionStatus === strings.states.rejected) {
          this.props.updateSelectedCustomer("requestId", null);
          this.props.updateCustomer(
            this.props.selectedCustomerData.gcrId,
            this.props.selectedCustomerData,
            this.props.headers,
            this.state.initialCustomerData,
            false
          );
        }
        this.setState(
          { permissionStatus: this.props.permissionStatus },
          async () => {
            if (this.state.permissionStatus === strings.states.rejected) {
              await this.props.updateSelectedCustomer("requestId", null);
              this.props.updateCustomer(
                this.props.selectedCustomerData.gcrId,
                this.props.selectedCustomerData,
                this.props.headers,
                this.state.initialCustomerData,
                false
              );
            }
          }
        );
        this.props.clearAmendmentRequest();
      }
    } catch (ex) {
      throw ex;
    } finally {
      this.setState({
        fetchedPermissionStatus: true,
        initialCustomerData: this.props.selectedCustomerData
      });
      let form = document.getElementById("engagementForm");
      if (form) {
        form.setAttribute("novalidate", true);
        //set the form to have the novalidate attribute to suppress the default html validation tooltips
      }
      if (this.props.selectedCustomerData.referralFrom) {
        this.filterReferralToOptions(
          this.props.selectedCustomerData.referralFrom
        );
      } else if (this.props.selectedCustomerData.referralTo) {
        this.filterReferralFromOptions(
          this.props.selectedCustomerData.referralTo
        );
      }
    }
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedCustomerData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.customers.modifyCustomerDirectly
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else if (!results && this.state.mode === "amend") {
      this.setState({ formInvalid: false });
      this.toggleConfirmSubmitAmendmentModalVisibility();
    } else if (!results && this.state.mode === "edit") {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    if (this.state.hasFileChanges) {
      await commitFileChanges(
        this.props.commitChanges,
        this.props.localData,
        this.props.remoteData,
        strings.folder.customerFiles,
        strings.entity.customer,
        this.props.selectedCustomerData.gcrId
      );
    }

    const data = textFormattingHelpers.decodeData(
      this.props.selectedCustomerData,
      this.state.initialCustomerData,
      textFields
    );

    await this.props.updateCustomer(
      this.props.match.params.id,
      data,
      this.props.headers,
      this.state.initialCustomerData
    );
    this.handleModeSwitch();
    window.setTimeout(() => {
      this.props.clearCustomerRequest();
    }, 10000);
  };

  handleAmendmentRequest = async () => {
    const initialCustomerData = this.state.initialCustomerData;
    const amendedCustomerData = this.props.selectedCustomerData;

    const { oldValues, newValues } = diffObjects(
      initialCustomerData,
      amendedCustomerData
    );

    this.props.createAmendmentRequest(
      this.props.selectedCustomerData.gcrId,
      oldValues,
      newValues,
      `${this.props.selectedCustomerData.firstName} ${this.props.selectedCustomerData.surname}, Prison Number: ${this.props.selectedCustomerData.pNumber}`,
      "Customer",
      this.state.amendmentReason
    );

    this.handleModeSwitch();
    this.resetReasonsOnModalClose();

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
    }, 10000);
  };

  handleAmendmentMode = () => {
    this.setState({
      mode: "amend",
      initialCustomerData: this.props.selectedCustomerData
    });
  };

  resetReasonsOnModalClose = () => {
    this.setState({ deletionReason: undefined, amendmentReason: undefined });
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedCustomerData.gcrId,
      this.state.deletionReason.value,
      strings.amendments.fullName,
      strings.amendments.fullName,
      this.props.headers,
      this.props.selectedCustomerData
    );
    this.props.updateSelectedCustomer("requestId", this.props.requestId);
    this.props.updateCustomer(
      this.props.selectedCustomerData.gcrId,
      this.props.selectedCustomerData,
      this.props.headers,
      this.state.initialCustomerData
    );
    this.setState({
      initialCustomerData: this.props.selectedCustomerData
    });

    window.setTimeout(() => {
      this.props.clearReferralRequest();
      this.props.clearAmendmentRequest();
    }, 10000);
  };

  handleModalInputChange = (e, data) => {
    if (data.name === "amendmentReason") {
      this.setState({ amendmentReason: data.value });
    } else {
      this.setState({ deletionReason: data });
    }
  };

  componentWillUnmount() {
    this.props.clearCustomerRequest();
    this.props.clearSelectedCustomer();
    this.props.clearAmendmentRequest();
  }

  handleChange = async (event, data) => {
    if (data.type === "checkbox") {
      await this.props.updateSelectedCustomer(data.name, data.checked);
    } else if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedCustomer(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedCustomer(data.name, data.value);
    }
    if (data.name === "referralFrom") {
      this.filterReferralToOptions(data.value);
    } else if (data.name === "referralTo") {
      this.filterReferralFromOptions(data.value);
    }
  };

  handleDelete = async () => {
    await this.props.deleteCustomer(
      this.props.selectedCustomerData.gcrId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      await this.props.setAmendmentStatusActioned(
        this.props.selectedCustomerData.requestId,
        this.props.headers,
        true,
        {
          entity: strings.amendments.entity,
          id: this.props.selectedCustomerData.gcrId,
          type: strings.amendments.deletion
        }
      );

      this.props.history.push("/customers");
    }
  };

  filterReferralToOptions = selectedOption => {
    if (selectedOption) {
      let newOptions = referralToOptions.filter(
        option => !option.value.includes(selectedOption)
      );

      this.setState({ referralToOptions: newOptions });
    } else {
      this.setState({ referralToOptions: referralToOptions });
    }
  };

  filterReferralFromOptions = selectedOption => {
    if (selectedOption) {
      let newOptions = referralFromOptions.filter(
        option => !option.value.includes(selectedOption)
      );
      this.setState({ referralFromOptions: newOptions });
    } else {
      this.setState({ referralFromOptions: referralFromOptions });
    }
  };

  filesHaveChanged = () => {
    this.setState({ hasFileChanges: true });
  };

  handleToggleAudit = () => {
    const isAudit = this.state.showAuditLog;
    this.setState({ showAuditLog: !isAudit });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;
      case "confirmSubmitAmendmentModal":
        this.handleAmendmentRequest();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableCustomerModalVisibility = () => {
    this.setState({
      enableDisableCustomerModalOpen: !this.state.enableDisableCustomerModalOpen
    });
  };

  toggleConfirmSubmitAmendmentModalVisibility = () => {
    this.setState({
      confirmSubmitAmendmentModalOpen: !this.state
        .confirmSubmitAmendmentModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialCustomerData) !==
        JSON.stringify(this.props.selectedCustomerData)
      ) {
        await this.props.cancelSelectedCustomerUpdate(
          this.state.initialCustomerData
        );
      }
      this.setState({ mode: "view", formInvalid: false });
    } else if (this.state.mode === "amend") {
      await this.props.cancelSelectedCustomerUpdate(
        this.state.initialCustomerData
      );
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialCustomerData: JSON.parse(
          JSON.stringify(this.props.selectedCustomerData)
        )
      });
    }
  };

  pageTitle = () => {
    switch (this.state.mode) {
      case "edit":
        return strings.header.editCustomer;
      case "view":
        return strings.header.viewCustomer;
      case "amend":
        return strings.header.amendCustomer;
      default:
        return;
    }
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      !this.state.fetchedPermissionStatus ||
      this.props.amendmentLoadingPage;

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.requestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          {this.props.showNotification && (
            <RequestFeedback
              className={
                this.props.amendmentRequestStatus ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.customerRequestStatus}
              requestMade={this.props.customerRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.customerRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyCustomer
            requestStatus={this.props.customerRequestStatus}
            mode={this.state.mode}
            pageTitle={this.pageTitle()}
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnableDisableCustomerModalVisibility={
              this.toggleEnableDisableCustomerModalVisibility
            }
            toggleConfirmSubmitAmendmentModalVisibility={
              this.toggleConfirmSubmitAmendmentModalVisibility
            }
            selectedCustomerData={this.props.selectedCustomerData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            confirmSubmitAmendmentModalOpen={
              this.state.confirmSubmitAmendmentModalOpen
            }
            enableDisableCustomerModalOpen={
              this.state.enableDisableCustomerModalOpen
            }
            initialCustomerData={this.state.initialCustomerData}
            formInvalid={this.state.formInvalid}
            allowNumericalInput={allowNumericalInput}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            referralToOptions={this.state.referralToOptions}
            referralFromOptions={this.state.referralFromOptions}
            headers={this.props.headers}
            filesHaveChanged={this.filesHaveChanged}
            hasFileChanges={this.state.hasFileChanges}
            loadingPage={this.props.loadingPage}
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            handleToggleAudit={this.handleToggleAudit}
            showAuditLog={this.state.showAuditLog}
            handleAmendmentMode={this.handleAmendmentMode}
            resetReasonsOnModalClose={this.resetReasonsOnModalClose}
            amendmentReason={this.state.amendmentReason}
          />
        </div>
      </div>
    );
  };
}

CustomerEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedCustomerData: PropTypes.object.isRequired,
  updateSelectedCustomer: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  getCustomerById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { customers, amendments, file, auth } = state;
  const {
    loadingPage,
    selectedCustomerData,
    customerRequestStatus,
    customerRequestMade,
    result,
    error,
    errorDetails,
    deleteSuccessful,
    showNotification
  } = customers;
  const { userId, username } = auth;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails,
    loadingPage: amendmentLoadingPage
  } = amendments;
  const { localData, remoteData } = file;
  return {
    loadingPage,
    selectedCustomerData,
    customerRequestStatus,
    customerRequestMade,
    result,
    error,
    errorDetails,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    localData,
    remoteData,
    amendmentRequestStatus,
    amendmentRequestMade,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails,
    userId,
    username,
    amendmentLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCustomer: (
      id,
      data,
      headers,
      initialCustomerData,
      showNotification = true
    ) => {
      dispatch(
        customerActions.updateCustomer(
          id,
          data,
          headers,
          initialCustomerData,
          showNotification
        )
      );
    },
    getCustomerById: async (id, headers) => {
      await dispatch(customerActions.getCustomerById(id, headers));
    },
    updateSelectedCustomer: (key, value) => {
      dispatch(customerActions.updateSelectedCustomer(key, value));
    },
    cancelSelectedCustomerUpdate: data => {
      dispatch(customerActions.cancelSelectedCustomerUpdate(data));
    },
    clearCustomerRequest: () => {
      dispatch(customerActions.clearCustomerRequest());
    },
    clearSelectedCustomer: () => {
      dispatch(customerActions.clearSelectedCustomer());
    },
    requestDeletePermission: async (
      id,
      reason,
      entity,
      recordIdentifier,
      headers,
      body
    ) => {
      await dispatch(
        amendmentActions.requestDeletePermission(
          id,
          reason,
          entity,
          recordIdentifier,
          headers,
          body
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteCustomer: async (id, headers) => {
      await dispatch(customerActions.deleteCustomer(id, headers));
    },
    setAmendmentStatusActioned: async (
      id,
      headers,
      sendNotification,
      entityDetails
    ) => {
      await dispatch(
        amendmentActions.setAmendmentStatusActioned(
          id,
          headers,
          sendNotification,
          entityDetails
        )
      );
    },
    commitChanges: (
      localData,
      remoteData,
      filePath,
      headers,
      entity,
      entityId,
      folder
    ) => {
      return dispatch(
        fileActions.commitChanges(
          localData,
          remoteData,
          filePath,
          headers,
          entity,
          entityId,
          folder
        )
      );
    },
    createAmendmentRequest: (
      id,
      oldValues,
      newValues,
      recordIdentifier,
      entity,
      reason,
      headers
    ) => {
      return dispatch(
        amendmentActions.createAmendmentRequest(
          id,
          oldValues,
          newValues,
          recordIdentifier,
          entity,
          reason,
          headers
        )
      );
    },
    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    lockUser: headers => {
      dispatch(userActions.lockUser(headers));
    },
    logout: hasExceeded => {
      dispatch(authActions.signOut(hasExceeded));
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerEditContainer);
