import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Form } from "semantic-ui-react";
import { ComponentHeader, BasicModal } from ".";
import { ValidatedFormInput } from "./validation";
import { permissions } from "../../App/resources";
import { strings } from "../resources";

class ModifySetting extends React.Component {
  renderEditButton() {
    return (
      <div className="containerButtons">
        <Button
          primary
          className="editButton"
          onClick={() => {
            this.props.handleModeSwitch("edit");
          }}
        >
          {strings.button.editLabel}
        </Button>
      </div>
    );
  }
  renderCancelSaveButton = () => {
    const {
      selectedSettingData,
      handleModeSwitch,
      handleModalConfirm,
      toggleCancelChangesModalVisibility,
      toggleConfirmSaveModalVisibility,
      initialSettingData,
      validateForm,
      confirmSaveModalOpen,
      mode,
      cancelChangesModalOpen,
      hasFileChanges,
      isDisabled
    } = this.props;

    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={validateForm}
            disabled={
              (JSON.stringify(selectedSettingData) ===
                JSON.stringify(initialSettingData) &&
                !hasFileChanges) ||
              isDisabled
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
            disabled={isDisabled}
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };

  renderForm = () => {
    return (
      <>
        <div className="infoHeader">{strings.form.header.settingInfo}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedSettingData.ttgPagesVisited}
              inputLabel={strings.form.label.ttgPagesVisited}
              inputName="ttgPagesVisited"
              inputPlaceholder={strings.form.placeholder.ttgPagesVisited}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.ttgPagesVisited}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedSettingData.ttgDurationVisited}
              inputLabel={strings.form.label.ttgDurationVisited}
              inputName="ttgDurationVisited"
              inputPlaceholder={strings.form.placeholder.ttgDurationVisited}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.ttgDurationVisited}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedSettingData.nrsPagesVisited}
              inputLabel={strings.form.label.nrsPagesVisited}
              inputName="nrsPagesVisited"
              inputPlaceholder={strings.form.placeholder.nrsPagesVisited}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.nrsPagesVisited}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedSettingData.nrsDurationVisited}
              inputLabel={strings.form.label.nrsDurationVisited}
              inputName="nrsDurationVisited"
              inputPlaceholder={strings.form.placeholder.nrsDurationVisited}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.nrsDurationVisited}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedSettingData.nrnePagesVisited}
              inputLabel={strings.form.label.nrnePagesVisited}
              inputName="nrnePagesVisited"
              inputPlaceholder={strings.form.placeholder.nrnePagesVisited}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.nrnePagesVisited}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedSettingData.nrneDurationVisited}
              inputLabel={strings.form.label.nrneDurationVisited}
              inputName="nrneDurationVisited"
              inputPlaceholder={strings.form.placeholder.nrneDurationVisited}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults.nrneDurationVisited
              }
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedSettingData.feedbackPassword}
              inputLabel={strings.form.label.feedbackPassword}
              inputName="feedbackPassword"
              inputPlaceholder={strings.form.placeholder.feedbackPassword}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.feedbackPassword}
            />
          </Form.Group>
        </div>
      </>
    );
  };

  render = () => {
    const { mode, pageTitle, requestStatus, formInvalid } = this.props;

    return (
      <div className="container">
        <ComponentHeader
          returnToParent={this.returnToParent}
          returnToList={this.returnToList}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderEditButton()}
          cancelSaveButtons={this.renderCancelSaveButton()}
          headerList={strings.header.settingList}
          permissionsUpdate={permissions.settings.update}
          permissionsCreate={permissions.settings.create}
          permissionsDelete={permissions.settings.delete}
          formInvalid={formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />
        <Form id="settingForm" className="infoBlock stickyForm">
          <fieldset disabled={mode === "view"}>{this.renderForm()}</fieldset>
        </Form>
      </div>
    );
  };
}

ModifySetting.propTypes = {
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModeSwitch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  initialSettingData: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  requestStatus: PropTypes.bool,
  selectedSettingData: PropTypes.object.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  validationResults: PropTypes.object
};

export default withRouter(ModifySetting);
