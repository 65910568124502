import React from "react";
import TableComponent from "../../components/OutcomeTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { strings, outcomesList } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../../Activities/components";
import { returnToParent } from "../../helpers/index";
const requestTimeout = 20000;

class OutcomeListContainer extends React.Component {
  state = {
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    size: 25,
    programmeId: localStorage.getItem("programmeId"),
    searchBarQuery: "",
    outcomes: []
  };

  componentDidMount = async () => {
    if (!localStorage.getItem("programmeId")) {
      this.props.history.goBack();
    }
    this.timerID = setTimeout(() => {
      if (
        this.props.programmeRequestStatus === undefined &&
        this.props.programmeRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
  }

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    await this.handleFetchData(this.state);
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleChange = event => {
    this.setState({ searchBarQuery: event.target.value });
  };

  handleFetchData = async state => {
    await this.props.getProgrammeOutcomes(
      this.state.programmeId,
      this.props.headers
    );
    let achievedOutcomes = this.props.outcomeListData;
    let combinedOutcomes = JSON.parse(JSON.stringify(outcomesList));
    achievedOutcomes.forEach(achievedOutcome => {
      combinedOutcomes.forEach((genericOutcome, index) => {
        if (achievedOutcome.outcomeName === genericOutcome.outcomeName) {
          combinedOutcomes[index] = achievedOutcome;
        }
      });
    });
    this.setState({ outcomes: combinedOutcomes });
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };

  render = () => {
    const loading = this.props.loadingPage;

    let parentPage;
    if (localStorage.getItem("programmeName")) {
      parentPage = localStorage.getItem("programmeName");
    } else if (localStorage.getItem("referralName")) {
      parentPage = localStorage.getItem("referralName");
    }

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          <RequestFeedback
            requestStatus={this.props.programmeRequestStatus}
            requestMade={this.props.programmeRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.outcomeRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={strings.form.feedback.outcomeRequestUnknown}
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            outcomeListData={this.state.outcomes}
            handleRefresh={this.handleRefresh}
            pageSize={this.state.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalOutcomes={this.props.totalOutcomes}
            updatePageIndex={this.updatePageIndex}
            roleId={this.props.roleId}
            hideCreate={true}
            searchBarQuery={this.state.searchBarQuery}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            referralId={this.props.referralId}
            hideSearchBar={true}
            parentPage={parentPage}
            returnToParent={() => {
              returnToParent(this.props.history);
            }}
          />
        </div>
      </div>
    );
  };
}

OutcomeListContainer.propTypes = {
  outcomeListData: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getProgrammeOutcomes: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.object
};

const mapStateToProps = state => {
  const { programmes, auth } = state;
  const { roleId } = auth;
  const {
    outcomeListData,
    loadingPage,
    programmeRequestStatus,
    programmeRequestMade,
    result,
    error,
    totalOutcomes,
    errorDetails
  } = programmes;
  return {
    outcomeListData,
    loadingPage,
    programmeRequestStatus,
    programmeRequestMade,
    roleId,
    result,
    error,
    totalOutcomes,
    errorDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProgrammeOutcomes: async (id, headers) => {
      await dispatch(programmeActions.getProgrammeOutcomes(id, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutcomeListContainer);
