import axios from "./axios-programmes";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createProgramme = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error creating programme: ", error.response.data);
          reject(error);
        });
    });
  };

  updateProgramme = (id, details, isSupportReferralTask) => {
    const config = {
      headers: this.headers,
      params: { isSupportReferralTask: isSupportReferralTask }
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating programme: ", error.message);
          reject(error);
        });
    });
  };

  deleteProgramme = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, null, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting programme: ", error.message);
          reject(error);
        });
    });
  };

  getProgrammes = (queryString, size, from, fields, sort, activeOnly) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly
    };

    return new Promise((resolve, reject) => {
      axios
        .post("/list-programmes/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting programmes: ", error.message);
          reject(error);
        });
    });
  };

  getProgrammeById = (id, isSupportReferralTask) => {
    const config = {
      headers: this.headers,
      params: { isSupportReferralTask: isSupportReferralTask }
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting programme: ", error.message);
          reject(error);
        });
    });
  };

  outcomesStatistics = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/outcomes/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting programme's outcomes: ", error.message);
          reject(error);
        });
    });
  };
}
