import React from "react";
import ModifyNews from "../../components/ModifyNews";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { newsActions } from "../../store/actions/news.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import PropTypes from "prop-types";
import {
  convertToIso,
  canUserModifyRecord,
  textFormattingHelpers
} from "../../helpers";

class NewsCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableNewsModalOpen: false,
    formInvalid: false,
    validationResults: {},
    isPrePopulated: false,
    hasPageLoaded: false
  };

  componentDidMount = async () => {
    this.props.getProgrammeById(localStorage.getItem("programmeId"));
    let form = document.getElementById("newsForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    await this.props.updateSelectedNews(
      "programmeId",
      localStorage.getItem("programmeId")
    );
    this.setState({ hasPageLoaded: true });
  };

  componentDidUpdate = () => {
    if (
      Object.keys(this.props.selectedProgrammeData).length > 0 &&
      !this.state.isPrePopulated
    ) {
      if (this.props.selectedProgrammeData.owner) {
        this.props.updateSelectedNews(
          "owner",
          this.props.selectedProgrammeData.owner
        );
      }
      if (this.props.selectedProgrammeData.area) {
        this.props.updateSelectedNews(
          "area",
          this.props.selectedProgrammeData.area
        );
      }
      if (this.props.selectedProgrammeData.firstName) {
        this.props.updateSelectedNews(
          "firstName",
          this.props.selectedProgrammeData.firstName
        );
      }
      if (this.props.selectedProgrammeData.surname) {
        this.props.updateSelectedNews(
          "surname",
          this.props.selectedProgrammeData.surname
        );
      }
      if (this.props.selectedProgrammeData.pNumber) {
        this.props.updateSelectedNews(
          "pNumber",
          this.props.selectedProgrammeData.pNumber
        );
      }
      if (this.props.selectedProgrammeData.scroNumber) {
        this.props.updateSelectedNews(
          "scroNumber",
          this.props.selectedProgrammeData.scroNumber
        );
      }
      this.setState({ isPrePopulated: true });
    }
  };

  componentWillUnmount() {
    this.props.clearSelectedNews();
  }

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedNewsData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    this.setState({ formInvalid: false });
    this.toggleConfirmSaveModalVisibility();
  };

  handleSubmit = async () => {
    const data = textFormattingHelpers.decodeData(
      this.props.selectedNewsData,
      {},
      textFields
    );

    await this.props.createNews(data, this.props.headers);
    this.props.history.push("/news");
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearNewsRequest();
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedNews(data.name, convertToIso(data.value));
    } else {
      await this.props.updateSelectedNews(data.name, data.value);
    }
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableNewsModalVisibility = () => {
    this.setState({
      enableDisableNewsModalOpen: !this.state.enableDisableNewsModalOpen
    });
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading = this.props.loadingPage || this.props.programmeLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <ModifyNews
            mode="create"
            pageTitle={strings.header.createNews}
            selectedNewsData={this.props.selectedNewsData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            enableDisableNewsModalOpen={this.state.enableDisableNewsModalOpen}
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableNewsModalVisibility={
              this.toggleEnableDisableNewsModalVisibility
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            initialNewsData={{}}
            hasPageLoaded={this.state.hasPageLoaded}
            headers={this.props.headers}
            isDisabled={this.isDisabled()}
          />
        </div>
      </div>
    );
  };
}

NewsCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedNewsData: PropTypes.object.isRequired,
  updateSelectedNews: PropTypes.func.isRequired,
  clearSelectedNews: PropTypes.func.isRequired,
  createNews: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { news, auth, organisations, referrals, programmes, users } = state;
  const { loadingPage, selectedNewsData } = news;
  const { organisationOfLoggedInUser } = auth;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const { selectedReferralData, referralRequestStatus } = referrals;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  const { userListData, loadingPage: usersLoadingPage } = users;

  return {
    loadingPage,
    selectedNewsData,
    organisationListData,
    selectedReferralData,
    selectedProgrammeData,
    referralRequestStatus,
    userListData,
    organisationOfLoggedInUser,
    programmeLoadingPage,
    orgsLoadingPage,
    usersLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createNews: async (data, headers) => {
      await dispatch(newsActions.createNews(data, headers));
    },
    updateSelectedNews: (key, value) => {
      dispatch(newsActions.updateSelectedNews(key, value));
    },
    clearSelectedNews: () => {
      dispatch(newsActions.clearSelectedNews());
    },
    clearNewsRequest: () => {
      dispatch(newsActions.clearNewsRequest());
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsCreateContainer);
