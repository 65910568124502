import React from "react";
import PropTypes from "prop-types";
import FormBoxWithHeader from "../../App/components/FormBoxWithHeader";
import { strings } from "../resources";
import { providerOptions } from "../../SupportReferral/resources";
import { convertFromIso, dropdownHelper } from "../helpers/index";
import {
  ValidatedFormInput,
  ValidatedDateInput,
  ValidatedFormTextArea,
  ValidatedFormDropdown
} from "./validation";
import { Form } from "semantic-ui-react";

export default function SupportReferralFields({
  supportReferralDetails,
  handleChange
}) {
  if (supportReferralDetails) {
    return (
      <FormBoxWithHeader
        isFormWrapper
        header={strings.form.header.supportReferralDetails}
      >
        <Form.Group>
          <ValidatedFormDropdown
            inputData={supportReferralDetails.provider}
            viewData={
              dropdownHelper.getNameFromValues(
                supportReferralDetails.provider,
                providerOptions
              ) || ""
            }
            dropdownLabel={strings.form.label.provider}
            dropdownName="provider"
            dropdownOptions={providerOptions}
            dropdownPlaceholder={strings.form.placeholder.provider}
            formFieldWidth={8}
            handleChange={handleChange}
            dropdownDisabled
          />
          <ValidatedDateInput
            inputId="dateReferred"
            inputLabel={strings.form.label.dateReferred}
            inputName="dateReferred"
            inputPlaceholder={strings.form.placeholder.dateReferred}
            inputDateFormat="DD/MM/YYYY"
            inputData={
              supportReferralDetails.dateReferred
                ? convertFromIso(supportReferralDetails.dateReferred)
                : ""
            }
            iconPosition="left"
            formFieldWidth={8}
            handleChange={handleChange}
            disabled
          />
        </Form.Group>
        <Form.Group>
          <ValidatedFormInput
            inputData={supportReferralDetails.stageOfReferral}
            inputLabel={strings.form.label.stageOfReferral}
            inputName="stageOfReferral"
            inputPlaceholder={strings.form.placeholder.stageOfReferral}
            formFieldWidth={8}
            handleChange={handleChange}
            disabled
          />
          <ValidatedFormTextArea
            inputData={supportReferralDetails.reasonForReferral}
            inputLabel={strings.form.label.reasonForReferral}
            inputName="reasonForReferral"
            inputPlaceholder={strings.form.placeholder.reasonForReferral}
            formFieldWidth={8}
            handleChange={handleChange}
            disabled
            formInvalid={false}
          />
        </Form.Group>
      </FormBoxWithHeader>
    );
  } else {
    return null;
  }
}

SupportReferralFields.propTypes = {
  supportReferralDetails: PropTypes.object.isRequired,
  handleChange: PropTypes.func
};
