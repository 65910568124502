import { fileConstants } from "../actions/file.actionTypes";
import fileHelper from "../../helpers/fileHelper";

const initialState = {
  remoteData: [],
  localData: [],
  loadingFileData: false,
  uploadProgress: 0,
  isUploading: false,
  request: "",
  error: ""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case fileConstants.PURGE_STATE:
      return { ...initialState };

    case fileConstants.GET_FILES_REQUEST:
      return { ...state, request: action.payload };
    case fileConstants.GET_FILES_SUCCESS:
      return {
        ...state,
        remoteData: {
          ...state.remoteData,
          [action.folder]: fileHelper.mapToRemoteData(
            action.payload.data.content || {}
          )
        },
        localData: {
          ...state.localData,
          [action.folder]: fileHelper.mapToLocalData(
            action.payload.data.content || {}
          )
        }
      };
    case fileConstants.GET_FILES_FAILURE:
      return { ...state, error: action.payload };

    case fileConstants.COMMIT_FILE_CHANGES_REQUEST:
      return { ...state, request: action.payload };
    case fileConstants.COMMIT_FILE_CHANGES_SUCCESS:
      return { ...state, request: action.payload };
    case fileConstants.COMMIT_FILE_CHANGES_FAILURE:
      return { ...state, error: action.payload };

    case fileConstants.ADD_LOCAL_FILE_SUCCESS:
      let newLocalDataAdd;
      if (action.payload.folder) {
        if (!state.localData[action.payload.folder]) {
          state.localData[action.payload.folder] = [];
        }
        newLocalDataAdd = state.localData[action.payload.folder].slice();
        newLocalDataAdd.push(action.payload);
      }

      return {
        ...state,
        localData: {
          ...state.localData,
          [action.payload.folder]: newLocalDataAdd
        }
      };

    case fileConstants.UPDATE_LOCAL_FILES_SUCCESS:
      const newLocalDataUpdate = state.localData[action.folder].slice();
      newLocalDataUpdate[action.payload.key] = action.payload.value;
      return {
        ...state,
        localData: {
          ...state.localData,
          [action.folder]: newLocalDataUpdate
        }
      };

    case fileConstants.SET_LOADING_FILES_SUCCESS:
      return { ...state, loadingFileData: action.payload };

    case fileConstants.SET_UPLOAD_PROGRESS:
      return { ...state, uploadProgress: action.payload };

    case fileConstants.SET_SHOULD_UPLOAD:
      return { ...state, shouldUpload: action.payload };

    case fileConstants.SET_UPLOADING:
      return { ...state, isUploading: action.payload };

    case fileConstants.DELETE_LOCAL_FILE_SUCCESS:
      const newLocalDataDelete = state.localData[action.folder].slice();
      for (let i = newLocalDataDelete.length - 1; i >= 0; i--) {
        if (i === action.payload) {
          newLocalDataDelete.splice(i, 1);
          break;
        }
      }
      return {
        ...state,
        localData: {
          ...state.localData,
          [action.folder]: newLocalDataDelete
        }
      };

    default:
      return state;
  }
}
