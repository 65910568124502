import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      entity: "organisations",
      deletion: "deletion"
    },
    header: {
      login: "Login",
      organisationList: "Organisations",
      createOrganisation: "New Organisation",
      editOrganisation: "Edit Organisation",
      viewOrganisation: "Organisation Details",
      loading: "Loading Information"
    },
    form: {
      header: {
        organisationInfo: "Organisation Information",
        contactInfo: "Contact Information",
        organisationsLink: "Organisations",
        clientsLink: "Clients",
        supportLink: "Support",
        settingsLink: "Settings"
      },
      label: {
        organisationName: "Organisation Name",
        address1: "Address Line 1",
        address2: "Address Line 2",
        postcode: "Postcode",
        phone: "Phone",
        email: "Email",
        contactName: "Contact Name",
        contactPhone: "Contact Phone",
        contactEmail: "Contact Email",
        mainType: "Organisation Type",
        lastContactProgramme: "Last Contact Programme",
        lastContactBy: "Last Contact By",
        sicCode: "SIC Code",
        socCode: "SOC Code",
        osCode: "OS Code",
        localAuthorityArea: "Local Authority Area",
        prisons: "Prisons",
        website: "Website",
        mainCategory: "Main Category",
        postcodeSearch: "Postcode Search"
      },
      placeholder: {
        stateReason: "State your reason...",
        organisationName: "",
        address1: "",
        address2: "",
        postcode: "",
        phone: "",
        email: "",
        contactName: "",
        contactPhone: "",
        contactEmail: "",
        mainType: "- select -",
        lastContactProgramme: "- select -",
        lastContactBy: "",
        sicCode: "",
        socCode: "",
        osCode: "",
        localAuthorityArea: "- search and select -",
        prisons: "- search and select -",
        website: "",
        mainCategory: "- select -"
      },
      feedback: {
        processing: "Processing request...",
        organisationRequestSuccess: "Organisation created successfully!",
        organisationRequestRefreshPrompt:
          "Use the refresh function to see an updated organisations list.",
        organisationRequestFailure: "Organisation creation failed.",
        organisationRequestUnknown:
          "Something went wrong with the organisation request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      validation: {
        mainType: "^Please select an organisation type.",
        organisationName: "^Please enter a valid organisation name.",
        address1: "^Please enter the first address line.",
        address2: "^Please enter the second address line.",
        phone: "^Please enter a valid phone number with only digits.",
        email: "^Please enter a valid email address.",
        postcode:
          "^Please enter a valid postcode. Refer to the tooltip for the correct format.",
        contactName: "^Please enter a contact name.",
        contactPhone:
          "^Please enter a valid contact phone number. Only digits are allowed.",
        contactEmail: "^Please enter a valid email address.",
        lastContactProgramme: "^Please select the last programme carried out",
        lastContactBy: "Please enter the last person that was contacted.",
        sicCode: "Please enter the employer's SIC code."
      }
    },
    modal: {
      createOrganisationMessage: "Save organisation?",
      modifyOrganisationMessage: "Are you sure you want to save these changes?",
      deleteOrganisationMessage:
        "Are you sure you want to delete this organisation? (This action cannot be undone)",
      deleteContactMessage:
        "Are you sure you want to delete this contact? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this organisation's status to active?",
      setInactiveMessage: "Set this organisation's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      addOrganisationLabel: "New Organisation",
      addContact: "Add Contact",
      removeContact: "Remove Contact",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form",
      searchLabel: "Search"
    },
    tooltip: {
      editOrganisation: "Edit Organisation",
      deleteOrganisation: "Delete Organisation",
      toggleStatus: "Click to change status",
      organisationTypeWarning:
        "Organisation Type cannot be changed after creation.",
      postcodeFormatHeader: "Postcode Format",
      postcodeFormatContent:
        "Please use the same format as one of the following: 'AA9A 9AA', 'A9A 9AA', 'A9 9AA', 'A99 9AA', 'AA9 9AA' or 'AA99 9AA'. Spaces aren't required.",
      websiteFormatHeader: "Website Format",
      websiteFormatContent: 'Website should be of the format "www.example.com"'
    },
    table: {
      header: {
        organisationName: "Organisation Name",
        address1: "Address Line 1",
        address2: "Address Line 2",
        postcode: "Postcode",
        phone: "Phone Number",
        email: "Email",
        contactName: "Contact Name",
        contactPhone: "Contact Phone",
        contactEmail: "Contact Email",
        mainType: "Organisation Type",
        lastContactProgramme: "Last Contact Programme",
        lastContactBy: "Last Contact By",
        createdDate: "Created",
        updatedDate: "Updated",
        active: "Active",
        mainCategory: "Category",
        website: "Website",
        contact: "Main Contact"
      },
      text: {
        noResults: "No results found."
      }
    },
    fieldValues: {
      yes: "Yes",
      no: "No",
      employer: "Employer",
      supportService: "Support Service"
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    }
  }
});
