export const caseloadActionTypes = {
  GET_CASELOADS_REQUEST: "GET_CASELOADS_REQUEST",
  GET_CASELOADS_SUCCESS: "GET_CASELOADS_SUCCESS",
  GET_CASELOADS_FAILURE: "GET_CASELOADS_FAILURE",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE",

  CLEAR_CREATE_CASELOAD_REQUEST: "CLEAR_CREATE_CASELOAD_REQUEST",
  CLEAR_CREATE_CASELOAD_FAILURE: "CLEAR_CREATE_CASELOAD_FAILURE",
  CLEAR_CREATE_CASELOAD_SUCCESS: "CLEAR_CREATE_CASELOAD_SUCCESS"
};
