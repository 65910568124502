import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import { convertFromIso } from "../../helpers/index";

import {
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormInput
} from "../validation";
import "../css/ProgrammeComponents.scss";
import {
  yesNoOptions,
  exitReasonOptions,
  typeOfExitOptions
} from "../../resources";

const ExitFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">{strings.form.header.exitHeader}</div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedDateInput
          inputId="actualEndDate"
          formInvalid={props.formInvalid}
          inputLabel={strings.form.label.actualEndDate}
          inputName="actualEndDate"
          inputPlaceholder={strings.form.placeholder.actualEndDate}
          inputDateFormat="DD/MM/YYYY"
          inputData={
            props.selectedProgrammeData.actualEndDate
              ? convertFromIso(props.selectedProgrammeData.actualEndDate)
              : ""
          }
          iconPosition="left"
          handleChange={props.handleChange}
          validationResult={props.validationResults.actualEndDate}
          formFieldWidth={8}
          required={
            props.selectedProgrammeData.journeyStatus ===
            strings.fieldValues.exit
          }
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.onRemand}
          viewData={props.selectedProgrammeData.onRemand}
          dropdownLabel={strings.form.label.onRemand}
          dropdownName="onRemand"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.onRemand}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.onRemand}
          required={false}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.typeOfExit}
          viewData={props.selectedProgrammeData.typeOfExit}
          dropdownLabel={strings.form.label.typeOfExit}
          dropdownName="typeOfExit"
          dropdownOptions={typeOfExitOptions}
          dropdownPlaceholder={strings.form.placeholder.typeOfExit}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.typeOfExit}
          required={
            props.selectedProgrammeData.journeyStatus ===
            strings.fieldValues.exit
          }
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hasCompletedExitSurvey}
          viewData={props.selectedProgrammeData.hasCompletedExitSurvey}
          dropdownLabel={strings.form.label.hasCompletedExitSurvey}
          dropdownName="hasCompletedExitSurvey"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.hasCompletedExitSurvey}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.hasCompletedExitSurvey}
          required={
            props.selectedProgrammeData.journeyStatus ===
            strings.fieldValues.exit
          }
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.exitReason}
          viewData={props.selectedProgrammeData.exitReason}
          dropdownLabel={strings.form.label.exitReason}
          dropdownName="exitReason"
          dropdownOptions={exitReasonOptions}
          dropdownPlaceholder={strings.form.placeholder.exitReason}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.exitReason}
          required={
            props.selectedProgrammeData.journeyStatus ===
            strings.fieldValues.exit
          }
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
        {props.selectedProgrammeData.exitReason ===
          strings.fieldValues.other && (
          <ValidatedFormInput
            formInvalid={props.formInvalid}
            inputData={props.selectedProgrammeData.exitReasonOther}
            inputLabel={strings.form.label.exitReasonOther}
            inputName="exitReasonOther"
            inputPlaceholder={strings.form.placeholder.exitReasonOther}
            formFieldWidth={8}
            handleChange={props.handleChange}
            validationResult={props.validationResults.exitReasonOther}
            mode={props.mode}
            disabled={props.mode === "amend"}
          />
        )}
      </Form.Group>
      <Form.Group>
        <ValidatedDateInput
          inputId="dateOfLastContact"
          formInvalid={props.formInvalid}
          inputLabel={strings.form.label.dateOfLastContact}
          inputName="dateOfLastContact"
          inputPlaceholder={strings.form.placeholder.dateOfLastContact}
          inputDateFormat="DD/MM/YYYY"
          inputData={
            props.selectedProgrammeData.dateOfLastContact
              ? convertFromIso(props.selectedProgrammeData.dateOfLastContact)
              : ""
          }
          iconPosition="left"
          handleChange={props.handleChange}
          validationResult={props.validationResults.dateOfLastContact}
          formFieldWidth={8}
          required={
            props.selectedProgrammeData.journeyStatus ===
            strings.fieldValues.exit
          }
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
      </Form.Group>
    </div>
  </div>
);

ExitFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default ExitFields;
