export const ttgProgrammeActionTypes = {
  CREATE_TTG_PROGRAMME_REQUEST: "CREATE_TTG_PROGRAMME_REQUEST",
  CREATE_TTG_PROGRAMME_SUCCESS: "CREATE_TTG_PROGRAMME_SUCCESS",
  CREATE_TTG_PROGRAMME_FAILURE: "CREATE_TTG_PROGRAMME_FAILURE",

  CLEAR_CREATE_TTG_PROGRAMME_REQUEST: "CLEAR_CREATE_TTG_PROGRAMME_REQUEST",
  CLEAR_CREATE_TTG_PROGRAMME_FAILURE: "CLEAR_CREATE_TTG_PROGRAMME_FAILURE",
  CLEAR_CREATE_TTG_PROGRAMME_SUCCESS: "CLEAR_CREATE_TTG_PROGRAMME_SUCCESS",

  DELETE_TTG_PROGRAMME_REQUEST: "DELETE_TTG_PROGRAMME_REQUEST",
  DELETE_TTG_PROGRAMME_SUCCESS: "DELETE_TTG_PROGRAMME_SUCCESS",
  DELETE_TTG_PROGRAMME_FAILURE: "DELETE_TTG_PROGRAMME_FAILURE",

  UPDATE_SELECTED_TTG_PROGRAMME_DATA_REQUEST:
    "UPDATE_SELECTED_TTG_PROGRAMME_DATA_REQUEST",
  UPDATE_SELECTED_TTG_PROGRAMME_DATA_SUCCESS:
    "UPDATE_SELECTED_TTG_PROGRAMME_DATA_SUCCESS",
  UPDATE_SELECTED_TTG_PROGRAMME_DATA_FAILURE:
    "UPDATE_SELECTED_TTG_PROGRAMME_DATA_FAILURE",

  UPDATE_SELECTED_TTG_PROGRAMME_REQUEST:
    "UPDATE_SELECTED_TTG_PROGRAMME_REQUEST",
  UPDATE_SELECTED_TTG_PROGRAMME_SUCCESS:
    "UPDATE_SELECTED_TTG_PROGRAMME_SUCCESS",
  UPDATE_SELECTED_TTG_PROGRAMME_FAILURE:
    "UPDATE_SELECTED_TTG_PROGRAMME_FAILURE",

  CLEAR_SELECTED_TTG_PROGRAMME_REQUEST: "CLEAR_SELECTED_TTG_PROGRAMME_REQUEST",
  CLEAR_SELECTED_TTG_PROGRAMME_SUCCESS: "CLEAR_SELECTED_TTG_PROGRAMME_SUCCESS",
  CLEAR_SELECTED_TTG_PROGRAMME_FAILURE: "CLEAR_SELECTED_TTG_PROGRAMME_FAILURE",

  CANCEL_SELECTED_TTG_PROGRAMME_UPDATE_REQUEST:
    "CANCEL_SELECTED_TTG_PROGRAMME_UPDATE_REQUEST",
  CANCEL_SELECTED_TTG_PROGRAMME_UPDATE_SUCCESS:
    "CANCEL_SELECTED_TTG_PROGRAMME_UPDATE_SUCCESS",
  CANCEL_SELECTED_TTG_PROGRAMME_UPDATE_FAILURE:
    "CANCEL_SELECTED_TTG_PROGRAMME_UPDATE_FAILURE",

  GET_TTG_PROGRAMMES_REQUEST: "GET_TTG_PROGRAMMES_REQUEST",
  GET_TTG_PROGRAMMES_SUCCESS: "GET_TTG_PROGRAMMES_SUCCESS",
  GET_TTG_PROGRAMMES_FAILURE: "GET_TTG_PROGRAMMES_FAILURE",

  UPDATE_TTG_PROGRAMME_REQUEST: "UPDATE_TTG_PROGRAMME_REQUEST",
  UPDATE_TTG_PROGRAMME_SUCCESS: "UPDATE_TTG_PROGRAMME_SUCCESS",
  UPDATE_TTG_PROGRAMME_FAILURE: "UPDATE_TTG_PROGRAMME_FAILURE",

  GET_TTG_PROGRAMME_BY_ID_REQUEST: "GET_TTG_PROGRAMME_BY_ID_REQUEST",
  GET_TTG_PROGRAMME_BY_ID_SUCCESS: "GET_TTG_PROGRAMME_BY_ID_SUCCESS",
  GET_TTG_PROGRAMME_BY_ID_FAILURE: "GET_TTG_PROGRAMME_BY_ID_FAILURE",

  GENERATE_TTG_PROGRAMME_ID_REQUEST: "GENERATE_TTG_PROGRAMME_ID_REQUEST",
  GENERATE_TTG_PROGRAMME_ID_SUCCESS: "GENERATE_TTG_PROGRAMME_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE",

  GET_TTG_PROGRAMME_OUTCOMES_REQUEST: "GET_TTG_PROGRAMME_OUTCOMES_REQUEST",
  GET_TTG_PROGRAMME_OUTCOMES_SUCCESS: "GET_TTG_PROGRAMME_OUTCOMES_SUCCESS",
  GET_TTG_PROGRAMME_OUTCOMES_FAILURE: "GET_TTG_PROGRAMME_OUTCOMES_FAILURE"
};
