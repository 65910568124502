import { API } from "../../services";
import { amendmentActionTypes } from "./amendment.actionTypes";

const api = new API();

const requestDeletePermission = (
  id,
  reason,
  entity,
  recordIdentifier,
  headers,
  body,
  showNotification = true
) => {
  const request = () => {
    return { type: amendmentActionTypes.REQUEST_DELETE_PERMISSION_REQUEST };
  };
  const success = response => {
    return {
      type: amendmentActionTypes.REQUEST_DELETE_PERMISSION_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: amendmentActionTypes.REQUEST_DELETE_PERMISSION_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "requestDeletePermission"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api
          .requestDeletePermission(id, reason, entity, recordIdentifier, body)
          .then(
            data => {
              dispatch(success(data));
              resolve(data);
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          );
      }
    });
  };
};

const getDeletePermissionStatus = (id, headers) => {
  const request = () => {
    return { type: amendmentActionTypes.GET_DELETE_PERMISSION_STATUS_REQUEST };
  };
  const success = response => {
    return {
      type: amendmentActionTypes.GET_DELETE_PERMISSION_STATUS_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: amendmentActionTypes.GET_DELETE_PERMISSION_STATUS_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "getDeletePermissionStatus"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getDeletePermissionStatus(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const setAmendmentStatusActioned = (
  id,
  headers,
  showNotification = true,
  entityDetails = {}
) => {
  const request = () => {
    return {
      type: amendmentActionTypes.SET_AMENDMENT_STATUS_ACTIONED_REQUEST
    };
  };
  const success = response => {
    return {
      type: amendmentActionTypes.SET_AMENDMENT_STATUS_ACTIONED_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: amendmentActionTypes.SET_AMENDMENT_STATUS_ACTIONED_FAILURE,
      error,
      showNotification
    };
  };
  const clearProgrammeData = () => {
    localStorage.removeItem("programmeName");
    localStorage.removeItem("programmeId");
    localStorage.removeItem("programmeArea");
  };
  const clearTtgProgrammeData = () => {
    localStorage.removeItem("ttgProgrammeName");
    localStorage.removeItem("ttgProgrammeId");
    localStorage.removeItem("ttgProgrammeArea");
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "setAmendmentStatusActioned"
  });

  return dispatch => {
    dispatch(request());
    return new Promise(async (resolve, reject) => {
      await api.setAmendmentStatusActioned(id).then(
        response => {
          if (entityDetails.type === "deletion") {
            localStorage.setItem(
              "entityDetails",
              JSON.stringify(entityDetails)
            );

            if (entityDetails.entity === "programmes") {
              clearProgrammeData();
            } else if (entityDetails.entity === "ttgProgrammes") {
              clearTtgProgrammeData();
            }
          }

          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateAmendmentPermissionStatus = (
  id,
  approvalStatus,
  headers,
  showNotification = true,
  rejectionReason
) => {
  const request = () => {
    return {
      type: amendmentActionTypes.UPDATE_AMENDMENT_PERMISSION_STATUS_REQUEST
    };
  };
  const success = response => {
    return {
      type: amendmentActionTypes.UPDATE_AMENDMENT_PERMISSION_STATUS_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: amendmentActionTypes.UPDATE_AMENDMENT_PERMISSION_STATUS_FAILURE,
      error,
      showNotification
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "updateAmendmentPermissionStatus"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api
        .updateAmendmentPermissionStatus(id, approvalStatus, rejectionReason)
        .then(
          response => {
            dispatch(success(response.data));
            resolve(response.data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
    });
  };
};

const createAmendmentRequest = (
  id,
  oldValues,
  newValues,
  entity,
  recordIdentifier,
  reason,
  headers,
  showNotification = true
) => {
  const request = () => {
    return { type: amendmentActionTypes.CREATE_AMENDMENT_REQUEST_REQUEST };
  };
  const success = response => {
    return {
      type: amendmentActionTypes.CREATE_AMENDMENT_REQUEST_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: amendmentActionTypes.CREATE_AMENDMENT_REQUEST_FAILURE,
      error,
      showNotification
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "createAmendmentRequest"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api
          .createAmendmentRequest(
            id,
            oldValues,
            newValues,
            entity,
            recordIdentifier,
            reason
          )
          .then(
            data => {
              dispatch(success(data));
              resolve(data);
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          );
      }
    });
  };
};

const getAmendmentRequest = (id, headers) => {
  const request = () => {
    return { type: amendmentActionTypes.GET_AMENDMENT_REQUEST };
  };
  const success = response => {
    return {
      type: amendmentActionTypes.GET_AMENDMENT_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: amendmentActionTypes.GET_AMENDMENT_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "getAmendmentRequest"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getAmendmentRequest(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const clearAmendmentRequest = () => {
  const request = () => {
    return { type: amendmentActionTypes.CLEAR_AMENDMENT_REQUEST };
  };
  const success = () => {
    return { type: amendmentActionTypes.CLEAR_AMENDMENT_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

export const amendmentActions = {
  requestDeletePermission,
  getDeletePermissionStatus,
  setAmendmentStatusActioned,
  updateAmendmentPermissionStatus,
  createAmendmentRequest,
  getAmendmentRequest,
  clearAmendmentRequest
};
