import React from "react";
import { Modal, Icon, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { strings } from "../resources";

function ComparisonModal(props) {
  const [open, setOpen] = React.useState(false);

  const modalContent = () => {
    return (
      <>
        <Modal.Content>
          <h3>{props.confirmMessage}</h3>
        </Modal.Content>

        <Modal.Actions>
          <Button
            basic
            color="red"
            onClick={() => {
              setOpen(true);
              props.handleRejectionButton();
            }}
            style={{ display: props.showOkButton ? "" : "none" }}
          >
            <Icon name="x" /> {strings.button.reject}
          </Button>
          <Button
            basic
            color="green"
            onClick={() => {
              props.handleApprovalButton();
              props.handleClose();
            }}
            style={{ display: props.showOkButton ? "" : "none" }}
          >
            <Icon name="checkmark" /> {strings.button.approve}
          </Button>
        </Modal.Actions>
        {nestedModal}
      </>
    );
  };

  const nestedModal = (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        props.resetDataOnModalClose();
      }}
    >
      <Modal.Header>{strings.form.label.rejectionReason}</Modal.Header>
      <Modal.Content>
        <Modal.Description>{props.children}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          color="red"
          onClick={() => {
            setOpen(false);
            props.resetDataOnModalClose();
          }}
        >
          <Icon name="x" /> {strings.button.cancelLabel}
        </Button>
        <Button
          basic
          color="green"
          onClick={() => {
            props.handleModalConfirm(props.modalClassName);
            props.handleClose();
          }}
          disabled={props.inputValue.length < 1}
          style={{ display: props.showOkButton ? "" : "none" }}
          closeIcon
        >
          <Icon name="checkmark" /> {strings.button.submit}
        </Button>
      </Modal.Actions>
    </Modal>
  );

  return (
    <>
      <Modal
        open={props.modalOpen}
        onClose={() => props.handleClose()}
        closeIcon
      >
        {modalContent()}
      </Modal>
    </>
  );
}

ComparisonModal.propTypes = {
  children: PropTypes.any,
  handleApprovalButton: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func,
  handleRejectionButton: PropTypes.func,
  inputValue: PropTypes.shape({
    length: PropTypes.number
  }),
  modalClassName: PropTypes.any,
  modalOpen: PropTypes.bool,
  resetDataOnModalClose: PropTypes.func,
  showOkButton: PropTypes.any
};

export default ComparisonModal;
