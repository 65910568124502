export const isEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const listObjectValues = obj => {
  let values = [];
  Object.keys(obj).forEach(property => {
    values.push(obj[property]);
  });
  return values;
};

export const removeKeyFromObject = (obj, deleteKey) => {
  return Object.keys(obj, deleteKey)
    .filter(key => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = obj[current];
      return result;
    }, {});
};
