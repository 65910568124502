import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import { dropdownHelper } from "../../helpers/index";
import { filterOutUndesiredProps } from "../../helpers/index";
import { ShowIfSameArea } from "../../../App/components/";
import { ValidatedFormDropdown } from "../validation";
import "../css/ProgrammeComponents.scss";

const MentorFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">{strings.form.header.mentorHeader}</div>
    <div className="infoWrapper">
      <ShowIfSameArea area={props.area} allowedArea={strings.areas.NRS}>
        <Form.Group>
          <ValidatedFormDropdown
            formInvalid={props.formInvalid}
            inputData={props.selectedProgrammeData.prisonOrganisation}
            viewData={
              dropdownHelper.getNameFromValues(
                props.selectedProgrammeData.prisonOrganisation,
                props.organisations
              ) || ""
            }
            dropdownLabel={strings.form.label.prisonOrganisation}
            dropdownName="prisonOrganisation"
            dropdownOptions={filterOutUndesiredProps(props.organisations)}
            dropdownPlaceholder={strings.form.placeholder.prisonOrganisation}
            formFieldWidth={8}
            handleChange={props.handleChange}
            validationResult={props.validationResults.prisonOrganisation}
            mode={props.mode}
            dropdownDisabled={props.mode === "amend"}
          />
          <ValidatedFormDropdown
            formInvalid={props.formInvalid}
            inputData={
              props.selectedProgrammeData.prisonSpecialist || props.userId
            }
            viewData={
              dropdownHelper.getNameFromValues(
                props.selectedProgrammeData.prisonSpecialist,
                props.getMentors(
                  props.area,
                  props.selectedProgrammeData.prisonOrganisation
                )
              ) || ""
            }
            dropdownLabel={strings.form.label.prisonSpecialist}
            dropdownName="prisonSpecialist"
            dropdownPlaceholder={strings.form.placeholder.prisonSpecialist}
            dropdownOptions={
              props.getMentors(
                props.area,
                props.selectedProgrammeData.prisonOrganisation
              ) || []
            }
            formFieldWidth={8}
            handleChange={props.handleChange}
            validationResult={props.validationResults.prisonSpecialist}
            mode={props.mode}
            dropdownDisabled={props.mode === "amend"}
          />
        </Form.Group>
        <Form.Group>
          <ValidatedFormDropdown
            formInvalid={props.formInvalid}
            inputData={props.selectedProgrammeData.communityOrganisation}
            viewData={
              dropdownHelper.getNameFromValues(
                props.selectedProgrammeData.communityOrganisation,
                props.organisations
              ) || ""
            }
            dropdownLabel={strings.form.label.communityOrganisation}
            dropdownName="communityOrganisation"
            dropdownOptions={filterOutUndesiredProps(props.organisations)}
            dropdownPlaceholder={strings.form.placeholder.communityOrganisation}
            formFieldWidth={8}
            handleChange={props.handleChange}
            validationResult={props.validationResults.communityOrganisation}
            mode={props.mode}
            dropdownDisabled={props.mode === "amend"}
          />
          <ValidatedFormDropdown
            formInvalid={props.formInvalid}
            inputData={props.selectedProgrammeData.communityMentor}
            viewData={
              dropdownHelper.getNameFromValues(
                props.selectedProgrammeData.communityMentor,
                props.getMentorsAll(props.area)
              ) || ""
            }
            dropdownLabel={strings.form.label.communityMentor}
            dropdownName="communityMentor"
            dropdownOptions={props.getMentorsAll(props.area) || []}
            dropdownPlaceholder={strings.form.placeholder.communityMentor}
            formFieldWidth={8}
            handleChange={props.handleChange}
            validationResult={props.validationResults.communityMentor}
            mode={props.mode}
            dropdownDisabled={props.mode === "amend"}
          />
        </Form.Group>
      </ShowIfSameArea>
      <ShowIfSameArea area={props.area} allowedArea={strings.areas.NRNE}>
        <Form.Group>
          <ValidatedFormDropdown
            formInvalid={props.formInvalid}
            inputData={props.selectedProgrammeData.mentor}
            viewData={
              dropdownHelper.getNameFromValues(
                props.selectedProgrammeData.mentor,
                props.getMentors(props.area)
              ) || ""
            }
            dropdownLabel={strings.form.label.mentor}
            dropdownName="mentor"
            dropdownOptions={props.getMentors(props.area) || []}
            dropdownPlaceholder={strings.form.placeholder.mentor}
            formFieldWidth={8}
            handleChange={props.handleChange}
            validationResult={props.validationResults.mentor}
            mode={props.mode}
            dropdownDisabled={props.mode === "amend"}
          />
        </Form.Group>
      </ShowIfSameArea>
    </div>
  </div>
);
MentorFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default MentorFields;
