import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      entity: "feedback",
      deletion: "deletion",
      fullName: "Feedback"
    },
    header: {
      login: "Login",
      feedbackList: "Feedback",
      createFeedback: "New Feedback",
      editFeedback: "Edit Feedback",
      viewFeedback: "Feedback Details",
      loading: "Loading Information",
      embeddedFeedbackListStart: "Feedback for '",
      embeddedFeedbackListMiddle: "' of type '",
      embeddedFeedbackListEnd: "'",
      dateCompleted: "Date Completed"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        feedbackInfo: "Feedback Information",
        activityDetails: "Activity Details",
        administration: "Administration",
        customerDetails: "Customer Details",
        crmID: "CRM ID",
        owner: "Owner",
        feedback: "Feedback",
        stage1: "Stage 1 - Pre-Release",
        stage2: "Stage 2 - Community",
        stage3: "Stage 3 - Exit",
        additional: "Additional Feedback"
      },
      label: {
        feedbackStage: "Feedback Stage",
        crmId: "CRM ID",
        owner: "Owner",
        stage1Q1: "Are you happy to provide customer feedback at this stage?",
        stage1Q2: "Do you feel your Mentor is a good match for you?",
        stage1Q3: "Additional Information",
        stage1Q4:
          "Before New Routes, did you previously engage with any services to address your needs?",
        stage1Q5: "Additional Information",
        stage1Q6:
          "Have you engaged with services in Prison with the support from your Mentor?",
        stage1Q7: "Additional Information",
        stage1Q8:
          "Do you feel that your Mentor fully understands your needs at this stage?",
        stage1Q9: "Additional Information",
        stage1Q10: "Would you recommend the service to others?",
        stage1Q11: "Additional Information",
        stage2Q1: "Are you happy to provide customer feedback at this stage?",
        stage2Q2:
          "Are you receiving enough support from your Mentor to help you address your needs?",
        stage2Q3: "Additional Information",
        stage2Q4:
          "On the day of liberation, did your mentor support you to engage with services to address your needs?",
        stage2Q5: "Additional Information",
        stage2Q6: "Do you feel your Mentor is spending enough time with you?",
        stage2Q7: "Additional Information",
        stage2Q8:
          "Has your situation improved since your liberation from Prison?",
        stage2Q9: "Additional Information",
        stage2Q10:
          "Are you continuing to make plans for the future now that you have been liberated?",
        stage2Q11: "Additional Information",
        stage2Q12:
          "Is there anything that the service could do to improve your experience on New Routes?",
        stage2Q13: "Additional Information",
        stage2Q14: "Would you recommend the service to others?",
        stage2Q15: "Additional Information",
        stage3Q1: "Are you happy to provide customer feedback at this stage?",
        stage3Q2: "Is the New Routes programme what you expected?",
        stage3Q3: "Additional Information",
        stage3Q4:
          "Has your situation improved since you started on the New Routes programme?",
        stage3Q5: "Additional Information",
        stage3Q6:
          "Do you feel you have the confidence to continue your journey without the support from New Routes?",
        stage3Q7: "Additional Information",
        stage3Q8:
          "Do you feel that your attitude to Criminal behaviour has improved since engaging with New Routes?",
        stage3Q9: "Additional Information",
        stage3Q10: "Do you believe you can abstain from criminal activity?",
        stage3Q11: "Additional Information",
        stage3Q12:
          "Is there anything that the service could have done to improve your experience on New Routes?",
        stage3Q13: "Additional Information",
        stage3Q14: "Would you recommend the service to others?",
        stage3Q15: "Additional Information",
        additionalQ1:
          "Are you happy to provide customer feedback at this stage?",
        additionalQ2: "Customer or Mentor feedback?",
        additionalQ3: "Related area of need?",
        additionalQ4: "Stage of Journey",
        additionalQ5: "Please provide details of interactions",
        additionalQ6: "Positive or Negative feedback?"
      },
      placeholder: {
        crmId: "",
        owner: "- select -",
        feedbackStage: "- select -",
        stage1Q1: "- select -",
        stage1Q2: "- select -",
        stage1Q3: "",
        stage1Q4: "- select -",
        stage1Q5: "",
        stage1Q6: "- select -",
        stage1Q7: "",
        stage1Q8: "- select -",
        stage1Q9: "",
        stage1Q10: "- select -",
        stage1Q11: "",
        stage2Q1: "- select -",
        stage2Q2: "- select -",
        stage2Q3: "",
        stage2Q4: "- select -",
        stage2Q5: "",
        stage2Q6: "- select -",
        stage2Q7: "",
        stage2Q8: "- select -",
        stage2Q9: "",
        stage2Q10: "- select -",
        stage2Q11: "",
        stage2Q12: "- select -",
        stage2Q13: "",
        stage2Q14: "- select -",
        stage2Q15: "",
        stage3Q1: "- select -",
        stage3Q2: "- select -",
        stage3Q3: "",
        stage3Q4: "- select -",
        stage3Q5: "",
        stage3Q6: "- select -",
        stage3Q7: "",
        stage3Q8: "- select -",
        stage3Q9: "",
        stage3Q10: "- select -",
        stage3Q11: "",
        stage3Q12: "- select -",
        stage3Q13: "",
        stage3Q14: "- select -",
        stage3Q15: "",
        additionalQ1: "- select -",
        additionalQ2: "- select -",
        additionalQ3: "- select -",
        additionalQ4: "- select -",
        additionalQ5: "",
        additionalQ6: "- select -"
      },
      feedback: {
        processing: "Processing request...",
        feedbackRequestSuccess: "Feedback created successfully!",
        feedbackRequestRefreshPrompt:
          "Use the refresh function to see an updated feedback list.",
        feedbackRequestFailure: "Feedback creation failed.",
        feedbackRequestUnknown:
          "Something went wrong with the Feedback request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        yes: "Yes",
        no: "No"
      }
    },
    modal: {
      deleteFeedbackMessage:
        "Are you sure you want to delete this Feedback? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:",
      lockScreen: "Lock screen?",
      unlockScreen: "Please enter the password to unlock the screen"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addActivity: "Add Activity",
      addFeedbackLabel: "New Feedback",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form",
      unlockScreen: "Unlock Screen",
      lockScreen: "Lock Screen"
    },
    fieldValues: {
      stage1: "Stage 1 - Pre-Release",
      stage2: "Stage 2 - Community",
      stage3: "Stage 3 - Exit",
      additional: "Additional Feedback",
      unlock: "unlock",
      password: "8619",
      handleToggleUnlock: "handleToggleUnlock"
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    table: {
      header: {
        type: "Feedback Type",
        created: "Date Created"
      },
      text: {
        noResults: "No results found."
      }
    }
  }
});
