export const newsActionTypes = {
  CREATE_NEWS_REQUEST: "CREATE_NEWS_REQUEST",
  CREATE_NEWS_SUCCESS: "CREATE_NEWS_SUCCESS",
  CREATE_NEWS_FAILURE: "CREATE_NEWS_FAILURE",

  CLEAR_CREATE_NEWS_REQUEST: "CLEAR_CREATE_NEWS_REQUEST",
  CLEAR_CREATE_NEWS_FAILURE: "CLEAR_CREATE_NEWS_FAILURE",
  CLEAR_CREATE_NEWS_SUCCESS: "CLEAR_CREATE_NEWS_SUCCESS",

  DELETE_NEWS_REQUEST: "DELETE_NEWS_REQUEST",
  DELETE_NEWS_SUCCESS: "DELETE_NEWS_SUCCESS",
  DELETE_NEWS_FAILURE: "DELETE_NEWS_FAILURE",

  UPDATE_SELECTED_NEWS_DATA_REQUEST: "UPDATE_SELECTED_NEWS_DATA_REQUEST",
  UPDATE_SELECTED_NEWS_DATA_SUCCESS: "UPDATE_SELECTED_NEWS_DATA_SUCCESS",
  UPDATE_SELECTED_NEWS_DATA_FAILURE: "UPDATE_SELECTED_NEWS_DATA_FAILURE",

  UPDATE_SELECTED_NEWS_REQUEST: "UPDATE_SELECTED_NEWS_REQUEST",
  UPDATE_SELECTED_NEWS_SUCCESS: "UPDATE_SELECTED_NEWS_SUCCESS",
  UPDATE_SELECTED_NEWS_FAILURE: "UPDATE_SELECTED_NEWS_FAILURE",

  CLEAR_SELECTED_NEWS_REQUEST: "CLEAR_SELECTED_NEWS_REQUEST",
  CLEAR_SELECTED_NEWS_SUCCESS: "CLEAR_SELECTED_NEWS_SUCCESS",
  CLEAR_SELECTED_NEWS_FAILURE: "CLEAR_SELECTED_NEWS_FAILURE",

  CANCEL_SELECTED_NEWS_UPDATE_REQUEST: "CANCEL_SELECTED_NEWS_UPDATE_REQUEST",
  CANCEL_SELECTED_NEWS_UPDATE_SUCCESS: "CANCEL_SELECTED_NEWS_UPDATE_SUCCESS",
  CANCEL_SELECTED_NEWS_UPDATE_FAILURE: "CANCEL_SELECTED_NEWS_UPDATE_FAILURE",

  GET_NEWS_REQUEST: "GET_NEWS_REQUEST",
  GET_NEWS_SUCCESS: "GET_NEWS_SUCCESS",
  GET_NEWS_FAILURE: "GET_NEWS_FAILURE",

  GET_NEWS_BY_PROGRAMME_REQUEST: "GET_NEWS_BY_PROGRAMME_REQUEST",
  GET_NEWS_BY_PROGRAMME_SUCCESS: "GET_NEWS_BY_PROGRAMME_SUCCESS",
  GET_NEWS_BY_PROGRAMME_FAILURE: "GET_NEWS_BY_PROGRAMME_FAILURE",

  UPDATE_NEWS_REQUEST: "UPDATE_NEWS_REQUEST",
  UPDATE_NEWS_SUCCESS: "UPDATE_NEWS_SUCCESS",
  UPDATE_NEWS_FAILURE: "UPDATE_NEWS_FAILURE",

  GET_NEWS_BY_ID_REQUEST: "GET_NEWS_BY_ID_REQUEST",
  GET_NEWS_BY_ID_SUCCESS: "GET_NEWS_BY_ID_SUCCESS",
  GET_NEWS_BY_ID_FAILURE: "GET_NEWS_BY_ID_FAILURE",

  GENERATE_NEWS_ID_REQUEST: "GENERATE_NEWS_ID_REQUEST",
  GENERATE_NEWS_ID_SUCCESS: "GENERATE_NEWS_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE"
};
