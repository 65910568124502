import React from "react";
import ModifyOrganisation from "../../components/ModifyOrganisation";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { organisationActions } from "../../store/actions/organisation.actions";
import { addressActions } from "../../../App/store/actions/address.actions";
import PropTypes from "prop-types";
import { convertToIso, allowNumericalInput } from "../../helpers/index";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";

class OrganisationCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    initialOrganisationData: {},
    // Required to remove error since enable/disable still present even
    // in create mode, just not visisble.
    enableDisableOrganisationModalOpen: false,
    deleteModalOpen: false,
    formInvalid: false,
    validationResults: {},
    contactDeletionId: undefined,
    postcodeInputValues: {
      postcode: "",
      libPostcode: "",
      mailingPostcode: "",
      additionalPostcode: ""
    },
    postcodeOpenStates: {
      postcode: false,
      libPostcode: false,
      mailingPostcode: false,
      additionalPostcode: false
    }
  };

  componentDidMount = async () => {
    this.initialiseContactTable();

    // HTML attributes added to stop html's validation and autocompletion

    let form = document.getElementById("organisationForm");
    form.setAttribute("novalidate", true);
  };

  componentWillUnmount() {
    this.props.clearSelectedOrganisation();
  }

  validateForm = async () => {
    let organisationConstraints = {
      ...twgConstraints.organisations.modifyOrganisation
    };
    let organisationContactConstraints = {
      ...twgConstraints.organisations.organisationContact
    };
    organisationConstraints.active = undefined;
    if (
      this.props.selectedOrganisationData.mainType !==
      strings.fieldValues.employer
    ) {
      organisationConstraints.sicCode = undefined;
    }
    let details = JSON.parse(
      JSON.stringify(this.props.selectedOrganisationData)
    );
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(
      details,
      organisationConstraints
    );
    let contactResults = [];
    if (this.props.selectedOrganisationData.contacts) {
      if (
        this.props.selectedOrganisationData.contacts.length > 1 ||
        this.props.selectedOrganisationData.contacts[0].contactName ||
        this.props.selectedOrganisationData.contacts[0].contactEmail ||
        this.props.selectedOrganisationData.contacts[0].contactPhone
      ) {
        this.props.selectedOrganisationData.contacts.forEach(function (
          contact
        ) {
          let contactResult = twgValidationFunctions.validateData(
            contact,
            organisationContactConstraints
          );
          contactResult
            ? contactResults.push(contactResult)
            : contactResults.push({});
        });
      } else {
        this.props.updateSelectedOrganisation("contacts", undefined);
      }
    }
    results ? (results.contacts = []) : (results = { contacts: [] });
    let contactResultsAreEmpty = true;
    for (let i in contactResults) {
      //All results need to be added to preserve order
      results.contacts.push(contactResults[i]);
      if (Object.keys(contactResults[i]).length !== 0) {
        contactResultsAreEmpty = false;
      }
    }
    //Remove the contacts key if all contacts pass validation
    if (contactResultsAreEmpty) {
      delete results.contacts;
    }
    //There will be no keys in the object if the form passes validation
    if (Object.keys(results).length > 0) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  initialiseContactTable = async () => {
    let contacts = [];
    contacts.push({
      contactName: "",
      contactPhone: "",
      contactEmail: ""
    });
    contacts.length > 0
      ? await this.props.updateSelectedOrganisation(
          this.getKeyByValue(
            this.props.selectedOrganisationData,
            this.props.selectedOrganisationData.contacts
          ),
          contacts
        )
      : await this.props.updateSelectedOrganisation("contacts", contacts);
  };

  addContact = async e => {
    let contacts = this.props.selectedOrganisationData.contacts || [];
    contacts.push({
      contactName: "",
      contactPhone: "",
      contactEmail: ""
    });
    contacts.length > 1
      ? await this.props.updateSelectedOrganisation(
          this.getKeyByValue(
            this.props.selectedOrganisationData,
            this.props.selectedOrganisationData.contacts
          ),
          contacts
        )
      : await this.props.updateSelectedOrganisation("contacts", contacts);
  };

  removeContact = async () => {
    let contacts = this.props.selectedOrganisationData.contacts;
    if (contacts.length > 0) {
      contacts.splice(this.state.contactDeletionId, 1);
    }
    if (contacts.length === 0) {
      contacts = undefined;
    }
    await this.props.updateSelectedOrganisation(
      this.getKeyByValue(
        this.props.selectedOrganisationData,
        this.props.selectedOrganisationData.contacts
      ),
      contacts
    );
  };

  handleSubmit = async () => {
    let data = this.props.selectedOrganisationData;
    data.active = true;
    await this.props.createOrganisation(data, this.props.headers);
    this.props.history.push("/organisations");
  };

  handleCancel = () => {
    this.props.clearOrganisationRequest();
    this.props.history.goBack();
    this.setState({ formInvalid: false });
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedOrganisation(
        data.name,
        convertToIso(data.value)
      );
    } else if (data.name === "mainType") {
      this.props.updateSelectedOrganisation(data.name, data.value);
      if (
        data.value === strings.fieldValues.employer ||
        data.value === strings.fieldValues.supportService
      ) {
        this.props.updateSelectedOrganisation("localAuthorityArea", "");
        this.props.updateSelectedOrganisation("prisons", "");
      }
      if (data.value !== strings.fieldValues.employer) {
        this.props.updateSelectedOrganisation("sicCode", "");
        this.props.updateSelectedOrganisation("socCode", "");
        this.props.updateSelectedOrganisation("osCode", "");
      }
    } else {
      await this.props.updateSelectedOrganisation(data.name, data.value);
    }
  };

  handleContactChange = async (e, data) => {
    let contacts = this.props.selectedOrganisationData.contacts;
    const result = data.name.split("-");
    contacts[result[1]][result[0]] = data.value;

    await this.props.updateSelectedOrganisation(
      this.getKeyByValue(
        this.props.selectedOrganisationData,
        this.props.selectedOrganisationData.contacts
      ),
      contacts
    );
  };

  handlePostcodeChanged = async (e, data, fieldName) => {
    this.setState({
      postcodeInputValues: {
        ...this.state.postcodeInputValues,
        [data.name]: data.searchQuery
      }
    });
  };

  handlePostcodeSearch = async (e, click) => {
    await this.props.findAddress(
      this.state.postcodeInputValues[click.name],
      click.name
    );
    this.setState({
      postcodeOpenStates: {
        ...this.state.postcodeOpenStates,
        [click.name]: this.props.addressSearchResults[click.name].length > 0
      }
    });
    this.setState({
      postcodeOpenStates: {
        ...this.state.postcodeOpenStates,
        [click.name]: undefined
      }
    });
  };

  handlePostcodeClicked = async (event, click) => {
    let selectedResult = click.options[click.value];
    this.setState({
      postcodeOpenStates: {
        ...this.state.postcodeOpenStates,
        [click.name]: false
      }
    });
    if (selectedResult) await this.props.retrieveAddress(selectedResult.id);
    // AWAIT and update organisation
    let data = {
      postcode: selectedResult ? this.props.formAddressResults.postcode : "",
      address1: selectedResult
        ? this.props.formAddressResults.addressLine1
        : "",
      address2: selectedResult ? this.props.formAddressResults.addressLine2 : ""
    };

    for (const key in data) {
      this.props.updateSelectedOrganisation(
        key,

        data[key]
      );
    }
  };

  getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "deletionContactModal":
        this.removeContact(this.state.contactDeletionId);
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableOrganisationModalVisibility = () => {
    this.setState({
      enableDisableOrganisationModalOpen: !this.state
        .enableDisableOrganisationModalOpen
    });
  };

  toggleConfirmDeleteModalVisibility = idx => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      contactDeletionId: idx
    });
  };

  render = () => {
    const loading = this.props.loadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <ModifyOrganisation
            mode="create"
            pageTitle={strings.header.createOrganisation}
            selectedOrganisationData={this.props.selectedOrganisationData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            enableDisableOrganisationModalOpen={
              this.state.enableDisableOrganisationModalOpen
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableOrganisationModalVisibility={
              this.toggleEnableDisableOrganisationModalVisibility
            }
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            handleChange={this.handleChange}
            handleContactChange={this.handleContactChange}
            handleModalConfirm={this.handleModalConfirm}
            deleteModalOpen={this.state.deleteModalOpen}
            formInvalid={this.state.formInvalid}
            allowNumericalInput={allowNumericalInput}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            cancelSelectedOrganisationUpdate={
              this.props.cancelSelectedOrganisationUpdate
            }
            addContact={this.addContact}
            removeContact={this.removeContact}
            initialOrganisationData={{}}
            handlePostcodeChanged={this.handlePostcodeChanged}
            handlePostcodeClicked={this.handlePostcodeClicked}
            addressSearchResults={this.props.addressSearchResults}
            addressRetrieved={this.props.addressRetrieved}
            handlePostcodeSearch={this.handlePostcodeSearch}
            postcodeOpenStates={this.state.postcodeOpenStates}
          />
        </div>
      </div>
    );
  };
}

OrganisationCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedOrganisationData: PropTypes.object.isRequired,
  updateSelectedOrganisation: PropTypes.func.isRequired,
  clearSelectedOrganisation: PropTypes.func.isRequired,
  createOrganisation: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    loadingPage,
    selectedOrganisationData,
    organisationRequestMade,
    organisationRequestStatus
  } = state.organisations;
  const { loggingIn, isLoggedIn, error } = state.auth;
  const {
    addressSearchResults,
    formAddressResults,
    addressRetrieved
  } = state.address;
  return {
    loadingPage,
    selectedOrganisationData,
    loggingIn,
    isLoggedIn,
    error,
    organisationRequestMade,
    organisationRequestStatus,
    addressSearchResults,
    formAddressResults,
    addressRetrieved
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOrganisation: (data, headers) => {
      dispatch(organisationActions.createOrganisation(data, headers));
    },
    updateSelectedOrganisation: (key, value) => {
      dispatch(organisationActions.updateSelectedOrganisation(key, value));
    },
    updateSelectedOrganisationContact: contacts => {
      dispatch(organisationActions.updateSelectedOrganisationContact(contacts));
    },
    clearSelectedOrganisation: () => {
      dispatch(organisationActions.clearSelectedOrganisation());
    },
    clearOrganisationRequest: () => {
      dispatch(organisationActions.clearOrganisationRequest());
    },
    findAddress: async (value, fieldName) => {
      await dispatch(addressActions.findAddress(value, fieldName));
    },
    retrieveAddress: async id => {
      await dispatch(addressActions.retrieveAddress(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationCreateContainer);
