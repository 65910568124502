import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import { strings, yesNoOptions, contractTypes } from "../resources";
import {
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormTextArea,
  ValidatedFormInput
} from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from ".";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { dropdownHelper, convertFromIso, allowCurrencyInput } from "../helpers";
import FileManager from "../../File/components/FileManager";
import { AuditListContainer } from "../../Audit/containers/";

class ModifyEmployment extends React.Component {
  returnToList = () => {
    this.props.history.push("/employments");
  };

  returnToParent = () => {
    this.props.history.push(
      "/programmes/view/" + localStorage.getItem("programmeId")
    );
  };

  renderModifyButtons = () => {
    const {
      handleModalInputChange,
      handleModeSwitch,
      isDisabled,
      handleModalConfirm,
      enableDisableEmploymentModalOpen,
      toggleEnableDisableEmploymentModalVisibility,
      deletionReason
    } = this.props;

    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={handleModalInputChange}
      ></Input>
    );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful &&
          (!isDisabled ? (
            <>
              <Button.Group>
                <Button
                  className="editButton"
                  primary
                  onClick={() => {
                    handleModeSwitch("edit");
                  }}
                  disabled={this.props.showAuditLog}
                >
                  {strings.button.editLabel}
                </Button>
              </Button.Group>
            </>
          ) : (
            <span className="errorText">
              {strings.form.errorText.notShared}
            </span>
          ))}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={toggleEnableDisableEmploymentModalVisibility}
                type="button"
                disabled={this.props.selectedEmploymentData.requestId}
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={enableDisableEmploymentModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={toggleEnableDisableEmploymentModalVisibility}
                okLabel={strings.button.submitLabel}
                deletionReason={deletionReason}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderDeleteButton = () => {
    const {
      isDisabled,
      handleModalConfirm,
      enableDisableEmploymentModalOpen,
      toggleEnableDisableEmploymentModalVisibility
    } = this.props;

    return (
      <div className="containerButtons">
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={toggleEnableDisableEmploymentModalVisibility}
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteEmploymentMessage}
                handleModalConfirm={handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={enableDisableEmploymentModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={toggleEnableDisableEmploymentModalVisibility}
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderAuditButton = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="auditButton"
            onClick={() => {
              this.props.handleToggleAudit();
            }}
          >
            {!this.props.showAuditLog
              ? strings.button.auditLabel
              : strings.button.formLabel}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderCancelSaveButton = () => {
    const {
      selectedEmploymentData,
      handleModeSwitch,
      handleModalConfirm,
      toggleCancelChangesModalVisibility,
      toggleConfirmSaveModalVisibility,
      initialEmploymentData,
      validateForm,
      confirmSaveModalOpen,
      mode,
      cancelChangesModalOpen,
      hasFileChanges,
      isDisabled
    } = this.props;

    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={validateForm}
            disabled={
              (JSON.stringify(selectedEmploymentData) ===
                JSON.stringify(initialEmploymentData) &&
                !hasFileChanges) ||
              isDisabled
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
            disabled={isDisabled}
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };

  renderForm = () => {
    const {
      selectedEmploymentData,
      handleChange,
      mode,
      formInvalid,
      validationResults,
      getOrganisations
    } = this.props;

    return (
      <>
        {mode !== "create" && (
          <div className="infoBlock">
            <div className="infoHeader">
              {strings.form.header.administration}
            </div>
            <div className="infoWrapper">
              <Form.Group>
                <ValidatedFormInput
                  formInvalid={formInvalid}
                  inputData={selectedEmploymentData.crmId}
                  inputLabel={strings.form.label.crmId}
                  inputName="crmId"
                  inputPlaceholder={strings.form.placeholder.crmId}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.crmId}
                  disabled={mode === "edit"}
                  mode={mode}
                />
                <ValidatedFormDropdown
                  formInvalid={formInvalid}
                  inputData={selectedEmploymentData.owner}
                  viewData={
                    dropdownHelper.getNameFromValues(
                      selectedEmploymentData.owner,
                      getOrganisations()
                    ) || ""
                  }
                  dropdownLabel={strings.form.label.owner}
                  dropdownName="owner"
                  dropdownDisabled={mode === "edit"}
                  dropdownOptions={getOrganisations() || []}
                  dropdownPlaceholder={strings.form.placeholder.owner}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.owner}
                  mode={mode}
                />
              </Form.Group>
            </div>
          </div>
        )}
        <div className="infoBlock">
          <div className="infoHeader">
            {strings.form.header.employmentDetails}
          </div>
          <div className="infoWrapper">
            <Form.Group>
              <ValidatedDateInput
                inputId="employmentStartDate"
                formInvalid={formInvalid}
                inputLabel={strings.form.label.employmentStartDate}
                inputName="employmentStartDate"
                inputPlaceholder={strings.form.placeholder.employmentStartDate}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  selectedEmploymentData.employmentStartDate
                    ? convertFromIso(selectedEmploymentData.employmentStartDate)
                    : ""
                }
                iconPosition="left"
                required
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.employmentStartDate}
                mode={mode}
              />
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedEmploymentData.contractType}
                viewData={selectedEmploymentData.contractType}
                dropdownLabel={strings.form.label.contractType}
                dropdownName="contractType"
                dropdownOptions={contractTypes}
                dropdownPlaceholder={strings.form.placeholder.contractType}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.contractType}
                dropdownSearch={true}
                required
                mode={mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={selectedEmploymentData.jobTitle}
                inputLabel={strings.form.label.jobTitle}
                inputName="jobTitle"
                inputPlaceholder={strings.form.placeholder.jobTitle}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.jobTitle}
                mode={mode}
              />
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={selectedEmploymentData.hoursEmployed}
                inputLabel={strings.form.label.hoursEmployed}
                inputName="hoursEmployed"
                onKeyPress={this.props.allowNumericalInput}
                inputPlaceholder={strings.form.placeholder.hoursEmployed}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.hoursEmployed}
                required={false}
                mode={mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormInput
                formInvalid={formInvalid}
                inputData={selectedEmploymentData.employerName}
                inputLabel={strings.form.label.employerName}
                inputName="employerName"
                inputPlaceholder={strings.form.placeholder.employerName}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.employerName}
                mode={mode}
              />
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={selectedEmploymentData.hourlyRate}
                inputLabel={strings.form.label.hourlyRate}
                inputName="hourlyRate"
                onKeyPress={allowCurrencyInput}
                inputPlaceholder={strings.form.placeholder.hourlyRate}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.hourlyRate}
                required={false}
                mode={mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormTextArea
                formInvalid={formInvalid}
                inputData={selectedEmploymentData.employerAddress}
                inputLabel={strings.form.label.employerAddress}
                inputName="employerAddress"
                inputPlaceholder={strings.form.placeholder.employerAddress}
                formFieldWidth={8}
                handleChange={handleChange}
                required={false}
                mode={mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormInput
                formInvalid={formInvalid}
                inputData={selectedEmploymentData.employerPostcode}
                inputLabel={strings.form.label.employerPostcode}
                inputName="employerPostcode"
                inputPlaceholder={strings.form.placeholder.employerPostcode}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.employerPostcode}
                required={false}
                mode={mode}
              />
            </Form.Group>
          </div>
        </div>
        <div className="infoBlock">
          <div className="infoHeader">{strings.form.header.tracking}</div>
          <div className="infoWrapper">
            <Form.Group>
              <ValidatedDateInput
                inputId="week13SustainedDate"
                formInvalid={formInvalid}
                inputLabel={strings.form.label.week13SustainedDate}
                inputName="week13SustainedDate"
                inputPlaceholder={strings.form.placeholder.week13SustainedDate}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  selectedEmploymentData.week13SustainedDate
                    ? convertFromIso(selectedEmploymentData.week13SustainedDate)
                    : ""
                }
                iconPosition="left"
                required
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.week13SustainedDate}
                disabled
                mode={mode}
              />
              <ValidatedDateInput
                inputId="week26SustainedDate"
                formInvalid={formInvalid}
                inputLabel={strings.form.label.week26SustainedDate}
                inputName="week26SustainedDate"
                inputPlaceholder={strings.form.placeholder.week26SustainedDate}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  selectedEmploymentData.week26SustainedDate
                    ? convertFromIso(selectedEmploymentData.week26SustainedDate)
                    : ""
                }
                iconPosition="left"
                required
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.week26SustainedDate}
                disabled
                mode={mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedEmploymentData.week13Sustained}
                viewData={selectedEmploymentData.week13Sustained}
                dropdownLabel={strings.form.label.week13Sustained}
                dropdownName="week13Sustained"
                dropdownOptions={yesNoOptions || []}
                dropdownPlaceholder={strings.form.placeholder.week13Sustained}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.week13Sustained}
                required={false}
                mode={mode}
              />
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedEmploymentData.week26Sustained}
                viewData={selectedEmploymentData.week26Sustained}
                dropdownLabel={strings.form.label.week26Sustained}
                dropdownName="week26Sustained"
                dropdownOptions={yesNoOptions || []}
                dropdownPlaceholder={strings.form.placeholder.week26Sustained}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.week26Sustained}
                required={false}
                mode={mode}
              />
            </Form.Group>
          </div>
        </div>
        <div className="infoBlock">
          <div className="infoHeader">{strings.form.header.notes}</div>
          <div className="infoWrapper">
            <Form.Group>
              <ValidatedFormTextArea
                formInvalid={formInvalid}
                inputData={selectedEmploymentData.notes}
                inputName="notes"
                inputLabel={strings.form.label.notes}
                inputPlaceholder={strings.form.placeholder.notes}
                formFieldWidth={8}
                handleChange={handleChange}
                required={false}
                mode={mode}
              />
            </Form.Group>
            <Form.Group>
              {(this.props.hasPageLoaded || this.props.mode !== "create") &&
                this.props.selectedEmploymentData.employmentId && (
                  <FileManager
                    folder={strings.folder.employmentFiles}
                    headers={this.props.headers}
                    allowEdit={true}
                    filePath={`${strings.entity.employment}s/`}
                    filesLabel={strings.form.label.fileUpload}
                    disabled={this.props.mode === "view"}
                    entityId={this.props.selectedEmploymentData.employmentId}
                    entity={strings.entity.employment}
                    filesHaveChanged={this.props.filesHaveChanged}
                  />
                )}
            </Form.Group>
          </div>
        </div>
      </>
    );
  };

  render = () => {
    const { mode, pageTitle, requestStatus, formInvalid } = this.props;

    let parentPage = localStorage.getItem("programmeName")
      ? localStorage.getItem("programmeName")
      : undefined;

    return (
      <div className="container">
        <ComponentHeader
          parentPage={parentPage}
          returnToParent={this.returnToParent}
          returnToList={this.returnToList}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderModifyButtons()}
          cancelSaveButtons={this.renderCancelSaveButton()}
          auditLog={this.renderAuditButton()}
          deleteButton={this.renderDeleteButton()}
          headerList={strings.header.employmentList}
          permissionsUpdate={permissions.employments.update}
          permissionsCreate={permissions.employments.create}
          permissionsDelete={permissions.employments.delete}
          formInvalid={formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />
        {this.props.showAuditLog ? (
          <AuditListContainer
            embedded={true}
            headers={this.props.headers}
            history={this.props.history}
            entityId={this.props.selectedEmploymentData.employmentId}
            hideSearchBar={true}
            record={true}
          />
        ) : (
          <Form id="employmentForm" className="stickyForm">
            <fieldset disabled={mode === "view"}>{this.renderForm()}</fieldset>
          </Form>
        )}
      </div>
    );
  };
}

ModifyEmployment.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedEmploymentData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  initialEmploymentData: PropTypes.object.isRequired,
  allowNumericalInput: PropTypes.func.isRequired,
  deletePermissionStatus: PropTypes.string,
  handleModalInputChange: PropTypes.func,
  deletionReason: PropTypes.object,
  isDisabled: PropTypes.bool.isRequired
};

export default withRouter(ModifyEmployment);
