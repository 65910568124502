import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      entity: "employments",
      deletion: "deletion",
      fullName: "Employment"
    },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    header: {
      login: "Login",
      employmentList: "Employments",
      createEmployment: "New Employment",
      editEmployment: "Edit Employment",
      viewEmployment: "Employment Details",
      loading: "Loading Information"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        administration: "Administration",
        employmentDetails: "Employment Details",
        tracking: "Tracking",
        notes: "Notes"
      },
      label: {
        employmentStartDate: "Employment Start Date",
        method: "Method",
        location: "Location",
        employmentNotes: "Employment notes",
        crmId: "CRM ID",
        owner: "Owner",
        consent: "Agree to data protection",
        contactType: "Contact Type",
        startDate: "Start Date",
        endDate: "End Date",
        contractType: "Contract Type",
        hoursEmployed: "Hours Employed",
        hourlyRate: "Hourly Rate",
        employerAddress: "Employer Address",
        employerName: "Employer Name",
        week13SustainedDate: "13 Week Sustained Date",
        week26SustainedDate: "26 Week Sustained Date",
        week13Sustained: "13 Week Sustained",
        week26Sustained: "26 Week Sustained",
        employerPostcode: "Employer Post Code",
        jobTitle: "Job Title",
        fileUpload: "Upload Files",
        notes: "Employment Notes"
      },
      placeholder: {
        stateReason: "State your reason...",
        employmentStartDate: "- select -",
        crmId: "",
        owner: "- select -",
        employerAddress: "",
        employerPostcode: "",
        employerName: "",
        hoursEmployed: "",
        hourlyRate: "",
        jobTitle: "",
        week13SustainedDate: "- select -",
        week26SustainedDate: "- select -",
        week13Sustained: "- select -",
        week26Sustained: "- select -",
        notes: "Enter a note",
        contractType: "- select -"
      },
      feedback: {
        processing: "Processing request...",
        employmentRequestSuccess: "Employment created successfully!",
        employmentRequestRefreshPrompt:
          "Use the refresh function to see an updated employment list.",
        employmentRequestFailure: "Employment creation failed.",
        employmentRequestUnknown:
          "Something went wrong with the employment request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        yes: "Yes",
        no: "No"
      }
    },
    modal: {
      createEmploymentMessage: "Save employment?",
      modifyEmploymentMessage: "Are you sure you want to save these changes?",
      deleteEmploymentMessage:
        "Are you sure you want to delete this employment? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this employment's status to active?",
      setInactiveMessage: "Set this employment's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addActivity: "Add Activity",
      addEmploymentLabel: "New Employment",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    tooltip: {},
    table: {
      header: {
        firstName: "First Name",
        surname: "Last Name",
        pNumber: "Prison Number",
        scroNumber: "S Number",
        employerName: "Employer Name",
        crmId: "CRM ID",
        employerAddress: "Employer Address",
        title: "Job Title",
        contractType: "Contract Type",
        startDate: "Employment Start Date"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    entity: {
      employment: "employment"
    },
    folder: {
      employmentFiles: "employmentFiles"
    }
  }
});
