import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import { ValidatedFormDropdown, ValidatedFormInput } from "../validation";
import "../css/ProgrammeComponents.scss";
import { yesNoOptions } from "../../resources";

const DependantsFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">{strings.form.header.dependantsHeader}</div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hasDependants}
          viewData={props.selectedProgrammeData.hasDependants}
          dropdownLabel={strings.form.label.hasDependants}
          dropdownName="hasDependants"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.hasDependants}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.hasDependants}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hasCaringResponsibilities}
          viewData={props.selectedProgrammeData.hasCaringResponsibilities}
          dropdownLabel={strings.form.label.hasCaringResponsibilities}
          dropdownName="hasCaringResponsibilities"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={
            strings.form.placeholder.hasCaringResponsibilities
          }
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.hasCaringResponsibilities}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <Form.Checkbox
          label={strings.form.label.hasChildrenDependants}
          name="hasChildrenDependants"
          onChange={props.handleChange}
          checked={props.selectedProgrammeData.hasChildrenDependants || false}
          disabled={props.mode === "view" || props.mode === "amend"}
          width={8}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hasChildrenAccess}
          viewData={props.selectedProgrammeData.hasChildrenAccess}
          dropdownLabel={strings.form.label.hasChildrenAccess}
          dropdownName="hasChildrenAccess"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.hasChildrenAccess}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.hasChildrenAccess}
          required={false}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <Form.Checkbox
          label={strings.form.label.hasRelativeDependants}
          name="hasRelativeDependants"
          onChange={props.handleChange}
          checked={props.selectedProgrammeData.hasRelativeDependants || false}
          disabled={props.mode === "view" || props.mode === "amend"}
          width={8}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.isLoneParent}
          viewData={props.selectedProgrammeData.isLoneParent}
          dropdownLabel={strings.form.label.isLoneParent}
          dropdownName="isLoneParent"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.isLoneParent}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.isLoneParent}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <Form.Checkbox
          label={strings.form.label.hasOtherDependants}
          name="hasOtherDependants"
          onChange={props.handleChange}
          checked={props.selectedProgrammeData.hasOtherDependants || false}
          disabled={props.mode === "view" || props.mode === "amend"}
          width={8}
        />
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.otherDependantsDetail}
          inputLabel={strings.form.label.otherDependantsDetail}
          inputName="otherDependantsDetail"
          inputPlaceholder={strings.form.placeholder.otherDependantsDetail}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.otherDependantsDetail}
          required={false}
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
      </Form.Group>
    </div>
  </div>
);

DependantsFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default DependantsFields;
