import React from "react";
import moment from "moment";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ModifyReferral from "../../components/ModifyReferral";
import { strings, textFields } from "../../resources";
import { referralActions } from "../../store/actions/referral.actions";
import { customerActions } from "../../../Customers/store/actions/customer.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { taskActions } from "../../../Tasks/store/actions/task.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  getListOfMentors,
  getListOfPrisonSpecialists,
  getOrganisations,
  convertToIso,
  allowNumericalInput,
  textFormattingHelpers
} from "../../helpers/index";
import { RequestFeedback } from "../../components";

class ReferralCreateContainer extends React.Component {
  state = {
    pageIndex: 0,
    confirmSaveModalOpen: false,
    confirmDuplicateModalOpen: false,
    cancelChangesModalOpen: false,
    // Required to remove error since enable/disable still present even
    // in create mode, just not visible.
    enableDisableReferralModalOpen: false,
    formInvalid: false,
    validationResults: {},
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    selectedArea: "",
    organisations: [],
    mentorsArea: [],
    loadingCustomers: false,
    unknownRequestStatus: false,
    taskResult: ""
  };

  componentDidMount = async () => {
    await this.handleFetchData();
    let form = document.getElementById("referralForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    if (this.props.area !== strings.areas.ALL) {
      this.props.updateSelectedReferral("area", this.props.area);
    }
    if (this.props.area === strings.areas.NE) {
      await this.props.updateSelectedReferral("area", strings.areas.NRNE);
      this.setState({
        mentorsArea: this.getMentors(strings.areas.NRNE),
        selectedArea: strings.areas.NRNE
      });
    }
    if (this.props.area === strings.areas.NRNE) {
      this.setState({
        mentorsArea: this.getMentors(this.props.area)
      });
    }
  };

  componentWillUnmount = () => {
    this.props.clearSelectedReferral();
  };

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      true
    );
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    this.setState({
      organisations: getOrganisations(this.props.organisationListData)
    });
  };

  validateForm = async () => {
    let area = this.state.selectedArea
      ? this.state.selectedArea
      : this.props.area;

    if (this.props.selectedReferralData.area === strings.areas.NRS) {
      await this.props.updateSelectedReferral(
        "outcomeOfIdentification_initial",
        "Eligible"
      );
    }
    if (this.props.selectedReferralData.owner === undefined) {
      await this.props.updateSelectedReferral("owner", this.getInitialOwner());
    }
    if (
      this.props.selectedReferralData.prisonSpecialist === undefined &&
      area === strings.areas.NRS
    ) {
      await this.props.updateSelectedReferral(
        "prisonSpecialist",
        this.getPrisonSpecialist(
          this.props.selectedReferralData.prisonNrs_initial
        )
      );
    }
    let details = JSON.parse(JSON.stringify(this.props.selectedReferralData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.referrals.referralIdentification
    );

    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      if (area === strings.areas.NRS) {
        await this.props.updateSelectedReferral(
          "returningCustomer",
          strings.fieldValues.no
        );
        this.setState({ loadingCustomers: true });
        await this.props.getCustomerByPNumber(
          this.props.headers,
          this.props.selectedReferralData.pNumber_initial
        );
        this.setState({ loadingCustomers: false });
        if (Object.keys(this.props.customerData).length > 0) {
          this.toggleConfirmDuplicateModalVisibility();
        } else {
          this.setState({ formInvalid: false });
          this.toggleConfirmSaveModalVisibility();
        }
      } else {
        this.setState({ formInvalid: false });
        this.toggleConfirmSaveModalVisibility();
      }
    }
  };

  handleSubmit = async () => {
    const sCrit = this.state.searchCriteria;
    await this.props.updateSelectedReferral("sharedWith", []);
    if (
      this.props.selectedReferralData.returningCustomer ===
      strings.fieldValues.newJourney
    ) {
      sCrit.fields = ["pNumber.normalizer"];
      sCrit.query = this.props.selectedReferralData.pNumber_initial;
      await this.props.getReferrals(
        this.props.headers,
        sCrit.query,
        10000,
        0,
        sCrit.clear,
        sCrit.fields,
        sCrit.sort,
        sCrit.activeOnly
      );
      let numberOfJournies = this.props.referralListData.length;
      this.props.updateSelectedReferral(
        "returningCustomer",
        strings.fieldValues.yes
      );
      this.props.updateSelectedReferral("journeyCount", numberOfJournies + 1);
      let taskDetails = this.createTaskDetails(this.props.selectedReferralData);
      this.props.createTask(
        taskDetails,
        this.props.headers,
        "Request for duplicate creation submitted"
      );
      this.setState({ taskResult: "Request for duplicate creation submitted" });
      if (this.props.referralListData[0]._id) {
        this.props.history.push("/referrals/");
      }
    } else {
      const data = textFormattingHelpers.decodeData(
        this.props.selectedReferralData,
        {},
        textFields
      );

      await this.props.createReferral(data, this.props.headers);
      this.props.history.push("/referrals");
    }
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearReferralCreation();
    this.props.history.push("/referrals");
  };

  handleChange = (event, data) => {
    if (data.type === "checkbox") {
      this.props.updateSelectedReferral(data.name, data.checked);
    } else if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      this.props.updateSelectedReferral(data.name, convertToIso(data.value));
    } else {
      this.props.updateSelectedReferral(data.name, data.value);
    }
  };

  handleChangePrisonNrs = async (event, data) => {
    await this.props.updateSelectedReferral(data.name, data.value);
    await this.props.updateSelectedReferral(
      "prisonOrganisation",
      this.getPrisonOrg(data.value)
    );
    this.props.updateSelectedReferral(
      "prisonSpecialist",
      this.getPrisonSpecialist(data.value)
    );
  };
  handleChangeOutcomeOfThreshold = (event, data) => {
    this.props.updateSelectedReferral(data.name, data.value);
    this.props.updateSelectedReferral("outcomeReason_initial", "");
  };
  handleChangeOutcomeReason = (event, data) => {
    this.props.updateSelectedReferral(data.name, data.value);
    this.props.updateSelectedReferral("otherOutcomeReason_initial", "");
  };
  handleChangePrisonNrne = (event, data) => {
    this.props.updateSelectedReferral(data.name, data.value);
    this.props.updateSelectedReferral("prisonNrneOther_initial", "");
  };

  handleAreaChange = async (event, data) => {
    await this.props.updateSelectedReferral(data.name, data.value);
    if (data.value === strings.areas.NRS) {
      this.props.updateSelectedReferral(
        "outcomeOfIdentification_initial",
        "Eligible"
      );
    }
    this.setState({
      selectedArea: data.value,
      mentorsArea: this.getMentors(data.value)
    });
  };

  handleModalConfirm = async modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "confirmDuplicateModal":
        await this.props.updateSelectedReferral(
          "returningCustomer",
          "Start New Journey"
        );
        this.handleSubmit();
        break;
      default:
        break;
    }
  };

  createTaskDetails = data => {
    let assignedSpecialistIds = [];
    this.props.userListData.forEach(user => {
      if (
        user._source.roleId === strings.fieldValues.teamLead &&
        (user._source.area === data.area ||
          user._source.area === strings.areas.ALL)
      ) {
        assignedSpecialistIds.push(user._id);
      }
    });
    let returnData = {};
    returnData.assignedSpecialistIds = assignedSpecialistIds;
    returnData.type = "duplicateWarning";
    returnData.customerForename = data.firstName_initial;
    returnData.customerSurname = data.surname_initial;
    returnData.prisonNumber = data.pNumber_initial;
    returnData.dueDate = moment().add(2, "w").toISOString();
    returnData.details = data;
    returnData.gcrId = this.props.customerData[0].gcrId;
    return returnData;
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleConfirmDuplicateModalVisibility = () => {
    this.setState({
      confirmDuplicateModalOpen: !this.state.confirmDuplicateModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableReferralModalVisibility = () => {
    this.setState({
      enableDisableReferralModalOpen: !this.state.enableDisableReferralModalOpen
    });
  };

  getInitialOwner = () => {
    let matchedOrg = undefined;
    this.props.organisationListData.forEach(organisation => {
      if (organisation._id === this.props.organisationOfLoggedInUser) {
        matchedOrg = organisation._id;
      }
    });
    return matchedOrg;
  };

  getPrisonSpecialist = prison => {
    let matchedMentor = undefined;
    const mentors = getListOfPrisonSpecialists(
      this.props.selectedReferralData.area,
      this.props.userListData,
      this.props.selectedReferralData.prisonOrganisation,
      prison
    );
    // Use regular for loop to enable early return
    for (let i = 0; i < mentors.length; i++) {
      // Assign to the current user if they match the criteria
      if (mentors[i].value && mentors[i].value.includes(this.props.userId)) {
        return this.props.userId;
      }
      // Else, return another mentor that matches the criteria
      if (mentors[i].prison && mentors[i].prison.includes(prison)) {
        matchedMentor = mentors[i].value;
      }
    }
    return matchedMentor;
  };

  resetDataOnModalClose = async () => {
    await this.props.updateSelectedReferral("returningCustomer", undefined);
  };

  getPrisonOrg = prison => {
    let organisation;
    this.props.organisationListData.forEach(org => {
      if (
        Array.isArray(org["_source"].prisons) &&
        org["_source"].prisons.includes(prison) &&
        (org["_source"].mainType === strings.orgTypes.leadPartner ||
          org["_source"].mainType === strings.orgTypes.endToEndPartner)
      ) {
        organisation = org["_source"].organisationId;
      }
    });
    return organisation;
  };

  getMentors = area => {
    return getListOfMentors(area, this.props.userListData);
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.usersLoadingPage ||
      this.props.orgsLoadingPage ||
      this.state.loadingCustomers;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <RequestFeedback
            requestStatus={this.props.taskRequestStatus}
            requestMade={this.props.taskRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.state.taskResult}
            failureMessage={this.props.taskError}
            processingFeedbackMessage={strings.feedback.processing}
            unknownFeedbackMessage={strings.feedback.requestUnknown}
            statusFeedbackMessage={strings.feedback.status}
            successFeedbackMessage={strings.feedback.success}
            errorDetails={this.props.taskErrorDetails}
          />
          <ModifyReferral
            mode="create"
            pageTitle={strings.header.createReferral}
            selectedReferralData={this.props.selectedReferralData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            confirmDuplicateModalOpen={this.state.confirmDuplicateModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            enableDisableReferralModalOpen={
              this.state.enableDisableReferralModalOpen
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableReferralModalVisibility={
              this.toggleEnableDisableReferralModalVisibility
            }
            toggleConfirmDuplicateModalVisibility={
              this.toggleConfirmDuplicateModalVisibility
            }
            handleChange={this.handleChange}
            handleChangePrisonNrs={this.handleChangePrisonNrs}
            handleChangePrisonNrne={this.handleChangePrisonNrne}
            handleChangeOutcomeOfThreshold={this.handleChangeOutcomeOfThreshold}
            handleChangeOutcomeReason={this.handleChangeOutcomeReason}
            handleAreaChange={this.handleAreaChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            allowNumericalInput={allowNumericalInput}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            area={this.props.area}
            selectedArea={this.state.selectedArea}
            roleId={this.props.roleId}
            getOrganisations={this.state.organisations}
            mentorsArea={this.state.mentorsArea}
            getInitialOwner={this.getInitialOwner}
            userId={this.props.userId}
            initialReferralData={{}}
            resetDataOnModalClose={this.resetDataOnModalClose}
          />
        </div>
      </div>
    );
  };
}

ReferralCreateContainer.propTypes = {
  area: PropTypes.string.isRequired,
  clearReferralCreation: PropTypes.func.isRequired,
  clearSelectedReferral: PropTypes.func.isRequired,
  createReferral: PropTypes.func.isRequired,
  getOrganisations: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  organisationListData: PropTypes.array.isRequired,
  organisationOfLoggedInUser: PropTypes.string.isRequired,
  roleId: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  updateSelectedReferral: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userListData: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  const { users, customers, tasks } = state;
  const { customerData } = customers;
  const { userListData, loadingPage: usersLoadingPage } = users;
  const {
    loadingPage: taskLoadingPage,
    taskRequestStatus,
    taskRequestMade,
    result: taskResult,
    error: taskError,
    totalResults: taskTotalResults,
    errorDetails: taskErrorDetails
  } = tasks;
  const {
    organisationListData,
    loadingPage: orgsLoadingPage
  } = state.organisations;
  const {
    loadingPage,
    selectedReferralData,
    referralListData
  } = state.referrals;
  const { roleId, area, organisationOfLoggedInUser, userId } = state.auth;
  return {
    loadingPage,
    selectedReferralData,
    roleId,
    area,
    organisationOfLoggedInUser,
    userListData,
    organisationListData,
    userId,
    customerData,
    referralListData,
    usersLoadingPage,
    orgsLoadingPage,
    taskLoadingPage,
    taskRequestStatus,
    taskRequestMade,
    taskResult,
    taskError,
    taskTotalResults,
    taskErrorDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createReferral: (data, headers) => {
      dispatch(referralActions.createReferral(data, headers));
    },
    updateSelectedReferral: async (key, value) => {
      await dispatch(referralActions.updateSelectedReferral(key, value));
    },
    clearSelectedReferral: () => {
      dispatch(referralActions.clearSelectedReferral());
    },
    clearReferralCreation: () => {
      dispatch(referralActions.clearReferralRequest());
    },
    getUsers: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getCustomerByPNumber: async (headers, pNumber) => {
      await dispatch(customerActions.getCustomerByPNumber(headers, pNumber));
    },
    getOrganisations: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getReferrals: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        referralActions.getReferrals(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    updateReferral: (id, data, headers, initialReferralData) => {
      dispatch(
        referralActions.updateReferral(id, data, headers, initialReferralData)
      );
    },
    createTask: (details, headers, createTaskMessage = "") => {
      dispatch(taskActions.createTask(details, headers, createTaskMessage));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralCreateContainer);
