import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "./components/Layout";
import Routes from "./routes";
import { config } from "../_config/aws";
import { azureConfig } from "../_config/azure";
import { UserAgentApplication } from "msal";
import {
  getUserDetails,
  createCalendarEvent,
  removeCalendarEvent
} from "./GraphService";
import { authActions } from "./store/actions/auth.actions";
import { auditActions } from "../Audit/store/actions/audit.actions";
import { settingActions } from "../Settings/store/actions/setting.actions";
import "./App.scss";
import moment from "moment";
import IdleTimer from "react-idle-timer";
import { BasicModal } from "./components";
import { strings } from "./resources";
import SystemHeaders from "./classes/SystemHeaders";
import userLockHelpers from "./helpers/userLockHelpers";

const timeoutPeriodInMinutes = 120;

const idleTimerReminderInMinutes = 10;
const idleTimerResponseInMinutes = 5;

class App extends Component {
  state = {
    lastActivity: null,
    idleTimerModalOpen: false,
    isTimedOut: false,
    microsoftUser: {},
    isMicrosoftAuthenticated: false,
    error: null
  };

  checkAuthenticationStatus = async () => {
    try {
      await this.props.checkCurrentAuthenticatedUser();
    } catch (err) {
      // Set session expired if authentication check throws error
      await this.props.setSessionExpired();
    }
  };

  checkLastActivity = async () => {
    if (this.state.lastActivity) {
      const lastActivityTime = moment(this.state.lastActivity).local();
      const currentTime = moment(+new Date()).local();
      const diffInMinutes = currentTime.diff(lastActivityTime, "minutes");
      if (diffInMinutes >= timeoutPeriodInMinutes) {
        await this.props.setSessionExpired();
      }
    }
  };

  setupUserAgentApplication = () => {
    this.userAgentApplication = new UserAgentApplication({
      auth: {
        clientId: azureConfig.appId,
        redirectUri: azureConfig.redirectUri
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
      }
    });

    let microsoftUser = this.userAgentApplication.getAccount();

    if (microsoftUser) {
      this.getUserProfile();
    }
  };

  azureLogin = async () => {
    try {
      // Login via popup
      await this.userAgentApplication.loginPopup({
        scopes: azureConfig.scopes,
        prompt: "select_account"
      });
      // After login, get the user's profile
      await this.getUserProfile();
    } catch (err) {
      this.setState({
        isMicrosoftAuthenticated: false,
        microsoftUser: {},
        error: this.normalizeError(err)
      });
    }
  };

  azureLogout = () => {
    this.userAgentApplication.logout();
  };

  getAccessToken = async scopes => {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      var silentResult = await this.userAgentApplication.acquireTokenSilent({
        scopes: scopes
      });

      return silentResult.accessToken;
    } catch (err) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (this.isInteractionRequired(err)) {
        var interactiveResult = await this.userAgentApplication.acquireTokenPopup(
          {
            scopes: scopes
          }
        );

        return interactiveResult.accessToken;
      } else {
        throw err;
      }
    }
  };

  isInteractionRequired(error) {
    if (!error.message || error.message.length <= 0) {
      return false;
    }

    return (
      error.message.indexOf("consent_required") > -1 ||
      error.message.indexOf("interaction_required") > -1 ||
      error.message.indexOf("login_required") > -1
    );
  }

  getUserProfile = async () => {
    try {
      var accessToken = await this.getAccessToken(azureConfig.scopes);

      if (accessToken) {
        // Get the user's profile from Graph
        var user = await getUserDetails(accessToken);
        this.setState({
          isMicrosoftAuthenticated: true,
          microsoftUser: {
            displayName: user.displayName,
            email: user.mail || user.userPrincipalName
          },
          error: null
        });
      }
    } catch (err) {
      this.setState({
        isMicrosoftAuthenticated: false,
        microsoftUser: {},
        error: this.normalizeError(err)
      });
    }
  };

  normalizeError = error => {
    var normalizedError = {};
    if (typeof error === "string") {
      var errParts = error.split("|");
      normalizedError =
        errParts.length > 1
          ? { message: errParts[1], debug: errParts[0] }
          : { message: error };
    } else {
      normalizedError = {
        message: error.message,
        debug: JSON.stringify(error)
      };
    }
    return normalizedError;
  };

  createCalendarEvent = async event => {
    try {
      var accessToken = await this.getAccessToken(azureConfig.scopes);

      if (accessToken) {
        return createCalendarEvent(accessToken, event);
      }
    } catch (err) {
      this.setState({
        isMicrosoftAuthenticated: false,
        microsoftUser: {},
        error: this.normalizeError(err)
      });
    }
  };

  removeCalendarEvent = async eventId => {
    try {
      var accessToken = await this.getAccessToken(azureConfig.scopes);

      if (accessToken) {
        removeCalendarEvent(accessToken, eventId);
      }
    } catch (err) {
      this.setState({
        isMicrosoftAuthenticated: false,
        microsoftUser: {},
        error: this.normalizeError(err)
      });
    }
  };

  setErrorMessage = (message, debug) => {
    this.setState({
      error: { message: message, debug: debug }
    });
  };

  convertMinsToMs = date => {
    return date * 60000;
  };

  setTimer = () => {
    clearTimeout(this.timeoutID);
    const timeoutPeriod = this.convertMinsToMs(
      idleTimerReminderInMinutes + idleTimerResponseInMinutes
    );

    this.timeoutID = setTimeout(() => {
      this.handleTimeout();
    }, timeoutPeriod);
  };

  resetIdleTimeout = () => {
    this.setState({ isTimedOut: false }, this.setTimer());
  };

  onAction = () => {
    this.resetIdleTimeout();
  };

  onActive = () => {
    this.resetIdleTimeout();
  };

  onIdle = async () => {
    const { isTimedOut } = this.state;

    if (isTimedOut) {
      await this.props.setSessionExpired();
    } else {
      this.toggleModal();
      if (this.idleTimer) {
        this.idleTimer.reset();
      }
      this.setState({ isTimedOut: true });
    }
  };

  toggleModal = () => {
    this.setState({ idleTimerModalOpen: !this.state.idleTimerModalOpen });
  };

  handleSubmit = () => {
    this.resetIdleTimeout();
  };

  handleTimeout = async () => {
    let currentState = SystemHeaders.load();
    if (this.props.isLoggedIn && currentState["headers"]) {
      await this.props.createAudit(
        {
          userId: this.props.userId,
          actionType: "Logout (timeout)",
          username: this.props.username,
          sessionId: currentState["headers"]["sessionId"],
          ipAddress: localStorage.getItem("ipAddress")
        },
        this.props.headers
      );
    }
    clearTimeout(this.timeoutID);
    this.setState({ isTimedOut: true }, this.toggleModal());
    await this.props.setSessionExpired();
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "idleTimerModal":
        this.handleSubmit();
        break;
      default:
        break;
    }
  };

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    let currentState = SystemHeaders.load();
    if (!prevProps.isLoggedIn && this.props.isLoggedIn && this.idleTimer) {
      this.setTimer();
      this.idleTimer.reset();
      this.setState({ isTimedOut: false, idleTimerModalOpen: false });
    }
    if (
      this.props.isLoggedIn !== prevProps.isLoggedIn &&
      this.props.isLoggedIn &&
      this.props.userId &&
      this.props.logInButtonClicked
    ) {
      await this.props.createAudit(
        {
          userId: this.props.userId,
          actionType: "Login",
          username: this.props.username,
          sessionId: currentState["headers"]["sessionId"],
          ipAddress: localStorage.getItem("ipAddress")
        },
        this.props.headers
      );
      await this.props.getSettings(this.props.headers, true);
      userLockHelpers.findSettingValues(
        this.props.selectedSettingData,
        this.props.area,
        strings.areas
      );
      this.props.clearSelectedSetting();
    } else if (
      this.props.isLoggedIn &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.props.createAudit(
        {
          userId: this.props.userId,
          page: window.location.hash.slice(1),
          actionType: "Change Page",
          username: this.props.username,
          sessionId: currentState["headers"]["sessionId"],
          ipAddress: localStorage.getItem("ipAddress")
        },
        this.props.headers
      );
    }

    if (
      prevProps.location.pathname !== prevProps.history.location.pathname &&
      !this.props.loggedOut
    ) {
      this.checkAuthenticationStatus();
    }
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    this.setupUserAgentApplication();

    let lastActive =
      JSON.parse(localStorage.getItem("activity")) || +new Date();
    if (lastActive) {
      this.setState({ lastActivity: lastActive });
    }
    localStorage.removeItem("activity");
    this.checkLastActivity();

    window.addEventListener("beforeunload", event => {
      localStorage.setItem("activity", this.state.lastActivity);
    });
  };

  renderIdleTimer = () => {
    const idleTimerTimeout = this.convertMinsToMs(idleTimerReminderInMinutes);

    return (
      <>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={idleTimerTimeout}
        />

        <BasicModal
          confirmMessage={strings.modal.confirmMessage}
          handleModalConfirm={this.handleModalConfirm}
          modalClassName="idleTimerModal"
          modalOpen={this.state.idleTimerModalOpen}
          showNoButton
          showYesButton
          showOkButton={false}
          toggleModal={this.toggleModal}
          noLabel={strings.modal.noLabel}
          yesLabel={strings.modal.yesLabel}
          okLabel={strings.modal.okLabel}
          handleClose={this.handleTimeout}
        />
      </>
    );
  };

  render() {
    const isLoggedIn = this.props.isLoggedIn;
    const roleId = this.props.roleId;
    const area = this.props.area;
    let headers = {};
    if (JSON.parse(localStorage.getItem("state"))) {
      headers = JSON.parse(localStorage.getItem("state")).headers;
    }

    const injectedProps = {
      headers,
      isLoggedIn,
      roleId,
      area,
      cognitoUserPool: config.cognito.UserPoolId,
      cognitoClient: config.cognito.ClientId,
      signOut: this.props.signOut,
      createCalendarEvent: this.createCalendarEvent,
      removeCalendarEvent: this.removeCalendarEvent,
      microsoftUser: this.state.microsoftUser
    };

    return (
      <div className="App">
        <Layout
          headers={headers}
          azureLogin={this.azureLogin}
          azureLogout={this.azureLogout}
          isMicrosoftAuthenticated={this.state.isMicrosoftAuthenticated}
          microsoftUser={this.state.microsoftUser}
        >
          {isLoggedIn && this.renderIdleTimer()}
          <Routes injectedProps={injectedProps} />
        </Layout>
      </div>
    );
  }
}

App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  area: PropTypes.string,
  roleId: PropTypes.string,
  refreshSignInStatus: PropTypes.func.isRequired,
  checkCurrentAuthenticatedUser: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    isLoggedIn,
    roleId,
    area,
    loggedOut,
    logInButtonClicked,
    userId,
    username
  } = state.auth;
  const { selectedSettingData } = state.settings;
  return {
    isLoggedIn,
    roleId,
    area,
    loggedOut,
    logInButtonClicked,
    userId,
    username,
    selectedSettingData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshSignInStatus: () => {
      dispatch(authActions.refreshSignInStatus());
    },
    checkCurrentAuthenticatedUser: () => {
      return dispatch(authActions.checkCurrentAuthenticatedUser());
    },
    signOut: () => {
      return dispatch(authActions.signOut());
    },
    setSessionExpired: () => {
      return dispatch(authActions.setSessionExpired());
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    },
    getSettings: async (headers, isLoggingIn) => {
      await dispatch(settingActions.getSettings(headers, isLoggingIn));
    },
    clearSelectedSetting: () => {
      dispatch(settingActions.clearSelectedSetting());
    }
  };
};

const connectedApp = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(App)
);
export { connectedApp as App };
