import React from "react";
import ModifyTtgEngagement from "../../components/ModifyTtgEngagement";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { ttgEngagementActions } from "../../store/actions/ttgEngagement.actions";
import { ttgProgrammeActions } from "../../../TtgProgrammes/store/actions/ttgProgramme.actions";
import { areaOfNeedActions } from "../../../AreaOfNeed/store/actions/areaOfNeed.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import PropTypes from "prop-types";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { convertToIso, getUsers, textFormattingHelpers } from "../../helpers";
import uuidv4 from "uuid/v4";

class TtgEngagementCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableTtgEngagementModalOpen: false,
    formInvalid: false,
    validationResults: {},
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    isPrePopulated: false,
    shouldSaveFiles: false,
    hasFileChanges: false,
    hasPageLoaded: false,
    userList: [],
    addNew: false
  };

  componentDidMount = async () => {
    this.props.clearSelectedTtgEngagement();
    this.props.clearSelectedTtgProgramme();
    await this.handleFetchData();

    this.setState(
      { globalProgrammeId: localStorage.getItem("ttgProgrammeId") },
      () => {
        if (this.state.globalProgrammeId) {
          this.props.getTtgProgrammeById(
            this.state.globalProgrammeId,
            this.props.headers
          );
        }
      }
    );

    let form = document.getElementById("ttgEngagementForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }

    if (this.props.match.params.entityType === "ttgProgramme") {
      await this.props.getTtgProgrammeById(
        this.props.match.params.entityId,
        this.props.headers
      );
    }

    this.props.updateSelectedTtgEngagement(
      "needId",
      this.props.match.params.needId
    );

    this.props.updateSelectedTtgEngagement(
      "ttgProgrammeId",
      this.state.globalProgrammeId
    );

    this.props.getAreaOfNeedById(
      this.props.match.params.needId,
      this.props.headers
    );

    this.setState({
      hasPageLoaded: true,
      userList: getUsers(this.props.userListData)
    });
    this.props.updateSelectedTtgEngagement("ttgEngagementId", uuidv4());
  };

  componentDidUpdate = () => {
    if (
      Object.keys(this.props.selectedTtgProgrammeData).length > 0 &&
      !this.state.isPrePopulated
    ) {
      if (this.props.selectedTtgProgrammeData.firstName) {
        this.props.updateSelectedTtgEngagement(
          "firstName",
          this.props.selectedTtgProgrammeData.firstName
        );
      }
      if (this.props.selectedTtgProgrammeData.surname) {
        this.props.updateSelectedTtgEngagement(
          "surname",
          this.props.selectedTtgProgrammeData.surname
        );
      }
      if (this.props.selectedTtgProgrammeData.ttgProgrammeId) {
        this.props.updateSelectedTtgEngagement(
          "ttgProgrammeId",
          this.props.selectedTtgProgrammeData.ttgProgrammeId
        );
      }
      if (this.props.selectedAreaOfNeedData !== undefined) {
        this.props.updateSelectedTtgEngagement(
          "needId",
          this.props.selectedAreaOfNeedData.needId
        );
      }
      this.props.updateSelectedTtgEngagement("area", strings.form.text.ttg);
      this.setState({ isPrePopulated: true });
    }
  };

  componentWillUnmount() {
    this.props.clearSelectedTtgEngagement();
  }

  validateFormAndAddNew = async () => {
    this.setState({ addNew: true });
    this.validateForm();
  };

  filesHaveChanged = () => {
    this.setState({ hasFileChanges: true });
  };

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    sCrit.query = "ttg";
    sCrit.fields = ["area.normalizer"];
    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  validateForm = async () => {
    let details = JSON.parse(
      JSON.stringify(this.props.selectedTtgEngagementData)
    );
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.ttgEngagements.modifyTtgEngagement
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    this.setState({ shouldSaveFiles: true });

    const data = textFormattingHelpers.decodeData(
      this.props.selectedTtgEngagementData,
      {},
      textFields
    );
    await this.props.createTtgEngagement(data, this.props.headers);

    if (this.state.addNew) {
      window.location.reload();
    } else {
      this.props.history.push("/ttgEngagements");
    }
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearTtgEngagementRequest();
    this.props.history.push("/ttgEngagements");
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedTtgEngagement(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedTtgEngagement(data.name, data.value);
    }
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableTtgEngagementModalVisibility = () => {
    this.setState({
      enableDisableTtgEngagementModalOpen: !this.state
        .enableDisableTtgEngagementModalOpen
    });
  };

  render = () => {
    const loading = this.props.loadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <ModifyTtgEngagement
            mode="create"
            role={this.props.roleId}
            pageTitle={strings.header.createTtgEngagement}
            selectedTtgEngagementData={this.props.selectedTtgEngagementData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            enableDisableTtgEngagementModalOpen={
              this.state.enableDisableTtgEngagementModalOpen
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableTtgEngagementModalVisibility={
              this.toggleEnableDisableTtgEngagementModalVisibility
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            initialTtgEngagementData={{}}
            shouldSaveFiles={this.state.shouldSaveFiles}
            filesHaveChanged={this.filesHaveChanged}
            hasFileChanges={this.state.hasFileChanges}
            headers={this.props.headers}
            hasPageLoaded={this.state.hasPageLoaded}
            userList={this.state.userList}
            validateFormAndAddNew={this.validateFormAndAddNew}
          />
        </div>
      </div>
    );
  };
}

TtgEngagementCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedTtgEngagementData: PropTypes.object.isRequired,
  updateSelectedTtgEngagement: PropTypes.func.isRequired,
  clearSelectedTtgEngagement: PropTypes.func.isRequired,
  createTtgEngagement: PropTypes.func.isRequired,
  clearTtgEngagementRequest: PropTypes.func,
  getUsers: PropTypes.func,
  roleId: PropTypes.string,
  userListData: PropTypes.array
};

const mapStateToProps = state => {
  const { userListData } = state.users;
  const { loadingPage, selectedTtgEngagementData } = state.ttgEngagements;
  const { selectedTtgProgrammeData } = state.ttgProgrammes;
  const { roleId, userId } = state.auth;
  return {
    loadingPage,
    selectedTtgEngagementData,
    roleId,
    userListData,
    selectedTtgProgrammeData,
    userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createTtgEngagement: async (data, headers) => {
      await dispatch(ttgEngagementActions.createTtgEngagement(data, headers));
    },
    updateSelectedTtgEngagement: (key, value) => {
      dispatch(ttgEngagementActions.updateSelectedTtgEngagement(key, value));
    },
    clearSelectedTtgEngagement: () => {
      dispatch(ttgEngagementActions.clearSelectedTtgEngagement());
    },
    clearTtgEngagementRequest: () => {
      dispatch(ttgEngagementActions.clearTtgEngagementRequest());
    },
    getTtgProgrammeById: (id, headers) => {
      dispatch(ttgProgrammeActions.getTtgProgrammeById(id, headers));
    },
    clearSelectedTtgProgramme: () => {
      dispatch(ttgProgrammeActions.clearSelectedTtgProgramme());
    },
    getAreaOfNeedById: (id, headers) => {
      dispatch(areaOfNeedActions.getAreaOfNeedById(id, headers));
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TtgEngagementCreateContainer);
