import { dummyRecordActionTypes } from "../actions/dummyRecord.actionTypes";

const initialState = {
  loadingPage: false,
  selectedDummyRecordData: {},
  dummyRecordListData: [{}],
  totalResults: 0,
  dummyRecordToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  dummyRecordRequestStatus: undefined,
  dummyRecordRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let details;
  let newTotal;
  switch (action.type) {
    case dummyRecordActionTypes.CREATE_DUMMY_RECORD_REQUEST:
      return {
        ...state,
        dummyRecordRequestStatus: undefined,
        dummyRecordRequestMade: true
      };
    case dummyRecordActionTypes.CREATE_DUMMY_RECORD_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        dummyRecordRequestStatus: true
      };
    case dummyRecordActionTypes.CREATE_DUMMY_RECORD_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        dummyRecordRequestStatus: false
      };

    case dummyRecordActionTypes.CLEAR_CREATE_DUMMY_RECORD_REQUEST:
      return { ...state };
    case dummyRecordActionTypes.CLEAR_CREATE_DUMMY_RECORD_SUCCESS:
      return {
        ...state,
        dummyRecordRequestMade: false,
        dummyRecordRequestStatus: undefined
      };
    case dummyRecordActionTypes.CLEAR_CREATE_DUMMY_RECORD_FAILURE:
      return { ...state };

    case dummyRecordActionTypes.UPDATE_DUMMY_RECORD_REQUEST:
      return {
        ...state,
        loadingPage: true,
        dummyRecordRequestStatus: undefined,
        dummyRecordRequestMade: true
      };
    case dummyRecordActionTypes.UPDATE_DUMMY_RECORD_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        dummyRecordRequestStatus: true,
        selectedDummyRecordData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case dummyRecordActionTypes.UPDATE_DUMMY_RECORD_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        dummyRecordRequestStatus: false,
        selectedDummyRecordData: action.initialDetails,
        errorDetails: details
      };

    case dummyRecordActionTypes.DELETE_DUMMY_RECORD_REQUEST:
      return {
        ...state,
        dummyRecordRequestStatus: undefined,
        dummyRecordRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case dummyRecordActionTypes.DELETE_DUMMY_RECORD_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        dummyRecordRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case dummyRecordActionTypes.DELETE_DUMMY_RECORD_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        dummyRecordRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case dummyRecordActionTypes.GET_DUMMY_RECORDS_REQUEST:
      return { ...state, loadingPage: true, deleteSuccessful: false };
    case dummyRecordActionTypes.GET_DUMMY_RECORDS_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newDummyRecord = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newDummyRecord;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        dummyRecordListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case dummyRecordActionTypes.GET_DUMMY_RECORDS_FAILURE:
      return { ...state, dummyRecordListData: [{}], loadingPage: false };

    case dummyRecordActionTypes.GET_DUMMY_RECORD_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        dummyRecordRequestStatus: undefined
      };
    case dummyRecordActionTypes.GET_DUMMY_RECORD_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        dummyRecordRequestStatus: true,
        selectedDummyRecordData: action.response.Body.Item,
        loadingPage: false
      };
    case dummyRecordActionTypes.GET_DUMMY_RECORD_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedDummyRecordData: {},
        dummyRecordRequestStatus: false,
        dummyRecordRequestMade: true
      };

    case dummyRecordActionTypes.UPDATE_SELECTED_DUMMY_RECORD_REQUEST:
      return { ...state };
    case dummyRecordActionTypes.UPDATE_SELECTED_DUMMY_RECORD_SUCCESS:
      return {
        ...state,
        selectedDummyRecordData: {
          ...state.selectedDummyRecordData,
          [action.key]: action.value
        }
      };
    case dummyRecordActionTypes.UPDATE_SELECTED_DUMMY_RECORD_FAILURE:
      return { ...state };

    case dummyRecordActionTypes.CLEAR_SELECTED_DUMMY_RECORD_REQUEST:
      return { ...state };
    case dummyRecordActionTypes.CLEAR_SELECTED_DUMMY_RECORD_SUCCESS:
      return { ...state, selectedDummyRecordData: {} };
    case dummyRecordActionTypes.CLEAR_SELECTED_DUMMY_RECORD_FAILURE:
      return { ...state };

    case dummyRecordActionTypes.CANCEL_SELECTED_DUMMY_RECORD_UPDATE_REQUEST:
      return { ...state };
    case dummyRecordActionTypes.CANCEL_SELECTED_DUMMY_RECORD_UPDATE_SUCCESS:
      return { ...state, selectedDummyRecordData: action.data };
    case dummyRecordActionTypes.CANCEL_SELECTED_DUMMY_RECORD_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};
