import { permissions } from "./AppPermissions";
import { strings } from "./Strings";

const generateMenuBar = props => {
  const menubarPart1 = [
    {
      menuItemUrl: "mywork",
      menuItemName: strings.menuPages.mywork,
      menuItemRole: permissions.global,
      iconName: "suitcase"
    },
    {
      menuItemUrl: "caseload",
      menuItemName: strings.menuPages.caseload,
      menuItemRole: permissions.caseloads.read,
      iconName: "calendar",
      allowedAreas: [
        strings.areas.NRS,
        strings.areas.NRNE,
        strings.areas.ALL,
        strings.areas.NE,
        strings.areas.TTG
      ]
    },
    {
      menuItemUrl: "customers",
      menuItemName: strings.menuPages.customers,
      menuItemRole: permissions.customers.read,
      iconName: "user"
    },
    {
      menuItemUrl: "referrals",
      menuItemName: strings.menuPages.referrals,
      menuItemRole: permissions.referrals.read,
      iconName: "share",
      allowedAreas: [
        strings.areas.NRS,
        strings.areas.NRNE,
        strings.areas.ALL,
        strings.areas.NE
      ]
    },
    {
      menuItemUrl: "dummyRecords",
      menuItemName: strings.menuPages.dummyRecords,
      menuItemRole: permissions.dummyRecords.read,
      iconName: "archive",
      allowedAreas: [strings.areas.TTG, strings.areas.ALL, strings.areas.NE]
    }
  ];

  let programmes = {
    menuItemUrl: "programmes",
    menuItemName: strings.menuPages.programmes,
    menuItemRole: permissions.programmes.read,
    iconName: "list ul",
    allowedAreas: [
      strings.areas.NRS,
      strings.areas.NRNE,
      strings.areas.ALL,
      strings.areas.NE
    ]
  };
  if (localStorage.getItem("programmeId")) {
    programmes.subItems = programmeSubMenuItems(props);
  }
  let ttgProgrammes = {
    menuItemUrl: "ttgProgrammes",
    menuItemName: strings.menuPages.ttgProgrammes,
    menuItemRole: permissions.ttgProgrammes.read,
    iconName: "list ul",
    allowedAreas: [strings.areas.TTG, strings.areas.ALL, strings.areas.NE]
  };

  if (localStorage.getItem("ttgProgrammeId")) {
    ttgProgrammes.subItems = ttgProgrammeSubMenuItems(props);
  }

  const menubarPart2 = [
    {
      menuItemUrl: "organisations",
      menuItemName: strings.menuPages.organisations,
      menuItemRole: permissions.organisations.read,
      iconName: "map marker alternate"
    },
    {
      menuItemUrl: "ocr",
      menuItemName: strings.menuPages.ocr,
      menuItemRole: permissions.global,
      iconName: "upload"
    },
    {
      menuItemUrl: "reports",
      menuItemName: strings.menuPages.reports,
      menuItemRole: permissions.reports.read,
      iconName: "chart bar"
    },
    {
      menuItemUrl: "users",
      menuItemName: strings.menuPages.users,
      menuItemRole: permissions.users.read,
      iconName: "lock"
    },
    {
      menuItemUrl: "audit",
      menuItemName: strings.menuPages.audit,
      menuItemRole: permissions.audit,
      iconName: "exchange"
    },
    {
      menuItemUrl: "settings",
      menuItemName: strings.menuPages.settings,
      menuItemRole: permissions.settings.read,
      iconName: "cog"
    }
  ];

  return [...menubarPart1, programmes, ttgProgrammes, ...menubarPart2];
};

const programmeSubMenuItems = props => {
  let items = [
    {
      menuItemUrl: "engagements",
      menuItemName: strings.menuPages.engagements,
      menuItemRole: permissions.engagements.read,
      iconName: "handshake"
    },
    {
      menuItemUrl: "activities",
      menuItemName: strings.menuPages.activities,
      menuItemRole: permissions.activities.read,
      iconName: "object group"
    },
    {
      menuItemUrl: "outcomes",
      menuItemName: strings.menuPages.outcomes,
      menuItemRole: permissions.programmes.read,
      iconName: "eye"
    },
    {
      menuItemUrl: "baselines",
      menuItemName: strings.menuPages.baseline,
      menuItemRole: permissions.baselines.read,
      iconName: "clipboard outline"
    },
    {
      menuItemUrl: "assessmentSG",
      menuItemName: strings.menuPages.assessmentSG,
      menuItemRole: permissions.assessmentSG.read,
      iconName: "clipboard list",
      allowedAreas: [strings.areas.NRS, strings.areas.ALL]
    },
    {
      menuItemUrl: "employments",
      menuItemName: strings.menuPages.employments,
      menuItemRole: permissions.employments.read,
      iconName: "address card"
    },
    {
      menuItemUrl: "costs",
      menuItemName: strings.menuPages.costs,
      menuItemRole: permissions.costs.read,
      iconName: "gbp"
    },
    {
      menuItemUrl: "complaints",
      menuItemName: strings.menuPages.complaints,
      menuItemRole: permissions.complaints.read,
      iconName: "frown"
    },
    {
      menuItemUrl: "feedback",
      menuItemName: strings.menuPages.feedback,
      menuItemRole: permissions.feedback.listAccess,
      iconName: "pen square"
    },
    {
      menuItemUrl: "assurances",
      menuItemName: strings.menuPages.assurances,
      menuItemRole: permissions.assurances.read,
      iconName: "shield"
    },
    {
      menuItemUrl: "supportreferrals",
      menuItemName: strings.menuPages.supportReferrals,
      menuItemRole: permissions.supportReferrals.read,
      iconName: "external share",
      allowedAreas: [strings.areas.NRS, strings.areas.ALL]
    },
    {
      menuItemUrl: "news",
      menuItemName: strings.menuPages.news,
      menuItemRole: permissions.news.read,
      iconName: "newspaper"
    },
    {
      menuItemUrl: "history",
      menuItemName: strings.menuPages.history,
      menuItemRole: permissions.history.read,
      iconName: "history"
    }
  ];

  return items;
};

const ttgProgrammeSubMenuItems = props => {
  let items = [
    {
      menuItemUrl: "areaOfNeed",
      menuItemName: strings.menuPages.areaOfNeed,
      menuItemRole: permissions.areaOfNeed.read,
      iconName: "braille"
    },
    {
      menuItemUrl: "ttgEngagements",
      menuItemName: strings.menuPages.ttgEngagements,
      menuItemRole: permissions.ttgEngagements.read,
      iconName: "handshake"
    }
  ];
  return items;
};

const generateFlattenedMenu = props => {
  let flattenedMenu = [];
  const menubar = generateMenuBar(props);
  menubar.forEach(items => {
    flattenedMenu.push({
      itemUrl: items.menuItemUrl,
      activeItem: items.menuItemName,
      activeSubItem: ""
    });
    if (items.subItems)
      items.subItems.forEach(subItem => {
        flattenedMenu.push({
          itemUrl: subItem.menuItemUrl,
          activeItem: items.menuItemName,
          activeSubItem: subItem.menuItemUrl
        });
      });
  });

  return flattenedMenu;
};

export { generateMenuBar, generateFlattenedMenu };
