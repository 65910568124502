import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Loader, Input, TextArea } from "semantic-ui-react";
import { strings } from "../resources";
import { ComponentHeader, BasicModal, TextInputModal, InputModal } from ".";
import { ValidatedFormDropdown } from "./validation";
import "./css/ProgrammeComponents.scss";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import FieldList from "./FieldList";
import { AuditListContainer } from "../../Audit/containers/";

class ModifyProgramme extends React.Component {
  returnToList = () => {
    this.props.history.push("/programmes");
  };

  isSharable = () => {
    return (
      this.props.orgOfLoggedInUser === this.props.selectedProgrammeData.owner &&
      !this.props.hasChoseForm &&
      permissions.programmes.shareRecord.includes(this.props.roleId) &&
      this.props.selectedProgrammeData.area === strings.areas.NRS
    );
  };

  renderShareButtons = () => {
    const modalDropdown = (
      <ValidatedFormDropdown
        formInvalid={false}
        inputData={this.props.selectedProgrammeData.sharedWith}
        viewData={this.props.selectedProgrammeData.sharedWith}
        dropdownLabel={strings.form.label.sharedWith}
        dropdownName="sharedWith"
        dropdownOptions={this.props.getOrganisations().filter(org => {
          return org.maintype === strings.orgTypes.supportPartner;
        })}
        dropdownPlaceholder={strings.form.placeholder.sharedWith}
        formFieldWidth={8}
        handleChange={this.props.handleChange}
        validationResult={this.props.validationResults.sharedWith}
        dropdownMultiple={true}
        dropdownFluid={true}
        required={true}
        hideMessage={true}
      />
    );

    return (
      <>
        <Button.Group>
          {this.isSharable() && (
            <Button
              className="shareButton"
              primary
              inverted
              onClick={this.props.toggleShareProgrammeModalVisibility}
              type="button"
            >
              {strings.button.shareLabel}
            </Button>
          )}
        </Button.Group>

        <InputModal
          input={modalDropdown}
          confirmMessage={strings.modal.shareMessage}
          handleModalConfirm={this.props.handleModalConfirm}
          modalClassName="shareModal"
          modalOpen={this.props.shareProgrammeModalOpen}
          showOkButton={true}
          toggleModal={this.props.toggleShareProgrammeModalVisibility}
          okLabel={strings.button.okLabel}
          resetDataOnModalClose={this.props.resetDataOnModalClose}
        />
      </>
    );
  };

  renderModifyButtons = () => {
    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleChangeModal}
      ></Input>
    );
    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful &&
          (!this.props.isDisabled ? (
            <>
              <Button.Group>
                <Button
                  className="editButton"
                  primary
                  onClick={() => {
                    this.props.handleModeSwitch("edit");
                  }}
                  disabled={
                    !this.props.selectedProgrammeData.active ||
                    !this.props.hasChoseForm ||
                    this.props.isDisabled ||
                    this.props.showAuditLog
                  }
                >
                  {strings.button.editLabel}
                </Button>
              </Button.Group>
            </>
          ) : (
            <span className="errorText">
              {strings.form.errorText.notShared}
            </span>
          ))}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful &&
          !this.props.isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={this.props.toggleEnableDisableProgrammeModalVisibility}
                type="button"
                disabled={this.props.selectedProgrammeData.requestId}
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableProgrammeModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={
                  this.props.toggleEnableDisableProgrammeModalVisibility
                }
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
                resetDataOnModalClose={this.props.resetReasonsOnModalClose}
              />
            </Button.Group>
          )}
        {this.renderShareButtons()}
      </div>
    );
  };

  renderDeleteButton = () => {
    return (
      <div className="containerButtons">
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful &&
          !this.props.isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={this.props.toggleEnableDisableProgrammeModalVisibility}
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteProgrammeMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableProgrammeModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={
                  this.props.toggleEnableDisableProgrammeModalVisibility
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderAmendmentButton = () => {
    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful &&
          !this.props.isDisabled &&
          this.props.mode === "view" && (
            <Button.Group>
              <Button
                className="amendmentButton"
                primary
                inverted
                onClick={() => {
                  this.props.handleAmendmentMode();
                }}
                disabled={
                  !this.props.selectedProgrammeData.active ||
                  !this.props.hasChoseForm ||
                  this.props.isDisabled ||
                  this.props.showAuditLog
                }
              >
                {strings.button.requestAmendmentLabel}
              </Button>
            </Button.Group>
          )}
      </div>
    );
  };

  renderAuditButton = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="auditButton"
            onClick={() => {
              this.props.handleToggleAudit();
            }}
          >
            {!this.props.showAuditLog
              ? strings.button.auditLabel
              : strings.button.formLabel}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderAmendmentCancelSaveButtons = () => {
    const modalTextInput = (
      <Form>
        <TextArea
          placeholder={strings.form.placeholder.stateReason}
          onChange={this.props.handleChangeModal}
          name="amendmentReason"
        ></TextArea>
      </Form>
    );
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={this.props.validateForm}
            disabled={
              JSON.stringify(this.props.selectedProgrammeData) ===
                JSON.stringify(this.props.initialProgrammeData) &&
              !this.props.hasFileChanges
            }
          >
            {strings.button.submitAmendmentLabel}
          </Button>

          <InputModal
            input={modalTextInput}
            inputValue={this.props.amendmentReason}
            inputRequired
            confirmMessage={strings.modal.amendmentMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmSubmitAmendmentModal"
            modalOpen={this.props.confirmSubmitAmendmentModalOpen}
            showOkButton={true}
            toggleModal={this.props.toggleConfirmSubmitAmendmentModalVisibility}
            okLabel={strings.button.okLabel}
            resetDataOnModalClose={this.props.resetReasonsOnModalClose}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={this.props.toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              this.props.mode === "create"
                ? this.props.handleModalConfirm
                : this.props.handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={this.props.cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };

  renderCancelSaveButtons = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={this.props.validateForm}
            disabled={
              JSON.stringify(this.props.selectedProgrammeData) ===
                JSON.stringify(this.props.initialProgrammeData) &&
              !this.props.hasFileChanges
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={this.props.confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={this.props.toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              this.props.mode === "create"
                ? this.props.handleModalConfirm
                : this.props.handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={this.props.cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };
  renderForm = () => {
    return (
      <>
        <div className="infoHeader">{strings.form.header.programmeInfo}</div>
        <div className="infoWrapper">
          <FieldList
            selectedProgrammeData={this.props.selectedProgrammeData}
            initialProgrammeData={this.props.initialProgrammeData}
            handleChange={this.props.handleChange}
            mode={this.props.mode}
            validationResults={this.props.validationResults}
            formInvalid={this.props.formInvalid}
            area={this.props.selectedProgrammeData.area}
            allowNumericalInput={this.props.allowNumericalInput}
            roleId={this.props.roleId}
            specialistDropdown={this.props.specialistDropdown}
            getMentors={this.props.getMentors}
            getMentorsAll={this.props.getMentorsAll}
            getOrganisations={this.props.getOrganisations}
            getInitialOwner={this.props.getInitialOwner}
            userId={this.props.userId}
            chooseForm={this.props.chooseForm}
            hasChoseForm={this.props.hasChoseForm}
            history={this.props.history}
            headers={this.props.headers}
            resetForm={this.props.resetForm}
            isDisabled={
              this.props.hasChoseForm ||
              this.props.selectedProgrammeData.engagementType
            }
            filesHaveChanged={this.props.filesHaveChanged}
            showSupportReferral={this.props.showSupportReferral}
            handleShowSupportReferral={this.props.handleShowSupportReferral}
          />
        </div>
      </>
    );
  };

  render = () => {
    if (Object.keys(this.props.selectedProgrammeData).length > 0) {
      return (
        <div className="container">
          <ComponentHeader
            returnToList={this.returnToList}
            pageTitle={this.props.pageTitle}
            mode={this.props.mode}
            requestStatus={this.props.requestStatus}
            modifyButtons={this.renderModifyButtons()}
            cancelSaveButtons={this.renderCancelSaveButtons()}
            auditLog={this.renderAuditButton()}
            deleteButton={this.renderDeleteButton()}
            amendmentButton={this.renderAmendmentButton()}
            amendmentCancelSaveButtons={this.renderAmendmentCancelSaveButtons()}
            headerList={strings.header.programmeList}
            permissionsUpdate={permissions.programmes.update}
            permissionsCreate={permissions.programmes.create}
            permissionsDelete={permissions.programmes.delete}
            permissionsAmend={permissions.programmes.amend}
            formInvalid={this.props.formInvalid}
            validationResults={this.props.validationResults}
            sticky
            journeyCount={this.props.selectedProgrammeData.journeyCount}
          />
          {this.props.showAuditLog ? (
            <AuditListContainer
              embedded={true}
              headers={this.props.headers}
              history={this.props.history}
              entityId={this.props.selectedProgrammeData.programmeId}
              hideSearchBar={true}
              record={true}
            />
          ) : (
            <Form id="programmeForm" className="infoBlock stickyForm">
              {this.renderForm()}
            </Form>
          )}
        </div>
      );
    } else {
      return <Loader active>{strings.header.loading}</Loader>;
    }
  };
}

ModifyProgramme.propTypes = {
  allowNumericalInput: PropTypes.func.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  enableDisableProgrammeModalOpen: PropTypes.bool.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  handleModeSwitch: PropTypes.func,
  history: PropTypes.object.isRequired,
  initialProgrammeData: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  requestStatus: PropTypes.bool,
  selectedProgrammeData: PropTypes.object.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleEnableDisableProgrammeModalVisibility: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  validationResults: PropTypes.object,
  isDisabled: PropTypes.bool.isRequired
};

export default withRouter(ModifyProgramme);
