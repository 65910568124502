import React from "react";
import ResponsiveContainer from "../../components/Layout/ResponsiveContainer";

const HomepageLayout = props => (
  <ResponsiveContainer
    headers={props.headers}
    azureLogin={props.azureLogin}
    azureLogout={props.azureLogout}
    isMicrosoftAuthenticated={props.isMicrosoftAuthenticated}
    microsoftUser={props.microsoftUser}
  >
    {props.children}
  </ResponsiveContainer>
);
export default HomepageLayout;
