import { API } from "../../services";
import { newsActionTypes } from "./news.actionTypes";
import { amendmentHelpers } from "../../helpers";
import { strings } from "../../resources";

const api = new API();

const createNews = (details, headers) => {
  const request = details => {
    return { type: newsActionTypes.CREATE_NEWS_REQUEST, details };
  };
  const success = response => {
    return { type: newsActionTypes.CREATE_NEWS_SUCCESS, response };
  };
  const failure = error => {
    return { type: newsActionTypes.CREATE_NEWS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "createNews" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };
    return new Promise((resolve, reject) => {
      api.createNews(body).then(
        response => {
          dispatch(success(response));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const clearNewsRequest = () => {
  const request = () => {
    return { type: newsActionTypes.CLEAR_CREATE_NEWS_REQUEST };
  };

  const success = () => {
    return { type: newsActionTypes.CLEAR_CREATE_NEWS_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateNews = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return { type: newsActionTypes.UPDATE_NEWS_REQUEST, details };
  };
  const success = response => {
    return {
      type: newsActionTypes.UPDATE_NEWS_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: newsActionTypes.UPDATE_NEWS_FAILURE,
      error,
      initialDetails
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "updateNews" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };
    api.updateNews(id, body).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error, initialDetails));
      }
    );
  };
};

const deleteNews = (id, headers) => {
  const request = () => {
    return { type: newsActionTypes.DELETE_NEWS_REQUEST };
  };
  const success = response => {
    return { type: newsActionTypes.DELETE_NEWS_SUCCESS, response };
  };
  const failure = error => {
    return { type: newsActionTypes.DELETE_NEWS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteNews" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteNews(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getNews = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly,
  programmeId
) => {
  const request = () => {
    return { type: newsActionTypes.GET_NEWS_REQUEST };
  };
  const success = response => {
    return { type: newsActionTypes.GET_NEWS_SUCCESS, response };
  };
  const failure = error => {
    return { type: newsActionTypes.GET_NEWS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getNews" });

  return dispatch => {
    dispatch(request());
    if (clear) {
      // If supposed to clear, replicate blank result
      dispatch(success({ hits: { hits: [] } }));
    } else {
      api
        .getNews(queryString, size, from, fields, sort, activeOnly, programmeId)
        .then(
          response => {
            const entityDetails = JSON.parse(
              localStorage.getItem("entityDetails")
            );

            if (
              entityDetails &&
              entityDetails.entity === strings.amendments.entity
            ) {
              response.data = amendmentHelpers.removeFromList(
                response.data,
                entityDetails
              );
            }

            dispatch(success(response));
          },
          error => dispatch(failure(error))
        );
    }
  };
};

const getNewsByProgramme = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly,
  programmeId
) => {
  const request = () => {
    return { type: newsActionTypes.GET_NEWS_BY_PROGRAMME_REQUEST };
  };
  const success = response => {
    return {
      type: newsActionTypes.GET_NEWS_BY_PROGRAMME_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: newsActionTypes.GET_NEWS_BY_PROGRAMME_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "getNewsByProgramme"
  });

  return dispatch => {
    dispatch(request());
    if (clear) {
      // If supposed to clear, replicate blank result
      dispatch(success({ hits: { hits: [] } }));
    } else {
      api
        .getNewsByProgramme(
          queryString,
          size,
          from,
          fields,
          sort,
          activeOnly,
          programmeId
        )
        .then(
          response => {
            dispatch(success(response));
          },
          error => dispatch(failure(error))
        );
    }
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly,
  programmeId
) => {
  const request = () => {
    return { type: newsActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: newsActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };
  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly,
    programmeId: programmeId
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(
      getNews(
        headers,
        queryString,
        size,
        from,
        clear,
        fields,
        sort,
        activeOnly,
        programmeId
      )
    );
  };
};

const getNewsById = (id, headers) => {
  const request = () => {
    return { type: newsActionTypes.GET_NEWS_BY_ID_REQUEST };
  };
  const success = response => {
    return {
      type: newsActionTypes.GET_NEWS_BY_ID_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: newsActionTypes.GET_NEWS_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getNewsById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getNewsById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedNews = (key, value) => {
  const request = (key, value) => {
    return {
      type: newsActionTypes.UPDATE_SELECTED_NEWS_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: newsActionTypes.UPDATE_SELECTED_NEWS_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: newsActionTypes.UPDATE_SELECTED_NEWS_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedNews = () => {
  const request = () => {
    return { type: newsActionTypes.CLEAR_SELECTED_NEWS_REQUEST };
  };
  const success = () => {
    return { type: newsActionTypes.CLEAR_SELECTED_NEWS_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedNewsUpdate = initialNewsData => {
  const request = () => {
    return {
      type: newsActionTypes.CANCEL_SELECTED_NEWS_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type: newsActionTypes.CANCEL_SELECTED_NEWS_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialNewsData));
  };
};

export const newsActions = {
  createNews,
  updateNews,
  deleteNews,
  getNewsById,
  getNews,
  getNewsByProgramme,
  updateSearchCriteria,
  updateSelectedNews,
  clearSelectedNews,
  clearNewsRequest,
  cancelSelectedNewsUpdate
};
