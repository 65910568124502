import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      entity: "ttgProgrammes",
      deletion: "deletion",
      fullName: "Ttg Programme"
    },
    header: {
      login: "Login",
      ttgProgrammeList: "Programmes",
      createTtgProgramme: "New Programme",
      editTtgProgramme: "Edit Programme",
      viewTtgProgramme: "Programme Details",
      loading: "Loading Information"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },

      feedback: {
        processing: "Processing request...",
        ttgProgrammeRequestSuccess: "Programme created successfully!",
        ttgProgrammeRequestRefreshPrompt:
          "Use the refresh function to see an updated Programmes list.",
        ttgProgrammeRequestFailure: "Programme creation failed.",
        status: "Status",
        success: "Success",
        failure: "Failure",
        ttgProgrammeRequestUnknown:
          "Something went wrong with the Programme request."
      },
      fileUpload: "Upload files"
    },
    modal: {
      deleteTtgProgrammeMessage:
        "Are you sure you want to delete this Programme? (This action cannot be undone)"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      shareLabel: "Manage Sharing",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      addTtgProgrammeLabel: "New Programme",
      createTtgProgramme: "Create a Programme",
      chooseForm: "View",
      resetLabel: "Go back to form selection",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit"
    },
    tooltip: {
      editTtgProgramme: "Edit Programme",
      deleteTtgProgramme: "Delete Programme",
      toggleStatus: "Click to change status",
      ttgProgrammeTypeWarning:
        "Programme Type cannot be changed after creation.",
      ninFormatHeader: "National Insurance Format",
      ninFormatContent:
        "Please enter the NIN in the same format as 'QQ123456C'. The following characters are not allowed. First Letter: DFIQUV. Second Letter: DFIQUVO. Final Letter must be: ABCD."
    },
    table: {
      header: {
        createdDate: "Created",
        crmId: "Programme ID",
        owner: "Owner",
        region: "Region",
        firstName: "First Name",
        surname: "Last Name",
        scroNumber: "S Number",
        prisonSpecialist: "Prison Specialist",
        mentor: "Specialist",
        communityMentor: "Community Mentor",
        pNumber: "Prison Number",
        journeyStatus: "Journey Status",
        overallEDL: "Overall EDL",
        firstCommunityMeetingAttended: "First Community Meeting",
        anticipatedEndDate: "Anticipated End Date"
      }
    },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    fieldValues: {
      yes: "Yes",
      no: "No",
      other: "Other",
      onTtgProgrammeCommunity: "On Programme - In Community",
      exit: "Exit"
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    orgTypes: {
      supportPartner: "Support Partner"
    },
    folder: {
      ttgProgrammeFiles: "ttgProgrammeFiles"
    },
    entity: {
      ttgProgramme: "ttgProgramme"
    }
  }
});
