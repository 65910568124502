import React from "react";
import ModifyReport from "../../components/ModifyReport";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  strings,
  reportSubTypeOptions,
  enums,
  textFields
} from "../../resources";
import { reportActions } from "../../store/actions/report.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  convertToIso,
  reportHelpers,
  getUsers,
  textFormattingHelpers
} from "../../helpers";
import "../../components/css/ReportComponents.scss";

class ReportEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    deleteModalOpen: false,
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      size: 1000,
      from: 0,
      query: {
        reportViewType: strings.text.templateReportType
      },
      sort: {
        name: "asc"
      }
    },
    generateReportCriteria: {
      size: 20,
      from: 0,
      query: "",
      sort: {}
    },
    tableHasRendered: false,
    selectedReport: {},
    uploadSize: "20",
    downloadLinkModalOpen: false
  };

  componentDidMount = async () => {
    await this.getUsers();
    if (
      this.props.area &&
      this.props.area !== strings.areas.ALL &&
      this.props.area !== strings.areas.NE
    ) {
      this.updateSearchCriteriaQuery({
        area: this.props.area
      });
    }
    await this.props.getReports(this.state.searchCriteria, this.props.headers);

    let form = document.getElementById("reportForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.reportReferrals &&
      this.props.reportReferrals &&
      this.props.reportReferrals.length > prevProps.reportReferrals.length
    ) {
      this.setState({
        tableHasRendered: true
      });
    }
  };

  async componentWillUnmount() {
    await this.props.clearReportRequest();
    await this.props.clearSelectedReport();
  }

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedReportData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.reports.modifyReport
    );

    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSave = async () => {
    let data = this.props.selectedReportData;
    data = {
      ...data,
      ...this.state.searchCriteria.query,
      measure: undefined,
      stage: undefined,
      reportViewType: undefined,
      result: undefined,
      reportTemplateId: this.state.searchCriteria.query.reportId
    };

    data = textFormattingHelpers.decodeData(data, {}, textFields);

    await this.props.saveReport(
      data,
      this.props.headers,
      this.props.reportReferrals
    );
    this.handleModeSwitch();
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleUpload = async () => {
    const {
      reportReferrals: body,
      savedReportId: reportId,
      uploadReferrals,
      headers,
      selectedReportData
    } = this.props;
    const { generateReportCriteria, uploadSize, searchCriteria } = this.state;
    const result = selectedReportData.result;

    if (reportId && body.length > 0) {
      await uploadReferrals(
        {
          reportId,
          body: reportHelpers.prepareForUpload(body),
          result,
          uploadSize: parseInt(uploadSize),
          pageSize: generateReportCriteria.size,
          from: generateReportCriteria.from,
          reportTemplateId: searchCriteria.query.reportId,
          query: {
            ...searchCriteria.query,
            measure: undefined,
            stage: undefined,
            reportId: undefined
          }
        },
        headers
      );
    }
  };

  prepareData = state => {
    let sCrit = this.state.searchCriteria;
    let sortObject = { created: { order: "desc" } };
    if (state && state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
      sCrit.sort = sortObject;
    }
    sCrit.from = state && state.page ? state.page * sCrit.size : 0;
    return sCrit;
  };

  toggleDownloadLinkModalVisibility = () => {
    this.setState({
      downloadLinkModalOpen: !this.state.downloadLinkModalOpen
    });
  };

  handleChange = async (event, data) => {
    if (data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)) {
      await this.props.updateSelectedReport(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedReport(data.name, data.value);
    }
  };

  handleStateChange = (event, data) => {
    this.setState({ [data.name]: data.value });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSave();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "uploadReferralsModal":
        this.handleUpload();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleConfirmDeleteModalVisibility = idx => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      categoryDeletionId: idx
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialReportData) !==
        JSON.stringify(this.props.selectedReportData)
      ) {
        await this.props.cancelSelectedReportUpdate(
          this.state.initialReportData
        );
      }
      this.setState({
        mode: "view",
        formInvalid: false,
        searchCriteria: {
          ...this.state.searchCriteria,
          query: {
            ...this.state.searchCriteria.query
          }
        }
      });
    } else {
      this.setState({
        mode: "edit",
        initialReportData: JSON.parse(
          JSON.stringify(this.props.selectedReportData)
        )
      });
    }
  };

  handleFetchData = async (state, downloadCSV) => {
    let {
      searchCriteria: sCrit,
      generateReportCriteria: grCrit,
      tableHasRendered,
      selectedReport
    } = this.state;
    const {
      headers,
      generateReport,
      getReportById,
      selectedReportData
    } = this.props;

    const query = sCrit.query;
    const isTemplate = query.reportViewType === strings.text.templateReportType;
    const isResult = query.reportViewType === strings.text.resultReportType;

    if (state && !tableHasRendered) {
      return;
    }

    if (state) {
      grCrit.from = state.page * grCrit.size;
      await this.setState({ generateReportCriteria: grCrit });
    }

    if (
      isResult &&
      selectedReport.hassavedreferrals &&
      selectedReportData.reportId
    ) {
      return this.handleSearchReferrals(state);
    }

    if (isTemplate || (isResult && state)) {
      let body = {
        ...sCrit.query,
        id: isTemplate
          ? sCrit.query.reportId
          : selectedReportData.reportTemplateId,
        reportId: undefined,
        measure: undefined,
        stage: undefined,
        criteria: {
          size: grCrit.size,
          from: grCrit.from
        }
      };

      if (isResult) {
        body = {
          ...body,
          ...selectedReportData,
          reportId: undefined,
          reportTemplateId: undefined,
          name: undefined,
          description: undefined,
          dateSaved: undefined
        };
      }
      if (downloadCSV) {
        await this.props.downloadReportsCSV(body, headers);
        this.setState({ downloadLinkModalOpen: true });
      } else {
        await generateReport(body, headers);
      }
    } else if (isResult) {
      await getReportById(sCrit.query.reportId, headers, grCrit);
    }
  };

  handleSearchReferrals = async state => {
    let { generateReportCriteria: sCrit } = this.state;
    const { searchReportReferrals, headers, selectedReportData } = this.props;

    let sortObject = {};
    if (state && state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }
    sCrit.query = { reportId: selectedReportData.reportId };

    await searchReportReferrals(sCrit, headers);
  };

  handleClearSearch = async () => {
    await this.setState(
      {
        searchCriteria: {
          ...this.state.searchCriteria,
          query: {
            reportViewType: this.state.searchCriteria.query.reportViewType,
            area: this.state.searchCriteria.query.area
          }
        },
        tableHasRendered: false
      },
      async () => {
        await this.props.clearSelectedReport();
        await this.props.clearReportRequest();
        await this.props.clearSavedReport();
      }
    );
  };

  updateSearchCriteriaQuery = async queryObject => {
    let { searchCriteria } = this.state;

    if (queryObject) {
      searchCriteria.query = {
        ...searchCriteria.query,
        ...queryObject
      };

      this.setState({ searchCriteria });
    }
  };

  handleReportOptionsChange = async (event, data) => {
    const isConversion =
      data.value === strings.text.etsReportType ||
      data.value === strings.text.stpReportType;

    if (data.name === "reportViewType") {
      await this.handleClearSearch();
      this.setState(
        {
          searchCriteria: {
            ...this.state.searchCriteria,
            query: {
              [data.name]: data.value,
              area: this.state.searchCriteria.query.area
            }
          },
          tableHasRendered: false
        },
        async () => {
          if (data.value) {
            await this.props.getReports(
              this.state.searchCriteria,
              this.props.headers
            );
          }
        }
      );
    } else if (data.name === "reportId" && data.options) {
      this.updateSearchCriteriaQuery({
        measure: undefined,
        stage: undefined
      });

      const report = reportHelpers.findReport(data.options, data.value);
      if (report) {
        if (report.measure) {
          this.updateSearchCriteriaQuery({
            measure: report.measure
          });
        }
        if (report.stage) {
          this.updateSearchCriteriaQuery({
            stage: report.stage
          });
        }
        this.setState({ selectedReport: report });
      }
      this.setState(
        {
          generateReportCriteria: {
            from: 0,
            size: 20,
            query: "",
            sort: {}
          }
        },
        async () => {
          if (this.props.savedReportId) {
            await this.props.clearSavedReport();
          }
        }
      );
    } else if (data.name === "type" && isConversion) {
      await this.updateSearchCriteriaQuery({
        reportId: reportHelpers.generateConversion(
          data.value,
          this.props.reportOptions,
          reportSubTypeOptions
        )
      });
    }

    let value = "";
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      value = convertToIso(data.value);
    } else {
      value = data.value;
    }
    if (!value) {
      value = undefined;
    }
    if (value && Array.isArray(value) && data.multiple) {
      value = value.toString();
    }

    if (data.checked !== undefined) {
      value = data.checked;
    }

    // await is required
    await this.updateSearchCriteriaQuery({
      [data.name]: value
    });
  };

  getUsers = async () => {
    const sCrit = {
      size: 1000,
      from: 0,
      query: enums.role.MENTOR,
      fields: ["roleId"],
      clear: false,
      sort: {},
      activeOnly: false
    };

    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      sCrit.from,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    await this.props.storeSpecialistOptions(this.props.userListData);
  };

  render = () => {
    const loading = this.props.loadingPage || this.props.usersLoadingPage;
    const reportIsSelected = this.state.searchCriteria.query.reportId;
    const showForm =
      reportIsSelected && this.props.reportRequestStatus !== undefined;
    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          <RequestFeedback
            requestStatus={this.props.reportRequestStatus}
            requestMade={this.props.reportRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={strings.form.feedback.reportRequestUnknown}
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <ModifyReport
            mode={this.state.mode}
            role={this.props.roleId}
            requestStatus={this.props.reportRequestStatus}
            history={this.props.history}
            headers={this.props.headers}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editReport
                : strings.header.viewReport
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            selectedReportData={this.props.selectedReportData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            deleteModalOpen={this.state.deleteModalOpen}
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            initialReportData={{}}
            formInvalid={this.state.formInvalid}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            showForm={showForm}
            searchCriteria={this.state.searchCriteria}
            reportOptions={this.props.reportOptions}
            handleFetchData={this.handleFetchData}
            handleReportOptionsChange={this.handleReportOptionsChange}
            areaIsAll={this.props.area === strings.areas.ALL}
            areaIsNE={this.props.area === strings.areas.NE}
            specialistOptions={getUsers(this.props.specialistOptions)}
            handleClearSearch={this.handleClearSearch}
            reportReferrals={this.props.reportReferrals}
            generateReportCriteria={this.state.generateReportCriteria}
            savedReportId={this.props.savedReportId}
            uploadSize={this.state.uploadSize}
            handleStateChange={this.handleStateChange}
            totalReportReferrals={this.props.totalReportReferrals}
            reportRun={this.props.reportRun}
            downloadLink={this.props.currentDownloadLink}
            loadingLink={this.props.loadingLink}
            toggleDownloadLinkModalVisibility={
              this.toggleDownloadLinkModalVisibility
            }
            downloadLinkModalOpen={this.state.downloadLinkModalOpen}
          />
        </div>
      </div>
    );
  };
}

ReportEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedReportData: PropTypes.object.isRequired,
  updateSelectedReport: PropTypes.func.isRequired,
  saveReport: PropTypes.func.isRequired,
  generateReport: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { reports, auth, users } = state;
  const {
    loadingPage,
    selectedReportData,
    reportRequestStatus,
    reportRequestMade,
    result,
    error,
    errorDetails,
    reportOptions,
    reportReferrals,
    savedReportId,
    totalReportReferrals,
    specialistOptions,
    reportRun,
    currentDownloadLink,
    loadingLink
  } = reports;
  const { roleId, organisationOfLoggedInUser, area } = auth;
  const { loadingPage: usersLoadingPage, userListData } = users;
  return {
    loadingPage,
    selectedReportData,
    reportRequestStatus,
    reportRequestMade,
    result,
    error,
    roleId,
    errorDetails,
    reportOptions,
    organisationOfLoggedInUser,
    area,
    usersLoadingPage,
    userListData,
    reportReferrals,
    savedReportId,
    totalReportReferrals,
    specialistOptions,
    reportRun,
    currentDownloadLink,
    loadingLink
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveReport: (data, headers, referrals) => {
      dispatch(reportActions.saveReport(data, headers, referrals));
    },
    generateReport: async (body, headers) => {
      await dispatch(reportActions.generateReport(body, headers));
    },
    updateSelectedReport: (key, value) => {
      dispatch(reportActions.updateSelectedReport(key, value));
    },
    cancelSelectedReportUpdate: data => {
      dispatch(reportActions.cancelSelectedReportUpdate(data));
    },
    clearReportRequest: () => {
      dispatch(reportActions.clearReportRequest());
    },
    clearSelectedReport: () => {
      dispatch(reportActions.clearSelectedReport());
    },
    getReports: (searchCriteria, headers) => {
      dispatch(reportActions.getReports(searchCriteria, headers));
    },
    getReportById: (id, headers, searchCriteria) => {
      dispatch(reportActions.getReportById(id, headers, searchCriteria));
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    searchReportReferrals: (searchCriteria, headers) => {
      dispatch(reportActions.searchReportReferrals(searchCriteria, headers));
    },
    uploadReferrals: (data, headers) => {
      dispatch(reportActions.uploadReferrals(data, headers));
    },
    clearSavedReport: () => {
      dispatch(reportActions.clearSavedReport());
    },
    storeSpecialistOptions: async users => {
      await dispatch(reportActions.storeSpecialistOptions(users));
    },
    downloadReportsCSV: (searchCriteria, headers) => {
      dispatch(reportActions.downloadReportsCSV(searchCriteria, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportEditContainer);
