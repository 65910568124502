import axios from "./axios-history";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  getHistory = (queryString, size, from, fields, sort) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      sort: sort,
      fields: fields,
      query_string: queryString
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/list-logs/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting history: ", error.message);
          reject(error);
        });
    });
  };
}
