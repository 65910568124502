import { API } from "../../services";
import { taskActionTypes } from "./task.actionTypes";
import { strings } from "../../resources";

const api = new API();

const removeEmptyFields = details => {
  for (let property in details) {
    if (!details[property]) {
      delete details[property];
    } else if (typeof details[property] === "object") {
      removeEmptyFields(details[property]);
    }
  }
};

const createTask = (
  details,
  headers,
  createTaskMessage = "",
  showNotification = true
) => {
  removeEmptyFields(details);
  const request = () => {
    return { type: taskActionTypes.CREATE_TASK_REQUEST };
  };
  const success = response => {
    return {
      type: taskActionTypes.CREATE_TASK_SUCCESS,
      response,
      showNotification,
      createTaskMessage
    };
  };
  const failure = error => {
    return {
      type: taskActionTypes.CREATE_TASK_FAILURE,
      error,
      showNotification
    };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "createTask" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.createTask(details).then(
        response => {
          dispatch(success(response));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const clearTaskRequest = () => {
  const request = () => {
    return { type: taskActionTypes.CLEAR_CREATE_TASK_REQUEST };
  };

  const success = () => {
    return { type: taskActionTypes.CLEAR_CREATE_TASK_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateTask = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return {
      type: taskActionTypes.UPDATE_TASK_REQUEST,
      details
    };
  };
  const success = response => {
    return {
      type: taskActionTypes.UPDATE_TASK_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = (error, initialDetails) => {
    return {
      type: taskActionTypes.UPDATE_TASK_FAILURE,
      error,
      initialDetails,
      showNotification
    };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "updateTask" });

  return dispatch => {
    dispatch(request(details));
    api.updateTask(id, details).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const deleteTask = (id, clearReason, headers, showNotification = true) => {
  const request = () => {
    return { type: taskActionTypes.DELETE_TASK_REQUEST };
  };
  const success = response => {
    return {
      type: taskActionTypes.DELETE_TASK_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: taskActionTypes.DELETE_TASK_FAILURE,
      error,
      showNotification
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteTask" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id) {
        api.deleteTask(id, clearReason).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getTasks = (
  headers,
  queryString,
  size,
  from,
  clear,
  sort = {},
  activeOnly,
  fields,
  startDate,
  endDate
) => {
  const request = () => {
    return { type: taskActionTypes.GET_TASKS_REQUEST };
  };
  const success = response => {
    return {
      type: taskActionTypes.GET_TASKS_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: taskActionTypes.GET_TASKS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getTasks" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (clear) {
        // If supposed to clear, replicate blank result
        dispatch(success({ hits: { hits: [] } }));
        resolve();
      } else {
        api
          .getTasks(
            queryString,
            size,
            from,
            sort,
            activeOnly,
            fields,
            startDate,
            endDate
          )
          .then(
            response => {
              dispatch(success(response));
              resolve(response);
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          );
      }
    });
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly,
  startDate,
  endDate
) => {
  const request = () => {
    return { type: taskActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: taskActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };

  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    sort: sort,
    activeOnly: activeOnly,
    fields: fields
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(
      getTasks(
        headers,
        queryString,
        size,
        from,
        clear,
        sort,
        activeOnly ? strings.fieldValues.yes : strings.fieldValues.no,
        fields,
        startDate,
        endDate
      )
    );
  };
};

const getTaskById = (id, headers, showNotification = true) => {
  const request = () => {
    return { type: taskActionTypes.GET_TASK_BY_ID_REQUEST };
  };
  const success = response => {
    return {
      type: taskActionTypes.GET_TASK_BY_ID_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: taskActionTypes.GET_TASK_BY_ID_FAILURE,
      error,
      showNotification
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getTaskById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getTaskById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedTask = (key, value) => {
  const request = (key, value) => {
    return {
      type: taskActionTypes.UPDATE_SELECTED_TASK_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: taskActionTypes.UPDATE_SELECTED_TASK_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return {
      type: taskActionTypes.UPDATE_SELECTED_TASK_FAILURE
    };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedTask = () => {
  const request = () => {
    return {
      type: taskActionTypes.CLEAR_SELECTED_TASK_REQUEST
    };
  };
  const success = () => {
    return {
      type: taskActionTypes.CLEAR_SELECTED_TASK_SUCCESS
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedTaskUpdate = initialTaskData => {
  const request = () => {
    return {
      type: taskActionTypes.CANCEL_SELECTED_TASK_UPDATE_REQUEST
    };
  };

  const success = data => {
    return {
      type: taskActionTypes.CANCEL_SELECTED_TASK_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialTaskData));
  };
};

const setTaskDataSelected = data => {
  return { type: taskActionTypes.SET_TASK_DATA_SELECTED, data };
};

const clearCreateTask = () => {
  return { type: taskActionTypes.CLEAR_CREATE_TASK };
};

export const taskActions = {
  createTask,
  updateTask,
  deleteTask,
  getTaskById,
  getTasks,
  updateSearchCriteria,
  updateSelectedTask,
  clearSelectedTask,
  clearTaskRequest,
  cancelSelectedTaskUpdate,
  setTaskDataSelected,
  clearCreateTask
};
