import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import {
  strings,
  feedbackStageOptions,
  yesNoOptions,
  yesNoNAOptions,
  additionalQ2Options,
  additionalQ3Options,
  additionalQ4Options,
  additionalQ6Options
} from "../resources";
import {
  ValidatedFormDropdown,
  ValidatedFormInput,
  ValidatedFormTextArea
} from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from ".";
import "./css/FeedbackComponents.scss";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { dropdownHelper } from "../helpers";
import { AuditListContainer } from "../../Audit/containers/";

class ModifyFeedback extends React.Component {
  modalUnlockTextInput = (
    <Input
      placeholder={strings.form.placeholder.stateReason}
      fluid
      onChange={this.props.handleUnlockModalInputChange}
      type="password"
    ></Input>
  );
  returnToParent = () => {
    this.props.history.push(
      "/programmes/view/" + localStorage.getItem("programmeId")
    );
  };
  returnToList = () => {
    this.props.history.push("/feedback");
  };
  renderModifyButtons = () => {
    const { isDisabled } = this.props;

    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      ></Input>
    );
    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful &&
          (!isDisabled ? (
            <>
              <Button.Group>
                <Button
                  className="editButton"
                  primary
                  onClick={() => {
                    this.props.handleModeSwitch("edit");
                  }}
                  disabled={this.props.showAuditLog}
                >
                  {strings.button.editLabel}
                </Button>
              </Button.Group>
            </>
          ) : (
            <span className="errorText">
              {strings.form.errorText.notShared}
            </span>
          ))}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={this.props.toggleEnableDisableFeedbackModalVisibility}
                type="button"
                disabled={this.props.selectedFeedbackData.requestId}
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableFeedbackModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={
                  this.props.toggleEnableDisableFeedbackModalVisibility
                }
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderDeleteButton = () => {
    const { isDisabled } = this.props;
    return (
      <div className="containerButtons">
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={this.props.toggleEnableDisableFeedbackModalVisibility}
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteFeedbackMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableFeedbackModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={
                  this.props.toggleEnableDisableFeedbackModalVisibility
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderAuditButton = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="auditButton"
            onClick={() => {
              this.props.handleToggleAudit();
            }}
          >
            {!this.props.showAuditLog
              ? strings.button.auditLabel
              : strings.button.formLabel}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderCancelSaveButtons = () => {
    const {
      selectedFeedbackData,
      handleModeSwitch,
      handleModalConfirm,
      toggleCancelChangesModalVisibility,
      toggleConfirmSaveModalVisibility,
      initialFeedbackData,
      validateForm,
      confirmSaveModalOpen,
      mode,
      cancelChangesModalOpen,
      isDisabled
    } = this.props;

    return (
      <div className="containerButtons">
        <Button.Group>
          {mode === "create" && (
            <>
              {localStorage.getItem("feedbackLocked") ? (
                <>
                  <Button
                    className="shareToggle"
                    onClick={() => {
                      this.props.toggleEnableDisableUnlockModalVisibility();
                    }}
                  >
                    {strings.button.unlockScreen}
                  </Button>
                  <TextInputModal
                    confirmMessage={strings.modal.unlockScreen}
                    handleModalConfirm={this.props.toggleLock}
                    modalClassName="handleToggleUnlock"
                    modalOpen={this.props.enableDisableUnlockModalOpen}
                    showOkButton={true}
                    textInput={this.modalUnlockTextInput}
                    toggleModal={
                      this.props.toggleEnableDisableUnlockModalVisibility
                    }
                    okLabel={strings.button.submitLabel}
                    deletionReason={this.props.password}
                  />
                </>
              ) : (
                <>
                  <Button
                    className="shareToggle"
                    onClick={() => {
                      this.props.toggleEnableDisableLockModalVisibility();
                    }}
                  >
                    {strings.button.lockScreen}
                  </Button>
                  <BasicModal
                    confirmMessage={strings.modal.lockScreen}
                    handleModalConfirm={this.props.toggleLock}
                    modalClassName="handleToggleLock"
                    modalOpen={this.props.enableDisableLockModalOpen}
                    showOkButton={false}
                    showYesButton={true}
                    showNoButton={true}
                    toggleModal={
                      this.props.toggleEnableDisableLockModalVisibility
                    }
                    noLabel={strings.button.noLabel}
                    yesLabel={strings.button.yesLabel}
                    okLabel={strings.button.okLabel}
                  />
                </>
              )}
            </>
          )}
          <Button
            className="saveButton"
            primary
            inverted
            onClick={validateForm}
            disabled={
              JSON.stringify(selectedFeedbackData) ===
                JSON.stringify(initialFeedbackData) || isDisabled
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
            disabled={isDisabled}
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };

  renderForm = () => {
    const {
      selectedFeedbackData,
      handleChange,
      mode,
      formInvalid,
      validationResults,
      getOrganisations
    } = this.props;

    return (
      <>
        <div className="infoBlock">
          <div className="infoHeader">{strings.form.header.administration}</div>
          <div className="infoWrapper">
            <Form.Group>
              {mode !== "create" && (
                <ValidatedFormInput
                  formInvalid={formInvalid}
                  inputData={selectedFeedbackData.crmId}
                  inputLabel={strings.form.label.crmId}
                  inputName="crmId"
                  inputPlaceholder={strings.form.placeholder.crmId}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.crmId}
                  disabled={mode === "edit"}
                />
              )}
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedFeedbackData.owner}
                viewData={
                  dropdownHelper.getNameFromValues(
                    selectedFeedbackData.owner,
                    getOrganisations()
                  ) || ""
                }
                dropdownLabel={strings.form.label.owner}
                dropdownName="owner"
                dropdownDisabled={true}
                dropdownOptions={getOrganisations() || []}
                dropdownPlaceholder={strings.form.placeholder.owner}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.owner}
                mode={mode}
              />
            </Form.Group>
          </div>
        </div>
        <div className="infoBlock">
          <div className="infoHeader">{strings.form.header.feedback}</div>
          <div className="infoWrapper">
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedFeedbackData.feedbackStage}
                viewData={
                  dropdownHelper.getNameFromValues(
                    selectedFeedbackData.feedbackStage,
                    feedbackStageOptions
                  ) || ""
                }
                dropdownLabel={strings.form.label.feedbackStage}
                dropdownName="feedbackStage"
                dropdownOptions={feedbackStageOptions}
                dropdownPlaceholder={strings.form.placeholder.feedbackStage}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.feedbackStage}
                mode={mode}
              />
            </Form.Group>
          </div>
        </div>
        {this.renderFields()}
      </>
    );
  };

  renderFields = () => {
    let returnFields;
    switch (this.props.selectedFeedbackData.feedbackStage) {
      case strings.fieldValues.stage1:
        returnFields = this.stage1Fields();
        break;
      case strings.fieldValues.stage2:
        returnFields = this.stage2Fields();
        break;
      case strings.fieldValues.stage3:
        returnFields = this.stage3Fields();
        break;
      case strings.fieldValues.additional:
        returnFields = this.additionalFields();
        break;
      default:
        returnFields = undefined;
    }
    return returnFields;
  };

  stage1Fields = () => {
    const {
      selectedFeedbackData,
      handleChange,
      mode,
      formInvalid,
      validationResults
    } = this.props;
    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.stage1}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage1Q1}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage1Q1,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage1Q1}
              dropdownName="stage1Q1"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage1Q1}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage1Q1}
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage1Q2}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage1Q2,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage1Q2}
              dropdownName="stage1Q2"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage1Q2}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage1Q2}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage1Q3}
              inputLabel={strings.form.label.stage1Q3}
              inputName="stage1Q3"
              inputPlaceholder={strings.form.placeholder.stage1Q3}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage1Q3s}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage1Q4}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage1Q4,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage1Q4}
              dropdownName="stage1Q4"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage1Q4}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage1Q4}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage1Q5}
              inputLabel={strings.form.label.stage1Q5}
              inputName="stage1Q5"
              inputPlaceholder={strings.form.placeholder.stage1Q5}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage1Q5}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage1Q6}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage1Q6,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage1Q6}
              dropdownName="stage1Q6"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage1Q6}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage1Q6}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage1Q7}
              inputLabel={strings.form.label.stage1Q7}
              inputName="stage1Q7"
              inputPlaceholder={strings.form.placeholder.stage1Q7}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage1Q7}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage1Q8}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage1Q8,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage1Q8}
              dropdownName="stage1Q8"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage1Q8}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage1Q8}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage1Q9}
              inputLabel={strings.form.label.stage1Q9}
              inputName="stage1Q9"
              inputPlaceholder={strings.form.placeholder.stage1Q9}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage1Q9}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage1Q10}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage1Q10,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage1Q10}
              dropdownName="stage1Q10"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage1Q10}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage1Q10}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage1Q11}
              inputLabel={strings.form.label.stage1Q11}
              inputName="stage1Q11"
              inputPlaceholder={strings.form.placeholder.stage1Q11}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage1Q11}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
        </div>
      </div>
    );
  };

  stage2Fields = () => {
    const {
      selectedFeedbackData,
      handleChange,
      mode,
      formInvalid,
      validationResults
    } = this.props;
    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.stage2}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage2Q1}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage2Q1,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage2Q1}
              dropdownName="stage2Q1"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage2Q1}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage2Q1}
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage2Q2}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage2Q2,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage2Q2}
              dropdownName="stage2Q2"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage2Q2}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage2Q2}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage2Q3}
              inputLabel={strings.form.label.stage2Q3}
              inputName="stage2Q3"
              inputPlaceholder={strings.form.placeholder.stage2Q3}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage2Q3}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage2Q4}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage2Q4,
                  yesNoNAOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage2Q4}
              dropdownName="stage2Q4"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage2Q4}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage2Q4}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage2Q5}
              inputLabel={strings.form.label.stage2Q5}
              inputName="stage2Q5"
              inputPlaceholder={strings.form.placeholder.stage2Q5}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage2Q5}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage2Q6}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage2Q6,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage2Q6}
              dropdownName="stage2Q6"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage2Q6}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage2Q6}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage2Q7}
              inputLabel={strings.form.label.stage2Q7}
              inputName="stage2Q7"
              inputPlaceholder={strings.form.placeholder.stage2Q7}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage2Q7}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage2Q8}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage2Q8,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage2Q8}
              dropdownName="stage2Q8"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage2Q8}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage2Q8}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage2Q9}
              inputLabel={strings.form.label.stage2Q9}
              inputName="stage2Q9"
              inputPlaceholder={strings.form.placeholder.stage2Q9}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage2Q9}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage2Q10}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage2Q10,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage2Q10}
              dropdownName="stage2Q10"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage2Q10}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage2Q10}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage2Q11}
              inputLabel={strings.form.label.stage2Q11}
              inputName="stage2Q11"
              inputPlaceholder={strings.form.placeholder.stage2Q11}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage2Q11}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage2Q12}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage2Q12,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage2Q12}
              dropdownName="stage2Q12"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage2Q12}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage2Q12}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage2Q13}
              inputLabel={strings.form.label.stage2Q13}
              inputName="stage2Q13"
              inputPlaceholder={strings.form.placeholder.stage2Q13}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage2Q13}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage2Q14}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage2Q14,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage2Q14}
              dropdownName="stage2Q14"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage2Q14}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage2Q14}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage2Q15}
              inputLabel={strings.form.label.stage2Q15}
              inputName="stage2Q15"
              inputPlaceholder={strings.form.placeholder.stage2Q15}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage2Q15}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
        </div>
      </div>
    );
  };

  stage3Fields = () => {
    const {
      selectedFeedbackData,
      handleChange,
      mode,
      formInvalid,
      validationResults
    } = this.props;
    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.stage3}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stageQ1}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage3Q1,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage3Q1}
              dropdownName="stage3Q1"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage3Q1}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage3Q1}
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage3Q2}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage3Q2,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage3Q2}
              dropdownName="stage3Q2"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage3Q2}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage3Q2}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage3Q3}
              inputLabel={strings.form.label.stage3Q3}
              inputName="stage3Q3"
              inputPlaceholder={strings.form.placeholder.stage3Q3}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage3Q3}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage3Q4}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage3Q4,
                  yesNoNAOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage3Q4}
              dropdownName="stage3Q4"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage3Q4}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage3Q4}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage3Q5}
              inputLabel={strings.form.label.stage3Q5}
              inputName="stage3Q5"
              inputPlaceholder={strings.form.placeholder.stage3Q5}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage3Q5}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage3Q6}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage3Q6,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage3Q6}
              dropdownName="stage3Q6"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage3Q6}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage3Q6}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage3Q7}
              inputLabel={strings.form.label.stage3Q7}
              inputName="stage3Q7"
              inputPlaceholder={strings.form.placeholder.stage3Q7}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage3Q7}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage3Q8}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage3Q8,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage3Q8}
              dropdownName="stage3Q8"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage3Q8}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage3Q8}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage3Q9}
              inputLabel={strings.form.label.stage3Q9}
              inputName="stage3Q9"
              inputPlaceholder={strings.form.placeholder.stage3Q9}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage3Q9}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage3Q10}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage3Q10,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage3Q10}
              dropdownName="stage3Q10"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage3Q10}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage3Q10}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage3Q11}
              inputLabel={strings.form.label.stage3Q11}
              inputName="stage3Q11"
              inputPlaceholder={strings.form.placeholder.stage3Q11}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage3Q11}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage3Q12}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage3Q12,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage3Q12}
              dropdownName="stage3Q12"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage3Q12}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage3Q12}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage3Q13}
              inputLabel={strings.form.label.stage3Q13}
              inputName="stage3Q13"
              inputPlaceholder={strings.form.placeholder.stage3Q13}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage3Q13}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.stage3Q14}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.stage3Q14,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.stage3Q14}
              dropdownName="stage3Q14"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.stage3Q14}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.stage3Q14}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.stage3Q15}
              inputLabel={strings.form.label.stage3Q15}
              inputName="stage3Q15"
              inputPlaceholder={strings.form.placeholder.stage3Q15}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.stage3Q15}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
        </div>
      </div>
    );
  };

  additionalFields = () => {
    const {
      selectedFeedbackData,
      handleChange,
      mode,
      formInvalid,
      validationResults
    } = this.props;
    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.additional}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.additionalQ1}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.additionalQ1,
                  yesNoOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.additionalQ1}
              dropdownName="additionalQ1"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.additionalQ1}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.additionalQ1}
              mode={mode}
            />
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.additionalQ2}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.additionalQ2,
                  additionalQ2Options
                ) || ""
              }
              dropdownLabel={strings.form.label.additionalQ2}
              dropdownName="additionalQ2"
              dropdownOptions={additionalQ2Options}
              dropdownPlaceholder={strings.form.placeholder.additionalQ2}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.additionalQ2}
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.additionalQ3}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.additionalQ3,
                  additionalQ3Options
                ) || ""
              }
              dropdownLabel={strings.form.label.additionalQ3}
              dropdownName="additionalQ3"
              dropdownOptions={additionalQ3Options}
              dropdownPlaceholder={strings.form.placeholder.additionalQ3}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.additionalQ3}
              mode={mode}
            />
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.additionalQ4}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.additionalQ4,
                  additionalQ4Options
                ) || ""
              }
              dropdownLabel={strings.form.label.additionalQ4}
              dropdownName="additionalQ4"
              dropdownOptions={additionalQ4Options}
              dropdownPlaceholder={strings.form.placeholder.additionalQ4}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.additionalQ4}
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedFeedbackData.additionalQ5}
              inputLabel={strings.form.label.additionalQ5}
              inputName="additionalQ5"
              inputPlaceholder={strings.form.placeholder.additionalQ5}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.additionalQ5}
              mode={this.props.mode}
            />
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedFeedbackData.additionalQ6}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedFeedbackData.additionalQ6,
                  additionalQ6Options
                ) || ""
              }
              dropdownLabel={strings.form.label.additionalQ6}
              dropdownName="additionalQ6"
              dropdownOptions={additionalQ6Options}
              dropdownPlaceholder={strings.form.placeholder.additionalQ6}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.additionalQ6}
              mode={mode}
            />
          </Form.Group>
        </div>
      </div>
    );
  };

  render = () => {
    const { mode, formInvalid, pageTitle, requestStatus } = this.props;

    let parentPage = localStorage.getItem("programmeName")
      ? localStorage.getItem("programmeName")
      : undefined;

    return (
      <div className="container">
        <ComponentHeader
          parentPage={parentPage}
          returnToParent={this.returnToParent}
          returnToList={this.returnToList}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderModifyButtons()}
          cancelSaveButtons={this.renderCancelSaveButtons()}
          deleteButton={this.renderDeleteButton()}
          auditLog={this.renderAuditButton()}
          headerList={strings.header.feedbackList}
          permissionsUpdate={permissions.feedback.update}
          permissionsCreate={permissions.feedback.create}
          permissionsDelete={permissions.feedback.delete}
          formInvalid={formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />
        {this.props.showAuditLog ? (
          <AuditListContainer
            embedded={true}
            headers={this.props.headers}
            history={this.props.history}
            entityId={this.props.selectedFeedbackData.feedbackId}
            hideSearchBar={true}
            record={true}
          />
        ) : (
          <Form id="feedbackForm" className="stickyForm">
            <fieldset disabled={mode === "view"}>{this.renderForm()}</fieldset>
          </Form>
        )}
      </div>
    );
  };
}

ModifyFeedback.propTypes = {
  mode: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  selectedFeedbackData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModeSwitch: PropTypes.func,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  initialFeedbackData: PropTypes.object.isRequired,
  getOrganisations: PropTypes.func.isRequired,
  updateSelectedFeedback: PropTypes.func.isRequired,
  deletePermissionStatus: PropTypes.string,
  handleModalInputChange: PropTypes.func,
  deletionReason: PropTypes.object,
  isDisabled: PropTypes.bool.isRequired
};

export default withRouter(ModifyFeedback);
