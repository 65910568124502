import { feedbackActionTypes } from "../actions/feedback.actionTypes";

const initialState = {
  loadingPage: false,
  selectedFeedbackData: {},
  feedbackListData: [{}],
  totalResults: 0,
  feedbackToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  feedbackRequestStatus: undefined,
  feedbackRequestMade: false,
  isFullyLoaded: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let details;
  let newTotal;
  switch (action.type) {
    case feedbackActionTypes.CREATE_FEEDBACK_REQUEST:
      return {
        ...state,
        feedbackRequestStatus: undefined,
        feedbackRequestMade: true
      };
    case feedbackActionTypes.CREATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        feedbackRequestStatus: true
      };
    case feedbackActionTypes.CREATE_FEEDBACK_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        feedbackRequestStatus: false
      };

    case feedbackActionTypes.CLEAR_CREATE_FEEDBACK_REQUEST:
      return { ...state };
    case feedbackActionTypes.CLEAR_CREATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackRequestMade: false,
        feedbackRequestStatus: undefined
      };
    case feedbackActionTypes.CLEAR_CREATE_FEEDBACK_FAILURE:
      return { ...state };

    case feedbackActionTypes.UPDATE_FEEDBACK_REQUEST:
      return {
        ...state,
        loadingPage: true,
        feedbackRequestStatus: undefined,
        feedbackRequestMade: true
      };
    case feedbackActionTypes.UPDATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        feedbackRequestStatus: true,
        selectedFeedbackData: action.response.data.Body,
        showNotification: action.showNotification
      };
    case feedbackActionTypes.UPDATE_FEEDBACK_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        feedbackRequestStatus: false,
        selectedFeedbackData: action.initialDetails,
        errorDetails: details
      };

    case feedbackActionTypes.DELETE_FEEDBACK_REQUEST:
      return {
        ...state,
        feedbackRequestStatus: undefined,
        feedbackRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case feedbackActionTypes.DELETE_FEEDBACK_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        feedbackRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case feedbackActionTypes.DELETE_FEEDBACK_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        feedbackRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case feedbackActionTypes.GET_FEEDBACK_REQUEST:
      return {
        ...state,
        loadingPage: true,
        deleteSuccessful: false,
        isFullyLoaded: false
      };
    case feedbackActionTypes.GET_FEEDBACK_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Body.hits.total) {
        newTotal = action.response.data.Body.hits.total;
        rawHits = action.response.data.Body.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newFeedback = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newFeedback;
        });
      } else {
        rawHits = [{}];
      }
      return {
        ...state,
        feedbackListData: rawHits,
        totalResults: newTotal,
        loadingPage: false,
        isFullyLoaded: true
      };
    case feedbackActionTypes.GET_FEEDBACK_FAILURE:
      return {
        ...state,
        feedbackListData: [{}],
        loadingPage: false,
        isFullyLoaded: true
      };

    case feedbackActionTypes.GET_FEEDBACK_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        feedbackRequestStatus: undefined
      };
    case feedbackActionTypes.GET_FEEDBACK_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        feedbackRequestStatus: true,
        selectedFeedbackData: action.response.Body,
        loadingPage: false
      };
    case feedbackActionTypes.GET_FEEDBACK_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedFeedbackData: {},
        feedbackRequestStatus: false,
        feedbackRequestMade: true
      };

    case feedbackActionTypes.UPDATE_SELECTED_FEEDBACK_REQUEST:
      return { ...state };
    case feedbackActionTypes.UPDATE_SELECTED_FEEDBACK_SUCCESS:
      return {
        ...state,
        selectedFeedbackData: {
          ...state.selectedFeedbackData,
          [action.key]: action.value
        }
      };
    case feedbackActionTypes.UPDATE_SELECTED_FEEDBACK_FAILURE:
      return { ...state };

    case feedbackActionTypes.CLEAR_SELECTED_FEEDBACK_REQUEST:
      return { ...state };
    case feedbackActionTypes.CLEAR_SELECTED_FEEDBACK_SUCCESS:
      return { ...state, selectedFeedbackData: {}, isFullyLoaded: false };
    case feedbackActionTypes.CLEAR_SELECTED_FEEDBACK_FAILURE:
      return { ...state };

    case feedbackActionTypes.CANCEL_SELECTED_FEEDBACK_UPDATE_REQUEST:
      return { ...state };
    case feedbackActionTypes.CANCEL_SELECTED_FEEDBACK_UPDATE_SUCCESS:
      return { ...state, selectedFeedbackData: action.data };
    case feedbackActionTypes.CANCEL_SELECTED_FEEDBACK_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};
