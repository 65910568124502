import { API as DummyAPI } from "../../../DummyRecords/services";
import { API as TtgProgrammeAPI } from "../../../TtgProgrammes/services";
import {
  dummyRecordActionTypes,
  ttgRecordActionTypes,
  ttgProgrammeActionTypes
} from "./ttgRecord.actionTypes";
import { strings } from "../../resources/Strings";

const dummyApi = new DummyAPI();
const ttgProgrammeApi = new TtgProgrammeAPI();

const createDummyRecord = (details, headers) => {
  const request = details => {
    return {
      type: dummyRecordActionTypes.CREATE_DUMMY_RECORD_REQUEST,
      details
    };
  };
  const success = response => {
    return {
      type: dummyRecordActionTypes.CREATE_DUMMY_RECORD_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: dummyRecordActionTypes.CREATE_DUMMY_RECORD_FAILURE, error };
  };

  dummyApi.setHeaders({
    ...headers,
    systemHeader_subsysref: "createDummyRecord"
  });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };
    return new Promise((resolve, reject) => {
      dummyApi.createDummyRecord(body).then(
        response => {
          dispatch(success(response));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const createTtgProgramme = (details, headers) => {
  const request = details => {
    return {
      type: ttgProgrammeActionTypes.CREATE_TTG_PROGRAMME_REQUEST,
      details
    };
  };
  const success = response => {
    return {
      type: ttgProgrammeActionTypes.CREATE_TTG_PROGRAMME_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: ttgProgrammeActionTypes.CREATE_TTG_PROGRAMME_FAILURE,
      error
    };
  };

  ttgProgrammeApi.setHeaders({
    ...headers,
    systemHeader_subsysref: "createTtgProgramme"
  });

  return dispatch => {
    dispatch(request(details));

    ttgProgrammeApi.createTtgProgramme(details).then(
      response => dispatch(success(response)),
      error => dispatch(failure(error))
    );
  };
};

const createTtgRecord = (details, headers) => {
  if (details.markerRecord === strings.form.text.no) {
    return createTtgProgramme(details, headers);
  } else {
    return createDummyRecord(details, headers);
  }
};

const updateSelectedTtgRecord = (key, value) => {
  const request = (key, value) => {
    return {
      type: ttgRecordActionTypes.UPDATE_SELECTED_TTG_RECORD_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: ttgRecordActionTypes.UPDATE_SELECTED_TTG_RECORD_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return {
      type: ttgRecordActionTypes.UPDATE_SELECTED_TTG_RECORD_FAILURE
    };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearTtgRecordRequest = details => {
  if (details.markerRecord === strings.form.text.no) {
    return clearTtgProgrammeRequest();
  } else {
    return clearDummyRecordRequest();
  }
};

const clearDummyRecordRequest = () => {
  const request = () => {
    return { type: dummyRecordActionTypes.CLEAR_CREATE_DUMMY_RECORD_REQUEST };
  };

  const success = () => {
    return { type: dummyRecordActionTypes.CLEAR_CREATE_DUMMY_RECORD_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const clearTtgProgrammeRequest = () => {
  const request = () => {
    return {
      type: ttgProgrammeActionTypes.CLEAR_CREATE_TTG_PROGRAMME_REQUEST
    };
  };

  const success = () => {
    return {
      type: ttgProgrammeActionTypes.CLEAR_CREATE_TTG_PROGRAMME_SUCCESS
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const clearSelectedTtgRecord = () => {
  const request = () => {
    return { type: dummyRecordActionTypes.CLEAR_SELECTED_TTG_RECORD_REQUEST };
  };
  const success = () => {
    return { type: dummyRecordActionTypes.CLEAR_SELECTED_TTG_RECORD_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const deleteSelectedTtgRecordProperty = key => {
  const request = () => {
    return {
      type: ttgRecordActionTypes.DELETE_SELECTED_TTG_RECORD_PROPERTY_REQUEST,
      key
    };
  };
  const success = () => {
    return {
      type: ttgRecordActionTypes.DELETE_SELECTED_TTG_RECORD_PROPERTY_SUCCESS,
      key
    };
  };
  const failure = () => {
    return {
      type: ttgRecordActionTypes.DELETE_SELECTED_TTG_RECORD_PROPERTY_FAILURE,
      key
    };
  };

  return dispatch => {
    dispatch(request(key));
    if (key) {
      dispatch(success());
    } else {
      dispatch(failure());
    }
  };
};

export const ttgRecordActions = {
  createTtgRecord,
  clearTtgRecordRequest,
  clearSelectedTtgRecord,
  updateSelectedTtgRecord,
  deleteSelectedTtgRecordProperty
};
