import React from "react";
import PropTypes from "prop-types";
import { Image, Menu, Sidebar } from "semantic-ui-react";
import { ShowIfAuthorised, ShowIfIncludedArea } from "../../";
import twgLogo from "../../../assets/images/logo.png";
import twgSmallLogo from "../../../assets/images/logo_small.png";
import "./css/Sidebar.scss";
import { generateMenuBar } from "../../../resources";
import CollapsableMenuItem from "./CollapsableMenuItem";

const AppSidebar = props => {
  const menubar = generateMenuBar(props);
  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      className="appSidebar"
      inverted
      vertical
      visible
      style={{ width: props.sidebarOpened ? "18rem" : "6rem" }}
    >
      <div className="logoImages">
        <Image
          className="twgLogo"
          src={twgLogo}
          hidden={!props.sidebarOpened}
        />
        <Image
          className="twgSmallLogo"
          src={twgSmallLogo}
          hidden={props.sidebarOpened}
        />
      </div>

      <div className="menuItems">
        {menubar.map((items, key) => (
          <ShowIfIncludedArea
            key={key}
            area={props.area}
            allowedAreas={items.allowedAreas}
          >
            <ShowIfAuthorised key={`${key}`} allowedRoles={items.menuItemRole}>
              <CollapsableMenuItem
                menuItemUrl={items.menuItemUrl}
                activeItem={props.activeItem}
                activeSubItem={props.activeSubItem}
                isSubMenuOpen={props.isSubMenuOpen}
                handleItemClick={props.handleItemClick}
                handleSubItemClick={props.handleSubItemClick}
                sidebarOpened={props.sidebarOpened}
                menuItemName={items.menuItemName}
                iconName={items.iconName}
                subItems={
                  items.menuItemUrl === "mywork"
                    ? props.taskSidebarItems
                    : items.subItems
                }
                area={props.area}
              />
            </ShowIfAuthorised>
          </ShowIfIncludedArea>
        ))}
      </div>
    </Sidebar>
  );
};

AppSidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  toggleSidebar: PropTypes.func.isRequired,
  role: PropTypes.string
};

export default AppSidebar;
