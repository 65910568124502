import React from "react";
import ModifyEngagement from "../../components/ModifyEngagement";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { engagementActions } from "../../store/actions/engagement.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { referralActions } from "../../../Referrals/store/actions/referral.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { fileActions } from "../../../File/store/actions/file.actions";
import PropTypes from "prop-types";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  convertToIso,
  getOrganisations,
  calculateSignUpOutcomeOptions,
  isEmpty,
  canUserModifyRecord,
  commitFileChanges,
  textFormattingHelpers
} from "../../helpers";
import moment from "moment";
import uuidv4 from "uuid/v4";

class EngagementCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableEngagementModalOpen: false,
    formInvalid: false,
    validationResults: {},
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    addActivities: false,
    isPrePopulated: false,
    hasFileChanges: false,
    hasPageLoaded: false
  };

  componentDidMount = async () => {
    this.props.clearSelectedProgramme();
    switch (this.props.match.params.entityType) {
      case "referral":
        await this.props.getReferralById(
          this.props.match.params.entityId,
          this.props.headers
        );
        break;
      case "programme":
        await this.props.getProgrammeById(
          this.props.match.params.entityId,
          this.props.headers
        );
        break;
      default:
        break;
    }

    await this.handleFetchData();
    let form = document.getElementById("engagementForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    await this.props.updateSelectedEngagement(
      "dateOfEngagement",
      moment().toISOString()
    );
    this.props.updateSelectedEngagement("engagementId", uuidv4());
    this.setState({ hasPageLoaded: true });
  };

  componentDidUpdate = async prevProps => {
    if (
      localStorage.getItem("referralId") &&
      Object.keys(this.props.selectedReferralData).length > 0 &&
      !this.state.isPrePopulated
    ) {
      if (this.props.selectedReferralData.owner) {
        this.props.updateSelectedEngagement(
          "owner",
          this.props.selectedReferralData.owner
        );
      }
      if (this.props.selectedReferralData.referralId) {
        this.props.updateSelectedEngagement(
          "referralId",
          this.props.selectedReferralData.referralId
        );
      }
      if (this.props.selectedReferralData.crmId) {
        this.props.updateSelectedEngagement(
          "referralCrmId",
          this.props.selectedReferralData.crmId
        );
      }
      if (this.props.selectedReferralData.area) {
        this.props.updateSelectedEngagement(
          "area",
          this.props.selectedReferralData.area
        );
      }
      if (this.props.selectedReferralData.firstName) {
        this.props.updateSelectedEngagement(
          "firstName",
          this.props.selectedReferralData.firstName
        );
      }
      if (this.props.selectedReferralData.surname) {
        this.props.updateSelectedEngagement(
          "surname",
          this.props.selectedReferralData.surname
        );
      }
      if (this.props.selectedReferralData.pNumber) {
        this.props.updateSelectedEngagement(
          "pNumber",
          this.props.selectedReferralData.pNumber
        );
      }
      if (this.props.selectedReferralData.scroNumber) {
        this.props.updateSelectedEngagement(
          "scroNumber",
          this.props.selectedReferralData.scroNumber
        );
      }
      if (this.props.match.params.engagementType) {
        this.props.updateSelectedEngagement(
          "type",
          this.props.match.params.engagementType
        );
      }
      this.props.updateSelectedEngagement(
        "referralDetails",
        this.props.selectedReferralData
      );

      if (
        !isEmpty(this.props.selectedReferralData) &&
        isEmpty(this.state.initialReferralData)
      ) {
        if (!this.props.initialReferralData) {
          this.setState({
            initialReferralData: this.props.selectedReferralData
          });
        }
      }

      this.setState({ isPrePopulated: true });
    } else if (
      Object.keys(this.props.selectedProgrammeData).length > 0 &&
      !this.state.isPrePopulated
    ) {
      if (this.props.selectedProgrammeData.owner) {
        this.props.updateSelectedEngagement(
          "owner",
          this.props.selectedProgrammeData.owner
        );
      }
      if (this.props.selectedProgrammeData.programmeId) {
        this.props.updateSelectedEngagement(
          "programmeId",
          this.props.selectedProgrammeData.programmeId
        );
      }
      if (this.props.selectedProgrammeData.referralId) {
        this.props.updateSelectedEngagement(
          "referralId",
          this.props.selectedProgrammeData.referralId
        );
      }
      if (this.props.selectedProgrammeData.crmId) {
        this.props.updateSelectedEngagement(
          "programmeCrmId",
          this.props.selectedProgrammeData.crmId
        );
      }
      if (this.props.selectedProgrammeData.area) {
        this.props.updateSelectedEngagement(
          "area",
          this.props.selectedProgrammeData.area
        );
      }
      if (this.props.selectedProgrammeData.firstName) {
        this.props.updateSelectedEngagement(
          "firstName",
          this.props.selectedProgrammeData.firstName
        );
      }
      if (this.props.selectedProgrammeData.surname) {
        this.props.updateSelectedEngagement(
          "surname",
          this.props.selectedProgrammeData.surname
        );
      }
      if (this.props.selectedProgrammeData.pNumber) {
        this.props.updateSelectedEngagement(
          "pNumber",
          this.props.selectedProgrammeData.pNumber
        );
      }
      if (this.props.selectedProgrammeData.scroNumber) {
        this.props.updateSelectedEngagement(
          "scroNumber",
          this.props.selectedProgrammeData.scroNumber
        );
      }
      if (this.props.match.params.engagementType) {
        this.props.updateSelectedEngagement(
          "type",
          this.props.match.params.engagementType
        );
      }
      this.setState({ isPrePopulated: true });
    }

    if (
      prevProps.selectedProgrammeData.referralId !==
        this.props.selectedProgrammeData.referralId &&
      this.props.selectedProgrammeData.referralId
    ) {
      await this.props.getReferralById(
        this.props.selectedProgrammeData.referralId,
        this.props.headers
      );
    }
  };

  componentWillUnmount() {
    this.props.clearSelectedEngagement();
    this.props.clearSelectedReferral();
  }

  filesHaveChanged = () => {
    this.setState({ hasFileChanges: true });
  };

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedEngagementData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.engagements.modifyEngagement
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    if (this.state.hasFileChanges) {
      await commitFileChanges(
        this.props.commitChanges,
        this.props.localData,
        this.props.remoteData,
        strings.folder.engagementFiles,
        strings.entity.engagement,
        this.props.selectedEngagementData.engagementId
      );
    }

    const data = textFormattingHelpers.decodeData(
      this.props.selectedEngagementData,
      {},
      textFields
    );

    await this.props.createEngagement(data, this.props.headers);
    if (
      this.props.selectedEngagementData.outcomeOfSignupMeeting ===
        strings.outcomes.customerStart &&
      this.props.selectedReferralData.communityOrganisation !==
        this.props.selectedReferralData.owner &&
      this.props.selectedReferralData.area === strings.areas.NRS
    ) {
      let sharedWith = JSON.parse(
        JSON.stringify(this.props.selectedReferralData.sharedWith)
      );
      sharedWith.push(this.props.selectedReferralData.communityOrganisation);
      this.props.updateSelectedReferral("sharedWith", sharedWith);
      await this.props.updateReferral(
        this.props.selectedReferralData.referralId,
        this.props.selectedReferralData,
        this.props.headers,
        this.state.initialReferralData
      );
    }
    if (
      this.props.selectedEngagementData.outcomeOfSignupMeeting ===
      strings.outcomes.customerStart
    ) {
      await this.props.updateReferral(
        this.props.selectedReferralData.referralId,
        this.props.selectedReferralData,
        this.props.headers,
        this.state.initialReferralData
      );
    }
    if (this.state.addActivities) {
      this.props.history.push(
        "/activities/create/" + this.props.selectedEngagementData.engagementId
      );
    } else if (localStorage.getItem("referralId")) {
      this.props.history.push(
        "/referrals/view/" + this.props.selectedReferralData.referralId
      );
    } else {
      this.props.history.push("/engagements");
    }
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearEngagementRequest();
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedEngagement(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedEngagement(data.name, data.value);
    }
    if (data.name === "isSuccessfulValue") {
      await this.props.updateSelectedEngagement("isNotSuccessfulReason", "");
    } else if (data.name === "type") {
      await this.props.updateSelectedEngagement("outcomeOfReferralMeeting", "");
      await this.props.updateSelectedEngagement("outcomeOfSignupMeeting", "");
      await this.props.updateSelectedEngagement("consent", "");
    }
  };

  handleAddActivity = () => {
    this.setState({ addActivities: true });
    this.validateForm();
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableEngagementModalVisibility = () => {
    this.setState({
      enableDisableEngagementModalOpen: !this.state
        .enableDisableEngagementModalOpen
    });
  };

  getSignUpOutcomeOptions = () => {
    return calculateSignUpOutcomeOptions(this.props.selectedReferralData);
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.match.params.entityType === "referral"
        ? this.props.selectedReferralData
        : this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.orgsLoadingPage ||
      this.props.programmeLoadingPage ||
      this.props.referralLoadingPage;
    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <ModifyEngagement
            mode="create"
            role={this.props.roleId}
            pageTitle={strings.header.createEngagement}
            selectedEngagementData={this.props.selectedEngagementData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            enableDisableEngagementModalOpen={
              this.state.enableDisableEngagementModalOpen
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableEngagementModalVisibility={
              this.toggleEnableDisableEngagementModalVisibility
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            handleAddActivity={this.handleAddActivity}
            initialEngagementData={{}}
            type={this.props.match.params.engagementType}
            getOrganisations={this.getOrganisations}
            filesHaveChanged={this.filesHaveChanged}
            hasFileChanges={this.state.hasFileChanges}
            headers={this.props.headers}
            hasPageLoaded={this.state.hasPageLoaded}
            orgOfLoggedInUser={this.props.organisationOfLoggedInUser}
            getSignUpOutcomeOptions={this.getSignUpOutcomeOptions()}
            isDisabled={this.isDisabled()}
          />
        </div>
      </div>
    );
  };
}

EngagementCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedEngagementData: PropTypes.object.isRequired,
  updateSelectedEngagement: PropTypes.func.isRequired,
  clearSelectedEngagement: PropTypes.func.isRequired,
  createEngagement: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    engagements,
    auth,
    organisations,
    referrals,
    programmes,
    file
  } = state;
  const { loadingPage, selectedEngagementData } = engagements;
  const { roleId, organisationOfLoggedInUser } = auth;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const { selectedReferralData, loadingPage: referralLoadingPage } = referrals;
  const {
    selectedProgrammeData,
    programmeRequestStatus,
    loadingPage: programmeLoadingPage
  } = programmes;
  const { localData, remoteData } = file;

  return {
    loadingPage,
    selectedEngagementData,
    roleId,
    organisationListData,
    selectedReferralData,
    selectedProgrammeData,
    programmeRequestStatus,
    organisationOfLoggedInUser,
    orgsLoadingPage,
    referralLoadingPage,
    programmeLoadingPage,
    localData,
    remoteData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createEngagement: async (data, headers) => {
      await dispatch(engagementActions.createEngagement(data, headers));
    },
    updateSelectedEngagement: (key, value) => {
      dispatch(engagementActions.updateSelectedEngagement(key, value));
    },
    clearSelectedEngagement: () => {
      dispatch(engagementActions.clearSelectedEngagement());
    },
    clearEngagementRequest: () => {
      dispatch(engagementActions.clearEngagementRequest());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getReferralById: (id, headers) => {
      dispatch(referralActions.getReferralById(id, headers));
    },
    updateSelectedReferral: (key, value) => {
      dispatch(referralActions.updateSelectedReferral(key, value));
    },
    updateReferral: (
      id,
      data,
      headers,
      initialReferralData,
      showNotification = true
    ) => {
      dispatch(
        referralActions.updateReferral(
          id,
          data,
          headers,
          initialReferralData,
          showNotification
        )
      );
    },
    clearSelectedReferral: () => {
      dispatch(referralActions.clearSelectedReferral());
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    },
    commitChanges: (
      localData,
      remoteData,
      filePath,
      headers,
      entity,
      entityId,
      folder
    ) => {
      return dispatch(
        fileActions.commitChanges(
          localData,
          remoteData,
          filePath,
          headers,
          entity,
          entityId,
          folder
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EngagementCreateContainer);
