import { API } from "../../services";
import { ttgProgrammeActionTypes } from "./ttgProgramme.actionTypes";
import { amendmentHelpers } from "../../helpers";
import { strings } from "../../resources";

const api = new API();

const clearTtgProgrammeRequest = () => {
  const request = () => {
    return {
      type: ttgProgrammeActionTypes.CLEAR_CREATE_TTG_PROGRAMME_REQUEST
    };
  };

  const success = () => {
    return {
      type: ttgProgrammeActionTypes.CLEAR_CREATE_TTG_PROGRAMME_SUCCESS
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedTtgProgrammeUpdate = initialTtgProgrammeData => {
  const request = () => {
    return {
      type: ttgProgrammeActionTypes.CANCEL_SELECTED_TTG_PROGRAMME_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type:
        ttgProgrammeActionTypes.CANCEL_SELECTED_TTG_PROGRAMME_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialTtgProgrammeData));
  };
};

const updateTtgProgramme = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return {
      type: ttgProgrammeActionTypes.UPDATE_TTG_PROGRAMME_REQUEST,
      details
    };
  };
  const success = response => {
    return {
      type: ttgProgrammeActionTypes.UPDATE_TTG_PROGRAMME_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: ttgProgrammeActionTypes.UPDATE_TTG_PROGRAMME_FAILURE,
      error,
      initialDetails
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "updateTtgProgramme" });

  return dispatch => {
    dispatch(request(details));
    api.updateTtgProgramme(id, details).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const deleteTtgProgramme = (id, headers) => {
  const request = () => {
    return { type: ttgProgrammeActionTypes.DELETE_TTG_PROGRAMME_REQUEST };
  };
  const success = response => {
    return {
      type: ttgProgrammeActionTypes.DELETE_TTG_PROGRAMME_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: ttgProgrammeActionTypes.DELETE_TTG_PROGRAMME_FAILURE,
      error
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteTtgProgramme" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteTtgProgramme(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getTtgProgrammes = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly
) => {
  const request = () => {
    return { type: ttgProgrammeActionTypes.GET_TTG_PROGRAMMES_REQUEST };
  };
  const success = response => {
    return {
      type: ttgProgrammeActionTypes.GET_TTG_PROGRAMMES_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: ttgProgrammeActionTypes.GET_TTG_PROGRAMMES_FAILURE,
      error
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getTtgProgrammes" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (clear) {
        // If supposed to clear, replicate blank result
        dispatch(success({ hits: { hits: [] } }));
        resolve();
      } else {
        api
          .getTtgProgrammes(queryString, size, from, fields, sort, activeOnly)
          .then(
            response => {
              const entityDetails = JSON.parse(
                localStorage.getItem("entityDetails")
              );

              if (
                entityDetails &&
                entityDetails.entity === strings.amendments.entity
              ) {
                response.data = amendmentHelpers.removeFromList(
                  response.data,
                  entityDetails
                );
              }

              dispatch(success(response));
              resolve(response);
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          );
      }
    });
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly
) => {
  const request = () => {
    return { type: ttgProgrammeActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: ttgProgrammeActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };

  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(
      getTtgProgrammes(
        headers,
        queryString,
        size,
        from,
        clear,
        fields,
        sort,
        activeOnly
      )
    );
  };
};

const getTtgProgrammeById = (id, headers) => {
  const request = () => {
    return { type: ttgProgrammeActionTypes.GET_TTG_PROGRAMME_BY_ID_REQUEST };
  };
  const success = response => {
    return {
      type: ttgProgrammeActionTypes.GET_TTG_PROGRAMME_BY_ID_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: ttgProgrammeActionTypes.GET_TTG_PROGRAMME_BY_ID_FAILURE,
      error
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getTtgProgrammeById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getTtgProgrammeById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedTtgProgramme = (key, value) => {
  const request = (key, value) => {
    return {
      type: ttgProgrammeActionTypes.UPDATE_SELECTED_TTG_PROGRAMME_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: ttgProgrammeActionTypes.UPDATE_SELECTED_TTG_PROGRAMME_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return {
      type: ttgProgrammeActionTypes.UPDATE_SELECTED_TTG_PROGRAMME_FAILURE
    };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedTtgProgramme = () => {
  const request = () => {
    return {
      type: ttgProgrammeActionTypes.CLEAR_SELECTED_TTG_PROGRAMME_REQUEST
    };
  };
  const success = () => {
    return {
      type: ttgProgrammeActionTypes.CLEAR_SELECTED_TTG_PROGRAMME_SUCCESS
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const getTtgProgrammeOutcomes = (id, headers) => {
  const request = () => {
    return {
      type: ttgProgrammeActionTypes.GET_TTG_PROGRAMME_OUTCOMES_REQUEST
    };
  };
  const success = response => {
    return {
      type: ttgProgrammeActionTypes.GET_TTG_PROGRAMME_OUTCOMES_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: ttgProgrammeActionTypes.GET_TTG_PROGRAMME_OUTCOMES_FAILURE,
      error
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "outcomesStatistics" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.outcomesStatistics(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};
export const ttgProgrammeActions = {
  updateTtgProgramme,
  deleteTtgProgramme,
  getTtgProgrammeById,
  getTtgProgrammes,
  updateSearchCriteria,
  updateSelectedTtgProgramme,
  clearSelectedTtgProgramme,
  clearTtgProgrammeRequest,
  cancelSelectedTtgProgrammeUpdate,
  getTtgProgrammeOutcomes
};
