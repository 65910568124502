import React from "react";
import TableComponent from "../../components/AuditTable";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { auditActions } from "../../store/actions/audit.actions";
import { strings as activityStrings } from "../../../Activities/resources/Strings";
import { strings as assessmentSGStrings } from "../../../AssessmentSG/resources/Strings";
import { strings as baselineStrings } from "../../../Baselines/resources/Strings";
import { strings as costsStrings } from "../../../Costs/resources/Strings";
import { strings as customerStrings } from "../../../Customers/resources/Strings";
import { strings as employmentStrings } from "../../../Employments/resources/Strings";
import { strings as engagementStrings } from "../../../Engagements/resources/Strings";
import { strings as feedbackStrings } from "../../../Feedback/resources/Strings";
import { strings as organisationStrings } from "../../../Organisations/resources/Strings";
import { strings as programmeStrings } from "../../../Programmes/resources/Strings";
import { strings as referralStrings } from "../../../Referrals/resources/Strings";
import { strings as taskStrings } from "../../../Tasks/resources/Strings";
import { strings as userStrings } from "../../../Users/resources/Strings";
import { strings as ttgRecordStrings } from "../../../TtgRecord/resources/Strings";
import { strings as ttgProgrammeStrings } from "../../../TtgProgrammes/resources/Strings";
import { strings as newsStrings } from "../../../News/resources/Strings";
import { strings as dummyStrings } from "../../../DummyRecords/resources/Strings";
import { strings as assuranceStrings } from "../../../Assurances/resources/Strings";
import { strings as complaintStrings } from "../../../Complaints/resources/Strings";
import { strings } from "../../resources";
import PropTypes from "prop-types";

const requestTimeout = 20000;

class AuditListContainer extends React.Component {
  state = {
    deleteModalOpen: false,
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    auditDeletionId: "",
    searchCriteria: {
      size: 20,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: { created: { order: "desc" } },
      activeOnly: false
    },
    showCleared: false,
    startDate: "",
    endDate: "",
    searchBarQuery: "",
    activeIndex: 0,
    stringsObject: {},
    sessionId: undefined
  };

  componentDidMount = async () => {
    this.timerID = setTimeout(() => {
      if (
        this.props.auditRequestStatus === undefined &&
        this.props.auditRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
    this.setState({
      stringsObject: {
        ...activityStrings.form.label,
        ...assessmentSGStrings.form.label,
        ...ttgProgrammeStrings.form.label,
        ...ttgRecordStrings.form.label,
        ...baselineStrings.form.label,
        ...costsStrings.form.label,
        ...customerStrings.form.label,
        ...employmentStrings.form.label,
        ...engagementStrings.form.label,
        ...feedbackStrings.form.label,
        ...organisationStrings.form.label,
        ...programmeStrings.form.label,
        ...referralStrings.form.label,
        ...taskStrings.form.label,
        ...userStrings.form.label,
        ...dummyStrings.form.label,
        ...assuranceStrings.form.label,
        ...complaintStrings.form.label,
        ...newsStrings.form.label,
        ...{ updaterName: "Updater Name" }
      }
    });
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearAuditRequest();
  }

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "", sessionId: "" });
    await this.props.clearAuditRequest();
    await this.handleFetchData(this.state);
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteria };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteria: searchCriteria });
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleFetchData = async state => {
    const sCrit = this.state.searchCriteria;
    let sortObject = {};
    if (state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }
    if (this.state.sessionId) {
      sCrit.fields = ["sessionId"];
      sCrit.query = this.state.sessionId;
    } else if (this.props.entityId) {
      sCrit.fields = ["entityId"];
      sCrit.query = this.props.entityId;
    } else {
      sCrit.fields = [
        "actionType",
        "page",
        "table",
        "created",
        "crmId",
        "username",
        "dbActionType"
      ];
      sCrit.query = this.state.searchBarQuery;
    }

    await this.props.updateSearchCriteria(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      state.page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      Object.keys(sortObject).length > 0 ? sortObject : sCrit.sort,
      sCrit.activeOnly
    );
  };

  viewSession = async sessionId => {
    this.setState({ sessionId: sessionId }, () => {
      this.handleFetchData(this.state);
    });
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "deleteAuditModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  toggleConfirmDeleteModalVisibility = id => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      auditDeletionId: id
    });
  };

  render = () => {
    const loading = this.props.loadingPage;
    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            auditListData={this.props.auditListData}
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            deleteModalOpen={this.state.deleteModalOpen}
            handleModalConfirm={this.handleModalConfirm}
            handleRefresh={this.handleRefresh}
            handleSubmit={this.handleSubmit}
            searchBarQuery={this.state.searchBarQuery}
            pageSize={this.state.searchCriteria.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            handleChange={this.handleChange}
            totalResults={this.props.totalResults}
            updatePageIndex={this.updatePageIndex}
            roleId={this.props.roleId}
            stringsObject={this.state.stringsObject}
            viewSession={this.viewSession}
            hideSearchBar={this.state.sessionId || this.props.hideSearchBar}
            record={this.props.record}
            embedded={this.props.embedded}
          />
        </div>
      </div>
    );
  };
}

AuditListContainer.propTypes = {
  auditListData: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getAudits: PropTypes.func.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { audit, auth } = state;
  const { roleId } = auth;
  const {
    auditListData,
    loadingPage,
    auditRequestStatus,
    auditRequestMade,
    result,
    error,
    totalResults,
    errorDetails
  } = audit;
  return {
    auditListData,
    loadingPage,
    auditRequestStatus,
    auditRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAudits: (
      headers,
      queryString,
      size,
      from,
      clear,
      sort,
      activeOnly,
      fields
    ) => {
      dispatch(
        auditActions.getAudits(
          headers,
          queryString,
          size,
          from,
          clear,
          sort,
          activeOnly,
          fields
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      startDate,
      endDate
    ) => {
      dispatch(
        auditActions.updateSearchCriteria(
          headers,
          data,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          startDate,
          endDate
        )
      );
    },
    clearAuditRequest: () => {
      dispatch(auditActions.clearAuditRequest());
    },
    setAuditDataSelected: data => {
      dispatch(auditActions.setAuditDataSelected(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditListContainer);
