import axios from "./axios-news";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createNews = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  };

  updateNews = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating news: ", error.message);
          reject(error);
        });
    });
  };

  deleteNews = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, null, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting news: ", error.message);
          reject(error);
        });
    });
  };

  getNews = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    programmeId
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly,
      programmeId: programmeId
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/list-news/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting news: ", error.message);
          reject(error);
        });
    });
  };

  getNewsByProgramme = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    programmeId
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`/programmes/${programmeId}`, JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting programme news: ", error.message);
          reject(error);
        });
    });
  };

  getNewsById = newsId => {
    const config = {
      headers: {
        ...this.headers,
        programme: localStorage.getItem("programmeId")
      }
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/${newsId}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting news: ", error.message);
          reject(error);
        });
    });
  };
}
