import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    form: {
      feedback: {
        requestUnknown: "Something went wrong with the request."
      }
    }
  }
});
