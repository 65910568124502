/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEntry = /* GraphQL */ `
  query GetEntry($userId: String!) {
    getEntry(userId: $userId) {
      results {
        id
        created
        userId
        details
        entity
      }
    }
  }
`;
