import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      login: "Login",
      taskList: "Tasks",
      createTask: "New Task",
      editTask: "Edit Task",
      viewTask: "Task Details",
      loading: "Loading Information",
      showCleared: "Show Cleared Tasks",
      hideCleared: "Hide Cleared Tasks"
    },
    form: {
      header: {
        taskInfo: "Task Information",
        tasksLink: "Tasks",
        clientsLink: "Clients",
        supportLink: "Support",
        settingsLink: "Settings",
        supportReferralDetails: "Support Referral Details"
      },
      label: {
        taskName: "Task Name",
        firstName: "First Name",
        surname: "Last Name",
        description: "Description",
        assignedSpecialistIds: "Assigned Specialists",
        cleared: "Cleared",
        clearReason: "Clear Reason",
        crmId: "CRM ID",
        dueDate: "Due Date",
        pNumber: "Prison Number",
        clearTask: "Clear Task",
        deleteReason: "Delete Request Reason",
        rejectionReason: "Rejection Reason",
        dateOfEngagement: "Last Date of Engagement",
        engagementSuccessful: "Last Engagement Succesful?",
        provider: "Provider",
        dateReferred: "Date Referred",
        stageOfReferral: "Stage of Referral",
        reasonForReferral: "Reason For Referral"
      },
      placeholder: {
        taskName: "",
        firstName: "",
        surname: "",
        description: "",
        assignedSpecialistIds: "",
        cleared: "",
        clearReason: "",
        crmId: "",
        dueDate: "",
        pNumber: "",
        stateReason: "State your reason...",
        approveReject: "-",
        deleteReason: "No Reason Provided",
        rejectionReason: "",
        dateOfEngagement: "",
        engagementSuccessful: "",
        stageOfReferral: "",
        provider: "",
        dateReferred: "",
        reasonForReferral: ""
      },
      feedback: {
        processing: "Processing request...",
        taskRequestSuccess: "Task created successfully!",
        taskRequestRefreshPrompt:
          "Use the refresh function to see an updated tasks list.",
        taskRequestFailure: "Task creation failed.",
        taskRequestUnknown: "Something went wrong with the task request.",
        amendmentRequestUnknown: "Something went wrong with the request.",
        programmeRequestUnknown:
          "Something went wrong with the programme request.",
        customerRequestUnknown:
          "Something went wrong with the customer request.",
        referralRequestUnknown:
          "Something went wrong with the referral request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      }
    },
    modal: {
      createTaskMessage: "Save task?",
      modifyTaskMessage: "Are you sure you want to save these changes?",
      deleteTaskMessage:
        "Are you sure you want to delete this task? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this task's status to active?",
      setInactiveMessage: "Set this task's status to inactive?",
      clearTaskReasonMessage: "Please submit a reason for deleting this task: ",
      actionTaskMessage: "Would you like to approve or reject this request?",
      supportReferralTaskMessage:
        "Would you like to approve or reject this support referral?",
      amendmentComparison: "Please approve or reject the requested changes:",
      deletionApproval: "Please approve or reject the deletion request:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      addTaskLabel: "New Task",
      approve: "Approve",
      reject: "Reject",
      actionLabel: "Action Task",
      confirm: "Confirm",
      submit: "Submit"
    },
    tooltip: {
      editTask: "Edit Task",
      deleteTask: "Delete Task"
    },
    table: {
      header: {
        firstName: "First Name",
        surname: "Last Name",
        createdDate: "Created",
        updatedDate: "Updated",
        active: "Active",
        dueDate: "Follow Up Date",
        crmId: "CRM ID",
        taskType: "Task Type",
        pNumber: "Prison Number",
        risk: "Risk",
        organisationName: "Organisation Name",
        type: "Area of Need",
        action: "Action",
        dateOfEngagement: "Last Date of Engagement",
        engagementSuccessful: "Last Engagement Successful?"
      },
      text: {
        noResults: "No results found."
      },
      title: "Tasks Table",
      ttgNeedFollowUpTaskTitles: [
        "Accommodation Follow Up",
        "Finance Follow Up",
        "Employment, Education & Training Follow Up",
        "Victims of Domestic Abuse Follow Up",
        "Sex Worker Follow Up",
        "Other Need Follow Up"
      ]
    },
    fieldValues: {
      yes: "Yes",
      no: "No",
      Employer: "Employer",
      approved: "Approved",
      rejected: "Rejected",
      SAMH: "102ca101-6a1e-431f-b142-e2de093844d1",
      familiesOutside: "f9f77eea-5690-46cd-bbf6-240b7ad5ffde"
    },
    amendmentTypes: {
      deleteAmendment: "deleteAmendment",
      changeAmendment: "changeAmendment",
      duplicateWarning: "duplicateWarning",
      mentorAssigned: "mentorAssigned",
      supportPartnerReferral: "supportServiceReferralAssignedSAMH"
    },
    tasks: {
      header: "My Work",
      labels: {
        due: "Due",
        stillToComplete: "Still to Complete"
      },
      na: "N/A",
      ttgEngagementFollowup: "TtG Engagement Follow-up",
      ttgNeedFollowUpTask: [
        "accommodationNeed",
        "financeNeed",
        "employmentEducationTrainingNeed",
        "victimsDomesticAbuseNeed",
        "sexWorkerNeed",
        "otherNeed"
      ]
    },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" }
  }
});
