import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, TextArea } from "semantic-ui-react";
import { strings } from "../resources";
import { BasicModal, ComponentHeader, InputModal } from ".";
import { ValidatedFormDropdown } from "./validation";
import "./css/ReferralComponents.scss";
import { permissions } from "../../App/resources/AppPermissions";
import { withRouter } from "react-router-dom";
import { ChooseArea } from "./";
import FieldList from "./FieldList";
import { AuditListContainer } from "../../Audit/containers/";

class ModifyReferral extends React.Component {
  returnToList = () => {
    this.props.history.push("/referrals");
  };

  isSharable = () => {
    return (
      this.props.orgOfLoggedInUser === this.props.selectedReferralData.owner &&
      !this.props.selectedReferralData.formType &&
      permissions.referrals.shareRecord.includes(this.props.roleId) &&
      this.props.selectedReferralData.area === strings.areas.NRS
    );
  };

  renderModifyButtons = () => {
    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      ></Input>
    );
    const modalDropdown = (
      <ValidatedFormDropdown
        formInvalid={false}
        inputData={this.props.selectedReferralData.sharedWith}
        dropdownLabel={strings.form.label.sharedWith}
        dropdownName="sharedWith"
        dropdownOptions={this.props.getOrganisations.filter(org => {
          return (
            (org.maintype === strings.orgTypes.endToEndPartner ||
              org.maintype === strings.orgTypes.leadPartner) &&
            org.value !== this.props.selectedReferralData.owner
          );
        })}
        dropdownPlaceholder={strings.form.placeholder.sharedWith}
        formFieldWidth={8}
        handleChange={this.props.handleChange}
        validationResult={this.props.validationResults.sharedWith}
        dropdownMultiple={true}
        dropdownFluid={true}
        required={true}
        hideMessage={true}
      />
    );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful && (
          <>
            {!this.props.isDisabled ? (
              <>
                <Button.Group>
                  <Button
                    className="editButton"
                    primary
                    onClick={() => {
                      this.props.handleModeSwitch("edit");
                    }}
                    disabled={
                      !this.props.selectedReferralData.active ||
                      !this.props.selectedReferralData.formType ||
                      this.props.showAuditLog
                    }
                  >
                    {strings.button.editLabel}
                  </Button>
                </Button.Group>
              </>
            ) : (
              <span className="errorText">
                {strings.form.errorText.notShared}
              </span>
            )}
            <Button.Group>
              {this.isSharable() && (
                <Button
                  className="shareButton"
                  primary
                  inverted
                  onClick={this.props.toggleShareReferralModalVisibility}
                  type="button"
                >
                  {strings.button.shareLabel}
                </Button>
              )}
            </Button.Group>
            <InputModal
              input={modalDropdown}
              confirmMessage={strings.modal.shareMessage}
              handleModalConfirm={this.props.handleModalConfirm}
              modalClassName="shareModal"
              modalOpen={this.props.shareReferralModalOpen}
              showOkButton={true}
              toggleModal={this.props.toggleShareReferralModalVisibility}
              okLabel={strings.button.okLabel}
              resetDataOnModalClose={this.props.resetDataOnModalClose}
            />
          </>
        )}

        {/* Temporarily hidden */}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful &&
          !this.props.isDisabled &&
          false && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={this.props.toggleEnableDisableReferralModalVisibility}
                type="button"
                disabled={
                  this.props.selectedReferralData.requestId ? true : false
                }
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <InputModal
                input={modalTextInput}
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableReferralModalOpen}
                showOkButton={true}
                inputValue={
                  this.props.deletionReason && this.props.deletionReason.value
                }
                inputRequired={true}
                toggleModal={
                  this.props.toggleEnableDisableReferralModalVisibility
                }
                okLabel={strings.button.submitLabel}
                resetDataOnModalClose={() => {}}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderDeleteButton = () => {
    return (
      <div className="containerButtons">
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful &&
          !this.props.isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={this.props.toggleEnableDisableReferralModalVisibility}
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteReferralMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableReferralModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={
                  this.props.toggleEnableDisableReferralModalVisibility
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderAmendmentButton = () => {
    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful &&
          !this.props.isDisabled &&
          this.props.mode === "view" && (
            <Button.Group>
              <Button
                className="amendmentButton"
                primary
                inverted
                onClick={() => {
                  this.props.handleAmendmentMode();
                }}
                disabled={
                  !this.props.selectedReferralData.active ||
                  this.props.selectedReferralData.formType !== "Referral" ||
                  (this.props.selectedReferralData.area ===
                    strings.areas.NRNE &&
                    !this.props.initialReferralData.consent) ||
                  this.props.showAuditLog
                }
              >
                {strings.button.requestAmendmentLabel}
              </Button>
            </Button.Group>
          )}
      </div>
    );
  };

  renderAuditButton = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="auditButton"
            onClick={() => {
              this.props.handleToggleAudit();
            }}
          >
            {!this.props.showAuditLog
              ? strings.button.auditLabel
              : strings.button.formLabel}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderCancelSaveButton = () => {
    const dataHasNotChanged =
      JSON.stringify(this.props.selectedReferralData) ===
      JSON.stringify(this.props.initialReferralData);
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={this.props.validateForm}
            disabled={dataHasNotChanged && !this.props.hasFileChanges}
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={this.props.confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
          <BasicModal
            confirmMessage={strings.modal.saveDuplicateMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmDuplicateModal"
            modalOpen={this.props.confirmDuplicateModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleConfirmDuplicateModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>

        {this.props.mode === "edit" && (
          <Button.Group>
            <Button
              className="saveButton"
              primary
              inverted
              onClick={this.props.validateFormAndClose}
              disabled={dataHasNotChanged && !this.props.hasFileChanges}
            >
              {strings.button.saveAndCloseLabel}
            </Button>
          </Button.Group>
        )}
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={this.props.toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              this.props.mode === "create"
                ? this.props.handleModalConfirm
                : this.props.handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={this.props.cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };

  renderAmendmentCancelSaveButtons = () => {
    const modalTextInput = (
      <Form>
        <TextArea
          placeholder={strings.form.placeholder.stateReason}
          onChange={this.props.handleChangeModal}
          name="amendmentReason"
        ></TextArea>
      </Form>
    );
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={this.props.validateForm}
            disabled={
              JSON.stringify(this.props.selectedReferralData) ===
                JSON.stringify(this.props.initialReferralData) &&
              !this.props.hasFileChanges
            }
          >
            {strings.button.submitAmendmentLabel}
          </Button>

          <InputModal
            input={modalTextInput}
            inputValue={this.props.amendmentReason}
            inputRequired
            confirmMessage={strings.modal.amendmentMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmSubmitAmendmentModal"
            modalOpen={this.props.confirmSubmitAmendmentModalOpen}
            showOkButton={true}
            toggleModal={this.props.toggleConfirmSubmitAmendmentModalVisibility}
            okLabel={strings.button.okLabel}
            resetDataOnModalClose={this.props.resetReasonsOnModalClose}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={this.props.toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              this.props.mode === "create"
                ? this.props.handleModalConfirm
                : this.props.handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={this.props.cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };

  renderForm = () => {
    return (
      <Form id="referralForm" className="stickyForm">
        {this.props.area !== strings.areas.ALL || this.props.selectedArea ? (
          <>
            {this.props.mode === "view" && (
              <FieldList
                selectedReferralData={this.props.selectedReferralData}
                handleChange={this.props.handleChange}
                handleChangeAddress1={this.props.handleChangeAddress1}
                handleChangeAddress2={this.props.handleChangeAddress2}
                handleChangeCommunity={this.props.handleChangeCommunity}
                handleChangeDoNotApproach={this.props.handleChangeDoNotApproach}
                handleChangeLiberation={this.props.handleChangeLiberation}
                handleChangeOffendingHistory={
                  this.props.handleChangeOffendingHistory
                }
                handleChangeOutcomeOfThreshold={
                  this.props.handleChangeOutcomeOfThreshold
                }
                handleChangeOutcomeReason={this.props.handleChangeOutcomeReason}
                handleChangePostcode={this.props.handleChangePostcode}
                handleChangePrisonNrne={this.props.handleChangePrisonNrne}
                handleChangePrisonNrs={this.props.handleChangePrisonNrs}
                handleChangePrisonOrg={this.props.handleChangePrisonOrg}
                handleChangeTownOrCity={this.props.handleChangeTownOrCity}
                mode={this.props.mode}
                validationResults={this.props.validationResults}
                formInvalid={this.props.formInvalid}
                area={
                  this.props.selectedArea
                    ? this.props.selectedArea
                    : this.props.area
                }
                allowNumericalInput={this.props.allowNumericalInput}
                roleId={this.props.roleId}
                specialistDropdown={this.props.specialistDropdown}
                mentorsArea={this.props.mentorsArea}
                mentorsPrisonSpecialistView={
                  this.props.mentorsPrisonSpecialistView
                }
                mentorsPrisonSpecialistSelect={
                  this.props.mentorsPrisonSpecialistSelect
                }
                mentorsCommunitySpecialistSelect={
                  this.props.mentorsCommunitySpecialistSelect
                }
                getOrganisations={this.props.getOrganisations}
                getInitialOwner={this.props.getInitialOwner}
                userId={this.props.userId}
                disabled={true}
                initialReferralData={this.props.initialReferralData}
                history={this.props.history}
                headers={this.props.headers}
                resetForm={this.props.resetForm}
                filesHaveChanged={this.props.filesHaveChanged}
                handleSameAddressChange={this.props.handleSameAddressChange}
                sameAddressChecked={this.props.sameAddressChecked}
                linkedProgramme={this.props.linkedProgramme}
                showSupportReferral={this.props.showSupportReferral}
                handleShowSupportReferral={this.props.handleShowSupportReferral}
                fixedAbodeChange={this.props.fixedAbodeChange}
                noFixedAbodeChecked={this.props.noFixedAbodeChecked}
              />
            )}
            {this.props.mode !== "view" && (
              <FieldList
                selectedReferralData={this.props.selectedReferralData}
                handleChange={this.props.handleChange}
                handleChangeAddress1={this.props.handleChangeAddress1}
                handleChangeAddress2={this.props.handleChangeAddress2}
                handleChangeCommunity={this.props.handleChangeCommunity}
                handleChangeDoNotApproach={this.props.handleChangeDoNotApproach}
                handleChangeLiberation={this.props.handleChangeLiberation}
                handleChangeOffendingHistory={
                  this.props.handleChangeOffendingHistory
                }
                handleChangeOutcomeOfThreshold={
                  this.props.handleChangeOutcomeOfThreshold
                }
                handleChangeOutcomeReason={this.props.handleChangeOutcomeReason}
                handleChangePostcode={this.props.handleChangePostcode}
                handleChangePrisonNrne={this.props.handleChangePrisonNrne}
                handleChangePrisonNrs={this.props.handleChangePrisonNrs}
                handleChangePrisonOrg={this.props.handleChangePrisonOrg}
                handleChangeTownOrCity={this.props.handleChangeTownOrCity}
                mode={this.props.mode}
                validationResults={this.props.validationResults}
                formInvalid={this.props.formInvalid}
                area={
                  this.props.selectedArea
                    ? this.props.selectedArea
                    : this.props.area
                }
                allowNumericalInput={this.props.allowNumericalInput}
                roleId={this.props.roleId}
                specialistDropdown={this.props.specialistDropdown}
                mentorsArea={this.props.mentorsArea}
                mentorsPrisonSpecialistView={
                  this.props.mentorsPrisonSpecialistView
                }
                mentorsPrisonSpecialistSelect={
                  this.props.mentorsPrisonSpecialistSelect
                }
                mentorsCommunitySpecialistSelect={
                  this.props.mentorsCommunitySpecialistSelect
                }
                getOrganisations={this.props.getOrganisations}
                getInitialOwner={this.props.getInitialOwner}
                userId={this.props.userId}
                disabled={false}
                initialReferralData={this.props.initialReferralData}
                history={this.props.history}
                headers={this.props.headers}
                filesHaveChanged={this.props.filesHaveChanged}
                handlePostcodeChanged={this.props.handlePostcodeChanged}
                handlePostcodeClicked={this.props.handlePostcodeClicked}
                addressSearchResults={this.props.addressSearchResults}
                addressRetrieved={this.props.addressRetrieved}
                handlePostcodeSearch={this.props.handlePostcodeSearch}
                postcodeOpenStates={this.props.postcodeOpenStates}
                handleSameAddressChange={this.props.handleSameAddressChange}
                libAddressSameChecked={this.props.libAddressSameChecked}
                mailingAddressSameChecked={this.props.mailingAddressSameChecked}
                linkedProgramme={this.props.linkedProgramme}
                showSupportReferral={this.props.showSupportReferral}
                handleShowSupportReferral={this.props.handleShowSupportReferral}
                fixedAbodeChange={this.props.fixedAbodeChange}
                noFixedAbodeChecked={this.props.noFixedAbodeChecked}
              />
            )}
          </>
        ) : (
          <fieldset>
            <ChooseArea
              selectedReferralData={this.props.selectedReferralData}
              handleAreaChange={this.props.handleAreaChange}
              mode={this.props.mode}
              validationResults={this.props.validationResults}
              formInvalid={this.props.formInvalid}
            />
          </fieldset>
        )}
      </Form>
    );
  };

  render = () => {
    return (
      <div className="container">
        {this.props.area && (
          <>
            <ComponentHeader
              returnToList={this.returnToList}
              pageTitle={this.props.pageTitle}
              mode={this.props.mode}
              requestStatus={this.props.requestStatus}
              modifyButtons={this.renderModifyButtons()}
              cancelSaveButtons={this.renderCancelSaveButton()}
              auditLog={this.renderAuditButton()}
              deleteButton={this.renderDeleteButton()}
              amendmentButton={this.renderAmendmentButton()}
              amendmentCancelSaveButtons={this.renderAmendmentCancelSaveButtons()}
              headerList={strings.header.referralList}
              permissionsUpdate={permissions.referrals.update}
              permissionsCreate={permissions.referrals.create}
              permissionsDelete={permissions.referrals.delete}
              permissionsAmend={permissions.referrals.amend}
              formInvalid={this.props.formInvalid}
              validationResults={this.props.validationResults}
              journeyCount={this.props.selectedReferralData.journeyCount}
              sticky
            />
            {this.props.showAuditLog ? (
              <AuditListContainer
                embedded={true}
                headers={this.props.headers}
                history={this.props.history}
                entityId={this.props.selectedReferralData.referralId}
                hideSearchBar={true}
                record={true}
              />
            ) : (
              this.renderForm()
            )}
          </>
        )}
      </div>
    );
  };
}

ModifyReferral.propTypes = {
  allowNumericalInput: PropTypes.func.isRequired,
  area: PropTypes.string,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  getInitialOwner: PropTypes.func,
  getOrganisations: PropTypes.array,
  handleAreaChange: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  handleModeSwitch: PropTypes.func,
  history: PropTypes.object.isRequired,
  initialReferralData: PropTypes.object.isRequired,
  mentors: PropTypes.array,
  mode: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  requestStatus: PropTypes.bool,
  roleId: PropTypes.string.isRequired,
  selectedArea: PropTypes.string,
  selectedReferralData: PropTypes.object.isRequired,
  specialistDropdown: PropTypes.array,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  userId: PropTypes.string,
  validateForm: PropTypes.func.isRequired,
  validationResults: PropTypes.object,
  deletePermissionStatus: PropTypes.string,
  handleModalInputChange: PropTypes.func,
  deletionReason: PropTypes.object,
  isDisabled: PropTypes.bool,
  shareReferralModalOpen: PropTypes.bool
};

export default withRouter(ModifyReferral);
