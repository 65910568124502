import React from "react";
import { Icon, Button, Grid, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ShowIfAuthorised } from ".";
import SearchBar from "./SearchBar";

import { DateRangeComponent } from "../../App/components";

class TableFunctions extends React.Component {
  render = () => {
    let buttonClassName = "redirectButton";
    if (
      this.props.returnToList ||
      this.props.returnToParent ||
      Array.isArray(this.props.parentPage)
    ) {
      buttonClassName += " redirectButtonUnderline";
    }
    return (
      <Grid columns={3} stackable padded className="TableFunctions">
        {this.props.headerList && (
          <Grid.Row>
            {this.props.parentPage && (
              <div className="pageHeader">
                {this.props.parentPage &&
                  (typeof this.props.parentPage === "string" ? (
                    <>
                      <Button
                        className={buttonClassName}
                        onClick={this.props.returnToParent}
                      >
                        {this.props.parentPage}
                      </Button>
                      <div className="headingDivider">/</div>
                    </>
                  ) : (
                    this.props.parentPage.map((page, key) => (
                      <React.Fragment key={key}>
                        <Button
                          className={
                            page.className ? page.className : buttonClassName
                          }
                          onClick={page.returnToParent}
                        >
                          {page.parentPage}
                        </Button>
                        <div className="headingDivider">/</div>
                      </React.Fragment>
                    ))
                  ))}
                {this.props.headerList}
              </div>
            )}
            {!this.props.parentPage && (
              <Header className={this.props.headerClass} as="h1">
                {this.props.headerList}
              </Header>
            )}
          </Grid.Row>
        )}
        <Grid.Column className="firstColumn">
          {!this.props.hideSearchBar && (
            <SearchBar
              handleChange={this.props.handleChange}
              inputValue={this.props.searchBarQuery}
              handleSubmit={this.props.handleSubmit}
            />
          )}
        </Grid.Column>
        <Grid.Column>
          {this.props.enableDateRange && (
            <DateRangeComponent dateRangeObject={this.props.dateRangeObject} />
          )}
        </Grid.Column>
        <Grid.Column className="ControlButtons">
          <Button
            primary
            className={this.props.refreshButtonClass}
            onClick={this.props.handleRefresh}
          >
            <Icon name="redo" />
          </Button>
          {!this.props.hideCreate && !this.props.isCreateDisabled && (
            <ShowIfAuthorised allowedRoles={this.props.permissionsCreate}>
              <Link
                to={{
                  pathname: this.props.addButtonLink,
                  state: { from: this.props.from }
                }}
              >
                <Button primary className={this.props.addButtonClass}>
                  {this.props.buttonAddLabel}
                </Button>
              </Link>
            </ShowIfAuthorised>
          )}
          {this.props.showClearedButton && this.props.showClearedButton()}
        </Grid.Column>
      </Grid>
    );
  };
}

TableFunctions.propTypes = {
  handleRefresh: PropTypes.func,
  headerClass: PropTypes.string.isRequired,
  headerList: PropTypes.string,
  refreshButtonClass: PropTypes.string.isRequired,
  permissionsCreate: PropTypes.array,
  addButtonLink: PropTypes.string,
  addButtonClass: PropTypes.string,
  buttonAddLabel: PropTypes.string,
  enableDateRange: PropTypes.bool,
  dateRangeObject: PropTypes.object
};

export default TableFunctions;
