export default function diffObjects(oldObject, newObject) {
  let oldKeys = Object.keys(oldObject);
  let newKeys = Object.keys(newObject);
  const deletedKeys = oldKeys.filter(key => !newKeys.includes(key));
  let oldValues = {};
  let newValues = {};
  for (let i = 0; i < deletedKeys.length; i++) {
    oldValues[deletedKeys[i]] = oldObject[deletedKeys[i]];
    newValues[deletedKeys[i]] = "deleted";
  }
  for (let i = 0; i < newKeys.length; i++) {
    if (!deepEqual(newObject[newKeys[i]], oldObject[newKeys[i]])) {
      oldValues[newKeys[i]] = oldObject[newKeys[i]];
      newValues[newKeys[i]] = newObject[newKeys[i]];
    }
  }
  return {
    oldValues: oldValues,
    newValues: newValues
  };
}

const deepEqual = (x, y) => {
  if (x === y) {
    return true;
  } else if (
    typeof x == "object" &&
    x != null &&
    typeof y == "object" &&
    y != null
  ) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;

    for (let prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) return false;
      } else return false;
    }

    return true;
  } else return false;
};
