import axios from "./axios-area-of-need.js";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createAreaOfNeed = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  };

  updateAreaOfNeed = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating areaOfNeed: ", error.message);
          reject(error);
        });
    });
  };

  deleteAreaOfNeed = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, null, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting areaOfNeed: ", error.message);
          reject(error);
        });
    });
  };

  getAreaOfNeed = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    ttgProgrammeId
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly,
      ttgProgrammeId
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/list-needs/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting areaOfNeed: ", error.message);
          reject(error);
        });
    });
  };

  getAreaOfNeedById = id => {
    const config = {
      headers: {
        ...this.headers,
        ttgProgramme: localStorage.getItem("ttgProgrammeId")
      }
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting areaOfNeed: ", error.message);
          reject(error);
        });
    });
  };
}
