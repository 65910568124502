import { isEmpty } from "./objectHelpers";

const getListOfPrisonSpecialists = (
  area,
  userListData,
  organisation,
  prison,
  assignedMentor
) => {
  //only add the ones of the correct role type to the list and are in the correct area.
  //If organisation is not set, return all, else return only ones of matching org and prison
  let data = [];
  if (userListData) {
    userListData.forEach(result => {
      if (result["_source"]) {
        if (hasAreaAndRole(result["_source"], area) && !organisation) {
          data.push(result["_source"]);
        } else if (
          hasAreaAndRole(result["_source"], area) &&
          hasValue(result["_source"], "organisationId", organisation) &&
          hasPrison(result["_source"], prison)
        ) {
          data.push(result["_source"]);
        }
      }
    });
  }

  if (!isEmpty(assignedMentor)) {
    data.push(assignedMentor);
  }

  let dropdownFields = [];
  data.forEach((object, key) => {
    //format the data in an appropriate format for a dropdown list
    dropdownFields.push({
      key: key,
      text: object.firstName + " " + object.surname,
      value: object.userId,
      caseloadmax: object.caseloadMax,
      organisation: object.organisationId,
      prison: object.prison,
      caseload: 0
    });
  });
  return dropdownFields;
};

const hasValue = (obj, key, value) => {
  return obj.hasOwnProperty(key) && obj[key] === value;
};

const includesValue = (obj, key, arr) => {
  return obj.hasOwnProperty(key) && arr.includes(obj[key]);
};

const hasPrison = (source, prison) => {
  return source.hasOwnProperty("prison") && source["prison"].includes(prison);
};

const hasAreaAndRole = (source, area) => {
  return (
    hasValue(source, "area", area) &&
    includesValue(source, "roleId", ["mentor", "teamLead", "prisonBasedMentor"])
  );
};

export default getListOfPrisonSpecialists;
