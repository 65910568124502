import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      fullName: "SG Assessment"
    },
    header: {
      login: "Login",
      assessmentSGList: "SG Assessment",
      createAssessmentSG: "New SG Assessment",
      editAssessmentSG: "Edit SG Assessment",
      viewAssessmentSG: "SG Assessment Details",
      loading: "Loading Information",
      embeddedAssessmentSGListStart: "SG Assessment for '",
      embeddedAssessmentSGListMiddle: "' of type '",
      embeddedAssessmentSGListEnd: "'",
      dateCompleted: "Date Completed"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        assessmentSGInfo: "SG Assessment Information",
        activityDetails: "Activity Details",
        administration: "Administration",
        customerDetails: "Customer Details",
        sgAssessmentQuestions: "SG Assessment Questions",
        crmID: "CRM ID",
        owner: "Owner"
      },
      label: {
        dateOfAssessmentSG: "Date of SG Assessment",
        method: "Method",
        location: "Location",
        isSuccessfulValue: "Successful",
        hasActivities: "Activity (or Activities) Carried Out?",
        outcomeOfReferralMeeting: "Outcome of Referral Meeting",
        outcomeOfSignupMeeting: "Outcome of Sign-up Meeting",
        dateOfActivity: "Date of Activity",
        type: "Activity Type",
        isNotSuccessfulReason: "If not successful, why not?",
        assessmentSGNotes: "SG Assessment notes",
        crmId: "CRM ID",
        owner: "Owner",
        consent: "Agree to data protection",
        customerName: "Customer Name",
        sNumber: "S Number",
        specialist: "Specialist",
        pNumber: "Prison Number",
        dateCompleted: ""
      },
      placeholder: {
        stateReason: "State your reason...",
        dateOfAssessmentSG: "- select -",
        method: "- select -",
        location: "- select -",
        type: "- select -",
        isSuccessfulValue: "- select -",
        hasActivities: "- select -",
        outcomeOfReferralMeeting: "- select -",
        outcomeOfSignupMeeting: "- select -",
        isNotSuccessfulReason: "",
        assessmentSGNotes: "",
        crmId: "",
        owner: "- select -",
        consent: "- select -",
        customerName: "",
        sNumber: "",
        specialist: "",
        dateCompleted: "",
        pNumber: ""
      },
      feedback: {
        processing: "Processing request...",
        assessmentSGRequestSuccess: "SG Assessment created successfully!",
        assessmentSGRequestRefreshPrompt:
          "Use the refresh function to see an updated assessmentSG list.",
        assessmentSGRequestFailure: "SG Assessment creation failed.",
        assessmentSGRequestUnknown:
          "Something went wrong with the SG Assessment request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        yes: "Yes",
        no: "No"
      }
    },
    modal: {
      deleteAssessmentSGMessage:
        "Are you sure you want to delete this SG Assessment? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addActivity: "Add Activity",
      addAssessmentSGLabel: "New SG Assessment",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    tooltip: {},
    table: {
      header: {
        date: "Date of SG Assessment",
        type: "Type",
        location: "Location",
        method: "Method",
        isSuccessfulValue: "Successful",
        numActivities: "No. Activities",
        crmId: "CRM ID"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    stages: {
      initialAssessment: "initialAssessment",
      preRelease: "preRelease",
      postRelease: "postRelease",
      exit: "exit",
      progress: "progress"
    },
    stageKeys: { dateCompleted: "dateCompleted" },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    error: {
      wrongArea: "SG Assessment is not available for NRNE programmes"
    }
  }
});
