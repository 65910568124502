const actionStatusOptions = [
  { key: 1, text: "Open", value: "Open" },
  { key: 2, text: "Closed", value: "Closed" }
];
const outcomeOfAreaOfNeedOptions = [
  { key: 0, text: "Positive", value: "Positive" },
  { key: 1, text: "Neutral", value: "Neutral" },
  { key: 2, text: "Work In Progress", value: "Work in progress" }
];

const areaOfNeedOptions = [
  {
    key: 0,
    text: "Accommodation",
    value: "Accommodation",
    match: "accommodation"
  },
  { key: 1, text: "Finance", value: "Finance", match: "finance" },
  {
    key: 2,
    text: "Employment, Education & Training",
    value: "Employment, Education & Training",
    match: "employmentEducationAndTraining"
  },
  {
    key: 3,
    text: "Victims of Domestic Abuse",
    value: "Victims of Domestic Abuse",
    match: "victimsOfDomesticAbuse"
  },
  { key: 4, text: "Sex Worker", value: "Sex Worker", match: "sexWorker" },
  { key: 5, text: "Other", value: "Other", match: "other" }
];

const accommodation = [
  {
    key: 0,
    text: "Submit a duty to refer form",
    value: "Submit a duty to refer form"
  },
  {
    key: 1,
    text: "Make suitable housing referrals",
    value: "Make suitable housing referrals"
  },
  { key: 2, text: "Approved premises", value: "Approved premises" },
  {
    key: 3,
    text: "Supported accommodation",
    value: "Supported accommodation"
  },
  { key: 4, text: "Maintain tenancy", value: "Maintain tenancy" },
  { key: 5, text: "End tenancy", value: "End tenancy" },
  { key: 6, text: "Other", value: "Other" }
];

const finance = [
  { key: 0, text: "Bank transfers", value: "Bank transfers" },
  {
    key: 1,
    text: "Cancelling direct debits",
    value: "Cancelling direct debits"
  },
  {
    key: 2,
    text: "Writing to debt collectors",
    value: "Writing to debt collectors"
  },
  { key: 3, text: "HMRC", value: "HMRC" },
  { key: 4, text: "Fines", value: "Fines" },
  { key: 5, text: "Benefits", value: "Benefits" },
  { key: 6, text: "Court Fines", value: "Court Fines" },
  {
    key: 7,
    text: "Open bank account/credit union account",
    value: "Open bank account/credit union account"
  },
  { key: 8, text: "Other", value: "Other" }
];

const employmentEducationAndTraining = [
  { key: 0, text: "Signpost to education", value: "Signpost to education" },
  { key: 1, text: "CSCS card", value: "CSCS card" },
  {
    key: 2,
    text: "Source training for release",
    value: "Source training for release"
  },
  { key: 3, text: "Training courses", value: "Training courses" },
  { key: 4, text: "Employment", value: "Employment" },
  { key: 5, text: "Other", value: "Other" }
];

const victimsOfDomesticAbuse = [
  {
    key: 0,
    text: "Signpost to specialist agency",
    value: "Signpost to specialist agency"
  },
  { key: 1, text: "Other", value: "Other" }
];

const sexWorker = [
  {
    key: 0,
    text: "Signpost to specialist agency",
    value: "Signpost to specialist agency"
  },
  { key: 1, text: "Other", value: "Other" }
];

export const dropdownOptions = {
  areaOfNeedOptions,
  actionStatusOptions,
  outcomeOfAreaOfNeedOptions,
  accommodation,
  finance,
  employmentEducationAndTraining,
  victimsOfDomesticAbuse,
  sexWorker
};
