import React from "react";
import ModifyOrganisation from "../../components/ModifyOrganisation";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources/Strings";
import { organisationActions } from "../../store/actions/organisation.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { addressActions } from "../../../App/store/actions/address.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { authActions } from "../../../App/store/actions/auth.actions";
import { auditActions } from "../../../Audit/store/actions/audit.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";
import {
  convertToIso,
  allowNumericalInput,
  incrementObject,
  handleLogout
} from "../../helpers/index";
class OrganisationEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableOrganisationModalOpen: false,
    deleteModalOpen: false,
    initialOrganisationData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    contactDeletionId: undefined,
    deletionReason: undefined,
    fetchedPermissionStatus: false,
    permissionStatus: "",
    postcodeInputValues: {
      postcode: "",
      libPostcode: "",
      mailingPostcode: "",
      additionalPostcode: ""
    },
    postcodeOpenStates: {
      postcode: false,
      libPostcode: false,
      mailingPostcode: false,
      additionalPostcode: false,
      showAuditLog: false
    }
  };

  componentDidMount = async () => {
    try {
      await this.props.getOrganisationById(
        this.props.match.params.id,
        this.props.headers
      );
      if (!incrementObject(this.props.match.params.id)) {
        this.props.lockUser(this.props.header);
        handleLogout(
          this.props.userId,
          this.props.username,
          this.props.headers,
          this.props.createAudit,
          this.props.logout
        );
      }
      if (
        this.state.mode === "view" &&
        this.props.selectedOrganisationData.requestId
      ) {
        await this.props.getDeletePermissionStatus(
          this.props.selectedOrganisationData.requestId
        );
        await this.setState(
          { permissionStatus: this.props.permissionStatus },
          async () => {
            if (this.props.permissionStatus === strings.states.rejected) {
              await this.props.updateSelectedOrganisation("requestId", null);
              this.props.updateOrganisation(
                this.props.selectedOrganisationData.organisationId,
                this.props.selectedOrganisationData,
                this.props.headers,
                this.state.initialOrganisationData,
                false
              );
            }
          }
        );
        this.props.clearAmendmentRequest();
      }
    } catch (ex) {
      throw ex;
    } finally {
      this.setState({
        fetchedPermissionStatus: true,
        initialOrganisationData: this.props.selectedOrganisationData
      });
      let form = document.getElementById("engagementForm");
      if (form) {
        form.setAttribute("novalidate", true);
        //set the form to have the novalidate attribute to suppress the default html validation tooltips
      }
    }
  };

  componentWillUnmount() {
    this.props.clearOrganisationRequest();
    this.props.clearSelectedOrganisation();
    this.props.clearAmendmentRequest();
  }

  validateForm = async () => {
    let organisationConstraints = {
      ...twgConstraints.organisations.modifyOrganisation
    };
    let organisationContactConstraints = {
      ...twgConstraints.organisations.organisationContact
    };
    if (
      this.props.selectedOrganisationData.mainType !==
      strings.fieldValues.employer
    ) {
      organisationConstraints.sicCode = undefined;
    }
    let details = JSON.parse(
      JSON.stringify(this.props.selectedOrganisationData)
    );
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(
      details,
      organisationConstraints
    );
    let contactResults = [];
    if (this.props.selectedOrganisationData.contacts) {
      if (
        this.props.selectedOrganisationData.contacts.length > 1 ||
        this.props.selectedOrganisationData.contacts[0].contactName ||
        this.props.selectedOrganisationData.contacts[0].contactEmail ||
        this.props.selectedOrganisationData.contacts[0].contactPhone
      ) {
        this.props.selectedOrganisationData.contacts.forEach(function (
          contact
        ) {
          let contactResult = twgValidationFunctions.validateData(
            contact,
            organisationContactConstraints
          );
          contactResult
            ? contactResults.push(contactResult)
            : contactResults.push({});
        });
      } else {
        this.props.updateSelectedOrganisation("contacts", undefined);
      }
    }
    results ? (results.contacts = []) : (results = { contacts: [] });
    let contactResultsAreEmpty = true;
    for (let i in contactResults) {
      //All results need to be added to preserve order
      results.contacts.push(contactResults[i]);
      if (Object.keys(contactResults[i]).length !== 0) {
        contactResultsAreEmpty = false;
      }
    }
    //Remove the contacts key if all contacts pass validation
    if (contactResultsAreEmpty) {
      delete results.contacts;
    }
    //There will be no keys in the object if the form passes validation
    if (Object.keys(results).length > 0) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  addContact = async e => {
    let contacts = this.props.selectedOrganisationData.contacts || [];
    contacts.push({
      contactName: "",
      contactPhone: "",
      contactEmail: ""
    });
    contacts.length > 1
      ? await this.props.updateSelectedOrganisation(
          this.getKeyByValue(
            this.props.selectedOrganisationData,
            this.props.selectedOrganisationData.contacts
          ),
          contacts
        )
      : await this.props.updateSelectedOrganisation("contacts", contacts);
  };

  removeContact = async () => {
    let contacts = this.props.selectedOrganisationData.contacts;
    if (contacts.length > 0) {
      contacts.splice(this.state.contactDeletionId, 1);
    }
    if (contacts.length === 0) {
      contacts = undefined;
    }
    await this.props.updateSelectedOrganisation(
      this.getKeyByValue(
        this.props.selectedOrganisationData,
        this.props.selectedOrganisationData.contacts
      ),
      contacts
    );
  };

  handleSubmit = async () => {
    await this.props.updateOrganisation(
      this.props.match.params.id,
      this.props.selectedOrganisationData,
      this.props.headers,
      this.state.initialOrganisationData
    );
    this.setState({
      initialOrganisationData: this.props.selectedOrganisationData
    });
    this.handleModeSwitch();

    window.setTimeout(() => {
      this.props.clearOrganisationRequest();
    }, 10000);
  };

  handleCancel = () => {
    this.props.history.goBack();
    this.setState({ formInvalid: false });
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedOrganisation(
        data.name,
        convertToIso(data.value)
      );
    } else if (data.name === "mainType") {
      this.props.updateSelectedOrganisation(data.name, data.value);
      if (
        data.value === strings.fieldValues.employer ||
        data.value === strings.fieldValues.supportService
      ) {
        this.props.updateSelectedOrganisation("localAuthorityArea", "");
        this.props.updateSelectedOrganisation("prisons", "");
      }
      if (data.value !== strings.fieldValues.employer) {
        this.props.updateSelectedOrganisation("sicCode", "");
        this.props.updateSelectedOrganisation("socCode", "");
        this.props.updateSelectedOrganisation("osCode", "");
      }
    } else {
      await this.props.updateSelectedOrganisation(data.name, data.value);
    }
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedOrganisationData.organisationId,
      this.state.deletionReason.value,
      "Organisation",
      this.props.selectedOrganisationData.organisationName,
      this.props.headers,
      this.props.selectedOrganisationData
    );
    this.props.updateSelectedOrganisation("requestId", this.props.requestId);
    this.props.updateOrganisation(
      this.props.selectedOrganisationData.organisationId,
      this.props.selectedOrganisationData,
      this.props.headers,
      this.state.initialOrganisationData
    );
    this.setState({
      initialOrganisationData: this.props.selectedOrganisationData
    });

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearOrganisationRequest();
    }, 10000);
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteOrganisation(
      this.props.selectedOrganisationData.organisationId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      await this.props.setAmendmentStatusActioned(
        this.props.selectedOrganisationData.requestId,
        this.props.headers,
        true,
        {
          entity: strings.amendments.entity,
          id: this.props.selectedOrganisationData.organisationId,
          type: strings.amendments.deletion
        }
      );

      this.props.history.push("/organisations");
    }

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearOrganisationRequest();
    }, 10000);
  };

  handleContactChange = async (event, data) => {
    let contacts = this.props.selectedOrganisationData.contacts;
    const result = data.name.split("-");
    contacts[result[1]][result[0]] = data.value;

    await this.props.updateSelectedOrganisation(
      this.getKeyByValue(
        this.props.selectedOrganisationData,
        this.props.selectedOrganisationData.contacts
      ),
      contacts
    );
  };

  handlePostcodeChanged = async (e, data, fieldName) => {
    this.setState({
      postcodeInputValues: {
        ...this.state.postcodeInputValues,
        [data.name]: data.searchQuery
      }
    });
  };

  handlePostcodeSearch = async (e, click) => {
    await this.props.findAddress(
      this.state.postcodeInputValues[click.name],
      click.name
    );
    this.setState({
      postcodeOpenStates: {
        ...this.state.postcodeOpenStates,
        [click.name]: this.props.addressSearchResults[click.name].length > 0
      }
    });
    this.setState({
      postcodeOpenStates: {
        ...this.state.postcodeOpenStates,
        [click.name]: undefined
      }
    });
  };

  handlePostcodeClicked = async (event, click) => {
    let selectedResult = click.options[click.value];
    this.setState({
      postcodeOpenStates: {
        ...this.state.postcodeOpenStates,
        [click.name]: false
      }
    });
    if (selectedResult) await this.props.retrieveAddress(selectedResult.id);
    // AWAIT and update organisation
    let data = {
      postcode: selectedResult ? this.props.formAddressResults.postcode : "",
      address1: selectedResult
        ? this.props.formAddressResults.addressLine1
        : "",
      address2: selectedResult ? this.props.formAddressResults.addressLine2 : ""
    };

    for (const key in data) {
      this.props.updateSelectedOrganisation(
        key,

        data[key]
      );
    }
  };

  getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedOrganisationData.active
    };

    await this.props.updateOrganisation(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialOrganisationData
    );
    this.setState({
      initialOrganisationData: this.props.selectedOrganisationData
    });
  };

  handleToggleAudit = () => {
    const isAudit = this.state.showAuditLog;
    this.setState({ showAuditLog: !isAudit });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "deletionContactModal":
        this.removeContact();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableOrganisationModalVisibility = () => {
    this.setState({
      enableDisableOrganisationModalOpen: !this.state
        .enableDisableOrganisationModalOpen
    });
  };

  toggleConfirmDeleteModalVisibility = idx => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      contactDeletionId: idx
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialOrganisationData) !==
        JSON.stringify(this.props.selectedOrganisationData)
      ) {
        await this.props.cancelSelectedOrganisationUpdate(
          this.state.initialOrganisationData
        );
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialOrganisationData:
          JSON.parse(JSON.stringify(this.props.selectedOrganisationData)) ||
          this.props.selectedOrganisationData
      });
    }
  };

  render = () => {
    const loading =
      this.props.loadingPage || !this.state.fetchedPermissionStatus;

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.requestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          {this.props.showNotification && (
            <RequestFeedback
              className={
                this.props.amendmentRequestStatus ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.organisationRequestStatus}
              requestMade={this.props.organisationRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.organisationRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}

          <Divider hidden />

          <ModifyOrganisation
            requestStatus={this.props.organisationRequestStatus}
            mode={this.state.mode}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editOrganisation
                : strings.header.viewOrganisation
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnableDisableOrganisationModalVisibility={
              this.toggleEnableDisableOrganisationModalVisibility
            }
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            selectedOrganisationData={this.props.selectedOrganisationData}
            handleChange={this.handleChange}
            handleContactChange={this.handleContactChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            deleteModalOpen={this.state.deleteModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enableDisableOrganisationModalOpen={
              this.state.enableDisableOrganisationModalOpen
            }
            initialOrganisationData={this.state.initialOrganisationData}
            formInvalid={this.state.formInvalid}
            allowNumericalInput={allowNumericalInput}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            cancelSelectedOrganisationUpdate={
              this.props.cancelSelectedOrganisationUpdate
            }
            addContact={this.addContact}
            removeContact={this.removeContact}
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            handlePostcodeChanged={this.handlePostcodeChanged}
            handlePostcodeClicked={this.handlePostcodeClicked}
            addressSearchResults={this.props.addressSearchResults}
            addressRetrieved={this.props.addressRetrieved}
            handlePostcodeSearch={this.handlePostcodeSearch}
            postcodeOpenStates={this.state.postcodeOpenStates}
            headers={this.props.headers}
            handleToggleAudit={this.handleToggleAudit}
            showAuditLog={this.state.showAuditLog}
          />
        </div>
      </div>
    );
  };
}

OrganisationEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedOrganisationData: PropTypes.object.isRequired,
  updateSelectedOrganisation: PropTypes.func.isRequired,
  updateOrganisation: PropTypes.func.isRequired,
  getOrganisationById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { organisations, amendments, address, auth } = state;
  const {
    loadingPage,
    selectedOrganisationData,
    organisationRequestStatus,
    organisationRequestMade,
    result,
    error,
    errorDetails,
    deleteSuccessful,
    showNotification
  } = organisations;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails
  } = amendments;
  const { userId, username } = auth;
  const {
    addressSearchResults,
    formAddressResults,
    addressRetrieved
  } = address;
  return {
    loadingPage,
    selectedOrganisationData,
    organisationRequestStatus,
    organisationRequestMade,
    result,
    error,
    errorDetails,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    addressSearchResults,
    formAddressResults,
    addressRetrieved,
    amendmentRequestStatus,
    amendmentRequestMade,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails,
    userId,
    username
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOrganisation: (
      id,
      data,
      headers,
      initialOrganisationData,
      showNotification = true
    ) => {
      dispatch(
        organisationActions.updateOrganisation(
          id,
          data,
          headers,
          initialOrganisationData,
          showNotification
        )
      );
    },
    getOrganisationById: async (id, headers) => {
      await dispatch(organisationActions.getOrganisationById(id, headers));
    },
    updateSelectedOrganisation: (key, value) => {
      dispatch(organisationActions.updateSelectedOrganisation(key, value));
    },
    updateSelectedOrganisationContact: (key, value) => {
      dispatch(
        organisationActions.updateSelectedOrganisationContact(key, value)
      );
    },
    cancelSelectedOrganisationUpdate: data => {
      dispatch(organisationActions.cancelSelectedOrganisationUpdate(data));
    },
    clearOrganisationRequest: () => {
      dispatch(organisationActions.clearOrganisationRequest());
    },
    clearSelectedOrganisation: () => {
      dispatch(organisationActions.clearSelectedOrganisation());
    },
    requestDeletePermission: async (
      id,
      reason,
      entity,
      recordIdentifier,
      headers,
      body
    ) => {
      await dispatch(
        amendmentActions.requestDeletePermission(
          id,
          reason,
          entity,
          recordIdentifier,
          headers,
          body
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteOrganisation: async (id, headers) => {
      await dispatch(organisationActions.deleteOrganisation(id, headers));
    },
    setAmendmentStatusActioned: async (
      id,
      headers,
      sendNotification,
      entityDetails
    ) => {
      await dispatch(
        amendmentActions.setAmendmentStatusActioned(
          id,
          headers,
          sendNotification,
          entityDetails
        )
      );
    },
    findAddress: async (value, fieldName) => {
      await dispatch(addressActions.findAddress(value, fieldName));
    },
    retrieveAddress: async id => {
      await dispatch(addressActions.retrieveAddress(id));
    },
    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    lockUser: headers => {
      dispatch(userActions.lockUser(headers));
    },
    logout: hasExceeded => {
      dispatch(authActions.signOut(hasExceeded));
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationEditContainer);
