import React from "react";
import ReactTable from "react-table";
import { TableFunctions } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";
import moment from "moment";
import { dropdownHelper } from "../helpers/index";
import { getUsers } from "../helpers/index";

class TableComponent extends React.Component {
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      return {
        onClick: () => {
          if (rowInfo?.original?.programmeId) {
            this.props.history.push(
              "/programmes/view/" + rowInfo.original.programmeId
            );
          } else if (rowInfo?.original?.ttgProgrammeId) {
            this.props.history.push(
              "/ttgProgrammes/view/" + rowInfo.original.ttgProgrammeId
            );
          }
        }
      };
    }
    return {}; // getTdProps requires a return statement
  };

  mapSearchResultsToRows = () => {
    const { caseloadListData } = this.props;
    let data = [];
    if (caseloadListData) {
      caseloadListData.forEach((result, index) => {
        if (result["_source"]) {
          // React Table does not show numeric zero, so convert to string
          if (result["_source"].daysSinceLastContact === 0) {
            result["_source"].daysSinceLastContact =
              result["_source"].daysSinceLastContact.toString();
          }
          data.push(result["_source"]);
        }
      });
    }
    return data;
  };

  render = () => {
    let data = this.mapSearchResultsToRows();
    let pages = Math.ceil(this.props.totalResults / this.props.pageSize);
    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };

    const columns = [
      {
        id: "specialist",
        Header: strings.table.header.specialist,
        accessor: caseload => {
          let specialist;
          switch (caseload.area) {
            case strings.areas.NRS:
              specialist = caseload.communityMentor;
              break;
            case strings.areas.NRNE:
              specialist = caseload.mentor;
              break;
            case strings.areas.TTG:
              specialist = caseload.cjSpecialist;
              break;
            case undefined:
            default:
              specialist = "";
              break;
          }
          return (
            dropdownHelper.getNameFromValues(
              specialist,
              getUsers(this.props.userList)
            ) || ""
          );
        },
        show: this.props.roleId !== strings.fieldValues.mentor,
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.surname,
        accessor: "surname",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.firstName,
        accessor: "firstName",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.pNumber,
        accessor: "pNumber",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.prison,
        accessor: "prison",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.journeyStatus,
        accessor: "journeyStatus",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "dateOfEngagementSuccessful",
        Header: strings.table.header.dateOfEngagementSuccessful,
        accessor: caseload =>
          caseload.dateOfEngagementSuccessful
            ? moment(caseload.dateOfEngagementSuccessful)
                .local()
                .format("DD/MM/YYYY")
            : "",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.daysSinceLastContact,
        accessor: "daysSinceLastContact",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20),
        getProps: (state, rowInfo, column) => {
          return rowInfo
            ? {
                style: {
                  color: rowInfo.original.color
                }
              }
            : "";
        },
        sortable: false
      }
    ];
    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          handleRefresh={this.props.handleRefresh}
          headerList={strings.header.caseloadList}
          buttonAddLabel={strings.button.addCaseloadLabel}
          permissionsCreate={[]}
          headerClass="pageHeader"
          refreshButtonClass="refreshButton"
          searchBarQuery={this.props.searchBarQuery}
          handleChange={this.props.handleChange}
          handleSubmit={this.props.handleSubmit}
          hideCreate={true}
          parentPage={this.props.parentPage}
          returnToParent={this.props.returnToParent}
        />
        <div>
          <ReactTable
            manual
            data={data}
            pages={pages}
            defaultPageSize={this.props.pageSize}
            showPagination={true}
            getTdProps={this.getTdProps}
            onFetchData={(state, instance) => {
              this.props.handleFetchData(state);
              this.props.updatePageIndex(state);
            }}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText={strings.table.text.noResults}
            previousText="<"
            nextText=">"
            pageText=""
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  handleModalConfirm: PropTypes.func.isRequired,
  caseloadListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  updatePageIndex: PropTypes.func.isRequired,
  isCreateDisabled: PropTypes.bool.isRequired
};

export default TableComponent;
