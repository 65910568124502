import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import { ValidatedFormDropdown, ValidatedFormInput } from "../validation";
import "../css/ProgrammeComponents.scss";
import { yesNoOptions } from "../../resources";

const AddictionsFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">{strings.form.header.addictionsHeader}</div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hasAddiction}
          viewData={props.selectedProgrammeData.hasAddiction}
          dropdownLabel={strings.form.label.hasAddiction}
          dropdownName="hasAddiction"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.hasAddiction}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.hasAddiction}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.takingPrescribedMedication}
          viewData={props.selectedProgrammeData.takingPrescribedMedication}
          dropdownLabel={strings.form.label.takingPrescribedMedication}
          dropdownName="takingPrescribedMedication"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={
            strings.form.placeholder.takingPrescribedMedication
          }
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.takingPrescribedMedication}
          required={false}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.medicationDetails}
          inputLabel={strings.form.label.medicationDetails}
          inputName="medicationDetails"
          inputPlaceholder={strings.form.placeholder.medicationDetails}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.medicationDetails}
          required={false}
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.whatMedication}
          inputLabel={strings.form.label.whatMedication}
          inputName="whatMedication"
          inputPlaceholder={strings.form.placeholder.whatMedication}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.whatMedication}
          required={false}
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hasAddictionAffectedOffending}
          viewData={props.selectedProgrammeData.hasAddictionAffectedOffending}
          dropdownLabel={strings.form.label.hasAddictionAffectedOffending}
          dropdownName="hasAddictionAffectedOffending"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={
            strings.form.placeholder.hasAddictionAffectedOffending
          }
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={
            props.validationResults.hasAddictionAffectedOffending
          }
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.medicationQuantity}
          inputLabel={strings.form.label.medicationQuantity}
          inputName="medicationQuantity"
          inputPlaceholder={strings.form.placeholder.medicationQuantity}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.medicationQuantity}
          required={false}
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.inReceiptOfSupport}
          inputLabel={strings.form.label.inReceiptOfSupport}
          inputName="inReceiptOfSupport"
          inputPlaceholder={strings.form.placeholder.inReceiptOfSupport}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.inReceiptOfSupport}
          required={false}
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.keyWorkerName}
          inputLabel={strings.form.label.keyWorkerName}
          inputName="keyWorkerName"
          inputPlaceholder={strings.form.placeholder.keyWorkerName}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.keyWorkerName}
          required={false}
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
      </Form.Group>
    </div>
  </div>
);

AddictionsFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default AddictionsFields;
