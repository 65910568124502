import axios from "./axios-settings";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  updateSetting = details => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(``, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating setting: ", error.message);
          reject(error);
        });
    });
  };

  getSettings = isLoggingIn => {
    const config = {
      headers: this.headers,
      params: { isLoggingIn: isLoggingIn }
    };
    return new Promise((resolve, reject) => {
      axios
        .get(``, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting setting: ", error.message);
          reject(error);
        });
    });
  };
  checkSettingMatchesValue = (value, id) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`/check/${id}`, value, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error checking setting: ", error.message);
          reject(error);
        });
    });
  };
}
