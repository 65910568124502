import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      settings: "Global Settings",
      settingList: "Settings",
      editSettings: "Edit Settings",
      viewSettings: "View Settings"
    },
    modal: {
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?"
    },
    feedback: {
      info: {
        noSettingChanges: "No more settings left to confirm"
      }
    },
    form: {
      header: {
        settingInfo: "Settings"
      },
      label: {
        ttgPagesVisited: "Pages visited limit (TTG)",
        ttgDurationVisited: "Limit of visit duration (TTG)",
        nrsPagesVisited: "Pages visited limit (NRS)",
        nrsDurationVisited: "Limit of visit duration (NRS)",
        nrnePagesVisited: "Pages visited limit (NRNE)",
        nrneDurationVisited: "Limit of visit duration (NRNE)",
        feedbackPassword: "Password for feedback form lock"
      },
      placeholder: {
        ttgPagesVisited: "",
        ttgDurationVisited: "",
        nrsPagesVisited: "",
        nrsDurationVisited: "",
        nrnePagesVisited: "",
        nrneDurationVisited: "",
        feedbackPassword: ""
      },
      feedback: {
        processing: "Processing request...",
        settingRequestSuccess: "Setting created successfully!",
        settingRequestRefreshPrompt:
          "Use the refresh function to see an updated settings list.",
        settingRequestFailure: "Setting creation failed.",
        settingRequestUnknown: "Something went wrong with the Setting request.",
        status: "Status",
        success: "Updated",
        failure: "Failure"
      }
    },
    button: {
      editLabel: "Edit",
      cancelLabel: "Cancel",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      submitLabel: "Submit"
    }
  }
});
