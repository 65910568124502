import React from "react";
import { strings } from ".";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import { taskTitleMappings } from "../resources/TaskTitleMappings";

const cellTruncate = length => {
  return row => {
    let value = row.value ? row.value : "";
    let truncatedValue =
      value.length > length ? (
        <div>{value.substring(0, length)}...</div>
      ) : (
        <div>{value}</div>
      );
    return truncatedValue;
  };
};

export const ttgEngagementColumns = [
  {
    Header: strings.table.header.firstName,
    accessor: "customerForename",
    headerStyle: { textAlign: "left" },
    Cell: e => (
      <a
        className="hyperlink"
        href={"/#/ttgProgrammes/view/" + e.row._original.linkedRecordId}
      >
        {e.value}
      </a>
    )
  },
  {
    Header: strings.table.header.surname,
    accessor: "customerSurname",
    headerStyle: { textAlign: "left" },
    Cell: cellTruncate(20)
  },
  {
    Header: strings.table.header.pNumber,
    accessor: "prisonNumber",
    headerStyle: { textAlign: "left" },
    Cell: cellTruncate(20)
  },
  {
    Header: strings.table.header.risk,
    accessor: "risk",
    headerStyle: { textAlign: "left" },
    Cell: cellTruncate(20)
  },
  {
    id: "created", //we need an id for this because the accessor is not a string
    Header: strings.table.header.createdDate,
    accessor: task => moment(task.created).local().format("DD/MM/YYYY, HH:mm"),
    headerStyle: { textAlign: "left" }
  },
  {
    Header: strings.table.header.dueDate,
    accessor: "dueDate",
    headerStyle: { textAlign: "left" },
    Cell: cellTruncate(20),
    getProps: (state, rowInfo, column) => {
      return rowInfo
        ? {
            style: {
              color: rowInfo.original.color
            }
          }
        : "";
    }
  },
  {
    id: "active",
    Header: strings.table.header.active,
    accessor: task => {
      if (!task.cleared) {
        return <Icon name="circle" color="green" />;
      } else {
        return <Icon name="circle outline" color="red" />;
      }
    },
    sortable: false,
    headerStyle: { textAlign: "left" },
    width: 60
  }
];

export const ttgFollowUpColumns = [
  {
    Header: strings.table.header.firstName,
    accessor: "customerForename",
    headerStyle: { textAlign: "left" },
    Cell: e => (
      <a
        className="hyperlink"
        href={"/#/ttgProgrammes/view/" + e.row._original.linkedRecordId}
      >
        {e.value}
      </a>
    )
  },
  {
    Header: strings.table.header.surname,
    accessor: "customerSurname",
    headerStyle: { textAlign: "left" },
    Cell: cellTruncate(20)
  },
  {
    id: "type",
    Header: strings.table.header.type,
    accessor: task => {
      return taskTitleMappings[task.type];
    },
    headerStyle: { textAlign: "left" },
    Cell: cellTruncate(20)
  },
  {
    Header: strings.table.header.dueDate,
    accessor: "dueDate",
    headerStyle: { textAlign: "left" },
    Cell: cellTruncate(20),
    getProps: (state, rowInfo, column) => {
      return rowInfo
        ? {
            style: {
              color: rowInfo.original.color
            }
          }
        : "";
    }
  },
  {
    id: "active",
    Header: strings.table.header.active,
    accessor: task => {
      if (!task.cleared) {
        return <Icon name="circle" color="green" />;
      } else {
        return <Icon name="circle outline" color="red" />;
      }
    },
    sortable: false,
    headerStyle: { textAlign: "left" },
    width: 60
  }
];
