import { taskActionTypes } from "../actions/task.actionTypes";
import { differenceInWorkingDays } from "../../helpers";
import { strings } from "../../resources/Strings";

import {
  colors,
  emptyTaskListDataByType,
  emptyTaskListDataByDate
} from "../../resources/TaskData";
import moment from "moment";

const initialState = {
  loadingPage: false,
  selectedTaskData: {},
  taskListData: [{}],
  totalResults: 0,
  taskToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  taskRequestStatus: undefined,
  taskRequestMade: false,
  taskListDataByType: emptyTaskListDataByType,
  taskListDataByDate: emptyTaskListDataByDate,
  taskDataSelected: {
    type: "",
    filter: ""
  },
  showNotification: false,
  createTaskMessage: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case taskActionTypes.CREATE_TASK_REQUEST:
      return {
        ...state,
        taskRequestStatus: undefined,
        taskRequestMade: true,
        createTaskMessage: ""
      };
    case taskActionTypes.CREATE_TASK_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        taskRequestStatus: true,
        showNotification: action.showNotification,
        createTaskMessage: action.createTaskMessage
      };
    case taskActionTypes.CREATE_TASK_FAILURE:
      let details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        taskRequestStatus: false,
        showNotification: action.showNotification,
        createTaskMessage: action.createTaskMessage
      };

    case taskActionTypes.CLEAR_CREATE_TASK_REQUEST:
      return { ...state };
    case taskActionTypes.CLEAR_CREATE_TASK_SUCCESS:
      return {
        ...state,
        taskRequestMade: false,
        taskRequestStatus: undefined
      };
    case taskActionTypes.CLEAR_CREATE_TASK_FAILURE:
      return { ...state };

    case taskActionTypes.UPDATE_TASK_REQUEST:
      return {
        ...state,
        loadingPage: true,
        taskRequestStatus: undefined,
        taskRequestMade: true
      };
    case taskActionTypes.UPDATE_TASK_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        taskRequestStatus: true,
        selectedTaskData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case taskActionTypes.UPDATE_TASK_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        taskRequestStatus: false,
        selectedTaskData: action.initialDetails,
        errorDetails: details,
        showNotification: action.showNotification
      };

    case taskActionTypes.DELETE_TASK_REQUEST:
      return {
        ...state,
        taskRequestStatus: undefined,
        taskRequestMade: true,
        loadingPage: true
      };
    case taskActionTypes.DELETE_TASK_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        taskRequestStatus: true,
        loadingPage: false,
        showNotification: action.showNotification
      };
    case taskActionTypes.DELETE_TASK_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Status,
        errorDetails: details,
        taskRequestStatus: false,
        loadingPage: false,
        showNotification: action.showNotification
      };

    case taskActionTypes.GET_TASKS_REQUEST:
      return {
        ...state,
        loadingPage: true
      };
    case taskActionTypes.GET_TASKS_SUCCESS:
      let rawHits = [];
      let newTotal = 0;
      if (action.response.data.Data) {
        newTotal = action.response.data.Data.total;
        rawHits = action.response.data.Data.tasks;
      } else {
        rawHits = [];
      }

      let newTaskListDataByType = JSON.parse(
        JSON.stringify(emptyTaskListDataByType)
      );
      let newTaskListDataByDate = JSON.parse(
        JSON.stringify(emptyTaskListDataByDate)
      );

      let today = new Date(moment().local().format("MM/DD/YYYY"));
      let shouldRemoveTask = false;
      let tasksToRemove = [];
      rawHits.forEach((task, index) => {
        const dueDateFormatted = moment(task.dueDate).format("MM/DD/YYYY");
        const dueDateString = moment(task.dueDate).format("DD/MM/YYYY");

        let dueDate = new Date(dueDateFormatted.toString());
        const isOverdue = dueDate - today > 0 ? false : true;
        const diffDays = differenceInWorkingDays(today, dueDate);
        task.dueDate = dueDateString;
        if (newTaskListDataByType[task.type]) {
          newTaskListDataByType[task.type].push(task);
        }
        if (
          task.type === "ttgEngagementFollowup" ||
          strings.tasks.ttgNeedFollowUpTask.includes(task.type)
        ) {
          if (diffDays >= 5 && !isOverdue && diffDays <= 10) {
            task.color = colors.green;
            newTaskListDataByDate[task.type].due.push(task);
          } else if (diffDays > 0 && diffDays < 5 && !isOverdue) {
            task.color = colors.orange;
            newTaskListDataByDate[task.type].dueSoon.push(task);
          } else if (isOverdue || diffDays < 1) {
            task.color = colors.red;
            newTaskListDataByDate[task.type].dueVerySoon.push(task);
          } else {
            shouldRemoveTask = true;
            tasksToRemove.push(index);
          }
        } else {
          const diffTime = Math.abs(dueDate - today);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          if (newTaskListDataByDate[task.type]) {
            if (diffDays > 3 && !isOverdue) {
              task.color = colors.green;
              newTaskListDataByDate[task.type].due.push(task);
            } else if (diffDays === 3) {
              task.color = colors.orange;
              newTaskListDataByDate[task.type].dueSoon.push(task);
            } else if (isOverdue || diffDays < 3) {
              task.color = colors.red;
              newTaskListDataByDate[task.type].dueVerySoon.push(task);
            }
          }
        }
      });
      if (shouldRemoveTask) {
        rawHits = rawHits.filter((hit, index) => {
          return !tasksToRemove.includes(index);
        });
      }
      return {
        ...state,
        taskListData: rawHits,
        totalResults: newTotal,
        loadingPage: false,
        taskListDataByType: newTaskListDataByType,
        taskListDataByDate: newTaskListDataByDate
      };
    case taskActionTypes.GET_TASKS_FAILURE:
      return {
        ...state,
        taskListData: [{}],
        loadingPage: false
      };

    case taskActionTypes.GET_TASK_BY_ID_REQUEST:
      return {
        ...state,
        taskRequestStatus: undefined,
        loadingPage: true
      };
    case taskActionTypes.GET_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        selectedTaskData: action.response.Data,
        taskRequestStatus: true,
        loadingPage: false,
        showNotification: false
      };
    case taskActionTypes.GET_TASK_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        selectedTaskData: {},
        taskRequestStatus: false,
        taskRequestMade: true,
        loadingPage: false,
        showNotification: action.showNotification
      };

    case taskActionTypes.UPDATE_SELECTED_TASK_REQUEST:
      return { ...state };
    case taskActionTypes.UPDATE_SELECTED_TASK_SUCCESS:
      return {
        ...state,
        selectedTaskData: {
          ...state.selectedTaskData,
          [action.key]: action.value
        }
      };
    case taskActionTypes.UPDATE_SELECTED_TASK_FAILURE:
      return { ...state };

    case taskActionTypes.CLEAR_SELECTED_TASK_REQUEST:
      return { ...state };
    case taskActionTypes.CLEAR_SELECTED_TASK_SUCCESS:
      return { ...state, selectedTaskData: {} };
    case taskActionTypes.CLEAR_SELECTED_TASK_FAILURE:
      return { ...state };

    case taskActionTypes.CANCEL_SELECTED_TASK_UPDATE_REQUEST:
      return { ...state };
    case taskActionTypes.CANCEL_SELECTED_TASK_UPDATE_SUCCESS:
      return { ...state, selectedTaskData: action.data };
    case taskActionTypes.CANCEL_SELECTED_TASK_UPDATE_FAILURE:
      return { ...state };

    case taskActionTypes.SET_TASK_DATA_SELECTED:
      return { ...state, taskDataSelected: action.data };

    case taskActionTypes.CLEAR_CREATE_TASK:
      return {
        ...state,
        createTaskMessage: "",
        taskRequestStatus: undefined,
        taskRequestMade: false
      };

    default:
      return state;
  }
};
