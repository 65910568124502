export const userRoleOptions = [
  { key: 1, text: "Pulsion", value: "pulsion" },
  { key: 2, text: "Mentor", value: "mentor" },
  { key: 3, text: "NR Team Lead", value: "teamLead" },
  { key: 4, text: "NR Manager", value: "manager" },
  { key: 5, text: "NR Manager Partner", value: "managerPartner", area: "NRS" },
  { key: 6, text: "NR Specialist Support Partner", value: "ssp", area: "NRS" },
  { key: 7, text: "NR Head of Operations", value: "hoo" },
  { key: 8, text: "NR Assurance", value: "assurance" },
  { key: 9, text: "NR ICT", value: "ict" },
  { key: 10, text: "NR Support", value: "support" },
  { key: 11, text: "Prison Based Mentor", value: "prisonBasedMentor" }
];

export const areaOptions = [
  { key: 1, text: "NRS", value: "NRS" },
  { key: 2, text: "NRNE", value: "NRNE" },
  { key: 3, text: "TTG", value: "TTG" },
  { key: 4, text: "North East", value: "NE" },
  { key: 5, text: "All Areas", value: "ALL" }
];

export const areaOptionsMentor = [
  { key: 1, text: "NRS", value: "NRS" },
  { key: 2, text: "NRNE", value: "NRNE" },
  { key: 3, text: "TTG", value: "TTG" }
];
