import React from "react";
import ModifyAssessmentSG from "../../components/ModifyAssessmentSG";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, fieldsToStringify } from "../../resources";
import { assessmentSGActions } from "../../store/actions/assessmentSG.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import PropTypes from "prop-types";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  convertToIso,
  getOrganisations,
  canUserModifyRecord
} from "../../helpers";
import uuidv4 from "uuid/v4";

class AssessmentSGCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableAssessmentSGModalOpen: false,
    formInvalid: false,
    validationResults: {},
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    prePopulated: false
  };

  componentDidMount = async () => {
    await this.handleFetchData();
    this.props.clearSelectedProgramme();
    let form = document.getElementById("assessmentSGForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    await this.props.getProgrammeById(
      this.props.programmeId,
      this.props.headers
    );
  };

  componentDidUpdate = () => {
    if (
      Object.keys(this.props.selectedProgrammeData).length > 0 &&
      !this.state.prePopulated
    ) {
      if (this.props.selectedProgrammeData.owner) {
        this.props.updateSelectedAssessmentSG(
          "owner",
          this.props.selectedProgrammeData.owner
        );
      }
      if (this.props.selectedProgrammeData.programmeId) {
        this.props.updateSelectedAssessmentSG(
          "programmeId",
          this.props.selectedProgrammeData.programmeId
        );
      }
      if (this.props.selectedProgrammeData.mentor) {
        this.props.updateSelectedAssessmentSG(
          "specialist",
          this.props.selectedProgrammeData.mentor
        );
      } else if (this.props.selectedProgrammeData.communityMentor) {
        this.props.updateSelectedAssessmentSG(
          "specialist",
          this.props.selectedProgrammeData.communityMentor
        );
      }
      if (this.props.selectedProgrammeData.scroNumber) {
        this.props.updateSelectedAssessmentSG(
          "sNumber",
          this.props.selectedProgrammeData.scroNumber
        );
      }
      if (this.props.selectedProgrammeData.area) {
        this.props.updateSelectedAssessmentSG(
          "area",
          this.props.selectedProgrammeData.area
        );
      }
      if (this.props.selectedProgrammeData.pNumber) {
        this.props.updateSelectedAssessmentSG(
          "pNumber",
          this.props.selectedProgrammeData.pNumber
        );
      }
      if (
        this.props.selectedProgrammeData.firstName &&
        this.props.selectedProgrammeData.surname
      ) {
        this.props.updateSelectedAssessmentSG(
          "customerName",
          this.props.selectedProgrammeData.firstName +
            " " +
            this.props.selectedProgrammeData.surname
        );
      }
      if (this.props.selectedProgrammeData.crmId) {
        this.props.updateSelectedAssessmentSG(
          "programmeCrmId",
          this.props.selectedProgrammeData.crmId
        );
      }
      this.props.updateSelectedAssessmentSG("type", "SGA");
      this.setState({ prePopulated: true });
    }
  };

  componentWillUnmount() {
    this.props.clearAssessmentSGRequest();
    this.props.clearSelectedAssessmentSG();
  }

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );

    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      true
    );
  };

  validateForm = async () => {
    let details = JSON.parse(
      JSON.stringify(this.props.selectedAssessmentSGData)
    );
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.assessments.modifySGA
    );

    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.props.updateSelectedAssessmentSG("assessmentId", uuidv4());
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  stringifyFields = async () => {
    return new Promise((resolve, reject) => {
      for (const field of Object.keys(this.props.selectedAssessmentSGData)) {
        if (fieldsToStringify.includes(field)) {
            this.props.updateSelectedAssessmentSG(
            field,
            JSON.stringify(this.props.selectedAssessmentSGData[field])
          );
        }
      }
      resolve()
    })
  }

  handleSubmit = async () => {
    await this.stringifyFields()
    await this.props.createAssessmentSG(
      this.props.selectedAssessmentSGData,
      this.props.headers
    );
    window.location.reload();
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearAssessmentSGRequest();
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedAssessmentSG(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedAssessmentSG(data.name, data.value);
    }
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableAssessmentSGModalVisibility = () => {
    this.setState({
      enableDisableAssessmentSGModalOpen: !this.state
        .enableDisableAssessmentSGModalOpen
    });
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.usersLoadingPage ||
      this.props.programmeLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <ModifyAssessmentSG
            mode="create"
            role={this.props.roleId}
            pageTitle={strings.header.createAssessmentSG}
            selectedAssessmentSGData={this.props.selectedAssessmentSGData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            enableDisableAssessmentSGModalOpen={
              this.state.enableDisableAssessmentSGModalOpen
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableAssessmentSGModalVisibility={
              this.toggleEnableDisableAssessmentSGModalVisibility
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            initialAssessmentSGData={{}}
            getOrganisations={this.getOrganisations}
            userListData={this.props.userListData}
            updateSelectedAssessmentSG={this.props.updateSelectedAssessmentSG}
            isDisabled={this.isDisabled()}
          />
        </div>
      </div>
    );
  };
}

AssessmentSGCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedAssessmentSGData: PropTypes.object.isRequired,
  updateSelectedAssessmentSG: PropTypes.func.isRequired,
  createAssessmentSG: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { loadingPage, selectedAssessmentSGData } = state.assessmentSG;
  const { roleId, organisationOfLoggedInUser } = state.auth;
  const {
    organisationListData,
    loadingPage: orgsLoadingPage
  } = state.organisations;
  const { userListData, loadingPage: usersLoadingPage } = state.users;
  const {
    selectedProgrammeData,
    programmeRequestStatus,
    loadingPage: programmeLoadingPage
  } = state.programmes;

  return {
    loadingPage,
    selectedAssessmentSGData,
    roleId,
    organisationListData,
    userListData,
    selectedProgrammeData,
    programmeRequestStatus,
    organisationOfLoggedInUser,
    programmeLoadingPage,
    orgsLoadingPage,
    usersLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createAssessmentSG: async (data, headers) => {
      await dispatch(assessmentSGActions.createAssessmentSG(data, headers));
    },
    updateSelectedAssessmentSG: async (key, value) => {
      await dispatch(assessmentSGActions.updateSelectedAssessmentSG(key, value));
    },
    clearAssessmentSGRequest: () => {
      dispatch(assessmentSGActions.clearAssessmentSGRequest());
    },
    clearSelectedAssessmentSG: () => {
      dispatch(assessmentSGActions.clearSelectedAssessmentSG());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getUsers: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentSGCreateContainer);
