import React from "react";
import ReactTable from "react-table";
import { BasicModal, TableFunctions } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import { permissions } from "../../App/resources";
import { userRoleOptions } from "../resources/DropdownOptions";

class TableComponent extends React.Component {
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      if (column.id !== "delete" && column.id !== "active") {
        return {
          onClick: () => {
            if (rowInfo) {
              this.props.history.push("/users/view/" + rowInfo.original.userId);
            }
          }
        };
      }
    }
    return {}; // getTdProps requires a return statement
  };

  mapSearchResultsToRows = () => {
    const { userListData } = this.props;
    let data = [];
    if (userListData) {
      userListData.forEach((result, index) => {
        if (result["_source"]) {
          data.push(result["_source"]);
        }
      });
    }
    return data;
  };

  render = () => {
    let data = this.mapSearchResultsToRows();
    let pages = Math.ceil(this.props.totalResults / this.props.pageSize);
    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };
    const columns = [
      {
        Header: strings.table.header.userName,
        accessor: "username",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.firstName,
        accessor: "firstName",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.surname,
        accessor: "surname",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40)
      },
      {
        Header: strings.table.header.email,
        accessor: "email",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40)
      },
      {
        id: "organisationId",
        Header: strings.table.header.organisation,
        accessor: user => {
          if (
            Object.entries(user).length > 0 &&
            this.props.organisationList.length > 0
          ) {
            let matchedOrg = this.props.organisationList.find(
              org => user.organisationId === org.value
            )
              ? this.props.organisationList.find(
                  org => user.organisationId === org.value
                ).text
              : "";
            return matchedOrg;
          } else {
            return "";
          }
        },
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40)
      },
      {
        id: "roleId",
        Header: strings.table.header.role,
        accessor: user => {
          if (Object.entries(user).length > 0) {
            let userRole = userRoleOptions.find(
              role => user.roleId === role.value
            )
              ? userRoleOptions.find(role => user.roleId === role.value).text
              : "";
            return userRole;
          } else {
            return "";
          }
        },
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "created", //we need an id for this because the accessor is not a string
        Header: strings.table.header.createdDate,
        accessor: user =>
          moment(user.created).local().format("DD/MM/YYYY, HH:mm"),
        headerStyle: { textAlign: "left" }
      },
      {
        id: "updated", //we need an id for this because the accessor is not a string
        Header: strings.table.header.updatedDate,
        accessor: user =>
          moment(user.updated).local().format("DD/MM/YYYY, HH:mm"),
        headerStyle: { textAlign: "left" }
      },
      {
        id: "active",
        Header: strings.table.header.active,
        accessor: user => {
          if (user.active) {
            return <Icon name="circle" color="green" />;
          } else {
            return <Icon name="circle outline" color="red" />;
          }
        },
        sortable: false,
        headerStyle: { textAlign: "left" },
        width: 60
      }
    ];

    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          handleRefresh={this.props.handleRefresh}
          headerList={strings.header.userList}
          buttonAddLabel={strings.button.addUserLabel}
          permissionsCreate={permissions.users.create}
          headerClass="pageHeader"
          refreshButtonClass="refreshButton"
          addButtonClass="addButton"
          addButtonLink="/users/create"
          searchBarQuery={this.props.searchBarQuery}
          handleChange={this.props.handleChange}
          handleSubmit={this.props.handleSubmit}
        />
        <div>
          <ReactTable
            manual
            data={data}
            pages={pages}
            defaultPageSize={this.props.pageSize}
            showPagination={true}
            getTdProps={this.getTdProps}
            onFetchData={(state, instance) => {
              this.props.handleFetchData(state);
              this.props.updatePageIndex(state);
            }}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText={strings.table.text.noResults}
            previousText="<"
            nextText=">"
            pageText=""
          />
          <BasicModal
            confirmMessage={strings.modal.deleteUserMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="deleteUserModal"
            modalOpen={this.props.deleteModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleDeleteModal}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  deleteModalOpen: PropTypes.bool.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  userListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  updatePageIndex: PropTypes.func.isRequired
};

export default TableComponent;
