const yesNoOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" }
];
const categoryOptions = [
  { key: 1, text: "Mental Health", value: "Mental Health" },
  { key: 2, text: "Physical Health", value: "Physical Health" },
  { key: 3, text: "Substance Misuse", value: "Substance Misuse" },
  { key: 4, text: "Accommodation", value: "Accommodation" },
  { key: 5, text: "Relationships", value: "Relationships" },
  { key: 6, text: "Finance", value: "Finance" },
  { key: 7, text: "Employment", value: "Employment" },
  { key: 8, text: "Education & Training", value: "Education & Training" },
  { key: 9, text: "Attitudes & Behaviours", value: "Attitudes & Behaviours" },
  { key: 10, text: "Social & Leisure", value: "Social & Leisure" }
];
const stageOfReferralOptions = [
  { key: 1, text: "Prison", value: "Prison" },
  { key: 2, text: "Community", value: "Community" }
];
const methodOptions = [
  { key: 1, text: "Face-Face", value: "Face-Face" },
  { key: 2, text: "Phone call", value: "Phone call" },
  { key: 3, text: "Text", value: "Text" },
  { key: 4, text: "Email", value: "Email" },
  { key: 5, text: "Letter", value: "Letter" }
];
const providerOptions = [
  { key: 1, text: "SAMH", value: "102ca101-6a1e-431f-b142-e2de093844d1" },
  {
    key: 2,
    text: "Families Outside",
    value: "f9f77eea-5690-46cd-bbf6-240b7ad5ffde"
  }
];
const outcomeOfSpecialistReferralOptions = [
  { key: 1, text: "Unsuitable Referral", value: "Unsuitable Referral" },
  {
    key: 2,
    text: "Customer refused service",
    value: "Customer refused service"
  },
  { key: 3, text: "Customer not engaging", value: "Customer not engaging" },
  { key: 4, text: "Customer Start", value: "Customer Start" }
];
const reasonOptions = [
  { key: 1, text: "Needs not identified", value: "Needs not identified" },
  { key: 2, text: "Inappropriate risk", value: "Inappropriate risk" }
];

export {
  yesNoOptions,
  methodOptions,
  stageOfReferralOptions,
  categoryOptions,
  providerOptions,
  outcomeOfSpecialistReferralOptions,
  reasonOptions
};
