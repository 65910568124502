const he = require("he");

function cleanString(input) {
  return he.encode(input);
}
function cleanObject(object) {
  let output = {};

  Object.keys(object).forEach(key => {
    output[key] = cleanString(object[key]);
  });

  return output;
}

function decodeString(input) {
  if (input) {
    return he.decode(input);
  }
  return input;
}

function decodeData(inputData = {}, initialData, textfields) {
  let data = { ...inputData };
  const fields = textfields.filter(field => Object.keys(data).includes(field));

  for (let index in fields) {
    if (
      data[`${fields[index]}`] &&
      data[`${fields[index]}`] !== initialData[`${fields[index]}`]
    ) {
      data[`${fields[index]}`] = cleanString(data[`${fields[index]}`]);
    }
  }

  return data;
}

export default { cleanString, cleanObject, decodeString, decodeData };
