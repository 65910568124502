import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      entity: "ttgEngagements",
      deletion: "deletion",
      fullName: "Ttg Engagement"
    },
    areas: { NRS: "NRS", both: "both", NRNE: "NRNE" },
    header: {
      ttgEngagementList: "Engagements",
      ttgEngagements: "Engagements",
      createTtgEngagement: "Create New Engagement",
      editTtgEngagement: "Edit Engagement",
      viewTtgEngagement: "Engagement Details"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        ttgEngagementInfo: "Engagement Information"
      },
      label: {
        cjSpecialist: "CJ Specialist",
        crmId: "CRM ID",
        dateOfEngagement: "Date of Engagement",
        methodOfEngagement: "Method of Engagement",
        engagementSuccessful: "Engagement Successful?",
        areaOfNeedWorkedOn: "Area of Need Worked On?",
        notes: "Notes",
        firstName: "First Name",
        surname: "Surname",
        typeOfEngagement: "Type of Engagement"
      },
      placeholder: {
        cjSpecialist: "- select -",
        crmId: "",
        dateOfEngagement: "",
        methodOfEngagement: "- select -",
        engagementSuccessful: "- select -",
        areaOfNeedWorkedOn: "- select -",
        typeOfEngagement: "- select -",
        notes: "",
        firstName: "",
        surname: ""
      },
      feedback: {
        processing: "Processing request...",
        ttgEngagementRequestSuccess: "Engagement created successfully!",
        ttgEngagementRequestRefreshPrompt:
          "Use the refresh function to see an updated engagements list.",
        ttgEngagementRequestFailure: "Engagement creation failed.",
        ttgEngagementRequestUnknown:
          "Something went wrong with the engagement request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        yes: "Yes",
        no: "No",
        other: "Other",
        ttg: "TTG"
      },
      fileUpload: "Upload files"
    },
    modal: {
      createTtgEngagementMessage: "Save engagement?",
      modifyTtgEngagementMessage:
        "Are you sure you want to save these changes?",
      deleteTtgEngagementMessage:
        "Are you sure you want to delete this engagement? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this engagement's status to active?",
      setInactiveMessage: "Set this engagement's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addTtgEngagementLabel: "New Engagement Record",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      addAreaOfNeed: "Add Area Of Need",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form",
      saveAddNew: "Save & Add New"
    },
    tooltip: {},
    table: {
      header: {
        cjSpecialist: "CJ Specialist",
        created: "Date Created",
        crmId: "CRM ID",
        firstName: "First Name",
        surname: "Surname",
        typeOfEngagement: "Type of Engagement",
        methodOfEngagement: "Method of Engagement"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    }
  }
});
