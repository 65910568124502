import moment from "moment";
import SystemHeaders from "../classes/SystemHeaders";

export const incrementObject = entityId => {
  const pageLimit = localStorage.getItem("pageLimit");
  let retrievedEntities = JSON.parse(localStorage.getItem("retrievedEntities"));

  if (retrievedEntities && !retrievedEntities.hasOwnProperty(entityId)) {
    retrievedEntities[entityId] = moment().format();
    retrievedEntities = checkObject(retrievedEntities);
    if (Object.keys(retrievedEntities).length > pageLimit) {
      return false;
    } else {
      localStorage.setItem(
        "retrievedEntities",
        JSON.stringify(retrievedEntities)
      );
    }
  } else if (!retrievedEntities) {
    return false;
  }
  return true;
};

export const checkObject = retrievedEntities => {
  const timeLimit = localStorage.getItem("timeLimit");
  Object.entries(retrievedEntities).forEach(value => {
    if (moment(value[1]).isBefore(moment().subtract(timeLimit, "m"))) {
      delete retrievedEntities[value[0]];
    }
  });
  return retrievedEntities;
};

export const handleLogout = async (
  userId,
  username,
  headers,
  createAudit,
  logout
) => {
  let currentState = SystemHeaders.load();
  await createAudit(
    {
      userId: userId,
      actionType: "Logout - locked out",
      username: username,
      sessionId: currentState["headers"]["sessionId"],
      ipAddress: localStorage.getItem("ipAddress")
    },
    headers
  );
  logout(true);
};

export const findSettingValues = (settings, userArea, areas) => {
  let settingsData = { ...settings };
  delete settingsData.feedbackPassword;

  switch (userArea) {
    case areas.NRS:
      localStorage.setItem("pageLimit", settingsData.nrsPagesVisited);
      localStorage.setItem("timeLimit", settingsData.nrsDurationVisited);
      localStorage.setItem("retrievedEntities", JSON.stringify({}));
      break;
    case areas.NRNE:
      localStorage.setItem("pageLimit", settingsData.nrnePagesVisited);
      localStorage.setItem("timeLimit", settingsData.nrneDurationVisited);
      localStorage.setItem("retrievedEntities", JSON.stringify({}));
      break;
    case areas.TTG:
      localStorage.setItem("pageLimit", settingsData.ttgPagesVisited);
      localStorage.setItem("timeLimit", settingsData.ttgDurationVisited);
      localStorage.setItem("retrievedEntities", JSON.stringify({}));
      break;
    case areas.ALL:
    case areas.NE:
      let durationVisitedValues = [];
      let pagesVisitedValues = [];

      for (const [key, value] of Object.entries(settingsData)) {
        settingsData[key] = parseInt(value);
        const isNE = userArea === areas.NE;
        const neSettingsAreas = [
          areas.TTG.toLowerCase(),
          areas.NRNE.toLowerCase()
        ];

        if (key.includes("DurationVisited")) {
          if (!isNE) {
            durationVisitedValues.push(settingsData[key]);
          } else if (
            neSettingsAreas.some(settingArea => key.includes(settingArea))
          ) {
            durationVisitedValues.push(settingsData[key]);
          }
        } else if (key.includes("PagesVisited")) {
          if (!isNE) {
            pagesVisitedValues.push(settingsData[key]);
          } else if (
            neSettingsAreas.some(settingArea => key.includes(settingArea))
          ) {
            pagesVisitedValues.push(settingsData[key]);
          }
        }
      }

      localStorage.setItem("pageLimit", Math.min(...pagesVisitedValues));
      localStorage.setItem("timeLimit", Math.min(...durationVisitedValues));
      localStorage.setItem("retrievedEntities", JSON.stringify({}));
      break;
    default:
      localStorage.setItem("pageLimit", settingsData.nrsPagesVisited);
      localStorage.setItem("timeLimit", settingsData.nrsDurationVisited);
      localStorage.setItem("retrievedEntities", JSON.stringify({}));
      break;
  }
};

export default { findSettingValues, checkObject, incrementObject };
