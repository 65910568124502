import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class ShowIfAuthorised extends React.Component {
  render = () => {
    return (
      <>
        {this.props.allowedRoles.forEach(role => {
          if (!role) console.log("ERROR: role was not set");
        })}

        {this.props.isLoggedIn &&
          this.props.allowedRoles &&
          this.props.allowedRoles.indexOf(this.props.roleId) !== -1 &&
          this.props.children}
      </>
    );
  };
}

ShowIfAuthorised.propTypes = {
  allowedRoles: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  roleId: PropTypes.string,
  children: PropTypes.node
};

const mapStateToProps = state => {
  const { isLoggedIn, roleId } = state.auth;

  return {
    isLoggedIn,
    roleId
  };
};

export default connect(mapStateToProps, null)(ShowIfAuthorised);
