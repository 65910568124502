import axios from "./axios-complaint";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createComplaint = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  };

  updateComplaint = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating Complaint: ", error.message);
          reject(error);
        });
    });
  };

  deleteComplaint = id => {
    const config = {
      headers: this.headers,
      data: {
        feedbackType: "Complaint"
      }
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting Complaint: ", error.message);
          reject(error);
        });
    });
  };

  getComplaints = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    feedbackType,
    programmeId
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly,
      feedbackType: feedbackType,
      programmeId: programmeId
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/list-forms/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting feedback: ", error.message);
          reject(error);
        });
    });
  };

  getComplaintById = id => {
    const config = {
      headers: this.headers,
      params: { feedbackType: "Complaint" }
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting Complaint: ", error.message);
          reject(error);
        });
    });
  };
}
