import React from "react";
import ReactTable from "react-table";
import { Header } from "semantic-ui-react";
import { TableFunctions } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";
import { convertFromIsoWithTime } from "../helpers/index";
import ComparisonModal from "./ComparisonModal.js";
import "./css/AuditComponent.scss";
import AmendmentModal from "./AmendmentModal.js";

class TableComponent extends React.Component {
  state = {
    showComparisonModal: false,
    showAmendmentModal: false,
    auditObject: {}
  };

  mapSearchResultsToRows = () => {
    const { auditListData } = this.props;
    let data = [];
    if (auditListData) {
      auditListData.forEach((result, index) => {
        data.push(result);
      });
    }
    return data;
  };

  getTdProps = (state, rowInfo, column, instance) => {
    return {
      onClick: () => {
        if (rowInfo) {
          if (rowInfo.original.actionType === "Amendment") {
            this.setState({
              showAmendmentModal: true,
              auditObject: {
                dateOfRequest: rowInfo.original.newDate,
                actionType: rowInfo.original.actionType,
                table: rowInfo.original.table,
                dbActionType: rowInfo.original.dbActionType,
                amendmentDetails: rowInfo.original.newValues,
                crmId: rowInfo.original.crmId,
                page: rowInfo.original.page,
                username: rowInfo.original.username,
                sessionId: rowInfo.original.sessionId,
                ipAddress: rowInfo.original.ipAddress
              }
            });
          } else {
            this.setState({
              showComparisonModal: true,
              auditObject: {
                oldDate: rowInfo.original.oldDate
                  ? convertFromIsoWithTime(rowInfo.original.oldDate)
                  : "",
                oldValues: rowInfo.original.oldValues,
                newDate: rowInfo.original.newDate
                  ? convertFromIsoWithTime(rowInfo.original.newDate)
                  : "",
                newValues: rowInfo.original.newValues,
                actionType: rowInfo.original.actionType,
                table: rowInfo.original.table,
                dbActionType: rowInfo.original.dbActionType,
                crmId: rowInfo.original.crmId,
                page: rowInfo.original.page,
                username: rowInfo.original.username,
                sessionId: rowInfo.original.sessionId,
                ipAddress: rowInfo.original.ipAddress
              }
            });
          }
        }
      }
    };
  };

  handleClose = () => {
    this.setState({ showComparisonModal: false, showAmendmentModal: false });
  };

  viewSession = sessionId => {
    this.props.viewSession(sessionId);
    this.handleClose();
  };

  render = () => {
    let data = this.mapSearchResultsToRows();
    let pages = Math.ceil(this.props.totalResults / this.props.pageSize);
    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };
    const recordColumns = [
      {
        Header: strings.table.header.username,
        accessor: "username",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "oldDate",
        Header: strings.table.header.oldDate,
        accessor: audit =>
          audit.oldDate ? convertFromIsoWithTime(audit.oldDate) : "",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.oldValues,
        accessor: "oldValues",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "newDate",
        Header: strings.table.header.newDate,
        accessor: audit =>
          audit.newDate ? convertFromIsoWithTime(audit.newDate) : "",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.newValues,
        accessor: "newValues",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      }
    ];

    const globalColumns = [
      {
        Header: strings.table.header.actionType,
        accessor: "actionType",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.username,
        accessor: "username",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.page,
        accessor: "page",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.table,
        accessor: "table",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.crmId,
        accessor: "crmId",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.dbActionType,
        accessor: "dbActionType",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "created",
        Header: strings.table.header.created,
        accessor: audit =>
          audit.created ? convertFromIsoWithTime(audit.created) : "",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      }
    ];

    let columns;
    if (this.props.record) {
      columns = recordColumns;
    } else {
      columns = globalColumns;
    }

    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          handleRefresh={this.props.handleRefresh}
          headerClass="pageHeader"
          refreshButtonClass="refreshButton"
          hideCreate={true}
          showClearedButton={this.showClearedButton}
          handleChange={this.props.handleChange}
          handleSubmit={this.props.handleSubmit}
          searchBarQuery={this.props.searchBarQuery}
          hideSearchBar={this.props.hideSearchBar}
          headerList={!this.props.embedded ? strings.header.auditList : " "}
        />
        <div>
          {this.props.hideSearchBar && !this.props.record && (
            <Header>
              {`Viewing this session for ${this.state.auditObject.username} -
              ${this.props.auditListData.length} entries`}
            </Header>
          )}
          <ComparisonModal
            modalOpen={this.state.showComparisonModal}
            auditObject={this.state.auditObject}
            handleClose={this.handleClose}
            stringsObject={this.props.stringsObject}
            viewSession={this.viewSession}
          />
          <AmendmentModal
            modalOpen={this.state.showAmendmentModal}
            auditObject={this.state.auditObject}
            handleClose={this.handleClose}
            stringsObject={this.props.stringsObject}
            viewSession={this.viewSession}
          />
          <ReactTable
            manual
            data={data}
            pages={pages}
            defaultPageSize={this.props.pageSize}
            getTdProps={this.getTdProps}
            showPagination={true}
            onFetchData={(state, instance) => {
              this.props.handleFetchData(state);
              this.props.updatePageIndex(state);
            }}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText={strings.table.text.noResults}
            previousText="<"
            nextText=">"
            pageText=""
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  handleModalConfirm: PropTypes.func.isRequired,
  auditListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  updatePageIndex: PropTypes.func.isRequired,
  enableDateRange: PropTypes.bool,
  dateRangeObject: PropTypes.object
};

export default TableComponent;
