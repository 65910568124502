import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import {
  strings,
  complaintCategoryOptions,
  methodOptions,
  yesNoOptions
} from "../resources";
import {
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormInput,
  ValidatedFormTextArea
} from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from ".";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import {
  dropdownHelper,
  convertFromIso,
  getUsers,
  filterOutUndesiredProps
} from "../helpers";
import FileManager from "../../File/components/FileManager";
import { AuditListContainer } from "../../Audit/containers/";

class ModifyComplaint extends React.Component {
  returnToList = () => {
    this.props.history.push("/complaints");
  };

  returnToParent = () => {
    this.props.history.push(
      "/programmes/view/" + localStorage.getItem("programmeId")
    );
  };

  renderModifyButtons = () => {
    const { handleModeSwitch, isDisabled } = this.props;

    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      ></Input>
    );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful &&
          (!isDisabled ? (
            <>
              <Button.Group>
                <Button
                  className="editButton"
                  primary
                  onClick={() => {
                    handleModeSwitch("edit");
                  }}
                  disabled={this.props.showAuditLog}
                >
                  {strings.button.editLabel}
                </Button>
              </Button.Group>
            </>
          ) : (
            <span className="errorText">
              {strings.form.errorText.notShared}
            </span>
          ))}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={this.props.toggleEnableDisableComplaintModalVisibility}
                type="button"
                disabled={this.props.selectedComplaintData.requestId}
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableComplaintModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={
                  this.props.toggleEnableDisableComplaintModalVisibility
                }
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderDeleteButton = () => {
    return (
      <div className="containerButtons">
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={this.props.toggleEnableDisableComplaintModalVisibility}
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteComplaintMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableComplaintModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={
                  this.props.toggleEnableDisableComplaintModalVisibility
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderAuditButton = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="auditButton"
            onClick={() => {
              this.props.handleToggleAudit();
            }}
          >
            {!this.props.showAuditLog
              ? strings.button.auditLabel
              : strings.button.formLabel}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderCancelSaveButtons = () => {
    const {
      selectedComplaintData,
      handleModeSwitch,
      handleModalConfirm,
      toggleCancelChangesModalVisibility,
      toggleConfirmSaveModalVisibility,
      initialComplaintData,
      validateForm,
      confirmSaveModalOpen,
      mode,
      cancelChangesModalOpen,
      isDisabled
    } = this.props;

    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={validateForm}
            disabled={
              (JSON.stringify(selectedComplaintData) ===
                JSON.stringify(initialComplaintData) &&
                !this.props.stage1HasFileChanges &&
                !this.props.stage2HasFileChanges &&
                !this.props.stage3HasFileChanges) ||
              isDisabled
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
            disabled={isDisabled}
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };

  renderAdminAndStatus = () => {
    const {
      selectedComplaintData,
      handleChange,
      mode,
      formInvalid,
      validationResults,
      getOrganisations
    } = this.props;

    return (
      <>
        {mode !== "create" && (
          <div className="infoBlock">
            <div className="infoHeader">
              {strings.form.header.administration}
            </div>
            <div className="infoWrapper">
              <Form.Group>
                <ValidatedFormInput
                  formInvalid={formInvalid}
                  inputData={selectedComplaintData.crmId}
                  inputLabel={strings.form.label.crmId}
                  inputName="crmId"
                  inputPlaceholder={strings.form.placeholder.crmId}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.crmId}
                  disabled={true}
                  mode={mode}
                />
                <ValidatedFormDropdown
                  formInvalid={formInvalid}
                  inputData={selectedComplaintData.owner}
                  viewData={
                    dropdownHelper.getNameFromValues(
                      selectedComplaintData.owner,
                      getOrganisations()
                    ) || ""
                  }
                  dropdownLabel={strings.form.label.owner}
                  dropdownName="owner"
                  dropdownDisabled={mode === "edit"}
                  dropdownOptions={getOrganisations() || []}
                  dropdownPlaceholder={strings.form.placeholder.owner}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.owner}
                  mode={mode}
                />
              </Form.Group>
            </div>
          </div>
        )}

        <div className="infoBlock">
          <div className="infoHeader">{strings.form.header.status}</div>
          <div className="infoWrapper">
            <Form.Group>
              <ValidatedFormInput
                formInvalid={formInvalid}
                inputData={selectedComplaintData.stage}
                inputLabel={strings.form.label.stage}
                inputName="stage"
                inputPlaceholder={strings.form.placeholder.stage}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.stage}
                disabled={true}
                mode={mode}
              />
              <ValidatedFormInput
                formInvalid={formInvalid}
                inputData={selectedComplaintData.status}
                inputLabel={strings.form.label.status}
                inputName="status"
                inputPlaceholder={strings.form.placeholder.status}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.status}
                disabled={true}
                mode={mode}
              />
              <ValidatedDateInput
                inputId="dateResolved"
                formInvalid={formInvalid}
                inputLabel={strings.form.label.dateResolved}
                inputName="dateResolved"
                inputPlaceholder={strings.form.placeholder.dateResolved}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  selectedComplaintData.dateResolved
                    ? convertFromIso(selectedComplaintData.dateResolved)
                    : ""
                }
                iconPosition="left"
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.dateResolved}
                mode={mode}
                disabled={true}
                required={true}
              />
            </Form.Group>
          </div>
        </div>
      </>
    );
  };

  renderDetails = () => {
    const {
      selectedComplaintData,
      handleChange,
      mode,
      formInvalid,
      validationResults,
      userList
    } = this.props;

    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.complaintDetails}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedDateInput
              inputId="dateReceived"
              formInvalid={formInvalid}
              inputLabel={strings.form.label.dateReceived}
              inputName="dateReceived"
              inputPlaceholder={strings.form.placeholder.dateReceived}
              inputDateFormat="DD/MM/YYYY"
              inputData={
                selectedComplaintData.dateReceived
                  ? convertFromIso(selectedComplaintData.dateReceived)
                  : ""
              }
              iconPosition="left"
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.dateReceived}
              mode={mode}
              required={true}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedComplaintData.method}
              viewData={this.props.selectedComplaintData.method}
              dropdownLabel={strings.form.label.method}
              dropdownName="method"
              dropdownOptions={methodOptions}
              dropdownPlaceholder={strings.form.placeholder.method}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.method}
              dropdownFluid={true}
              required={true}
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={
                this.props.selectedComplaintData.personReceivingStage1Complaint
              }
              viewData={
                dropdownHelper.getNameFromValues(
                  this.props.selectedComplaintData
                    .personReceivingStage1Complaint,
                  getUsers(userList || [])
                ) || ""
              }
              dropdownLabel={strings.form.label.personReceivingStage1Complaint}
              dropdownName="personReceivingStage1Complaint"
              dropdownOptions={getUsers(userList || []) || []}
              dropdownPlaceholder={
                strings.form.placeholder.personReceivingStage1Complaint
              }
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults.personReceivingStage1Complaint
              }
              dropdownFluid={true}
              required={true}
              mode={mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedComplaintData.categoryOfComplaint}
              viewData={this.props.selectedComplaintData.categoryOfComplaint}
              dropdownLabel={strings.form.label.categoryOfComplaint}
              dropdownName="categoryOfComplaint"
              dropdownOptions={complaintCategoryOptions}
              dropdownPlaceholder={strings.form.placeholder.categoryOfComplaint}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults.categoryOfComplaint
              }
              dropdownFluid={true}
              required={true}
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedComplaintData.detailsOfComplaint}
              inputLabel={strings.form.label.detailsOfComplaint}
              inputName="detailsOfComplaint"
              inputPlaceholder={strings.form.placeholder.detailsOfComplaint}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.detailsOfComplaint}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedComplaintData.additionalNotes}
              inputLabel={strings.form.label.additionalNotes}
              inputName="additionalNotes"
              inputPlaceholder={strings.form.placeholder.additionalNotes}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.additionalNotes}
              mode={mode}
              required={false}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedComplaintData.subjectOfComplaint}
              viewData={
                dropdownHelper.getNameFromValues(
                  this.props.selectedComplaintData.subjectOfComplaint,
                  getUsers(userList || [])
                ) || ""
              }
              dropdownLabel={strings.form.label.subjectOfComplaint}
              dropdownName="subjectOfComplaint"
              dropdownOptions={getUsers(userList || []) || []}
              dropdownPlaceholder={strings.form.placeholder.subjectOfComplaint}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.subjectOfComplaint}
              dropdownFluid={true}
              required={false}
              mode={mode}
            />
          </Form.Group>
        </div>
      </div>
    );
  };

  renderStage = stage => {
    const {
      selectedComplaintData,
      handleChange,
      mode,
      formInvalid,
      validationResults,
      userList
    } = this.props;

    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header[`stage${stage}`]}</div>
        <div className="infoWrapper">
          {(stage === 2 || stage === 3) && (
            <Form.Group>
              <ValidatedDateInput
                inputId={`stage${stage}DateAcknowledged`}
                formInvalid={formInvalid}
                inputLabel={strings.form.label[`stage${stage}DateAcknowledged`]}
                inputName={`stage${stage}DateAcknowledged`}
                inputPlaceholder={
                  strings.form.placeholder[`stage${stage}DateAcknowledged`]
                }
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  selectedComplaintData[`stage${stage}DateAcknowledged`]
                    ? convertFromIso(
                        selectedComplaintData[`stage${stage}DateAcknowledged`]
                      )
                    : ""
                }
                iconPosition="left"
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={
                  validationResults[`stage${stage}DateAcknowledged`]
                }
                mode={mode}
                required={false}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={
                  this.props.selectedComplaintData[
                    `stage${stage}AcknowledgementSentBy`
                  ]
                }
                viewData={
                  dropdownHelper.getNameFromValues(
                    this.props.selectedComplaintData[
                      `stage${stage}AcknowledgementSentBy`
                    ],
                    getUsers(userList || [])
                  ) || ""
                }
                dropdownLabel={
                  strings.form.label[`stage${stage}AcknowledgementSentBy`]
                }
                dropdownName={`stage${stage}AcknowledgementSentBy`}
                dropdownOptions={
                  filterOutUndesiredProps(
                    getUsers(userList || []).filter(user => {
                      return (
                        user.role === strings.roles.headOfOps ||
                        user.role === strings.roles.teamlead ||
                        user.role === strings.roles.manager
                      );
                    })
                  ) || []
                }
                dropdownPlaceholder={
                  strings.form.placeholder[`stage${stage}AcknowledgementSentBy`]
                }
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults[
                    `stage${stage}AcknowledgementSentBy`
                  ]
                }
                dropdownFluid={true}
                required={
                  selectedComplaintData[`stage${stage}DateAcknowledged`] &&
                  selectedComplaintData[`stage${stage}DateAcknowledged`]
                    .length > 0
                    ? true
                    : false
                }
                mode={mode}
              />
            </Form.Group>
          )}
          <Form.Group>
            <ValidatedDateInput
              inputId={`stage${stage}ResponseDate`}
              formInvalid={formInvalid}
              inputLabel={strings.form.label[`stage${stage}ResponseDate`]}
              inputName={`stage${stage}ResponseDate`}
              inputPlaceholder={
                strings.form.placeholder[`stage${stage}ResponseDate`]
              }
              inputDateFormat="DD/MM/YYYY"
              inputData={
                selectedComplaintData[`stage${stage}ResponseDate`]
                  ? convertFromIso(
                      selectedComplaintData[`stage${stage}ResponseDate`]
                    )
                  : ""
              }
              iconPosition="left"
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults[`stage${stage}ResponseDate`]}
              mode={mode}
              required={false}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={
                this.props.selectedComplaintData[`stage${stage}ResponseDetails`]
              }
              inputLabel={strings.form.label[`stage${stage}ResponseDetails`]}
              inputName={`stage${stage}ResponseDetails`}
              inputPlaceholder={
                strings.form.placeholder[`stage${stage}ResponseDetails`]
              }
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults[`stage${stage}ResponseDetails`]
              }
              required={
                selectedComplaintData[`stage${stage}ResponseDate`] &&
                selectedComplaintData[`stage${stage}ResponseDate`].length > 0
                  ? true
                  : false
              }
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={
                this.props.selectedComplaintData[
                  `stage${stage}ResponseProvidedBy`
                ]
              }
              viewData={
                dropdownHelper.getNameFromValues(
                  this.props.selectedComplaintData[
                    `stage${stage}ResponseProvidedBy`
                  ],
                  getUsers(userList || [])
                ) || ""
              }
              dropdownLabel={
                strings.form.label[`stage${stage}ResponseProvidedBy`]
              }
              dropdownName={`stage${stage}ResponseProvidedBy`}
              dropdownOptions={
                filterOutUndesiredProps(
                  getUsers(userList || []).filter(user => {
                    return (
                      user.role === strings.roles.headOfOps ||
                      user.role === strings.roles.teamlead ||
                      user.role === strings.roles.manager
                    );
                  })
                ) || []
              }
              dropdownPlaceholder={
                strings.form.placeholder[`stage${stage}ResponseProvidedBy`]
              }
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults[`stage${stage}ResponseProvidedBy`]
              }
              dropdownFluid={true}
              required={
                selectedComplaintData[`stage${stage}ResponseDate`] &&
                selectedComplaintData[`stage${stage}ResponseDate`].length > 0
                  ? true
                  : false
              }
              mode={mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={
                this.props.selectedComplaintData[
                  `stage${stage}ComplaintResolved`
                ]
              }
              viewData={
                this.props.selectedComplaintData[
                  `stage${stage}ComplaintResolved`
                ]
              }
              dropdownLabel={
                strings.form.label[`stage${stage}ComplaintResolved`]
              }
              dropdownName={`stage${stage}ComplaintResolved`}
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={
                strings.form.placeholder[`stage${stage}ComplaintResolved`]
              }
              formFieldWidth={8}
              handleChange={(e, data) => {
                this.props.handleChange(e, data, this.props.setStatus(e, data));
              }}
              validationResult={
                this.props.validationResults[`stage${stage}ComplaintResolved`]
              }
              dropdownFluid={true}
              required={false}
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={
                this.props.selectedComplaintData[
                  `stage${stage}ResolutionDetails`
                ]
              }
              inputLabel={strings.form.label[`stage${stage}ResolutionDetails`]}
              inputName={`stage${stage}ResolutionDetails`}
              inputPlaceholder={
                strings.form.placeholder[`stage${stage}ResolutionDetails`]
              }
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults[`stage${stage}ResolutionDetails`]
              }
              required={false}
              mode={mode}
            />
            {(stage === 1 || stage === 2) && (
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={
                  this.props.selectedComplaintData[`stage${stage}Escalated`]
                }
                viewData={
                  this.props.selectedComplaintData[`stage${stage}Escalated`]
                }
                dropdownLabel={strings.form.label[`stage${stage}Escalated`]}
                dropdownName={`stage${stage}Escalated`}
                dropdownOptions={yesNoOptions}
                dropdownPlaceholder={
                  strings.form.placeholder[`stage${stage}Escalated`]
                }
                formFieldWidth={8}
                handleChange={(e, data) => {
                  this.props.handleChange(
                    e,
                    data,
                    this.props.setComplaintStage(e, data)
                  );
                }}
                validationResult={
                  this.props.validationResults[`stage${stage}Escalated`]
                }
                dropdownFluid={true}
                required={false}
                mode={mode}
              />
            )}
          </Form.Group>
          {(stage === 1 || stage === 2) && (
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={
                  this.props.selectedComplaintData[`stage${stage}EscalatedTo`]
                }
                viewData={
                  dropdownHelper.getNameFromValues(
                    this.props.selectedComplaintData[
                      `stage${stage}EscalatedTo`
                    ],
                    getUsers(userList || [])
                  ) || ""
                }
                dropdownLabel={strings.form.label[`stage${stage}EscalatedTo`]}
                dropdownName={`stage${stage}EscalatedTo`}
                dropdownOptions={
                  filterOutUndesiredProps(
                    getUsers(userList || []).filter(user => {
                      return (
                        user.role === strings.roles.headOfOps ||
                        user.role === strings.roles.teamlead ||
                        user.role === strings.roles.manager
                      );
                    })
                  ) || []
                }
                dropdownPlaceholder={
                  strings.form.placeholder[`stage${stage}EscalatedTo`]
                }
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults[`stage${stage}EscalatedTo`]
                }
                dropdownFluid={true}
                required={
                  selectedComplaintData[`stage${stage}Escalated`] ===
                  strings.form.text.yes
                }
                mode={mode}
              />
              <ValidatedDateInput
                inputId={`stage${stage}EscalationDate`}
                formInvalid={formInvalid}
                inputLabel={strings.form.label[`stage${stage}EscalationDate`]}
                inputName={`stage${stage}EscalationDate`}
                inputPlaceholder={
                  strings.form.placeholder[`stage${stage}EscalationDate`]
                }
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  selectedComplaintData[`stage${stage}EscalationDate`]
                    ? convertFromIso(
                        selectedComplaintData[`stage${stage}EscalationDate`]
                      )
                    : ""
                }
                iconPosition="left"
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={
                  validationResults[`stage${stage}EscalationDate`]
                }
                required={
                  selectedComplaintData[`stage${stage}Escalated`] ===
                  strings.form.text.yes
                }
                mode={mode}
              />
            </Form.Group>
          )}
          <Form.Group>
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={
                this.props.selectedComplaintData[`stage${stage}AdditionalNotes`]
              }
              inputLabel={strings.form.label[`stage${stage}AdditionalNotes`]}
              inputName={`stage${stage}AdditionalNotes`}
              inputPlaceholder={
                strings.form.placeholder[`stage${stage}AdditionalNotes`]
              }
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults[`stage${stage}AdditionalNotes`]
              }
              mode={mode}
              required={false}
            />
            {(this.props.hasPageLoaded || this.props.mode !== "create") &&
              this.props.selectedComplaintData.feedbackId && (
                <FileManager
                  folder={strings.folder[`stage${stage}`]}
                  id={`fileUploadButton${stage}`}
                  name={`files${stage}`}
                  headers={this.props.headers}
                  allowEdit={true}
                  filePath={`${strings.entity.complaint}s/`}
                  filesLabel={
                    strings.form.fileUploads[`stage${stage}FileUpload`]
                  }
                  disabled={this.props.mode === "view"}
                  entityId={this.props.selectedComplaintData.feedbackId}
                  entity={strings.entity.complaint}
                  filesHaveChanged={this.props.stageFilesHaveChanged}
                  stage={stage}
                />
              )}
          </Form.Group>
        </div>
      </div>
    );
  };

  render = () => {
    const { mode, formInvalid, pageTitle, requestStatus } = this.props;

    let parentPage = localStorage.getItem("programmeName")
      ? localStorage.getItem("programmeName")
      : undefined;

    return (
      <div className="container">
        <ComponentHeader
          parentPage={parentPage}
          returnToParent={this.returnToParent}
          returnToList={this.returnToList}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderModifyButtons()}
          cancelSaveButtons={this.renderCancelSaveButtons()}
          auditLog={this.renderAuditButton()}
          deleteButton={this.renderDeleteButton()}
          headerList={strings.header.complaintList}
          permissionsUpdate={permissions.complaints.update}
          permissionsCreate={permissions.complaints.create}
          permissionsDelete={permissions.complaints.delete}
          formInvalid={formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />
        {this.props.showAuditLog ? (
          <AuditListContainer
            embedded={true}
            headers={this.props.headers}
            history={this.props.history}
            entityId={this.props.selectedComplaintData.feedbackId}
            hideSearchBar={true}
            record={true}
          />
        ) : (
          <Form id="complaintForm" className="stickyForm">
            <fieldset disabled={mode === "view"}>
              {this.renderAdminAndStatus()}
              {this.renderDetails()}
              {this.renderStage(1)}
              {this.renderStage(2)}
              {this.renderStage(3)}
            </fieldset>
          </Form>
        )}
      </div>
    );
  };
}

ModifyComplaint.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedComplaintData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  initialComplaintData: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleModalInputChange: PropTypes.func
};

export default withRouter(ModifyComplaint);
