import React from "react";
import PropTypes from "prop-types";

class ShowIfIncludesArea extends React.Component {
  render = () => {
    return (
      <>
        {this.props.allowedAreas
          ? this.props.allowedAreas.includes(this.props.area) &&
            this.props.children
          : this.props.children}
      </>
    );
  };
}

ShowIfIncludesArea.propTypes = {
  allowedAreas: PropTypes.array,
  area: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default ShowIfIncludesArea;
