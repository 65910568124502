import { API } from "../../services";
import { historyActionTypes } from "./history.actionTypes";

const api = new API();

const getHistory = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {}
) => {
  const request = () => {
    return { type: historyActionTypes.GET_HISTORY_REQUEST };
  };
  const success = response => {
    return { type: historyActionTypes.GET_HISTORY_SUCCESS, response };
  };
  const failure = error => {
    return { type: historyActionTypes.GET_HISTORY_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getHistory" });

  return dispatch => {
    dispatch(request());
    if (clear) {
      // If supposed to clear, replicate blank result
      dispatch(success({ hits: { hits: [] } }));
    } else {
      api.getHistory(queryString, size, from, fields, sort).then(
        response => {
          dispatch(success(response));
        },
        error => dispatch(failure(error))
      );
    }
  };
};

const clearHistoryRequest = () => {
  const request = () => {
    return { type: historyActionTypes.CLEAR_CREATE_HISTORY_REQUEST };
  };

  const success = () => {
    return { type: historyActionTypes.CLEAR_CREATE_HISTORY_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort
) => {
  const request = () => {
    return { type: historyActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: historyActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };
  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(getHistory(headers, queryString, size, from, clear, fields, sort));
  };
};

export const historyActions = {
  getHistory,
  updateSearchCriteria,
  clearHistoryRequest
};
