import React from "react";
import ModifyComplaint from "../../components/ModifyComplaint";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { complaintActions } from "../../store/actions/complaint.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { authActions } from "../../../App/store/actions/auth.actions";
import { auditActions } from "../../../Audit/store/actions/audit.actions";
import { fileActions } from "../../../File/store/actions/file.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  convertToIso,
  getOrganisations,
  canUserModifyRecord,
  commitFileChanges,
  incrementObject,
  handleLogout,
  textFormattingHelpers
} from "../../helpers";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";

class ComplaintEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableComplaintModalOpen: false,
    initialComplaintData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    hasPageLoaded: false,
    isPrePopulated: false,
    deletionReason: undefined,
    permissionStatus: "",
    stage1HasFileChanges: false,
    stage2HasFileChanges: false,
    stage3HasFileChanges: false,
    showAuditLog: false
  };

  componentDidMount = async () => {
    await this.handleFetchData();
    await this.props.getComplaintById(
      this.props.match.params.id,
      this.props.headers
    );
    if (!incrementObject(this.props.match.params.id)) {
      this.props.lockUser(this.props.header);
      handleLogout(
        this.props.userId,
        this.props.username,
        this.props.headers,
        this.props.createAudit,
        this.props.logout
      );
    }
    let form = document.getElementById("complaintForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    this.setState({ hasPageLoaded: true });

    if (
      this.state.mode === "view" &&
      this.props.selectedComplaintData.requestId
    ) {
      await this.props.getDeletePermissionStatus(
        this.props.selectedComplaintData.requestId
      );
      this.setState(
        { permissionStatus: this.props.permissionStatus },
        async () => {
          if (this.state.permissionStatus === strings.states.rejected) {
            await this.props.updateSelectedComplaint("requestId", null);
            this.props.updateComplaint(
              this.props.selectedComplaintData.feedbackId,
              this.props.selectedComplaintData,
              this.props.headers,
              this.state.initialComplaintData,
              false
            );
          }
        }
      );
      this.props.clearAmendmentRequest();
    }
    this.setState({ initialComplaintData: this.props.selectedComplaintData });
  };

  componentWillUnmount() {
    this.props.clearSelectedComplaint();
    this.props.clearComplaintRequest();
    this.props.clearSelectedProgramme();
    this.props.clearAmendmentRequest();
  }

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    sCrit.size = 10000;
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    await this.props.getProgrammeById(localStorage.getItem("programmeId"));
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedComplaintData.feedbackId,
      this.state.deletionReason.value,
      strings.amendments.fullName,
      this.props.selectedComplaintData.crmId,
      this.props.headers,
      this.props.selectedComplaintData
    );
    this.props.updateSelectedComplaint("requestId", this.props.requestId);
    this.props.updateComplaint(
      this.props.selectedComplaintData.feedbackId,
      this.props.selectedComplaintData,
      this.props.headers,
      this.state.initialComplaintData
    );
    this.setState({
      initialComplaintData: this.props.selectedComplaintData
    });

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearComplaintRequest();
    });
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteComplaint(
      this.props.selectedComplaintData.feedbackId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      await this.props.setAmendmentStatusActioned(
        this.props.selectedComplaintData.requestId,
        this.props.headers,
        true,
        {
          entity: strings.amendments.entity,
          id: this.props.selectedComplaintData.feedbackId,
          type: strings.amendments.deletion
        }
      );

      this.props.history.push("/complaints");
    }

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearComplaintRequest();
    });
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedComplaintData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.feedback.modifyComplaint
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  commitStage = async stage => {
    await commitFileChanges(
      this.props.commitChanges,
      this.props.localData,
      this.props.remoteData,
      strings.folder[`stage${stage}`],
      strings.entity.complaint,
      this.props.selectedComplaintData.feedbackId
    );
  };

  handleSubmit = async () => {
    if (this.state.stage1HasFileChanges) {
      this.commitStage(1);
    }
    if (this.state.stage2HasFileChanges) {
      this.commitStage(2);
    }
    if (this.state.stage3HasFileChanges) {
      this.commitStage(3);
    }

    const data = textFormattingHelpers.decodeData(
      this.props.selectedComplaintData,
      this.state.initialComplaintData,
      textFields
    );

    await this.props.updateComplaint(
      this.props.match.params.id,
      data,
      this.props.headers,
      this.state.initialComplaintData
    );
    this.handleModeSwitch();
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedComplaint(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedComplaint(data.name, data.value);
    }
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedComplaintData.active
    };
    await this.props.updateComplaint(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialComplaintData
    );
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;

      default:
        break;
    }
  };

  handleToggleAudit = () => {
    const isAudit = this.state.showAuditLog;
    this.setState({ showAuditLog: !isAudit });
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableComplaintModalVisibility = () => {
    this.setState({
      enableDisableComplaintModalOpen: !this.state
        .enableDisableComplaintModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialComplaintData) !==
        JSON.stringify(this.props.selectedComplaintData)
      ) {
        await this.props.cancelSelectedComplaintUpdate(
          this.state.initialComplaintData
        );
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialComplaintData: JSON.parse(
          JSON.stringify(this.props.selectedComplaintData)
        )
      });
    }
  };

  stageFilesHaveChanged = stageNumber => {
    this.setState({ [`stage${stageNumber}HasFileChanges`]: true });
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  setComplaintStage = (e, data) => {
    if (
      data.name === strings.form.text.stage1Escalated &&
      data.value === strings.form.text.no
    ) {
      this.props.updateSelectedComplaint(strings.form.text.stage, "1");
    } else if (
      data.name === strings.form.text.stage2Escalated &&
      data.value === strings.form.text.no &&
      this.props.selectedComplaintData.stage1Escalated === strings.form.text.no
    ) {
      this.props.updateSelectedComplaint(strings.form.text.stage, "1");
    } else if (
      data.name === strings.form.text.stage1Escalated &&
      data.value === strings.form.text.yes &&
      (this.props.selectedComplaintData.stage2Escalated ===
        strings.form.text.no ||
        !this.props.selectedComplaintData.stage2Escalated)
    ) {
      this.props.updateSelectedComplaint(strings.form.text.stage, "2");
    } else if (
      data.name === strings.form.text.stage2Escalated &&
      data.value === strings.form.text.no &&
      this.props.selectedComplaintData.stage1Escalated === strings.form.text.yes
    ) {
      this.props.updateSelectedComplaint(strings.form.text.stage, "2");
    } else if (
      data.name === strings.form.text.stage2Escalated &&
      data.value === strings.form.text.yes &&
      this.props.selectedComplaintData.stage1Escalated === strings.form.text.yes
    ) {
      this.props.updateSelectedComplaint(strings.form.text.stage, "3");
    } else if (
      data.name === strings.form.text.stage1Escalated &&
      data.value === strings.form.text.yes &&
      this.props.selectedComplaintData.stage2Escalated === strings.form.text.yes
    ) {
      this.props.updateSelectedComplaint(strings.form.text.stage, "3");
    }
  };

  setStatus = (e, data) => {
    if (data.value === strings.form.text.yes) {
      this.props.updateSelectedComplaint("status", strings.form.text.resolved);
      this.setDateResolved();
    } else if (data.value === strings.form.text.no) {
      this.props.updateSelectedComplaint("status", strings.form.text.open);
      this.resetDateResolved();
    }
  };

  setDateResolved = () => {
    this.props.updateSelectedComplaint(
      "dateResolved",
      new Date().toISOString()
    );
  };

  resetDateResolved = () => {
    this.props.updateSelectedComplaint("dateResolved", undefined);
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.orgsLoadingPage ||
      this.props.usersLoadingPage ||
      this.props.programmeLoadingPage ||
      this.props.amendmentLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          {this.props.showNotification && (
            <RequestFeedback
              requestStatus={this.props.complaintRequestStatus}
              requestMade={this.props.complaintRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.complaintRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyComplaint
            mode={this.state.mode}
            requestStatus={this.props.complaintRequestStatus}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editComplaint
                : strings.header.viewComplaint
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnableDisableComplaintModalVisibility={
              this.toggleEnableDisableComplaintModalVisibility
            }
            selectedComplaintData={this.props.selectedComplaintData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enableDisableComplaintModalOpen={
              this.state.enableDisableComplaintModalOpen
            }
            initialComplaintData={this.state.initialComplaintData}
            formInvalid={this.state.formInvalid}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            headers={this.props.headers}
            getOrganisations={this.getOrganisations}
            hasPageLoaded={this.state.hasPageLoaded}
            area={this.props.selectedProgrammeData.area}
            userList={this.props.userListData}
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            isDisabled={this.isDisabled()}
            stageFilesHaveChanged={this.stageFilesHaveChanged}
            stage1HasFileChanges={this.state.stage1HasFileChanges}
            stage2HasFileChanges={this.state.stage2HasFileChanges}
            stage3HasFileChanges={this.state.stage3HasFileChanges}
            setComplaintStage={this.setComplaintStage}
            setStatus={this.setStatus}
            handleToggleAudit={this.handleToggleAudit}
            showAuditLog={this.state.showAuditLog}
          />
        </div>
      </div>
    );
  };
}

ComplaintEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedComplaintData: PropTypes.object.isRequired,
  updateSelectedComplaint: PropTypes.func.isRequired,
  updateComplaint: PropTypes.func.isRequired,
  getComplaintById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const {
    complaints,
    auth,
    organisations,
    programmes,
    users,
    amendments,
    file
  } = state;
  const {
    loadingPage,
    selectedComplaintData,
    complaintRequestStatus,
    complaintRequestMade,
    result,
    error,
    errorDetails,
    complaintListData,
    deleteSuccessful,
    showNotification
  } = complaints;
  const { headers, organisationOfLoggedInUser, userId, username } = auth;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  const { userListData, loadingPage: usersLoadingPage } = users;
  const {
    requestId,
    permissionStatus,
    loadingPage: amendmentLoadingPage
  } = amendments;
  const { localData, remoteData } = file;
  return {
    headers,
    loadingPage,
    selectedComplaintData,
    complaintRequestStatus,
    complaintRequestMade,
    result,
    error,
    errorDetails,
    complaintListData,
    organisationListData,
    selectedProgrammeData,
    userListData,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    organisationOfLoggedInUser,
    programmeLoadingPage,
    orgsLoadingPage,
    usersLoadingPage,
    localData,
    remoteData,
    userId,
    username,
    amendmentLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateComplaint: (
      id,
      data,
      headers,
      initialComplaintData,
      showNotification = true
    ) => {
      dispatch(
        complaintActions.updateComplaint(
          id,
          data,
          headers,
          initialComplaintData,
          showNotification
        )
      );
    },
    getComplaintById: async (id, headers) => {
      await dispatch(complaintActions.getComplaintById(id, headers));
    },
    updateSelectedComplaint: (key, value) => {
      dispatch(complaintActions.updateSelectedComplaint(key, value));
    },
    cancelSelectedComplaintUpdate: data => {
      dispatch(complaintActions.cancelSelectedComplaintUpdate(data));
    },
    clearComplaintRequest: () => {
      dispatch(complaintActions.clearComplaintRequest());
    },
    clearSelectedComplaint: () => {
      dispatch(complaintActions.clearSelectedComplaint());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    requestDeletePermission: async (
      id,
      reason,
      entity,
      recordIdentifier,
      headers,
      body
    ) => {
      await dispatch(
        amendmentActions.requestDeletePermission(
          id,
          reason,
          entity,
          recordIdentifier,
          headers,
          body
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteComplaint: async (id, headers) => {
      await dispatch(complaintActions.deleteComplaint(id, headers));
    },
    setAmendmentStatusActioned: async (
      id,
      headers,
      sendNotification,
      entityDetails
    ) => {
      await dispatch(
        amendmentActions.setAmendmentStatusActioned(
          id,
          headers,
          sendNotification,
          entityDetails
        )
      );
    },
    commitChanges: (
      localData,
      remoteData,
      filePath,
      headers,
      entity,
      entityId,
      folder
    ) => {
      return dispatch(
        fileActions.commitChanges(
          localData,
          remoteData,
          filePath,
          headers,
          entity,
          entityId,
          folder
        )
      );
    },
    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    lockUser: headers => {
      dispatch(userActions.lockUser(headers));
    },
    logout: hasExceeded => {
      dispatch(authActions.signOut(hasExceeded));
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplaintEditContainer);
