import { areaOfNeedActionTypes } from "../actions/areaOfNeed.actionTypes";

const initialState = {
  loadingPage: false,
  selectedAreaOfNeedData: { actionsArray: [] },
  areaOfNeedListData: [{}],
  totalResults: 0,
  areaOfNeedToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  areaOfNeedRequestStatus: undefined,
  areaOfNeedRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let details;
  let newTotal;
  switch (action.type) {
    case areaOfNeedActionTypes.CREATE_AREA_OF_NEED_REQUEST:
      return {
        ...state,
        areaOfNeedRequestStatus: undefined,
        areaOfNeedRequestMade: true
      };
    case areaOfNeedActionTypes.CREATE_AREA_OF_NEED_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        areaOfNeedRequestStatus: true
      };
    case areaOfNeedActionTypes.CREATE_AREA_OF_NEED_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        areaOfNeedRequestStatus: false
      };

    case areaOfNeedActionTypes.CLEAR_CREATE_AREA_OF_NEED_REQUEST:
      return { ...state };
    case areaOfNeedActionTypes.CLEAR_CREATE_AREA_OF_NEED_SUCCESS:
      return {
        ...state,
        areaOfNeedRequestMade: false,
        areaOfNeedRequestStatus: undefined
      };
    case areaOfNeedActionTypes.CLEAR_CREATE_AREA_OF_NEED_FAILURE:
      return { ...state };

    case areaOfNeedActionTypes.UPDATE_AREA_OF_NEED_REQUEST:
      return {
        ...state,
        loadingPage: true,
        areaOfNeedRequestStatus: undefined,
        areaOfNeedRequestMade: true
      };
    case areaOfNeedActionTypes.UPDATE_AREA_OF_NEED_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        areaOfNeedRequestStatus: true,
        selectedAreaOfNeedData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case areaOfNeedActionTypes.UPDATE_AREA_OF_NEED_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        areaOfNeedRequestStatus: false,
        selectedAreaOfNeedData: action.initialDetails,
        errorDetails: details
      };

    case areaOfNeedActionTypes.DELETE_AREA_OF_NEED_REQUEST:
      return {
        ...state,
        areaOfNeedRequestStatus: undefined,
        areaOfNeedRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case areaOfNeedActionTypes.DELETE_AREA_OF_NEED_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        areaOfNeedRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case areaOfNeedActionTypes.DELETE_AREA_OF_NEED_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        areaOfNeedRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case areaOfNeedActionTypes.GET_AREA_OF_NEEDS_REQUEST:
      return { ...state, loadingPage: true, deleteSuccessful: false };
    case areaOfNeedActionTypes.GET_AREA_OF_NEEDS_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newAreaOfNeed = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newAreaOfNeed;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        areaOfNeedListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case areaOfNeedActionTypes.GET_AREA_OF_NEEDS_FAILURE:
      return { ...state, areaOfNeedListData: [{}], loadingPage: false };

    case areaOfNeedActionTypes.GET_AREA_OF_NEED_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        areaOfNeedRequestStatus: undefined
      };
    case areaOfNeedActionTypes.GET_AREA_OF_NEED_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        areaOfNeedRequestStatus: true,
        selectedAreaOfNeedData: action.response.Body.Item,
        loadingPage: false
      };
    case areaOfNeedActionTypes.GET_AREA_OF_NEED_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedAreaOfNeedData: {},
        areaOfNeedRequestStatus: false,
        areaOfNeedRequestMade: true
      };

    case areaOfNeedActionTypes.UPDATE_SELECTED_AREA_OF_NEED_REQUEST:
      return { ...state };
    case areaOfNeedActionTypes.UPDATE_SELECTED_AREA_OF_NEED_SUCCESS:
      return {
        ...state,
        selectedAreaOfNeedData: {
          ...state.selectedAreaOfNeedData,
          [action.key]: action.value
        }
      };
    case areaOfNeedActionTypes.UPDATE_SELECTED_AREA_OF_NEED_FAILURE:
      return { ...state };

    case areaOfNeedActionTypes.CLEAR_SELECTED_AREA_OF_NEED_REQUEST:
      return { ...state };
    case areaOfNeedActionTypes.CLEAR_SELECTED_AREA_OF_NEED_SUCCESS:
      return { ...state, selectedAreaOfNeedData: {} };
    case areaOfNeedActionTypes.CLEAR_SELECTED_AREA_OF_NEED_FAILURE:
      return { ...state };

    case areaOfNeedActionTypes.CANCEL_SELECTED_AREA_OF_NEED_UPDATE_REQUEST:
      return { ...state };
    case areaOfNeedActionTypes.CANCEL_SELECTED_AREA_OF_NEED_UPDATE_SUCCESS:
      return { ...state, selectedAreaOfNeedData: action.data };
    case areaOfNeedActionTypes.CANCEL_SELECTED_AREA_OF_NEED_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};
