import React from "react";
import ModifyNews from "../../components/ModifyNews";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { newsActions } from "../../store/actions/news.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { authActions } from "../../../App/store/actions/auth.actions";
import { auditActions } from "../../../Audit/store/actions/audit.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";
import {
  convertToIso,
  canUserModifyRecord,
  incrementObject,
  handleLogout,
  textFormattingHelpers
} from "../../helpers";

class NewsEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableNewsModalOpen: false,
    initialNewsData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    hasPageLoaded: false,
    isPrePopulated: false,
    deletionReason: undefined,
    permissionStatus: "",
    showAuditLog: false
  };

  componentDidMount = async () => {
    await this.handleFetchData();
    await this.props.getNewsById(
      this.props.match.params.id,
      this.props.headers
    );
    if (!incrementObject(this.props.match.params.id)) {
      this.props.lockUser(this.props.header);
      handleLogout(
        this.props.userId,
        this.props.username,
        this.props.headers,
        this.props.createAudit,
        this.props.logout
      );
    }
    let form = document.getElementById("newsForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    this.setState({ hasPageLoaded: true });

    if (this.state.mode === "view" && this.props.selectedNewsData.requestId) {
      await this.props.getDeletePermissionStatus(
        this.props.selectedNewsData.requestId
      );
      this.setState(
        { permissionStatus: this.props.permissionStatus },
        async () => {
          if (this.state.permissionStatus === strings.states.rejected) {
            await this.props.updateSelectedNews("requestId", null);
            this.props.updateNews(
              this.props.selectedNewsData.newsId,
              this.props.selectedNewsData,
              this.props.headers,
              this.state.initialNewsData,
              false
            );
          }
        }
      );
    }
    this.props.clearAmendmentRequest();
    this.setState({ initialNewsData: this.props.selectedNewsData });
  };

  componentWillUnmount() {
    this.props.clearSelectedNews();
  }

  handleFetchData = async page => {
    await this.props.getProgrammeById(localStorage.getItem("programmeId"));
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedNewsData.newsId,
      this.state.deletionReason.value,
      strings.amendments.fullName,
      this.props.selectedNewsData.crmId,
      this.props.headers,
      this.props.selectedNewsData
    );
    this.props.updateSelectedNews("requestId", this.props.requestId);
    this.props.updateNews(
      this.props.selectedNewsData.newsId,
      this.props.selectedNewsData,
      this.props.headers,
      this.state.initialNewsData
    );
    this.setState({
      initialNewsData: this.props.selectedNewsData
    });

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearNewsRequest();
    }, 10000);
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteNews(
      this.props.selectedNewsData.newsId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      await this.props.setAmendmentStatusActioned(
        this.props.selectedNewsData.requestId,
        this.props.headers,
        true,
        {
          entity: strings.amendments.entity,
          id: this.props.selectedNewsData.newsId,
          type: strings.amendments.deletion
        }
      );

      this.props.history.push("/news");
    }

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearNewsRequest();
    }, 10000);
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedNewsData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    this.setState({ formInvalid: false });
    this.toggleConfirmSaveModalVisibility();
  };

  handleSubmit = async () => {
    const data = textFormattingHelpers.decodeData(
      this.props.selectedNewsData,
      this.state.initialNewsData,
      textFields
    );

    await this.props.updateNews(
      this.props.match.params.id,
      data,
      this.props.headers,
      this.state.initialNewsData
    );
    this.handleModeSwitch();
    window.setTimeout(() => {
      this.props.clearNewsRequest();
    }, 10000);
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedNews(data.name, convertToIso(data.value));
    } else {
      await this.props.updateSelectedNews(data.name, data.value);
    }
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedNewsData.active
    };
    await this.props.updateNews(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialNewsData
    );
  };

  handleToggleAudit = () => {
    const isAudit = this.state.showAuditLog;
    this.setState({ showAuditLog: !isAudit });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;

      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableNewsModalVisibility = () => {
    this.setState({
      enableDisableNewsModalOpen: !this.state.enableDisableNewsModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialNewsData) !==
        JSON.stringify(this.props.selectedNewsData)
      ) {
        await this.props.cancelSelectedNewsUpdate(this.state.initialNewsData);
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialNewsData: JSON.parse(JSON.stringify(this.props.selectedNewsData))
      });
    }
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.orgsLoadingPage ||
      this.props.usersLoadingPage ||
      this.props.programmeLoadingPage ||
      this.props.amendmentLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.requestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          {this.props.showNotification && (
            <RequestFeedback
              className={
                this.props.amendmentRequestStatus ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.newsRequestStatus}
              requestMade={this.props.newsRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={strings.form.feedback.newsRequestUnknown}
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyNews
            mode={this.state.mode}
            requestStatus={this.props.newsRequestStatus}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editNews
                : strings.header.viewNews
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnableDisableNewsModalVisibility={
              this.toggleEnableDisableNewsModalVisibility
            }
            selectedNewsData={this.props.selectedNewsData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enableDisableNewsModalOpen={this.state.enableDisableNewsModalOpen}
            initialNewsData={this.state.initialNewsData}
            formInvalid={this.state.formInvalid}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            type={this.state.newsType}
            headers={this.props.headers}
            getOrganisations={this.getOrganisations}
            hasPageLoaded={this.state.hasPageLoaded}
            area={this.props.selectedProgrammeData.area}
            userList={this.props.userListData}
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            isDisabled={this.isDisabled()}
            handleToggleAudit={this.handleToggleAudit}
            showAuditLog={this.state.showAuditLog}
          />
        </div>
      </div>
    );
  };
}

NewsEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedNewsData: PropTypes.object.isRequired,
  updateSelectedNews: PropTypes.func.isRequired,
  updateNews: PropTypes.func.isRequired,
  getNewsById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { news, auth, organisations, programmes, users, amendments } = state;
  const {
    loadingPage,
    selectedNewsData,
    newsRequestStatus,
    newsRequestMade,
    result,
    error,
    errorDetails,
    newsListData,
    deleteSuccessful,
    showNotification
  } = news;
  const { organisationOfLoggedInUser, userId, username } = auth;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  const { userListData, loadingPage: usersLoadingPage } = users;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails,
    loadingPage: amendmentLoadingPage
  } = amendments;
  return {
    loadingPage,
    selectedNewsData,
    newsRequestStatus,
    newsRequestMade,
    result,
    error,
    errorDetails,
    newsListData,
    organisationListData,
    selectedProgrammeData,
    userListData,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    organisationOfLoggedInUser,
    programmeLoadingPage,
    orgsLoadingPage,
    usersLoadingPage,
    amendmentRequestStatus,
    amendmentRequestMade,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails,
    userId,
    username,
    amendmentLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNews: (
      id,
      data,
      headers,
      initialNewsData,
      showNotification = true
    ) => {
      dispatch(
        newsActions.updateNews(
          id,
          data,
          headers,
          initialNewsData,
          showNotification
        )
      );
    },
    getNewsById: async (id, headers) => {
      await dispatch(newsActions.getNewsById(id, headers));
    },
    updateSelectedNews: (key, value) => {
      dispatch(newsActions.updateSelectedNews(key, value));
    },
    cancelSelectedNewsUpdate: data => {
      dispatch(newsActions.cancelSelectedNewsUpdate(data));
    },
    clearNewsRequest: () => {
      dispatch(newsActions.clearNewsRequest());
    },
    clearSelectedNews: () => {
      dispatch(newsActions.clearSelectedNews());
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    },
    requestDeletePermission: async (
      id,
      reason,
      entity,
      recordIdentifier,
      headers,
      body
    ) => {
      await dispatch(
        amendmentActions.requestDeletePermission(
          id,
          reason,
          entity,
          recordIdentifier,
          headers,
          body
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteNews: async (id, headers) => {
      await dispatch(newsActions.deleteNews(id, headers));
    },
    setAmendmentStatusActioned: async (
      id,
      headers,
      sendNotification,
      entityDetails
    ) => {
      await dispatch(
        amendmentActions.setAmendmentStatusActioned(
          id,
          headers,
          sendNotification,
          entityDetails
        )
      );
    },
    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    lockUser: headers => {
      dispatch(userActions.lockUser(headers));
    },
    logout: hasExceeded => {
      dispatch(authActions.signOut(hasExceeded));
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsEditContainer);
