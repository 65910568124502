export const typeOptions = [
  { key: 0, value: "Record Review", text: "Record Review" },
  { key: 1, value: "Shadowing/Observation", text: "Shadowing/Observation" },
  { key: 2, value: "Feedback", text: "Feedback" }
];
export const outcomeOptions = [
  { key: 0, value: "reviewed - satisfactory", text: "Reviewed - Satisfactory" },
  {
    key: 1,
    value: "reviewed - good practice identified",
    text: "Reviewed - Good practice identified"
  },
  {
    key: 2,
    value: "reviewed - improvement feedback identified",
    text: "Reviewed - Improvement feedback identified"
  },
  {
    key: 3,
    value: "reviewed - improvement actions identified",
    text: "Reviewed - Improvement actions identified"
  }
];
export const actionStatusOptions = [
  { key: 0, value: "Raised", text: "Raised" },
  { key: 1, value: "In Progress", text: "In Progress" },
  { key: 2, value: "Completed", text: "Completed" },
  { key: 3, value: "Closed", text: "Closed" }
];
