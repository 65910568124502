import axios from "./axios-ttg-programmes";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createTtgProgramme = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error creating ttgProgramme: ", error.response.data);
          reject(error);
        });
    });
  };

  updateTtgProgramme = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating ttgProgramme: ", error.message);
          reject(error);
        });
    });
  };

  deleteTtgProgramme = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, null, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting ttgProgramme: ", error.message);
          reject(error);
        });
    });
  };

  getTtgProgrammes = (queryString, size, from, fields, sort, activeOnly) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly
    };

    return new Promise((resolve, reject) => {
      axios
        .post("/list-ttg-programmes/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting ttgProgrammes: ", error.message);
          reject(error);
        });
    });
  };

  getTtgProgrammeById = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting ttgProgramme: ", error.message);
          reject(error);
        });
    });
  };

  outcomesStatistics = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/outcomes/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting ttgProgramme's outcomes: ", error.message);
          reject(error);
        });
    });
  };
}
