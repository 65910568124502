import React from "react";
import ModifyAssurance from "../../components/ModifyAssurance";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { assuranceActions } from "../../store/actions/assurance.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import PropTypes from "prop-types";
import {
  convertToIso,
  getOrganisations,
  canUserModifyRecord,
  getUsers,
  textFormattingHelpers
} from "../../helpers";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import uuidv4 from "uuid/v4";

class AssuranceCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    deleteActionModalOpen: false,
    enableDisableAssuranceModalOpen: false,
    formInvalid: false,
    validationResults: {},
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    isPrePopulated: false,
    hasPageLoaded: false,
    userList: []
  };

  componentDidMount = async () => {
    this.props.getProgrammeById(localStorage.getItem("programmeId"));
    await this.handleFetchData();
    let form = document.getElementById("assuranceForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    await this.props.updateSelectedAssurance(
      "programmeId",
      localStorage.getItem("programmeId")
    );
    this.setState({ hasPageLoaded: true });
  };

  componentDidUpdate = () => {
    if (
      Object.keys(this.props.selectedProgrammeData).length > 0 &&
      !this.state.isPrePopulated
    ) {
      if (this.props.selectedProgrammeData.owner) {
        this.props.updateSelectedAssurance(
          "owner",
          this.props.selectedProgrammeData.owner
        );
      }
      if (this.props.selectedProgrammeData.programmeId) {
        this.props.updateSelectedAssurance(
          "programmeId",
          this.props.selectedProgrammeData.programmeId
        );
      }
      if (this.props.selectedProgrammeData.crmId) {
        this.props.updateSelectedAssurance(
          "programmeCrmId",
          this.props.selectedProgrammeData.crmId
        );
      }
      if (this.props.selectedProgrammeData.area) {
        this.props.updateSelectedAssurance(
          "area",
          this.props.selectedProgrammeData.area
        );
      }
      this.props.updateSelectedAssurance("byWhom", this.props.userId);
      this.props.updateSelectedAssurance("feedbackType", "Assurance");
      this.setState({ isPrePopulated: true });
    }
  };

  componentWillUnmount() {
    this.props.clearSelectedAssurance();
  }

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    this.setState({ userList: getUsers(this.props.userListData) });
  };

  validateForm = async () => {
    let assuranceConstraints = {
      ...twgConstraints.feedback.modifyAssurance
    };
    let assuranceActionConstraints = {
      ...twgConstraints.feedback.modifyAssuranceAction
    };
    let details = JSON.parse(JSON.stringify(this.props.selectedAssuranceData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(
      details,
      assuranceConstraints
    );

    let hasActionsBeenIdentified =
      this.props.selectedAssuranceData.outcome ===
      strings.fieldValues.improvementActions;

    let actionResults = [];
    if (hasActionsBeenIdentified) {
      let actions = this.props.selectedAssuranceData.actions;
      actions.forEach(action => {
        for (let property in action) {
          if (!action[property]) {
            delete action[property];
          }
        }
      });

      actions.forEach(function (action) {
        let actionResult = twgValidationFunctions.validateData(
          { ...action, ...details },
          assuranceActionConstraints
        );
        actionResult
          ? actionResults.push(actionResult)
          : actionResults.push({});
      });
    } else {
      this.props.updateSelectedAssurance("actions", undefined);
    }

    results ? (results.actions = []) : (results = { actions: [] });
    let actionResultsAreEmpty = true;
    for (let i in actionResults) {
      //All results need to be added to preserve order
      results.actions.push(actionResults[i]);
      if (Object.keys(actionResults[i]).length !== 0) {
        actionResultsAreEmpty = false;
      }
    }
    //Remove the actions key if all actions pass validation
    if (actionResultsAreEmpty) {
      delete results.actions;
    }
    //There will be no keys in the object if the form passes validation
    if (Object.keys(results).length > 0) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  initialiseActionTable = async () => {
    let actions = [
      {
        action: "",
        actionCompletedBy: "",
        actionStatus: "",
        notes: "",
        dateActionClosed: "",
        id: uuidv4()
      }
    ];

    actions.length > 1
      ? await this.props.updateSelectedAssurance(
          this.getKeyByValue(
            this.props.selectedAssuranceData,
            this.props.selectedAssuranceData.actions
          )
        )
      : await this.props.updateSelectedAssurance("actions", actions);
  };

  addAction = async e => {
    const actions = this.props.selectedAssuranceData.actions || [];
    const newActions = actions.concat({
      action: "",
      actionCompletedBy: "",
      actionStatus: "",
      notes: "",
      dateActionClosed: "",
      id: uuidv4()
    });
    actions.length > 1
      ? await this.props.updateSelectedAssurance(
          this.getKeyByValue(
            this.props.selectedAssuranceData,
            this.props.selectedAssuranceData.actions
          ),
          newActions
        )
      : await this.props.updateSelectedAssurance("actions", newActions);
  };

  removeAction = id => {
    let actions = this.props.selectedAssuranceData.actions;
    if (actions.length > 0) {
      this.props.updateSelectedAssurance(
        this.getKeyByValue(
          this.props.selectedAssuranceData,
          this.props.selectedAssuranceData.actions
        ),
        actions.filter(action => action.id !== id)
      );
    }
  };

  handleActionChange = async (e, data) => {
    let actions = this.props.selectedAssuranceData.actions;
    const result = data.name.split("-");

    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      actions[result[1]][result[0]] = convertToIso(data.value);

      await this.props.updateSelectedAssurance(
        this.getKeyByValue(
          this.props.selectedAssuranceData,
          this.props.selectedAssuranceData.actions
        ),
        actions
      );
    } else {
      actions[result[1]][result[0]] = data.value;

      await this.props.updateSelectedAssurance(
        this.getKeyByValue(
          this.props.selectedAssuranceData,
          this.props.selectedAssuranceData.actions
        ),
        actions
      );
    }
  };

  getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  handleSubmit = async () => {
    const data = textFormattingHelpers.decodeData(
      this.props.selectedAssuranceData,
      {},
      textFields
    );

    await this.props.createAssurance(data, this.props.headers);
    this.props.history.push("/assurances");
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearAssuranceRequest();
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedAssurance(
        data.name,
        convertToIso(data.value)
      );
    } else if (
      data.name === "outcome" &&
      data.value === strings.fieldValues.improvementActions &&
      (!this.props.selectedAssuranceData.actions ||
        (this.props.selectedAssuranceData.actions &&
          this.props.selectedAssuranceData.actions.length === 0))
    ) {
      this.initialiseActionTable();
      await this.props.updateSelectedAssurance(data.name, data.value);
    } else {
      await this.props.updateSelectedAssurance(data.name, data.value);
    }
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "deleteActionModal":
        this.removeAction(this.state.actionDeletionId);
        break;
      default:
        break;
    }
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableAssuranceModalVisibility = () => {
    this.setState({
      enableDisableAssuranceModalOpen: !this.state
        .enableDisableAssuranceModalOpen
    });
  };

  toggleConfirmDeleteActionModalVisibility = id => {
    this.setState({
      deleteActionModalOpen: !this.state.deleteActionModalOpen,
      actionDeletionId: id
    });
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.programmeLoadingPage ||
      this.props.orgsLoadingPage ||
      this.props.usersLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <ModifyAssurance
            mode="create"
            pageTitle={strings.header.createAssurance}
            selectedAssuranceData={this.props.selectedAssuranceData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            deleteActionModalOpen={this.state.deleteActionModalOpen}
            enableDisableAssuranceModalOpen={
              this.state.enableDisableAssuranceModalOpen
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableAssuranceModalVisibility={
              this.toggleEnableDisableAssuranceModalVisibility
            }
            toggleConfirmDeleteActionModalVisibility={
              this.toggleConfirmDeleteActionModalVisibility
            }
            handleChange={this.handleChange}
            handleActionChange={this.handleActionChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            initialAssuranceData={{}}
            getOrganisations={this.getOrganisations}
            hasPageLoaded={this.state.hasPageLoaded}
            headers={this.props.headers}
            userList={this.state.userList}
            isDisabled={this.isDisabled()}
            addAction={this.addAction}
          />
        </div>
      </div>
    );
  };
}

AssuranceCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedAssuranceData: PropTypes.object.isRequired,
  updateSelectedAssurance: PropTypes.func.isRequired,
  clearSelectedAssurance: PropTypes.func.isRequired,
  createAssurance: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    assurances,
    auth,
    organisations,
    referrals,
    programmes,
    users
  } = state;
  const { loadingPage, selectedAssuranceData } = assurances;
  const { headers, organisationOfLoggedInUser, userId } = auth;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const { selectedReferralData, referralRequestStatus } = referrals;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  const { userListData, loadingPage: usersLoadingPage } = users;

  return {
    loadingPage,
    selectedAssuranceData,
    headers,
    organisationListData,
    selectedReferralData,
    selectedProgrammeData,
    referralRequestStatus,
    userListData,
    organisationOfLoggedInUser,
    programmeLoadingPage,
    orgsLoadingPage,
    usersLoadingPage,
    userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createAssurance: async (data, headers) => {
      await dispatch(assuranceActions.createAssurance(data, headers));
    },
    updateSelectedAssurance: (key, value) => {
      dispatch(assuranceActions.updateSelectedAssurance(key, value));
    },
    clearSelectedAssurance: () => {
      dispatch(assuranceActions.clearSelectedAssurance());
    },
    clearAssuranceRequest: () => {
      dispatch(assuranceActions.clearAssuranceRequest());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssuranceCreateContainer);
