import { historyActionTypes } from "../actions/history.actionTypes";

const initialState = {
  loadingPage: false,
  historyListData: [{}],
  totalResults: 0,
  result: "",
  error: "",
  errorDetails: {},
  historyRequestStatus: undefined,
  historyRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let newTotal;

  switch (action.type) {
    case historyActionTypes.GET_HISTORY_REQUEST:
      return { ...state, loadingPage: true };
    case historyActionTypes.GET_HISTORY_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Body.logs) {
        newTotal = action.response.data.Body.total;
        rawHits = action.response.data.Body.logs.slice();
        rawHits.forEach((result, index) => {
          let newAudit = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newAudit;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        historyListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case historyActionTypes.GET_HISTORY_FAILURE:
      return { ...state, historyListData: [{}], loadingPage: false };

    case historyActionTypes.CLEAR_CREATE_HISTORY_REQUEST:
      return { ...state };
    case historyActionTypes.CLEAR_CREATE_HISTORY_SUCCESS:
      return {
        ...state,
        historyRequestMade: false,
        historyRequestStatus: undefined
      };
    case historyActionTypes.CLEAR_CREATE_HISTORY_FAILURE:
      return { ...state };

    default:
      return state;
  }
};
