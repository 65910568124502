import React from "react";
import ModifyEngagement from "../../components/ModifyEngagement";
import { ActivityListContainer } from "../../../Activities/containers/";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { engagementActions } from "../../store/actions/engagement.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { referralActions } from "../../../Referrals/store/actions/referral.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { fileActions } from "../../../File/store/actions/file.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { authActions } from "../../../App/store/actions/auth.actions";
import { auditActions } from "../../../Audit/store/actions/audit.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  convertToIso,
  getOrganisations,
  calculateSignUpOutcomeOptions,
  canUserModifyRecord,
  commitFileChanges,
  incrementObject,
  handleLogout,
  textFormattingHelpers
} from "../../helpers";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";

class EngagementEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableEngagementModalOpen: false,
    initialEngagementData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    engagementType: this.props.match.params.engagementType,
    addActivities: false,
    hasFileChanges: false,
    deletionReason: undefined,
    fetchedPermissionStatus: false,
    permissionStatus: "",
    canView: true,
    showAuditLog: false
  };

  componentDidMount = async () => {
    await this.handleFetchData();
    try {
      await this.props.getEngagementById(
        this.props.match.params.id,
        this.props.headers
      );
      if (!incrementObject(this.props.match.params.id)) {
        this.props.lockUser(this.props.header);
        handleLogout(
          this.props.userId,
          this.props.username,
          this.props.headers,
          this.props.createAudit,
          this.props.logout
        );
      }
    } catch (ex) {
      this.setState({
        canView: false
      });
      throw ex;
    } finally {
      if (
        this.state.mode === "view" &&
        this.props.selectedEngagementData.requestId
      ) {
        await this.props.getDeletePermissionStatus(
          this.props.selectedEngagementData.requestId
        );
        await this.setState(
          { permissionStatus: this.props.permissionStatus },
          async () => {
            if (this.state.permissionStatus === strings.states.rejected) {
              await this.props.updateSelectedEngagement("requestId", null);
              this.props.updateEngagement(
                this.props.selectedEngagementData.engagementId,
                this.props.selectedEngagementData,
                this.props.headers,
                this.state.initialEngagementData,
                false
              );
            }
          }
        );
        this.props.clearAmendmentRequest();
      }
      this.setState({
        fetchedPermissionStatus: true,
        globalProgrammeId: localStorage.getItem("programmeId"),
        initialEngagementData: this.props.selectedEngagementData
      });

      let form = document.getElementById("engagementForm");
      if (form) {
        form.setAttribute("novalidate", true);
        //set the form to have the novalidate attribute to suppress the default html validation tooltips
      }
      if (this.props.match.params.referralId) {
        await this.props.getReferralById(
          this.props.match.params.referralId,
          this.props.headers
        );
      }
      if (this.state.globalProgrammeId) {
        await this.props.getProgrammeById(
          this.state.globalProgrammeId,
          this.props.headers
        );
      }
      localStorage.setItem("engagementId", this.props.match.params.id);
    }

    if (this.props.selectedProgrammeData.referralId) {
      await this.props.getReferralById(
        this.props.selectedProgrammeData.referralId,
        this.props.headers
      );
    }
    localStorage.setItem("engagementId", this.props.match.params.id);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      prevProps.selectedProgrammeData.referralId !==
        this.props.selectedProgrammeData.referralId &&
      this.props.selectedProgrammeData.referralId
    ) {
      await this.props.getReferralById(
        this.props.selectedProgrammeData.referralId,
        this.props.headers
      );
    }
  };

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedEngagementData.engagementId,
      this.state.deletionReason.value,
      strings.amendments.fullName,
      this.props.selectedEngagementData.crmId,
      this.props.headers,
      this.props.selectedEngagementData
    );
    this.props.updateSelectedEngagement("requestId", this.props.requestId);
    this.props.updateEngagement(
      this.props.selectedEngagementData.engagementId,
      this.props.selectedEngagementData,
      this.props.headers,
      this.state.initialEngagementData
    );
    this.setState({
      initialEngagementData: this.props.selectedEngagementData
    });
    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearEngagementRequest();
    }, 10000);
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteEngagement(
      this.props.selectedEngagementData.engagementId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      await this.props.setAmendmentStatusActioned(
        this.props.selectedEngagementData.requestId,
        this.props.headers,
        true,
        {
          entity: strings.amendments.entity,
          id: this.props.selectedEngagementData.engagementId,
          type: strings.amendments.deletion
        }
      );

      if (this.props.match && this.props.match.params) {
        const { referralId } = this.props.match.params;
        if (referralId) {
          this.props.history.push(`/referrals/view/${referralId}`);
        } else {
          this.props.history.push("/engagements");
        }
      }
    }

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearEngagementRequest();
    }, 10000);
  };

  componentWillUnmount() {
    this.props.clearEngagementRequest();
    this.props.clearSelectedEngagement();
    this.props.clearSelectedReferral();
    this.props.clearAmendmentRequest();
  }

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedEngagementData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.engagements.modifyEngagement
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  filesHaveChanged = () => {
    this.setState({ hasFileChanges: true });
  };

  handleSubmit = async () => {
    if (this.state.hasFileChanges) {
      await commitFileChanges(
        this.props.commitChanges,
        this.props.localData,
        this.props.remoteData,
        strings.folder.engagementFiles,
        strings.entity.engagement,
        this.props.selectedEngagementData.engagementId
      );
    }

    const data = textFormattingHelpers.decodeData(
      this.props.selectedEngagementData,
      this.state.initialEngagementData,
      textFields
    );

    await this.props.updateEngagement(
      this.props.match.params.id,
      data,
      this.props.headers,
      this.state.initialEngagementData
    );
    if (this.state.addActivities) {
      this.props.history.push(
        "/activities/create/" + this.props.selectedEngagementData.engagementId
      );
    } else {
      this.handleModeSwitch();
      window.setTimeout(() => {
        this.props.clearEngagementRequest();
      }, 10000);
    }
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedEngagement(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedEngagement(data.name, data.value);
    }
    if (data.name === "isSuccessfulValue") {
      await this.props.updateSelectedEngagement("isNotSuccessfulReason", "");
    } else if (data.name === "type") {
      await this.props.updateSelectedEngagement("outcomeOfReferralMeeting", "");
      await this.props.updateSelectedEngagement("outcomeOfSignupMeeting", "");
      await this.props.updateSelectedEngagement("consent", "");
    }
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedEngagementData.active
    };
    await this.props.updateEngagement(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialEngagementData
    );
  };

  handleToggleAudit = () => {
    const isAudit = this.state.showAuditLog;
    this.setState({ showAuditLog: !isAudit });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;

      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableEngagementModalVisibility = () => {
    this.setState({
      enableDisableEngagementModalOpen: !this.state
        .enableDisableEngagementModalOpen
    });
  };

  handleAddActivity = () => {
    if (this.state.mode === "edit") {
      this.setState({ addActivities: true });
      this.validateForm();
    } else {
      this.props.history.push(
        "/activities/create/" + this.props.selectedEngagementData.engagementId
      );
    }
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialEngagementData) !==
        JSON.stringify(this.props.selectedEngagementData)
      ) {
        await this.props.cancelSelectedEngagementUpdate(
          this.state.initialEngagementData
        );
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialEngagementData: JSON.parse(
          JSON.stringify(this.props.selectedEngagementData)
        )
      });
    }
  };

  getSignUpOutcomeOptions = () => {
    return calculateSignUpOutcomeOptions(this.props.selectedReferralData);
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.match.params.referralId
        ? this.props.selectedReferralData
        : this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      !this.state.fetchedPermissionStatus ||
      this.props.referralLoadingPage ||
      this.props.programmeLoadingPage ||
      this.props.orgsLoadingPage ||
      this.props.amendmentLoadingPage;

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.requestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          {this.props.showNotification && (
            <RequestFeedback
              className={
                this.props.amendmentRequestStatus ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.engagementRequestStatus}
              requestMade={this.props.engagementRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.engagementRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyEngagement
            mode={this.state.mode}
            role={this.props.roleId}
            requestStatus={this.props.engagementRequestStatus}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editEngagement
                : strings.header.viewEngagement
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnableDisableEngagementModalVisibility={
              this.toggleEnableDisableEngagementModalVisibility
            }
            selectedEngagementData={this.props.selectedEngagementData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enableDisableEngagementModalOpen={
              this.state.enableDisableEngagementModalOpen
            }
            initialEngagementData={this.state.initialEngagementData}
            formInvalid={this.state.formInvalid}
            validateForm={this.validateForm}
            handleAddActivity={this.handleAddActivity}
            validationResults={this.state.validationResults}
            type={this.state.engagementType}
            getOrganisations={this.getOrganisations}
            filesHaveChanged={this.filesHaveChanged}
            hasFileChanges={this.state.hasFileChanges}
            headers={this.props.headers}
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            orgOfLoggedInUser={this.props.organisationOfLoggedInUser}
            getSignUpOutcomeOptions={this.getSignUpOutcomeOptions()}
            isDisabled={this.isDisabled()}
            handleToggleAudit={this.handleToggleAudit}
            showAuditLog={this.state.showAuditLog}
          />
          {this.state.canView && (
            <div className="embeddedComponent">
              <ActivityListContainer
                headers={this.props.headers}
                history={this.props.history}
                engagementId={this.props.match.params.id}
                referralId={this.props.match.params.referralId}
                hideSearchBar={true}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
}

EngagementEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedEngagementData: PropTypes.object.isRequired,
  updateSelectedEngagement: PropTypes.func.isRequired,
  updateEngagement: PropTypes.func.isRequired,
  getEngagementById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const {
    engagements,
    auth,
    amendments,
    referrals,
    programmes,
    file,
    organisations
  } = state;
  const {
    loadingPage,
    selectedEngagementData,
    engagementRequestStatus,
    engagementRequestMade,
    result,
    error,
    errorDetails,
    deleteSuccessful,
    showNotification
  } = engagements;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const { roleId, organisationOfLoggedInUser, userId, username } = auth;
  const { selectedReferralData, loadingPage: referralLoadingPage } = referrals;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails,
    loadingPage: amendmentLoadingPage
  } = amendments;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  const { localData, remoteData } = file;
  return {
    loadingPage,
    selectedEngagementData,
    engagementRequestStatus,
    engagementRequestMade,
    result,
    error,
    roleId,
    errorDetails,
    organisationListData,
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails,
    deleteSuccessful,
    showNotification,
    organisationOfLoggedInUser,
    selectedReferralData,
    selectedProgrammeData,
    programmeLoadingPage,
    referralLoadingPage,
    orgsLoadingPage,
    localData,
    remoteData,
    userId,
    username,
    amendmentLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateEngagement: (
      id,
      data,
      headers,
      initialEngagementData,
      showNotification = true
    ) => {
      dispatch(
        engagementActions.updateEngagement(
          id,
          data,
          headers,
          initialEngagementData,
          showNotification
        )
      );
    },
    getEngagementById: async (id, headers) => {
      await dispatch(engagementActions.getEngagementById(id, headers));
    },
    updateSelectedEngagement: (key, value) => {
      dispatch(engagementActions.updateSelectedEngagement(key, value));
    },
    cancelSelectedEngagementUpdate: data => {
      dispatch(engagementActions.cancelSelectedEngagementUpdate(data));
    },
    clearEngagementRequest: () => {
      dispatch(engagementActions.clearEngagementRequest());
    },
    clearSelectedEngagement: () => {
      dispatch(engagementActions.clearSelectedEngagement());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    requestDeletePermission: async (
      id,
      reason,
      entity,
      recordIdentifier,
      headers,
      body
    ) => {
      await dispatch(
        amendmentActions.requestDeletePermission(
          id,
          reason,
          entity,
          recordIdentifier,
          headers,
          body
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteEngagement: async (id, headers) => {
      await dispatch(engagementActions.deleteEngagement(id, headers));
    },
    setAmendmentStatusActioned: async (
      id,
      headers,
      sendNotification,
      entityDetails
    ) => {
      await dispatch(
        amendmentActions.setAmendmentStatusActioned(
          id,
          headers,
          sendNotification,
          entityDetails
        )
      );
    },
    getReferralById: (id, headers) => {
      dispatch(referralActions.getReferralById(id, headers));
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearSelectedReferral: () => {
      dispatch(referralActions.clearSelectedReferral());
    },
    commitChanges: (
      localData,
      remoteData,
      filePath,
      headers,
      entity,
      entityId,
      folder
    ) => {
      return dispatch(
        fileActions.commitChanges(
          localData,
          remoteData,
          filePath,
          headers,
          entity,
          entityId,
          folder
        )
      );
    },
    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    lockUser: headers => {
      dispatch(userActions.lockUser(headers));
    },
    logout: hasExceeded => {
      dispatch(authActions.signOut(hasExceeded));
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EngagementEditContainer);
