import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

export default function FormBoxWithHeader({
  header,
  children,
  isInfoBlock,
  isFormWrapper
}) {
  if (isInfoBlock) {
    return (
      <div className="infoBlock">
        <div className="infoHeader">{header}</div>
        <div className="infoWrapper">{children}</div>
      </div>
    );
  } else if (isFormWrapper) {
    return (
      <>
        <div className="infoHeader">{header}</div>
        <div className="infoWrapper">{children}</div>
      </>
    );
  } else {
    return (
      <Form>
        <div className="infoBlock">
          <div className="infoHeader">{header}</div>
          <div className="infoWrapper">{children}</div>
        </div>
      </Form>
    );
  }
}

FormBoxWithHeader.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]).isRequired
};
