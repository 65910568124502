import React from "react";
import { Form, Message } from "semantic-ui-react";
import PropTypes from "prop-types";
import { strings } from "../../resources";

const DisplayValidatedInfo = props => {
  return (
    <Form.Field className={props.className} width={props.formFieldWidth}>
      <div className="inputLabel">
        {`${props.inputLabel} `}
        {props.popUp}
      </div>
      <div className="displayData">{props.inputData || (props.noDefaultText ? null : strings.text.none)}</div>
      {props.displayInfoMessage && (
        <Message attached="bottom" visible content={props.messageContent} />
      )}
    </Form.Field>
  );
};

DisplayValidatedInfo.propTypes = {
  inputData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputLabel: PropTypes.string.isRequired,
  formFieldWidth: PropTypes.number
};

export default DisplayValidatedInfo;
