import React from "react";
import ReactTable from "react-table";
import { TableFunctions } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";

class TableComponent extends React.Component {
  mapSearchResultsToRows = () => {
    const { historyListData } = this.props;

    let data = [];
    if (historyListData) {
      historyListData.forEach((result, index) => {
        data.push(result);
      });
    }
    return data;
  };

  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      if (column.id !== "delete" && column.id !== "active") {
        return {
          onClick: () => {
            if (rowInfo) {
              if (rowInfo.original.programmeId) {
                localStorage.setItem(
                  "programmeId",
                  rowInfo.original.programmeId
                );
              }
              if (strings.assessmentTypes.includes(rowInfo.original.table)) {
                this.props.history.push(
                  `/${strings.urlMapping[rowInfo.original.table]}`
                );
              } else if (
                rowInfo.original.table === strings.entities.activities
              ) {
                this.props.history.push(
                  `/${
                    strings.urlMapping[rowInfo.original.table]
                  }/view/undefined/${rowInfo.original.entityId}`
                );
              } else {
                this.props.history.push(
                  `/${strings.urlMapping[rowInfo.original.table]}/view/${
                    rowInfo.original.entityId
                  }`
                );
              }
            }
          }
        };
      }
    }
    return {}; // getTdProps requires a return statement
  };

  render = () => {
    let data = this.mapSearchResultsToRows();
    let pages = Math.ceil(this.props.totalResults / this.props.pageSize);
    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };

    const columns = [
      {
        Header: strings.table.header.username,
        accessor: "username",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.table,
        accessor: "table",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.crmId,
        accessor: "crmId",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.dbActionType,
        accessor: "dbActionType",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      }
    ];
    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          handleRefresh={this.props.handleRefresh}
          headerList={strings.header.historyList}
          buttonAddLabel={strings.button.addHistoryLabel}
          permissionsCreate={[]}
          headerClass="pageHeader"
          refreshButtonClass="refreshButton"
          searchBarQuery={this.props.searchBarQuery}
          handleChange={this.props.handleChange}
          handleSubmit={this.props.handleSubmit}
          hideCreate={true}
          parentPage={this.props.parentPage}
          returnToParent={this.props.returnToParent}
        />
        <div>
          <ReactTable
            manual
            data={data}
            pages={pages}
            defaultPageSize={this.props.pageSize}
            getTdProps={this.getTdProps}
            showPagination={true}
            onFetchData={(state, instance) => {
              this.props.handleFetchData(state);
              this.props.updatePageIndex(state);
            }}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText={strings.table.text.noResults}
            previousText="<"
            nextText=">"
            pageText=""
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  handleModalConfirm: PropTypes.func.isRequired,
  historyListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  updatePageIndex: PropTypes.func.isRequired,
  isCreateDisabled: PropTypes.bool.isRequired
};

export default TableComponent;
