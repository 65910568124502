export const dummyRecordActionTypes = {
  CREATE_DUMMY_RECORD_REQUEST: "CREATE_DUMMY_RECORD_REQUEST",
  CREATE_DUMMY_RECORD_SUCCESS: "CREATE_DUMMY_RECORD_SUCCESS",
  CREATE_DUMMY_RECORD_FAILURE: "CREATE_DUMMY_RECORD_FAILURE",

  CLEAR_CREATE_DUMMY_RECORD_REQUEST: "CLEAR_CREATE_DUMMY_RECORD_REQUEST",
  CLEAR_CREATE_DUMMY_RECORD_FAILURE: "CLEAR_CREATE_DUMMY_RECORD_FAILURE",
  CLEAR_CREATE_DUMMY_RECORD_SUCCESS: "CLEAR_CREATE_DUMMY_RECORD_SUCCESS",

  DELETE_DUMMY_RECORD_REQUEST: "DELETE_DUMMY_RECORD_REQUEST",
  DELETE_DUMMY_RECORD_SUCCESS: "DELETE_DUMMY_RECORD_SUCCESS",
  DELETE_DUMMY_RECORD_FAILURE: "DELETE_DUMMY_RECORD_FAILURE",

  UPDATE_SELECTED_DUMMY_RECORD_REQUEST: "UPDATE_SELECTED_DUMMY_RECORD_REQUEST",
  UPDATE_SELECTED_DUMMY_RECORD_SUCCESS: "UPDATE_SELECTED_DUMMY_RECORD_SUCCESS",
  UPDATE_SELECTED_DUMMY_RECORD_FAILURE: "UPDATE_SELECTED_DUMMY_RECORD_FAILURE",

  CLEAR_SELECTED_DUMMY_RECORD_REQUEST: "CLEAR_SELECTED_DUMMY_RECORD_REQUEST",
  CLEAR_SELECTED_DUMMY_RECORD_SUCCESS: "CLEAR_SELECTED_DUMMY_RECORD_SUCCESS",
  CLEAR_SELECTED_DUMMY_RECORD_FAILURE: "CLEAR_SELECTED_DUMMY_RECORD_FAILURE",

  CANCEL_SELECTED_DUMMY_RECORD_UPDATE_REQUEST:
    "CANCEL_SELECTED_DUMMY_RECORD_UPDATE_REQUEST",
  CANCEL_SELECTED_DUMMY_RECORD_UPDATE_SUCCESS:
    "CANCEL_SELECTED_DUMMY_RECORD_UPDATE_SUCCESS",
  CANCEL_SELECTED_DUMMY_RECORD_UPDATE_FAILURE:
    "CANCEL_SELECTED_DUMMY_RECORD_UPDATE_FAILURE",

  GET_DUMMY_RECORDS_REQUEST: "GET_DUMMY_RECORDS_REQUEST",
  GET_DUMMY_RECORDS_SUCCESS: "GET_DUMMY_RECORDS_SUCCESS",
  GET_DUMMY_RECORDS_FAILURE: "GET_DUMMY_RECORDS_FAILURE",

  UPDATE_DUMMY_RECORD_REQUEST: "UPDATE_DUMMY_RECORD_REQUEST",
  UPDATE_DUMMY_RECORD_SUCCESS: "UPDATE_DUMMY_RECORD_SUCCESS",
  UPDATE_DUMMY_RECORD_FAILURE: "UPDATE_DUMMY_RECORD_FAILURE",

  GET_DUMMY_RECORD_BY_ID_REQUEST: "GET_DUMMY_RECORD_BY_ID_REQUEST",
  GET_DUMMY_RECORD_BY_ID_SUCCESS: "GET_DUMMY_RECORD_BY_ID_SUCCESS",
  GET_DUMMY_RECORD_BY_ID_FAILURE: "GET_DUMMY_RECORD_BY_ID_FAILURE",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE"
};
