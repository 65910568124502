export const ttgEngagementActionTypes = {
  CREATE_TTG_ENGAGEMENT_RECORD_REQUEST: "CREATE_TTG_ENGAGEMENT_RECORD_REQUEST",
  CREATE_TTG_ENGAGEMENT_RECORD_SUCCESS: "CREATE_TTG_ENGAGEMENT_RECORD_SUCCESS",
  CREATE_TTG_ENGAGEMENT_RECORD_FAILURE: "CREATE_TTG_ENGAGEMENT_RECORD_FAILURE",

  CLEAR_CREATE_TTG_ENGAGEMENT_RECORD_REQUEST:
    "CLEAR_CREATE_TTG_ENGAGEMENT_RECORD_REQUEST",
  CLEAR_CREATE_TTG_ENGAGEMENT_RECORD_FAILURE:
    "CLEAR_CREATE_TTG_ENGAGEMENT_RECORD_FAILURE",
  CLEAR_CREATE_TTG_ENGAGEMENT_RECORD_SUCCESS:
    "CLEAR_CREATE_TTG_ENGAGEMENT_RECORD_SUCCESS",

  DELETE_TTG_ENGAGEMENT_RECORD_REQUEST: "DELETE_TTG_ENGAGEMENT_RECORD_REQUEST",
  DELETE_TTG_ENGAGEMENT_RECORD_SUCCESS: "DELETE_TTG_ENGAGEMENT_RECORD_SUCCESS",
  DELETE_TTG_ENGAGEMENT_RECORD_FAILURE: "DELETE_TTG_ENGAGEMENT_RECORD_FAILURE",

  UPDATE_SELECTED_TTG_ENGAGEMENT_RECORD_REQUEST:
    "UPDATE_SELECTED_TTG_ENGAGEMENT_RECORD_REQUEST",
  UPDATE_SELECTED_TTG_ENGAGEMENT_RECORD_SUCCESS:
    "UPDATE_SELECTED_TTG_ENGAGEMENT_RECORD_SUCCESS",
  UPDATE_SELECTED_TTG_ENGAGEMENT_RECORD_FAILURE:
    "UPDATE_SELECTED_TTG_ENGAGEMENT_RECORD_FAILURE",

  CLEAR_SELECTED_TTG_ENGAGEMENT_RECORD_REQUEST:
    "CLEAR_SELECTED_TTG_ENGAGEMENT_RECORD_REQUEST",
  CLEAR_SELECTED_TTG_ENGAGEMENT_RECORD_SUCCESS:
    "CLEAR_SELECTED_TTG_ENGAGEMENT_RECORD_SUCCESS",
  CLEAR_SELECTED_TTG_ENGAGEMENT_RECORD_FAILURE:
    "CLEAR_SELECTED_TTG_ENGAGEMENT_RECORD_FAILURE",

  CANCEL_SELECTED_TTG_ENGAGEMENT_RECORD_UPDATE_REQUEST:
    "CANCEL_SELECTED_TTG_ENGAGEMENT_RECORD_UPDATE_REQUEST",
  CANCEL_SELECTED_TTG_ENGAGEMENT_RECORD_UPDATE_SUCCESS:
    "CANCEL_SELECTED_TTG_ENGAGEMENT_RECORD_UPDATE_SUCCESS",
  CANCEL_SELECTED_TTG_ENGAGEMENT_RECORD_UPDATE_FAILURE:
    "CANCEL_SELECTED_TTG_ENGAGEMENT_RECORD_UPDATE_FAILURE",

  GET_TTG_ENGAGEMENT_RECORDS_REQUEST: "GET_TTG_ENGAGEMENT_RECORDS_REQUEST",
  GET_TTG_ENGAGEMENT_RECORDS_SUCCESS: "GET_TTG_ENGAGEMENT_RECORDS_SUCCESS",
  GET_TTG_ENGAGEMENT_RECORDS_FAILURE: "GET_TTG_ENGAGEMENT_RECORDS_FAILURE",

  UPDATE_TTG_ENGAGEMENT_RECORD_REQUEST: "UPDATE_TTG_ENGAGEMENT_RECORD_REQUEST",
  UPDATE_TTG_ENGAGEMENT_RECORD_SUCCESS: "UPDATE_TTG_ENGAGEMENT_RECORD_SUCCESS",
  UPDATE_TTG_ENGAGEMENT_RECORD_FAILURE: "UPDATE_TTG_ENGAGEMENT_RECORD_FAILURE",

  GET_TTG_ENGAGEMENT_RECORD_BY_ID_REQUEST:
    "GET_TTG_ENGAGEMENT_RECORD_BY_ID_REQUEST",
  GET_TTG_ENGAGEMENT_RECORD_BY_ID_SUCCESS:
    "GET_TTG_ENGAGEMENT_RECORD_BY_ID_SUCCESS",
  GET_TTG_ENGAGEMENT_RECORD_BY_ID_FAILURE:
    "GET_TTG_ENGAGEMENT_RECORD_BY_ID_FAILURE",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE"
};
