const accessingSpecialistSupportService = {
  "Physical Health": [
    ["S2", "S4A", "S5A"],
    ["S3", "M2", "M3", "M4A"]
  ],
  "Mental Health": [
    ["S2", "S4B", "S5B"],
    ["S3", "M2", "M3", "M4B"]
  ],
  "Substance Misuse": [
    ["S2", "S4C", "S5C"],
    ["S3", "M2", "M3", "M4C"]
  ],
  Accommodation: [
    ["S2", "S5D"],
    ["S3", "M2", "M3", "M5"]
  ],
  Relationships: [
    ["S2", "S5E"],
    ["S3", "M2", "M3", "M6"]
  ],
  Finance: [
    ["S2", "S5F"],
    ["S3", "M2", "M3", "M7"]
  ],
  Employment: [
    ["S2", "S5G"],
    ["S3", "M2", "M3", "M8"]
  ],
  "Education & Training": [
    ["S2", "S5H"],
    ["S3", "M2", "M3", "M8"]
  ],
  "Attitudes & Behaviours": [["S2"], ["S3", "M2", "M3"]],
  "Social & Leisure": [["S2"], ["S3", "M2", "M3"]]
};

const accessingSupportServicePrison = {
  "Physical Health": [
    ["S2", "S4A", "S5A"],
    ["S3", "M2", "M3", "M4A"]
  ],
  "Mental Health": [
    ["S2", "S4B", "S5B"],
    ["S3", "M2", "M3", "M4B"]
  ],
  "Substance Misuse": [
    ["S2", "S4C", "S5C"],
    ["S3", "M2", "M3", "M4C"]
  ],
  Accommodation: [
    ["S2", "S5D"],
    ["S3", "M2", "M3", "M5"]
  ],
  Relationships: [
    ["S2", "S5E"],
    ["S3", "M2", "M3", "M6"]
  ],
  Finance: [
    ["S2", "S5F"],
    ["S3", "M2", "M3", "M7"]
  ],
  Employment: [
    ["S2", "S5G"],
    ["S3", "M2", "M3", "M8"]
  ],
  "Education & Training": [
    ["S2", "S5H"],
    ["S3", "M2", "M3", "M8"]
  ],
  "Attitudes & Behaviours": [["S2"], ["S3", "M2", "M3"]],
  "Social & Leisure": [["S2"], ["S3", "M2", "M3"]]
};

const accessingSupportServiceCommunity = {
  "Physical Health": [
    ["S2", "S4A", "S5A"],
    ["S3", "M2", "M3", "M4A"]
  ],
  "Mental Health": [
    ["S2", "S4B", "S5B"],
    ["S3", "M2", "M3", "M4B"]
  ],
  "Substance Misuse": [
    ["S2", "S4C", "S5C"],
    ["S3", "M2", "M3", "M4C"]
  ],
  Accommodation: [
    ["S2", "S5D"],
    ["S3", "M2", "M3", "M5"]
  ],
  Relationships: [
    ["S2", "S5E"],
    ["S3", "M2", "M3", "M6"]
  ],
  Finance: [
    ["S2", "S5F"],
    ["S3", "M2", "M3", "M7"]
  ],
  Employment: [
    ["S2", "S5G"],
    ["S3", "M2", "M3", "M8"]
  ],
  "Education & Training": [
    ["S2", "S5H"],
    ["S3", "M2", "M3", "M8"]
  ],
  "Attitudes & Behaviours": [["S2"], ["S3", "M2", "M3"]],
  "Social & Leisure": [["S2"], ["S3", "M2", "M3"]]
};

const worksWithSpecialistOutcomes = {
  "Physical Health": [[], ["S3", "S4A", "S5A", "M3", "M4A"]],
  "Mental Health": [[], ["S3", "S4B", "S5B", "M3", "M4B"]],
  "Substance Misuse": [[], ["S3", "S4C", "S5C", "M3", "M4C"]],
  Accommodation: [[], ["S3", "S5D", "M3", "M5"]],
  Relationships: [[], ["S3", "S5E", "M3", "M6"]],
  Finance: [[], ["S3", "S5F", "M3", "M7"]],
  Employment: [[], ["S3", "S5G", "M3", "M8"]],
  "Education & Training": [[], ["S3", "S5H", "M3", "M8"]],
  "Attitudes & Behaviours": [[], ["S3", "M3"]],
  "Social & Leisure": [[], ["S3", "M3"]]
};

const participatesRecreationalActivityOutcomes = {
  "Physical Health": [[], ["S3", "S5A", "M3", "M4A"]],
  "Mental Health": [[], ["S3", "S5B", "M3", "M4B"]],
  "Substance Misuse": [[], ["S3", "S5C", "M3", "M4C"]],
  Relationships: [[], ["S3", "S5E", "M3"]],
  Finance: [[], ["S3", "S5F", "M3"]],
  Employment: [[], ["S3", "S5G", "M3"]],
  "Education & Training": [[], ["S3", "S5H", "M3"]],
  "Attitudes & Behaviours": [[], ["S3", "M3"]],
  "Social & Leisure": [[], ["S3", "M3"]]
};

const registersWithGP = {
  "Physical Health": [[], ["S4A", "M4A"]],
  "Mental Health": [[], ["S4B", "M4B"]],
  "Substance Misuse": [[], ["S4C", "M4C"]],
  "Attitudes & Behaviours": [[], ["S3", "M3"]]
};

const registersWithDentist = {
  "Physical Health": [[], ["S4A", "M4A"]],
  "Mental Health": [[], ["S4B", "M4B"]],
  "Substance Misuse": [[], ["S4C", "M4C"]],
  "Attitudes & Behaviours": [[], ["S3", "M3"]]
};

const engagesWithFEProviderOutcomes = {
  "Physical Health": [
    ["S2", "S4A", "S5A"],
    ["S3", "M2", "M3"]
  ],
  "Mental Health": [
    ["S2", "S4B", "S5B"],
    ["S3", "M2", "M3"]
  ],
  "Substance Misuse": [
    ["S2", "S4C", "S5C"],
    ["S3", "M2", "M3"]
  ],
  Accommodation: [
    ["S2", "S5D"],
    ["S3", "M2", "M3"]
  ],
  Relationships: [
    ["S2", "S5E"],
    ["S3", "M2", "M3"]
  ],
  Finance: [
    ["S2", "S5F"],
    ["S3", "M2", "M3"]
  ],
  Employment: [
    ["S2", "S5G"],
    ["S3", "M2", "M3"]
  ],
  "Education & Training": [
    ["S2", "S5H"],
    ["S3", "M2", "M3"]
  ],
  "Attitudes & Behaviours": [["S2"], ["S3", "M2", "M3"]],
  "Social & Leisure": [["S2"], ["S3", "M2", "M3"]]
};

const engagesWithTrainingProviderOutcomes = {
  "Physical Health": [
    ["S2", "S5A"],
    ["S3", "M2", "M3", "M4A"]
  ],
  "Mental Health": [
    ["S2", "S5B"],
    ["S3", "M2", "M3", "M4B"]
  ],
  "Substance Misuse": [
    ["S2", "S5C"],
    ["S3", "M2", "M3", "M4C"]
  ],
  Accommodation: [
    ["S2", "S5D"],
    ["S3", "M2", "M3", "M5"]
  ],
  Relationships: [
    ["S2", "S5E"],
    ["S3", "M2", "M3", "M6"]
  ],
  Finance: [
    ["S2", "S5F"],
    ["S3", "M2", "M3", "M7"]
  ],
  Employment: [
    ["S2", "S5G"],
    ["S3", "M2", "M3", "M8"]
  ],
  "Education & Training": [
    ["S2", "S5H"],
    ["S3", "M2", "M3", "M8"]
  ],
  "Attitudes & Behaviours": [["S2"], ["S3", "M2", "M3"]],
  "Social & Leisure": [["S2"], ["S3", "M2", "M3"]]
};

const engagesWithVolunteerProviderOutcomes = {
  Employment: [["S2"], ["S3", "S6", "M3"]],
  "Education & Training": [["S2"], ["S3", "S6", "M3"]],
  "Attitudes & Behaviours": [["S2"], ["S3", "S6", "M3"]],
  "Social & Leisure": [["S2"], ["S3", "S6", "M3"]]
};

const engagesWithWorkPlacementProviderOutcomes = {
  Employment: [["S2"], ["S3", "S6", "M3"]],
  "Education & Training": [["S2"], ["S3", "S6", "M3"]],
  "Attitudes & Behaviours": [["S2"], ["S3", "S6", "M3"]],
  "Social & Leisure": [["S2"], ["S3", "S6", "M3"]]
};

const completesFurtherEducationCourseOutcomes = {
  "Physical Health": [[], ["M2", "M3", "M8"]],
  "Mental Health": [[], ["M2", "M3", "M8"]],
  "Substance Misuse": [[], ["M2", "M3", "M8"]],
  Accommodation: [[], ["M2", "M3", "M8"]],
  Relationships: [[], ["M2", "M3", "M8"]],
  Finance: [[], ["M2", "M3", "M8"]],
  Employment: [[], ["M2", "M3", "M8"]],
  "Education & Training": [[], ["M2", "M3", "M8"]],
  "Attitudes & Behaviours": [[], ["M2", "M3", "M8"]],
  "Social & Leisure": [[], ["M2", "M3", "M8"]]
};

const engagesWithEmploymentAgencyOutcomes = {
  Employment: [["S2"], ["S3", "S6"]],
  "Education & Training": [["S2"], ["S3", "S6"]],
  "Attitudes & Behaviours": [["S2"], ["S3", "S6"]]
};

const engagesWithServiceBeforeAfterReleaseOutcomes = {
  "Attitudes & Behaviours": [[], ["M1"]]
};

const completesTrainingCourseOutcomes = {
  "Physical Health": [[], ["M2", "M3", "M4A", "M8"]],
  "Mental Health": [[], ["M2", "M3", "M4B", "M8"]],
  "Substance Misuse": [[], ["M2", "M3", "M4C", "M8"]],
  Accommodation: [[], ["M2", "M3", "M8"]],
  Relationships: [[], ["M2", "M3", "M8"]],
  Finance: [[], ["M2", "M3", "M8"]],
  Employment: [[], ["M2", "M3", "M8"]],
  "Education & Training": [[], ["M2", "M3", "M8"]],
  "Attitudes & Behaviours": [[], ["M2", "M3", "M8"]],
  "Social & Leisure": [[], ["M2", "M3", "M8"]]
};

const engagesOrCompletesVolunteeringOpportunityOutcomes = {
  "Physical Health": [[], ["M2", "M8"]],
  "Mental Health": [[], ["M2", "M8"]],
  "Substance Misuse": [[], ["M2", "M8"]],
  Accommodation: [[], ["M2", "M8"]],
  Relationships: [[], ["M2", "M8"]],
  Finance: [[], ["M2", "M8"]],
  Employment: [[], ["M2", "M8"]],
  "Education & Training": [[], ["M2", "M8"]],
  "Attitudes & Behaviours": [[], ["M2", "M8"]],
  "Social & Leisure": [[], ["M2", "M8"]]
};

const engagesOrCompletesWorkPlacementOutcomes = {
  "Physical Health": [[], ["M2", "M8"]],
  "Mental Health": [[], ["M2", "M8"]],
  "Substance Misuse": [[], ["M2", "M8"]],
  Accommodation: [[], ["M2", "M8"]],
  Relationships: [[], ["M2", "M8"]],
  Finance: [[], ["M2", "M8"]],
  Employment: [[], ["M2", "M8"]],
  "Education & Training": [[], ["M2", "M8"]],
  "Attitudes & Behaviours": [[], ["M2", "M8"]],
  "Social & Leisure": [[], ["M2", "M8"]]
};

const jobsearchOutcomes = {
  Employment: [[], ["S3", "S6"]],
  "Attitudes & Behaviours": [[], ["S3", "S6"]]
};

const monthlyFaceToFaceOutcomes = {
  "Attitudes & Behaviours": [[], ["M1"]]
};

const otherOutcomes = {
  "Physical Health": [[], ["S3", "S4A", "S5A", "M3", "M4A"]],
  "Mental Health": [[], ["S3", "S4B", "S5B", "M3", "M4B"]],
  "Substance Misuse": [[], ["S3", "S4C", "S5C", "M3", "M4C"]],
  Accommodation: [[], ["S3", "S5D", "M3", "M5"]],
  Relationships: [[], ["S3", "S5E", "M3", "M6"]],
  Finance: [[], ["S3", "S5F", "M3", "M7"]],
  Employment: [[], ["S3", "S5G", "M3", "M8"]],
  "Education & Training": [[], ["S3", "S5H", "M3", "M8"]],
  "Attitudes & Behaviours": [[], ["S3", "S2", "M3"]],
  "Social & Leisure": [[], ["S3", "S2", "M3"]]
};

const personalReleasePlanUpdatedOutcomes = {
  "Attitudes & Behaviours": [[], ["M1"]]
};

const startsEmploymentOutcomes = {
  Employment: [[], ["M3", "M8"]],
  "Attitudes & Behaviours": [[], ["M3", "M8"]]
};

const weeklyMentorContactOutcomes = {
  "Attitudes & Behaviours": [[], ["M1"]]
};

const attendsPreReleaseMeetingOutcomes = {
  "Attitudes & Behaviours": [[], ["S1"]]
};

const attendsPostReleaseMeetingOutcomes = {
  "Attitudes & Behaviours": [[], ["S1"]]
};

const participatesInSignupMeetingOutcomes = {
  "Attitudes & Behaviours": [[], ["S1"]]
};

const agreesToPRPAssessmentOutcomes = {
  "Attitudes & Behaviours": [[], ["S1"]]
};

const agreesToGateLiberationOutcomes = {
  "Attitudes & Behaviours": [[], ["S1"]]
};

const activityTypeOptions = [
  {
    key: 0,
    text: "Agrees to gate liberation service",
    value: "Agrees to gate liberation service",
    supportservicerequired: 0,
    outcomes: agreesToGateLiberationOutcomes
  },
  {
    key: 1,
    text: "Agrees to personal release plan assessment",
    value: "Agrees to personal release plan assessment",
    supportservicerequired: 0,
    outcomes: agreesToPRPAssessmentOutcomes
  },
  {
    key: 2,
    text: "Personal release plan regularly updated",
    value: "Personal release plan regularly updated",
    supportservicerequired: 0,
    outcomes: personalReleasePlanUpdatedOutcomes
  },
  {
    key: 3,
    text: "Accesses specialist support service",
    value: "Accesses specialist support service",
    supportservicerequired: 1,
    outcomes: accessingSpecialistSupportService
  },
  {
    key: 4,
    text: "Accesses Support Service in prison",
    value: "Accesses Support Service in prison",
    supportservicerequired: 1,
    outcomes: accessingSupportServicePrison
  },
  {
    key: 5,
    text: "Accesses support service in community",
    value: "Accesses support service in community",
    supportservicerequired: 1,
    outcomes: accessingSupportServiceCommunity
  },
  {
    key: 6,
    text: "Engages with employment agency",
    value: "Engages with employment agency",
    supportservicerequired: 0,
    outcomes: engagesWithEmploymentAgencyOutcomes
  },
  {
    key: 7,
    text: "Engages with FE provider",
    value: "Engages with FE provider",
    supportservicerequired: 0,
    outcomes: engagesWithFEProviderOutcomes
  },
  {
    key: 8,
    text: "Engages with service before and after release",
    value: "Engages with service before and after release",
    supportservicerequired: 1,
    outcomes: engagesWithServiceBeforeAfterReleaseOutcomes
  },
  {
    key: 9,
    text: "Engages with training provider",
    value: "Engages with training provider",
    supportservicerequired: 0,
    outcomes: engagesWithTrainingProviderOutcomes
  },
  {
    key: 10,
    text: "Engages with volunteering provider",
    value: "Engages with volunteering provider",
    supportservicerequired: 0,
    outcomes: engagesWithVolunteerProviderOutcomes
  },
  {
    key: 11,
    text: "Engages with, or completes, training course",
    value: "Engages with, or completes, training course",
    supportservicerequired: 0,
    outcomes: completesTrainingCourseOutcomes
  },
  {
    key: 12,
    text: "Engages with, or completes, volunteering opportunity",
    value: "Engages with, or completes, volunteering opportunity",
    supportservicerequired: 0,
    outcomes: engagesOrCompletesVolunteeringOpportunityOutcomes
  },
  {
    key: 13,
    text: "Engages with, or completes, work placement",
    value: "Engages with, or completes, work placement",
    supportservicerequired: 0,
    outcomes: engagesOrCompletesWorkPlacementOutcomes
  },
  {
    key: 14,
    text: "Engages with work placement provider",
    value: "Engages with work placement provider",
    supportservicerequired: 0,
    outcomes: engagesWithWorkPlacementProviderOutcomes
  },
  {
    key: 15,
    text: "Engages with, or completes, FE course",
    value: "Engages with, or completes, FE course",
    supportservicerequired: 0,
    outcomes: completesFurtherEducationCourseOutcomes
  },
  {
    key: 16,
    text: "Job search activities",
    value: "Job search activities",
    supportservicerequired: 0,
    outcomes: jobsearchOutcomes
  },
  {
    key: 17,
    text: "Participates in recreational activities",
    value: "Participates in recreational activities",
    supportservicerequired: 0,
    outcomes: participatesRecreationalActivityOutcomes
  },
  {
    key: 18,
    text: "Participates in sign up meeting",
    value: "Participates in sign up meeting",
    supportservicerequired: 0,
    outcomes: participatesInSignupMeetingOutcomes
  },
  {
    key: 19,
    text: "Registers with dentist",
    value: "Registers with dentist",
    supportservicerequired: 0,
    outcomes: registersWithDentist
  },
  {
    key: 20,
    text: "Registers with GP",
    value: "Registers with GP",
    supportservicerequired: 0,
    outcomes: registersWithGP
  },
  {
    key: 21,
    text: "Starts full or part time employment",
    value: "Starts full or part time employment",
    supportservicerequired: 0,
    outcomes: startsEmploymentOutcomes
  },
  {
    key: 22,
    text: "Attends post release meeting with mentor",
    value: "Attends post release meeting with mentor",
    supportservicerequired: 0,
    outcomes: attendsPostReleaseMeetingOutcomes
  },
  {
    key: 23,
    text: "Attends pre-release meeting with mentor",
    value: "Attends pre-release meeting with mentor",
    supportservicerequired: 0,
    outcomes: attendsPreReleaseMeetingOutcomes
  },
  {
    key: 24,
    text: "Work directly with mentor",
    value: "Work directly with mentor",
    supportservicerequired: 0,
    outcomes: worksWithSpecialistOutcomes
  },
  {
    key: 25,
    text: "Weekly contact with mentor",
    value: "Weekly contact with mentor",
    supportservicerequired: 0,
    outcomes: weeklyMentorContactOutcomes
  },
  {
    key: 26,
    text: "Monthly face to face meetings",
    value: "Monthly face to face meetings",
    supportservicerequired: 0,
    outcomes: monthlyFaceToFaceOutcomes
  },
  {
    key: 27,
    text: "Other",
    value: "Other",
    supportservicerequired: 0,
    outcomes: otherOutcomes
  }
];

const onNRSProgrammeActivityTypeOptions = [
  {
    key: 0,
    text: "Agrees to gate liberation service",
    value: "Agrees to gate liberation service",
    supportservicerequired: 0,
    outcomes: agreesToGateLiberationOutcomes
  },
  {
    key: 1,
    text: "Agrees to personal release plan assessment",
    value: "Agrees to personal release plan assessment",
    supportservicerequired: 0,
    outcomes: agreesToPRPAssessmentOutcomes
  },
  {
    key: 2,
    text: "Personal release plan regularly updated",
    value: "Personal release plan regularly updated",
    supportservicerequired: 0,
    outcomes: personalReleasePlanUpdatedOutcomes
  },
  {
    key: 3,
    text: "Accesses specialist support service",
    value: "Accesses specialist support service",
    supportservicerequired: 1,
    outcomes: accessingSpecialistSupportService
  },
  {
    key: 4,
    text: "Accesses Support Service in prison",
    value: "Accesses Support Service in prison",
    supportservicerequired: 1,
    outcomes: accessingSupportServicePrison
  },
  {
    key: 5,
    text: "Accesses support service in community",
    value: "Accesses support service in community",
    supportservicerequired: 1,
    outcomes: accessingSupportServiceCommunity
  },
  {
    key: 6,
    text: "Engages with employment agency",
    value: "Engages with employment agency",
    supportservicerequired: 0,
    outcomes: engagesWithEmploymentAgencyOutcomes
  },
  {
    key: 7,
    text: "Engages with FE provider",
    value: "Engages with FE provider",
    supportservicerequired: 0,
    outcomes: engagesWithFEProviderOutcomes
  },
  {
    key: 8,
    text: "Engages with service before and after release",
    value: "Engages with service before and after release",
    supportservicerequired: 1,
    outcomes: engagesWithServiceBeforeAfterReleaseOutcomes
  },
  {
    key: 9,
    text: "Engages with training provider",
    value: "Engages with training provider",
    supportservicerequired: 0,
    outcomes: engagesWithTrainingProviderOutcomes
  },
  {
    key: 10,
    text: "Engages with volunteering provider",
    value: "Engages with volunteering provider",
    supportservicerequired: 0,
    outcomes: engagesWithVolunteerProviderOutcomes
  },
  {
    key: 11,
    text: "Engages with, or completes, training course",
    value: "Engages with, or completes, training course",
    supportservicerequired: 0,
    outcomes: completesTrainingCourseOutcomes
  },
  {
    key: 12,
    text: "Engages with, or completes, volunteering opportunity",
    value: "Engages with, or completes, volunteering opportunity",
    supportservicerequired: 0,
    outcomes: engagesOrCompletesVolunteeringOpportunityOutcomes
  },
  {
    key: 13,
    text: "Engages with, or completes, work placement",
    value: "Engages with, or completes, work placement",
    supportservicerequired: 0,
    outcomes: engagesOrCompletesWorkPlacementOutcomes
  },
  {
    key: 14,
    text: "Engages with work placement provider",
    value: "Engages with work placement provider",
    supportservicerequired: 0,
    outcomes: engagesWithWorkPlacementProviderOutcomes
  },
  {
    key: 15,
    text: "Engages with, or completes, FE course",
    value: "Engages with, or completes, FE course",
    supportservicerequired: 0,
    outcomes: completesFurtherEducationCourseOutcomes
  },
  {
    key: 16,
    text: "Job search activities",
    value: "Job search activities",
    supportservicerequired: 0,
    outcomes: jobsearchOutcomes
  },
  {
    key: 17,
    text: "Participates in recreational activities",
    value: "Participates in recreational activities",
    supportservicerequired: 0,
    outcomes: participatesRecreationalActivityOutcomes
  },
  {
    key: 18,
    text: "Participates in sign up meeting",
    value: "Participates in sign up meeting",
    supportservicerequired: 0,
    outcomes: participatesInSignupMeetingOutcomes
  },
  {
    key: 19,
    text: "Registers with dentist",
    value: "Registers with dentist",
    supportservicerequired: 0,
    outcomes: registersWithDentist
  },
  {
    key: 20,
    text: "Registers with GP",
    value: "Registers with GP",
    supportservicerequired: 0,
    outcomes: registersWithGP
  },
  {
    key: 21,
    text: "Starts full or part time employment",
    value: "Starts full or part time employment",
    supportservicerequired: 0,
    outcomes: startsEmploymentOutcomes
  },
  {
    key: 22,
    text: "Attends post release meeting with mentor",
    value: "Attends post release meeting with mentor",
    supportservicerequired: 0,
    outcomes: attendsPostReleaseMeetingOutcomes
  },
  {
    key: 23,
    text: "Attends pre-release meeting with mentor",
    value: "Attends pre-release meeting with mentor",
    supportservicerequired: 0,
    outcomes: attendsPreReleaseMeetingOutcomes
  },
  {
    key: 24,
    text: "Work directly with mentor",
    value: "Work directly with mentor",
    supportservicerequired: 0,
    outcomes: worksWithSpecialistOutcomes
  },
  {
    key: 25,
    text: "Weekly contact with mentor",
    value: "Weekly contact with mentor",
    supportservicerequired: 0,
    outcomes: weeklyMentorContactOutcomes
  },
  {
    key: 26,
    text: "Monthly face to face meetings",
    value: "Monthly face to face meetings",
    supportservicerequired: 0,
    outcomes: monthlyFaceToFaceOutcomes
  },
  {
    key: 27,
    text: "Referral to Support Partner",
    value: "Referral to Support Partner",
    supportservicerequired: 0
  },
  {
    key: 28,
    text: "Other",
    value: "Other",
    supportservicerequired: 0,
    outcomes: otherOutcomes
  }
];

const activityCategoryOptions = [
  {
    key: 0,
    text: "Mental Health",
    value: "Mental Health",
    baseline: "mentalHealth"
  },
  {
    key: 1,
    text: "Physical Health",
    value: "Physical Health",
    baseline: "generalHealth"
  },
  {
    key: 2,
    text: "Substance Misuse",
    value: "Substance Misuse",
    baseline: "addictions"
  },
  {
    key: 3,
    text: "Accommodation",
    value: "Accommodation",
    baseline: "housing"
  },
  {
    key: 4,
    text: "Relationships",
    value: "Relationships",
    baseline: "relationships"
  },
  { key: 5, text: "Finance", value: "Finance", baseline: "finance" },
  { key: 6, text: "Employment", value: "Employment", baseline: "employment" },
  {
    key: 7,
    text: "Education & training",
    value: "Education & training",
    baseline: "educationTraining"
  },
  {
    key: 8,
    text: "Attitudes & behaviours",
    value: "Attitudes & behaviours",
    baseline: "attitudeBehaviour"
  },
  {
    key: 9,
    text: "Social & Leisure",
    value: "Social & Leisure",
    baseline: "socialLeisure"
  }
];

const activityEngagedOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" }
];

const groupedTypes = [
  "Agrees to gate liberation service",
  "Agrees to personal release plan assessment",
  "Participates in sign up meeting",
  "Attends post release meeting with mentor",
  "Attends pre-release meeting with mentor"
];

export {
  activityTypeOptions,
  onNRSProgrammeActivityTypeOptions,
  activityCategoryOptions,
  activityEngagedOptions,
  groupedTypes
};
