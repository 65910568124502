import React from "react";
import ModifyAssessmentSG from "../../components/ModifyAssessmentSG";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, fieldsToStringify } from "../../resources";
import { assessmentSGActions } from "../../store/actions/assessmentSG.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { authActions } from "../../../App/store/actions/auth.actions";
import { auditActions } from "../../../Audit/store/actions/audit.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  convertToIso,
  getOrganisations,
  canUserModifyRecord,
  incrementObject,
  handleLogout
} from "../../helpers";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";
class AssessmentSGEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableAssessmentSGModalOpen: false,
    initialAssessmentSGData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    deletionReason: undefined,
    fetchedPermissionStatus: false,
    permissionStatus: "",
    hasCancelled: false,
    showAuditLog: false
  };

  componentDidMount = async () => {
    try {
      await this.handleFetchData();
      await this.props.getAssessmentSGById(this.props.id, this.props.headers);
      if (!incrementObject(this.props.id)) {
        this.props.lockUser(this.props.header);
        handleLogout(
          this.props.userId,
          this.props.username,
          this.props.headers,
          this.props.createAudit,
          this.props.logout
        );
      }
      if (
        this.state.mode === "view" &&
        this.props.selectedAssessmentSGData.requestId
      ) {
        await this.props.getDeletePermissionStatus(
          this.props.selectedAssessmentSGData.requestId
        );
        await this.setState(
          { permissionStatus: this.props.permissionStatus },
          async () => {
            if (this.state.permissionStatus === strings.states.rejected) {
              await this.props.updateSelectedAssessmentSG("requestId", null);
              this.props.updateAssessmentSG(
                this.props.selectedAssessmentSGData.assessmentId,
                this.props.selectedAssessmentSGData,
                this.props.headers,
                this.state.initialAssessmentSGData,
                false
              );
            }
          }
        );
        this.props.clearAmendmentRequest();
      }
    } catch (ex) {
      throw ex;
    } finally {
      this.setState({
        fetchedPermissionStatus: true,
        globalProgrammeId: localStorage.getItem("programmeId"),
        initialAssessmentSGData: JSON.parse(
          JSON.stringify(this.props.selectedAssessmentSGData)
        )
      });
      let form = document.getElementById("engagementForm");
      if (form) {
        form.setAttribute("novalidate", true);
        //set the form to have the novalidate attribute to suppress the default html validation tooltips
      }
      this.props.getProgrammeById(
        this.state.globalProgrammeId,
        this.props.headers
      );
    }
  };

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );

    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  componentWillUnmount() {
    this.props.clearAssessmentSGRequest();
    this.props.clearSelectedAssessmentSG();
    this.props.clearAmendmentRequest();
  }

  validateForm = async () => {
    let details = JSON.parse(
      JSON.stringify(this.props.selectedAssessmentSGData)
    );
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.assessments.modifySGA
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  stringifyFields = async () => {
    return new Promise((resolve, reject) => {
      Object.keys(this.props.selectedAssessmentSGData).forEach(async field => {
        if (
          fieldsToStringify.includes(field) &&
          typeof this.props.selectedAssessmentSGData[field] !== "string"
        ) {
          await this.props.updateSelectedAssessmentSG(
            field,
            JSON.stringify(this.props.selectedAssessmentSGData[field])
          );
        }
      });
      resolve();
    });
  };

  handleSubmit = async () => {
    await this.stringifyFields();
    await this.props.updateAssessmentSG(
      this.props.id,
      this.props.selectedAssessmentSGData,
      this.props.headers,
      this.state.initialAssessmentSGData
    );
    this.setState({
      initialAssessmentSGData: JSON.parse(
        JSON.stringify(this.props.selectedAssessmentSGData)
      )
    });
    this.handleModeSwitch();

    window.setTimeout(() => {
      this.props.clearAssessmentSGRequest();
    }, 10000);
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.cancelSelectedAssessmentSGUpdate(
      this.state.initialAssessmentSGData
    );
    this.props.history.goBack();
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedAssessmentSGData.assessmentId,
      this.state.deletionReason.value,
      strings.amendments.fullName,
      this.props.selectedAssessmentSGData.crmId,
      this.props.headers,
      this.props.selectedAssessmentSGData
    );
    this.props.updateSelectedAssessmentSG("requestId", this.props.requestId);
    this.props.updateAssessmentSG(
      this.props.selectedAssessmentSGData.assessmentId,
      this.props.selectedAssessmentSGData,
      this.props.headers,
      this.state.initialAssessmentSGData
    );
    this.setState({
      initialAssessmentSGData: JSON.parse(
        JSON.stringify(this.props.selectedAssessmentSGData)
      )
    });

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearAssessmentSGRequest();
    }, 10000);
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteAssessmentSG(
      this.props.selectedAssessmentSGData.assessmentId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      this.props.setAmendmentStatusActioned(
        this.props.selectedAssessmentSGData.requestId
      );

      this.props.history.push(
        `/programmes/view/${localStorage.getItem("programmeId")}`
      );
    }

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearAssessmentSGRequest();
    }, 10000);
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedAssessmentSG(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedAssessmentSG(data.name, data.value);
    }
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedAssessmentSGData.active
    };
    await this.props.updateAssessmentSG(
      this.props.id,
      body,
      this.props.headers,
      this.state.initialAssessmentSGData
    );
  };

  handleToggleAudit = () => {
    const isAudit = this.state.showAuditLog;
    this.setState({ showAuditLog: !isAudit });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableAssessmentSGModalVisibility = () => {
    this.setState({
      enableDisableAssessmentSGModalOpen: !this.state
        .enableDisableAssessmentSGModalOpen
    });
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialAssessmentSGData) !==
        JSON.stringify(this.props.selectedAssessmentSGData)
      ) {
        await this.props.cancelSelectedAssessmentSGUpdate(
          this.state.initialAssessmentSGData
        );
        this.setState({ hasCancelled: true });
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialAssessmentSGData: JSON.parse(
          JSON.stringify(this.props.selectedAssessmentSGData)
        )
      });
    }
  };

  resetCancelledState = () => {
    this.setState(() => ({ hasCancelled: false }));
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      !this.state.fetchedPermissionStatus ||
      this.props.usersLoadingPage ||
      this.props.orgsLoadingPage ||
      this.props.programmeLoadingPage ||
      this.props.amendmentLoadingPage;
    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.requestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          {this.props.showNotification && (
            <RequestFeedback
              className={
                this.props.amendmentRequestStatus ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.assessmentSGRequestStatus}
              requestMade={this.props.assessmentSGRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.assessmentSGRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}

          <ModifyAssessmentSG
            mode={this.state.mode}
            requestStatus={this.props.assessmentSGRequestStatus}
            role={this.props.roleId}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editAssessmentSG
                : strings.header.viewAssessmentSG
            }
            selectedAssessmentSGData={this.props.selectedAssessmentSGData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            initialAssessmentSGData={{}}
            userListData={this.props.userListData}
            getOrganisations={this.getOrganisations}
            updateSelectedAssessmentSG={this.props.updateSelectedAssessmentSG}
            enableDisableAssessmentSGModalOpen={
              this.state.enableDisableAssessmentSGModalOpen
            }
            toggleEnableDisableAssessmentSGModalVisibility={
              this.toggleEnableDisableAssessmentSGModalVisibility
            }
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            isDisabled={this.isDisabled()}
            hasCancelled={this.state.hasCancelled}
            resetCancelledState={this.resetCancelledState}
            headers={this.props.headers}
            handleToggleAudit={this.handleToggleAudit}
            showAuditLog={this.state.showAuditLog}
          />
        </div>
      </div>
    );
  };
}

AssessmentSGEditContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedAssessmentSGData: PropTypes.object.isRequired,
  updateSelectedAssessmentSG: PropTypes.func.isRequired,
  updateAssessmentSG: PropTypes.func.isRequired,
  getAssessmentSGById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const {
    assessmentSG,
    auth,
    amendments,
    users,
    organisations,
    programmes
  } = state;
  const { userListData, loadingPage: usersLoadingPage } = users;
  const {
    loadingPage,
    selectedAssessmentSGData,
    assessmentSGRequestStatus,
    assessmentSGRequestMade,
    result,
    error,
    errorDetails,
    assessmentSGListData,
    deleteSuccessful,
    showNotification
  } = assessmentSG;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const { roleId, organisationOfLoggedInUser, userId, username } = auth;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails,
    loadingPage: amendmentLoadingPage
  } = amendments;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  return {
    loadingPage,
    selectedAssessmentSGData,
    assessmentSGRequestStatus,
    assessmentSGRequestMade,
    result,
    error,
    roleId,
    errorDetails,
    assessmentSGListData,
    organisationListData,
    userListData,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    selectedProgrammeData,
    organisationOfLoggedInUser,
    programmeLoadingPage,
    orgsLoadingPage,
    usersLoadingPage,
    amendmentRequestStatus,
    amendmentRequestMade,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails,
    userId,
    username,
    amendmentLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAssessmentSG: (
      id,
      data,
      headers,
      initialAssessmentSGData,
      showNotification = true
    ) => {
      dispatch(
        assessmentSGActions.updateAssessmentSG(
          id,
          data,
          headers,
          initialAssessmentSGData,
          showNotification
        )
      );
    },
    getAssessmentSGById: async (id, headers) => {
      await dispatch(assessmentSGActions.getAssessmentSGById(id, headers));
    },
    updateSelectedAssessmentSG: (key, value) => {
      dispatch(assessmentSGActions.updateSelectedAssessmentSG(key, value));
    },
    cancelSelectedAssessmentSGUpdate: data => {
      dispatch(assessmentSGActions.cancelSelectedAssessmentSGUpdate(data));
    },
    clearAssessmentSGRequest: () => {
      dispatch(assessmentSGActions.clearAssessmentSGRequest());
    },
    clearSelectedAssessmentSG: () => {
      dispatch(assessmentSGActions.clearSelectedAssessmentSG());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getUsers: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    requestDeletePermission: async (
      id,
      reason,
      entity,
      recordIdentifier,
      headers,
      body
    ) => {
      await dispatch(
        amendmentActions.requestDeletePermission(
          id,
          reason,
          entity,
          recordIdentifier,
          headers,
          body
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteAssessmentSG: async (id, headers) => {
      await dispatch(assessmentSGActions.deleteAssessmentSG(id, headers));
    },
    setAmendmentStatusActioned: (id, headers) => {
      dispatch(amendmentActions.setAmendmentStatusActioned(id, headers));
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    lockUser: headers => {
      dispatch(userActions.lockUser(headers));
    },
    logout: hasExceeded => {
      dispatch(authActions.signOut(hasExceeded));
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentSGEditContainer);
