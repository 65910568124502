import React from "react";
import ModifyBaseline from "../../components/ModifyBaseline";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { baselineActions } from "../../store/actions/baseline.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import PropTypes from "prop-types";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { RequestFeedback } from "../../components";
import {
  convertToIso,
  canUserModifyRecord,
  getOrganisations
} from "../../helpers";
import uuidv4 from "uuid/v4";

class BaselineCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableBaselineModalOpen: false,
    formInvalid: false,
    validationResults: {},
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    isPrePopulated: false,
    unknownRequestStatus: false
  };

  componentDidMount = async () => {
    const sCrit = this.state.searchCriteria;
    this.props.clearSelectedProgramme();
    let form = document.getElementById("baselineForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    await this.props.getProgrammeById(
      this.props.programmeId,
      this.props.headers
    );
  };

  componentDidUpdate = () => {
    if (
      Object.keys(this.props.selectedProgrammeData).length > 0 &&
      !this.state.isPrePopulated
    ) {
      if (this.props.selectedProgrammeData.owner) {
        this.props.updateSelectedBaseline(
          "owner",
          this.props.selectedProgrammeData.owner
        );
      }
      if (this.props.selectedProgrammeData.programmeId) {
        this.props.updateSelectedBaseline(
          "programmeId",
          this.props.selectedProgrammeData.programmeId
        );
      }
      if (this.props.selectedProgrammeData.crmId) {
        this.props.updateSelectedBaseline(
          "programmeCrmId",
          this.props.selectedProgrammeData.crmId
        );
      }
      if (this.props.selectedProgrammeData.scroNumber) {
        this.props.updateSelectedBaseline(
          "sNumber",
          this.props.selectedProgrammeData.scroNumber
        );
      }
      if (this.props.selectedProgrammeData.pNumber) {
        this.props.updateSelectedBaseline(
          "pNumber",
          this.props.selectedProgrammeData.pNumber
        );
      }
      if (
        this.props.selectedProgrammeData.firstName &&
        this.props.selectedProgrammeData.surname
      ) {
        this.props.updateSelectedBaseline(
          "customerName",
          this.props.selectedProgrammeData.firstName +
            " " +
            this.props.selectedProgrammeData.surname
        );
      }
      if (this.props.selectedProgrammeData.area) {
        this.props.updateSelectedBaseline(
          "area",
          this.props.selectedProgrammeData.area
        );
      }
      this.props.updateSelectedBaseline("type", "BA");
      this.setState({ isPrePopulated: true });
    }
  };

  componentWillUnmount() {
    this.props.clearSelectedBaseline();
  }

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedBaselineData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.assessments.modifyBA
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.props.updateSelectedBaseline("assessmentId", uuidv4());
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    await this.props.createBaseline(
      this.props.selectedBaselineData,
      this.props.headers
    );
    window.location.reload();
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearBaselineRequest();
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedBaseline(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedBaseline(data.name, data.value);
    }
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableBaselineModalVisibility = () => {
    this.setState({
      enableDisableBaselineModalOpen: !this.state.enableDisableBaselineModalOpen
    });
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.orgsLoadingPage ||
      this.props.programmeLoadingPage;

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          <RequestFeedback
            requestStatus={this.props.baselineRequestStatus}
            requestMade={this.props.baselineRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={
              strings.form.feedback.baselineRequestUnknown
            }
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <ModifyBaseline
            mode="create"
            role={this.props.roleId}
            pageTitle={strings.header.createBaseline}
            selectedBaselineData={this.props.selectedBaselineData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            enableDisableBaselineModalOpen={
              this.state.enableDisableBaselineModalOpen
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableBaselineModalVisibility={
              this.toggleEnableDisableBaselineModalVisibility
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            initialBaselineData={{}}
            updateSelectedBaseline={this.props.updateSelectedBaseline}
            selectedProgrammeData={this.props.organisationOfLoggedInUser}
            orgOfLoggedInUser={this.props.organisationOfLoggedInUser}
            isDisabled={this.isDisabled()}
            getOrganisations={this.getOrganisations}
          />
        </div>
      </div>
    );
  };
}

BaselineCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedBaselineData: PropTypes.object.isRequired,
  updateSelectedBaseline: PropTypes.func.isRequired,
  clearSelectedBaseline: PropTypes.func.isRequired,
  createBaseline: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    loadingPage,
    selectedBaselineData,
    baselineRequestStatus,
    baselineRequestMade,
    result,
    error,
    errorDetails
  } = state.baselines;
  const {
    loadingPage: orgsLoadingPage,
    organisationListData
  } = state.organisations;
  const { roleId, organisationOfLoggedInUser } = state.auth;
  const {
    selectedProgrammeData,
    programmeRequestStatus,
    loadingPage: programmeLoadingPage
  } = state.programmes;

  return {
    loadingPage,
    selectedBaselineData,
    roleId,
    selectedProgrammeData,
    programmeRequestStatus,
    baselineRequestStatus,
    baselineRequestMade,
    result,
    error,
    errorDetails,
    organisationOfLoggedInUser,
    orgsLoadingPage,
    organisationListData,
    programmeLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createBaseline: async (data, headers) => {
      await dispatch(baselineActions.createBaseline(data, headers));
    },
    updateSelectedBaseline: (key, value) => {
      dispatch(baselineActions.updateSelectedBaseline(key, value));
    },
    clearSelectedBaseline: () => {
      dispatch(baselineActions.clearSelectedBaseline());
    },
    clearBaselineRequest: () => {
      dispatch(baselineActions.clearBaselineRequest());
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaselineCreateContainer);
