import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    fieldValues: { other: "Other", mentor: "mentor" },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    header: {
      login: "Login",
      historyList: "Customer History",
      createHistory: "New History",
      editHistory: "Edit History",
      viewHistory: "History Details",
      loading: "Loading Information"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        administration: "Administration",
        historyDetails: "History Details"
      },
      label: {
        crmId: "CRM ID",
        owner: "Owner",
        date: "Date",
        type: "Type",
        amount: "Amount",
        issuedByOrg: "Issued By Organisation",
        issuedBy: "Issued By",
        otherType: "Other Type"
      },
      placeholder: {
        stateReason: "State your reason...",
        crmId: "",
        owner: "- select -",
        date: "- select -",
        type: "- select -",
        amount: "",
        issuedByOrg: "- select -",
        issuedBy: "- select -",
        otherType: ""
      },
      feedback: {
        processing: "Processing request...",
        historyRequestSuccess: "History created successfully!",
        historyRequestRefreshPrompt:
          "Use the refresh function to see an updated history list.",
        historyRequestFailure: "History creation failed.",
        historyRequestUnknown: "Something went wrong with the history request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        yes: "Yes",
        no: "No"
      }
    },
    modal: {
      createHistoryMessage: "Save history?",
      modifyHistoryMessage: "Are you sure you want to save these changes?",
      deleteHistoryMessage:
        "Are you sure you want to delete this history? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this history's status to active?",
      setInactiveMessage: "Set this history's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addHistoryLabel: "New History",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    table: {
      header: {
        actionType: "Action Type",
        username: "Username",
        table: "Table",
        oldDate: "Old Date",
        oldValues: "Old Values",
        newDate: "New Date",
        newValues: "New Values",
        dbActionType: "Database Action Type",
        created: "Date",
        page: "Page",
        crmId: "CRM ID"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    assessmentTypes: ["Baseline Assessment", "SG Assessments"],
    entities: {
      activities: "Activities"
    },
    urlMapping: {
      Activities: "activities",
      Amendments: "amendments",
      Assessments: "assessments",
      Costs: "costs",
      Dummys: "dummyRecords",
      Employments: "employments",
      Engagements: "engagements",
      Feedback: "feedback",
      "Global Contact Record": "customers",
      Organisations: "organisations",
      Programmes: "programmes",
      Referrals: "referrals",
      Settings: "settings",
      "TtG Engagements": "ttgEngagements",
      "TtG Programmes": "ttgProgrammes",
      Users: "users",
      News: "news",
      "Area of Need": "areaOfNeed",
      "Specialist Support Referral": "supportreferrals",
      Complaints: "complaints",
      Assurances: "assurances",
      "SG Assessments": "assessmentSG",
      "Baseline Assessment": "baselines"
    }
  }
});
