import React from "react";
import TableComponent from "../../components/CaseloadTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { caseloadActions } from "../../store/actions/caseload.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import { getOrganisations } from "../../helpers";

const requestTimeout = 20000;

class CaseloadListContainer extends React.Component {
  state = {
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    searchCriteriaForCaseloads: {
      size: 20,
      from: null,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    searchCriteriaForUsers: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    searchBarQuery: ""
  };

  componentDidMount = async () => {
    this.timerID = setTimeout(() => {
      if (
        this.props.caseloadRequestStatus === undefined &&
        this.props.caseloadRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearCaseloadRequest();
  }

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    this.props.clearCaseloadRequest();
    await this.handleFetchData(this.state);
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteria };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteria: searchCriteria });
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleFetchData = async state => {
    const sCritForCaseloads = this.state.searchCriteriaForCaseloads;
    const sCritForUsers = this.state.searchCriteriaForUsers;
    await this.props.getUsers(
      this.props.headers,
      sCritForUsers.query,
      sCritForUsers.size,
      sCritForUsers.from,
      sCritForUsers.clear,
      sCritForUsers.fields,
      sCritForUsers.sort,
      sCritForUsers.activeOnly
    );

    let sortObject = {};
    if (state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }

    sCritForCaseloads.query = this.state.searchBarQuery;
    sCritForCaseloads.fields = [
      "firstName.normalizer",
      "surname.normalizer",
      "pNumber.normalizer",
      "prison.normalizer"
    ];
    await this.props.updateSearchCriteria(
      this.props.headers,
      sCritForCaseloads.query,
      sCritForCaseloads.size,
      state.page * sCritForCaseloads.size,
      sCritForCaseloads.clear,
      sCritForCaseloads.fields,
      sortObject,
      sCritForCaseloads.activeOnly
    );
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      default:
        break;
    }
  };

  render = () => {
    const loading = this.props.loadingPage || this.props.usersLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <RequestFeedback
            requestStatus={this.props.caseloadRequestStatus}
            requestMade={this.props.caseloadRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.caseloadRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={
              strings.form.feedback.caseloadRequestUnknown
            }
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            caseloadListData={this.props.caseloadListData}
            handleModalConfirm={this.handleModalConfirm}
            handleRefresh={this.handleRefresh}
            pageSize={this.state.searchCriteriaForCaseloads.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalResults={this.props.totalResults}
            updatePageIndex={this.updatePageIndex}
            getOrganisations={this.getOrganisations}
            userList={this.props.userListData}
            searchBarQuery={this.state.searchBarQuery}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            isCreateDisabled={true}
            roleId={this.props.roleId}
          />
        </div>
      </div>
    );
  };
}

CaseloadListContainer.propTypes = {
  caseloadListData: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getCaseloads: PropTypes.func.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { caseloads, auth, users } = state;
  const { roleId, organisationOfLoggedInUser } = auth;

  const { userListData, loadingPage: usersLoadingPage } = users;

  const {
    caseloadListData,
    loadingPage,
    caseloadRequestStatus,
    caseloadRequestMade,
    result,
    error,
    totalResults,
    errorDetails
  } = caseloads;

  return {
    caseloadListData,
    loadingPage,
    caseloadRequestStatus,
    caseloadRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails,

    userListData,
    organisationOfLoggedInUser,
    usersLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCaseloads: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        caseloadActions.getCaseloads(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        caseloadActions.updateSearchCriteria(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    clearCaseloadRequest: () => {
      dispatch(caseloadActions.clearCaseloadRequest());
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseloadListContainer);
