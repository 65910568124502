import React from "react";
import TableComponent from "../../components/HistoryTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { historyActions } from "../../store/actions/history.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { customerActions } from "../../../Customers/store/actions/customer.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";

const requestTimeout = 20000;

class HistoryListContainer extends React.Component {
  state = {
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    searchCriteriaForHistory: {
      size: 20,
      from: null,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    searchCriteriaForUsers: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    searchBarQuery: ""
  };

  componentDidMount = async () => {
    this.timerID = setTimeout(() => {
      if (
        this.props.historyRequestStatus === undefined &&
        this.props.historyRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearHistoryRequest();
  }

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    this.props.clearHistoryRequest();
    await this.handleFetchData(this.state);
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteria };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteria: searchCriteria });
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleFetchData = async state => {
    const sCritForHistory = this.state.searchCriteriaForHistory;
    await this.props.getProgrammeById(
      localStorage.getItem("programmeId"),
      this.props.headers
    );
    await this.props.getCustomers(
      this.props.headers,
      this.props.selectedProgrammeData.referralId,
      1,
      0,
      false,
      ["newRoutesReferralId"],
      {},
      false
    );
    let sortObject = {};
    if (state.sorted && state.sorted.length > 0) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    } else {
      sortObject = { created: { order: "desc" } };
    }
    let fields = [];
    if (this.props.customerListData.length > 0) {
      const gcrId = this.props.customerListData[0].gcrId;
      fields = ["actionType", "gcrId"];
      sCritForHistory.query = `(Database Change) AND (${gcrId})`;

      if (this.state.searchBarQuery) {
        sCritForHistory.fields = ["*"];
        sCritForHistory.query = `${sCritForHistory.query} AND (${this.state.searchBarQuery})`;
      } else {
        sCritForHistory.fields = fields;
      }

      await this.props.updateSearchCriteria(
        this.props.headers,
        sCritForHistory.query,
        sCritForHistory.size,
        state.page * sCritForHistory.size,
        sCritForHistory.clear,
        sCritForHistory.fields,
        sortObject,
        sCritForHistory.activeOnly
      );
    }
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      default:
        break;
    }
  };

  render = () => {
    const loading = this.props.loadingPage || this.props.customersLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <RequestFeedback
            requestStatus={this.props.historyRequestStatus}
            requestMade={this.props.historyRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.historyRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={strings.form.feedback.historyRequestUnknown}
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            historyListData={this.props.historyListData}
            handleModalConfirm={this.handleModalConfirm}
            handleRefresh={this.handleRefresh}
            pageSize={this.state.searchCriteriaForHistory.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalResults={this.props.totalResults}
            updatePageIndex={this.updatePageIndex}
            getOrganisations={this.getOrganisations}
            userList={this.props.userListData}
            searchBarQuery={this.state.searchBarQuery}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            isCreateDisabled={true}
            roleId={this.props.roleId}
          />
        </div>
      </div>
    );
  };
}

HistoryListContainer.propTypes = {
  historyListData: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getHistory: PropTypes.func.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { history, auth, programmes, customers } = state;
  const { roleId, organisationOfLoggedInUser } = auth;
  const { selectedProgrammeData } = programmes;
  const { customerListData, loadingPage: customersLoadingPage } = customers;

  const {
    historyListData,
    loadingPage,
    historyRequestStatus,
    historyRequestMade,
    result,
    error,
    totalResults,
    errorDetails
  } = history;

  return {
    historyListData,
    loadingPage,
    historyRequestStatus,
    historyRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails,
    selectedProgrammeData,
    customerListData,
    organisationOfLoggedInUser,
    customersLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCustomers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      globalContactOnly
    ) => {
      await dispatch(
        customerActions.getCustomers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          globalContactOnly
        )
      );
    },
    getHistory: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        historyActions.getHistory(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        historyActions.updateSearchCriteria(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    clearHistoryRequest: () => {
      dispatch(historyActions.clearHistoryRequest());
    },
    getProgrammeById: async (id, headers) => {
      await dispatch(programmeActions.getProgrammeById(id, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryListContainer);
