export const settingActionTypes = {
  CLEAR_CREATE_SETTING_REQUEST: "CLEAR_CREATE_SETTING_REQUEST",
  CLEAR_CREATE_SETTING_FAILURE: "CLEAR_CREATE_SETTING_FAILURE",
  CLEAR_CREATE_SETTING_SUCCESS: "CLEAR_CREATE_SETTING_SUCCESS",

  UPDATE_SELECTED_SETTING_REQUEST: "UPDATE_SELECTED_SETTING_REQUEST",
  UPDATE_SELECTED_SETTING_SUCCESS: "UPDATE_SELECTED_SETTING_SUCCESS",
  UPDATE_SELECTED_SETTING_FAILURE: "UPDATE_SELECTED_SETTING_FAILURE",

  CLEAR_SELECTED_SETTING_REQUEST: "CLEAR_SELECTED_SETTING_REQUEST",
  CLEAR_SELECTED_SETTING_SUCCESS: "CLEAR_SELECTED_SETTING_SUCCESS",
  CLEAR_SELECTED_SETTING_FAILURE: "CLEAR_SELECTED_SETTING_FAILURE",

  CANCEL_SELECTED_SETTING_UPDATE_REQUEST:
    "CANCEL_SELECTED_SETTING_UPDATE_REQUEST",
  CANCEL_SELECTED_SETTING_UPDATE_SUCCESS:
    "CANCEL_SELECTED_SETTING_UPDATE_SUCCESS",
  CANCEL_SELECTED_SETTING_UPDATE_FAILURE:
    "CANCEL_SELECTED_SETTING_UPDATE_FAILURE",

  GET_SETTINGS_REQUEST: "GET_SETTINGS_REQUEST",
  GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",
  GET_SETTINGS_FAILURE: "GET_SETTINGS_FAILURE",

  UPDATE_SETTING_REQUEST: "UPDATE_SETTING_REQUEST",
  UPDATE_SETTING_SUCCESS: "UPDATE_SETTING_SUCCESS",
  UPDATE_SETTING_FAILURE: "UPDATE_SETTING_FAILURE",

  CHECK_SETTING_MATCHES_REQUEST: "CHECK_SETTING_MATCHES_REQUEST",
  CHECK_SETTING_MATCHES_SUCCESS: "CHECK_SETTING_MATCHES_SUCCESS",
  CHECK_SETTING_MATCHES_FAILURE: "CHECK_SETTING_MATCHES_FAILURE"
};
