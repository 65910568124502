import React from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { BasicModal } from "..";
import PropTypes from "prop-types";
import { convertFromIso } from "../../helpers/dateHelpers";
import dropdownHelper from "../../helpers/dropdownHelper";

import {
  ValidatedDateInput,
  ValidatedFormDropdown,
  ValidatedFormTextArea
} from ".";

export default function ValidatedMultipleAssuranceActions({
  selectedAssuranceData,
  handleActionChange,
  validationResults,
  strings,
  formInvalid,
  mode,
  toggleDeleteModal,
  actionStatusOptions,
  deleteActionModalOpen,
  handleModalConfirm,
  addAction
}) {
  return (
    <>
      <Table className="actionsTable" basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>#</Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {strings.form.label.action}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {strings.form.label.actionCompletedBy}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {strings.form.label.actionStatus}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {strings.form.label.notes}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {strings.form.label.dateActionClosed}
            </Table.HeaderCell>
            <Table.HeaderCell width={3} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {selectedAssuranceData.actions &&
            selectedAssuranceData.actions.map((action, idx) => {
              let actionId = `action-${idx}`,
                actionCompletedById = `actionCompletedBy-${idx}`,
                actionStatusId = `actionStatus-${idx}`,
                noteId = `notes-${idx}`,
                dateActionClosedId = `dateActionClosed-${idx}`;
              return (
                <Table.Row key={action.id}>
                  <Table.Cell>
                    <span>{idx + 1}</span>
                  </Table.Cell>
                  <Table.Cell>
                    <ValidatedFormTextArea
                      formInvalid={formInvalid}
                      inputData={selectedAssuranceData.actions[idx].action}
                      inputLabel={strings.form.label.action}
                      inputName={actionId}
                      inputPlaceholder={strings.form.placeholder.action}
                      formFieldWidth={8}
                      handleChange={handleActionChange}
                      validationResult={
                        validationResults.actions
                          ? validationResults.actions[idx]
                            ? validationResults.actions[idx].action
                            : undefined
                          : undefined
                      }
                      required={
                        selectedAssuranceData.outcome ===
                        strings.fieldValues.improvementActions
                      }
                      mode={mode}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <ValidatedDateInput
                      inputId={actionCompletedById}
                      formInvalid={formInvalid}
                      inputLabel={strings.form.label.actionCompletedBy}
                      inputName={actionCompletedById}
                      inputPlaceholder={
                        strings.form.placeholder.actionCompletedBy
                      }
                      inputDateFormat="DD/MM/YYYY"
                      inputData={
                        selectedAssuranceData.actions[idx].actionCompletedBy
                          ? convertFromIso(
                              selectedAssuranceData.actions[idx]
                                .actionCompletedBy
                            )
                          : ""
                      }
                      iconPosition="left"
                      required={
                        selectedAssuranceData.outcome ===
                        strings.fieldValues.improvementActions
                      }
                      formFieldWidth={8}
                      handleChange={handleActionChange}
                      validationResult={
                        validationResults.actions
                          ? validationResults.actions[idx]
                            ? validationResults.actions[idx].actionCompletedBy
                            : undefined
                          : undefined
                      }
                      mode={mode}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <ValidatedFormDropdown
                      formInvalid={formInvalid}
                      inputData={
                        selectedAssuranceData.actions[idx].actionStatus
                      }
                      viewData={
                        dropdownHelper.getNameFromValues(
                          selectedAssuranceData.actions[idx].actionStatus,
                          actionStatusOptions
                        ) || ""
                      }
                      dropdownLabel={strings.form.label.actionStatus}
                      dropdownName={actionStatusId}
                      dropdownOptions={actionStatusOptions || []}
                      dropdownPlaceholder={
                        strings.form.placeholder.actionStatus
                      }
                      formFieldWidth={8}
                      handleChange={handleActionChange}
                      validationResult={
                        validationResults.actions
                          ? validationResults.actions[idx]
                            ? validationResults.actions[idx].actionStatus
                            : undefined
                          : undefined
                      }
                      mode={mode}
                      required={
                        selectedAssuranceData.outcome ===
                        strings.fieldValues.improvementActions
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <ValidatedFormTextArea
                      formInvalid={formInvalid}
                      inputData={selectedAssuranceData.actions[idx].notes}
                      inputLabel={strings.form.label.notes}
                      inputName={noteId}
                      inputPlaceholder={strings.form.placeholder.notes}
                      formFieldWidth={8}
                      handleChange={handleActionChange}
                      validationResult={
                        validationResults.actions
                          ? validationResults.actions[idx]
                            ? validationResults.actions[idx].notes
                            : undefined
                          : undefined
                      }
                      required={false}
                      mode={mode}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <ValidatedDateInput
                      inputId={dateActionClosedId}
                      formInvalid={formInvalid}
                      inputLabel={strings.form.label.dateActionClosed}
                      inputName={dateActionClosedId}
                      inputPlaceholder={
                        strings.form.placeholder.dateActionClosed
                      }
                      inputDateFormat="DD/MM/YYYY"
                      inputData={
                        selectedAssuranceData.actions[idx].dateActionClosed
                          ? convertFromIso(
                              selectedAssuranceData.actions[idx]
                                .dateActionClosed
                            )
                          : ""
                      }
                      iconPosition="left"
                      formFieldWidth={8}
                      handleChange={handleActionChange}
                      validationResult={
                        validationResults.actions
                          ? validationResults.actions[idx]
                            ? validationResults.actions[idx].dateActionClosed
                            : undefined
                          : undefined
                      }
                      mode={mode}
                      required={
                        selectedAssuranceData.actions[idx].actionStatus ===
                        strings.fieldValues.closed
                      }
                    />
                  </Table.Cell>
                  {mode !== "view" && (
                    <Table.Cell textAlign="center">
                      <Icon
                        className="actionDeleteIcon"
                        name="trash outline alternate"
                        onClick={() => toggleDeleteModal(action.id)}
                        disabled={
                          selectedAssuranceData.actions.length === 1 &&
                          selectedAssuranceData.outcome ===
                            strings.fieldValues.improvementActions
                        }
                      />
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            })}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell colSpan="5">
              <Button
                className="actionAddIcon"
                floated="right"
                icon
                labelPosition="left"
                size="small"
                onClick={addAction}
              >
                <Icon name="plus" />{" "}
                {strings.multipleAssuranceActions.addAction}
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <BasicModal
        confirmMessage={strings.modal.deleteActionMessage}
        handleModalConfirm={handleModalConfirm}
        modalClassName="deleteActionModal"
        modalOpen={deleteActionModalOpen}
        showNoButton={true}
        showOkButton={false}
        showYesButton={true}
        toggleModal={toggleDeleteModal}
        noLabel={strings.button.noLabel}
        yesLabel={strings.button.yesLabel}
        okLabel={strings.button.okLabel}
      />
    </>
  );
}

ValidatedMultipleAssuranceActions.propTypes = {
  selectedAssuranceData: PropTypes.object.isRequired,
  handleActionChange: PropTypes.func.isRequired,
  validationResults: PropTypes.object,
  strings: PropTypes.object.isRequired,
  formInvalid: PropTypes.bool,
  mode: PropTypes.string,
  toggleDeleteModal: PropTypes.func.isRequired,
  actionStatusOptions: PropTypes.array.isRequired,
  deleteActionModalOpen: PropTypes.bool.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  addAction: PropTypes.func.isRequired
};
