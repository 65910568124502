import axios from "./axios-ttg-engagements";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createTtgEngagement = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  };

  updateTtgEngagement = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating ttgEngagement: ", error.message);
          reject(error);
        });
    });
  };

  deleteTtgEngagement = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, null, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting ttgEngagement: ", error.message);
          reject(error);
        });
    });
  };

  getTtgEngagements = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    ttgProgrammeId
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly,
      ttgProgrammeId: ttgProgrammeId
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/list-ttg-engagements/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting ttgEngagements: ", error.message);
          reject(error);
        });
    });
  };

  getTtgEngagementById = id => {
    const config = {
      headers: {
        ...this.headers,
        ttgProgramme: localStorage.getItem("ttgProgrammeId")
      }
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting ttgEngagement: ", error.message);
          reject(error);
        });
    });
  };
}
