import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { strings } from "../resources";
import FormBoxWithHeader from "../../App/components/FormBoxWithHeader";

export default function SupportReferralBox({ handleShowSupportReferral }) {
  return (
    <FormBoxWithHeader
      header={strings.form.header.supportPartnerReferrals}
      isInfoBlock
    >
      <label className="viewLabel">
        {strings.form.label.viewOrUpdateSupportPartnerReferrals}
      </label>
      <Button
        className="formButton"
        onClick={handleShowSupportReferral}
        primary
        inverted
      >
        {strings.button.viewLabel}
      </Button>
    </FormBoxWithHeader>
  );
}

SupportReferralBox.propTypes = {
  handleShowSupportReferral: PropTypes.func.isRequired
};
