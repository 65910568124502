import React from "react";
import ModifyAreaOfNeed from "../../components/ModifyAreaOfNeed";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, dropdownOptions, areaOfNeedObjects } from "../../resources";
import { TtgEngagementListContainer } from "../../../TtgEngagements/containers/";
import { areaOfNeedActions } from "../../store/actions/areaOfNeed.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { ttgEngagementActions } from "../../../TtgEngagements/store/actions/ttgEngagement.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  convertToIso,
  getUserList,
  initialiseActionsTableAndNeedsFields
} from "../../helpers";
import moment from "moment";
class AreaOfNeedEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableAreaOfNeedModalOpen: false,
    initialAreaOfNeedData: {},
    formInvalid: false,
    validationResults: {},
    deleteModalOpen: false,
    unknownRequestStatus: false,
    searchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    deletionReason: undefined,
    fetchedPermissionStatus: false,
    permissionStatus: "",
    userList: [],
    showAuditLog: false,
    areaOfNeedTable: "",
    customDropdownOptions: [],
    canView: true
  };

  componentDidMount = async () => {
    let dropdownOption = [];
    await this.handleFetchData();
    try {
      await this.props.getAreaOfNeedById(
        this.props.match.params.id,
        this.props.headers
      );
    } catch (err) {
      this.setState({
        canView: false
      });
      throw err;
    } finally {
      if (
        this.state.mode === "view" &&
        this.props.selectedAreaOfNeedData.requestId
      ) {
        await this.props.getDeletePermissionStatus(
          this.props.selectedAreaOfNeedData.requestId
        );
        this.setState(
          { permissionStatus: this.props.permissionStatus },
          async () => {
            if (this.state.permissionStatus === strings.states.rejected) {
              await this.props.updateSelectedAreaOfNeed("requestId", null);
              this.props.updateAreaOfNeed(
                this.props.selectedAreaOfNeedData.needId,
                this.props.selectedAreaOfNeedData,
                this.props.headers,
                this.state.initialAreaOfNeedData,
                false
              );
            }
          }
        );
      }
      Object.values(dropdownOptions.areaOfNeedOptions).forEach(option => {
        if (option.value === strings.form.text.other) {
          this.props.selectedAreaOfNeedData.actionsTable[
            strings.form.text.other
          ].forEach(action =>
            dropdownOption.push({
              key: action.actions,
              text: action.actions,
              value: action.actions
            })
          );
        }
      });

      this.setState({ customDropdownOptions: dropdownOption });

      this.setState({
        fetchedPermissionStatus: true,
        initialAreaOfNeedData: this.props.selectedAreaOfNeedData,
        userList: getUserList(this.props.userListData)
      });

      let form = document.getElementById("areaOfNeedForm");
      if (form) {
        form.setAttribute("novalidate", true);
        //set the form to have the novalidate attribute to suppress the default html validation tooltips
      }
    }
  };

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    sCrit.query = "ttg";
    sCrit.fields = ["area.normalizer"];
    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedAreaOfNeedData.needId,
      this.state.deletionReason.value,
      strings.amendments.fullName,
      this.props.selectedAreaOfNeedData.crmId,
      this.props.headers,
      this.props.selectedAreaOfNeedData
    );
    this.props.updateSelectedAreaOfNeed("requestId", this.props.requestId);
    this.props.updateAreaOfNeed(
      this.props.selectedAreaOfNeedData.needId,
      this.props.selectedAreaOfNeedData,
      this.props.headers,
      this.state.initialAreaOfNeedData
    );
    this.setState({
      initialAreaOfNeedData: JSON.parse(
        JSON.stringify(this.props.selectedAreaOfNeedData)
      )
    });

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearAreaOfNeedRequest();
    }, 10000);
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteAreaOfNeed(
      this.props.selectedAreaOfNeedData.needId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      await this.props.setAmendmentStatusActioned(
        this.props.selectedAreaOfNeedData.requestId,
        this.props.headers,
        true,
        {
          entity: strings.amendments.entity,
          id: this.props.selectedAreaOfNeedData.needId,
          type: strings.amendments.deletion
        }
      );

      this.props.history.push("/areaOfNeed");
    }
  };

  componentWillUnmount() {
    this.props.clearAreaOfNeedRequest();
    this.props.clearSelectedAreaOfNeed();
  }

  validateForm = async () => {
    const { selectedAreaOfNeedData } = this.props;
    let areaOfNeedConstraints = {
      ...twgConstraints.needs.modifyNeed
    };
    let areaOfNeedActionConstraints = {
      ...twgConstraints.needs.modifyAction
    };
    let areaOfNeedRowConstraints = {
      ...twgConstraints.needs.modifyRow
    };
    areaOfNeedConstraints.active = undefined;
    let details = JSON.parse(JSON.stringify(selectedAreaOfNeedData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(
      details,
      areaOfNeedConstraints
    );

    let areaOfNeedResults = [];
    let needFieldsEmpty = true;
    if (selectedAreaOfNeedData.areaOfNeed) {
      selectedAreaOfNeedData.areaOfNeed.forEach(need => {
        if (selectedAreaOfNeedData.areaOfNeedFields) {
          if (selectedAreaOfNeedData.areaOfNeedFields[need]) {
            let details = JSON.parse(
              JSON.stringify(selectedAreaOfNeedData.areaOfNeedFields[need])
            );
            for (let property in details) {
              if (!details[property]) {
                delete details[property];
              }
            }
            let actionResult = twgValidationFunctions.validateData(
              details,
              areaOfNeedActionConstraints
            );
            actionResult
              ? areaOfNeedResults.push(actionResult)
              : areaOfNeedResults.push({});
          }
        }

        if (results !== undefined && results.needFields === undefined) {
          results = {
            ...results,
            needFields: JSON.parse(
              JSON.stringify(areaOfNeedObjects.initialNeedFields)
            )
          };
        } else if (results === undefined) {
          results = {
            needFields: JSON.parse(
              JSON.stringify(areaOfNeedObjects.initialNeedFields)
            )
          };
        }

        let areaOfNeedResultsAreEmpty = true;
        areaOfNeedResults.forEach((result, i) => {
          areaOfNeedObjects.needFieldKeys.forEach(key => {
            //All results need to be added to preserve order
            if (results.needFields[need]) {
              results.needFields[need][key] = areaOfNeedResults[i][key];
            }
            if (areaOfNeedResults[i][key]) {
              if (Object.keys(areaOfNeedResults[i][key]).length !== 0) {
                areaOfNeedResultsAreEmpty = false;
                needFieldsEmpty = false;
              }
            }
          });
        });
        if (areaOfNeedResultsAreEmpty) {
          delete results.needFields[need];
        }
      });
    }

    if (needFieldsEmpty && results) {
      delete results.needFields;
    }

    let actionResultsEmpty = true;
    if (selectedAreaOfNeedData.areaOfNeed) {
      selectedAreaOfNeedData.areaOfNeed.forEach(need => {
        let actionResults = [];
        if (selectedAreaOfNeedData.actionsTable) {
          if (selectedAreaOfNeedData.actionsTable[need]) {
            selectedAreaOfNeedData.actionsTable[need].forEach((row, index) => {
              let details = JSON.parse(JSON.stringify(row));
              for (let property in details) {
                if (!details[property]) {
                  delete details[property];
                }
              }
              let actionResult = twgValidationFunctions.validateData(
                details,
                areaOfNeedRowConstraints
              );
              actionResult
                ? actionResults.push(actionResult)
                : actionResults.push({});

              if (results !== undefined && results.actionFields === undefined) {
                results = {
                  ...results,
                  actionFields: JSON.parse(
                    JSON.stringify(areaOfNeedObjects.initialNeedFields)
                  )
                };
              } else if (results === undefined) {
                results = {
                  actionFields: JSON.parse(
                    JSON.stringify(areaOfNeedObjects.initialNeedFields)
                  )
                };
              }
              if (results.actionFields[need]) {
                results.actionFields[need][index] = actionResults[index];
              }
              if (Object.keys(actionResults[index]).length !== 0) {
                actionResultsEmpty = false;
              }
            });
          }
        }
      });
    }

    if (actionResultsEmpty && results) {
      delete results.actionFields;
    }

    let resultsNotEmpty;
    if (results) {
      resultsNotEmpty = Object.keys(results).length > 0;
    }
    if (resultsNotEmpty) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  addAction = async need => {
    let actionsTable = this.props.selectedAreaOfNeedData.actionsTable || [];
    let actions = actionsTable[need] ? [...actionsTable[need]] : [];
    let newNeed = actionsTable[need] ? [...actionsTable[need]] : [];
    newNeed.push({
      actions: "",
      actionStatus: "",
      actionStartedDate: "",
      actionClosedDate: "",
      notes: ""
    });
    actionsTable[need] = newNeed;
    actions.length > 1
      ? await this.props.updateSelectedAreaOfNeed(
          this.getKeyByValue(
            this.props.selectedAreaOfNeedData,
            this.props.selectedAreaOfNeedData.actionsTable
          ),
          actionsTable
        )
      : await this.props.updateSelectedAreaOfNeed("actionsTable", actionsTable);
  };

  removeAction = async () => {
    let actionsTable = this.props.selectedAreaOfNeedData.actionsTable || [];
    let actions = [...actionsTable[this.state.areaOfNeedTable]] || [];
    if (actions.length > 0) {
      actions.splice(this.state.actionDeletionId, 1);
    }
    if (actions.length === 0) {
      actions = undefined;
    }

    actionsTable[this.state.areaOfNeedTable] = actions;
    await this.props.updateSelectedAreaOfNeed(
      this.getKeyByValue(
        this.props.selectedAreaOfNeedData,
        this.props.selectedAreaOfNeedData.actionsTable
      ),
      actionsTable
    );
  };

  handleSubmit = async () => {
    // Reset need fields and action table for removed areas of need
    let initialiseResults = initialiseActionsTableAndNeedsFields(
      this.props.selectedAreaOfNeedData
    );
    await this.props.updateSelectedAreaOfNeed(
      "actionsTable",
      initialiseResults.actionsTable
    );
    await this.props.updateSelectedAreaOfNeed(
      "areaOfNeedFields",
      initialiseResults.areaOfNeedFields
    );

    await this.props.updateAreaOfNeed(
      this.props.match.params.id,
      this.props.selectedAreaOfNeedData,
      this.props.headers,
      this.state.initialAreaOfNeedData
    );
    if (this.state.addEngagement) {
      this.props.history.push(
        "/ttgEngagements/create/" + this.props.selectedAreaOfNeedData.needId
      );
    } else {
      this.handleModeSwitch();
    }
  };

  handleAddEngagement = () => {
    if (this.state.mode === "edit") {
      this.setState({ addEngagement: true });
      this.validateForm();
    } else {
      this.props.history.push(
        "/ttgEngagements/create/" + this.props.selectedAreaOfNeedData.needId
      );
    }
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedAreaOfNeed(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedAreaOfNeed(data.name, data.value);
    }
  };

  getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedAreaOfNeedData.active
    };
    await this.props.updateAreaOfNeed(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialAreaOfNeedData
    );
  };

  handleToggleAudit = () => {
    const isAudit = this.state.showAuditLog;
    this.setState({ showAuditLog: !isAudit });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;
      case "deletionActionModal":
        this.removeAction(this.state.actionDeletionId);
        break;

      default:
        break;
    }
  };

  handleDropdownOptionAddition = (e, { value }) => {
    this.setState(prevState => ({
      customDropdownOptions: [
        { text: value, value },
        ...prevState.customDropdownOptions
      ]
    }));
  };

  handleActionChange = async (e, data, need) => {
    let actionsTable = this.props.selectedAreaOfNeedData.actionsTable || [];
    let actions = [...actionsTable[need]] || [];
    const result = data.name.split("-");
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      actions[result[1]][result[0]] = convertToIso(data.value);
    } else {
      actions[result[1]][result[0]] = data.value;
    }

    actionsTable[need] = actions;
    await this.props.updateSelectedAreaOfNeed(
      this.getKeyByValue(
        this.props.selectedAreaOfNeedData,
        this.props.selectedAreaOfNeedData.actionsTable
      ),
      actionsTable
    );
  };

  handleAreaOfNeedChange = async (e, data, need) => {
    let areaOfNeedFields =
      this.props.selectedAreaOfNeedData.areaOfNeedFields || {};
    let actions = areaOfNeedFields[need] || [];
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      actions[data.name] = convertToIso(data.value);
    } else {
      actions[data.name] = data.value;
    }
    areaOfNeedFields[need] = actions;
    if (data.value === strings.form.text.workInProgress) {
      areaOfNeedFields[need].followUpDate = moment(new Date())
        .add(7, "days")
        .format("YYYY-MM-DD");
    }

    await this.props.updateSelectedAreaOfNeed(
      this.getKeyByValue(
        this.props.selectedAreaOfNeedData,
        this.props.selectedAreaOfNeedData.areaOfNeedFields
      ),
      areaOfNeedFields
    );
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableAreaOfNeedModalVisibility = () => {
    this.setState({
      enableDisableAreaOfNeedModalOpen: !this.state
        .enableDisableAreaOfNeedModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialAreaOfNeedData) !==
        JSON.stringify(this.props.selectedAreaOfNeedData)
      ) {
        await this.props.cancelSelectedAreaOfNeedUpdate(
          this.state.initialAreaOfNeedData
        );
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialAreaOfNeedData: JSON.parse(
          JSON.stringify(this.props.selectedAreaOfNeedData)
        )
      });
    }
  };

  toggleConfirmDeleteModalVisibility = (idx, areaOfNeed) => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      actionDeletionId: idx,
      areaOfNeedTable: areaOfNeed
    });
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      !this.state.fetchedPermissionStatus ||
      this.props.amendmentLoadingPage;
    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          {this.props.showNotification && (
            <RequestFeedback
              requestStatus={this.props.areaOfNeedRequestStatus}
              requestMade={this.props.areaOfNeedRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.areaOfNeedRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyAreaOfNeed
            mode={this.state.mode}
            requestStatus={this.props.areaOfNeedRequestStatus}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editAreaOfNeed
                : strings.header.viewAreaOfNeed
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnableDisableAreaOfNeedModalVisibility={
              this.toggleEnableDisableAreaOfNeedModalVisibility
            }
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            deleteModalOpen={this.state.deleteModalOpen}
            selectedAreaOfNeedData={this.props.selectedAreaOfNeedData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enableDisableAreaOfNeedModalOpen={
              this.state.enableDisableAreaOfNeedModalOpen
            }
            initialAreaOfNeedData={this.state.initialAreaOfNeedData}
            formInvalid={this.state.formInvalid}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            headers={this.props.headers}
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            userList={this.state.userList}
            handleActionChange={this.handleActionChange}
            handleAreaOfNeedChange={this.handleAreaOfNeedChange}
            addAction={this.addAction}
            handleToggleAudit={this.handleToggleAudit}
            showAuditLog={this.state.showAuditLog}
            handleDropdownOptionAddition={this.handleDropdownOptionAddition}
            customDropdownOptions={this.state.customDropdownOptions}
            handleAddEngagement={this.handleAddEngagement}
          />
          {this.state.canView && (
            <div className="embeddedComponent">
              <TtgEngagementListContainer
                headers={this.props.headers}
                history={this.props.history}
                hideSearchBar={true}
                needId={this.props.match.params.id}
                userListData={this.props.userListData}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
}

AreaOfNeedEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedAreaOfNeedData: PropTypes.object.isRequired,
  updateSelectedAreaOfNeed: PropTypes.func.isRequired,
  updateAreaOfNeed: PropTypes.func.isRequired,
  getAreaOfNeedById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { areaOfNeed, auth, amendments, users } = state;
  const {
    loadingPage,
    selectedAreaOfNeedData,
    areaOfNeedRequestStatus,
    areaOfNeedRequestMade,
    result,
    error,
    errorDetails,
    deleteSuccessful,
    showNotification
  } = areaOfNeed;
  const { roleId } = auth;
  const { userListData } = users;
  const {
    requestId,
    permissionStatus,
    loadingPage: amendmentLoadingPage
  } = amendments;
  const { selectedTtgEngagementData } = state.ttgEngagements;
  return {
    loadingPage,
    selectedAreaOfNeedData,
    areaOfNeedRequestStatus,
    areaOfNeedRequestMade,
    result,
    error,
    roleId,
    errorDetails,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    userListData,
    selectedTtgEngagementData,
    amendmentLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAreaOfNeed: (
      id,
      data,
      headers,
      initialAreaOfNeedData,
      showNotification = true
    ) => {
      dispatch(
        areaOfNeedActions.updateAreaOfNeed(
          id,
          data,
          headers,
          initialAreaOfNeedData,
          showNotification
        )
      );
    },
    getAreaOfNeedById: async (id, headers) => {
      await dispatch(areaOfNeedActions.getAreaOfNeedById(id, headers));
    },
    updateSelectedAreaOfNeed: (key, value) => {
      dispatch(areaOfNeedActions.updateSelectedAreaOfNeed(key, value));
    },
    cancelSelectedAreaOfNeedUpdate: data => {
      dispatch(areaOfNeedActions.cancelSelectedAreaOfNeedUpdate(data));
    },
    clearAreaOfNeedRequest: () => {
      dispatch(areaOfNeedActions.clearAreaOfNeedRequest());
    },
    clearSelectedAreaOfNeed: () => {
      dispatch(areaOfNeedActions.clearSelectedAreaOfNeed());
    },

    requestDeletePermission: async (
      id,
      reason,
      entity,
      recordIdentifier,
      headers,
      body
    ) => {
      await dispatch(
        amendmentActions.requestDeletePermission(
          id,
          reason,
          entity,
          recordIdentifier,
          headers,
          body
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteAreaOfNeed: async (id, headers) => {
      await dispatch(areaOfNeedActions.deleteAreaOfNeed(id, headers));
    },
    setDeletePermissionStatusActioned: async (
      id,
      headers,
      sendNotification,
      entityDetails
    ) => {
      await dispatch(
        amendmentActions.setDeletePermissionStatusActioned(
          id,
          headers,
          sendNotification,
          entityDetails
        )
      );
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getTtgEngagementById: (id, headers) => {
      dispatch(ttgEngagementActions.getTtgEngagementById(id, headers));
    },
    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    setAmendmentStatusActioned: async (
      id,
      headers,
      showNotification,
      entityDetails
    ) => {
      await dispatch(
        amendmentActions.setAmendmentStatusActioned(
          id,
          headers,
          showNotification,
          entityDetails
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AreaOfNeedEditContainer);
