import React from "react";
import { Modal, Card } from "semantic-ui-react";
import PropTypes from "prop-types";
import {
  checkFormatIso,
  convertFromIso,
  convertFromIsoWithTime
} from "../helpers";
import { strings } from "../../Audit/resources";

class AmendmentModal extends React.Component {
  render = () => {
    let historyDetails = [],
      oldValues = [],
      newValues = [],
      amendmentDetails = {},
      header = "",
      deletionModalContent,
      changeModalContent;

    if (this.props.auditObject.amendmentDetails) {
      try {
        amendmentDetails = JSON.parse(this.props.auditObject.amendmentDetails);
      } catch (e) {
        amendmentDetails = this.props.auditObject.amendmentDetails;
      }

      delete amendmentDetails.updated;

      if (typeof amendmentDetails.history === "object") {
        amendmentDetails.history.map((element, count) =>
          historyDetails.push(
            <Card key={count}>
              <p style={{ margin: "0.5rem" }}>
                {`Date: ${convertFromIsoWithTime(element.date)}`}
                <br />
                {`Event: ${element.event}`}
              </p>
            </Card>
          )
        );
      }
    }

    if (
      amendmentDetails.requestDetails &&
      typeof amendmentDetails.requestDetails.oldValues === "object"
    ) {
      Object.entries(amendmentDetails.requestDetails.oldValues).forEach(
        (element, count) => {
          oldValues.push(
            <li key={count}>
              {this.props.stringsObject[element[0]]
                ? this.props.stringsObject[element[0]]
                : element[0]}
              {" : "}
              {typeof element[1] === "string"
                ? checkFormatIso(element[1])
                  ? convertFromIso(element[1])
                  : element[1]
                : JSON.stringify(element[1])}
            </li>
          );
        }
      );
    } else if (
      amendmentDetails.requestDetails &&
      typeof amendmentDetails.requestDetails.oldValues !== "object"
    ) {
      oldValues.push(amendmentDetails.requestDetails.oldValues);
    }

    if (
      amendmentDetails.requestDetails &&
      typeof amendmentDetails.requestDetails.newValues === "object"
    ) {
      Object.entries(amendmentDetails.requestDetails.newValues).forEach(
        (element, count) => {
          newValues.push(
            <li key={count}>
              {this.props.stringsObject[element[0]]
                ? this.props.stringsObject[element[0]]
                : element[0]}
              {" : "}
              {typeof element[1] === "string"
                ? checkFormatIso(element[1])
                  ? convertFromIso(element[1])
                  : element[1]
                : JSON.stringify(element[1])}
            </li>
          );
        }
      );
    } else if (
      amendmentDetails.requestDetails &&
      typeof amendmentDetails.requestDetails.newValues !== "object"
    ) {
      newValues.push(amendmentDetails.requestDetails.newValues);
    }

    header = (
      <Modal.Header>{`${this.props.auditObject.dbActionType} - ${
        this.props.auditObject.table
      }${
        this.props.auditObject.crmId ? " - " + this.props.auditObject.crmId : ""
      }`}</Modal.Header>
    );

    deletionModalContent = amendmentDetails && amendmentDetails.requestDetails && (
      <Modal.Content>
        <Modal.Content>
          <p>{`${strings.modal.requestingUser}: ${amendmentDetails.requestingUser}`}</p>
          <p>{`${strings.modal.entity}: ${amendmentDetails.requestDetails.entity}`}</p>
          <p>{`${strings.modal.recordIdentifier}: ${amendmentDetails.requestDetails.recordIdentifier}`}</p>
          <p>{`${strings.modal.statusOfRequest}: ${amendmentDetails.status}`}</p>
          <p>{`${strings.modal.typeOfRequest}: ${amendmentDetails.requestType}`}</p>
          <p>{`${strings.modal.reasonForDelete}: ${amendmentDetails.requestDetails.reason}`}</p>
          <p>{strings.modal.history}</p>
          <ul style={{ display: "inline" }}>{historyDetails}</ul>
        </Modal.Content>
      </Modal.Content>
    );
    changeModalContent = amendmentDetails && amendmentDetails.requestDetails && (
      <Modal.Content>
        <Modal.Content>
          <p>{`${strings.modal.requestingUser}: ${amendmentDetails.requestingUser}`}</p>
          <p>{`${strings.modal.entity}: ${amendmentDetails.requestDetails.entity}`}</p>
          <p>{`${strings.modal.recordIdentifier}: ${amendmentDetails.requestDetails.recordIdentifier}`}</p>
          <p>{`${strings.modal.statusOfRequest}: ${amendmentDetails.status}`}</p>
          <p>{`${strings.modal.typeOfRequest}: ${amendmentDetails.requestType}`}</p>
          <p>{`${strings.modal.reasonForAmendment}: ${amendmentDetails.requestDetails.reason}`}</p>
          <p>
            Old Values:
            {oldValues}
          </p>
          <p>
            New Values:
            {newValues}
          </p>
          <p>{strings.modal.history}</p>
        </Modal.Content>
        <ul style={{ display: "inline" }}>{historyDetails}</ul>
      </Modal.Content>
    );

    return (
      <Modal open={this.props.modalOpen} onClose={this.props.handleClose}>
        {header}
        {amendmentDetails.requestType === "DELETE"
          ? deletionModalContent
          : changeModalContent}
      </Modal>
    );
  };
}

AmendmentModal.propTypes = {
  oldDate: PropTypes.string,
  newDate: PropTypes.string,
  amendmentDetails: PropTypes.string,
  modalOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

export default AmendmentModal;
