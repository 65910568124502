export const supportReferralActionTypes = {
  CREATE_SUPPORTREFERRAL_REQUEST: "CREATE_SUPPORTREFERRAL_REQUEST",
  CREATE_SUPPORTREFERRAL_SUCCESS: "CREATE_SUPPORTREFERRAL_SUCCESS",
  CREATE_SUPPORTREFERRAL_FAILURE: "CREATE_SUPPORTREFERRAL_FAILURE",

  CLEAR_CREATE_SUPPORTREFERRAL_REQUEST: "CLEAR_CREATE_SUPPORTREFERRAL_REQUEST",
  CLEAR_CREATE_SUPPORTREFERRAL_FAILURE: "CLEAR_CREATE_SUPPORTREFERRAL_FAILURE",
  CLEAR_CREATE_SUPPORTREFERRAL_SUCCESS: "CLEAR_CREATE_SUPPORTREFERRAL_SUCCESS",

  DELETE_SUPPORTREFERRAL_REQUEST: "DELETE_SUPPORTREFERRAL_REQUEST",
  DELETE_SUPPORTREFERRAL_SUCCESS: "DELETE_SUPPORTREFERRAL_SUCCESS",
  DELETE_SUPPORTREFERRAL_FAILURE: "DELETE_SUPPORTREFERRAL_FAILURE",

  UPDATE_SELECTED_SUPPORTREFERRAL_REQUEST:
    "UPDATE_SELECTED_SUPPORTREFERRAL_REQUEST",
  UPDATE_SELECTED_SUPPORTREFERRAL_SUCCESS:
    "UPDATE_SELECTED_SUPPORTREFERRAL_SUCCESS",
  UPDATE_SELECTED_SUPPORTREFERRAL_FAILURE:
    "UPDATE_SELECTED_SUPPORTREFERRAL_FAILURE",

  CLEAR_SELECTED_SUPPORTREFERRAL_REQUEST:
    "CLEAR_SELECTED_SUPPORTREFERRAL_REQUEST",
  CLEAR_SELECTED_SUPPORTREFERRAL_SUCCESS:
    "CLEAR_SELECTED_SUPPORTREFERRAL_SUCCESS",
  CLEAR_SELECTED_SUPPORTREFERRAL_FAILURE:
    "CLEAR_SELECTED_SUPPORTREFERRAL_FAILURE",

  CANCEL_SELECTED_SUPPORTREFERRAL_UPDATE_REQUEST:
    "CANCEL_SELECTED_SUPPORTREFERRAL_UPDATE_REQUEST",
  CANCEL_SELECTED_SUPPORTREFERRAL_UPDATE_SUCCESS:
    "CANCEL_SELECTED_SUPPORTREFERRAL_UPDATE_SUCCESS",
  CANCEL_SELECTED_SUPPORTREFERRAL_UPDATE_FAILURE:
    "CANCEL_SELECTED_SUPPORTREFERRAL_UPDATE_FAILURE",

  GET_SUPPORTREFERRALS_REQUEST: "GET_SUPPORTREFERRALS_REQUEST",
  GET_SUPPORTREFERRALS_SUCCESS: "GET_SUPPORTREFERRALS_SUCCESS",
  GET_SUPPORTREFERRALS_FAILURE: "GET_SUPPORTREFERRALS_FAILURE",

  UPDATE_SUPPORTREFERRAL_REQUEST: "UPDATE_SUPPORTREFERRAL_REQUEST",
  UPDATE_SUPPORTREFERRAL_SUCCESS: "UPDATE_SUPPORTREFERRAL_SUCCESS",
  UPDATE_SUPPORTREFERRAL_FAILURE: "UPDATE_SUPPORTREFERRAL_FAILURE",

  GET_SUPPORTREFERRAL_BY_ID_REQUEST: "GET_SUPPORTREFERRAL_BY_ID_REQUEST",
  GET_SUPPORTREFERRAL_BY_ID_SUCCESS: "GET_SUPPORTREFERRAL_BY_ID_SUCCESS",
  GET_SUPPORTREFERRAL_BY_ID_FAILURE: "GET_SUPPORTREFERRAL_BY_ID_FAILURE",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE"
};
