import React from "react";
import ModifyComplaint from "../../components/ModifyComplaint";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { complaintActions } from "../../store/actions/complaint.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { fileActions } from "../../../File/store/actions/file.actions";
import PropTypes from "prop-types";
import {
  convertToIso,
  canUserModifyRecord,
  commitFileChanges,
  textFormattingHelpers
} from "../../helpers";
import uuidv4 from "uuid/v4";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";

class ComplaintCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableComplaintModalOpen: false,
    formInvalid: false,
    validationResults: {},
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    isPrePopulated: false,
    hasPageLoaded: false,
    escalatedToStage2: false,
    escalatedToStage3: false,
    stage1HasFileChanges: false,
    stage2HasFileChanges: false,
    stage3HasFileChanges: false
  };

  componentDidMount = async () => {
    this.props.updateSelectedComplaint("feedbackId", uuidv4());
    this.props.getProgrammeById(localStorage.getItem("programmeId"));
    await this.handleFetchData();
    let form = document.getElementById("complaintForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    await this.props.updateSelectedComplaint(
      "programmeId",
      localStorage.getItem("programmeId")
    );
    this.setState({ hasPageLoaded: true });

    this.props.updateSelectedComplaint("stage", "1");
    this.props.updateSelectedComplaint("status", strings.form.text.initial);
  };

  componentDidUpdate = () => {
    if (
      Object.keys(this.props.selectedProgrammeData).length > 0 &&
      !this.state.isPrePopulated
    ) {
      if (this.props.selectedProgrammeData.owner) {
        this.props.updateSelectedComplaint(
          "owner",
          this.props.selectedProgrammeData.owner
        );
      }
      if (this.props.selectedProgrammeData.area) {
        this.props.updateSelectedComplaint(
          "area",
          this.props.selectedProgrammeData.area
        );
      }
      if (this.props.selectedProgrammeData.firstName) {
        this.props.updateSelectedComplaint(
          "firstName",
          this.props.selectedProgrammeData.firstName
        );
      }
      if (this.props.selectedProgrammeData.surname) {
        this.props.updateSelectedComplaint(
          "surname",
          this.props.selectedProgrammeData.surname
        );
      }
      if (this.props.selectedProgrammeData.pNumber) {
        this.props.updateSelectedComplaint(
          "pNumber",
          this.props.selectedProgrammeData.pNumber
        );
      }
      if (this.props.selectedProgrammeData.scroNumber) {
        this.props.updateSelectedComplaint(
          "scroNumber",
          this.props.selectedProgrammeData.scroNumber
        );
      }
      this.props.updateSelectedComplaint("feedbackType", "Complaint");
      this.setState({ isPrePopulated: true });
    }
  };

  componentWillUnmount() {
    this.props.clearSelectedComplaint();
  }

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;

    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedComplaintData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.feedback.modifyComplaint
    );

    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  commitStage = async stage => {
    await commitFileChanges(
      this.props.commitChanges,
      this.props.localData,
      this.props.remoteData,
      strings.folder[`stage${stage}`],
      strings.entity.complaint,
      this.props.selectedComplaintData.feedbackId
    );
  };

  handleSubmit = async () => {
    if (this.state.stage1HasFileChanges) {
      this.commitStage(1);
    }
    if (this.state.stage2HasFileChanges) {
      this.commitStage(2);
    }
    if (this.state.stage3HasFileChanges) {
      this.commitStage(3);
    }

    const data = textFormattingHelpers.decodeData(
      this.props.selectedComplaintData,
      {},
      textFields
    );

    await this.props.createComplaint(data, this.props.headers);

    this.props.history.push("/complaints");
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearComplaintRequest();
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedComplaint(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedComplaint(data.name, data.value);
    }
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableComplaintModalVisibility = () => {
    this.setState({
      enableDisableComplaintModalOpen: !this.state
        .enableDisableComplaintModalOpen
    });
  };

  stageFilesHaveChanged = stageNumber => {
    this.setState({ [`stage${stageNumber}HasFileChanges`]: true });
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  setComplaintStage = (e, data) => {
    if (
      data.name === strings.form.text.stage1Escalated &&
      data.value === strings.form.text.no
    ) {
      this.props.updateSelectedComplaint(strings.form.text.stage, "1");
    } else if (
      data.name === strings.form.text.stage2Escalated &&
      data.value === strings.form.text.no &&
      this.props.selectedComplaintData.stage1Escalated === strings.form.text.no
    ) {
      this.props.updateSelectedComplaint(strings.form.text.stage, "1");
    } else if (
      data.name === strings.form.text.stage1Escalated &&
      data.value === strings.form.text.yes &&
      (this.props.selectedComplaintData.stage2Escalated ===
        strings.form.text.no ||
        !this.props.selectedComplaintData.stage2Escalated)
    ) {
      this.props.updateSelectedComplaint(strings.form.text.stage, "2");
    } else if (
      data.name === strings.form.text.stage2Escalated &&
      data.value === strings.form.text.no &&
      this.props.selectedComplaintData.stage1Escalated === strings.form.text.yes
    ) {
      this.props.updateSelectedComplaint(strings.form.text.stage, "2");
    } else if (
      data.name === strings.form.text.stage2Escalated &&
      data.value === strings.form.text.yes &&
      this.props.selectedComplaintData.stage1Escalated === strings.form.text.yes
    ) {
      this.props.updateSelectedComplaint(strings.form.text.stage, "3");
    } else if (
      data.name === strings.form.text.stage1Escalated &&
      data.value === strings.form.text.yes &&
      this.props.selectedComplaintData.stage2Escalated === strings.form.text.yes
    ) {
      this.props.updateSelectedComplaint(strings.form.text.stage, "3");
    }
  };

  setStatus = (e, data) => {
    if (data.value === strings.form.text.yes) {
      this.props.updateSelectedComplaint("status", strings.form.text.resolved);
      this.setDateResolved();
    } else if (data.value === strings.form.text.no) {
      this.props.updateSelectedComplaint("status", strings.form.text.open);
      this.resetDateResolved();
    }
  };

  setDateResolved = () => {
    this.props.updateSelectedComplaint(
      "dateResolved",
      new Date().toISOString()
    );
  };

  resetDateResolved = () => {
    this.props.updateSelectedComplaint("dateResolved", undefined);
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.programmeLoadingPage ||
      this.props.orgsLoadingPage ||
      this.props.usersLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <ModifyComplaint
            mode="create"
            pageTitle={strings.header.createComplaint}
            selectedComplaintData={this.props.selectedComplaintData}
            selectedProgrammeData={this.props.selectedProgrammeData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            enableDisableComplaintModalOpen={
              this.state.enableDisableComplaintModalOpen
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableComplaintModalVisibility={
              this.toggleEnableDisableComplaintModalVisibility
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            handleAddActivity={this.handleAddActivity}
            initialComplaintData={{}}
            getOrganisations={this.getOrganisations}
            hasPageLoaded={this.state.hasPageLoaded}
            headers={this.props.headers}
            area={this.props.selectedProgrammeData.area}
            userList={this.props.userListData}
            isDisabled={this.isDisabled()}
            stageFilesHaveChanged={this.stageFilesHaveChanged}
            setComplaintStage={this.setComplaintStage}
            setStatus={this.setStatus}
          />
        </div>
      </div>
    );
  };
}

ComplaintCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedComplaintData: PropTypes.object.isRequired,
  updateSelectedComplaint: PropTypes.func.isRequired,
  clearSelectedComplaint: PropTypes.func.isRequired,
  createComplaint: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    complaints,
    auth,
    organisations,
    referrals,
    programmes,
    users,
    file
  } = state;
  const { loadingPage, selectedComplaintData } = complaints;
  const { organisationOfLoggedInUser } = auth;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const { selectedReferralData, referralRequestStatus } = referrals;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  const { userListData, loadingPage: usersLoadingPage } = users;
  const { localData, remoteData } = file;

  return {
    loadingPage,
    selectedComplaintData,
    organisationListData,
    selectedReferralData,
    selectedProgrammeData,
    referralRequestStatus,
    userListData,
    organisationOfLoggedInUser,
    programmeLoadingPage,
    orgsLoadingPage,
    usersLoadingPage,
    localData,
    remoteData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createComplaint: async (data, headers) => {
      await dispatch(complaintActions.createComplaint(data, headers));
    },
    updateSelectedComplaint: (key, value) => {
      dispatch(complaintActions.updateSelectedComplaint(key, value));
    },
    clearSelectedComplaint: () => {
      dispatch(complaintActions.clearSelectedComplaint());
    },
    clearComplaintRequest: () => {
      dispatch(complaintActions.clearComplaintRequest());
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    commitChanges: (
      localData,
      remoteData,
      filePath,
      headers,
      entity,
      entityId,
      folder
    ) => {
      return dispatch(
        fileActions.commitChanges(
          localData,
          remoteData,
          filePath,
          headers,
          entity,
          entityId,
          folder
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplaintCreateContainer);
