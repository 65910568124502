import { API } from "../../services";
import { settingActionTypes } from "./setting.actionTypes";
import diffObjects from "../../../App/helpers/diffObjects";

const api = new API();

const clearSettingRequest = () => {
  const request = () => {
    return { type: settingActionTypes.CLEAR_CREATE_SETTING_REQUEST };
  };

  const success = () => {
    return { type: settingActionTypes.CLEAR_CREATE_SETTING_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateSetting = (details, headers, initialDetails, userId) => {
  const request = details => {
    return { type: settingActionTypes.UPDATE_SETTING_REQUEST, details };
  };
  const success = response => {
    return {
      type: settingActionTypes.UPDATE_SETTING_SUCCESS,
      response,
      userId
    };
  };
  const failure = error => {
    return {
      type: settingActionTypes.UPDATE_SETTING_FAILURE,
      error,
      initialDetails
    };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "updateSetting" });

  return dispatch => {
    let submission = diffObjects(initialDetails, details).newValues
    dispatch(request(submission));

    api.updateSetting(submission).then(
      response => {
        dispatch(success(response.data, userId));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const getSettings = (headers, isLoggingIn = false) => {
  const request = () => {
    return { type: settingActionTypes.GET_SETTINGS_REQUEST };
  };
  const success = response => {
    return { type: settingActionTypes.GET_SETTINGS_SUCCESS, response };
  };
  const failure = error => {
    return { type: settingActionTypes.GET_SETTINGS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getSettings" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getSettings(isLoggingIn).then(
        response => {
          let data = response.data;
          dispatch(success(data));
          resolve(data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedSetting = (key, value) => {
  const request = () => {
    return {
      type: settingActionTypes.UPDATE_SELECTED_SETTING_REQUEST
    };
  };
  const success = (key, value) => {
    return {
      type: settingActionTypes.UPDATE_SELECTED_SETTING_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: settingActionTypes.UPDATE_SELECTED_SETTING_FAILURE };
  };

  return dispatch => {
    dispatch(request());
    if (key) {
      dispatch(success(key, value));
    } else if (!key && typeof value === "object") {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedSetting = () => {
  const request = () => {
    return { type: settingActionTypes.CLEAR_SELECTED_SETTING_REQUEST };
  };
  const success = () => {
    return { type: settingActionTypes.CLEAR_SELECTED_SETTING_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedSettingUpdate = initialSettingData => {
  const request = () => {
    return { type: settingActionTypes.CANCEL_SELECTED_SETTING_UPDATE_REQUEST };
  };
  const success = data => {
    return {
      type: settingActionTypes.CANCEL_SELECTED_SETTING_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialSettingData));
  };
};

const checkSettingMatchesValue = (value, id, headers) => {
  const request = () => {
    return { type: settingActionTypes.CHECK_SETTING_MATCHES_REQUEST };
  };
  const success = response => {
    return { type: settingActionTypes.CHECK_SETTING_MATCHES_SUCCESS, response };
  };
  const failure = error => {
    return { type: settingActionTypes.CHECK_SETTING_MATCHES_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "checkSettings" });
  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.checkSettingMatchesValue(value, id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

export const settingActions = {
  updateSetting,
  getSettings,
  updateSelectedSetting,
  clearSelectedSetting,
  clearSettingRequest,
  cancelSelectedSettingUpdate,
  checkSettingMatchesValue
};
