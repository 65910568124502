const complaintCategoryOptions = [
  {
    key: 0,
    text: "Service - Poor service quality",
    value: "Service - Poor service quality"
  },
  {
    key: 1,
    text: "Service - Accessibility/Availability",
    value: "Service - Accessibility/Availability"
  },
  { key: 2, text: "Delays/Waiting Times", value: "Delays/Waiting Times" },
  {
    key: 3,
    text: "Colleagues - Poor attitude or conduct",
    value: "Colleagues - Poor attitude or conduct"
  },
  {
    key: 4,
    text: "Colleagues - Lack of knowledge or skills",
    value: "Colleagues - Lack of knowledge or skills"
  },
  { key: 5, text: "Colleagues - Unprepared", value: "Colleagues - Unprepared" },
  {
    key: 6,
    text: "Colleagues - Poor presentation",
    value: "Colleagues - Poor presentation"
  },
  {
    key: 7,
    text: "Colleagues - Allegation of serious misconduct (e.g discrimination)",
    value: "Colleagues - Allegation of serious misconduct (e.g discrimination)"
  },
  {
    key: 8,
    text: "General - Marketing complaints",
    value: "General - Marketing complaints"
  },
  {
    key: 9,
    text: "General - Data Protection complaints",
    value: "General - Data Protection complaints"
  },
  { key: 10, text: "General - Other", value: "General - Other" }
];

const methodOptions = [
  { key: 1, text: "Verbal", value: "Verbal" },
  { key: 2, text: "Postal", value: "Postal" },
  { key: 3, text: "Social Media", value: "Social Media" },
  { key: 4, text: "Email", value: "Email" },
  { key: 5, text: "Other", value: "Other" }
];

const yesNoOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" }
];

export { complaintCategoryOptions, methodOptions, yesNoOptions };
