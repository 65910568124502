import { complaintActionTypes } from "../actions/complaint.actionTypes";

const initialState = {
  loadingPage: false,
  selectedComplaintData: {},
  complaintListData: [{}],
  totalResults: 0,
  complaintToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  complaintRequestStatus: undefined,
  complaintRequestMade: false,
  isFullyLoaded: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let details;
  let newTotal;
  switch (action.type) {
    case complaintActionTypes.CREATE_COMPLAINT_REQUEST:
      return {
        ...state,
        complaintRequestStatus: undefined,
        complaintRequestMade: true
      };
    case complaintActionTypes.CREATE_COMPLAINT_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        complaintRequestStatus: true
      };
    case complaintActionTypes.CREATE_COMPLAINT_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        complaintRequestStatus: false
      };

    case complaintActionTypes.CLEAR_CREATE_COMPLAINT_REQUEST:
      return { ...state };
    case complaintActionTypes.CLEAR_CREATE_COMPLAINT_SUCCESS:
      return {
        ...state,
        complaintRequestMade: false,
        complaintRequestStatus: undefined
      };
    case complaintActionTypes.CLEAR_CREATE_COMPLAINT_FAILURE:
      return { ...state };

    case complaintActionTypes.UPDATE_COMPLAINT_REQUEST:
      return {
        ...state,
        loadingPage: true,
        complaintRequestStatus: undefined,
        complaintRequestMade: true
      };
    case complaintActionTypes.UPDATE_COMPLAINT_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        complaintRequestStatus: true,
        selectedComplaintData: action.response.data.Body,
        showNotification: action.showNotification
      };
    case complaintActionTypes.UPDATE_COMPLAINT_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        complaintRequestStatus: false,
        selectedComplaintData: action.initialDetails,
        errorDetails: details
      };

    case complaintActionTypes.DELETE_COMPLAINT_REQUEST:
      return {
        ...state,
        complaintRequestStatus: undefined,
        complaintRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case complaintActionTypes.DELETE_COMPLAINT_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        complaintRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case complaintActionTypes.DELETE_COMPLAINT_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        complaintRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case complaintActionTypes.GET_COMPLAINTS_REQUEST:
      return {
        ...state,
        loadingPage: true,
        deleteSuccessful: false,
        isFullyLoaded: false
      };
    case complaintActionTypes.GET_COMPLAINTS_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Body.hits.total) {
        newTotal = action.response.data.Body.hits.total;
        rawHits = action.response.data.Body.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newComplaint = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newComplaint;
        });
      } else {
        rawHits = [{}];
      }
      return {
        ...state,
        complaintListData: rawHits,
        totalResults: newTotal,
        loadingPage: false,
        isFullyLoaded: true
      };
    case complaintActionTypes.GET_COMPLAINTS_FAILURE:
      return {
        ...state,
        complaintListData: [{}],
        loadingPage: false,
        isFullyLoaded: true
      };

    case complaintActionTypes.GET_COMPLAINT_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        complaintRequestStatus: undefined
      };
    case complaintActionTypes.GET_COMPLAINT_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        complaintRequestStatus: true,
        selectedComplaintData: action.response.Body,
        loadingPage: false
      };
    case complaintActionTypes.GET_COMPLAINT_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedComplaintData: {},
        complaintRequestStatus: false,
        complaintRequestMade: true
      };

    case complaintActionTypes.UPDATE_SELECTED_COMPLAINT_REQUEST:
      return { ...state };
    case complaintActionTypes.UPDATE_SELECTED_COMPLAINT_SUCCESS:
      return {
        ...state,
        selectedComplaintData: {
          ...state.selectedComplaintData,
          [action.key]: action.value
        }
      };
    case complaintActionTypes.UPDATE_SELECTED_COMPLAINT_FAILURE:
      return { ...state };

    case complaintActionTypes.CLEAR_SELECTED_COMPLAINT_REQUEST:
      return { ...state };
    case complaintActionTypes.CLEAR_SELECTED_COMPLAINT_SUCCESS:
      return { ...state, selectedComplaintData: {}, isFullyLoaded: false };
    case complaintActionTypes.CLEAR_SELECTED_COMPLAINT_FAILURE:
      return { ...state };

    case complaintActionTypes.CANCEL_SELECTED_COMPLAINT_UPDATE_REQUEST:
      return { ...state };
    case complaintActionTypes.CANCEL_SELECTED_COMPLAINT_UPDATE_SUCCESS:
      return { ...state, selectedComplaintData: action.data };
    case complaintActionTypes.CANCEL_SELECTED_COMPLAINT_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};
