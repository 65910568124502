import axios from "./axios-reports";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  saveReport = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("/save", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error saving report: ", error.response.data);
          reject(error);
        });
    });
  };

  uploadReferrals = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("/referrals/save", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error saving report: ", error.response.data);
          reject(error);
        });
    });
  };

  deleteReport = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, null, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting report: ", error.message);
          reject(error);
        });
    });
  };

  getReports = body => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("/list-reports/", JSON.stringify(body), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting reports: ", error.message);
          reject(error);
        });
    });
  };

  searchReportReferrals = body => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("/list-referrals/", JSON.stringify(body), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting referrals: ", error.message);
          reject(error);
        });
    });
  };

  generateReport = body => {
    const config = this.headers;
    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(body), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting report: ", error.message);
          reject(error);
        });
    });
  };

  downloadReportsCSV = async body => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`/download/`, JSON.stringify(body), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error downloading report: ", error.message);
          reject(error);
        });
    });
  };
}
