import { API } from "../../services";
import { dummyRecordActionTypes } from "./dummyRecord.actionTypes";
import { amendmentHelpers } from "../../helpers";
import { strings } from "../../resources";

const api = new API();

const createDummyRecord = (details, headers) => {
  const request = details => {
    return {
      type: dummyRecordActionTypes.CREATE_DUMMY_RECORD_REQUEST,
      details
    };
  };
  const success = response => {
    return {
      type: dummyRecordActionTypes.CREATE_DUMMY_RECORD_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: dummyRecordActionTypes.CREATE_DUMMY_RECORD_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "createDummyRecord" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };
    return new Promise((resolve, reject) => {
      api.createDummyRecord(body).then(
        response => {
          dispatch(success(response));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const clearDummyRecordRequest = () => {
  const request = () => {
    return { type: dummyRecordActionTypes.CLEAR_CREATE_DUMMY_RECORD_REQUEST };
  };

  const success = () => {
    return { type: dummyRecordActionTypes.CLEAR_CREATE_DUMMY_RECORD_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateDummyRecord = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return {
      type: dummyRecordActionTypes.UPDATE_DUMMY_RECORD_REQUEST,
      details
    };
  };
  const success = response => {
    return {
      type: dummyRecordActionTypes.UPDATE_DUMMY_RECORD_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: dummyRecordActionTypes.UPDATE_DUMMY_RECORD_FAILURE,
      error,
      initialDetails
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "updateDummyRecord" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };

    api.updateDummyRecord(id, body).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const deleteDummyRecord = (id, headers) => {
  const request = () => {
    return { type: dummyRecordActionTypes.DELETE_DUMMY_RECORD_REQUEST };
  };
  const success = response => {
    return {
      type: dummyRecordActionTypes.DELETE_DUMMY_RECORD_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: dummyRecordActionTypes.DELETE_DUMMY_RECORD_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteDummyRecord" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteDummyRecord(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getDummyRecords = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly
) => {
  const request = () => {
    return { type: dummyRecordActionTypes.GET_DUMMY_RECORDS_REQUEST };
  };
  const success = response => {
    return { type: dummyRecordActionTypes.GET_DUMMY_RECORDS_SUCCESS, response };
  };
  const failure = error => {
    return { type: dummyRecordActionTypes.GET_DUMMY_RECORDS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getDummyRecords" });

  return dispatch => {
    dispatch(request());
    if (clear) {
      // If supposed to clear, replicate blank result
      dispatch(success({ hits: { hits: [] } }));
    } else {
      api
        .getDummyRecords(queryString, size, from, fields, sort, activeOnly)
        .then(
          response => {
            const entityDetails = JSON.parse(
              localStorage.getItem("entityDetails")
            );

            if (
              entityDetails &&
              entityDetails.entity === strings.amendments.entity
            ) {
              response.data = amendmentHelpers.removeFromList(
                response.data,
                entityDetails
              );
            }

            dispatch(success(response));
          },
          error => dispatch(failure(error))
        );
    }
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly,
  type
) => {
  const request = () => {
    return { type: dummyRecordActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: dummyRecordActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };
  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));

    dispatch(
      getDummyRecords(
        headers,
        queryString,
        size,
        from,
        clear,
        fields,
        sort,
        activeOnly
      )
    );
  };
};

const getDummyRecordById = (id, headers) => {
  const request = () => {
    return { type: dummyRecordActionTypes.GET_DUMMY_RECORD_BY_ID_REQUEST };
  };
  const success = response => {
    return {
      type: dummyRecordActionTypes.GET_DUMMY_RECORD_BY_ID_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: dummyRecordActionTypes.GET_DUMMY_RECORD_BY_ID_FAILURE,
      error
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getDummyRecordById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getDummyRecordById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedDummyRecord = (key, value) => {
  const request = (key, value) => {
    return {
      type: dummyRecordActionTypes.UPDATE_SELECTED_DUMMY_RECORD_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: dummyRecordActionTypes.UPDATE_SELECTED_DUMMY_RECORD_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return {
      type: dummyRecordActionTypes.UPDATE_SELECTED_DUMMY_RECORD_FAILURE
    };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedDummyRecord = () => {
  const request = () => {
    return { type: dummyRecordActionTypes.CLEAR_SELECTED_DUMMY_RECORD_REQUEST };
  };
  const success = () => {
    return { type: dummyRecordActionTypes.CLEAR_SELECTED_DUMMY_RECORD_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedDummyRecordUpdate = initialDummyRecordData => {
  const request = () => {
    return {
      type: dummyRecordActionTypes.CANCEL_SELECTED_DUMMY_RECORD_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type: dummyRecordActionTypes.CANCEL_SELECTED_DUMMY_RECORD_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialDummyRecordData));
  };
};

export const dummyRecordActions = {
  createDummyRecord,
  updateDummyRecord,
  deleteDummyRecord,
  getDummyRecordById,
  getDummyRecords,
  updateSearchCriteria,
  updateSelectedDummyRecord,
  clearSelectedDummyRecord,
  clearDummyRecordRequest,
  cancelSelectedDummyRecordUpdate
};
