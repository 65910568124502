import React from "react";
import ModifyUser from "../../components/ModifyUser";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, userRoleOptions } from "../../resources";
import { userActions } from "../../store/actions/user.actions";
import { authActions } from "../../../App/store/actions/auth.actions";
import { auditActions } from "../../../Audit/store/actions/audit.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  getOrganisations,
  allowNumericalInput,
  convertToIso,
  incrementObject,
  handleLogout
} from "../../helpers";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";

class UserEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableUserModalOpen: false,
    requestDeleteUserModalOpen: false,
    initialUserData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    deletionReason: undefined,
    fetchedPermissionStatus: false,
    permissionStatus: "",
    showAuditLog: false,
    roleArea: ""
  };

  componentDidMount = async () => {
    try {
      await this.props.getUserById(
        this.props.match.params.id,
        this.props.headers
      );
      if (!incrementObject(this.props.match.params.id)) {
        this.props.lockUser(this.props.header);
        handleLogout(
          this.props.userId,
          this.props.username,
          this.props.headers,
          this.props.createAudit,
          this.props.logout
        );
      }
      const sCrit = this.state.searchCriteria;
      this.props.getOrganisations(
        this.props.headers,
        sCrit.query,
        10000,
        0,
        sCrit.clear,
        sCrit.fields,
        sCrit.sort,
        sCrit.activeOnly
      );

      const { selectedUserData = {} } = this.props;
      const currentRoleOption = userRoleOptions.find(
        role => role.value === selectedUserData.roleId
      );
      if (currentRoleOption && currentRoleOption.area) {
        this.setState({ roleArea: currentRoleOption.area });
      }

      if (this.state.mode === "view" && this.props.selectedUserData.requestId) {
        await this.props.getDeletePermissionStatus(
          this.props.selectedUserData.requestId
        );
        await this.setState(
          { permissionStatus: this.props.permissionStatus },
          async () => {
            if (this.state.permissionStatus === strings.states.rejected) {
              await this.props.updateSelectedUser("requestId", null);
              this.props.updateUser(
                this.props.selectedUserData.userId,
                this.props.selectedUserData,
                this.props.headers,
                this.state.initialUserData,
                false
              );
            }
          }
        );
        this.props.clearAmendmentRequest();
      }
    } catch (ex) {
      throw ex;
    } finally {
      this.setState({
        fetchedPermissionStatus: true,
        initialUserData: this.props.selectedUserData
      });
      let form = document.getElementById("engagementForm");
      if (form) {
        form.setAttribute("novalidate", true);
        //set the form to have the novalidate attribute to suppress the default html validation tooltips
      }
    }
  };

  componentWillUnmount() {
    this.props.clearUserRequest();
    this.props.clearSelectedUser();
    this.props.clearAmendmentRequest();
  }

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedUserData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.users.updateUser
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    await this.props.updateUser(
      this.props.match.params.id,
      this.props.selectedUserData,
      this.props.headers,
      this.state.initialUserData
    );
    this.handleModeSwitch();

    window.setTimeout(() => {
      this.props.clearUserRequest();
    }, 10000);
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedUserData.userId,
      this.state.deletionReason.value,
      "User",
      `${this.props.selectedUserData.firstName} ${this.props.selectedUserData.surname}`,
      this.props.headers,
      this.props.selectedUserData
    );
    this.props.updateSelectedUser("requestId", this.props.requestId);
    this.props.updateUser(
      this.props.selectedUserData.userId,
      this.props.selectedUserData,
      this.props.headers,
      this.state.initialUserData
    );
    this.setState({
      initialUserData: this.props.selectedUserData
    });

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearUserRequest();
    }, 10000);
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteUser(
      this.props.selectedUserData.userId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      await this.props.setAmendmentStatusActioned(
        this.props.selectedUserData.requestId,
        this.props.headers,
        true,
        {
          entity: strings.amendments.entity,
          id: this.props.selectedUserData.userId,
          type: strings.amendments.deletion
        }
      );

      this.props.history.push("/users");
    }

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearUserRequest();
    }, 10000);
  };

  handleChange = async (event, data) => {
    if (data.name === "roleId") {
      await this.props.updateSelectedUser("caseloadMax", "");
      await this.props.updateSelectedUser("prison", "");

      const selectedRole = data.options.find(
        option => option.value === data.value
      );
      if (selectedRole && selectedRole.area) {
        await this.props.updateSelectedUser("area", selectedRole.area);

        this.setState({ roleArea: selectedRole.area });
      } else {
        this.setState({ roleArea: "" });
      }
    }
    if (data.name === "organisationId") {
      this.calculatePrisons(data.value);
      await this.props.updateSelectedUser("prison", "");
      this.calculateLocalAuthorityAreas(data.value);
      await this.props.updateSelectedUser("localAuthorityArea", "");
    }
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedUser(data.name, convertToIso(data.value));
    } else {
      await this.props.updateSelectedUser(data.name, data.value);
    }
  };

  handleEnableDisable = async () => {
    const body = {
      username: this.props.selectedUserData.username,
      active: !this.props.selectedUserData.active
    };
    await this.props.updateUser(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialUserData
    );
  };

  handleToggleAudit = () => {
    const isAudit = this.state.showAuditLog;
    this.setState({ showAuditLog: !isAudit });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;

      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleModalVisibility = modalName => {
    if (modalName === "disableModal") {
      this.setState({
        enableDisableUserModalOpen: !this.state.enableDisableUserModalOpen
      });
    } else if (modalName === "deleteModal") {
      this.setState({
        requestDeleteUserModalOpen: !this.state.requestDeleteUserModalOpen
      });
    }
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialUserData) !==
        JSON.stringify(this.props.selectedUserData)
      ) {
        await this.props.cancelSelectedUserUpdate(this.state.initialUserData);
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialUserData: JSON.parse(JSON.stringify(this.props.selectedUserData))
      });
    }
  };

  getInitialPrisonOptions = () => {
    let prisonList;
    let dropdownFields = [];
    if (
      this.props.selectedUserData &&
      this.props.organisationListData.length > 1
    ) {
      this.props.organisationListData.forEach(org => {
        if (
          this.hasValue(
            org["_source"],
            "organisationId",
            this.props.selectedUserData.organisationId
          )
        ) {
          prisonList = org["_source"].prisons;
        }
      });
    }
    if (prisonList) {
      prisonList.forEach((prison, key) => {
        dropdownFields.push({
          key: key,
          text: prison,
          value: prison
        });
      });
    }
    return dropdownFields;
  };

  calculatePrisons = organisationId => {
    let prisonList;
    let dropdownFields = [];
    this.props.organisationListData.forEach(org => {
      if (this.hasValue(org["_source"], "organisationId", organisationId)) {
        prisonList = org["_source"].prisons;
      }
    });
    if (prisonList) {
      prisonList.forEach((prison, key) => {
        dropdownFields.push({
          key: key,
          text: prison,
          value: prison
        });
      });
    }
    this.setState({ prisons: dropdownFields });
  };

  getInitialAreaOptions = () => {
    let areaList;
    let dropdownFields = [];
    if (
      this.props.selectedUserData &&
      this.props.organisationListData.length > 1
    ) {
      this.props.organisationListData.forEach(org => {
        if (
          this.hasValue(
            org["_source"],
            "organisationId",
            this.props.selectedUserData.organisationId
          )
        ) {
          areaList = org["_source"].localAuthorityArea;
        }
      });
    }
    if (areaList) {
      areaList.forEach((prison, key) => {
        dropdownFields.push({
          key: key,
          text: prison,
          value: prison
        });
      });
    }
    return dropdownFields;
  };

  calculateLocalAuthorityAreas = organisationId => {
    let areaList;
    let dropdownFields = [];
    this.props.organisationListData.forEach(org => {
      if (this.hasValue(org["_source"], "organisationId", organisationId)) {
        areaList = org["_source"].localAuthorityArea;
      }
    });
    if (areaList) {
      areaList.forEach((area, key) => {
        dropdownFields.push({
          key: key,
          text: area,
          value: area
        });
      });
    }
    this.setState({ localAuthorityAreas: dropdownFields });
  };

  hasValue = (obj, key, value) => {
    return obj.hasOwnProperty(key) && obj[key] === value;
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      !this.state.fetchedPermissionStatus ||
      this.props.orgsLoadingPage;

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.requestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          {this.props.showNotification && (
            <RequestFeedback
              className={
                this.props.amendmentRequestStatus ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.userRequestStatus}
              requestMade={this.props.userRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={strings.form.feedback.userRequestUnknown}
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyUser
            mode={this.state.mode}
            role={this.props.roleId}
            requestStatus={this.props.userRequestStatus}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editUser
                : strings.header.viewUser
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleModalVisibility={this.toggleModalVisibility}
            selectedUserData={this.props.selectedUserData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enableDisableUserModalOpen={this.state.enableDisableUserModalOpen}
            requestDeleteUserModalOpen={this.state.requestDeleteUserModalOpen}
            initialUserData={this.state.initialUserData}
            formInvalid={this.state.formInvalid}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            organisationList={getOrganisations(this.props.organisationListData)}
            allowNumericalInput={allowNumericalInput}
            initialPrisonOptions={this.getInitialPrisonOptions()}
            prisonOptions={this.state.prisons}
            initialLocalAuthorityAreaOptions={this.getInitialAreaOptions()}
            localAuthorityAreaOptions={this.state.localAuthorityAreas}
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            headers={this.props.headers}
            handleToggleAudit={this.handleToggleAudit}
            showAuditLog={this.state.showAuditLog}
            roleArea={this.state.roleArea}
          />
        </div>
      </div>
    );
  };
}

UserEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedUserData: PropTypes.object.isRequired,
  updateSelectedUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { users, auth, amendments } = state;
  const {
    loadingPage,
    selectedUserData,
    userRequestStatus,
    userRequestMade,
    result,
    error,
    errorDetails,
    deleteSuccessful,
    showNotification
  } = users;
  const { roleId, userId, username } = auth;
  const {
    organisationListData,
    loadingPage: orgsLoadingPage
  } = state.organisations;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails
  } = amendments;
  return {
    loadingPage,
    selectedUserData,
    userRequestStatus,
    userRequestMade,
    result,
    error,
    roleId,
    errorDetails,
    organisationListData,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    orgsLoadingPage,
    amendmentRequestStatus,
    amendmentRequestMade,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails,
    userId,
    username
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (
      id,
      data,
      headers,
      initialUserData,
      showNotification = true
    ) => {
      dispatch(
        userActions.updateUser(
          id,
          data,
          headers,
          initialUserData,
          showNotification
        )
      );
    },
    getUserById: async (id, headers) => {
      await dispatch(userActions.getUserById(id, headers));
    },
    updateSelectedUser: (key, value) => {
      dispatch(userActions.updateSelectedUser(key, value));
    },
    cancelSelectedUserUpdate: data => {
      dispatch(userActions.cancelSelectedUserUpdate(data));
    },
    clearUserRequest: () => {
      dispatch(userActions.clearUserRequest());
    },
    clearSelectedUser: () => {
      dispatch(userActions.clearSelectedUser());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    requestDeletePermission: async (
      id,
      reason,
      entity,
      recordIdentifier,
      headers,
      body
    ) => {
      await dispatch(
        amendmentActions.requestDeletePermission(
          id,
          reason,
          entity,
          recordIdentifier,
          headers,
          body
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteUser: async (id, headers) => {
      await dispatch(userActions.deleteUser(id, headers));
    },
    setAmendmentStatusActioned: async (
      id,
      headers,
      sendNotification,
      entityDetails
    ) => {
      await dispatch(
        amendmentActions.setAmendmentStatusActioned(
          id,
          headers,
          sendNotification,
          entityDetails
        )
      );
    },
    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    lockUser: headers => {
      dispatch(userActions.lockUser(headers));
    },
    logout: hasExceeded => {
      dispatch(authActions.signOut(hasExceeded));
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditContainer);
