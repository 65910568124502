import React from "react";
import { Menu, Icon, Grid, Accordion } from "semantic-ui-react";
import { ShowIfIncludedArea, ShowIfAuthorised } from "../../";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class CollapsableMenuItem extends React.Component {
  renderElement = (item, isParent) => {
    return (
      <Link
        to={
          "/" + item.menuItemUrl + (item.urlParameter ? item.urlParameter : "")
        }
        className="item"
      >
        <Grid columns={2} className="containerGrid">
          <Grid.Column width={1}>
            <Icon name={item.iconName} className="menuItemIcon" />
          </Grid.Column>
          {this.props.sidebarOpened && (
            <Grid.Column width={12}>{item.menuItemName}</Grid.Column>
          )}
          <Grid.Column width={1}>
            {this.props.subItems && isParent && (
              <Icon
                name={`caret ${
                  this.props.activeItem === this.props.menuItemName &&
                  this.props.isSubMenuOpen
                    ? "up"
                    : "down"
                }`}
                className="submenuItemIcon"
              />
            )}
          </Grid.Column>
        </Grid>
      </Link>
    );
  };

  renderSubMenu = area => {
    return this.props.subItems.map((item, key) => (
      <ShowIfIncludedArea
        key={key}
        area={area}
        allowedAreas={item.allowedAreas}
      >
        <ShowIfAuthorised key={key} allowedRoles={item.menuItemRole}>
          <Menu.Item
            name={item.menuItemName}
            url={item.menuItemUrl}
            className="collapsableMenuSubItem"
            active={this.props.activeSubItem === item.menuItemUrl}
            onClick={this.props.handleSubItemClick}
            type={item.type}
            as="div"
          >
            {this.renderElement(item, false)}
          </Menu.Item>
        </ShowIfAuthorised>
      </ShowIfIncludedArea>
    ));
  };

  render = () => {
    return (
      <Menu.Item
        name={this.props.menuItemName}
        className="collapsableMenuItem"
        active={this.props.activeItem === this.props.menuItemName}
        onClick={this.props.handleItemClick}
        as="div"
      >
        {this.props.subItems ? (
          <Accordion>
            <Accordion.Title onClick={this.props.handleItemClick}>
              {this.renderElement(this.props, true)}
            </Accordion.Title>
            <Accordion.Content
              active={
                this.props.activeItem === this.props.menuItemName &&
                this.props.isSubMenuOpen
              }
            >
              {this.renderSubMenu(this.props.area)}
            </Accordion.Content>
          </Accordion>
        ) : (
          this.renderElement(this.props)
        )}
      </Menu.Item>
    );
  };
}

CollapsableMenuItem.propTypes = {
  activeItem: PropTypes.string.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  sidebarOpened: PropTypes.bool.isRequired,
  menuItemName: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  urlParameter: PropTypes.string
};

export default CollapsableMenuItem;
