import React from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { BasicModal } from "..";
import PropTypes from "prop-types";
import { convertFromIso } from "../../helpers/dateHelpers";
import "./css/actions.scss";

import {
  ValidatedFormInput,
  ValidatedDateInput,
  ValidatedFormDropdown,
  ValidatedFormTextArea
} from ".";

const ValidatedMultipleActions = props => {
  return (
    <>
      <Table className="actionsTable" basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1} textAlign="center">
              Action
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>{props.actionsString}</Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {props.actionsOtherString}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {props.actionStatusString}
            </Table.HeaderCell>
            <Table.HeaderCell className="action-field" width={3}>
              {props.actionDateString}
            </Table.HeaderCell>
            <Table.HeaderCell width={8}>{props.notesString}</Table.HeaderCell>
            <Table.HeaderCell width={3} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.selectedAreaOfNeedData.actionsTable &&
            (props.selectedAreaOfNeedData.actionsTable[props.areaOfNeed]
              ? props.selectedAreaOfNeedData.actionsTable[props.areaOfNeed].map(
                  (val, idx) => {
                    let actionsId = `actions-${idx}`,
                      actionsOtherId = `actionsOther-${idx}`,
                      actionStatusId = `actionStatus-${idx}`,
                      actionStartedDateId = `actionStartedDate-${idx}`,
                      actionClosedDateId = `actionClosedDate-${idx}`,
                      notesId = `notes-${idx}`;
                    return (
                      <Table.Row key={idx}>
                        <Table.Cell textAlign="center">
                          <span>{idx + 1}</span>
                        </Table.Cell>
                        <Table.Cell>
                          <ValidatedFormDropdown
                            formInvalid={props.formInvalid}
                            inputData={
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ][idx].actions
                            }
                            viewData={
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ][idx].actions
                            }
                            dropdownLabel={props.actionsString}
                            dropdownPlaceholder={props.actionsPlaceholder}
                            inputClassName="mini"
                            dropdownName={actionsId}
                            dropdownOptions={
                              props.areaOfNeed === props.otherString
                                ? props.customDropdownOptions
                                : props.areaOfNeed
                                ? props.dropdownOptions[
                                    props.dropdownOptions.areaOfNeedOptions.find(
                                      option =>
                                        option.value === props.areaOfNeed
                                    ) !== undefined
                                      ? props.dropdownOptions.areaOfNeedOptions.find(
                                          option =>
                                            option.value === props.areaOfNeed
                                        ).match
                                      : 0
                                  ]
                                : []
                            }
                            formFieldWidth={8}
                            handleChange={
                              props.areaOfNeed !== props.otherString
                                ? (e, data) => {
                                    props.handleActionChange(
                                      e,
                                      data,
                                      props.areaOfNeed
                                    );
                                  }
                                : (e, data) => {
                                    props.handleActionChange(
                                      e,
                                      data,
                                      props.areaOfNeed
                                    );
                                  }
                            }
                            validationResult={
                              props.validationResults.actionFields
                                ? props.validationResults.actionFields[
                                    props.areaOfNeed
                                  ]
                                  ? props.validationResults.actionFields[
                                      props.areaOfNeed
                                    ][idx]
                                    ? props.validationResults.actionFields[
                                        props.areaOfNeed
                                      ][idx].actions
                                    : undefined
                                  : undefined
                                : undefined
                            }
                            dropdownSearch={true}
                            mode={props.mode}
                            allowAdditions={
                              props.areaOfNeed === props.otherString
                            }
                            onAddItem={props.handleDropdownOptionAddition}
                            required={true}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <ValidatedFormInput
                            formInvalid={props.formInvalid}
                            inputData={
                              props.selectedAreaOfNeedData.actionsTable &&
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ] &&
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ][idx] &&
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ][idx].actions === props.otherString
                                ? props.selectedAreaOfNeedData.actionsTable[
                                    props.areaOfNeed
                                  ][idx].actionsOther
                                : ""
                            }
                            viewData={
                              props.selectedAreaOfNeedData.actionsTable &&
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ] &&
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ][idx] &&
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ][idx].actions === props.otherString
                                ? props.selectedAreaOfNeedData.actionsTable[
                                    props.areaOfNeed
                                  ][idx].actionsOther
                                : ""
                            }
                            inputLabel={props.actionsOtherString}
                            inputPlaceholder={props.actionsOtherPlaceholder}
                            inputClassName="mini"
                            inputName={actionsOtherId}
                            formFieldWidth={8}
                            handleChange={
                              props.areaOfNeed !== props.otherString
                                ? (e, data) => {
                                    props.handleActionChange(
                                      e,
                                      data,
                                      props.areaOfNeed
                                    );
                                  }
                                : (e, data) => {
                                    props.handleActionChange(
                                      e,
                                      data,
                                      props.areaOfNeed
                                    );
                                  }
                            }
                            validationResult={
                              props.validationResults.actionFields
                                ? props.validationResults.actionFields[
                                    props.areaOfNeed
                                  ]
                                  ? props.validationResults.actionFields[
                                      props.areaOfNeed
                                    ][idx]
                                    ? props.validationResults.actionFields[
                                        props.areaOfNeed
                                      ][idx].actionsOther
                                    : undefined
                                  : undefined
                                : undefined
                            }
                            mode={props.mode}
                            required={true}
                            disabled={
                              props.selectedAreaOfNeedData.actionsTable &&
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ] &&
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ][idx] &&
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ][idx].actions !== props.otherString
                            }
                          />
                        </Table.Cell>

                        <Table.Cell>
                          <ValidatedFormDropdown
                            formInvalid={props.formInvalid}
                            inputData={
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ][idx].actionStatus
                            }
                            viewData={
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ][idx].actionStatus
                            }
                            dropdownLabel={props.actionStatusString}
                            dropdownPlaceholder={props.actionStatusPlaceholder}
                            inputClassName="mini"
                            dropdownName={actionStatusId}
                            dropdownOptions={
                              props.dropdownOptions.actionStatusOptions
                            }
                            formFieldWidth={8}
                            handleChange={(e, data) => {
                              props.handleActionChange(
                                e,
                                data,
                                props.areaOfNeed
                              );
                            }}
                            validationResult={
                              props.validationResults.actionFields
                                ? props.validationResults.actionFields[
                                    props.areaOfNeed
                                  ]
                                  ? props.validationResults.actionFields[
                                      props.areaOfNeed
                                    ][idx]
                                    ? props.validationResults.actionFields[
                                        props.areaOfNeed
                                      ][idx].actionStatus
                                    : undefined
                                  : undefined
                                : undefined
                            }
                            mode={props.mode}
                            required={true}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {props.selectedAreaOfNeedData.actionsTable[
                            props.areaOfNeed
                          ][idx].actionStatus === props.openString && (
                            <ValidatedDateInput
                              inputId="actionStartedDate"
                              formInvalid={props.formInvalid}
                              inputLabel={props.actionStartedDateString}
                              inputName={actionStartedDateId}
                              inputPlaceholder={props.actionDatePlaceholder}
                              inputDateFormat="DD/MM/YYYY"
                              inputData={
                                props.selectedAreaOfNeedData.actionsTable[
                                  props.areaOfNeed
                                ][idx].actionStartedDate
                                  ? convertFromIso(
                                      props.selectedAreaOfNeedData.actionsTable[
                                        props.areaOfNeed
                                      ][idx].actionStartedDate
                                    )
                                  : ""
                              }
                              iconPosition="left"
                              formFieldWidth={8}
                              handleChange={(e, data) => {
                                props.handleActionChange(
                                  e,
                                  data,
                                  props.areaOfNeed
                                );
                              }}
                              validationResult={
                                props.validationResults.actionFields
                                  ? props.validationResults.actionFields[
                                      props.areaOfNeed
                                    ]
                                    ? props.validationResults.actionFields[
                                        props.areaOfNeed
                                      ][idx]
                                      ? props.validationResults.actionFields[
                                          props.areaOfNeed
                                        ][idx].actionStartedDate
                                      : undefined
                                    : undefined
                                  : undefined
                              }
                              mode={props.mode}
                              required={true}
                            />
                          )}
                          {props.selectedAreaOfNeedData.actionsTable[
                            props.areaOfNeed
                          ][idx].actionStatus === props.closedString && (
                            <ValidatedDateInput
                              inputId="actionClosedDate"
                              formInvalid={props.formInvalid}
                              inputLabel={props.actionClosedDateString}
                              inputName={actionClosedDateId}
                              inputPlaceholder={props.actionDatePlaceholder}
                              inputDateFormat="DD/MM/YYYY"
                              inputData={
                                props.selectedAreaOfNeedData.actionsTable[
                                  props.areaOfNeed
                                ][idx].actionClosedDate
                                  ? convertFromIso(
                                      props.selectedAreaOfNeedData.actionsTable[
                                        props.areaOfNeed
                                      ][idx].actionClosedDate
                                    )
                                  : ""
                              }
                              iconPosition="left"
                              formFieldWidth={8}
                              handleChange={(e, data) => {
                                props.handleActionChange(
                                  e,
                                  data,
                                  props.areaOfNeed
                                );
                              }}
                              validationResult={
                                props.validationResults.actionFields
                                  ? props.validationResults.actionFields[
                                      props.areaOfNeed
                                    ]
                                    ? props.validationResults.actionFields[
                                        props.areaOfNeed
                                      ][idx]
                                      ? props.validationResults.actionFields[
                                          props.areaOfNeed
                                        ][idx].actionClosedDate
                                      : undefined
                                    : undefined
                                  : undefined
                              }
                              mode={props.mode}
                              required={true}
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <ValidatedFormTextArea
                            formInvalid={props.formInvalid}
                            inputData={
                              props.selectedAreaOfNeedData.actionsTable[
                                props.areaOfNeed
                              ][idx].notes
                            }
                            inputLabel={props.notesString}
                            inputName={notesId}
                            inputPlaceholder={props.notesPlaceholder}
                            formFieldWidth={16}
                            handleChange={(e, data) => {
                              props.handleActionChange(
                                e,
                                data,
                                props.areaOfNeed
                              );
                            }}
                            validationResult={
                              props.validationResults.actionFields
                                ? props.validationResults.actionFields[
                                    props.areaOfNeed
                                  ]
                                  ? props.validationResults.actionFields[
                                      props.areaOfNeed
                                    ][idx]
                                    ? props.validationResults.actionFields[
                                        props.areaOfNeed
                                      ][idx].notes
                                    : undefined
                                  : undefined
                                : undefined
                            }
                            mode={props.mode}
                            required={false}
                          />
                        </Table.Cell>
                        {props.mode !== "view" && idx !== 0 && (
                          <Table.Cell textAlign="center">
                            <Icon
                              className="actionDeleteIcon"
                              name="trash outline alternate"
                              onClick={() =>
                                props.toggleDeleteModal(idx, props.areaOfNeed)
                              }
                            />
                          </Table.Cell>
                        )}
                      </Table.Row>
                    );
                  }
                )
              : undefined)}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell colSpan="5">
              <Button
                className="addActionButton"
                floated="right"
                icon
                labelPosition="left"
                size="small"
                onClick={() => {
                  props.addAction(props.areaOfNeed);
                }}
              >
                <Icon name="plus" /> Add action
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <BasicModal
        confirmMessage={props.deleteActionMessage}
        handleModalConfirm={props.handleModalConfirm}
        modalClassName="deletionActionModal"
        modalOpen={props.deleteModalOpen}
        showNoButton={true}
        showOkButton={false}
        showYesButton={true}
        toggleModal={props.toggleDeleteModal}
        noLabel={props.noLabel}
        yesLabel={props.yesLabel}
        okLabel={props.okLabel}
      />
    </>
  );
};

ValidatedMultipleActions.propTypes = {
  addAction: PropTypes.func.isRequired,
  actionDatePlaceholder: PropTypes.string.isRequired,
  actionDateString: PropTypes.string,
  actionStartedDateString: PropTypes.string,
  actionClosedDateString: PropTypes.string,
  actionsOtherString: PropTypes.string,
  actionsOtherPlaceholder: PropTypes.string,
  actionsPlaceholder: PropTypes.string.isRequired,
  actionsString: PropTypes.string.isRequired,
  actionStatusPlaceholder: PropTypes.string.isRequired,
  actionStatusString: PropTypes.string.isRequired,
  notesString: PropTypes.string.isRequired,
  notesPlaceholder: PropTypes.string.isRequired,
  deleteActionMessage: PropTypes.string.isRequired,
  deleteModalOpen: PropTypes.bool.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  handleActionChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  handleDropdownOptionAddition: PropTypes.func,
  mode: PropTypes.string,
  noLabel: PropTypes.string.isRequired,
  okLabel: PropTypes.string.isRequired,
  selectedAreaOfNeedData: PropTypes.object.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  validationResults: PropTypes.object,
  yesLabel: PropTypes.string.isRequired,
  dropdownOptions: PropTypes.object.isRequired,
  closedString: PropTypes.string,
  openString: PropTypes.string,
  areaOfNeed: PropTypes.string,
  tableId: PropTypes.number,
  otherString: PropTypes.string,
  customDropdownOptions: PropTypes.array
};

export default ValidatedMultipleActions;
