import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Loader, Divider } from "semantic-ui-react";
import { RequestFeedback } from "../../components";
import ModifySetting from "../../components/ModifySetting";
import { settingActions } from "../../store/actions/setting.actions";
import { strings } from "../../resources";

class SettingsEditContainer extends React.Component {
  state = {
    mode: "view",
    cancelChangesModalOpen: false,
    initialSettingData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    confirmSaveModalOpen: false,
    fetchedPermissionStatus: false,
    permissionStatus: ""
  };

  componentDidMount = async () => {
    await this.props.getSettings(this.props.headers);
    let form = document.getElementById("settingForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
  };

  componentWillUnmount() {
    this.props.clearSettingRequest();
  }

  validateForm = async () => {
    this.setState({ formInvalid: false });
    this.toggleConfirmSaveModalVisibility();
  };

  handleSubmit = async () => {
    await this.props.updateSetting(
      this.props.selectedSettingData,
      this.props.headers,
      this.state.initialSettingData,
      this.props.userId
    );
    this.handleModeSwitch();
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    let formattedValue = data.value;
    await this.props.updateSelectedSetting(data.name, formattedValue);
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };
  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialSettingData) !==
        JSON.stringify(this.props.selectedSettingData)
      ) {
        await this.props.cancelSelectedSettingUpdate(
          this.state.initialSettingData
        );
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialSettingData: this.props.selectedSettingData
      });
    }
  };

  render = () => {
    return (
      <div>
        <div
          style={{
            display: this.props.loadingPage ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: this.props.loadingPage ? "none" : ""
          }}
        >
          <RequestFeedback
            requestStatus={this.props.settingRequestStatus}
            requestMade={this.props.settingRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={strings.form.feedback.settingRequestUnknown}
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <ModifySetting
            mode={this.state.mode}
            requestStatus={this.props.settingRequestStatus}
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editSettings
                : strings.header.viewSettings
            }
            selectedSettingData={this.props.selectedSettingData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            initialSettingData={this.state.initialSettingData}
            formInvalid={this.state.formInvalid}
            validateForm={this.validateForm}
            validateField={this.validateField}
            validationResults={this.state.validationResults}
            getAuditFreqByValue={this.getAuditFreqByValue}
            settingChanges={this.areSettingChanges}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
          />
        </div>
      </div>
    );
  };
}

SettingsEditContainer.propTypes = {
  cancelSelectedSettingUpdate: PropTypes.func.isRequired,
  clearSettingRequest: PropTypes.func.isRequired,
  error: PropTypes.string,
  errorDetails: PropTypes.string,
  getSettings: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  result: PropTypes.string,
  selectedSettingData: PropTypes.object.isRequired,
  settingRequestMade: PropTypes.bool,
  settingRequestStatus: PropTypes.bool,
  updateSelectedSetting: PropTypes.func.isRequired,
  updateSetting: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { settings, auth } = state;
  const {
    loadingPage,
    selectedSettingData,
    settingRequestStatus,
    settingRequestMade,
    result,
    error
  } = settings;
  const { userId } = auth;

  return {
    loadingPage,
    selectedSettingData,
    settingRequestStatus,
    settingRequestMade,
    result,
    error,
    userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSetting: (id, data, headers, initialSettingData, userId) => {
      dispatch(
        settingActions.updateSetting(
          id,
          data,
          headers,
          initialSettingData,
          userId
        )
      );
    },
    getSettings: headers => {
      dispatch(settingActions.getSettings(headers));
    },
    updateSelectedSetting: (key, value) => {
      dispatch(settingActions.updateSelectedSetting(key, value));
    },
    cancelSelectedSettingUpdate: data => {
      dispatch(settingActions.cancelSelectedSettingUpdate(data));
    },
    clearSettingRequest: () => {
      dispatch(settingActions.clearSettingRequest());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsEditContainer);
