import React from "react";
import ModifyTtgRecord from "../../components/ModifyTtgRecord";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { ttgRecordActions } from "../../store/actions/ttgRecord.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { fileActions } from "../../../File/store/actions/file.actions";
import PropTypes from "prop-types";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  convertToIso,
  getUsers,
  commitFileChanges,
  textFormattingHelpers
} from "../../helpers";
import uuidv4 from "uuid/v4";

class TtgRecordCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableTtgRecordModalOpen: false,
    formInvalid: false,
    validationResults: {},
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    isPrePopulated: false,
    hasFileChanges: false,
    hasPageLoaded: false,
    userList: [],
    isMarkerRecord: this.startedAsMarkerRecord()
  };

  componentDidMount = async () => {
    this.props.clearSelectedTtgRecord();
    if (this.startedAsMarkerRecord()) {
      this.props.updateSelectedTtgRecord("dummyId", uuidv4());
      this.props.updateSelectedTtgRecord("firstName", "DNS");
      this.props.updateSelectedTtgRecord("surname", "DNS");
    } else {
      this.props.updateSelectedTtgRecord("ttgProgrammeId", uuidv4());
    }
    await this.handleFetchData();
    let form = document.getElementById("ttgRecordForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }

    this.setState({
      hasPageLoaded: true,
      userList: getUsers(this.props.userListData)
    });

    await this.props.updateSelectedTtgRecord(
      strings.form.fields.markerRecord,
      this.startedAsMarkerRecord()
        ? strings.form.text.yes
        : strings.form.text.no
    );
    this.props.updateSelectedTtgRecord("cjSpecialist", this.props.userId);
    this.setDefaultValues();
  };

  setDefaultValues() {
    this.props.updateSelectedTtgRecord(
      strings.form.fields.prison,
      strings.form.dropDownOptions.hmpDurham
    );
    this.props.updateSelectedTtgRecord(
      strings.form.fields.areasOfNeedIdentified,
      strings.form.text.no
    );
    this.props.updateSelectedTtgRecord(
      strings.form.fields.accommodationNeed,
      strings.form.text.no
    );
    this.props.updateSelectedTtgRecord(
      strings.form.fields.financeBenefitAndDebtNeed,
      strings.form.text.no
    );
    this.props.updateSelectedTtgRecord(
      strings.form.fields.educationTrainingEmploymentNeed,
      strings.form.text.no
    );
    this.props.updateSelectedTtgRecord(
      strings.form.fields.domesticAbuseNeed,
      strings.form.text.no
    );
    this.props.updateSelectedTtgRecord(
      strings.form.fields.sexWorkNeed,
      strings.form.text.no
    );
    this.props.updateSelectedTtgRecord(
      strings.form.fields.otherNeed,
      strings.form.text.no
    );
    this.props.updateSelectedTtgRecord(
      strings.form.fields.area,
      strings.form.text.ttg
    );
  }

  componentWillUnmount() {
    this.props.clearSelectedTtgRecord();
  }

  startedAsMarkerRecord() {
    return (
      this.props.location.state &&
      this.props.location.state.from === "dummyRecords"
    );
  }

  filesHaveChanged = () => {
    this.setState({ hasFileChanges: true });
  };

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    sCrit.query = "ttg";
    sCrit.fields = ["area.normalizer"];
    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedTtgRecordData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let validation = !this.state.isMarkerRecord
      ? twgConstraints.ttgProgrammes.modifyTtgProgramme
      : twgConstraints.dummys.modifyDummy;

    let results = twgValidationFunctions.validateData(details, validation);
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    if (this.state.hasFileChanges) {
      await commitFileChanges(
        this.props.commitChanges,
        this.props.localData,
        this.props.remoteData,
        this.props.selectedTtgRecordData.markerRecord === strings.form.text.yes
          ? strings.folder.dummyFiles
          : strings.folder.ttgProgrammeFiles,
        this.props.selectedTtgRecordData.markerRecord === strings.form.text.yes
          ? strings.entity.dummy
          : strings.entity.ttgProgramme,
        this.props.selectedTtgRecordData.markerRecord === strings.form.text.yes
          ? this.props.selectedTtgRecordData.dummyId
          : this.props.selectedTtgRecordData.ttgProgrammeId
      );
    }

    const data = textFormattingHelpers.decodeData(
      this.props.selectedTtgRecordData,
      {},
      textFields
    );

    await this.props.createTtgRecord(data, this.props.headers);
    if (!this.state.isMarkerRecord) {
      this.props.history.push("/ttgProgrammes");
    } else {
      this.props.history.push("/dummyRecords");
    }
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearTtgRecordRequest(this.props.selectedTtgRecordData);
    if (this.props.location.state) {
      if (this.props.location.state.from === "ttgProgrammes") {
        this.props.history.push("/ttgProgrammes");
      } else if (this.props.location.state.from === "dummyRecords") {
        this.props.history.push("/dummyRecords");
      }
    } else {
      this.props.history.push("/");
    }
  };

  handleChange = async (event, data) => {
    if (data.name === strings.form.fields.accommodationNeed) {
      if (data.value === strings.form.text.yes) {
        this.props.updateSelectedTtgRecord(
          strings.form.fields.accommodationNeedDate,
          this.props.selectedTtgRecordData.dateBcst2
        );
      }
    }
    if (data.name === strings.form.fields.financeBenefitAndDebtNeed) {
      if (data.value === strings.form.text.yes) {
        this.props.updateSelectedTtgRecord(
          strings.form.fields.financeBenefitAndDebtNeedDate,
          this.props.selectedTtgRecordData.dateBcst2
        );
      }
    }
    if (data.name === strings.form.fields.educationTrainingEmploymentNeed) {
      if (data.value === strings.form.text.yes) {
        this.props.updateSelectedTtgRecord(
          strings.form.fields.educationTrainingEmploymentNeedDate,
          this.props.selectedTtgRecordData.dateBcst2
        );
      }
    }
    if (data.name === strings.form.fields.domesticAbuseNeed) {
      if (data.value === strings.form.text.yes) {
        this.props.updateSelectedTtgRecord(
          strings.form.fields.domesticAbuseNeedDate,
          this.props.selectedTtgRecordData.dateBcst2
        );
      }
    }
    if (data.name === strings.form.fields.sexWorkNeed) {
      if (data.value === strings.form.text.yes) {
        this.props.updateSelectedTtgRecord(
          strings.form.fields.sexWorkNeedDate,
          this.props.selectedTtgRecordData.dateBcst2
        );
      }
    }
    if (data.name === strings.form.fields.otherNeed) {
      if (data.value === strings.form.text.yes) {
        this.props.updateSelectedTtgRecord(
          strings.form.fields.otherNeedDate,
          this.props.selectedTtgRecordData.dateBcst2
        );
      }
    }
    if (
      data.name === strings.form.fields.markerRecord &&
      data.value === strings.form.text.no
    ) {
      this.setDefaultValues();
      this.setState({ isMarkerRecord: false });
      this.props.updateSelectedTtgRecord(
        "ttgProgrammeId",
        this.props.selectedTtgRecordData.dummyId
      );
      this.props.deleteSelectedTtgRecordProperty("dummyId");
      this.props.updateSelectedTtgRecord("firstName", "");
      this.props.updateSelectedTtgRecord("surname", "");
    } else if (
      data.name === strings.form.fields.markerRecord &&
      data.value === strings.form.text.yes
    ) {
      this.setState({ isMarkerRecord: true });
      this.props.updateSelectedTtgRecord(
        "dummyId",
        this.props.selectedTtgRecordData.ttgProgrammeId
      );
      this.props.deleteSelectedTtgRecordProperty("ttgProgrammeId");
      this.props.updateSelectedTtgRecord("firstName", "DNS");
      this.props.updateSelectedTtgRecord("surname", "DNS");
    }
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedTtgRecord(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedTtgRecord(data.name, data.value);
    }
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableTtgRecordModalVisibility = () => {
    this.setState({
      enableDisableTtgRecordModalOpen: !this.state
        .enableDisableTtgRecordModalOpen
    });
  };

  render = () => {
    const loading = this.props.loadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <ModifyTtgRecord
            mode="create"
            pageTitle={strings.header.createTtgRecord}
            selectedTtgRecordData={this.props.selectedTtgRecordData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableRecordModalVisibility={
              this.toggleEnableDisableTtgRecordModalVisibility
            }
            enableDisableRecordModalOpen={
              this.state.enableDisableTtgRecordModalOpen
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            shouldSaveFiles={this.state.shouldSaveFiles}
            filesHaveChanged={this.filesHaveChanged}
            hasFileChanges={this.state.hasFileChanges}
            headers={this.props.headers}
            hasPageLoaded={this.state.hasPageLoaded}
            userList={this.state.userList}
            initialTtgRecordData={{}}
          />
        </div>
      </div>
    );
  };
}

TtgRecordCreateContainer.propTypes = {
  createTtgRecord: PropTypes.func.isRequired,
  clearTtgRecordRequest: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  location: PropTypes.object,
  selectedTtgRecordData: PropTypes.object,
  updateSelectedTtgRecord: PropTypes.func,
  userListData: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  const { userListData } = state.users;
  const { loadingPage, selectedTtgRecordData } = state.ttgRecord;
  const { headers, roleId, userId } = state.auth;
  const { localData, remoteData } = state.file;
  return {
    loadingPage,
    selectedTtgRecordData,
    headers,
    roleId,
    userListData,
    userId,
    localData,
    remoteData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createTtgRecord: async (data, headers) => {
      await dispatch(ttgRecordActions.createTtgRecord(data, headers));
    },
    updateSelectedTtgRecord: (key, value) => {
      dispatch(ttgRecordActions.updateSelectedTtgRecord(key, value));
    },
    clearSelectedTtgRecord: () => {
      dispatch(ttgRecordActions.clearSelectedTtgRecord());
    },
    clearTtgRecordRequest: details => {
      dispatch(ttgRecordActions.clearTtgRecordRequest(details));
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    deleteSelectedTtgRecordProperty: key => {
      dispatch(ttgRecordActions.deleteSelectedTtgRecordProperty(key));
    },
    commitChanges: (
      localData,
      remoteData,
      filePath,
      headers,
      entity,
      entityId,
      folder
    ) => {
      return dispatch(
        fileActions.commitChanges(
          localData,
          remoteData,
          filePath,
          headers,
          entity,
          entityId,
          folder
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TtgRecordCreateContainer);
