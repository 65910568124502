import { enums } from "../resources/Enums";

const adminRoles = [enums.role.PULSION, enums.role.ICT];

// All users should be able to view My Work page
const globalPermissions = [
  ...adminRoles,
  enums.role.MENTOR,
  enums.role.TEAMLEAD,
  enums.role.MANAGER,
  enums.role.MANAGERPARTNER,
  enums.role.SSP,
  enums.role.HEAD,
  enums.role.ASSURANCE,
  enums.role.SUPPORT,
  enums.role.PRISONBASEDMENTOR
];

export const permissions = {
  users: {
    create: [...adminRoles],
    read: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    update: [...adminRoles],
    activate: [...adminRoles, enums.role.MANAGER, enums.role.HEAD]
  },
  global: [...globalPermissions],
  customers: {
    create: [],
    read: [...globalPermissions],
    update: [...adminRoles],
    delete: [...adminRoles],
    amend: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.PRISONBASEDMENTOR
    ]
  },
  referrals: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER,
      enums.role.PRISONBASEDMENTOR
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    shareRecord: [
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    amend: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.PRISONBASEDMENTOR
    ]
  },
  programmes: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [...adminRoles, enums.role.TEAMLEAD, enums.role.MANAGER],
    shareRecord: [
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    amend: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.PRISONBASEDMENTOR
    ]
  },
  ttgProgrammes: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [...adminRoles, enums.role.TEAMLEAD, enums.role.MANAGER],
    shareRecord: [
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ]
  },
  engagements: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER,
      enums.role.PRISONBASEDMENTOR
    ]
  },
  assessmentSG: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER,
      enums.role.PRISONBASEDMENTOR
    ]
  },
  activities: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER,
      enums.role.PRISONBASEDMENTOR
    ]
  },
  employments: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER,
      enums.role.PRISONBASEDMENTOR
    ]
  },
  costs: {
    create: [
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.PRISONBASEDMENTOR,
      ...adminRoles
    ],
    read: [...globalPermissions],
    update: [
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.PRISONBASEDMENTOR,
      ...adminRoles
    ],
    delete: [enums.role.HEAD, enums.role.MANAGER, ...adminRoles]
  },
  caseloads: {
    read: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [enums.role.HEAD, enums.role.MANAGER, ...adminRoles]
  },
  news: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD]
  },
  complaints: {
    create: [
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.HEAD,
      ...adminRoles
    ],
    read: [
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.HEAD,
      ...adminRoles
    ],
    update: [
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.HEAD,
      ...adminRoles
    ],
    delete: [enums.role.MANAGER, enums.role.HEAD, ...adminRoles]
  },
  feedback: {
    create: [
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.SSP,
      enums.role.PRISONBASEDMENTOR,
      ...adminRoles
    ],
    read: [
      enums.role.SUPPORT,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.SSP,

      enums.role.HEAD,
      enums.role.ASSURANCE,
      ...adminRoles
    ],
    update: [
      enums.role.SUPPORT,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      ...adminRoles
    ],
    delete: [...adminRoles, enums.role.HEAD, enums.role.MANAGER],
    listAccess: [...globalPermissions]
  },
  assurances: {
    create: [
      enums.role.TEAMLEAD,
      enums.role.ASSURANCE,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      ...adminRoles
    ],
    read: [
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.ASSURANCE,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.HEAD,
      enums.role.PRISONBASEDMENTOR,
      ...adminRoles
    ],
    update: [
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.ASSURANCE,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR,
      ...adminRoles
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD]
  },
  organisations: {
    create: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.HEAD,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.HEAD,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD]
  },
  baselines: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER,
      enums.role.PRISONBASEDMENTOR
    ]
  },
  reports: {
    read: [
      ...adminRoles,
      enums.role.HEAD,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    create: [
      ...adminRoles,
      enums.role.HEAD,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    update: [
      ...adminRoles,
      enums.role.HEAD,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ]
  },
  tasks: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.HEAD,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.HEAD,
      enums.role.ASSURANCE,
      enums.role.SSP,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD]
  },
  settings: {
    read: [
      ...adminRoles,
      enums.role.HEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.ASSURANCE
    ],
    update: [...adminRoles, enums.role.HEAD, enums.role.MANAGER],
    create: [],
    delete: []
  },
  audit: [
    enums.role.PULSION,
    enums.role.HEAD,
    enums.role.MANAGER,
    enums.role.ASSURANCE
  ],
  dummyRecords: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.HEAD,
      enums.role.PRISONBASEDMENTOR
    ],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER,
      enums.role.PRISONBASEDMENTOR
    ]
  },
  areaOfNeed: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER,
      enums.role.PRISONBASEDMENTOR
    ]
  },
  ttgEngagements: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.ICT,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.SUPPORT,
      enums.role.MANAGERPARTNER,
      enums.role.HEAD,
      enums.role.ICT,
      enums.role.PRISONBASEDMENTOR
    ],
    update: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.SUPPORT,
      enums.role.MANAGERPARTNER,
      enums.role.ICT,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD]
  },
  supportReferrals: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.PRISONBASEDMENTOR
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SSP,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.SUPPORT,
      enums.role.PRISONBASEDMENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD]
  },
  history: {
    read: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MENTOR,
      enums.role.PRISONBASEDMENTOR
    ]
  }
};
