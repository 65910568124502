export const differenceInWorkingDays = (date1, date2) => {
  let weeks,
    dateDiff,
    adjust = 0;
  if (date2 < date1) return -1;
  let weekday1 = date1.getDay();
  let weekday2 = date2.getDay();
  weekday1 = weekday1 === 0 ? 7 : weekday1; // change Sunday from 0 to 7
  weekday2 = weekday2 === 0 ? 7 : weekday2;
  if (weekday1 > 5 && weekday2 > 5) adjust = 1; // adjustment if both days on weekend
  weekday1 = weekday1 > 5 ? 5 : weekday1; // only count weekdays
  weekday2 = weekday2 > 5 ? 5 : weekday2;

  // calculate difference in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
  weeks = Math.floor((date2.getTime() - date1.getTime()) / 604800000);

  if (weekday1 <= weekday2) {
    dateDiff = weeks * 5 + (weekday2 - weekday1);
  } else {
    dateDiff = (weeks + 1) * 5 - (weekday1 - weekday2);
  }

  dateDiff -= adjust; // take into account both days on weekend

  return dateDiff; // add 1 because dates are inclusive
};
