import React from "react";
import {
  Button,
  List,
  Segment,
  Popup,
  Icon,
  Input,
  Loader,
  Label
} from "semantic-ui-react";
import { connect } from "react-redux";
import { fileActions } from "../store/actions/file.actions";
import { strings } from "../resources/Strings";
import "./css/FileManager.scss";
import PropTypes from "prop-types";
import fetch from "isomorphic-fetch";

// Props passed in:
// filePath: the file path in the s3 bucket to work with
// allowEdit: whether or not to display the upload functionality

class FileManager extends React.Component {
  componentWillUnmount = () => {
    this.props.purgeState();
  };

  componentDidMount = async () => {
    if (this.props.folder) {
      this.props.getFiles(
        this.props.filePath +
          this.props.entityId +
          "/" +
          this.props.folder +
          "/",
        this.props.headers,
        this.props.folder
      );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.entityId !== this.props.entityId && this.props.entityId) {
      this.props.getFiles(
        this.props.filePath +
          this.props.entityId +
          "/" +
          this.props.folder +
          "/",
        this.props.headers,
        this.props.folder
      );
    }
  };

  referFile = () => {
    const fileProps = {
      file: this.uploadInput.files[0]
    };
    this.handleUpload(fileProps);
  };

  handleUpload = async fileProps => {
    if (fileProps.file) {
      // Read file to a base64 string (then trim prefix)
      const reader = new FileReader();
      reader.readAsDataURL(fileProps.file);
      reader.onload = () => {
        const encodedFile = reader.result;
        fetch(encodedFile)
          .then(result => result.blob())
          .then(blob => {
            if (blob) {
              const newFile = {
                fileName: fileProps.file.name,
                url: "",
                description: "",
                fileContent: blob,
                folder: this.props.folder,
                new: true
              };
              this.props.addLocalFile(newFile);
            }
          })
          .catch(err => {
            console.log(err);
          });
      };
    }
  };

  onDescriptionChange = (index, newDesc) => {
    const newValue = this.props.localData[this.props.folder].slice()[index];
    newValue.description = newDesc;
    this.props.updateLocalFiles(index, newValue, this.props.folder);
  };

  render = () => {
    const classNames =
      "field fileUpload" + (this.props.disabled ? " fileDisabled" : "");
    // Only show if there are files or the user can upload files

    if (
      (this.props.localData.length !== 0 || this.props.allowEdit) &&
      this.props.folder
    ) {
      return (
        <div className={classNames}>
          <label className="fileUploadLabel">{this.props.filesLabel}</label>
          <Segment className="fileSegment" style={{ marginTop: 0.3 + "em" }}>
            {!this.props.loadingFileData ? (
              <List relaxed divided>
                {this.props.localData[this.props.folder] &&
                  this.props.localData[this.props.folder].map((item, key) => (
                    <List.Item className="uploadSubHeader" key={"item" + key}>
                      <List.Content key={"content" + key}>
                        <div className="fileActions">
                          <Popup
                            trigger={
                              <Icon
                                style={{
                                  display: this.props.allowEdit ? "" : "none"
                                }}
                                circular
                                name="delete"
                                color="red"
                                link
                                size="small"
                                onClick={e => {
                                  if (!this.props.disabled) {
                                    this.props.deleteLocalFile(
                                      key,
                                      this.props.folder
                                    );
                                    this.props.filesHaveChanged(
                                      this.props.stage
                                    );
                                  } else {
                                    return false;
                                  }
                                }}
                              />
                            }
                            content={
                              <div>{strings.delete + item.fileName}</div>
                            }
                          />
                          {/* Below trims filenames to 28 chars and appends ...  */}
                          {item.url !== "" ? (
                            <span>
                              <a className="fileText" href={item.url}>
                                <List.Icon name="file" />
                                {item.fileName.substring(0, 28)}
                                {item.fileName.length > 28 && "..."}
                              </a>
                            </span>
                          ) : (
                            <span>
                              <Icon name="file outline" />
                              {item.fileName.substring(0, 28)}
                              {item.fileName.length > 28 && "..."}
                            </span>
                          )}
                        </div>
                        {this.props.allowEdit ? (
                          <Input
                            className="fileDescInput"
                            placeholder={strings.placeholder}
                            label={{ icon: "bars" }}
                            labelPosition="left corner"
                            size="mini"
                            value={item.description || ""}
                            onChange={(e, data) => {
                              this.onDescriptionChange(key, data.value);
                              this.props.filesHaveChanged(this.props.stage);
                            }}
                          />
                        ) : (
                          <Label className="fileDescReadOnly" basic>
                            {item.description}
                          </Label>
                        )}
                      </List.Content>
                    </List.Item>
                  ))}
                <List.Item
                  className="uploadSubHeader"
                  style={{
                    display:
                      this.props.localData[this.props.folder] &&
                      this.props.localData[this.props.folder].length === 0
                        ? ""
                        : "none"
                  }}
                >
                  {strings.noFilesFound}
                </List.Item>
              </List>
            ) : this.props.error ? (
              <div className="fileLoader">{this.props.error.message}</div>
            ) : (
              <div className="fileLoader">
                <Loader active>{strings.loadingFiles}</Loader>
              </div>
            )}
            {!this.props.loadingFileData && this.props.allowEdit && (
              <div>
                <Button
                  as="label"
                  htmlFor={this.props.id || "fileUploadButton"}
                  color="green"
                  basic
                  disabled={this.props.disabled}
                >
                  <Icon name="cloud upload" />
                  {strings.uploadFile}
                </Button>
                <input
                  id={this.props.id || "fileUploadButton"}
                  hidden
                  name={this.props.name || "files"}
                  ref={ref => {
                    this.uploadInput = ref;
                  }}
                  type="file"
                  onChange={() => {
                    this.referFile();
                    this.props.filesHaveChanged(this.props.stage);
                  }}
                />
              </div>
            )}
          </Segment>
        </div>
      );
    } else {
      return null;
    }
  };
}

FileManager.propTypes = {
  headers: PropTypes.object.isRequired,
  filePath: PropTypes.string.isRequired,
  allowEdit: PropTypes.bool.isRequired,
  folder: PropTypes.string.isRequired,
  localData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  remoteData: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

const mapStateToProps = state => {
  const { file } = state;
  const { localData, remoteData, loadingFileData, error } = file;
  return {
    localData,
    remoteData,
    loadingFileData,
    error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    purgeState: () => {
      dispatch(fileActions.purgeState());
    },
    getFiles: (path, headers, folder) => {
      dispatch(fileActions.getFiles(path, headers, folder));
    },
    deleteLocalFile: (index, folder) => {
      dispatch(fileActions.deleteLocalFile(index, folder));
    },
    addLocalFile: newFile => {
      dispatch(fileActions.addLocalFile(newFile));
    },
    updateLocalFiles: (index, newVal, folder) => {
      dispatch(fileActions.updateLocalFiles(index, newVal, folder));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(FileManager);
