import React from "react";
import PropTypes from "prop-types";
import { Form, Label } from "semantic-ui-react";
import { strings } from "../../resources";
import { convertFromIso } from "../../helpers/index";

import {
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormInput
} from "../validation";
import "../css/ProgrammeComponents.scss";
import {
  yesNoOptions,
  employmentStatusOptions,
  lengthOfEmploymentOptions
} from "../../resources";

const EmploymentAndBenefitsFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">
      {strings.form.header.employmentAndBenefitsHeader}
    </div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.employmentStatus}
          viewData={props.selectedProgrammeData.employmentStatus}
          dropdownLabel={strings.form.label.employmentStatus}
          dropdownName="employmentStatus"
          dropdownOptions={employmentStatusOptions}
          dropdownPlaceholder={strings.form.placeholder.employmentStatus}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.employmentStatus}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.lengthOfEmployment}
          viewData={props.selectedProgrammeData.lengthOfEmployment}
          dropdownLabel={strings.form.label.lengthOfEmployment}
          dropdownName="lengthOfEmployment"
          dropdownOptions={lengthOfEmploymentOptions}
          dropdownPlaceholder={strings.form.placeholder.lengthOfEmployment}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.lengthOfEmployment}
          required={false}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.participatingWorkProgramme}
          viewData={props.selectedProgrammeData.participatingWorkProgramme}
          dropdownLabel={strings.form.label.participatingWorkProgramme}
          dropdownName="participatingWorkProgramme"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={
            strings.form.placeholder.participatingWorkProgramme
          }
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.participatingWorkProgramme}
          required={false}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <Label className="benefitsHeader">
          {strings.form.label.benefitsDetails}
        </Label>
      </Form.Group>
      <Form.Group>
        <Form.Checkbox
          label={strings.form.label.benefitsNone}
          name="benefitsNone"
          onChange={props.handleChange}
          checked={props.selectedProgrammeData.benefitsJobseekers || false}
          disabled={props.mode === "view" || props.mode === "amend"}
          width={8}
        />
        <Form.Checkbox
          label={strings.form.label.benefitsChild}
          name="benefitsChild"
          onChange={props.handleChange}
          checked={props.selectedProgrammeData.benefitsChild || false}
          disabled={props.mode === "view" || props.mode === "amend"}
          width={8}
        />
        <Form.Checkbox
          label={strings.form.label.benefitsIncapacity}
          name="benefitsIncapacity"
          onChange={props.handleChange}
          checked={props.selectedProgrammeData.benefitsIncapacity || false}
          disabled={props.mode === "view" || props.mode === "amend"}
          width={8}
        />
        <Form.Checkbox
          label={strings.form.label.benefitsPersonalIndependence}
          name="benefitsPersonalIndependence"
          onChange={props.handleChange}
          checked={
            props.selectedProgrammeData.benefitsPersonalIndependence || false
          }
          disabled={props.mode === "view" || props.mode === "amend"}
          width={8}
        />
      </Form.Group>
      <Form.Group>
        <Form.Checkbox
          label={strings.form.label.benefitsWorkingTaxCredit}
          name="benefitsWorkingTaxCredit"
          onChange={props.handleChange}
          checked={
            props.selectedProgrammeData.benefitsWorkingTaxCredit || false
          }
          disabled={props.mode === "view" || props.mode === "amend"}
          width={8}
        />
        <Form.Checkbox
          label={strings.form.label.benefitsUniversalCredit}
          name="benefitsUniversalCredit"
          onChange={props.handleChange}
          checked={props.selectedProgrammeData.benefitsUniversalCredit || false}
          disabled={props.mode === "view" || props.mode === "amend"}
          width={8}
        />
        <Form.Checkbox
          label={strings.form.label.benefitsIncomeSupport}
          name="benefitsIncomeSupport"
          onChange={props.handleChange}
          checked={props.selectedProgrammeData.benefitsIncomeSupport || false}
          disabled={props.mode === "view" || props.mode === "amend"}
          width={8}
        />
        <Form.Checkbox
          label={strings.form.label.benefitsEmploymentSupport}
          name="benefitsEmploymentSupport"
          onChange={props.handleChange}
          checked={
            props.selectedProgrammeData.benefitsEmploymentSupport || false
          }
          disabled={props.mode === "view" || props.mode === "amend"}
          width={8}
        />
      </Form.Group>
      <Form.Group>
        <Form.Checkbox
          label={strings.form.label.benefitsChildcareWTC}
          name="benefitsChildcareWTC"
          onChange={props.handleChange}
          checked={props.selectedProgrammeData.benefitsChildcareWTC || false}
          disabled={props.mode === "view" || props.mode === "amend"}
          width={4}
        />
        <Form.Checkbox
          label={strings.form.label.benefitsOther}
          name="benefitsOther"
          onChange={props.handleChange}
          checked={props.selectedProgrammeData.benefitsOther || false}
          disabled={props.mode === "view" || props.mode === "amend"}
          width={4}
        />
      </Form.Group>
      {props.selectedProgrammeData.benefitsOther && (
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.benefitDetailsOther}
          inputLabel={strings.form.label.benefitDetailsOther}
          inputName="benefitDetailsOther"
          inputPlaceholder={strings.form.placeholder.benefitDetailsOther}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.benefitDetailsOther}
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
      )}
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.jobCentrePlusAppointment}
          viewData={props.selectedProgrammeData.jobCentrePlusAppointment}
          dropdownLabel={strings.form.label.jobCentrePlusAppointment}
          dropdownName="jobCentrePlusAppointment"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={
            strings.form.placeholder.jobCentrePlusAppointment
          }
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.jobCentrePlusAppointment}
          required={false}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.isCareLeaver}
          viewData={props.selectedProgrammeData.isCareLeaver}
          dropdownLabel={strings.form.label.isCareLeaver}
          dropdownName="isCareLeaver"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.isCareLeaver}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.isCareLeaver}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />

        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hasItaAccountAvailable}
          viewData={props.selectedProgrammeData.hasItaAccountAvailable}
          dropdownLabel={strings.form.label.hasItaAccountAvailable}
          dropdownName="hasItaAccountAvailable"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.hasItaAccountAvailable}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.hasItaAccountAvailable}
          mode={props.mode}
          dropdownDisabled={props.mode === "amend"}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedDateInput
          inputId="itaDateApplied"
          formInvalid={props.formInvalid}
          inputLabel={strings.form.label.itaDateApplied}
          inputName="itaDateApplied"
          inputPlaceholder={strings.form.placeholder.itaDateApplied}
          inputDateFormat="DD/MM/YYYY"
          inputData={
            props.selectedProgrammeData.itaDateApplied
              ? convertFromIso(props.selectedProgrammeData.itaDateApplied)
              : ""
          }
          iconPosition="left"
          handleChange={props.handleChange}
          validationResult={props.validationResults.itaDateApplied}
          formFieldWidth={8}
          required={false}
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
      </Form.Group>
    </div>
  </div>
);

EmploymentAndBenefitsFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default EmploymentAndBenefitsFields;
