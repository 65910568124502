import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    areas: { NRS: "NRS", both: "both", NRNE: "NRNE" },
    header: {
      login: "Login",
      supportReferralList: "Support Partner Referrals",
      createSupportReferral: "New Support Partner Referral",
      editSupportReferral: "Edit Support Partner Referral",
      viewSupportReferral: "Support Partner Referral Details",
      loading: "Loading Information",
      embeddedSupportReferralListStart: "Support Partner Referrals for '",
      embeddedSupportReferralListMiddle: "' of type '",
      embeddedSupportReferralListEnd: "'"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        supportPartnerReferrals: "Support Partner Referrals",
        supportReferralGeneral: "General",
        supportReferralAdmin: "Administration",
        supportReferralDetails: "Support Referral Details",
        supportReferralEngagementList: "Engagement List",
        supportReferralEngagementProgress: "Engagement/Progress",
        supportReferralNotes: "Notes"
      },
      label: {
        firstName: "First Name",
        surname: "Surname",
        method: "Method",
        crmId: "CRM ID",
        owner: "Owner",
        category: "Category",
        stageOfReferral: "Stage of Referral",
        provider: "Provider",
        dateReferred: "Date Referred",
        referralAccepted: "Referral accepted?",
        outcomeOfSpecialistReferral: "Outcome of Specialist Referral",
        reason: "Reason for referral being declined",
        reasonDetails: "Reason Details",
        startDate: "Start Date",
        endDate: "End Date",
        reasonForReferral: "Reason for Referral",
        dateOfEngagement: "Date of Engagement",
        successfulEngagement: "Successful Engagement",
        whyNotSuccessful: "If not successful, why not?",
        engagementDetails: "Details of engagement",
        hasCustomerProgressed: "Has Customer Progressed?",
        notes: "Notes",
        viewOrUpdateSupportPartnerReferrals:
          "View or Update Support Partner Referrals"
      },
      placeholder: {
        firstName: "",
        surname: "",
        method: "- select -",
        crmId: "",
        owner: "",
        category: "- select -",
        stageOfReferral: "- select -",
        provider: "- select -",
        dateReferred: "",
        referralAccepted: "- select -",
        outcomeOfSpecialistReferral: "- select -",
        reason: "- select -",
        reasonDetails: "",
        startDate: "",
        endDate: "",
        reasonForReferral: "",
        dateOfEngagement: "",
        successfulEngagement: "- select -",
        whyNotSuccessful: "",
        engagementDetails: "",
        hasCustomerProgressed: "",
        notes: ""
      },
      feedback: {
        processing: "Processing request...",
        supportReferralRequestSuccess:
          "Support Partner Referral created successfully!",
        supportReferralRequestRefreshPrompt:
          "Use the refresh function to see an updated support partner referrals list.",
        supportReferralRequestFailure:
          "Support Partner Referral creation failed.",
        supportReferralRequestUnknown:
          "Something went wrong with the support partner referral request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        yes: "Yes",
        no: "No"
      },
      fileUpload: "Upload files"
    },
    modal: {
      createSupportReferralMessage: "Save support partner referral?",
      modifySupportReferralMessage:
        "Are you sure you want to save these changes?",
      deleteSupportReferralMessage:
        "Are you sure you want to delete this support partner referral? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:",
      deleteEngagementMessage:
        "Are you sure you want to delete this engagement? (This action cannot be undone)"
    },
    button: {
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addSupportReferralLabel: "New Support Partner Referral",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      addEngagement: "Add Engagement",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form",
      viewLabel: "View"
    },
    tooltip: {},
    table: {
      header: {
        firstName: "First Name",
        surname: "Last Name",
        pNumber: "Prison Number",
        scroNumber: "S Number",
        category: "Category",
        specialistSupportService: "Specialist Support Service",
        stageOfReferral: "Stage of Referral",
        referralAccepted: "Referral accepted?",
        outcomeOfReferral: "Outcome of Specialist Referral",
        crmId: "CRM ID"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    orgTypes: {
      supportPartner: "Support Partner"
    },

    entity: { supportReferral: "supportReferral" },
    folder: {
      supportReferralFiles: "supportReferralFiles"
    }
  }
});
