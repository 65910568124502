import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      entity: "engagements",
      deletion: "deletion",
      fullName: "Engagement"
    },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    header: {
      login: "Login",
      engagementList: "Engagements",
      createEngagement: "New Engagement",
      editEngagement: "Edit Engagement",
      viewEngagement: "Engagement Details",
      loading: "Loading Information",
      embeddedEngagementListStart: "Engagements for '",
      embeddedEngagementListMiddle: "' of type '",
      embeddedEngagementListEnd: "'"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        engagementInfo: "Engagement Information",
        activityDetails: "Activity Details"
      },
      label: {
        dateOfEngagement: "Date of Engagement",
        method: "Method",
        location: "Location",
        isSuccessfulValue: "Successful",
        hasActivities: "Activity (or Activities) Carried Out?",
        outcomeOfReferralMeeting: "Outcome of Referral Meeting",
        outcomeOfSignupMeeting: "Outcome of Sign-up Meeting",
        dateOfActivity: "Date of Activity",
        type: "Activity Type",
        isNotSuccessfulReason: "If not successful, why not?",
        engagementNotes: "Engagement notes",
        crmId: "CRM ID",
        owner: "Owner",
        consent: "Agree to data protection"
      },
      placeholder: {
        stateReason: "State your reason...",
        dateOfEngagement: "- select -",
        method: "- select -",
        location: "- select -",
        type: "- select -",
        isSuccessfulValue: "- select -",
        hasActivities: "- select -",
        outcomeOfReferralMeeting: "- select -",
        outcomeOfSignupMeeting: "- select -",
        isNotSuccessfulReason: "",
        engagementNotes: "",
        crmId: "",
        owner: "- select -",
        consent: "- select -"
      },
      feedback: {
        processing: "Processing request...",
        engagementRequestSuccess: "Engagement created successfully!",
        engagementRequestRefreshPrompt:
          "Use the refresh function to see an updated engagements list.",
        engagementRequestFailure: "Engagement creation failed.",
        engagementRequestUnknown:
          "Something went wrong with the engagement request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        yes: "Yes",
        no: "No"
      },
      fileUpload: "Upload files"
    },
    modal: {
      createEngagementMessage: "Save engagement?",
      modifyEngagementMessage: "Are you sure you want to save these changes?",
      deleteEngagementMessage:
        "Are you sure you want to delete this engagement? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this engagement's status to active?",
      setInactiveMessage: "Set this engagement's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addActivity: "Add Activity",
      addEngagementLabel: "New Engagement",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    tooltip: {},
    table: {
      header: {
        firstName: "First Name",
        surname: "Last Name",
        pNumber: "Prison Number",
        scroNumber: "S Number",
        date: "Date of Engagement",
        type: "Type",
        location: "Location",
        method: "Method",
        isSuccessfulValue: "Successful",
        numActivities: "No. Activities",
        crmId: "CRM ID"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    outcomes: {
      customerStart: "Customer Start"
    },
    entity: {
      engagement: "engagement"
    },
    folder: {
      engagementFiles: "engagementFiles"
    }
  }
});
