export const auditActionTypes = {
  CREATE_AUDIT_REQUEST: "CREATE_AUDIT_REQUEST",
  CREATE_AUDIT_FAILURE: "CREATE_AUDIT_FAILURE",
  CREATE_AUDIT_SUCCESS: "CREATE_AUDIT_SUCCESS",

  GET_AUDITS_REQUEST: "GET_AUDITS_REQUEST",
  GET_AUDITS_SUCCESS: "GET_AUDITS_SUCCESS",
  GET_AUDITS_FAILURE: "GET_AUDITS_FAILURE",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE",

  CLEAR_CREATE_AUDIT_REQUEST: "CLEAR_CREATE_AUDIT_REQUEST",
  CLEAR_CREATE_AUDIT_FAILURE: "CLEAR_CREATE_AUDIT_FAILURE",
  CLEAR_CREATE_AUDIT_SUCCESS: "CLEAR_CREATE_AUDIT_SUCCESS"
};
