import React from "react";
import TableComponent from "../../components/SupportReferralTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { supportReferralActions } from "../../store/actions/supportReferral.actions";
import { referralActions } from "../../../Referrals/store/actions/referral.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import { getOrganisations, returnToParent } from "../../helpers";

const requestTimeout = 20000;

class SupportReferralListContainer extends React.Component {
  state = {
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    searchCriteria: {
      size: 20,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false,
      referralId: undefined,
      programmeId: undefined,
      type: undefined
    },
    orgsSearchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    globalProgrammeId: localStorage.getItem("programmeId")
      ? localStorage.getItem("programmeId")
      : this.props.programmeId,
    searchBarQuery: ""
  };

  componentDidMount = async () => {
    if (
      !localStorage.getItem("programmeId") &&
      !localStorage.getItem("parentReferralId") &&
      !this.props.referralId &&
      !this.props.programmeId
    ) {
      this.props.history.goBack();
    }
    this.timerID = setTimeout(() => {
      if (
        this.props.supportReferralRequestStatus === undefined &&
        this.props.supportReferralRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
    if (this.state.globalProgrammeId === null) {
      this.setState({ globalProgrammeId: this.props.programmeId });
    }

    const sCrit = this.state.orgsSearchCriteria;
    this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearSupportReferralRequest();
  }

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    await this.props.clearSupportReferralRequest();
    await this.handleFetchData(this.state);
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteria };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteria: searchCriteria });
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleFetchData = async state => {
    const sCrit = this.state.searchCriteria;
    let sortObject = {};
    if (state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }
    if (this.state.globalProgrammeId) {
      sCrit.fields = ["category.normalizer", "stageOfReferral.normalizer"];
      sCrit.query = this.state.searchBarQuery;
    }
    sCrit.referralId = this.props.referralId
      ? this.props.referralId
      : localStorage.getItem("parentReferralId");
    sCrit.programmeId = this.state.globalProgrammeId;

    await this.props.updateSearchCriteria(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      state.page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sortObject,
      sCrit.activeOnly,
      sCrit.referralId,
      sCrit.programmeId
    );
    if (this.props.referralId) {
      localStorage.setItem("referralId", this.props.referralId);
      localStorage.setItem("referralName", this.props.referralName);
    }
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };
  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.referralLoadingPage ||
      this.props.programmeLoadingPage ||
      this.props.orgsLoadingPage;

    let parentPage;
    if (localStorage.getItem("programmeName")) {
      parentPage = localStorage.getItem("programmeName");
    } else if (localStorage.getItem("referralName")) {
      parentPage = localStorage.getItem("referralName");
    }

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <RequestFeedback
            requestStatus={this.props.supportReferralRequestStatus}
            requestMade={this.props.supportReferralRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.supportReferralRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={
              strings.form.feedback.supportReferralRequestUnknown
            }
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            supportReferralListData={this.props.supportReferralListData}
            handleModalConfirm={this.handleModalConfirm}
            handleRefresh={this.handleRefresh}
            pageSize={this.state.searchCriteria.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalResults={this.props.totalResults}
            updatePageIndex={this.updatePageIndex}
            roleId={this.props.roleId}
            supportReferralType={this.props.supportReferralType}
            referralCrmId={this.props.referralCrmId}
            programmeCrmId={this.props.programmeCrmId}
            area={this.props.area}
            searchBarQuery={this.state.searchBarQuery}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            selectedReferralData={this.props.selectedReferralData}
            selectedProgrammeData={this.props.selectedProgrammeData}
            orgOfLoggedInUser={this.props.organisationOfLoggedInUser}
            isCreateDisabled={this.isCreateDisabled}
            hideSearchBar={this.props.hideSearchBar}
            initialReferralPropsData={this.props.initialReferralData}
            initialReferralStateData={this.state.initialReferralData}
            initialProgrammePropsData={this.props.initialProgrammeData}
            initialProgrammeStateData={this.state.initialProgrammeData}
            referralId={this.props.referralId}
            programmeId={this.props.programmeId}
            organisationList={getOrganisations(this.props.organisationListData)}
            parentPage={parentPage}
            returnToParent={() => {
              returnToParent(this.props.history);
            }}
          />
        </div>
      </div>
    );
  };
}

SupportReferralListContainer.propTypes = {
  supportReferralListData: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getSupportReferrals: PropTypes.func.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const {
    supportReferrals,
    auth,
    referrals,
    programmes,
    organisations
  } = state;
  const { roleId, organisationOfLoggedInUser } = auth;
  const { selectedReferralData, loadingPage: referralLoadingPage } = referrals;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  const {
    supportReferralListData,
    loadingPage,
    supportReferralRequestStatus,
    supportReferralRequestMade,
    result,
    error,
    totalResults,
    errorDetails
  } = supportReferrals;
  const { loadingPage: orgsLoadingPage, organisationListData } = organisations;
  return {
    supportReferralListData,
    loadingPage,
    supportReferralRequestStatus,
    supportReferralRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails,
    selectedReferralData,
    organisationOfLoggedInUser,
    selectedProgrammeData,
    referralLoadingPage,
    programmeLoadingPage,
    orgsLoadingPage,
    organisationListData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSupportReferrals: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        supportReferralActions.getSupportReferrals(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      referralId,
      programmeId,
      type
    ) => {
      dispatch(
        supportReferralActions.updateSearchCriteria(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          referralId,
          programmeId,
          type
        )
      );
    },
    clearSupportReferralRequest: () => {
      dispatch(supportReferralActions.clearSupportReferralRequest());
    },
    getReferralById: (id, headers) => {
      dispatch(referralActions.getReferralById(id, headers));
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportReferralListContainer);
