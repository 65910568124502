import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import { dropdownHelper } from "../../helpers/index";
import { ValidatedFormInput, ValidatedFormDropdown } from "../validation";
import "../css/ProgrammeComponents.scss";

const AdministrationFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">{strings.form.header.administrationHeader}</div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.crmId}
          inputLabel={strings.form.label.crmId}
          inputName="crmId"
          inputPlaceholder={strings.form.placeholder.crmId}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.crmId}
          mode={props.mode}
          disabled={props.mode === "edit" || props.mode === "amend"}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.owner}
          viewData={
            dropdownHelper.getNameFromValues(
              props.selectedProgrammeData.owner,
              props.organisations
            ) || ""
          }
          dropdownLabel={strings.form.label.owner}
          dropdownName="owner"
          dropdownOptions={props.organisations}
          dropdownPlaceholder={strings.form.placeholder.owner}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.owner}
          dropdownDisabled={
            (props.modifyOwnerRoles.indexOf(props.roleId) === -1 &&
              props.mode === "edit") ||
            props.mode === "amend"
          }
          mode={props.mode}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.firstName}
          inputLabel={strings.form.label.firstName}
          inputName="firstName"
          inputPlaceholder={strings.form.placeholder.firstName}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.firstName}
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.surname}
          inputLabel={strings.form.label.surname}
          inputName="surname"
          inputPlaceholder={strings.form.placeholder.surname}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.surname}
          mode={props.mode}
          disabled={props.mode === "amend"}
        />
      </Form.Group>
    </div>
  </div>
);

AdministrationFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  organisations: PropTypes.array.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default AdministrationFields;
