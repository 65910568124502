import { appActionTypes } from "./app.actionTypes";

const setSidebarOpen = sidebarOpen => {
  const success = sidebarOpen => {
    return { type: appActionTypes.SET_SIDEBAR_OPEN_SUCCESS, sidebarOpen };
  };

  return dispatch => {
    dispatch(success(sidebarOpen));
  };
};

export const appActions = {
  setSidebarOpen
};
