import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      entity: "news",
      deletion: "deletion",
      fullName: "News"
    },
    fieldValues: { other: "Other" },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    header: {
      login: "Login",
      newsList: "Good News Stories",
      createNews: "New Good News Story",
      editNews: "Edit Story",
      viewNews: "News Details",
      loading: "Loading Information"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        administration: "Administration",
        newsDetails: "Story Details"
      },
      label: {
        notes: "Notes",
        date: "Date",
        outcome: "Outcome",
        crmId: "CRM ID"
      },
      placeholder: {
        notes: "",
        date: "",
        outcome: "- select -",
        crmId: ""
      },
      feedback: {
        processing: "Processing request...",
        newsRequestSuccess: "Story created successfully!",
        newsRequestRefreshPrompt:
          "Use the refresh function to see an updated news list.",
        newsRequestFailure: "Story creation failed.",
        newsRequestUnknown: "Something went wrong with the story request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        yes: "Yes",
        no: "No"
      }
    },
    modal: {
      createNewsMessage: "Save Story?",
      modifyNewsMessage: "Are you sure you want to save these changes?",
      deleteNewsMessage:
        "Are you sure you want to delete this news? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this news's status to active?",
      setInactiveMessage: "Set this news's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addNewsLabel: "New News",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    tooltip: {},
    table: {
      header: {
        date: "Date",
        outcome: "Outcome"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    }
  }
});
