import { combineReducers } from "redux";
import userReducer from "../Users/store/reducers/user.reducer";
import organisationReducer from "../Organisations/store/reducers/organisation.reducer";
import customerReducer from "../Customers/store/reducers/customer.reducer";
import referralReducer from "../Referrals/store/reducers/referral.reducer";
import engagementReducer from "../Engagements/store/reducers/engagement.reducer";
import programmeReducer from "../Programmes/store/reducers/programme.reducer";
import baselineReducer from "../Baselines/store/reducers/baseline.reducer";
import fileReducer from "../File/store/reducers/file.reducer";
import taskReducer from "../Tasks/store/reducers/task.reducer";
import employmentReducer from "../Employments/store/reducers/employment.reducer";
import costReducer from "../Costs/store/reducers/cost.reducer";
import auth from "../App/store/reducers/auth.reducer";
import app from "../App/store/reducers/app.reducer";
import activityReducer from "../Activities/store/reducers/activity.reducer";
import assessmentSGReducer from "../AssessmentSG/store/reducers/assessmentSG.reducer";
import reportReducer from "../Reports/store/reducers/report.reducer";
import amendmentsReducer from "../Amendments/store/reducers/amendment.reducer";
import addressReducer from "../App/store/reducers/address.reducer";
import feedbackReducer from "../Feedback/store/reducers/feedback.reducer";
import complaintsReducer from "../Complaints/store/reducers/complaint.reducer";
import auditReducer from "../Audit/store/reducers/audit.reducer";
import dummyRecordReducer from "../DummyRecords/store/reducers/dummyRecord.reducer";
import areaOfNeedReducer from "../AreaOfNeed/store/reducers/areaOfNeed.reducer";
import ttgEngagementReducer from "../TtgEngagements/store/reducers/ttgEngagement.reducer";
import assuranceReducer from "../Assurances/store/reducers/assurance.reducer";
import supportReferralReducer from "../SupportReferral/store/reducers/supportReferral.reducer";
import settingsReducer from "../Settings/store/reducers/setting.reducer";
import ttgProgrammeReducer from "../TtgProgrammes/store/reducers/ttgProgramme.reducer";
import ttgRecordReducer from "../TtgRecord/store/reducers/ttgRecord.reducer";
import newsReducer from "../News/store/reducers/news.reducer";
import caseloadReducer from "../Caseloads/store/reducers/caseload.reducer";
import historyReducer from "../History/store/reducers/history.reducer";
const appReducer = combineReducers({
  auth,
  app,
  users: userReducer,
  organisations: organisationReducer,
  customers: customerReducer,
  referrals: referralReducer,
  engagements: engagementReducer,
  activities: activityReducer,
  programmes: programmeReducer,
  file: fileReducer,
  tasks: taskReducer,
  assessmentSG: assessmentSGReducer,
  employments: employmentReducer,
  costs: costReducer,
  baselines: baselineReducer,
  reports: reportReducer,
  amendments: amendmentsReducer,
  address: addressReducer,
  feedback: feedbackReducer,
  areaOfNeed: areaOfNeedReducer,
  settings: settingsReducer,
  complaints: complaintsReducer,
  audit: auditReducer,
  dummyRecords: dummyRecordReducer,
  assurances: assuranceReducer,
  supportReferrals: supportReferralReducer,
  ttgProgrammes: ttgProgrammeReducer,
  ttgRecord: ttgRecordReducer,
  ttgEngagements: ttgEngagementReducer,
  news: newsReducer,
  caseloads: caseloadReducer,
  history: historyReducer
});

const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_SUCCESS") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
