export const feedbackStageOptions = [
  { key: 0, value: "Stage 1 - Pre-Release", text: "Stage 1 - Pre-Release" },
  { key: 1, value: "Stage 2 - Community", text: "Stage 2 - Community" },
  { key: 2, value: "Stage 3 - Exit", text: "Stage 3 - Exit" },
  { key: 3, value: "Additional Feedback", text: "Additional Feedback" }
];
export const yesNoOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" }
];

export const yesNoNAOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" },
  { key: 3, text: "N/A", value: "N/A" }
];

export const additionalQ2Options = [
  { key: 1, text: "Customer", value: "Customer" },
  { key: 2, text: "Mentor", value: "Mentor" }
];

export const additionalQ3Options = [
  { key: 0, text: "Mental Health", value: "Mental Health" },
  { key: 1, text: "Physical Health", value: "Physical Health" },
  { key: 2, text: "Substance Misuse", value: "Substance Misuse" },
  { key: 3, text: "Accommodation", value: "Accommodation" },
  { key: 4, text: "Relationships", value: "Relationships" },
  { key: 5, text: "Finance", value: "Finance" },
  { key: 6, text: "Employment", value: "Employment" },
  { key: 7, text: "Education & training", value: "Education & training" },
  { key: 8, text: "Attitudes & behaviours", value: "Attitudes & behaviours" },
  { key: 9, text: "Social & Leisure", value: "Social & Leisure" }
];
export const additionalQ4Options = [
  { key: 0, text: "On Programme - Prison", value: "On Programme - Prison" },
  { key: 1, text: "Gate Liberation", value: "Gate Liberation" },
  {
    key: 2,
    text: "On Programme - Community",
    value: "On Programme - Community"
  }
];
export const additionalQ6Options = [
  { key: 1, text: "Positive", value: "Positive" },
  { key: 2, text: "Negative", value: "Negative" }
];
