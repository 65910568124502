import React from "react";
import PropTypes from "prop-types";
import {
  Form,
  Popup,
  Icon,
  Accordion,
  Button,
  Checkbox
} from "semantic-ui-react";
import {
  strings,
  relationshipOptions,
  liberationLocalAreaOptions,
  prisonNrsOptions,
  prisonNrneOptions,
  yearsOptions,
  monthsOptions,
  daysOptions,
  yesNoOptions,
  customerLocalAuthorityAreaOptions
} from "../../resources";
import { convertFromIso } from "../../helpers/index";
import { ShowIfSameArea } from "../../../App/components/";
import {
  ValidatedFormInput,
  ValidatedFormDropdown,
  ValidatedDateInput
} from "../validation";
import "../css/ReferralComponents.scss";
import { AddressInput } from "../../../App/components/";

class PersonalDetailsFields extends React.Component {
  state = {
    mainActiveIndex: 0,
    libActiveIndex: 0,
    mailingActiveIndex: 0,
    additionalActiveIndex: 0
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const activeIndex = this.state[titleProps.typeofindex];
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ [titleProps.typeofindex]: newIndex });
  };

  render = () => {
    const {
      mainActiveIndex,
      libActiveIndex,
      mailingActiveIndex,
      additionalActiveIndex
    } = this.state;

    return (
      <div className="infoBlock">
        <div className="infoHeader">
          {strings.form.header.personalDetailsHeader}
        </div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.firstName}
              inputLabel={strings.form.label.firstName}
              inputName="firstName"
              inputPlaceholder={strings.form.placeholder.firstName}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.firstName}
              mode={this.props.mode}
              disabled={this.props.mode === "amend"}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.surname}
              inputLabel={strings.form.label.surname}
              inputName="surname"
              inputPlaceholder={strings.form.placeholder.surname}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.surname}
              mode={this.props.mode}
              disabled={this.props.mode === "amend"}
            />
          </Form.Group>
          <Form.Group>
            <ShowIfSameArea
              area={this.props.area}
              allowedArea={strings.areas.NRNE}
            >
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.initials}
                inputLabel={strings.form.label.initials}
                inputName="initials"
                inputPlaceholder={strings.form.placeholder.initials}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.initials}
                required={false}
                mode={this.props.mode}
                disabled={this.props.mode === "amend"}
              />
            </ShowIfSameArea>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.pNumber}
              inputLabel={strings.form.label.pNumber}
              inputName="pNumber"
              inputPlaceholder={strings.form.placeholder.pNumber}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.pNumber}
              mode={this.props.mode}
            />
            <ShowIfSameArea
              area={this.props.area}
              allowedArea={strings.areas.NRS}
            >
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.scroNumber}
                inputLabel={strings.form.label.scroNumber}
                inputName="scroNumber"
                inputPlaceholder={strings.form.placeholder.scroNumber}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.scroNumber}
                required={false}
                mode={this.props.mode}
              />
            </ShowIfSameArea>
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.nin}
              inputLabel={strings.form.label.nin}
              inputName="nin"
              inputPlaceholder={strings.form.placeholder.nin}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.nin}
              required={false}
              mode={this.props.mode}
              disabled={this.props.mode === "amend"}
              popUp={
                <Popup
                  content={strings.tooltip.ninFormatContent}
                  key="nin"
                  header={strings.tooltip.ninFormatHeader}
                  trigger={<Icon className="info tooltip" name="info circle" />}
                />
              }
            />
            <ValidatedDateInput
              inputId="dateOfBirth"
              formInvalid={this.props.formInvalid}
              inputLabel={strings.form.label.dateOfBirth}
              inputName="dateOfBirth"
              inputPlaceholder={strings.form.placeholder.dateOfBirth}
              inputDateFormat="DD/MM/YYYY"
              inputData={
                this.props.selectedReferralData.dateOfBirth
                  ? convertFromIso(this.props.selectedReferralData.dateOfBirth)
                  : ""
              }
              iconPosition="left"
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.dateOfBirth}
              formFieldWidth={8}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.email}
              inputLabel={strings.form.label.email}
              inputName="email"
              inputPlaceholder={strings.form.placeholder.email}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.email}
              required={false}
              mode={this.props.mode}
              disabled={this.props.mode === "amend"}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.phone1}
              inputLabel={strings.form.label.phone1}
              inputName="phone1"
              inputPlaceholder={strings.form.placeholder.phone1}
              formFieldWidth={8}
              onKeyPress={this.props.allowNumericalInput}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.phone1}
              required={false}
              mode={this.props.mode}
              disabled={this.props.mode === "amend"}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.phone2}
              inputLabel={strings.form.label.phone2}
              inputName="phone2"
              inputPlaceholder={strings.form.placeholder.phone2}
              formFieldWidth={8}
              onKeyPress={this.props.allowNumericalInput}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.phone2}
              required={false}
              mode={this.props.mode}
              disabled={this.props.mode === "amend"}
            />
          </Form.Group>
          <Accordion styled>
            <Accordion.Title
              active={mainActiveIndex === 0}
              index={0}
              onClick={this.handleClick}
              typeofindex="mainActiveIndex"
              className="addressbookHeader"
            >
              <Icon name="dropdown" />
              {strings.form.accordion.mainAddress}
            </Accordion.Title>
            <Accordion.Content
              active={mainActiveIndex === 0}
              className="addressbookAccordion"
            >
              {this.props.mode !== "view" && (
                <Form.Group>
                  <AddressInput
                    formInvalid={this.props.formInvalid}
                    inputData={this.props.selectedReferralData.postcode}
                    dropdownLabel={strings.form.label.postcodeSearch}
                    dropdownName="postcode"
                    dropdownOptions={this.props.addressSearchResults.postcode}
                    dropdownPlaceholder={strings.form.placeholder.postcode}
                    formFieldWidth={6}
                    handlePostcodeChanged={this.props.handlePostcodeChanged}
                    handlePostcodeClicked={this.props.handlePostcodeClicked}
                    validationResult={this.props.validationResults.postcode}
                    open={this.props.postcodeOpenStates.postcode}
                    mode={this.props.mode}
                    disabled={this.props.mode === "amend"}
                  />
                  <Button
                    className="dropdownButton"
                    onClick={this.props.handlePostcodeSearch}
                    floated="left"
                    name="postcode"
                    compact
                    disabled={this.props.mode === "amend"}
                  >
                    {strings.button.searchLabel}
                  </Button>
                </Form.Group>
              )}

              <Form.Group>
                {this.props.mode !== "view" ? (
                  <ValidatedFormInput
                    formInvalid={this.props.formInvalid}
                    inputData={this.props.selectedReferralData.postcode}
                    inputLabel={strings.form.label.postcode}
                    inputName="postcode"
                    inputPlaceholder={strings.form.placeholder.postcode}
                    formFieldWidth={8}
                    handleChange={this.props.handleChangePostcode}
                    validationResult={this.props.validationResults.postcode}
                    mode={this.props.mode}
                    popUp={
                      <Popup
                        content={strings.tooltip.postcodeHomelessFormatContent}
                        key="postcode"
                        header={strings.tooltip.postcodeHomelessFormatHeader}
                        trigger={
                          <Icon className="tooltip" name="info circle" />
                        }
                      />
                    }
                    disabled={this.props.mode === "amend"}
                  />
                ) : (
                  <ValidatedFormInput
                    formInvalid={this.props.formInvalid}
                    inputData={this.props.selectedReferralData.postcode}
                    inputLabel={strings.form.label.postcode}
                    inputName="postcode"
                    inputPlaceholder={strings.form.placeholder.postcode}
                    formFieldWidth={8}
                    handleChange={this.props.handleChangePostcode}
                    validationResult={this.props.validationResults.postcode}
                    mode={this.props.mode}
                    disabled={this.props.mode === "amend"}
                  />
                )}
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.townOrCity}
                  inputLabel={strings.form.label.townOrCity}
                  inputName="townOrCity"
                  inputPlaceholder={strings.form.placeholder.townOrCity}
                  formFieldWidth={8}
                  handleChange={this.props.handleChangeTownOrCity}
                  validationResult={this.props.validationResults.townOrCity}
                  required={false}
                  mode={this.props.mode}
                  disabled={this.props.mode === "amend"}
                />
              </Form.Group>
              <Form.Group>
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.address1}
                  inputLabel={strings.form.label.address1}
                  inputName="address1"
                  inputPlaceholder={strings.form.placeholder.address1}
                  formFieldWidth={8}
                  handleChange={this.props.handleChangeAddress1}
                  validationResult={this.props.validationResults.address1}
                  mode={this.props.mode}
                  disabled={this.props.mode === "amend"}
                />
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.address2}
                  inputLabel={strings.form.label.address2}
                  inputName="address2"
                  inputPlaceholder={strings.form.placeholder.address2}
                  formFieldWidth={8}
                  handleChange={this.props.handleChangeAddress2}
                  validationResult={this.props.validationResults.address2}
                  required={false}
                  mode={this.props.mode}
                  disabled={this.props.mode === "amend"}
                />
              </Form.Group>
            </Accordion.Content>
          </Accordion>
          <Accordion styled>
            <Accordion.Title
              active={libActiveIndex === 0}
              index={0}
              onClick={this.handleClick}
              typeofindex="libActiveIndex"
              className="addressbookHeader"
            >
              <Icon name="dropdown" />
              {strings.form.accordion.libAddress}
            </Accordion.Title>
            <Accordion.Content
              active={libActiveIndex === 0}
              className="addressbookAccordion"
            >
              {this.props.mode !== "view" && (
                <Form.Group>
                  <AddressInput
                    formInvalid={this.props.formInvalid}
                    inputData={this.props.selectedReferralData.libPostcode}
                    dropdownLabel={strings.form.label.postcodeSearch}
                    dropdownName="libPostcode"
                    dropdownOptions={
                      this.props.addressSearchResults.libPostcode
                    }
                    dropdownPlaceholder={strings.form.placeholder.libPostcode}
                    formFieldWidth={6}
                    handlePostcodeChanged={this.props.handlePostcodeChanged}
                    handlePostcodeClicked={this.props.handlePostcodeClicked}
                    validationResult={this.props.validationResults.libPostcode}
                    open={this.props.postcodeOpenStates.libPostcode}
                    mode={this.props.mode}
                    disabled={
                      this.props.mode === "amend" ||
                      this.props.noFixedAbodeChecked
                    }
                  />
                  <Button
                    className="dropdownButton"
                    onClick={this.props.handlePostcodeSearch}
                    floated="left"
                    name="libPostcode"
                    compact
                    disabled={
                      this.props.libAddressSameChecked ||
                      this.props.mode === "amend" ||
                      this.props.noFixedAbodeChecked
                    }
                  >
                    {strings.button.searchLabel}
                  </Button>
                </Form.Group>
              )}
              <Form.Group>
                {this.props.mode !== "view" ? (
                  <ValidatedFormInput
                    formInvalid={this.props.formInvalid}
                    inputData={this.props.selectedReferralData.libPostcode}
                    inputLabel={strings.form.label.libPostcode}
                    inputName="libPostcode"
                    inputPlaceholder={strings.form.placeholder.libPostcode}
                    formFieldWidth={8}
                    handleChange={this.props.handleChange}
                    validationResult={this.props.validationResults.libPostcode}
                    mode={this.props.mode}
                    popUp={
                      <Popup
                        content={strings.tooltip.postcodeHomelessFormatContent}
                        key="postcode"
                        header={strings.tooltip.postcodeHomelessFormatHeader}
                        trigger={
                          <Icon className="tooltip" name="info circle" />
                        }
                      />
                    }
                    disabled={
                      this.props.libAddressSameChecked ||
                      this.props.mode === "amend" ||
                      this.props.noFixedAbodeChecked
                    }
                  />
                ) : (
                  <ValidatedFormInput
                    formInvalid={this.props.formInvalid}
                    inputData={this.props.selectedReferralData.libPostcode}
                    inputLabel={strings.form.label.libPostcode}
                    inputName="libPostcode"
                    inputPlaceholder={strings.form.placeholder.libPostcode}
                    formFieldWidth={8}
                    handleChange={this.props.handleChange}
                    validationResult={this.props.validationResults.libPostcode}
                    mode={this.props.mode}
                    disabled={
                      this.props.libAddressSameChecked ||
                      this.props.mode === "amend" ||
                      this.props.noFixedAbodeChecked
                    }
                  />
                )}
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.libTown}
                  inputLabel={strings.form.label.libTown}
                  inputName="libTown"
                  inputPlaceholder={strings.form.placeholder.libTown}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={this.props.validationResults.libTown}
                  required={false}
                  disabled={
                    this.props.libAddressSameChecked ||
                    this.props.mode === "amend" ||
                    this.props.noFixedAbodeChecked
                  }
                  mode={this.props.mode}
                />
              </Form.Group>
              <Form.Group>
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.libAddress1}
                  inputLabel={strings.form.label.libAddress1}
                  inputName="libAddress1"
                  inputPlaceholder={strings.form.placeholder.libAddress1}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={this.props.validationResults.libAddress1}
                  disabled={
                    this.props.libAddressSameChecked ||
                    this.props.mode === "amend" ||
                    this.props.noFixedAbodeChecked
                  }
                  mode={this.props.mode}
                />

                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.libAddress2}
                  inputLabel={strings.form.label.libAddress2}
                  inputName="libAddress2"
                  inputPlaceholder={strings.form.placeholder.libAddress2}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={this.props.validationResults.libAddress2}
                  required={false}
                  mode={this.props.mode}
                  disabled={
                    this.props.libAddressSameChecked ||
                    this.props.mode === "amend" ||
                    this.props.noFixedAbodeChecked
                  }
                />
              </Form.Group>
              <Checkbox
                checked={this.props.libAddressSameChecked}
                disabled={
                  this.props.mode === "view" ||
                  this.props.mode === "amend" ||
                  this.props.noFixedAbodeChecked
                }
                label={strings.form.label.sameAddressCheckbox}
                name="libAddressSame"
                onChange={this.props.handleSameAddressChange}
              />
              <Checkbox
                checked={this.props.noFixedAbodeChecked}
                disabled={
                  this.props.mode === "view" || this.props.mode === "amend"
                }
                label={strings.form.label.noFixedAbode}
                name="noFixedAbode"
                onChange={this.props.fixedAbodeChange}
              />
            </Accordion.Content>
          </Accordion>
          <Accordion styled>
            <Accordion.Title
              active={mailingActiveIndex === 0}
              index={0}
              onClick={this.handleClick}
              typeofindex="mailingActiveIndex"
              className="addressbookHeader"
            >
              <Icon name="dropdown" />
              {strings.form.accordion.mailingAddress}
            </Accordion.Title>
            <Accordion.Content
              active={mailingActiveIndex === 0}
              className="addressbookAccordion"
            >
              {this.props.mode !== "view" && (
                <Form.Group>
                  <AddressInput
                    formInvalid={this.props.formInvalid}
                    inputData={this.props.selectedReferralData.mailingPostcode}
                    dropdownLabel={strings.form.label.postcodeSearch}
                    dropdownName="mailingPostcode"
                    dropdownOptions={
                      this.props.addressSearchResults.mailingPostcode
                    }
                    dropdownPlaceholder={
                      strings.form.placeholder.mailingPostcode
                    }
                    formFieldWidth={6}
                    handlePostcodeChanged={this.props.handlePostcodeChanged}
                    handlePostcodeClicked={this.props.handlePostcodeClicked}
                    validationResult={
                      this.props.validationResults.mailingPostcode
                    }
                    open={this.props.postcodeOpenStates.mailingPostcode}
                    mode={this.props.mode}
                    disabled={
                      this.props.mailingAddressSameChecked ||
                      this.props.mode === "amend"
                    }
                  />
                  <Button
                    className="dropdownButton"
                    onClick={this.props.handlePostcodeSearch}
                    floated="left"
                    name="mailingPostcode"
                    compact
                    disabled={
                      this.props.mailingAddressSameChecked ||
                      this.props.mode === "amend"
                    }
                  >
                    {strings.button.searchLabel}
                  </Button>
                </Form.Group>
              )}

              <Form.Group>
                {this.props.mode !== "view" ? (
                  <ValidatedFormInput
                    formInvalid={this.props.formInvalid}
                    inputData={this.props.selectedReferralData.mailingPostcode}
                    inputLabel={strings.form.label.mailingPostcode}
                    inputName="mailingPostcode"
                    inputPlaceholder={strings.form.placeholder.mailingPostcode}
                    formFieldWidth={8}
                    handleChange={this.props.handleChange}
                    validationResult={
                      this.props.validationResults.mailingPostcode
                    }
                    required={false}
                    mode={this.props.mode}
                    popUp={
                      <Popup
                        content={strings.tooltip.postcodeFormatContent}
                        key="postcode"
                        header={strings.tooltip.postcodeFormatHeader}
                        trigger={
                          <Icon className="tooltip" name="info circle" />
                        }
                      />
                    }
                    disabled={
                      this.props.mailingAddressSameChecked ||
                      this.props.mode === "amend"
                    }
                  />
                ) : (
                  <ValidatedFormInput
                    formInvalid={this.props.formInvalid}
                    inputData={this.props.selectedReferralData.mailingPostcode}
                    inputLabel={strings.form.label.mailingPostcode}
                    inputName="mailingPostcode"
                    inputPlaceholder={strings.form.placeholder.mailingPostcode}
                    formFieldWidth={8}
                    handleChange={this.props.handleChange}
                    validationResult={
                      this.props.validationResults.mailingPostcode
                    }
                    mode={this.props.mode}
                    disabled={
                      this.props.mailingAddressSameChecked ||
                      this.props.mode === "amend"
                    }
                  />
                )}
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.mailingTown}
                  inputLabel={strings.form.label.mailingTown}
                  inputName="mailingTown"
                  inputPlaceholder={strings.form.placeholder.mailingTown}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={this.props.validationResults.mailingTown}
                  required={false}
                  mode={this.props.mode}
                  disabled={
                    this.props.mailingAddressSameChecked ||
                    this.props.mode === "amend"
                  }
                />
              </Form.Group>
              <Form.Group>
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.mailingAddress1}
                  inputLabel={strings.form.label.mailingAddress1}
                  inputName="mailingAddress1"
                  inputPlaceholder={strings.form.placeholder.mailingAddress1}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={
                    this.props.validationResults.mailingAddress1
                  }
                  required={false}
                  mode={this.props.mode}
                  disabled={
                    this.props.mailingAddressSameChecked ||
                    this.props.mode === "amend"
                  }
                />
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.mailingAddress2}
                  inputLabel={strings.form.label.mailingAddress2}
                  inputName="mailingAddress2"
                  inputPlaceholder={strings.form.placeholder.mailingAddress2}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={
                    this.props.validationResults.mailingAddress2
                  }
                  required={false}
                  mode={this.props.mode}
                  disabled={
                    this.props.mailingAddressSameChecked ||
                    this.props.mode === "amend"
                  }
                />
              </Form.Group>
              <Checkbox
                checked={this.props.mailingAddressSameChecked}
                disabled={
                  this.props.mode === "view" || this.props.mode === "amend"
                }
                label={strings.form.label.sameAddressCheckbox}
                name="mailingAddressSame"
                onChange={this.props.handleSameAddressChange}
              />
            </Accordion.Content>
          </Accordion>
          <Accordion styled>
            <Accordion.Title
              active={additionalActiveIndex === 0}
              index={0}
              onClick={this.handleClick}
              typeofindex="additionalActiveIndex"
              className="addressbookHeader"
            >
              <Icon name="dropdown" />
              {strings.form.accordion.additionalAddress}
            </Accordion.Title>
            <Accordion.Content
              active={additionalActiveIndex === 0}
              className="addressbookAccordion"
            >
              {this.props.mode !== "view" && (
                <Form.Group>
                  <AddressInput
                    formInvalid={this.props.formInvalid}
                    inputData={
                      this.props.selectedReferralData.additionalPostcode
                    }
                    dropdownLabel={strings.form.label.postcodeSearch}
                    dropdownName="additionalPostcode"
                    dropdownOptions={
                      this.props.addressSearchResults.additionalPostcode
                    }
                    dropdownPlaceholder={
                      strings.form.placeholder.additionalPostcode
                    }
                    formFieldWidth={6}
                    handlePostcodeChanged={this.props.handlePostcodeChanged}
                    handlePostcodeClicked={this.props.handlePostcodeClicked}
                    handlePostcodeSearch={this.props.handlePostcodeSearch}
                    validationResult={
                      this.props.validationResults.additionalPostcode
                    }
                    open={this.props.postcodeOpenStates.additionalPostcode}
                    mode={this.props.mode}
                    disabled={this.props.mode === "amend"}
                  />
                  <Button
                    className="dropdownButton"
                    onClick={this.props.handlePostcodeSearch}
                    floated="left"
                    name="additionalPostcode"
                    compact
                    disabled={this.props.mode === "amend"}
                  >
                    {strings.button.searchLabel}
                  </Button>
                </Form.Group>
              )}
              <Form.Group>
                {this.props.mode !== "view" ? (
                  <ValidatedFormInput
                    formInvalid={this.props.formInvalid}
                    inputData={
                      this.props.selectedReferralData.additionalPostcode
                    }
                    inputLabel={strings.form.label.additionalPostcode}
                    inputName="additionalPostcode"
                    inputPlaceholder={
                      strings.form.placeholder.additionalPostcode
                    }
                    formFieldWidth={8}
                    handleChange={this.props.handleChange}
                    validationResult={
                      this.props.validationResults.additionalPostcode
                    }
                    required={false}
                    mode={this.props.mode}
                    popUp={
                      <Popup
                        content={strings.tooltip.postcodeFormatContent}
                        key="postcode"
                        header={strings.tooltip.postcodeFormatHeader}
                        trigger={
                          <Icon
                            className="referralPostcodeTooltip"
                            name="info circle"
                          />
                        }
                      />
                    }
                    disabled={this.props.mode === "amend"}
                  />
                ) : (
                  <ValidatedFormInput
                    formInvalid={this.props.formInvalid}
                    inputData={
                      this.props.selectedReferralData.additionalPostcode
                    }
                    inputLabel={strings.form.label.additionalPostcode}
                    inputName="additionalPostcode"
                    inputPlaceholder={
                      strings.form.placeholder.additionalPostcode
                    }
                    formFieldWidth={8}
                    handleChange={this.props.handleChange}
                    validationResult={
                      this.props.validationResults.additionalPostcode
                    }
                    required={false}
                    mode={this.props.mode}
                    disabled={this.props.mode === "amend"}
                  />
                )}
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.additionalTown}
                  inputLabel={strings.form.label.additionalTown}
                  inputName="additionalTown"
                  inputPlaceholder={strings.form.placeholder.additionalTown}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={this.props.validationResults.additionalTown}
                  required={false}
                  mode={this.props.mode}
                  disabled={this.props.mode === "amend"}
                />
              </Form.Group>
              <Form.Group>
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.additionalAddress1}
                  inputLabel={strings.form.label.additionalAddress1}
                  inputName="additionalAddress1"
                  inputPlaceholder={strings.form.placeholder.additionalAddress1}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={
                    this.props.validationResults.additionalAddress1
                  }
                  required={false}
                  mode={this.props.mode}
                  disabled={this.props.mode === "amend"}
                />
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.additionalAddress2}
                  inputLabel={strings.form.label.additionalAddress2}
                  inputName="additionalAddress2"
                  inputPlaceholder={strings.form.placeholder.additionalAddress2}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={
                    this.props.validationResults.additionalAddress2
                  }
                  required={false}
                  mode={this.props.mode}
                  disabled={this.props.mode === "amend"}
                />
              </Form.Group>
            </Accordion.Content>
          </Accordion>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.emergencyContactName}
              inputLabel={strings.form.label.emergencyContactName}
              inputName="emergencyContactName"
              inputPlaceholder={strings.form.placeholder.emergencyContactName}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults.emergencyContactName
              }
              required={false}
              mode={this.props.mode}
              disabled={this.props.mode === "amend"}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.relationship}
              viewData={this.props.selectedReferralData.relationship}
              dropdownLabel={strings.form.label.relationship}
              dropdownName="relationship"
              dropdownOptions={relationshipOptions}
              dropdownPlaceholder={strings.form.placeholder.relationship}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.relationship}
              required={false}
              mode={this.props.mode}
              dropdownDisabled={this.props.mode === "amend"}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.emergencyContactNum}
              inputLabel={strings.form.label.emergencyContactNum}
              inputName="emergencyContactNum"
              inputPlaceholder={strings.form.placeholder.emergencyContactNum}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults.emergencyContactNum
              }
              required={false}
              onKeyPress={this.props.allowNumericalInput}
              mode={this.props.mode}
              disabled={this.props.mode === "amend"}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.armedForces}
              viewData={this.props.selectedReferralData.armedForces}
              dropdownLabel={strings.form.label.armedForces}
              dropdownName="armedForces"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.armedForces}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.armedForces}
              mode={this.props.mode}
              dropdownDisabled={this.props.mode === "amend"}
            />
          </Form.Group>
          <ShowIfSameArea
            area={this.props.area}
            allowedArea={strings.areas.NRNE}
          >
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={
                  this.props.selectedReferralData.customerLocalAuthorityArea
                }
                viewData={
                  this.props.selectedReferralData.customerLocalAuthorityArea
                }
                dropdownLabel={strings.form.label.customerLocalAuthorityArea}
                dropdownName="customerLocalAuthorityArea"
                dropdownOptions={customerLocalAuthorityAreaOptions}
                dropdownPlaceholder={
                  strings.form.placeholder.customerLocalAuthorityArea
                }
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.customerLocalAuthorityArea
                }
                mode={this.props.mode}
                dropdownDisabled={this.props.mode === "amend"}
              />
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.wingCellNumber}
                inputLabel={strings.form.label.wingCellNumber}
                inputName="wingCellNumber"
                inputPlaceholder={strings.form.placeholder.wingCellNumber}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.wingCellNumber}
                mode={this.props.mode}
                required={false}
                disabled={this.props.mode === "amend"}
              />
            </Form.Group>
          </ShowIfSameArea>

          <Form.Group>
            <ShowIfSameArea
              area={this.props.area}
              allowedArea={strings.areas.NRS}
            >
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.liberationLocalArea}
                viewData={this.props.selectedReferralData.liberationLocalArea}
                dropdownLabel={strings.form.label.liberationLocalArea}
                dropdownName="liberationLocalArea"
                dropdownOptions={liberationLocalAreaOptions}
                dropdownPlaceholder={
                  strings.form.placeholder.liberationLocalArea
                }
                formFieldWidth={8}
                handleChange={this.props.handleChangeLiberation}
                validationResult={
                  this.props.validationResults.liberationLocalArea
                }
                mode={this.props.mode}
                dropdownDisabled={this.props.mode === "amend"}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.prisonNrs}
                viewData={this.props.selectedReferralData.prisonNrs}
                dropdownLabel={strings.form.label.prisonNrs}
                dropdownName="prisonNrs"
                dropdownOptions={prisonNrsOptions}
                dropdownPlaceholder={strings.form.placeholder.prisonNrs}
                formFieldWidth={8}
                handleChange={this.props.handleChangePrisonNrs}
                validationResult={this.props.validationResults.prisonNrs}
                required={false}
                mode={this.props.mode}
                dropdownDisabled={this.props.mode === "amend"}
              />
            </ShowIfSameArea>
            <ShowIfSameArea
              area={this.props.area}
              allowedArea={strings.areas.NRNE}
            >
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.prisonNrne}
                viewData={this.props.selectedReferralData.prisonNrne}
                dropdownLabel={strings.form.label.prisonNrne}
                dropdownName="prisonNrne"
                dropdownOptions={prisonNrneOptions}
                dropdownPlaceholder={strings.form.placeholder.prisonNrne}
                formFieldWidth={8}
                handleChange={this.props.handleChangePrisonNrne}
                validationResult={this.props.validationResults.prisonNrne}
                required={false}
                mode={this.props.mode}
                dropdownDisabled={this.props.mode === "amend"}
              />
              {this.props.selectedReferralData.prisonNrne ===
                strings.fieldValues.other && (
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.prisonNrneOther}
                  inputLabel={strings.form.label.prisonNrneOther}
                  inputName="prisonNrneOther"
                  inputPlaceholder={strings.form.placeholder.prisonNrneOther}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={
                    this.props.validationResults.prisonNrneOther
                  }
                  mode={this.props.mode}
                  disabled={this.props.mode === "amend"}
                />
              )}
            </ShowIfSameArea>
          </Form.Group>
          <ShowIfSameArea
            area={this.props.area}
            allowedArea={strings.areas.NRS}
          >
            <Form.Group>
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.hallName}
                inputLabel={strings.form.label.hallName}
                inputName="hallName"
                inputPlaceholder={strings.form.placeholder.hallName}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.hallName}
                required={false}
                mode={this.props.mode}
                disabled={this.props.mode === "amend"}
              />
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.level}
                inputLabel={strings.form.label.level}
                inputName="level"
                inputPlaceholder={strings.form.placeholder.level}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.level}
                required={false}
                mode={this.props.mode}
                disabled={this.props.mode === "amend"}
              />
            </Form.Group>
          </ShowIfSameArea>
          <Form.Group>
            <ValidatedDateInput
              inputId="convictionDate"
              formInvalid={this.props.formInvalid}
              inputLabel={strings.form.label.convictionDate}
              inputName="convictionDate"
              inputPlaceholder={strings.form.placeholder.convictionDate}
              inputDateFormat="DD/MM/YYYY"
              inputData={
                this.props.selectedReferralData.convictionDate
                  ? convertFromIso(
                      this.props.selectedReferralData.convictionDate
                    )
                  : ""
              }
              iconPosition="left"
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.convictionDate}
              formFieldWidth={8}
              mode={this.props.mode}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.outstandingCharges}
              inputLabel={strings.form.label.outstandingCharges}
              inputName="outstandingCharges"
              inputPlaceholder={strings.form.placeholder.outstandingCharges}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.outstandingCharges}
              required={false}
              mode={this.props.mode}
              disabled={this.props.mode === "amend"}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.years}
              viewData={this.props.selectedReferralData.years}
              dropdownLabel={strings.form.label.years}
              dropdownName="years"
              dropdownOptions={yearsOptions}
              dropdownPlaceholder={strings.form.placeholder.years}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.years}
              mode={this.props.mode}
              dropdownDisabled={this.props.mode === "amend"}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.months}
              viewData={this.props.selectedReferralData.months}
              dropdownLabel={strings.form.label.months}
              dropdownName="months"
              dropdownOptions={monthsOptions}
              dropdownPlaceholder={strings.form.placeholder.months}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.months}
              mode={this.props.mode}
              dropdownDisabled={this.props.mode === "amend"}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.days}
              viewData={this.props.selectedReferralData.days}
              dropdownLabel={strings.form.label.days}
              dropdownName="days"
              dropdownOptions={daysOptions}
              dropdownPlaceholder={strings.form.placeholder.days}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.days}
              required={false}
              mode={this.props.mode}
              dropdownDisabled={this.props.mode === "amend"}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedDateInput
              inputId="hdcQualificationDate"
              formInvalid={this.props.formInvalid}
              inputLabel={strings.form.label.hdcQualificationDate}
              inputName="hdcQualificationDate"
              inputPlaceholder={strings.form.placeholder.hdcQualificationDate}
              inputDateFormat="DD/MM/YYYY"
              inputData={
                this.props.selectedReferralData.hdcQualificationDate
                  ? convertFromIso(
                      this.props.selectedReferralData.hdcQualificationDate
                    )
                  : ""
              }
              iconPosition="left"
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults.hdcQualificationDate
              }
              formFieldWidth={8}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedDateInput
              inputId="overallEDL"
              formInvalid={this.props.formInvalid}
              inputLabel={strings.form.label.overallEDL}
              inputName="overallEDL"
              inputPlaceholder={strings.form.placeholder.overallEDL}
              inputDateFormat="DD/MM/YYYY"
              inputData={
                this.props.selectedReferralData.overallEDL
                  ? convertFromIso(this.props.selectedReferralData.overallEDL)
                  : ""
              }
              iconPosition="left"
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.overallEDL}
              formFieldWidth={8}
              required={this.props.area === strings.areas.NRS}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.hasChildren}
              viewData={this.props.selectedReferralData.hasChildren}
              dropdownLabel={strings.form.label.hasChildren}
              dropdownName="hasChildren"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.hasChildren}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.hasChildren}
              required={false}
              mode={this.props.mode}
              dropdownDisabled={this.props.mode === "amend"}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.contactChildren}
              viewData={this.props.selectedReferralData.contactChildren}
              dropdownLabel={strings.form.label.contactChildren}
              dropdownName="contactChildren"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.contactChildren}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.contactChildren}
              required={false}
              mode={this.props.mode}
              dropdownDisabled={this.props.mode === "amend"}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.custodialSentences}
              inputLabel={strings.form.label.custodialSentences}
              inputName="custodialSentences"
              onKeyPress={this.props.allowNumericalInput}
              inputPlaceholder={strings.form.placeholder.custodialSentences}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.custodialSentences}
              mode={this.props.mode}
              disabled={this.props.mode === "amend"}
            />
            <ShowIfSameArea
              area={this.props.area}
              allowedArea={strings.areas.NRS}
            >
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={
                  this.props.selectedReferralData.nonCustodialSentences
                }
                inputLabel={strings.form.label.nonCustodialSentences}
                inputName="nonCustodialSentences"
                onKeyPress={this.props.allowNumericalInput}
                inputPlaceholder={
                  strings.form.placeholder.nonCustodialSentences
                }
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.nonCustodialSentences
                }
                required={false}
                mode={this.props.mode}
                disabled={this.props.mode === "amend"}
              />
            </ShowIfSameArea>
          </Form.Group>
        </div>
      </div>
    );
  };
}

PersonalDetailsFields.propTypes = {
  allowNumericalInput: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default PersonalDetailsFields;
