import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      entity: "activities",
      deletion: "deletion",
      fullName: "Activity"
    },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    header: {
      login: "Login",
      activityList: "Activities",
      createActivity: "New Activity",
      editActivity: "Edit Activity",
      viewActivity: "Activity Details",
      loading: "Loading Information",
      createSupportReferral: "New Support Referral"
    },
    form: {
      text: {
        other: "Other"
      },
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        activityInfo: "Activity Details",
        activityCategoriesOutcomes: "Activity Categories and Outcomes",
        referralInfo: "Support Referral Information"
      },
      label: {
        dateOfActivity: "Date of Activity",
        type: "Activity Type",
        supportServiceEngaged: "Support Service Engaged?",
        supportService: "Support Service",
        category: "Category",
        outcomesAchieved: "Outcomes (achieved)",
        outcomesWorked: "Outcomes (worked towards)",
        identifiedNeed: "Identified Need",
        crmId: "CRM ID",
        owner: "Owner",
        outcomes: {
          outcomeS2: "S2",
          outcomeS4A: "S4A",
          outcomeS5A: "S5A",
          outcomeS3: "S3",
          outcomeM2: "M2",
          outcomeM3: "M3",
          outcomeM4A: "M4A"
        },
        previousCrmId: "Previous CRM ID",
        otherSupportService: "Other Support Service",
        firstName: "First Name",
        surname: "Surname"
      },
      placeholder: {
        stateReason: "State your reason...",
        dateOfActivity: "- select -",
        type: "- select -",
        supportServiceEngaged: "- select -",
        supportService: "- select -",
        category: "- select -",
        crmId: "",
        previousCrmId: "",
        owner: "- select -",
        otherSupportService: "",
        firstName: "",
        surname: ""
      },
      feedback: {
        processing: "Processing request...",
        activityRequestSuccess: "Activity created successfully!",
        activityRequestRefreshPrompt:
          "Use the refresh function to see an updated activities list.",
        activityRequestFailure: "Activity creation failed.",
        activityRequestUnknown:
          "Something went wrong with the activity request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      button: {
        addCategory: "Add category"
      }
    },
    modal: {
      createActivityMessage: "Save activity?",
      modifyActivityMessage: "Are you sure you want to save these changes?",
      deleteActivityMessage:
        "Are you sure you want to delete this activity? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      supportReferralMessage:
        "Are you sure you want to save these changes and create this support referral?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this activity's status to active?",
      setInactiveMessage: "Set this activity's status to inactive?",
      deleteCategoryMessage:
        "Are you sure you want to delete this category? (This action cannot be undone)",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      submitSupportReferral: "Submit Support Referral",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      addActivityLabel: "New Activity",
      saveAddNew: "Save & Add New",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    tooltip: {},
    table: {
      header: {
        firstName: "First Name",
        surname: "Last Name",
        pNumber: "Prison Number",
        scroNumber: "S Number",
        dateOfActivity: "Date of Activity",
        type: "Activity Type",
        supportServiceEngaged: "Support Service Engaged?",
        actCategories: "Categories",
        createdDate: "Created",
        updatedDate: "Updated",
        crmId: "CRM ID"
      },
      text: {
        noResults: "No results found."
      }
    },
    fieldValues: {
      yes: "Yes",
      no: "No",
      onProgrammeInPrison: "On Programme - In Prison",
      community: "Community",
      prison: "Prison"
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    orgTypes: {
      supportPartner: "Support Partner"
    },
    activityTypes: {
      referralToSupportPartner: "Referral to Support Partner"
    }
  }
});
