const commitFileChanges = async (
  commitChanges,
  localData,
  remoteData,
  folder,
  entity,
  id
) => {
  commitChanges(
    localData[`${folder}`],
    remoteData[`${folder}`],
    `${entity}s/`,
    "",
    `${entity}`,
    id,
    `${folder}`
  );
};

export default commitFileChanges;
