import {
  dummyRecordActionTypes,
  ttgRecordActionTypes,
  ttgProgrammeActionTypes
} from "../actions/ttgRecord.actionTypes";
import { removeKeyFromObject } from "../../../App/helpers/objectHelpers";

const initialState = {
  loadingPage: false,
  selectedTtgRecordData: {},
  ttgRecordListData: [{}],
  totalResults: 0,
  dummyRecordToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  dummyRecordRequestStatus: undefined,
  dummyRecordRequestMade: false,
  deleteSuccessful: false,
  showNotification: true,
  ttgProgrammeRequestStatus: undefined,
  ttgProgrammeRequestMade: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case dummyRecordActionTypes.CREATE_DUMMY_RECORD_REQUEST:
      return {
        ...state,
        dummyRecordRequestStatus: undefined,
        dummyRecordRequestMade: true
      };
    case dummyRecordActionTypes.CREATE_DUMMY_RECORD_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        dummyRecordRequestStatus: true
      };
    case dummyRecordActionTypes.CREATE_DUMMY_RECORD_FAILURE:
      let details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        dummyRecordRequestStatus: false
      };

    case dummyRecordActionTypes.CLEAR_CREATE_DUMMY_RECORD_REQUEST:
      return { ...state };
    case dummyRecordActionTypes.CLEAR_CREATE_DUMMY_RECORD_SUCCESS:
      return {
        ...state,
        dummyRecordRequestMade: false,
        dummyRecordRequestStatus: undefined
      };
    case dummyRecordActionTypes.CLEAR_CREATE_DUMMY_RECORD_FAILURE:
      return { ...state };

    case dummyRecordActionTypes.CLEAR_SELECTED_TTG_RECORD_REQUEST:
      return { ...state };
    case dummyRecordActionTypes.CLEAR_SELECTED_TTG_RECORD_SUCCESS:
      return { ...state, selectedTtgRecordData: {} };
    case dummyRecordActionTypes.CLEAR_SELECTED_TTG_RECORD_FAILURE:
      return { ...state };
    default:
      return state;

    case ttgRecordActionTypes.UPDATE_SELECTED_TTG_RECORD_REQUEST:
      return { ...state };
    case ttgRecordActionTypes.UPDATE_SELECTED_TTG_RECORD_SUCCESS:
      return {
        ...state,
        selectedTtgRecordData: {
          ...state.selectedTtgRecordData,
          [action.key]: action.value
        }
      };
    case ttgRecordActionTypes.UPDATE_SELECTED_TTG_RECORD_FAILURE:
      return { ...state };

    case ttgRecordActionTypes.DELETE_SELECTED_TTG_RECORD_PROPERTY_REQUEST:
      return { ...state };

    case ttgRecordActionTypes.DELETE_SELECTED_TTG_RECORD_PROPERTY_SUCCESS:
      const newSelectedTtgRecordData = removeKeyFromObject(
        state.selectedTtgRecordData,
        action.key
      );
      return { ...state, selectedTtgRecordData: newSelectedTtgRecordData };

    case ttgProgrammeActionTypes.CREATE_TTG_PROGRAMME_REQUEST:
      return {
        ...state,
        ttgProgrammeRequestStatus: undefined,
        ttgProgrammeRequestMade: true
      };
    case ttgProgrammeActionTypes.CREATE_TTG_PROGRAMME_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        ttgProgrammeRequestStatus: true
      };
    case ttgProgrammeActionTypes.CREATE_TTG_PROGRAMME_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        ttgProgrammeRequestStatus: false
      };
  }
};
