import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import { AuditListContainer } from "../../Audit/containers";
import {
  strings,
  journeyStatusOptions,
  yesNoOptions,
  hasBcst2ReasonOptions,
  prisonOptions,
  latestRiskRatingOptions,
  reasonForExitOptions,
  customerLocalAuthorityAreaOptions,
  sentIdentifiedNeedsOptions
} from "../resources";
import {
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormTextArea,
  ValidatedFormInput
} from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from "../components";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { convertFromIso, dropdownHelper } from "../helpers";
import FileManager from "../../File/components/FileManager";

class ModifyTtgRecord extends React.Component {
  returnToList = () => {
    this.props.history.push("/ttgRecord");
  };

  renderEditButton = () => {
    const { handleModeSwitch } = this.props;

    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      ></Input>
    );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful && (
          <Button.Group>
            <Button
              className="editButton"
              primary
              onClick={() => {
                handleModeSwitch("edit");
              }}
              disabled={this.props.showAuditLog}
            >
              {strings.button.editLabel}
            </Button>
          </Button.Group>
        )}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={this.props.toggleEnableDisableRecordModalVisibility}
                type="button"
                disabled={this.props.selectedTtgRecordData.requestId}
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableRecordModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={
                  this.props.toggleEnableDisableRecordModalVisibility
                }
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderDeleteButton = () => {
    return (
      <div className="containerButtons">
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={this.props.toggleEnableDisableRecordModalVisibility}
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteTtgRecordMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableRecordModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={
                  this.props.toggleEnableDisableRecordModalVisibility
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderAuditButton = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="auditButton"
            onClick={() => {
              this.props.handleToggleAudit();
            }}
          >
            {!this.props.showAuditLog
              ? strings.button.auditLabel
              : strings.button.formLabel}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderCancelSaveButton = () => {
    const {
      selectedTtgRecordData,
      handleModeSwitch,
      handleModalConfirm,
      toggleCancelChangesModalVisibility,
      toggleConfirmSaveModalVisibility,
      initialTtgRecordData,
      validateForm,
      confirmSaveModalOpen,
      mode,
      cancelChangesModalOpen
    } = this.props;

    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={validateForm}
            disabled={
              JSON.stringify(selectedTtgRecordData) ===
                JSON.stringify(initialTtgRecordData) &&
              !this.props.hasFileChanges
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };
  renderForm = () => {
    const {
      selectedTtgRecordData,
      handleChange,
      mode,
      formInvalid,
      validationResults,
      userList
    } = this.props;

    return (
      <>
        <div className="infoHeader">{strings.form.header.ttgRecordInfo}</div>
        <div className="infoWrapper">
          <Form.Group>
            {mode !== "create" && (
              <ValidatedFormInput
                formInvalid={formInvalid}
                inputData={selectedTtgRecordData.crmId}
                inputLabel={strings.form.label.crmId}
                inputName="crmId"
                inputPlaceholder={strings.form.placeholder.crmId}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.crmId}
                mode="view"
                disabled={mode === "edit"}
              />
            )}
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedTtgRecordData.cjSpecialist}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedTtgRecordData.cjSpecialist,
                  userList
                ) || ""
              }
              dropdownLabel={strings.form.label.cjSpecialist}
              dropdownName="cjSpecialist"
              dropdownOptions={userList}
              dropdownPlaceholder={strings.form.placeholder.cjSpecialist}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.cjSpecialist}
              dropdownSearch={true}
              required={true}
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={formInvalid}
              inputData={selectedTtgRecordData.firstName}
              inputLabel={strings.form.label.firstName}
              inputName="firstName"
              inputPlaceholder={strings.form.placeholder.firstName}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.firstName}
              mode={mode}
              required={true}
              disabled={
                selectedTtgRecordData.markerRecord === strings.form.text.yes
                  ? true
                  : false
              }
            />
            <ValidatedFormInput
              formInvalid={formInvalid}
              inputData={selectedTtgRecordData.surname}
              inputLabel={strings.form.label.surname}
              inputName="surname"
              inputPlaceholder={strings.form.placeholder.surname}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.surname}
              mode={mode}
              required={true}
              disabled={
                selectedTtgRecordData.markerRecord === strings.form.text.yes
                  ? true
                  : false
              }
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedTtgRecordData.markerRecord}
              viewData={selectedTtgRecordData.markerRecord}
              dropdownLabel={strings.form.label.markerRecord}
              dropdownName="markerRecord"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.markerRecord}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.markerRecord}
              mode={mode}
              required={true}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedTtgRecordData.journeyStatus}
              viewData={selectedTtgRecordData.journeyStatus}
              dropdownLabel={strings.form.label.journeyStatus}
              dropdownName="journeyStatus"
              dropdownOptions={journeyStatusOptions}
              dropdownPlaceholder={strings.form.placeholder.journeyStatus}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.journeyStatus}
              mode={mode}
              required={true}
            />
            <ValidatedDateInput
              inputId="dateBcst1"
              formInvalid={formInvalid}
              inputLabel={strings.form.label.dateBcst1}
              inputName="dateBcst1"
              inputPlaceholder={strings.form.placeholder.dateBcst1}
              inputDateFormat="DD/MM/YYYY"
              inputData={
                selectedTtgRecordData.dateBcst1
                  ? convertFromIso(selectedTtgRecordData.dateBcst1)
                  : ""
              }
              iconPosition="left"
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.dateBcst1}
              mode={mode}
              required={true}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedTtgRecordData.hasBcst2}
              viewData={selectedTtgRecordData.hasBcst2}
              dropdownLabel={strings.form.label.hasBcst2}
              dropdownName="hasBcst2"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.hasBcst2}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.hasBcst2}
              mode={mode}
              required={true}
            />
            {selectedTtgRecordData.hasBcst2 === strings.form.text.yes && (
              <ValidatedDateInput
                inputId="dateBcst2"
                formInvalid={formInvalid}
                inputLabel={strings.form.label.dateBcst2}
                inputName="dateBcst2"
                inputPlaceholder={strings.form.placeholder.dateBcst2}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  selectedTtgRecordData.dateBcst2
                    ? convertFromIso(selectedTtgRecordData.dateBcst2)
                    : ""
                }
                iconPosition="left"
                required={true}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.dateBcst2}
                mode={mode}
              />
            )}
            {selectedTtgRecordData.hasBcst2 === strings.form.text.no && (
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedTtgRecordData.hasBcst2Reason}
                viewData={selectedTtgRecordData.hasBcst2Reason}
                dropdownLabel={strings.form.label.hasBcst2Reason}
                dropdownName="hasBcst2Reason"
                dropdownOptions={hasBcst2ReasonOptions}
                dropdownPlaceholder={strings.form.placeholder.hasBcst2Reason}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.hasBcst2Reason}
                mode={mode}
              />
            )}
          </Form.Group>
          <Form.Group>
            {selectedTtgRecordData.hasBcst2Reason ===
              strings.form.text.other && (
              <ValidatedFormTextArea
                formInvalid={formInvalid}
                inputData={selectedTtgRecordData.hasBcst2ReasonOther}
                inputLabel={strings.form.label.hasBcst2ReasonOther}
                inputName="hasBcst2ReasonOther"
                inputPlaceholder={strings.form.placeholder.hasBcst2ReasonOther}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.hasBcst2ReasonOther}
                mode={mode}
              />
            )}
          </Form.Group>
          <Form.Group>
            <FileManager
              folder={
                selectedTtgRecordData.markerRecord === strings.form.text.yes
                  ? strings.folder.dummyFiles
                  : strings.folder.ttgProgrammeFiles
              }
              headers={this.props.headers}
              allowEdit={true}
              filePath={
                selectedTtgRecordData.markerRecord === strings.form.text.yes
                  ? `${strings.entity.dummy}s/`
                  : `${strings.entity.ttgProgramme}s/`
              }
              filesLabel={strings.form.fileUpload}
              disabled={this.props.mode === "view"}
              entityId={
                selectedTtgRecordData.markerRecord === strings.form.text.yes
                  ? selectedTtgRecordData.dummyId
                  : selectedTtgRecordData.ttgProgrammeId
              }
              entity={
                selectedTtgRecordData.markerRecord === strings.form.text.yes
                  ? strings.entity.dummy
                  : strings.entity.ttgProgramme
              }
              filesHaveChanged={this.props.filesHaveChanged}
            />
          </Form.Group>
          {selectedTtgRecordData.markerRecord === strings.form.text.no && (
            <>
              <Form.Group>
                <ValidatedFormDropdown
                  formInvalid={formInvalid}
                  inputData={selectedTtgRecordData.reasonForExit}
                  viewData={selectedTtgRecordData.reasonForExit}
                  dropdownLabel={strings.form.label.reasonForExit}
                  dropdownName="reasonForExit"
                  dropdownOptions={reasonForExitOptions}
                  dropdownPlaceholder={strings.form.placeholder.reasonForExit}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.reasonForExit}
                  mode={mode}
                  required={
                    selectedTtgRecordData.journeyStatus ===
                    strings.form.dropDownOptions.exit
                  }
                />
                <ValidatedDateInput
                  inputId="dateOfExit"
                  formInvalid={formInvalid}
                  inputLabel={strings.form.label.dateOfExit}
                  inputName="dateOfExit"
                  inputPlaceholder={strings.form.placeholder.dateOfExit}
                  inputDateFormat="DD/MM/YYYY"
                  inputData={
                    selectedTtgRecordData.dateOfExit
                      ? convertFromIso(selectedTtgRecordData.dateOfExit)
                      : ""
                  }
                  iconPosition="left"
                  handleChange={handleChange}
                  validationResult={validationResults.dateOfExit}
                  formFieldWidth={8}
                  mode={mode}
                  required={
                    selectedTtgRecordData.journeyStatus ===
                    strings.form.dropDownOptions.exit
                  }
                />
              </Form.Group>
              <Form.Group>
                {selectedTtgRecordData.reasonForExit ===
                  strings.form.dropDownOptions.other && (
                  <ValidatedFormInput
                    formInvalid={formInvalid}
                    inputData={selectedTtgRecordData.reasonForOtherExit}
                    inputLabel={strings.form.label.reasonForOtherExit}
                    inputName="reasonForOtherExit"
                    inputPlaceholder={
                      strings.form.placeholder.reasonForOtherExit
                    }
                    formFieldWidth={8}
                    handleChange={handleChange}
                    validationResult={validationResults.reasonForOtherExit}
                    mode={mode}
                    required={
                      selectedTtgRecordData.reasonForExit ===
                      strings.form.dropDownOptions.other
                    }
                  />
                )}
                <ValidatedFormDropdown
                  formInvalid={formInvalid}
                  inputData={selectedTtgRecordData.sentIdentifiedNeeds}
                  viewData={selectedTtgRecordData.sentIdentifiedNeeds}
                  dropdownLabel={strings.form.label.sentIdentifiedNeeds}
                  dropdownName="sentIdentifiedNeeds"
                  dropdownOptions={sentIdentifiedNeedsOptions}
                  dropdownPlaceholder={
                    strings.form.placeholder.sentIdentifiedNeeds
                  }
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.sentIdentifiedNeeds}
                  mode={mode}
                  required={
                    selectedTtgRecordData.reasonForExit ===
                    strings.form.dropDownOptions.transferred
                  }
                />
              </Form.Group>
              <Form.Group>
                <ValidatedFormInput
                  formInvalid={formInvalid}
                  inputData={selectedTtgRecordData.transferDetails}
                  inputLabel={strings.form.label.transferDetails}
                  inputName="transferDetails"
                  inputPlaceholder={strings.form.placeholder.transferDetails}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.transferDetails}
                  mode={mode}
                  required={
                    selectedTtgRecordData.sentIdentifiedNeeds ===
                    strings.form.text.no
                  }
                />
                <ValidatedDateInput
                  inputId="dateEmailSent"
                  formInvalid={formInvalid}
                  inputLabel={strings.form.label.dateEmailSent}
                  inputName="dateEmailSent"
                  inputPlaceholder={strings.form.placeholder.dateEmailSent}
                  inputDateFormat="DD/MM/YYYY"
                  inputData={
                    selectedTtgRecordData.dateEmailSent
                      ? convertFromIso(selectedTtgRecordData.dateEmailSent)
                      : ""
                  }
                  iconPosition="left"
                  handleChange={handleChange}
                  validationResult={validationResults.dateEmailSent}
                  formFieldWidth={8}
                  mode={mode}
                  required={
                    selectedTtgRecordData.sentIdentifiedNeeds ===
                    strings.form.text.yes
                  }
                />
              </Form.Group>
              <Form.Group>
                <ValidatedDateInput
                  inputId="releaseDate"
                  formInvalid={formInvalid}
                  inputLabel={strings.form.label.releaseDate}
                  inputName="releaseDate"
                  inputPlaceholder={strings.form.placeholder.releaseDate}
                  inputDateFormat="DD/MM/YYYY"
                  inputData={
                    selectedTtgRecordData.releaseDate
                      ? convertFromIso(selectedTtgRecordData.releaseDate)
                      : ""
                  }
                  iconPosition="left"
                  handleChange={handleChange}
                  validationResult={validationResults.releaseDate}
                  formFieldWidth={8}
                  mode={mode}
                  required={false}
                />
                <ValidatedDateInput
                  inputId="hdcEligibilityDate"
                  formInvalid={formInvalid}
                  inputLabel={strings.form.label.hdcEligibilityDate}
                  inputName="hdcEligibilityDate"
                  inputPlaceholder={strings.form.placeholder.hdcEligibilityDate}
                  inputDateFormat="DD/MM/YYYY"
                  inputData={
                    selectedTtgRecordData.hdcEligibilityDate
                      ? convertFromIso(selectedTtgRecordData.hdcEligibilityDate)
                      : ""
                  }
                  iconPosition="left"
                  handleChange={handleChange}
                  validationResult={validationResults.hdcEligibilityDate}
                  formFieldWidth={8}
                  mode={mode}
                  required={false}
                />
              </Form.Group>
              <Form.Group>
                <ValidatedDateInput
                  inputId="dateOfBirth"
                  formInvalid={formInvalid}
                  inputLabel={strings.form.label.dateOfBirth}
                  inputName="dateOfBirth"
                  inputPlaceholder={strings.form.placeholder.dateOfBirth}
                  inputDateFormat="DD/MM/YYYY"
                  inputData={
                    selectedTtgRecordData.dateOfBirth
                      ? convertFromIso(selectedTtgRecordData.dateOfBirth)
                      : ""
                  }
                  iconPosition="left"
                  handleChange={handleChange}
                  validationResult={validationResults.dateOfBirth}
                  formFieldWidth={8}
                  mode={mode}
                />
                <ValidatedFormInput
                  formInvalid={formInvalid}
                  inputData={selectedTtgRecordData.pNumber}
                  inputLabel={strings.form.label.pNumber}
                  inputName="pNumber"
                  inputPlaceholder={strings.form.placeholder.pNumber}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.pNumber}
                  mode={mode}
                  required={true}
                />
              </Form.Group>
              <Form.Group>
                <ValidatedFormDropdown
                  formInvalid={formInvalid}
                  inputData={selectedTtgRecordData.prison}
                  viewData={selectedTtgRecordData.prison}
                  dropdownLabel={strings.form.label.prison}
                  dropdownName="prison"
                  dropdownOptions={prisonOptions}
                  dropdownPlaceholder={strings.form.placeholder.prison}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.prison}
                  mode={mode}
                />
                {selectedTtgRecordData.prison ===
                  strings.form.dropDownOptions.other && (
                  <ValidatedFormInput
                    formInvalid={formInvalid}
                    inputData={selectedTtgRecordData.otherPrison}
                    inputLabel={strings.form.label.otherPrison}
                    inputName="otherPrison"
                    inputPlaceholder={strings.form.placeholder.otherPrison}
                    formFieldWidth={8}
                    handleChange={handleChange}
                    validationResult={validationResults.otherPrison}
                    mode={mode}
                  />
                )}
              </Form.Group>
              <Form.Group>
                <ValidatedFormDropdown
                  formInvalid={formInvalid}
                  inputData={selectedTtgRecordData.customerLocalAuthorityArea}
                  viewData={selectedTtgRecordData.customerLocalAuthorityArea}
                  dropdownLabel={strings.form.label.customerLocalAuthorityArea}
                  dropdownName="customerLocalAuthorityArea"
                  dropdownOptions={customerLocalAuthorityAreaOptions}
                  dropdownPlaceholder={
                    strings.form.placeholder.customerLocalAuthorityArea
                  }
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={
                    validationResults.customerLocalAuthorityArea
                  }
                  mode={mode}
                  required={false}
                />
                {selectedTtgRecordData.customerLocalAuthorityArea ===
                  strings.form.text.other && (
                  <ValidatedFormInput
                    formInvalid={formInvalid}
                    inputData={
                      selectedTtgRecordData.otherCustomerLocalAuthorityArea
                    }
                    inputLabel={
                      strings.form.label.otherCustomerLocalAuthorityArea
                    }
                    inputName="otherCustomerLocalAuthorityArea"
                    inputPlaceholder={
                      strings.form.placeholder.otherCustomerLocalAuthorityArea
                    }
                    formFieldWidth={8}
                    handleChange={handleChange}
                    validationResult={
                      validationResults.otherCustomerLocalAuthorityArea
                    }
                    mode={mode}
                  />
                )}
              </Form.Group>
              <Form.Group>
                <ValidatedFormDropdown
                  formInvalid={formInvalid}
                  inputData={selectedTtgRecordData.consent}
                  viewData={selectedTtgRecordData.consent}
                  dropdownLabel={strings.form.label.consent}
                  dropdownName="consent"
                  dropdownOptions={yesNoOptions}
                  dropdownPlaceholder={strings.form.placeholder.consent}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.consent}
                  mode={mode}
                  required={false}
                />
                <ValidatedFormDropdown
                  formInvalid={formInvalid}
                  inputData={selectedTtgRecordData.goodNewsStories}
                  viewData={selectedTtgRecordData.goodNewsStories}
                  dropdownLabel={strings.form.label.goodNewsStories}
                  dropdownName="goodNewsStories"
                  dropdownOptions={yesNoOptions}
                  dropdownPlaceholder={strings.form.placeholder.goodNewsStories}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.goodNewsStories}
                  mode={mode}
                  required={false}
                />
              </Form.Group>
              <Form.Group>
                <ValidatedFormDropdown
                  formInvalid={formInvalid}
                  inputData={selectedTtgRecordData.doNotApproach}
                  viewData={selectedTtgRecordData.doNotApproach}
                  dropdownLabel={strings.form.label.doNotApproach}
                  dropdownName="doNotApproach"
                  dropdownOptions={yesNoOptions}
                  dropdownPlaceholder={strings.form.placeholder.doNotApproach}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.doNotApproach}
                  mode={mode}
                  required={false}
                />
                {selectedTtgRecordData.doNotApproach ===
                  strings.form.text.yes && (
                  <ValidatedFormInput
                    formInvalid={formInvalid}
                    inputData={selectedTtgRecordData.whoNotApproach}
                    inputLabel={strings.form.label.whoNotApproach}
                    inputName="whoNotApproach"
                    inputPlaceholder={strings.form.placeholder.whoNotApproach}
                    formFieldWidth={8}
                    handleChange={handleChange}
                    validationResult={validationResults.whoNotApproach}
                    mode={mode}
                  />
                )}
              </Form.Group>
              <Form.Group>
                <ValidatedFormDropdown
                  formInvalid={formInvalid}
                  inputData={selectedTtgRecordData.latestRiskRating}
                  viewData={selectedTtgRecordData.latestRiskRating}
                  dropdownLabel={strings.form.label.latestRiskRating}
                  dropdownName="latestRiskRating"
                  dropdownOptions={latestRiskRatingOptions}
                  dropdownPlaceholder={
                    strings.form.placeholder.latestRiskRating
                  }
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.latestRiskRating}
                  mode={mode}
                  required={
                    selectedTtgRecordData.markerRecord === strings.form.text.no
                  }
                />
                <ValidatedFormDropdown
                  formInvalid={formInvalid}
                  inputData={selectedTtgRecordData.areasOfNeedIdentified}
                  viewData={selectedTtgRecordData.areasOfNeedIdentified}
                  dropdownLabel={strings.form.label.areasOfNeedIdentified}
                  dropdownName="areasOfNeedIdentified"
                  dropdownOptions={yesNoOptions}
                  dropdownPlaceholder={
                    strings.form.placeholder.areasOfNeedIdentified
                  }
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.areasOfNeedIdentified}
                  mode={mode}
                  required={false}
                />
              </Form.Group>
              {selectedTtgRecordData.areasOfNeedIdentified ===
                strings.form.text.yes && (
                <>
                  <Form.Group>
                    <ValidatedFormDropdown
                      formInvalid={formInvalid}
                      inputData={selectedTtgRecordData.accommodationNeed}
                      viewData={selectedTtgRecordData.accommodationNeed}
                      dropdownLabel={strings.form.label.accommodationNeed}
                      dropdownName="accommodationNeed"
                      dropdownOptions={yesNoOptions}
                      dropdownPlaceholder={
                        strings.form.placeholder.accommodationNeed
                      }
                      formFieldWidth={8}
                      handleChange={handleChange}
                      validationResult={validationResults.accommodationNeed}
                      mode={mode}
                      required={false}
                    />
                    <ValidatedDateInput
                      inputId="accommodationNeedDate"
                      formInvalid={formInvalid}
                      inputLabel={strings.form.label.accommodationNeedDate}
                      inputName="accommodationNeedDate"
                      inputPlaceholder={
                        strings.form.placeholder.accommodationNeedDate
                      }
                      inputDateFormat="DD/MM/YYYY"
                      inputData={
                        selectedTtgRecordData.accommodationNeedDate
                          ? convertFromIso(
                              selectedTtgRecordData.accommodationNeedDate
                            )
                          : ""
                      }
                      iconPosition="left"
                      handleChange={handleChange}
                      validationResult={validationResults.accommodationNeedDate}
                      formFieldWidth={8}
                      mode={mode}
                      required={false}
                    />
                  </Form.Group>
                  <Form.Group>
                    <ValidatedFormDropdown
                      formInvalid={formInvalid}
                      inputData={
                        selectedTtgRecordData.financeBenefitAndDebtNeed
                      }
                      viewData={selectedTtgRecordData.financeBenefitAndDebtNeed}
                      dropdownLabel={
                        strings.form.label.financeBenefitAndDebtNeed
                      }
                      dropdownName="financeBenefitAndDebtNeed"
                      dropdownOptions={yesNoOptions}
                      dropdownPlaceholder={
                        strings.form.placeholder.financeBenefitAndDebtNeed
                      }
                      formFieldWidth={8}
                      handleChange={handleChange}
                      validationResult={
                        validationResults.financeBenefitAndDebtNeed
                      }
                      mode={mode}
                      required={false}
                    />
                    <ValidatedDateInput
                      inputId="financeBenefitAndDebtNeedDate"
                      formInvalid={formInvalid}
                      inputLabel={
                        strings.form.label.financeBenefitAndDebtNeedDate
                      }
                      inputName="financeBenefitAndDebtNeedDate"
                      inputPlaceholder={
                        strings.form.placeholder.financeBenefitAndDebtNeedDate
                      }
                      inputDateFormat="DD/MM/YYYY"
                      inputData={
                        selectedTtgRecordData.financeBenefitAndDebtNeedDate
                          ? convertFromIso(
                              selectedTtgRecordData.financeBenefitAndDebtNeedDate
                            )
                          : ""
                      }
                      iconPosition="left"
                      handleChange={handleChange}
                      validationResult={
                        validationResults.financeBenefitAndDebtNeedDate
                      }
                      formFieldWidth={8}
                      mode={mode}
                      required={false}
                    />
                  </Form.Group>
                  <Form.Group>
                    <ValidatedFormDropdown
                      formInvalid={formInvalid}
                      inputData={
                        selectedTtgRecordData.educationTrainingEmploymentNeed
                      }
                      viewData={
                        selectedTtgRecordData.educationTrainingEmploymentNeed
                      }
                      dropdownLabel={
                        strings.form.label.educationTrainingEmploymentNeed
                      }
                      dropdownName="educationTrainingEmploymentNeed"
                      dropdownOptions={yesNoOptions}
                      dropdownPlaceholder={
                        strings.form.placeholder.educationTrainingEmploymentNeed
                      }
                      formFieldWidth={8}
                      handleChange={handleChange}
                      validationResult={
                        validationResults.educationTrainingEmploymentNeed
                      }
                      mode={mode}
                      required={false}
                    />
                    <ValidatedDateInput
                      inputId="educationTrainingEmploymentNeedDate"
                      formInvalid={formInvalid}
                      inputLabel={
                        strings.form.label.educationTrainingEmploymentNeedDate
                      }
                      inputName="educationTrainingEmploymentNeedDate"
                      inputPlaceholder={
                        strings.form.placeholder
                          .educationTrainingEmploymentNeedDate
                      }
                      inputDateFormat="DD/MM/YYYY"
                      inputData={
                        selectedTtgRecordData.educationTrainingEmploymentNeedDate
                          ? convertFromIso(
                              selectedTtgRecordData.educationTrainingEmploymentNeedDate
                            )
                          : ""
                      }
                      iconPosition="left"
                      handleChange={handleChange}
                      validationResult={
                        validationResults.educationTrainingEmploymentNeedDate
                      }
                      formFieldWidth={8}
                      mode={mode}
                      required={false}
                    />
                  </Form.Group>
                  <Form.Group>
                    <ValidatedFormDropdown
                      formInvalid={formInvalid}
                      inputData={selectedTtgRecordData.domesticAbuseNeed}
                      viewData={selectedTtgRecordData.domesticAbuseNeed}
                      dropdownLabel={strings.form.label.domesticAbuseNeed}
                      dropdownName="domesticAbuseNeed"
                      dropdownOptions={yesNoOptions}
                      dropdownPlaceholder={
                        strings.form.placeholder.domesticAbuseNeed
                      }
                      formFieldWidth={8}
                      handleChange={handleChange}
                      validationResult={validationResults.domesticAbuseNeed}
                      mode={mode}
                      required={false}
                    />
                    <ValidatedDateInput
                      inputId="domesticAbuseNeedDate"
                      formInvalid={formInvalid}
                      inputLabel={strings.form.label.domesticAbuseNeedDate}
                      inputName="domesticAbuseNeedDate"
                      inputPlaceholder={
                        strings.form.placeholder.domesticAbuseNeedDate
                      }
                      inputDateFormat="DD/MM/YYYY"
                      inputData={
                        selectedTtgRecordData.domesticAbuseNeedDate
                          ? convertFromIso(
                              selectedTtgRecordData.domesticAbuseNeedDate
                            )
                          : ""
                      }
                      iconPosition="left"
                      handleChange={handleChange}
                      validationResult={validationResults.domesticAbuseNeedDate}
                      formFieldWidth={8}
                      mode={mode}
                      required={false}
                    />
                  </Form.Group>
                  <Form.Group>
                    <ValidatedFormDropdown
                      formInvalid={formInvalid}
                      inputData={selectedTtgRecordData.sexWorkNeed}
                      viewData={selectedTtgRecordData.sexWorkNeed}
                      dropdownLabel={strings.form.label.sexWorkNeed}
                      dropdownName="sexWorkNeed"
                      dropdownOptions={yesNoOptions}
                      dropdownPlaceholder={strings.form.placeholder.sexWorkNeed}
                      formFieldWidth={8}
                      handleChange={handleChange}
                      validationResult={validationResults.sexWorkNeed}
                      mode={mode}
                      required={false}
                    />
                    <ValidatedDateInput
                      inputId="sexWorkNeedDate"
                      formInvalid={formInvalid}
                      inputLabel={strings.form.label.sexWorkNeedDate}
                      inputName="sexWorkNeedDate"
                      inputPlaceholder={
                        strings.form.placeholder.sexWorkNeedDate
                      }
                      inputDateFormat="DD/MM/YYYY"
                      inputData={
                        selectedTtgRecordData.sexWorkNeedDate
                          ? convertFromIso(
                              selectedTtgRecordData.sexWorkNeedDate
                            )
                          : ""
                      }
                      iconPosition="left"
                      handleChange={handleChange}
                      validationResult={validationResults.sexWorkNeedDate}
                      formFieldWidth={8}
                      mode={mode}
                      required={false}
                    />
                  </Form.Group>
                  <Form.Group>
                    <ValidatedFormDropdown
                      formInvalid={formInvalid}
                      inputData={selectedTtgRecordData.otherNeed}
                      viewData={selectedTtgRecordData.otherNeed}
                      dropdownLabel={strings.form.label.otherNeed}
                      dropdownName="otherNeed"
                      dropdownOptions={yesNoOptions}
                      dropdownPlaceholder={strings.form.placeholder.otherNeed}
                      formFieldWidth={8}
                      handleChange={handleChange}
                      validationResult={validationResults.otherNeed}
                      mode={mode}
                      required={false}
                    />
                    {selectedTtgRecordData.otherNeed ===
                      strings.form.text.yes && (
                      <ValidatedFormInput
                        formInvalid={formInvalid}
                        inputData={selectedTtgRecordData.otherNeedIdentified}
                        inputLabel={strings.form.label.otherNeedIdentified}
                        inputName="otherNeedIdentified"
                        inputPlaceholder={
                          strings.form.placeholder.otherNeedIdentified
                        }
                        formFieldWidth={8}
                        handleChange={handleChange}
                        validationResult={validationResults.otherNeedIdentified}
                        mode={mode}
                        required={false}
                      />
                    )}
                  </Form.Group>

                  <Form.Group>
                    <ValidatedDateInput
                      inputId="otherNeedDate"
                      formInvalid={formInvalid}
                      inputLabel={strings.form.label.otherNeedDate}
                      inputName="otherNeedDate"
                      inputPlaceholder={strings.form.placeholder.otherNeedDate}
                      inputDateFormat="DD/MM/YYYY"
                      inputData={
                        selectedTtgRecordData.otherNeedDate
                          ? convertFromIso(selectedTtgRecordData.otherNeedDate)
                          : ""
                      }
                      iconPosition="left"
                      handleChange={handleChange}
                      validationResult={validationResults.otherNeedDate}
                      formFieldWidth={8}
                      mode={mode}
                      required={false}
                    />
                  </Form.Group>
                </>
              )}
              <Form.Group>
                <ValidatedDateInput
                  inputId="resettlementPlanStarted"
                  formInvalid={formInvalid}
                  inputLabel={strings.form.label.resettlementPlanStarted}
                  inputName="resettlementPlanStarted"
                  inputPlaceholder={
                    strings.form.placeholder.resettlementPlanStarted
                  }
                  inputDateFormat="DD/MM/YYYY"
                  inputData={
                    selectedTtgRecordData.resettlementPlanStarted
                      ? convertFromIso(
                          selectedTtgRecordData.resettlementPlanStarted
                        )
                      : ""
                  }
                  iconPosition="left"
                  handleChange={handleChange}
                  validationResult={validationResults.resettlementPlanStarted}
                  formFieldWidth={8}
                  mode={mode}
                  required={false}
                />
                <ValidatedDateInput
                  inputId="resettlementPlanCompleted"
                  formInvalid={formInvalid}
                  inputLabel={strings.form.label.resettlementPlanCompleted}
                  inputName="resettlementPlanCompleted"
                  inputPlaceholder={
                    strings.form.placeholder.resettlementPlanCompleted
                  }
                  inputDateFormat="DD/MM/YYYY"
                  inputData={
                    selectedTtgRecordData.resettlementPlanCompleted
                      ? convertFromIso(
                          selectedTtgRecordData.resettlementPlanCompleted
                        )
                      : ""
                  }
                  iconPosition="left"
                  handleChange={handleChange}
                  validationResult={validationResults.resettlementPlanCompleted}
                  formFieldWidth={8}
                  mode={mode}
                  required={false}
                />
              </Form.Group>
            </>
          )}

          <Form.Group>
            <ValidatedFormTextArea
              formInvalid={formInvalid}
              inputData={selectedTtgRecordData.notes}
              inputLabel={strings.form.label.notes}
              inputName="notes"
              inputPlaceholder={strings.form.placeholder.notes}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.notes}
              mode={mode}
              required={false}
            />
          </Form.Group>
        </div>
      </>
    );
  };

  render = () => {
    const { mode, pageTitle, requestStatus, formInvalid } = this.props;

    return (
      <div className="container">
        <ComponentHeader
          returnToList={this.returnToList}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderEditButton()}
          cancelSaveButtons={this.renderCancelSaveButton()}
          auditLog={this.renderAuditButton()}
          deleteButton={this.renderDeleteButton()}
          headerList={strings.header.ttgRecordList}
          permissionsUpdate={permissions.programmes.update}
          permissionsCreate={permissions.programmes.create}
          permissionsDelete={permissions.programmes.delete}
          formInvalid={formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />
        {this.props.showAuditLog ? (
          <AuditListContainer
            embedded={true}
            headers={this.props.headers}
            history={this.props.history}
            entityId={
              this.props.selectedTtgRecordData.dummyId ||
              this.props.selectedTtgRecordData.ttgProgrammeId
            }
            hideSearchBar={true}
            record={true}
          />
        ) : (
          <Form id="ttgRecordForm" className="infoBlock stickyForm">
            <fieldset disabled={mode === "view"}>{this.renderForm()}</fieldset>
          </Form>
        )}
      </div>
    );
  };
}

ModifyTtgRecord.propTypes = {
  deleteSuccessful: PropTypes.bool,
  enableDisableRecordModalOpen: PropTypes.bool,
  filesHaveChanged: PropTypes.func,
  handleModeSwitch: PropTypes.func,
  hasFileChanges: PropTypes.bool,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  shouldSaveFiles: PropTypes.bool,
  toggleEnableDisableRecordModalVisibility: PropTypes.func,
  userList: PropTypes.array,

  mode: PropTypes.string.isRequired,
  selectedTtgRecordData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  initialTtgRecordData: PropTypes.object.isRequired,
  deletePermissionStatus: PropTypes.string,
  handleModalInputChange: PropTypes.func,
  deletionReason: PropTypes.object
};

export default withRouter(ModifyTtgRecord);
