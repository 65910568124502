import React from "react";
import { Form } from "semantic-ui-react";
import {
  strings,
  categoryOptions,
  stageOfReferralOptions,
  providerOptions
} from "../resources";
import {
  ValidatedFormInput,
  ValidatedDateInput,
  ValidatedFormDropdown,
  ValidatedFormTextArea
} from "./validation";
import { convertFromIso, dropdownHelper } from "../helpers";
import FormBoxWithHeader from "../../App/components/FormBoxWithHeader";

export default function SupportReferralCreationForm({
  formInvalid,
  selectedSupportReferralData,
  handleChange,
  validationResults,
  mode,
  getOrganisations
}) {
  return (
    <FormBoxWithHeader header={strings.form.header.supportReferralDetails}>
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={formInvalid}
          inputData={
            selectedSupportReferralData && selectedSupportReferralData.owner
          }
          viewData={
            dropdownHelper.getNameFromValues(
              selectedSupportReferralData.owner,
              getOrganisations()
            ) || ""
          }
          dropdownLabel={strings.form.label.owner}
          dropdownName="owner"
          dropdownDisabled
          dropdownOptions={getOrganisations() || []}
          dropdownPlaceholder={strings.form.placeholder.owner}
          formFieldWidth={8}
          handleChange={handleChange}
          validationResult={validationResults.owner}
          mode={mode}
        />
      </Form.Group>

      <Form.Group>
        <ValidatedFormInput
          formInvalid={formInvalid}
          inputData={selectedSupportReferralData.firstName}
          viewData={selectedSupportReferralData.firstName}
          inputLabel={strings.form.label.firstName}
          inputName="firstName"
          inputPlaceholder={strings.form.placeholder.firstName}
          formFieldWidth={8}
          handleChange={handleChange}
          validationResult={validationResults.firstName}
          disabled
          mode={mode}
          required={false}
        />
        <ValidatedFormInput
          formInvalid={formInvalid}
          inputData={selectedSupportReferralData.surname}
          viewData={selectedSupportReferralData.surname}
          inputLabel={strings.form.label.surname}
          inputName="surname"
          inputPlaceholder={strings.form.placeholder.surname}
          formFieldWidth={8}
          handleChange={handleChange}
          validationResult={validationResults.surname}
          required={false}
          disabled
          mode={mode}
        />
      </Form.Group>

      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={formInvalid}
          inputData={selectedSupportReferralData.category}
          viewData={selectedSupportReferralData.category}
          dropdownLabel={strings.form.label.category}
          dropdownName="category"
          dropdownOptions={categoryOptions}
          dropdownPlaceholder={strings.form.placeholder.category}
          formFieldWidth={8}
          handleChange={handleChange}
          validationResult={validationResults.category}
          mode={mode}
        />
        <ValidatedFormDropdown
          formInvalid={formInvalid}
          inputData={selectedSupportReferralData.stageOfReferral}
          viewData={selectedSupportReferralData.stageOfReferral}
          dropdownLabel={strings.form.label.stageOfReferral}
          dropdownName="stageOfReferral"
          dropdownOptions={stageOfReferralOptions}
          dropdownPlaceholder={strings.form.placeholder.stageOfReferral}
          formFieldWidth={8}
          handleChange={handleChange}
          validationResult={validationResults.stageOfReferral}
          mode={mode}
        />
      </Form.Group>

      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={formInvalid}
          inputData={selectedSupportReferralData.provider}
          viewData={
            dropdownHelper.getNameFromValues(
              selectedSupportReferralData.provider,
              providerOptions
            ) || ""
          }
          dropdownLabel={strings.form.label.provider}
          dropdownName="provider"
          dropdownOptions={providerOptions}
          dropdownPlaceholder={strings.form.placeholder.provider}
          formFieldWidth={8}
          handleChange={handleChange}
          validationResult={validationResults.provider}
          mode={mode}
        />
        <ValidatedDateInput
          inputId="dateReferred"
          formInvalid={formInvalid}
          inputLabel={strings.form.label.dateReferred}
          inputName="dateReferred"
          inputPlaceholder={strings.form.placeholder.dateReferred}
          inputDateFormat="DD/MM/YYYY"
          inputData={
            selectedSupportReferralData.dateReferred
              ? convertFromIso(selectedSupportReferralData.dateReferred)
              : ""
          }
          iconPosition="left"
          required
          formFieldWidth={8}
          handleChange={handleChange}
          validationResult={validationResults.dateReferred}
          mode={mode}
        />
      </Form.Group>

      <Form.Group>
        <ValidatedFormTextArea
          formInvalid={formInvalid}
          inputData={selectedSupportReferralData.reasonForReferral}
          inputLabel={strings.form.label.reasonForReferral}
          inputName="reasonForReferral"
          inputPlaceholder={strings.form.placeholder.reasonForReferral}
          formFieldWidth={8}
          handleChange={handleChange}
          validationResult={validationResults.reasonForReferral}
          mode={mode}
        />
      </Form.Group>
    </FormBoxWithHeader>
  );
}
