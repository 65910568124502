const initialNeedFields = {
  Accommodation: {},
  Finance: {},
  "Employment, Education & Training": {},
  "Victims of Domestic Abuse": {},
  "Sex Worker": {},
  Other: {}
};

const needFieldKeys = [
  "outcomeOfAreaOfNeed",
  "dateOfPositiveOutcome",
  "dateOfNeutralOutcome",
  "followUpDate",
  "reasonForOutcome"
];

export const areaOfNeedObjects = {
  initialNeedFields,
  needFieldKeys
};
