import React from "react";
import ModifyUser from "../../components/ModifyUser";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { userActions } from "../../store/actions/user.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import PropTypes from "prop-types";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  getOrganisations,
  allowNumericalInput,
  convertToIso
} from "../../helpers";

class UserCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    // Required to remove error since enable/disable still present even
    // in create mode, just not visisble.
    enableDisableUserModalOpen: false,
    formInvalid: false,
    validationResults: {},
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    prisons: [],
    localAuthorityAreas: [],
    roleArea: ""
  };

  componentDidMount = async () => {
    const sCrit = this.state.searchCriteria;
    this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    let form = document.getElementById("userForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
  };

  componentWillUnmount() {
    this.props.clearSelectedUser();
  }

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedUserData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.users.createUser
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    await this.props.createUser(
      this.props.selectedUserData,
      this.props.headers
    );
    this.props.history.push("/users");
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearUserRequest();
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (data.name === "roleId") {
      await this.props.updateSelectedUser("caseloadMax", "");
      await this.props.updateSelectedUser("prison", "");
      await this.props.updateSelectedUser("area", "");

      const selectedRole = data.options.find(
        option => option.value === data.value
      );
      if (selectedRole && selectedRole.area) {
        await this.props.updateSelectedUser("area", selectedRole.area);

        this.setState({ roleArea: selectedRole.area });
      } else {
        this.setState({ roleArea: "" });
      }
    }
    if (data.name === "organisationId") {
      this.calculatePrisons(data.value);
      await this.props.updateSelectedUser("prison", "");
      this.calculateLocalAuthorityAreas(data.value);
      await this.props.updateSelectedUser("localAuthorityArea", "");
    }
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedUser(data.name, convertToIso(data.value));
    } else {
      await this.props.updateSelectedUser(data.name, data.value);
    }
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableUserModalVisibility = () => {
    this.setState({
      enableDisableUserModalOpen: !this.state.enableDisableUserModalOpen
    });
  };

  calculatePrisons = organisationId => {
    let prisonList;
    let dropdownFields = [];
    this.props.organisationListData.forEach(org => {
      if (this.hasValue(org["_source"], "organisationId", organisationId)) {
        prisonList = org["_source"].prisons;
      }
    });
    if (prisonList) {
      prisonList.forEach((prison, key) => {
        dropdownFields.push({
          key: key,
          text: prison,
          value: prison
        });
      });
    }
    this.setState({ prisons: dropdownFields });
  };

  calculateLocalAuthorityAreas = organisationId => {
    let areaList;
    let dropdownFields = [];
    this.props.organisationListData.forEach(org => {
      if (this.hasValue(org["_source"], "organisationId", organisationId)) {
        areaList = org["_source"].localAuthorityArea;
      }
    });
    if (areaList) {
      areaList.forEach((area, key) => {
        dropdownFields.push({
          key: key,
          text: area,
          value: area
        });
      });
    }
    this.setState({ localAuthorityAreas: dropdownFields });
  };

  hasValue = (obj, key, value) => {
    return obj.hasOwnProperty(key) && obj[key] === value;
  };

  render = () => {
    const loading = this.props.loadingPage || this.props.orgsLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <ModifyUser
            mode="create"
            role={this.props.roleId}
            pageTitle={strings.header.createUser}
            selectedUserData={this.props.selectedUserData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            enableDisableUserModalOpen={this.state.enableDisableUserModalOpen}
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableUserModalVisibility={
              this.toggleEnableDisableUserModalVisibility
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            initialUserData={{}}
            organisationList={getOrganisations(this.props.organisationListData)}
            allowNumericalInput={allowNumericalInput}
            prisonOptions={this.state.prisons}
            localAuthorityAreaOptions={this.state.localAuthorityAreas}
            roleArea={this.state.roleArea}
          />
        </div>
      </div>
    );
  };
}

UserCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedUserData: PropTypes.object.isRequired,
  updateSelectedUser: PropTypes.func.isRequired,
  clearSelectedUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { loadingPage, selectedUserData } = state.users;
  const { roleId } = state.auth;
  const {
    organisationListData,
    loadingPage: orgsLoadingPage
  } = state.organisations;
  return {
    loadingPage,
    selectedUserData,
    roleId,
    organisationListData,
    orgsLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createUser: (data, headers) => {
      dispatch(userActions.createUser(data, headers));
    },
    updateSelectedUser: (key, value) => {
      dispatch(userActions.updateSelectedUser(key, value));
    },
    clearSelectedUser: () => {
      dispatch(userActions.clearSelectedUser());
    },
    clearUserRequest: () => {
      dispatch(userActions.clearUserRequest());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCreateContainer);
