import { auditActionTypes } from "../actions/audit.actionTypes";

const initialState = {
  loadingPage: false,
  auditListData: [{}],
  totalResults: 0,
  result: "",
  error: "",
  errorDetails: {},
  auditRequestStatus: undefined,
  auditRequestMade: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case auditActionTypes.CREATE_AUDIT_REQUEST:
      return {
        ...state,
        auditRequestStatus: undefined,
        auditRequestMade: true
      };
    case auditActionTypes.CREATE_AUDIT_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        auditRequestStatus: true
      };
    case auditActionTypes.CREATE_AUDIT_FAILURE:
      let details = action.error;

      return {
        ...state,
        error: action.error,
        errorDetails: details,
        auditRequestStatus: false
      };

    case auditActionTypes.GET_AUDITS_REQUEST:
      return {
        ...state,
        loadingPage: true,
        deleteSuccessful: false
      };
    case auditActionTypes.GET_AUDITS_SUCCESS:
      let rawHits = [];
      let newTotal = 0;
      if (action.response.data.Body.logs) {
        newTotal = action.response.data.Body.total;
        rawHits = action.response.data.Body.logs.slice();
        rawHits.forEach((result, index) => {
          let newAudit = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newAudit;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        auditListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case auditActionTypes.GET_AUDITS_FAILURE:
      return {
        ...state,
        auditListData: [{}],
        loadingPage: false
      };

    default:
      return state;
  }
};
