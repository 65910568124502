import React from "react";
import ReactTable from "react-table";
import { TableFunctions } from ".";
import { strings, outcomeOptions } from "../resources";
import PropTypes from "prop-types";
import moment from "moment";
import { permissions } from "../../App/resources";
import { dropdownHelper, getUsers } from "../helpers/index";

class TableComponent extends React.Component {
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      if (column.id !== "delete" && column.id !== "active") {
        return {
          onClick: () => {
            if (rowInfo) {
              this.props.history.push(
                "/assurances/view/" + rowInfo.original.feedbackId
              );
            }
          }
        };
      }
    }
    return {}; // getTdProps requires a return statement
  };

  mapSearchResultsToRows = () => {
    const { assuranceListData } = this.props;
    let data = [];
    if (assuranceListData) {
      assuranceListData.forEach((result, index) => {
        if (result["_source"]) {
          data.push(result["_source"]);
        }
      });
    }
    return data;
  };

  render = () => {
    let data = this.mapSearchResultsToRows();
    let pages = Math.ceil(this.props.totalResults / this.props.pageSize);
    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };

    const columns = [
      {
        id: "byWhom",
        Header: strings.table.header.byWhom,
        accessor: assurance => {
          return (
            dropdownHelper.getNameFromValues(
              assurance.byWhom,
              getUsers(this.props.userListData)
            ) || ""
          );
        },
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(30)
      },
      {
        Header: strings.table.header.type,
        accessor: "type",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(50)
      },
      {
        id: "outcome",
        Header: strings.table.header.outcome,
        accessor: assurance => {
          return (
            dropdownHelper.getNameFromValues(
              assurance.outcome,
              outcomeOptions
            ) || ""
          );
        },
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(50)
      },
      {
        id: "dateRaised",
        Header: strings.table.header.dateRaised,
        accessor: assurance =>
          moment(assurance.dateRaised).local().format("DD/MM/YYYY"),
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      }
    ];
    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          handleRefresh={this.props.handleRefresh}
          headerList={strings.header.assuranceList}
          buttonAddLabel={strings.button.addAssuranceLabel}
          permissionsCreate={permissions.assurances.create}
          headerClass="pageHeader"
          refreshButtonClass="refreshButton"
          addButtonClass="addButton"
          addButtonLink={"/assurances/create/"}
          searchBarQuery={this.props.searchBarQuery}
          handleChange={this.props.handleChange}
          handleSubmit={this.props.handleSubmit}
          isCreateDisabled={this.props.isCreateDisabled}
        />
        <div>
          <ReactTable
            manual
            data={data}
            pages={pages}
            defaultPageSize={this.props.pageSize}
            showPagination={true}
            getTdProps={this.getTdProps}
            onFetchData={(state, instance) => {
              this.props.handleFetchData(state);
              this.props.updatePageIndex(state);
            }}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText={strings.table.text.noResults}
            previousText="<"
            nextText=">"
            pageText=""
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  handleModalConfirm: PropTypes.func.isRequired,
  assuranceListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  updatePageIndex: PropTypes.func.isRequired,
  isCreateDisabled: PropTypes.bool.isRequired
};

export default TableComponent;
