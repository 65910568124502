export const complaintActionTypes = {
  CREATE_COMPLAINT_REQUEST: "CREATE_COMPLAINT_REQUEST",
  CREATE_COMPLAINT_SUCCESS: "CREATE_COMPLAINT_SUCCESS",
  CREATE_COMPLAINT_FAILURE: "CREATE_COMPLAINT_FAILURE",

  CLEAR_CREATE_COMPLAINT_REQUEST: "CLEAR_CREATE_COMPLAINT_REQUEST",
  CLEAR_CREATE_COMPLAINT_FAILURE: "CLEAR_CREATE_COMPLAINT_FAILURE",
  CLEAR_CREATE_COMPLAINT_SUCCESS: "CLEAR_CREATE_COMPLAINT_SUCCESS",

  DELETE_COMPLAINT_REQUEST: "DELETE_COMPLAINT_REQUEST",
  DELETE_COMPLAINT_SUCCESS: "DELETE_COMPLAINT_SUCCESS",
  DELETE_COMPLAINT_FAILURE: "DELETE_COMPLAINT_FAILURE",

  UPDATE_SELECTED_COMPLAINT_REQUEST: "UPDATE_SELECTED_COMPLAINT_REQUEST",
  UPDATE_SELECTED_COMPLAINT_SUCCESS: "UPDATE_SELECTED_COMPLAINT_SUCCESS",
  UPDATE_SELECTED_COMPLAINT_FAILURE: "UPDATE_SELECTED_COMPLAINT_FAILURE",

  CANCEL_SELECTED_COMPLAINT_UPDATE_REQUEST:
    "CANCEL_SELECTED_COMPLAINT_UPDATE_REQUEST",
  CANCEL_SELECTED_COMPLAINT_UPDATE_SUCCESS:
    "CANCEL_SELECTED_COMPLAINT_UPDATE_SUCCESS",
  CANCEL_SELECTED_COMPLAINT_UPDATE_FAILURE:
    "CANCEL_SELECTED_COMPLAINT_UPDATE_FAILURE",

  GET_COMPLAINTS_REQUEST: "GET_COMPLAINTS_REQUEST",
  GET_COMPLAINTS_SUCCESS: "GET_COMPLAINTS_SUCCESS",
  GET_COMPLAINTS_FAILURE: "GET_COMPLAINTS_FAILURE",

  UPDATE_COMPLAINT_REQUEST: "UPDATE_COMPLAINT_REQUEST",
  UPDATE_COMPLAINT_SUCCESS: "UPDATE_COMPLAINT_SUCCESS",
  UPDATE_COMPLAINT_FAILURE: "UPDATE_COMPLAINT_FAILURE",

  GET_COMPLAINT_BY_ID_REQUEST: "GET_COMPLAINT_BY_ID_REQUEST",
  GET_COMPLAINT_BY_ID_SUCCESS: "GET_COMPLAINT_BY_ID_SUCCESS",
  GET_COMPLAINT_BY_ID_FAILURE: "GET_COMPLAINT_BY_ID_FAILURE",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE",

  CLEAR_SELECTED_COMPLAINT_REQUEST: "CLEAR_SELECTED_COMPLAINT_REQUEST",
  CLEAR_SELECTED_COMPLAINT_SUCCESS: "CLEAR_SELECTED_COMPLAINT_SUCCESS"
};
