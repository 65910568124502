import React from "react";
import ModifyFeedback from "../../components/ModifyFeedback";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { feedbackActions } from "../../store/actions/feedback.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { settingActions } from "../../../Settings/store/actions/setting.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  convertToIso,
  getOrganisations,
  canUserModifyRecord,
  textFormattingHelpers
} from "../../helpers";

class FeedbackCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableFeedbackModalOpen: false,
    enableDisableLockModalOpen: false,
    enableDisableUnlockModalOpen: false,
    formInvalid: false,
    validationResults: {},
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    prePopulated: false,
    password: "",
    passwordError: false,
    errorDetails: "The password was incorrect",
    error: "Error",
    result: "",
    feedbackRequestStatus: false,
    feedbackRequestMade: true
  };

  componentDidMount = async () => {
    await this.handleFetchData();
    this.props.clearSelectedProgramme();
    let form = document.getElementById("feedbackForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    await this.props.getProgrammeById(
      localStorage.getItem("programmeId"),
      this.props.headers
    );
  };

  componentDidUpdate = () => {
    if (
      Object.keys(this.props.selectedProgrammeData).length > 0 &&
      !this.state.prePopulated
    ) {
      if (this.props.selectedProgrammeData.owner) {
        this.props.updateSelectedFeedback(
          "owner",
          this.props.selectedProgrammeData.owner
        );
      }
      if (this.props.selectedProgrammeData.programmeId) {
        this.props.updateSelectedFeedback(
          "programmeId",
          this.props.selectedProgrammeData.programmeId
        );
      }
      if (this.props.selectedProgrammeData.crmId) {
        this.props.updateSelectedFeedback(
          "programmeCrmId",
          this.props.selectedProgrammeData.crmId
        );
      }
      if (this.props.selectedProgrammeData.area) {
        this.props.updateSelectedFeedback(
          "area",
          this.props.selectedProgrammeData.area
        );
      }
      this.props.updateSelectedFeedback("feedbackType", "Feedback");
      this.setState({ prePopulated: true });
    }
  };

  componentWillUnmount() {
    this.props.clearSelectedFeedback();
  }

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedFeedbackData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.feedback.modifyFeedback
    );

    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    const data = textFormattingHelpers.decodeData(
      this.props.selectedFeedbackData,
      {},
      textFields
    );

    await this.props.createFeedback(data, this.props.headers);
    this.props.history.push("/feedback");
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearFeedbackRequest();
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedFeedback(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedFeedback(data.name, data.value);
    }
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableFeedbackModalVisibility = () => {
    this.setState({
      enableDisableFeedbackModalOpen: !this.state.enableDisableFeedbackModalOpen
    });
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  toggleLock = async type => {
    if (type === strings.fieldValues.handleToggleUnlock) {
      await this.props.checkSettingMatchesValue(
        this.state.password,
        "feedbackPassword",
        this.props.headers
      );
      if (this.props.settingMatches) {
        localStorage.setItem("feedbackLocked", "");
        this.setState({ passwordError: false });
      } else {
        this.setState({ passwordError: true });
      }
    } else {
      localStorage.setItem("feedbackLocked", true);
    }
  };

  toggleEnableDisableLockModalVisibility = () => {
    this.setState({
      enableDisableLockModalOpen: !this.state.enableDisableLockModalOpen
    });
  };

  toggleEnableDisableUnlockModalVisibility = () => {
    this.setState({
      enableDisableUnlockModalOpen: !this.state.enableDisableUnlockModalOpen
    });
  };

  handleUnlockModalInputChange = (e, value) => {
    this.setState({ password: value.value });
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.programmeLoadingPage ||
      this.props.checkingSetting;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          {this.state.passwordError && (
            <RequestFeedback
              requestStatus={this.state.feedbackRequestStatus}
              requestMade={this.state.feedbackRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.state.result}
              failureMessage={this.state.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.feedbackRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.state.errorDetails}
            />
          )}
          <ModifyFeedback
            mode="create"
            role={this.props.roleId}
            pageTitle={strings.header.createFeedback}
            selectedFeedbackData={this.props.selectedFeedbackData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            enableDisableFeedbackModalOpen={
              this.state.enableDisableFeedbackModalOpen
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableFeedbackModalVisibility={
              this.toggleEnableDisableFeedbackModalVisibility
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            initialFeedbackData={{}}
            getOrganisations={this.getOrganisations}
            updateSelectedFeedback={this.props.updateSelectedFeedback}
            isDisabled={this.isDisabled()}
            toggleLock={this.toggleLock}
            toggleEnableDisableLockModalVisibility={
              this.toggleEnableDisableLockModalVisibility
            }
            toggleEnableDisableUnlockModalVisibility={
              this.toggleEnableDisableUnlockModalVisibility
            }
            enableDisableLockModalOpen={this.state.enableDisableLockModalOpen}
            enableDisableUnlockModalOpen={
              this.state.enableDisableUnlockModalOpen
            }
            handleUnlockModalInputChange={this.handleUnlockModalInputChange}
            password={this.state.password}
          />
        </div>
      </div>
    );
  };
}

FeedbackCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedFeedbackData: PropTypes.object.isRequired,
  updateSelectedFeedback: PropTypes.func.isRequired,
  createFeedback: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { loadingPage, selectedFeedbackData } = state.feedback;
  const { roleId, organisationOfLoggedInUser } = state.auth;
  const {
    organisationListData,
    loadingPage: orgsLoadingPage
  } = state.organisations;
  const {
    selectedProgrammeData,
    programmeRequestStatus,
    loadingPage: programmeLoadingPage
  } = state.programmes;
  const { checkingSetting, settingMatches } = state.settings;
  return {
    loadingPage,
    selectedFeedbackData,
    roleId,
    organisationListData,
    selectedProgrammeData,
    programmeRequestStatus,
    organisationOfLoggedInUser,
    programmeLoadingPage,
    orgsLoadingPage,
    checkingSetting,
    settingMatches
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createFeedback: async (data, headers) => {
      await dispatch(feedbackActions.createFeedback(data, headers));
    },
    updateSelectedFeedback: (key, value) => {
      dispatch(feedbackActions.updateSelectedFeedback(key, value));
    },
    clearFeedbackRequest: () => {
      dispatch(feedbackActions.clearFeedbackRequest());
    },
    clearSelectedFeedback: () => {
      dispatch(feedbackActions.clearSelectedFeedback());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    },
    checkSettingMatchesValue: async (value, id, headers) => {
      await dispatch(
        settingActions.checkSettingMatchesValue(value, id, headers)
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackCreateContainer);
