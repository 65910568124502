import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      entity: "complaints",
      deletion: "deletion",
      fullName: "Complaint"
    },
    fieldValues: { other: "Other" },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    header: {
      login: "Login",
      complaintList: "Complaints",
      createComplaint: "New Complaint",
      editComplaint: "Edit Complaint",
      viewComplaint: "Complaint Details",
      loading: "Loading Information"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        administration: "Administration",
        status: "Status",
        complaintDetails: "Complaint Details",
        stage1: "Stage 1",
        stage2: "Stage 2",
        stage3: "Stage 3"
      },
      label: {
        crmId: "CRM ID",
        owner: "Owner",
        stage: "Stage",
        status: "Status",
        dateResolved: "Date Resolved",
        dateReceived: "Date Received",
        method: "Method",
        personReceivingStage1Complaint: "Person receiving Stage 1 complaint",
        categoryOfComplaint: "Category of complaint",
        detailsOfComplaint: "Details of complaint",
        additionalNotes: "Additional Notes",
        subjectOfComplaint: "Subject of complaint",
        stage1ResponseDate: "Stage 1 Response Date",
        stage1ResponseDetails: "Stage 1 Response Details",
        stage1ResponseProvidedBy: "Stage 1 Response Provided By",
        stage1ComplaintResolved: "Stage 1 Complaint Resolved",
        stage1ResolutionDetails: "Stage 1 Resolution Details",
        stage1Escalated: "Stage 1 Escalated?",
        stage1EscalatedTo: "Stage 1 Escalated To?",
        stage1EscalationDate: "Stage 1 Escalation Date",
        stage1AdditionalNotes: "Stage 1 Additional Notes",
        stage2DateAcknowledged: "Stage 2 Date Acknowledged",
        stage2AcknowledgementSentBy: "Stage 2 Acknowledgement Sent By",
        stage2ResponseDate: "Stage 2 Response Date",
        stage2ResponseDetails: "Stage 2 Response Details",
        stage2ResponseProvidedBy: "Stage 2 Response Provided By",
        stage2ComplaintResolved: "Stage 2 Complaint Resolved",
        stage2ResolutionDetails: "Stage 2 Resolution Details",
        stage2Escalated: "Stage 2 Escalated?",
        stage2EscalatedTo: "Stage 2 Escalated To?",
        stage2EscalationDate: "Stage 2 Escalation Date",
        stage2AdditionalNotes: "Stage 2 Additional Notes",
        stage3DateAcknowledged: "Stage 3 Date Acknowledged",
        stage3AcknowledgementSentBy: "Stage 3 Acknowledgement Sent By",
        stage3ResponseDate: "Stage 3 Response Date",
        stage3ResponseDetails: "Stage 3 Response Details",
        stage3ResponseProvidedBy: "Stage 3 Response Provided By",
        stage3ComplaintResolved: "Stage 3 Complaint Resolved",
        stage3ResolutionDetails: "Stage 3 Resolution Details",
        stage3AdditionalNotes: "Stage 3 Additional Notes"
      },
      placeholder: {
        stateReason: "State your reason...",
        crmId: "",
        owner: "- select -",
        stage: "",
        status: "",
        dateResolved: "Still to be resolved",
        dateReceived: "- select -",
        method: "- select -",
        personReceivingStage1Complaint: "- select -",
        categoryOfComplaint: "- select -",
        detailsOfComplaint: "",
        additionalNotes: "",
        subjectOfComplaint: "- select -",
        stage1ResponseDate: "- select -",
        stage1ResponseDetails: "",
        stage1ResponseProvidedBy: "- select -",
        stage1ComplaintResolved: "- select -",
        stage1ResolutionDetails: "",
        stage1Escalated: "- select -",
        stage1EscalatedTo: "- select -",
        stage1EscalationDate: "- select -",
        stage1AdditionalNotes: "",
        stage2DateAcknowledged: "- select -",
        stage2AcknowledgementSentBy: "- select -",
        stage2ResponseDate: "- select -",
        stage2ResponseDetails: "",
        stage2ResponseProvidedBy: "- select -",
        stage2ComplaintResolved: "- select -",
        stage2ResolutionDetails: "",
        stage2Escalated: "- select -",
        stage2EscalatedTo: "- select -",
        stage2EscalationDate: "- select -",
        stage2AdditionalNotes: "",
        stage3DateAcknowledged: "- select -",
        stage3AcknowledgementSentBy: "- select -",
        stage3ResponseDate: "- select -",
        stage3ResponseDetails: "",
        stage3ResponseProvidedBy: "- select -",
        stage3ComplaintResolved: "- select -",
        stage3ResolutionDetails: "",
        stage3AdditionalNotes: ""
      },
      feedback: {
        processing: "Processing request...",
        complaintRequestSuccess: "Complaint created successfully!",
        complaintRequestRefreshPrompt:
          "Use the refresh function to see an updated complaint list.",
        complaintRequestFailure: "Complaint creation failed.",
        complaintRequestUnknown:
          "Something went wrong with the complaint request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      fileUploads: {
        stage1FileUpload: "Upload files for stage 1",
        stage2FileUpload: "Upload files for stage 2",
        stage3FileUpload: "Upload files for stage 3"
      },
      text: {
        yes: "Yes",
        no: "No",
        stage: "stage",
        stage1Escalated: "stage1Escalated",
        stage2Escalated: "stage2Escalated",
        status: "status",
        resolved: "Resolved",
        open: "Open",
        initial: "Initial"
      }
    },
    modal: {
      createComplaintMessage: "Save complaint?",
      modifyComplaintMessage: "Are you sure you want to save these changes?",
      deleteComplaintMessage:
        "Are you sure you want to delete this complaint? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this complaint's status to active?",
      setInactiveMessage: "Set this complaint's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addComplaintLabel: "New Complaint",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    tooltip: {},
    table: {
      header: {
        crmId: "CRM ID",
        dateReceived: "Date Received",
        dateResolved: "Date Resolved",
        subjectOfComplaint: "Subject of Complaint",
        detailsOfComplaint: "Details of Complaint",
        personReceivingStage1Complaint: "Person Receiving Stage 1 Complaint",
        status: "Status",
        method: "Method",
        categoryOfComplaint: "CategoryOfComplaint"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    entity: {
      complaint: "complaint"
    },
    folder: {
      stage1: "stage1",
      stage2: "stage2",
      stage3: "stage3"
    },
    roles: {
      teamlead: "teamLead",
      manager: "manager",
      headOfOps: "hoo"
    }
  }
});
