import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import { strings } from "./resources";
import { PrivateRoute } from "./components/Navigation/PrivateRoute";
import { Login, Logout, PasswordReset } from "./containers/Login";
import {
  UserListContainer,
  UserEditContainer,
  UserCreateContainer
} from "../Users/containers";
import {
  CustomerListContainer,
  CustomerEditContainer
} from "../Customers/containers";
import {
  ProgrammeListContainer,
  ProgrammeEditContainer
} from "../Programmes/containers";
import { TaskListContainer, TaskEditContainer } from "../Tasks/containers";
import {
  ReferralListContainer,
  ReferralEditContainer,
  ReferralCreateContainer
} from "../Referrals/containers";
import { ReportEditContainer } from "../Reports/containers";
import {
  EngagementListContainer,
  EngagementEditContainer,
  EngagementCreateContainer
} from "../Engagements/containers";
import {
  AssessmentSGHandler,
  AssessmentSGCreateContainer,
  AssessmentSGEditContainer
} from "../AssessmentSG/containers";
import {
  BaselineHandler,
  BaselineEditContainer,
  BaselineCreateContainer
} from "../Baselines/containers";
import {
  ComplaintListContainer,
  ComplaintEditContainer,
  ComplaintCreateContainer
} from "../Complaints/containers";
import {
  OrganisationListContainer,
  OrganisationEditContainer,
  OrganisationCreateContainer
} from "../Organisations/containers";
import {
  ActivityListContainer,
  ActivityEditContainer,
  ActivityCreateContainer
} from "../Activities/containers";
import { OutcomeListContainer } from "../Outcomes/containers";
import {
  EmploymentListContainer,
  EmploymentCreateContainer,
  EmploymentEditContainer
} from "../Employments/containers";
import {
  CostListContainer,
  CostCreateContainer,
  CostEditContainer
} from "../Costs/containers";
import {
  NewsListContainer,
  NewsCreateContainer,
  NewsEditContainer
} from "../News/containers";
import { CaseloadListContainer } from "../Caseloads/containers";
import { HistoryListContainer } from "../History/containers";
import { AuditListContainer } from "../Audit/containers";
import {
  TtgProgrammeListContainer,
  TtgProgrammeEditContainer
} from "../TtgProgrammes/containers";
import {
  DummyRecordListContainer,
  DummyRecordEditContainer
} from "../DummyRecords/containers";
import {
  FeedbackListContainer,
  FeedbackEditContainer,
  FeedbackCreateContainer
} from "../Feedback/containers";
import {
  AreaOfNeedListContainer,
  AreaOfNeedEditContainer,
  AreaOfNeedCreateContainer
} from "../AreaOfNeed/containers";
import {
  TtgEngagementListContainer,
  TtgEngagementEditContainer,
  TtgEngagementCreateContainer
} from "../TtgEngagements/containers";
import {
  AssuranceListContainer,
  AssuranceCreateContainer,
  AssuranceEditContainer
} from "../Assurances/containers";
import {
  SupportReferralListContainer,
  SupportReferralEditContainer
} from "../SupportReferral/containers";
import { SettingsEditContainer } from "../Settings/containers";
import { TtgRecordCreateContainer } from "../TtgRecord/containers";
import OcrContainer from "../OCR/components/Ocr/OcrContainer";
import { permissions } from "./resources/AppPermissions";
import NotFoundPage from "./components/Navigation/NotFoundPage/NotFoundPage";

// Some routes will have commented out roles.
// This is simply since no such components exist yet so no point
// giving anyone access.
const Routes = props => {
  const screenLocked = localStorage.getItem("feedbackLocked");
  return (
    <Switch>
      <Route exact path="/login" render={props => <Login {...props} />} />
      <Route
        exact
        path="/reset-password"
        render={props =>
          props.isLoggedIn ? (
            <Redirect to="/mywork" />
          ) : (
            <PasswordReset {...props} />
          )
        }
      />
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/users/create"
          roles={permissions.users.create}
          component={UserCreateContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/users/view/:id"
          roles={permissions.users.read}
          component={UserEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/users"
          roles={permissions.users.read}
          component={UserListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/mywork/view/:id"
          roles={permissions.global}
          component={TaskEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/mywork/edit/:id"
          roles={permissions.global}
          component={TaskEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/mywork"
          roles={permissions.global}
          component={TaskListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/reports"
          roles={permissions.reports.read}
          component={ReportEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/audit"
          roles={permissions.audit}
          component={AuditListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/referrals/create"
          roles={permissions.referrals.create}
          component={ReferralCreateContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/referrals/view/:id"
          roles={permissions.referrals.read}
          component={ReferralEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/referrals/edit/:id"
          roles={permissions.referrals.update}
          component={ReferralEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/referrals"
          roles={permissions.referrals.read}
          component={ReferralListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/programmes/view/:id"
          roles={permissions.programmes.read}
          component={ProgrammeEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/programmes/edit/:id"
          roles={permissions.programmes.update}
          component={ProgrammeEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/programmes"
          roles={permissions.programmes.read}
          component={ProgrammeListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/engagements/create/:entityId/:entityType?/:engagementType?"
          roles={permissions.engagements.create}
          component={EngagementCreateContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/engagements/view/:id?/:referralId?"
          roles={permissions.engagements.read}
          component={EngagementEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/engagements"
          roles={permissions.engagements.read}
          component={EngagementListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/baselines/create/"
          roles={permissions.baselines.create}
          component={BaselineCreateContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/baselines/view/:id"
          roles={permissions.baselines.read}
          component={BaselineEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/baselines"
          roles={permissions.baselines.read}
          component={BaselineHandler}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/activities/create/:engagementId"
          roles={permissions.activities.create}
          component={ActivityCreateContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/activities/view/:referralId?/:id?"
          roles={permissions.activities.read}
          component={ActivityEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/activities"
          roles={permissions.activities.read}
          component={ActivityListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/outcomes"
          roles={permissions.programmes.read}
          component={OutcomeListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked &&
        props.injectedProps.area !== strings.areas.NRNE &&
        props.injectedProps.area !== strings.areas.TTG &&
        props.injectedProps.area !== strings.areas.NE && (
          <PrivateRoute
            exact
            path="/assessmentSG/create/"
            roles={permissions.assessmentSG.read}
            component={AssessmentSGCreateContainer}
            {...props.injectedProps}
          />
        )}
      {!screenLocked &&
        props.injectedProps.area !== strings.areas.NRNE &&
        props.injectedProps.area !== strings.areas.TTG &&
        props.injectedProps.area !== strings.areas.NE && (
          <PrivateRoute
            exact
            path="/assessmentSG/view:id"
            roles={permissions.assessmentSG.read}
            component={AssessmentSGEditContainer}
            {...props.injectedProps}
          />
        )}
      {!screenLocked &&
        props.injectedProps.area !== strings.areas.NRNE &&
        props.injectedProps.area !== strings.areas.TTG &&
        props.injectedProps.area !== strings.areas.NE && (
          <PrivateRoute
            exact
            path="/assessmentSG"
            roles={permissions.assessmentSG.read}
            component={AssessmentSGHandler}
            {...props.injectedProps}
          />
        )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/employments/view/:id"
          roles={permissions.employments.read}
          component={EmploymentEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/employments/create/"
          roles={permissions.employments.create}
          component={EmploymentCreateContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/employments"
          roles={permissions.employments.read}
          component={EmploymentListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/costs/view/:id"
          roles={permissions.costs.read}
          component={CostEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/costs/create/"
          roles={permissions.costs.create}
          component={CostCreateContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/costs"
          roles={permissions.costs.read}
          component={CostListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/caseload"
          roles={permissions.caseloads.read}
          component={CaseloadListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/history"
          roles={permissions.history.read}
          component={HistoryListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/news/view/:id"
          roles={permissions.news.read}
          component={NewsEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/news/create/"
          roles={permissions.news.create}
          component={NewsCreateContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/news"
          roles={permissions.news.read}
          component={NewsListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/organisations/edit/:id"
          roles={permissions.organisations.update}
          component={OrganisationEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/organisations/view/:id"
          roles={permissions.organisations.read}
          component={OrganisationEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/organisations/create"
          roles={permissions.organisations.create}
          component={OrganisationCreateContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/organisations"
          roles={permissions.organisations.read}
          component={OrganisationListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/customers/view/:id"
          roles={permissions.customers.read}
          component={CustomerEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/customers/edit/:id"
          roles={permissions.customers.update}
          component={CustomerEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/customers"
          roles={permissions.customers.read}
          component={CustomerListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/ocr"
          roles={permissions.global}
          component={OcrContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/assurances/view/:id"
          roles={permissions.assurances.read}
          component={AssuranceEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/assurances/create/"
          roles={permissions.assurances.create}
          component={AssuranceCreateContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/assurances"
          roles={permissions.assurances.read}
          component={AssuranceListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/complaints/view/:id"
          roles={permissions.complaints.read}
          component={ComplaintEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/complaints/create/"
          roles={permissions.complaints.create}
          component={ComplaintCreateContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/complaints"
          roles={permissions.complaints.read}
          component={ComplaintListContainer}
          {...props.injectedProps}
        />
      )}
      <PrivateRoute
        exact
        path="/ttgRecord/"
        roles={permissions.dummyRecords.create}
        component={TtgRecordCreateContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/ttgProgrammes/view/:id"
        roles={permissions.ttgProgrammes.read}
        component={TtgProgrammeEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/ttgProgrammes/edit/:id"
        roles={permissions.ttgProgrammes.update}
        component={TtgProgrammeEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/ttgProgrammes"
        roles={permissions.ttgProgrammes.read}
        component={TtgProgrammeListContainer}
        {...props.injectedProps}
      />
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/feedback/view/:id"
          roles={permissions.feedback.read}
          component={FeedbackEditContainer}
          {...props.injectedProps}
        />
      )}
      <PrivateRoute
        exact
        path="/feedback/create/"
        roles={permissions.feedback.create}
        component={FeedbackCreateContainer}
        {...props.injectedProps}
      />
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/feedback"
          roles={permissions.feedback.listAccess}
          component={FeedbackListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/supportreferrals/view/:id"
          roles={permissions.supportReferrals.read}
          component={SupportReferralEditContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/engagements/create/:entityId/:entityType?/:engagementType?"
          roles={permissions.engagements.create}
          component={EngagementCreateContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/supportreferrals"
          roles={permissions.supportReferrals.read}
          component={SupportReferralListContainer}
          {...props.injectedProps}
        />
      )}
      {!screenLocked && (
        <PrivateRoute
          exact
          path="/supportreferrals/view/:id?/:referralId?"
          roles={permissions.supportReferrals.read}
          component={SupportReferralEditContainer}
          {...props.injectedProps}
        />
      )}

      <PrivateRoute
        exact
        path="/settings"
        roles={permissions.settings.read}
        component={SettingsEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/dummyRecords/view/:id"
        roles={permissions.dummyRecords.read}
        component={DummyRecordEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/dummyRecords/edit/:id"
        roles={permissions.dummyRecords.update}
        component={DummyRecordEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/dummyRecords"
        roles={permissions.dummyRecords.read}
        component={DummyRecordListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/areaOfNeed/create/:entityId/:entityType?"
        roles={permissions.areaOfNeed.create}
        component={AreaOfNeedCreateContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/areaOfNeed/view/:id"
        roles={permissions.areaOfNeed.read}
        component={AreaOfNeedEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/areaOfNeed/edit/:id"
        roles={permissions.areaOfNeed.update}
        component={AreaOfNeedEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/areaOfNeed"
        roles={permissions.areaOfNeed.read}
        component={AreaOfNeedListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/ttgEngagements/create/:needId"
        roles={permissions.ttgEngagements.create}
        component={TtgEngagementCreateContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/ttgEngagements/view/:id"
        roles={permissions.ttgEngagements.read}
        component={TtgEngagementEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/ttgEngagements/edit/:id"
        component={TtgEngagementEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/ttgEngagements"
        roles={permissions.ttgEngagements.read}
        component={TtgEngagementListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/"
        roles={permissions.global}
        component={TaskListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/logout"
        roles={permissions.global}
        component={Logout}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/notFound"
        component={NotFoundPage}
        roles={permissions.global}
        {...props.injectedProps}
      />
      {!screenLocked ? (
        <Redirect to="/notFound" />
      ) : (
        <Redirect to="/feedback/create/" />
      )}
    </Switch>
  );
};

Routes.propTypes = {
  injectedProps: PropTypes.object.isRequired
};

export default Routes;
