import { appActionTypes } from "../actions/app.actionTypes";

const initialState = {
  clientId: "",
  sidebarOpen: !navigator.userAgent.includes("Mobile")
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appActionTypes.SET_CLIENT_REQUEST:
      return { ...state };
    case appActionTypes.SET_CLIENT_SUCCESS:
      return { ...state, clientId: action.payload };
    case appActionTypes.SET_SIDEBAR_OPEN_SUCCESS:
      return { ...state, sidebarOpen: action.sidebarOpen };

    default:
      return state;
  }
};
