import { API } from "../../services";
import { assuranceActionTypes } from "./assurance.actionTypes";
import { amendmentHelpers } from "../../helpers";
import { strings } from "../../resources";

const api = new API();

const createAssurance = (details, headers) => {
  const request = details => {
    return { type: assuranceActionTypes.CREATE_ASSURANCE_REQUEST, details };
  };
  const success = response => {
    return { type: assuranceActionTypes.CREATE_ASSURANCE_SUCCESS, response };
  };
  const failure = error => {
    return { type: assuranceActionTypes.CREATE_ASSURANCE_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "createAssurance" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };
    return new Promise((resolve, reject) => {
      api.createAssurance(body).then(
        response => {
          dispatch(success(response));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const clearAssuranceRequest = () => {
  const request = () => {
    return { type: assuranceActionTypes.CLEAR_CREATE_ASSURANCE_REQUEST };
  };

  const success = () => {
    return { type: assuranceActionTypes.CLEAR_CREATE_ASSURANCE_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateAssurance = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return { type: assuranceActionTypes.UPDATE_ASSURANCE_REQUEST, details };
  };
  const success = response => {
    return {
      type: assuranceActionTypes.UPDATE_ASSURANCE_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: assuranceActionTypes.UPDATE_ASSURANCE_FAILURE,
      error,
      initialDetails
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "updateAssurance" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };
    api.updateAssurance(id, body).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error, initialDetails));
      }
    );
  };
};

const deleteAssurance = (id, headers) => {
  const request = () => {
    return { type: assuranceActionTypes.DELETE_ASSURANCE_REQUEST };
  };
  const success = response => {
    return { type: assuranceActionTypes.DELETE_ASSURANCE_SUCCESS, response };
  };
  const failure = error => {
    return { type: assuranceActionTypes.DELETE_ASSURANCE_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteAssurance" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteAssurance(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getAssurances = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly,
  feedbackType,
  programmeId
) => {
  const request = () => {
    return { type: assuranceActionTypes.GET_ASSURANCES_REQUEST };
  };
  const success = response => {
    return { type: assuranceActionTypes.GET_ASSURANCES_SUCCESS, response };
  };
  const failure = error => {
    return { type: assuranceActionTypes.GET_ASSURANCES_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getAssurance" });

  return dispatch => {
    dispatch(request());
    if (clear) {
      // If supposed to clear, replicate blank result
      dispatch(success({ hits: { hits: [] } }));
    } else {
      api
        .getAssurances(
          queryString,
          size,
          from,
          fields,
          sort,
          activeOnly,
          feedbackType,
          programmeId
        )
        .then(
          response => {
            const entityDetails = JSON.parse(
              localStorage.getItem("entityDetails")
            );

            if (
              entityDetails &&
              entityDetails.entity === strings.amendments.entity
            ) {
              response.data = amendmentHelpers.removeFromList(
                response.data,
                entityDetails
              );
            }

            dispatch(success(response));
          },
          error => dispatch(failure(error))
        );
    }
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly,
  feedbackType,
  programmeId
) => {
  const request = () => {
    return { type: assuranceActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: assuranceActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };
  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly,
    feedbackType: feedbackType,
    programmeId: programmeId
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(
      getAssurances(
        headers,
        queryString,
        size,
        from,
        clear,
        fields,
        sort,
        activeOnly,
        feedbackType,
        programmeId
      )
    );
  };
};

const getAssuranceById = (id, headers) => {
  const request = () => {
    return { type: assuranceActionTypes.GET_ASSURANCE_BY_ID_REQUEST };
  };
  const success = response => {
    return {
      type: assuranceActionTypes.GET_ASSURANCE_BY_ID_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: assuranceActionTypes.GET_ASSURANCE_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getAssuranceById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getAssuranceById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedAssurance = (key, value) => {
  const request = (key, value) => {
    return {
      type: assuranceActionTypes.UPDATE_SELECTED_ASSURANCE_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: assuranceActionTypes.UPDATE_SELECTED_ASSURANCE_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: assuranceActionTypes.UPDATE_SELECTED_ASSURANCE_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedAssurance = () => {
  const request = () => {
    return { type: assuranceActionTypes.CLEAR_SELECTED_ASSURANCE_REQUEST };
  };
  const success = () => {
    return { type: assuranceActionTypes.CLEAR_SELECTED_ASSURANCE_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedAssuranceUpdate = initialAssuranceData => {
  const request = () => {
    return {
      type: assuranceActionTypes.CANCEL_SELECTED_ASSURANCE_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type: assuranceActionTypes.CANCEL_SELECTED_ASSURANCE_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialAssuranceData));
  };
};

export const assuranceActions = {
  createAssurance,
  updateAssurance,
  deleteAssurance,
  getAssuranceById,
  getAssurances,
  updateSearchCriteria,
  updateSelectedAssurance,
  clearSelectedAssurance,
  clearAssuranceRequest,
  cancelSelectedAssuranceUpdate
};
