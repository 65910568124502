import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      entity: "areaOfNeed",
      deletion: "deletion",
      fullName: "Area Of Need"
    },
    areas: { NRS: "NRS", both: "both", NRNE: "NRNE" },
    header: {
      areaOfNeedList: "Area Of Need",
      areaOfNeed: "Area Of Need",
      createAreaOfNeed: "Create New Area Of Need",
      editAreaOfNeed: "Edit Area Of Need",
      viewAreaOfNeed: "Area Of Need Details"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        areaOfNeedInfo: "Area of Need Information"
      },
      label: {
        notes: "Notes",
        reasonForOutcome: "Reason for Outcome",
        dateOfNeutralOutcome: "Date of Neutral Outcome",
        dateOfPositiveOutcome: "Date of Positive Outcome",
        followUpDate: "Follow Up Date",
        outcomeOfAreaOfNeed: "Outcome of Area of Need",
        actionStatus: "Action Status",
        actions: "Actions",
        areaOfNeed: "Area Of Need",
        areOfNeedDate: "Area of Need Date",
        cjSpecialist: "CJ Specialist",
        crmId: "CRM ID",
        actionStartedDate: "Action started date",
        actionClosedDate: "Action closed date",
        actionDate: "Action Date",
        actionsOther: "Action (Other)"
      },
      placeholder: {
        notes: "",
        reasonForOutcome: "",
        dateOfNeutralOutcome: "",
        dateOfPositiveOutcome: "",
        followUpDate: "",
        outcomeOfAreaOfNeed: "- select -",
        actionStatus: "- select -",
        actions: "- select -",
        areaOfNeed: "- select -",
        areOfNeedDate: "",
        cjSpecialist: "- select -",
        crmId: "",
        actionStartedDate: "",
        actionClosedDate: "",
        actionsOther: ""
      },
      feedback: {
        processing: "Processing request...",
        areaOfNeedRequestSuccess: "Area of Need created successfully!",
        areaOfNeedRequestRefreshPrompt:
          "Use the refresh function to see an updated Area Of Need list.",
        areaOfNeedRequestFailure: "Area of Need creation failed.",
        areaOfNeedRequestUnknown:
          "Something went wrong with the Area Of Need request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        yes: "Yes",
        no: "No",
        neutral: "Neutral",
        positive: "Positive",
        workInProgress: "Work in progress",
        open: "Open",
        closed: "Closed",
        other: "Other",
        actionStatus: "actionStatus",
        outcomeOfAreaOfNeed: "outcomeOfAreaOfNeed",
        ttg: "TTG"
      },
      fileUpload: "Upload files"
    },
    modal: {
      createAreaOfNeedMessage: "Save Area Of Need?",
      modifyAreaOfNeedMessage: "Are you sure you want to save these changes?",
      deleteAreaOfNeedMessage:
        "Are you sure you want to delete this Area of Need? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this Area Of Need's status to active?",
      setInactiveMessage: "Set this Area Of Need's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:",
      deleteActionMessage:
        "Are you sure you want to delete this action? (This deletion can't be undone)."
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addAreaOfNeedLabel: "New Area Of Need",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      saveAddNew: "Save & Add New",
      addEngagement: "Add Engagement",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    tooltip: {},
    table: {
      header: {
        cjSpecialist: "CJ Specialist",
        created: "Date Created",
        crmId: "CRM ID",
        areaOfNeedId: "Area Of Need ID",
        updated: "Last Updated",
        areaOfNeed: "Area of Need"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    }
  }
});
