import { API } from "../../services";
import { complaintActionTypes } from "./complaint.actionTypes";
import { amendmentHelpers } from "../../helpers";
import { strings } from "../../resources";

const api = new API();

const createComplaint = (details, headers) => {
  const request = details => {
    return {
      type: complaintActionTypes.CREATE_COMPLAINT_REQUEST,
      details
    };
  };
  const success = response => {
    return {
      type: complaintActionTypes.CREATE_COMPLAINT_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: complaintActionTypes.CREATE_COMPLAINT_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "createComplaint" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };
    return new Promise((resolve, reject) => {
      api.createComplaint(body).then(
        response => {
          dispatch(success(response));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const clearComplaintRequest = () => {
  const request = () => {
    return { type: complaintActionTypes.CLEAR_CREATE_COMPLAINT_REQUEST };
  };

  const success = () => {
    return { type: complaintActionTypes.CLEAR_CREATE_COMPLAINT_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateComplaint = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return {
      type: complaintActionTypes.UPDATE_COMPLAINT_REQUEST,
      details
    };
  };
  const success = response => {
    return {
      type: complaintActionTypes.UPDATE_COMPLAINT_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: complaintActionTypes.UPDATE_COMPLAINT_FAILURE,
      error,
      initialDetails
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "updateComplaint" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };

    api.updateComplaint(id, body).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const deleteComplaint = (id, headers) => {
  const request = () => {
    return { type: complaintActionTypes.DELETE_COMPLAINT_REQUEST };
  };
  const success = response => {
    return {
      type: complaintActionTypes.DELETE_COMPLAINT_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: complaintActionTypes.DELETE_COMPLAINT_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteComplaint" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteComplaint(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getComplaints = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly,
  feedbackType,
  programmeId
) => {
  const request = () => {
    return { type: complaintActionTypes.GET_COMPLAINTS_REQUEST };
  };
  const success = response => {
    return {
      type: complaintActionTypes.GET_COMPLAINTS_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: complaintActionTypes.GET_COMPLAINTS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getComplaints" });

  return dispatch => {
    dispatch(request());
    if (clear) {
      // If supposed to clear, replicate blank result
      dispatch(success({ hits: { hits: [] } }));
    } else {
      api
        .getComplaints(
          queryString,
          size,
          from,
          fields,
          sort,
          activeOnly,
          feedbackType,
          programmeId
        )
        .then(
          response => {
            const entityDetails = JSON.parse(
              localStorage.getItem("entityDetails")
            );

            if (
              entityDetails &&
              entityDetails.entity === strings.amendments.entity
            ) {
              response.data = amendmentHelpers.removeFromList(
                response.data,
                entityDetails
              );
            }

            dispatch(success(response));
          },
          error => dispatch(failure(error))
        );
    }
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly,
  feedbackType,
  programmeId
) => {
  const request = () => {
    return { type: complaintActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: complaintActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };
  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly,
    feedbackType: feedbackType,
    programmeId
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(
      getComplaints(
        headers,
        queryString,
        size,
        from,
        clear,
        fields,
        sort,
        activeOnly,
        feedbackType,
        programmeId
      )
    );
  };
};

const getComplaintById = (id, headers) => {
  const request = () => {
    return { type: complaintActionTypes.GET_COMPLAINT_BY_ID_REQUEST };
  };
  const success = response => {
    return {
      type: complaintActionTypes.GET_COMPLAINT_BY_ID_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: complaintActionTypes.GET_COMPLAINT_BY_ID_FAILURE,
      error
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getComplaintById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getComplaintById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedComplaint = (key, value) => {
  const request = (key, value) => {
    return {
      type: complaintActionTypes.UPDATE_SELECTED_COMPLAINT_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: complaintActionTypes.UPDATE_SELECTED_COMPLAINT_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return {
      type: complaintActionTypes.UPDATE_SELECTED_COMPLAINT_FAILURE
    };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const cancelSelectedComplaintUpdate = initialComplaintData => {
  const request = () => {
    return {
      type: complaintActionTypes.CANCEL_SELECTED_COMPLAINT_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type: complaintActionTypes.CANCEL_SELECTED_COMPLAINT_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialComplaintData));
  };
};

const clearSelectedComplaint = () => {
  const request = () => {
    return {
      type: complaintActionTypes.CLEAR_SELECTED_COMPLAINT_REQUEST
    };
  };
  const success = () => {
    return {
      type: complaintActionTypes.CLEAR_SELECTED_COMPLAINT_SUCCESS
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

export const complaintActions = {
  createComplaint,
  updateComplaint,
  deleteComplaint,
  getComplaintById,
  getComplaints,
  updateSearchCriteria,
  updateSelectedComplaint,
  clearComplaintRequest,
  cancelSelectedComplaintUpdate,
  clearSelectedComplaint
};
