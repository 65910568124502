import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Responsive, Segment, Sidebar } from "semantic-ui-react";

import TopMenuBar from "../Navigation/Menu/TopMenuBar";
import Footer from "../Navigation/Footer/Footer";
import AppSidebar from "../Navigation/Sidebar/AppSidebar";
import { authActions } from "../../store/actions/auth.actions";
import { appActions } from "../../store/actions/app.actions";
import { auditActions } from "../../../Audit/store/actions/audit.actions";
import { taskActions } from "../../../Tasks/store/actions/task.actions";
import { permissions } from "../../resources";
import { taskTitleMappings } from "../../../Tasks/resources/TaskTitleMappings";
import "./css/ResponsiveContainer.scss";
import "../../../_styles/_react-table.scss";
import { generateFlattenedMenu } from "../../resources";
import SystemHeaders from "../../classes/SystemHeaders";
import { strings } from "../../resources/Strings";

class ResponsiveContainer extends React.Component {
  state = {
    activeItem: strings.menuPages.mywork,
    isMobile: false,
    sidebarOpened: true,
    taskSidebarItems: [],
    previousTaskList: [],
    activeSubItem: "",
    isSubMenuOpen: false,
    feedbackLocked: false
  };

  flattenedMenu = generateFlattenedMenu(this.props);

  componentDidMount = () => {
    navigator.userAgent.includes("Mobile")
      ? this.setState({ isMobile: true, sidebarOpened: false })
      : this.setState({ isMobile: false, sidebarOpened: true });
    if (this.state.dropdownMenuOpen) {
      this.setState({
        dropdownMenuOpen: false
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      let selectedItem = this.flattenedMenu.find(
        m => "/" + m.itemUrl === this.props.location.pathname
      );
      if (
        selectedItem &&
        (prevState.activeItem !== selectedItem.activeItem ||
          prevState.activeSubItem !== selectedItem.activeSubItem)
      ) {
        this.setState({
          activeItem: selectedItem.activeItem,
          activeSubItem: selectedItem.activeSubItem,
          isSubMenuOpen: selectedItem.activeSubItem !== ""
        });
      }
    }
    if (localStorage.getItem("feedbackLocked") && prevState.sidebarOpened) {
      this.setState({ sidebarOpened: false, feedbackLocked: true });
      this.props.setSidebarOpen(false);
    } else if (
      !localStorage.getItem("feedbackLocked") &&
      !prevState.sidebarOpened &&
      prevState.feedbackLocked
    ) {
      this.setState({ sidebarOpened: true, feedbackLocked: false });
      this.props.setSidebarOpen(true);
    }
    this.calculateMyWorkSubItems();
  };

  calculateMyWorkSubItems = () => {
    const { taskListDataByType } = this.props;
    const { previousTaskList } = this.state;
    const taskListDataByTypeArray = Object.values(taskListDataByType);
    let sidebarTaskList = [];

    if (taskListDataByTypeArray) {
      if (taskListDataByTypeArray.toString() !== previousTaskList.toString()) {
        taskListDataByTypeArray.forEach(itemsOfType => {
          if (itemsOfType.length > 0) {
            sidebarTaskList.push({
              menuItemUrl: "",
              menuItemName:
                taskTitleMappings[itemsOfType[0].type] +
                " (" +
                itemsOfType.length +
                ")",
              menuItemRole: permissions.tasks.read,
              iconName: "circle outline",
              type: itemsOfType[0].type
            });
          }
        });

        this.setState({
          previousTaskList: taskListDataByTypeArray,
          taskSidebarItems: sidebarTaskList
        });
      }
    }
  };

  toggleSidebar = () => {
    const newSidebarOpenedState = !this.state.sidebarOpened;
    this.setState({ sidebarOpened: newSidebarOpenedState });
    this.props.setSidebarOpen(newSidebarOpenedState);
  };

  getLayoutClassname = () => {
    if (!this.props.isLoggedIn) return "loggedOut";
    if (this.state.sidebarOpened) return "sidebarOpen";
    else return "sidebarClosed";
  };

  getCollapsedLayoutClassname = () => {
    if (!this.props.isLoggedIn) return "loggedOut";
    if (this.state.sidebarOpened) return "sidebarOpen";
    else return "sidebarClosed";
  };

  handleItemClick = (e, { name, active }) => {
    this.setState({
      ...(!active && {
        activeSubItem: "",
        isSubMenuOpen: false
      }),
      activeItem: name,
      isSubMenuOpen:
        name === this.state.activeItem ? !this.state.isSubMenuOpen : true
    });
  };

  handleSubItemClick = (e, { url, type }) => {
    e.stopPropagation();
    if (type) {
      this.props.setTaskDataSelected({ type: type, filter: "all" });
    }
    this.setState({ activeSubItem: url, isSubMenuOpen: true });
  };

  handleLogout = async () => {
    let currentState = SystemHeaders.load();
    await this.props.createAudit(
      {
        userId: this.props.userId,
        actionType: "Logout",
        username: this.props.username,
        sessionId: currentState["headers"]["sessionId"],
        ipAddress: localStorage.getItem("ipAddress")
      },
      this.props.headers
    );
    this.props.logout();
  };

  render() {
    const mainView = (
      <>
        {this.props.isLoggedIn && (
          <TopMenuBar
            mobile={this.isMobile}
            toggleSidebar={this.toggleSidebar}
            isLoggedIn={this.props.isLoggedIn}
            roleId={this.props.roleId}
            logout={this.handleLogout}
            name={this.props.givenName + " " + this.props.familyName}
            sidebarOpened={this.state.sidebarOpened}
            azureLogin={this.props.azureLogin}
            azureLogout={this.props.azureLogout}
            isMicrosoftAuthenticated={this.props.isMicrosoftAuthenticated}
            microsoftUser={this.props.microsoftUser}
            userId={this.props.userId}
          />
        )}
        {this.props.isLoggedIn && !localStorage.getItem("feedbackLocked") && (
          <AppSidebar
            activeItem={this.state.activeItem}
            activeSubItem={this.state.activeSubItem}
            isSubMenuOpen={this.state.isSubMenuOpen}
            toggleSidebar={this.toggleSidebar}
            sidebarOpened={this.state.sidebarOpened}
            roleId={this.props.roleId}
            handleItemClick={this.handleItemClick}
            handleSubItemClick={this.handleSubItemClick}
            disableLink={this.disableLink}
            enableLink={this.enableLink}
            area={this.props.area}
            taskSidebarItems={this.state.taskSidebarItems}
          />
        )}
        <Sidebar.Pusher>
          <Segment basic className={this.getLayoutClassname()}>
            {this.props.children}
          </Segment>
        </Sidebar.Pusher>
        <Footer
          isLoggedIn={this.props.isLoggedIn}
          sidebarOpened={this.state.sidebarOpened}
        />
      </>
    );
    const collapsedView = (
      <>
        {this.props.isLoggedIn && (
          <TopMenuBar
            mobile={this.isMobile}
            toggleSidebar={this.toggleSidebar}
            isLoggedIn={this.props.isLoggedIn}
            roleId={this.props.roleId}
            logout={this.handleLogout}
            name={this.props.givenName + " " + this.props.familyName}
            sidebarOpened={this.state.sidebarOpened}
            azureLogin={this.props.azureLogin}
            azureLogout={this.props.azureLogout}
            isMicrosoftAuthenticated={this.props.isMicrosoftAuthenticated}
            microsoftUser={this.props.microsoftUser}
            userId={this.props.userId}
          />
        )}
        {this.props.isLoggedIn && !localStorage.getItem("feedbackLocked") && (
          <AppSidebar
            activeItem={this.state.activeItem}
            activeSubItem={this.state.activeSubItem}
            isSubMenuOpen={this.state.isSubMenuOpen}
            toggleSidebar={this.toggleSidebar}
            sidebarOpened={this.state.sidebarOpened}
            roleId={this.props.roleId}
            handleItemClick={this.handleItemClick}
            handleSubItemClick={this.handleSubItemClick}
            disableLink={this.disableLink}
            enableLink={this.enableLink}
            area={this.props.area}
            taskSidebarItems={this.state.taskSidebarItems}
          />
        )}
        <Sidebar.Pusher>
          <Segment basic className={this.getCollapsedLayoutClassname()}>
            {this.props.children}
          </Segment>
        </Sidebar.Pusher>
        <Footer
          isLoggedIn={this.props.isLoggedIn}
          sidebarOpened={this.state.sidebarOpened}
        />
      </>
    );
    return (
      <>
        {this.props.isMobile ? (
          <Responsive as={Sidebar.Pushable}>{collapsedView}</Responsive>
        ) : (
          <>
            <Responsive as={Sidebar.Pushable} minWidth="1669">
              {mainView}
            </Responsive>
            <Responsive as={Sidebar.Pushable} maxWidth="1668">
              {collapsedView}
            </Responsive>
          </>
        )}
      </>
    );
  }
}

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
  isLoggedIn: PropTypes.bool.isRequired,
  roleId: PropTypes.string,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    isLoggedIn,
    roleId,
    givenName,
    familyName,
    area,
    userId,
    username
  } = state.auth;
  const { taskListDataByType } = state.tasks;

  return {
    isLoggedIn,
    roleId,
    givenName,
    familyName,
    area,
    taskListDataByType,
    userId,
    username
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: hasExceeded => {
      dispatch(authActions.signOut(hasExceeded));
    },
    setTaskDataSelected: data => {
      dispatch(taskActions.setTaskDataSelected(data));
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    },
    setSidebarOpen: sidebarOpen => {
      dispatch(appActions.setSidebarOpen(sidebarOpen));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResponsiveContainer)
);
