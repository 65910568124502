import { ttgProgrammeActionTypes } from "../actions/ttgProgramme.actionTypes";

const initialState = {
  loadingPage: false,
  selectedTtgProgrammeData: {},
  ttgProgrammeListData: [{}],
  totalResults: 0,
  ttgProgrammeToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  ttgProgrammeRequestStatus: undefined,
  ttgProgrammeRequestMade: false,
  deleteSuccessful: false,
  showNotification: true,
  outcomeListData: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ttgProgrammeActionTypes.CREATE_TTG_PROGRAMME_REQUEST:
      return {
        ...state,
        ttgProgrammeRequestStatus: undefined,
        ttgProgrammeRequestMade: true
      };
    case ttgProgrammeActionTypes.CREATE_TTG_PROGRAMME_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        ttgProgrammeRequestStatus: true
      };
    case ttgProgrammeActionTypes.CREATE_TTG_PROGRAMME_FAILURE:
      let details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        ttgProgrammeRequestStatus: false
      };

    case ttgProgrammeActionTypes.CLEAR_CREATE_TTG_PROGRAMME_REQUEST:
      return { ...state };
    case ttgProgrammeActionTypes.CLEAR_CREATE_TTG_PROGRAMME_SUCCESS:
      return {
        ...state,
        ttgProgrammeRequestMade: false,
        ttgProgrammeRequestStatus: undefined
      };
    case ttgProgrammeActionTypes.CLEAR_CREATE_TTG_PROGRAMME_FAILURE:
      return { ...state };

    case ttgProgrammeActionTypes.UPDATE_TTG_PROGRAMME_REQUEST:
      return {
        ...state,
        loadingPage: true,
        ttgProgrammeRequestStatus: undefined,
        ttgProgrammeRequestMade: true
      };
    case ttgProgrammeActionTypes.UPDATE_TTG_PROGRAMME_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        ttgProgrammeRequestStatus: true,
        selectedTtgProgrammeData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case ttgProgrammeActionTypes.UPDATE_TTG_PROGRAMME_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        ttgProgrammeRequestStatus: false,
        selectedTtgProgrammeData: action.initialDetails,
        errorDetails: details
      };

    case ttgProgrammeActionTypes.DELETE_TTG_PROGRAMME_REQUEST:
      return {
        ...state,
        ttgProgrammeRequestStatus: undefined,
        ttgProgrammeRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case ttgProgrammeActionTypes.DELETE_TTG_PROGRAMME_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        ttgProgrammeRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case ttgProgrammeActionTypes.DELETE_TTG_PROGRAMME_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        ttgProgrammeRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case ttgProgrammeActionTypes.GET_TTG_PROGRAMMES_REQUEST:
      return {
        ...state,
        loadingPage: true,
        deleteSuccessful: false
      };
    case ttgProgrammeActionTypes.GET_TTG_PROGRAMMES_SUCCESS:
      let rawHits = [];
      let newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newTtgProgramme = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newTtgProgramme;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        ttgProgrammeListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case ttgProgrammeActionTypes.GET_TTG_PROGRAMMES_FAILURE:
      return {
        ...state,
        ttgProgrammeListData: [{}],
        loadingPage: false
      };

    case ttgProgrammeActionTypes.GET_TTG_PROGRAMME_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        ttgProgrammeRequestStatus: undefined
      };
    case ttgProgrammeActionTypes.GET_TTG_PROGRAMME_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        selectedTtgProgrammeData: action.response.Body.Item,
        loadingPage: false,
        ttgProgrammeRequestStatus: true
      };
    case ttgProgrammeActionTypes.GET_TTG_PROGRAMME_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        ttgProgrammeRequestStatus: false,
        selectedTtgProgrammeData: {},
        ttgProgrammeRequestMade: true
      };

    case ttgProgrammeActionTypes.UPDATE_SELECTED_TTG_PROGRAMME_REQUEST:
      return { ...state };
    case ttgProgrammeActionTypes.UPDATE_SELECTED_TTG_PROGRAMME_SUCCESS:
      return {
        ...state,
        selectedTtgProgrammeData: {
          ...state.selectedTtgProgrammeData,
          [action.key]: action.value
        }
      };
    case ttgProgrammeActionTypes.UPDATE_SELECTED_TTG_PROGRAMME_FAILURE:
      return { ...state };

    case ttgProgrammeActionTypes.CLEAR_SELECTED_TTG_PROGRAMME_REQUEST:
      return { ...state };
    case ttgProgrammeActionTypes.CLEAR_SELECTED_TTG_PROGRAMME_SUCCESS:
      return { ...state, selectedTtgProgrammeData: {} };
    case ttgProgrammeActionTypes.CLEAR_SELECTED_TTG_PROGRAMME_FAILURE:
      return { ...state };

    case ttgProgrammeActionTypes.CANCEL_SELECTED_TTG_PROGRAMME_UPDATE_REQUEST:
      return { ...state };
    case ttgProgrammeActionTypes.CANCEL_SELECTED_TTG_PROGRAMME_UPDATE_SUCCESS:
      return { ...state, selectedTtgProgrammeData: action.data };
    case ttgProgrammeActionTypes.CANCEL_SELECTED_TTG_PROGRAMME_UPDATE_FAILURE:
      return { ...state };

    case ttgProgrammeActionTypes.GET_TTG_PROGRAMME_OUTCOMES_REQUEST:
      return {
        ...state,
        loadingPage: true,
        ttgProgrammeRequestStatus: undefined
      };
    case ttgProgrammeActionTypes.GET_TTG_PROGRAMME_OUTCOMES_SUCCESS:
      const outcomesData = action.response.Body;
      let outcomeslist = [];
      if (outcomesData) {
        Object.keys(outcomesData).forEach(key => {
          let currentOutcomeData = outcomesData[key];
          outcomeslist.push({ outcomeName: key, ...currentOutcomeData });
        });
      }
      return {
        ...state,
        result: action.response.Reason,
        outcomeListData: outcomeslist,
        loadingPage: false,
        ttgProgrammeRequestStatus: true
      };
    case ttgProgrammeActionTypes.GET_TTG_PROGRAMME_OUTCOMES_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        ttgProgrammeRequestStatus: false,
        outcomeListData: [],
        ttgProgrammeRequestMade: true
      };

    default:
      return state;
  }
};
