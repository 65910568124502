import axios from "./axios-assurance";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createAssurance = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  };

  updateAssurance = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating assurance: ", error.message);
          reject(error);
        });
    });
  };

  deleteAssurance = id => {
    const config = {
      headers: this.headers,
      data: { feedbackType: "Assurance" }
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting assurance: ", error.message);
          reject(error);
        });
    });
  };

  getAssurances = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    feedbackType,
    programmeId
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly,
      feedbackType: feedbackType,
      programmeId: programmeId
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/list-forms/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting assurance: ", error.message);
          reject(error);
        });
    });
  };

  getAssuranceById = feedbackId => {
    const config = {
      headers: {
        ...this.headers,
        programme: localStorage.getItem("programmeId")
      },
      params: {
        feedbackType: "Assurance"
      }
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/${feedbackId}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting assurance: ", error.message);
          reject(error);
        });
    });
  };
}
