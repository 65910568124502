export const historyActionTypes = {
  GET_HISTORY_REQUEST: "GET_HISTORY_REQUEST",
  GET_HISTORY_SUCCESS: "GET_HISTORY_SUCCESS",
  GET_HISTORY_FAILURE: "GET_HISTORY_FAILURE",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE",

  CLEAR_CREATE_HISTORY_REQUEST: "CLEAR_CREATE_HISTORY_REQUEST",
  CLEAR_CREATE_HISTORY_FAILURE: "CLEAR_CREATE_HISTORY_FAILURE",
  CLEAR_CREATE_HISTORY_SUCCESS: "CLEAR_CREATE_HISTORY_SUCCESS"
};
