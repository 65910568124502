import React from "react";
import { Grid, Checkbox, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { initialStagesHead, initialStagesBody, strings } from "../../resources";
import { ValidatedDateInput, ValidatedFormInput } from ".";
import { convertToIso, convertFromIso } from "../../helpers";

class ValidatedBaselineAssessment extends React.Component {
  state = {
    stageValid: [],
    stagesHead: initialStagesHead,
    stagesBody: initialStagesBody,
    dateCompleted: [],
    generalHealth: [],
    mentalHealth: [],
    addictions: [],
    educationTraining: [],
    employment: [],
    relationships: [],
    finance: [],
    housing: [],
    attitudeBehaviour: [],
    socialLeisure: [],
    numberOfAdditional: 1,
    numberOfAdditionalEmpty: 1
  };

  getInitialState = () => ({
    stageValid: [],
    stagesHead: initialStagesHead,
    stagesBody: initialStagesBody,
    dateCompleted: [],
    generalHealth: [],
    mentalHealth: [],
    addictions: [],
    educationTraining: [],
    employment: [],
    relationships: [],
    finance: [],
    housing: [],
    attitudeBehaviour: [],
    socialLeisure: [],
    numberOfAdditional: 1,
    numberOfAdditionalEmpty: 1
  });

  componentDidMount = async () => {
    let challengeKeys = Object.keys(this.props.selectedBaselineData);
    if (challengeKeys.length > 0) {
      this.setupExistingBaselines(challengeKeys);
    } else {
      this.setupDefaultBaselines();
    }
  };

  componentDidUpdate() {
    if (this.props.hasCancelled === true) {
      let challengeKeys = Object.keys(this.props.selectedBaselineData);
      this.setState(this.getInitialState(), () => {
        this.setupExistingBaselines(challengeKeys);
      });
      this.props.resetCancelledState();
    }
  }

  setupExistingBaselines = challengeKeys => {
    let totalAdditional;
    let stagesHeadArray = [...this.state.stagesHead];
    let maxStageKeysArray = [];
    // Need to know the maximum number of stage keys before the
    // for loop that actually adds the rows
    for (let challengeKey of challengeKeys) {
      let stageKeys = Object.entries(
        this.props.selectedBaselineData[challengeKey]
      );
      // The "typeof" check ensures we are only checking Challenge/Aspiration
      // rows and not the table formatting rows (like the headers row)
      if (
        typeof this.props.selectedBaselineData[challengeKey] === "object" &&
        stageKeys.length > maxStageKeysArray.length
      ) {
        maxStageKeysArray = stageKeys;
      }
    }
    for (let challengeKey of challengeKeys) {
      if (typeof this.props.selectedBaselineData[challengeKey] === "object") {
        let keys = Object.keys(this.props.selectedBaselineData[challengeKey]);
        totalAdditional = keys.filter(key => {
          return !key.indexOf(strings.fieldValues.additionalLowercase);
        }).length;
        this.addNewRow(challengeKey, stagesHeadArray, maxStageKeysArray);
        this.validateRow(challengeKey);
      }
    }
    this.addMissingStages(totalAdditional);
  };

  addNewRow = (challengeKey, stagesHeadArray, maxStageKeysArray) => {
    let stageKeys = Object.entries(
      this.props.selectedBaselineData[challengeKey]
    );
    stageKeys.sort((a, b) => a[1].column - b[1].column);
    // If one of the rows is missing one or more of the stages, it will
    // mess up the rendering and prevent changes from being submitted, so
    // this code fills out missing columns with default values
    if (stageKeys.length < maxStageKeysArray.length) {
      maxStageKeysArray.forEach(stage => {
        // stageKeys is an array of arrays, to convert into a single array
        // to check if the stage name exists in any of the arrays
        if (![].concat.apply([], stageKeys).includes(stage[0])) {
          let newValueObject = {
            column: stage[1].column,
            value: "0"
          };
          let newStageKey = [stage[0], newValueObject];
          stageKeys.push(newStageKey);
        }
      });
    }
    stageKeys.forEach(key => {
      if (key[0].includes(strings.fieldValues.additionalLowercase)) {
        let stageName = key[0].match(/[a-zA-Z]+/g)[0];
        stageName = stageName.charAt(0).toUpperCase() + stageName.slice(1);
        let stageNumber = key[0].match(/\d+/g);
        let newStage = {
          position: "center",
          body: stageName + " " + stageNumber
        };
        if (
          stagesHeadArray.filter(stage => stage.body === newStage.body)
            .length === 0
        ) {
          //only add the new columns once
          stagesHeadArray.splice(key[1].column + 1, 0, newStage); //add one to offset the first column being the labels
        }
        this.setState({
          stagesHead: stagesHeadArray,
          numberOfAdditional: this.state.numberOfAdditional + 1
        });
      }
      let newState = this.state[challengeKey];
      let value = key[1].value;
      newState.push({ stage: key[0], value: value, valid: undefined });
      this.setState({ [challengeKey]: newState });
    });
  };

  addMissingStages = totalAdditional => {
    let stagesLength = this.state.dateCompleted.length;
    let stageHeadLength = 4 + totalAdditional;
    for (let i = 0; i < stagesLength; i++) {
      this.setState(prevState => ({
        stageValid: [...prevState.stageValid, true]
      }));
    }
    if (stagesLength < stageHeadLength) {
      initialStagesBody.map(item => {
        let i = stagesLength;
        while (i < stageHeadLength) {
          let newState = this.state[item.name];
          newState.push({
            stage: "stage" + (i - totalAdditional + 1),
            value: "",
            valid: undefined
          });
          this.setState({ [item.name]: newState });
          i++;
        }
        return "";
      });
    }
  };

  setupDefaultBaselines = () => {
    initialStagesBody.map(item => {
      this.setState({
        [item.name]: [...Array(4)].map((item, i) => ({
          stage: "stage" + (i + 1),
          value: "",
          valid: undefined
        }))
      });
      return "";
    });
  };

  allowNumericalInputLimit = e => {
    e = e || window.event;
    const charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[1-4]$/)) e.preventDefault();
  };

  validateNextStage = index => {
    const { stagesBody } = this.state;
    const stageValid = [];
    let i = 0;
    while (i < this.state.stagesHead.length - 2) {
      //subtract 2 as the labels and checkboxes are also considered columns
      stageValid.push([]);
      i++;
    }
    stagesBody.map(stage => {
      this.state[stage.name].map((item, index) => {
        stageValid[index].push(item.value && true);
        return "";
      });
      return "";
    });
    if (
      stageValid[index].every(el => el) &&
      this.state.stageValid.length === index
    ) {
      this.setState({
        stageValid: [...this.state.stageValid, true]
      });
      if (
        this.state.stagesHead[index + 1].body.includes(
          strings.fieldValues.additionalUppercase
        )
      ) {
        this.setState({
          numberOfAdditionalEmpty: this.state.numberOfAdditionalEmpty - 1
        });
      }
    }
  };

  validateRow = row => {
    const rowName = this.state[row];
    const rowValid = [];
    rowName.map(item => {
      if (item.value === "4") {
        rowValid.push(false);
      } else if (item.value) {
        rowValid.push(true);
      }
      return "";
    });
    // If any value is not 4, then Identified Need = Yes
    let needIdentified = false;
    rowName.map(item => {
      rowValid.forEach(value => {
        if (value) {
          needIdentified = true;
        }
      });
      item.valid = needIdentified;
      return "";
    });
    this.setState({
      [row]: rowName
    });
  };

  handlerField = (e, { name, value }, index = null) => {
    if (
      value === "" &&
      index < this.state.stageValid.length &&
      name !== strings.fieldValues.dateCompleted
    ) {
      value = null;
    }
    if (name === strings.fieldValues.dateCompleted) {
      let cleared = value === "" ? value : null;
      value = convertToIso(value);
      initialStagesBody.forEach(stage => {
        let field = { name: stage.name, value: cleared };
        //the below if sets each fields value to "" if it is not currently set, and the datepicker that has been changed has not been cleared
        if (
          stage.name !== strings.fieldValues.dateCompleted &&
          (!this.props.selectedBaselineData[stage.name] ||
            !this.props.selectedBaselineData[stage.name][
              this.state[name][index].stage
            ] ||
            cleared === "")
        ) {
          this.handlerField(null, field, index);
        }
      });
    }
    const array = this.state[name];
    if (index !== null) {
      array[index].value = value;

      this.setState({
        [name]: array
      });
    } else {
      this.setState({
        [name]: value
      });
    }
    this.validateNextStage(index);
    this.validateRow(name);
    this.buildReturnData(name);
  };

  buildReturnData = name => {
    let returnObject = {
      dateCompleted: {},
      generalHealth: {},
      mentalHealth: {},
      addictions: {},
      educationTraining: {},
      employment: {},
      relationships: {},
      finance: {},
      housing: {},
      attitudeBehaviour: {},
      socialLeisure: {}
    };
    let columnIndex = 0;
    this.state[name].forEach(element => {
      if (element.value !== "") {
        returnObject[name][element.stage] = {
          value: element.value,
          column: columnIndex
        };
        columnIndex++;
      }
    });
    this.props.updateSelectedBaseline(name, returnObject[name]);
  };

  addBaseline = () => {
    const {
      stagesHead,
      stagesBody,
      dateCompleted,
      generalHealth,
      mentalHealth,
      addictions,
      educationTraining,
      employment,
      relationships,
      finance,
      housing,
      attitudeBehaviour,
      socialLeisure,
      stageValid,
      numberOfAdditional,
      numberOfAdditionalEmpty
    } = this.state;
    stagesHead.splice(stageValid.length + numberOfAdditionalEmpty, 0, {
      position: "center",
      body: strings.fieldValues.additionalUppercase + " " + numberOfAdditional
    });

    stagesBody.map(item => {
      this.state[item.name].splice(
        stageValid.length + numberOfAdditionalEmpty - 1,
        0,
        {
          stage: strings.fieldValues.additionalLowercase + numberOfAdditional,
          value: "",
          valid: undefined
        }
      );
      return "";
    });
    this.setState({
      numberOfAdditional: numberOfAdditional + 1,
      numberOfAdditionalEmpty: numberOfAdditionalEmpty + 1,
      stagesHead,
      dateCompleted,
      generalHealth,
      mentalHealth,
      addictions,
      educationTraining,
      employment,
      relationships,
      finance,
      housing,
      attitudeBehaviour,
      socialLeisure
    });
  };

  render = () => {
    const { stageValid, dateCompleted, stagesHead, stagesBody } = this.state;
    const { Row, Column } = Grid;
    if (dateCompleted.length > 0) {
      return (
        <>
          <div className="ass-baseline-stages">
            <Grid>
              <Row columns={stagesHead.length}>
                {stagesHead.map((item, i) => (
                  <Column key={i} textAlign={item.position}>
                    {item.body}
                  </Column>
                ))}
              </Row>

              {stagesBody
                .filter(row => row.name === strings.fieldValues.dateCompleted)
                .map((row, rowIndex) => (
                  <Row key={rowIndex} columns={stagesHead.length}>
                    <Column>
                      <span className="ass-baseline-label">{row.label}</span>
                    </Column>

                    {dateCompleted.map((item, i, original) => (
                      <Column key={i}>
                        <ValidatedDateInput
                          inputId={"dateCompleted" + i}
                          formInvalid={this.props.formInvalid}
                          inputLabel={strings.form.label.dateCompleted}
                          inputName="dateCompleted"
                          inputPlaceholder={
                            strings.form.placeholder.dateCompleted
                          }
                          inputDateFormat="DD/MM/YYYY"
                          inputData={
                            item.value ? convertFromIso(item.value) : ""
                          }
                          iconPosition="left"
                          handleChange={(e, field) =>
                            this.handlerField(e, field, i)
                          }
                          validationResult={
                            this.props.validationResults.dateCompleted &&
                            this.props.validationResults.dateCompleted.length >
                              i &&
                            this.props.validationResults.dateCompleted[i][0]
                              ? this.props.validationResults.dateCompleted[i]
                              : null
                          }
                          disabled={
                            this.props.mode === "view" ||
                            (item.stage !== "stage1"
                              ? !stageValid[i - 1]
                              : false) ||
                            this.props.isDisabled
                          }
                          formFieldWidth={16}
                          required={false}
                          className="baselineDate"
                          noDefaultText={true}
                        />
                      </Column>
                    ))}
                  </Row>
                ))}

              {stagesBody
                .filter(row => row.name !== strings.fieldValues.dateCompleted)
                .map((row, rowIndex) => (
                  <Row key={rowIndex} columns={stagesHead.length}>
                    <Column>
                      <span className="ass-baseline-label">{row.label}</span>
                    </Column>
                    {this.state[row.name].map((item, i) => (
                      <Column key={i}>
                        <ValidatedFormInput
                          formInvalid={this.props.formInvalid}
                          inputLabel={strings.form.label.blank}
                          inputData={this.state[row.name][i].value}
                          inputName={row.name}
                          inputPlaceholder={strings.form.placeholder.blank}
                          formFieldWidth={16}
                          handleChange={(e, field) =>
                            this.handlerField(e, field, i)
                          }
                          validationResult={
                            this.props.validationResults[row.name] &&
                            this.props.validationResults[row.name].length > i &&
                            this.props.validationResults[row.name][i][0]
                              ? this.props.validationResults[row.name][i]
                              : null
                          }
                          disabled={
                            (item.stage !== strings.fieldValues.stage1
                              ? !stageValid[i - 1]
                              : false) || this.props.isDisabled
                          }
                          required={false}
                          onKeyPress={this.allowNumericalInputLimit}
                          maxLength={1}
                          tabIndex={"" + (i + 1) + rowIndex}
                          noDefaultText={true}
                        />
                      </Column>
                    ))}
                    <Column>
                      <div className="ass-baseline-group">
                        <div className="ass-baseline-group-checkbox">
                          <Checkbox
                            label="Yes"
                            checked={
                              this.state[row.name][0] &&
                              this.state[row.name][0].valid === true
                            }
                            disabled={this.props.isDisabled}
                          />
                        </div>

                        <div className="ass-baseline-group-checkbox">
                          <Checkbox
                            label="No"
                            checked={
                              this.state[row.name][0] &&
                              this.state[row.name][0].valid === false
                            }
                            disabled={this.props.isDisabled}
                          />
                        </div>
                      </div>
                    </Column>
                  </Row>
                ))}
            </Grid>
          </div>
          <div className="ass-baseline-add">
            <Button
              onClick={this.addBaseline}
              disabled={
                this.state.stagesHead.length === 16 ||
                this.state.stageValid.length === 0
              }
              primary
            >
              {strings.button.addBaseline}
            </Button>
          </div>
        </>
      );
    } else {
      return null;
    }
  };
}

ValidatedBaselineAssessment.propTypes = {
  selectedBaselineData: PropTypes.object.isRequired,
  validationResults: PropTypes.object,
  formInvalid: PropTypes.bool.isRequired
};

export default ValidatedBaselineAssessment;
