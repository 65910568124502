export const textFields = [
  "detailsOfComplaint",
  "additionalNotes",
  "stage1ResponseDetails",
  "stage2ResponseDetails",
  "stage3ResponseDetails",
  "stage1ResolutionDetails",
  "stage2ResolutionDetails",
  "stage3ResolutionDetails",
  "stage1AdditionalNotes",
  "stage2AdditionalNotes",
  "stage3AdditionalNotes"
];
