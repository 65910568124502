const journeyStatusOptions = [
  { key: 0, text: "No start", value: "No start" },
  { key: 1, text: "On Programme", value: "On Programme" },
  { key: 2, text: "On Programme (remand)", value: "On Programme (remand)" },
  { key: 3, text: "Exit", value: "Exit" }
];

const yesNoOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" }
];

const sentIdentifiedNeedsOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" },
  { key: 3, text: "N/A", value: "N/A - no needs identified" }
];

const hasBcst2ReasonOptions = [
  { key: 0, text: "Transferred Prisons", value: "Transferred Prisons" },
  { key: 1, text: "Released from Court", value: "Released from Court" },
  { key: 2, text: "Bailed", value: "Bailed" },
  { key: 3, text: "Prison Regime", value: "Prison Regime" },
  { key: 4, text: "Staffing Issue", value: "Staffing Issue" },
  { key: 5, text: "Scottish Transfer", value: "Scottish Transfer" },
  { key: 6, text: "Other", value: "Other" }
];

const prisonOptions = [
  { key: 1, text: "HMP Durham", value: "HMP Durham" },
  { key: 2, text: "Other", value: "Other" }
];

const latestRiskRatingOptions = [
  { key: 1, text: "Very High", value: "Very High" },
  { key: 2, text: "High", value: "High" },
  { key: 3, text: "Medium", value: "Medium" },
  { key: 4, text: "Low", value: "Low" },
  { key: 5, text: "Not Available", value: "Not Available" }
];
const reasonForExitOptions = [
  { key: 1, text: "Transferred", value: "Transferred" },
  { key: 2, text: "Released", value: "Released" },
  {
    key: 3,
    text: "No longer an identified need",
    value: "No longer an identified need"
  },
  {
    key: 4,
    text: "Identified needs addressed",
    value: "Identified needs addressed"
  },
  { key: 5, text: "Other", value: "Other" }
];

const customerLocalAuthorityAreaOptions = [
  { key: 1, text: "Darlington", value: "Darlington" },
  { key: 2, text: "Durham", value: "Durham" },
  {
    key: 3,
    text: "Hartlepool",
    value: "Hartlepool"
  },
  {
    key: 4,
    text: "Redcar & Cleveland",
    value: "Redcar & Cleveland"
  },
  {
    key: 5,
    text: "Stockton",
    value: "Stockton"
  },
  { key: 6, text: "Other", value: "Other" }
];

export {
  yesNoOptions,
  sentIdentifiedNeedsOptions,
  hasBcst2ReasonOptions,
  journeyStatusOptions,
  prisonOptions,
  latestRiskRatingOptions,
  reasonForExitOptions,
  customerLocalAuthorityAreaOptions
};
