import React from "react";
import PropTypes from "prop-types";
import { Icon, Menu, Dropdown, Image } from "semantic-ui-react";
import ShowIfAuthorised from "../../ShowIfAuthorised";
import DownloadsListModal from "../../DownloadsListModal";
import "./css/topMenuBar.scss";
import image from "../../../assets/images/logo_tiny.png";
import { permissions } from "../../../resources";
import { strings } from "../../../resources/Strings";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../../graphql/queries";
import * as subscriptions from "../../../../graphql/subscriptions";
import moment from "moment";

class TopMenuBar extends React.Component {
  state = {
    subscription: false,
    savedUrls: [],
    downloadsModalOpen: false
  };
  componentDidMount = async () => {
    if (this.props.isLoggedIn && this.props.userId) {
      await this.initSubscription();
    }
  };

  initSubscription = async () => {
    const { userId } = this.props;
    const { subscription } = this.state;

    if (userId) {
      await this.getDownloadUrls(userId);
      if (!subscription) {
        let sub = this.createSubscription(userId);

        this.subscribeToAppsync(sub);
        this.setState({ subscription: true });
      }
    }
  };

  getDownloadUrls = async userId => {
    let results = [];
    const allEntries = await API.graphql(
      graphqlOperation(queries.getEntry, { userId })
    );

    if (allEntries) {
      results = allEntries.data.getEntry.results;
    }
    this.setState({ savedUrls: results });

    return allEntries;
  };

  subscribeToAppsync = subscription => {
    const entry = subscription.subscribe({
      next: data => {
        return this.processSubscriptionData(data);
      }
    });

    return entry;
  };

  processSubscriptionData = data => {
    let { savedUrls: urls = [] } = this.state;
    const { newEntry } = data.value.data;
    let entry = {};

    entry = {
      ...newEntry,
      isNew: true
    };

    const found = urls.find(url => url.details === entry.details);
    if (!found) {
      urls.push(entry);
      this.setState({ savedUrls: urls });
    }
  };

  createSubscription = userId => {
    return API.graphql(graphqlOperation(subscriptions.newEntry, { userId }));
  };

  toggleModal = () => {
    let { downloadsModalOpen, savedUrls } = this.state;

    if (downloadsModalOpen) {
      for (let i = 0; i < savedUrls.length; i++) {
        let url = savedUrls[i];

        if (url.isNew) {
          savedUrls[i] = {
            ...url,
            isNew: false
          };
        }
      }
    }

    this.setState({ downloadsModalOpen: !downloadsModalOpen, savedUrls });
  };
  renderDownloadModal = () => {
    let { savedUrls, downloadsModalOpen } = this.state;
    const now = moment();
    savedUrls = savedUrls.filter(
      url => now.diff(moment(url.created), "minutes") < 60
    );

    return (
      <DownloadsListModal
        downloadData={savedUrls}
        toggleModal={this.toggleModal}
        modalClassName="downloadModal"
        modalOpen={downloadsModalOpen}
        showOkButton
        showNoButton={false}
        showYesButton={false}
        yesLabel=""
        noLabel=""
        okLabel={strings.button.closeLabel}
      />
    );
  };

  render() {
    const { savedUrls } = this.state;

    const downloadIconColour = savedUrls.find(url => url.isNew) && "red";

    return (
      this.props.isLoggedIn && (
        <div className="layoutHeaderSection">
          {this.renderDownloadModal()}
          <Menu inverted pointing secondary className="topMenuBar">
            <ShowIfAuthorised allowedRoles={permissions.global}>
              <Menu.Item
                className={`menuCollapser ${
                  this.props.sidebarOpened ? "open" : "closed"
                }`}
                onClick={this.props.toggleSidebar}
              >
                <Icon name="sidebar" />
              </Menu.Item>
            </ShowIfAuthorised>

            <Menu.Item position="right" className="profile">
              {this.props.roleId === strings.roles.mentor && (
                <Dropdown
                  icon="angle down"
                  className="menuDropdown microsoftLogin"
                  trigger={
                    <span>
                      <Icon name="user" />
                      <span className="usersName">
                        {!this.props.isMicrosoftAuthenticated &&
                          "Microsoft Sign in"}
                        {this.props.isMicrosoftAuthenticated &&
                          this.props.microsoftUser.email}
                      </span>
                    </span>
                  }
                >
                  <Dropdown.Menu direction="left">
                    {!this.props.isMicrosoftAuthenticated && (
                      <Dropdown.Item
                        text="Login"
                        icon="sign in"
                        onClick={this.props.azureLogin}
                        className="menuDropdownItem"
                      />
                    )}
                    {this.props.isMicrosoftAuthenticated && (
                      <Dropdown.Item
                        text="Logout"
                        icon="log out"
                        onClick={this.props.azureLogout}
                        className="menuDropdownItem"
                      />
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Icon
                className="downloadButton"
                name="download"
                onClick={this.toggleModal}
                color={downloadIconColour}
              />
              <Dropdown
                icon="angle down"
                className="menuDropdown"
                trigger={
                  <span>
                    <Image src={image} avatar />
                    <span className="usersName">{this.props.name}</span>
                  </span>
                }
              >
                <Dropdown.Menu direction="left">
                  <Dropdown.Item
                    text="Logout"
                    icon="log out"
                    onClick={this.props.logout}
                    className="menuDropdownItem"
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </div>
      )
    );
  }
}
TopMenuBar.propTypes = {
  isMobile: PropTypes.bool,
  toggleSidebar: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  roleId: PropTypes.string,
  name: PropTypes.string.isRequired,
  sidebarOpened: PropTypes.bool
};

export default TopMenuBar;
