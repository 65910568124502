import axios from "./axios-feedback";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createFeedback = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  };

  updateFeedback = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating Feedback: ", error.message);
          reject(error);
        });
    });
  };

  deleteFeedback = id => {
    const config = {
      headers: this.headers,
      data: { feedbackType: "Feedback" }
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting Feedback: ", error.message);
          reject(error);
        });
    });
  };

  getFeedback = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    feedbackType,
    anonymous,
    programmeId
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly,
      feedbackType: feedbackType,
      anonymous: anonymous,
      programmeId: programmeId
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/list-forms/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting feedback: ", error.message);
          reject(error);
        });
    });
  };

  getFeedbackById = id => {
    const config = {
      headers: this.headers,
      params: {
        feedbackType: "Feedback"
      }
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting Feedback: ", error.message);
          reject(error);
        });
    });
  };
}
