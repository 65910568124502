import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Modal, Loader, Dimmer } from "semantic-ui-react";
import { strings } from "../resources";

class DownloadLinkModal extends React.Component {
  handleClose = () => {
    this.props.toggleModal();
  };

  render = () => {
    return (
      <Modal
        className={this.props.modalClassName}
        open={this.props.modalOpen}
        onClose={this.handleClose}
      >
        <Modal.Content className="modalContent">
          <div
            style={{
              display: this.props.loadingLink ? "" : "none",
              height: "1.6rem"
            }}
          >
            <Dimmer active inverted>
              <Loader active>{strings.modal.loadingLink}</Loader>
            </Dimmer>
          </div>
          <div
            style={{
              display: this.props.loadingLink ? "none" : ""
            }}
          >
            {this.props.downloadLink ? (
              this.props.downloadLink !== strings.downloads.appsync ? (
                <h3>
                  <a href={this.props.downloadLink}>
                    {strings.modal.downloadModalLink}
                  </a>
                  {strings.modal.downloadModalText}
                </h3>
              ) : (
                <h3>{strings.modal.preparingFile}</h3>
              )
            ) : (
              <h3>{strings.modal.downloadFailed}</h3>
            )}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="green"
            onClick={this.handleClose}
            style={{ display: this.props.showOkButton ? "" : "none" }}
          >
            <Icon name="checkmark" /> {this.props.okLabel}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

DownloadLinkModal.propTypes = {
  downloadLink: PropTypes.string.isRequired,
  modalClassName: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  showOkButton: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  noLabel: PropTypes.string.isRequired,
  yesLabel: PropTypes.string.isRequired,
  okLabel: PropTypes.string.isRequired
};

export default DownloadLinkModal;
