import { settingActionTypes } from "../actions/setting.actionTypes";

const initialState = {
  loadingPage: false,
  selectedSettingData: {},
  settingListData: [{}],
  totalResults: 0,
  settingToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  settingRequestStatus: undefined,
  settingRequestMade: false,
  deleteSuccessful: false,
  showNotification: true,
  checkingSetting: false,
  settingMatches: false
};

export default (state = initialState, action) => {
  let details;
  switch (action.type) {
    case settingActionTypes.CLEAR_CREATE_SETTING_REQUEST:
      return { ...state };
    case settingActionTypes.CLEAR_CREATE_SETTING_SUCCESS:
      return {
        ...state,
        settingRequestMade: false,
        settingRequestStatus: undefined
      };
    case settingActionTypes.CLEAR_CREATE_SETTING_FAILURE:
      return { ...state };
    case settingActionTypes.UPDATE_SETTING_REQUEST:
      return {
        ...state,
        loadingPage: true,
        settingRequestStatus: undefined,
        settingRequestMade: true
      };
    case settingActionTypes.UPDATE_SETTING_SUCCESS:
      let updatedSettings = state.selectedSettingData;
      if (action.response.Data) {
        action.response.Data.forEach(setting => {
          updatedSettings[Object.keys(setting)[0]] =
            setting[Object.keys(setting)[0]];
        });
      }
      return {
        ...state,
        result: action.response.Reason,
        loadingPage: false,
        settingRequestStatus: true,
        selectedSettingData: updatedSettings
      };
    case settingActionTypes.UPDATE_SETTING_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        settingRequestStatus: false,
        selectedSettingData: action.initialDetails,
        errorDetails: details
      };

    case settingActionTypes.GET_SETTINGS_REQUEST:
      return {
        ...state,
        loadingPage: true,
        settingRequestStatus: undefined
      };
    case settingActionTypes.GET_SETTINGS_SUCCESS:
      let settings = {};
      if (action.response.Data) {
        action.response.Data.forEach(setting => {
          settings[setting.settingId] = setting.value;
        });
      }
      return {
        ...state,
        result: action.response.Reason,
        settingRequestStatus: true,
        selectedSettingData: settings,
        loadingPage: false
      };
    case settingActionTypes.GET_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedSettingData: {},
        settingRequestStatus: false,
        settingRequestMade: true
      };

    case settingActionTypes.UPDATE_SELECTED_SETTING_REQUEST:
      return { ...state };
    case settingActionTypes.UPDATE_SELECTED_SETTING_SUCCESS:
      return {
        ...state,
        selectedSettingData: {
          ...state.selectedSettingData,
          [action.key]: action.value
        }
      };
    case settingActionTypes.UPDATE_SELECTED_SETTING_FAILURE:
      return { ...state };

    case settingActionTypes.CLEAR_SELECTED_SETTING_REQUEST:
      return { ...state };
    case settingActionTypes.CLEAR_SELECTED_SETTING_SUCCESS:
      return { ...state, selectedSettingData: {} };
    case settingActionTypes.CLEAR_SELECTED_SETTING_FAILURE:
      return { ...state };

    case settingActionTypes.CANCEL_SELECTED_SETTING_UPDATE_REQUEST:
      return { ...state };
    case settingActionTypes.CANCEL_SELECTED_SETTING_UPDATE_SUCCESS:
      return { ...state, selectedSettingData: action.data };
    case settingActionTypes.CANCEL_SELECTED_SETTING_UPDATE_FAILURE:
      return { ...state };
    case settingActionTypes.CHECK_SETTING_MATCHES_REQUEST:
      return { ...state, checkingSetting: true, settingMatches: false };
    case settingActionTypes.CHECK_SETTING_MATCHES_SUCCESS:
      return {
        ...state,
        checkingSetting: false,
        settingMatches: action.response.Result
      };
    case settingActionTypes.CHECK_SETTING_MATCHES_FAILURE:
      return { ...state, checkingSetting: false, settingMatches: false };

    default:
      return state;
  }
};
