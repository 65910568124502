import React from "react";
import ModifyAreaOfNeed from "../../components/ModifyAreaOfNeed";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, areaOfNeedObjects } from "../../resources";
import { areaOfNeedActions } from "../../store/actions/areaOfNeed.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { ttgProgrammeActions } from "../../../TtgProgrammes/store/actions/ttgProgramme.actions";
import PropTypes from "prop-types";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  convertToIso,
  getUserList,
  initialiseActionsTableAndNeedsFields
} from "../../helpers";
import moment from "moment";

class AreaOfNeedCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableAreaOfNeedModalOpen: false,
    formInvalid: false,
    validationResults: {},
    deleteModalOpen: false,
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    isPrePopulated: false,
    userList: [],
    addNew: false,
    areaOfNeedTable: "",
    customDropdownOptions: []
  };

  componentDidMount = async () => {
    this.props.clearSelectedAreaOfNeed();

    let initialiseResults = initialiseActionsTableAndNeedsFields(
      this.props.selectedAreaOfNeedData
    );
    this.props.updateSelectedAreaOfNeed(
      "actionsTable",
      initialiseResults.actionsTable
    );
    this.props.updateSelectedAreaOfNeed(
      "areaOfNeedFields",
      initialiseResults.areaOfNeedFields
    );

    await this.handleFetchData();
    let form = document.getElementById("areaOfNeedForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }

    this.setState(
      { globalProgrammeId: localStorage.getItem("ttgProgrammeId") },
      () => {
        if (this.state.globalProgrammeId) {
          this.props.getTtgProgrammeById(
            this.state.globalProgrammeId,
            this.props.headers
          );
        }
      }
    );

    this.props.updateSelectedAreaOfNeed("area", strings.form.text.ttg);

    this.props.updateSelectedAreaOfNeed(
      "ttgProgrammeId",
      this.state.globalProgrammeId
    );

    this.setState({
      hasPageLoaded: true,
      userList: getUserList(this.props.userListData)
    });
  };

  componentWillUnmount() {
    this.props.clearSelectedAreaOfNeed();
  }

  validateFormAndAddNew = async () => {
    this.setState({ addNew: true });
    this.validateForm();
  };

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    sCrit.query = "ttg";
    sCrit.fields = ["area.normalizer"];
    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  validateForm = async () => {
    const { selectedAreaOfNeedData } = this.props;
    let areaOfNeedConstraints = {
      ...twgConstraints.needs.modifyNeed
    };
    let areaOfNeedActionConstraints = {
      ...twgConstraints.needs.modifyAction
    };
    let areaOfNeedRowConstraints = {
      ...twgConstraints.needs.modifyRow
    };
    areaOfNeedConstraints.active = undefined;
    let details = JSON.parse(JSON.stringify(selectedAreaOfNeedData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(
      details,
      areaOfNeedConstraints
    );

    let areaOfNeedResults = [];
    let needFieldsEmpty = true;
    if (selectedAreaOfNeedData.areaOfNeed) {
      selectedAreaOfNeedData.areaOfNeed.forEach(need => {
        if (selectedAreaOfNeedData.areaOfNeedFields) {
          if (selectedAreaOfNeedData.areaOfNeedFields[need]) {
            let details = JSON.parse(
              JSON.stringify(selectedAreaOfNeedData.areaOfNeedFields[need])
            );
            for (let property in details) {
              if (!details[property]) {
                delete details[property];
              }
            }
            let actionResult = twgValidationFunctions.validateData(
              details,
              areaOfNeedActionConstraints
            );
            actionResult
              ? areaOfNeedResults.push(actionResult)
              : areaOfNeedResults.push({});
          }
        }

        if (results !== undefined && results.needFields === undefined) {
          results = {
            ...results,
            needFields: JSON.parse(
              JSON.stringify(areaOfNeedObjects.initialNeedFields)
            )
          };
        } else if (results === undefined) {
          results = {
            needFields: JSON.parse(
              JSON.stringify(areaOfNeedObjects.initialNeedFields)
            )
          };
        }

        let areaOfNeedResultsAreEmpty = true;
        areaOfNeedResults.forEach((result, i) => {
          areaOfNeedObjects.needFieldKeys.forEach(key => {
            //All results need to be added to preserve order
            if (results.needFields[need]) {
              results.needFields[need][key] = areaOfNeedResults[i][key];
            }
            if (areaOfNeedResults[i][key]) {
              if (Object.keys(areaOfNeedResults[i][key]).length !== 0) {
                areaOfNeedResultsAreEmpty = false;
                needFieldsEmpty = false;
              }
            }
          });
        });
        if (areaOfNeedResultsAreEmpty) {
          delete results.needFields[need];
        }
      });
    }

    if (needFieldsEmpty && results) {
      delete results.needFields;
    }

    let actionResultsEmpty = true;
    if (selectedAreaOfNeedData.areaOfNeed) {
      selectedAreaOfNeedData.areaOfNeed.forEach(need => {
        let actionResults = [];
        if (selectedAreaOfNeedData.actionsTable) {
          if (selectedAreaOfNeedData.actionsTable[need]) {
            selectedAreaOfNeedData.actionsTable[need].forEach((row, index) => {
              let details = JSON.parse(JSON.stringify(row));
              for (let property in details) {
                if (!details[property]) {
                  delete details[property];
                }
              }
              let actionResult = twgValidationFunctions.validateData(
                details,
                areaOfNeedRowConstraints
              );
              actionResult
                ? actionResults.push(actionResult)
                : actionResults.push({});

              if (results !== undefined && results.actionFields === undefined) {
                results = {
                  ...results,
                  actionFields: JSON.parse(
                    JSON.stringify(areaOfNeedObjects.initialNeedFields)
                  )
                };
              } else if (results === undefined) {
                results = {
                  actionFields: JSON.parse(
                    JSON.stringify(areaOfNeedObjects.initialNeedFields)
                  )
                };
              }
              if (results.actionFields[need]) {
                results.actionFields[need][index] = actionResults[index];
              }
              if (Object.keys(actionResults[index]).length !== 0) {
                actionResultsEmpty = false;
              }
            });
          }
        }
      });
    }

    if (actionResultsEmpty && results) {
      delete results.actionFields;
    }

    let resultsNotEmpty;
    if (results) {
      resultsNotEmpty = Object.keys(results).length > 0;
    }
    if (resultsNotEmpty) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  addAction = async need => {
    let actionsTable = this.props.selectedAreaOfNeedData.actionsTable || [];
    let actions = actionsTable[need] ? [...actionsTable[need]] : [];
    let newNeed = actionsTable[need] ? [...actionsTable[need]] : [];
    newNeed.push({
      actions: "",
      actionStatus: "",
      actionStartedDate: "",
      actionClosedDate: "",
      notes: ""
    });
    actionsTable[need] = newNeed;
    actions.length > 1
      ? await this.props.updateSelectedAreaOfNeed(
          this.getKeyByValue(
            this.props.selectedAreaOfNeedData,
            this.props.selectedAreaOfNeedData.actionsTable
          ),
          actionsTable
        )
      : await this.props.updateSelectedAreaOfNeed("actionsTable", actionsTable);
  };

  removeAction = async () => {
    let actionsTable = this.props.selectedAreaOfNeedData.actionsTable || [];
    let actions = [...actionsTable[this.state.areaOfNeedTable]] || [];
    if (actions.length > 0) {
      actions.splice(this.state.actionDeletionId, 1);
    }
    if (actions.length === 0) {
      actions = undefined;
    }

    actionsTable[this.state.areaOfNeedTable] = actions;
    await this.props.updateSelectedAreaOfNeed(
      this.getKeyByValue(
        this.props.selectedAreaOfNeedData,
        this.props.selectedAreaOfNeedData.actionsTable
      ),
      actionsTable
    );
  };

  handleSubmit = async () => {
    // Reset need fields and action table for removed areas of need
    let initialiseResults = initialiseActionsTableAndNeedsFields(
      this.props.selectedAreaOfNeedData
    );
    await this.props.updateSelectedAreaOfNeed(
      "actionsTable",
      initialiseResults.actionsTable
    );
    await this.props.updateSelectedAreaOfNeed(
      "areaOfNeedFields",
      initialiseResults.areaOfNeedFields
    );
    let data = this.props.selectedAreaOfNeedData;
    data.active = true;
    await this.props.createAreaOfNeed(data, this.props.headers);
    if (this.state.addNew) {
      window.location.reload();
    } else {
      this.props.history.push("/areaOfNeed");
    }
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearAreaOfNeedRequest();
    this.props.history.push("/areaOfNeed");
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedAreaOfNeed(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedAreaOfNeed(data.name, data.value);
    }
  };

  getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "deletionActionModal":
        this.removeAction(this.state.actionDeletionId);
        break;
      default:
        break;
    }
  };

  handleDropdownOptionAddition = (e, { value }) => {
    this.setState(prevState => ({
      customDropdownOptions: [
        { text: value, value },
        ...prevState.customDropdownOptions
      ]
    }));
  };

  handleActionChange = async (e, data, need) => {
    let actionsTable = this.props.selectedAreaOfNeedData.actionsTable || [];
    let actions = [...actionsTable[need]] || [];
    const result = data.name.split("-");
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      actions[result[1]][result[0]] = convertToIso(data.value);
    } else {
      actions[result[1]][result[0]] = data.value;
    }

    actionsTable[need] = actions;
    await this.props.updateSelectedAreaOfNeed(
      this.getKeyByValue(
        this.props.selectedAreaOfNeedData,
        this.props.selectedAreaOfNeedData.actionsTable
      ),
      actionsTable
    );
  };

  handleAreaOfNeedChange = async (e, data, need) => {
    let areaOfNeedFields =
      this.props.selectedAreaOfNeedData.areaOfNeedFields || {};
    let actions = areaOfNeedFields[need] || [];
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      actions[data.name] = convertToIso(data.value);
    } else {
      actions[data.name] = data.value;
    }
    areaOfNeedFields[need] = actions;
    if (data.value === strings.form.text.workInProgress) {
      areaOfNeedFields[need].followUpDate = moment(new Date())
        .add(7, "days")
        .format("YYYY-MM-DD");
    }

    await this.props.updateSelectedAreaOfNeed(
      this.getKeyByValue(
        this.props.selectedAreaOfNeedData,
        this.props.selectedAreaOfNeedData.areaOfNeedFields
      ),
      areaOfNeedFields
    );
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableAreaOfNeedModalVisibility = () => {
    this.setState({
      enableDisableAreaOfNeedModalOpen: !this.state
        .enableDisableAreaOfNeedModalOpen
    });
  };

  toggleConfirmDeleteModalVisibility = (idx, areaOfNeed) => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      actionDeletionId: idx,
      areaOfNeedTable: areaOfNeed
    });
  };

  render = () => {
    const loading = this.props.loadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <ModifyAreaOfNeed
            mode="create"
            pageTitle={strings.header.createAreaOfNeed}
            selectedAreaOfNeedData={this.props.selectedAreaOfNeedData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            enableDisableAreaOfNeedModalOpen={
              this.state.enableDisableAreaOfNeedModalOpen
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableAreaOfNeedModalVisibility={
              this.toggleEnableDisableAreaOfNeedModalVisibility
            }
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            deleteModalOpen={this.state.deleteModalOpen}
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            initialAreaOfNeedData={{}}
            headers={this.props.headers}
            userList={this.state.userList}
            handleActionChange={this.handleActionChange}
            addAction={this.addAction}
            validateFormAndAddNew={this.validateFormAndAddNew}
            handleAreaOfNeedChange={this.handleAreaOfNeedChange}
            handleDropdownOptionAddition={this.handleDropdownOptionAddition}
            customDropdownOptions={this.state.customDropdownOptions}
          />
        </div>
      </div>
    );
  };
}

AreaOfNeedCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedAreaOfNeedData: PropTypes.object.isRequired,
  updateSelectedAreaOfNeed: PropTypes.func.isRequired,
  clearSelectedAreaOfNeed: PropTypes.func.isRequired,
  createAreaOfNeed: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { userListData } = state.users;
  const { loadingPage, selectedAreaOfNeedData } = state.areaOfNeed;
  const { roleId } = state.auth;
  const { selectedTtgProgrammeData } = state.ttgProgrammes;
  return {
    loadingPage,
    selectedAreaOfNeedData,
    roleId,
    userListData,
    selectedTtgProgrammeData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createAreaOfNeed: async (data, headers) => {
      await dispatch(areaOfNeedActions.createAreaOfNeed(data, headers));
    },
    updateSelectedAreaOfNeed: (key, value) => {
      dispatch(areaOfNeedActions.updateSelectedAreaOfNeed(key, value));
    },
    clearSelectedAreaOfNeed: () => {
      dispatch(areaOfNeedActions.clearSelectedAreaOfNeed());
    },
    clearAreaOfNeedRequest: () => {
      dispatch(areaOfNeedActions.clearAreaOfNeedRequest());
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getTtgProgrammeById: (id, headers) => {
      dispatch(ttgProgrammeActions.getTtgProgrammeById(id, headers));
    },
    clearSelectedTtgProgramme: () => {
      dispatch(ttgProgrammeActions.clearSelectedTtgProgramme());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AreaOfNeedCreateContainer);
