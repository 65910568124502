const methodOfEngagementOptions = [
  { key: 0, text: "Face to Face", value: "Face to Face" },
  { key: 1, text: "Phone", value: "Phone" },
  { key: 2, text: "Kiosk", value: "Kiosk" },
  { key: 3, text: "Other", value: "Other" }
];

const yesNoOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" }
];

const typeOfEngagementOptions = [
  { key: 0, text: "Customer", value: "Customer" },
  { key: 1, text: "3rd party", value: "3rd party" }
];

export { yesNoOptions, methodOfEngagementOptions, typeOfEngagementOptions };
