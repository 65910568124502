/*global awsEnvUsersApiEndpoint, awsEnvOrganisationsApiEndpoint, awsEnvCustomersApiEndpoint, awsEnvReferralsApiEndpoint, awsEnvEngagementsApiEndpoint, awsEnvProgrammesApiEndpoint, awsEnvActivitiesApiEndpoint, awsEnvAssessmentsApiEndpoint, awsEnvFilesApiEndpoint, awsEnvEmploymentsApiEndpoint, awsEnvTasksApiEndpoint, awsEnvAmendmentsApiEndpoint, awsEnvCostsApiEndpoint, awsEnvReportsApiEndpoint, awsEnvFeedbackApiEndpoint, awsEnvSettingsApiEndpoint, awsEnvDummyRecordsApiEndpoint, awsEnvAuditApiEndpoint awsEnvTtgProgrammesApiEndpoint, awsEnvTtgEngagementsApiEndpoint, awsEnvNewsApiEndpoint, awsEnvAreaOfNeedApiEndpoint, awsEnvSupportReferralsApiEndpoint, awsEnvCaseloadApiEndpoint awsEnvHistoryApiEndpoint*/

const development = {
  usersEndpoint: `${awsEnvUsersApiEndpoint}/users`,
  organisationsEndpoint: `${awsEnvOrganisationsApiEndpoint}/organisations`,
  customersEndpoint: `${awsEnvCustomersApiEndpoint}/customers`,
  referralsEndpoint: `${awsEnvReferralsApiEndpoint}/referrals`,
  activitiesEndpoint: `${awsEnvActivitiesApiEndpoint}/activities`,
  engagementsEndpoint: `${awsEnvEngagementsApiEndpoint}/engagements`,
  programmesEndpoint: `${awsEnvProgrammesApiEndpoint}/programmes`,
  assessmentsEndpoint: `${awsEnvAssessmentsApiEndpoint}/assessments`,
  filesEndpoint: `${awsEnvFilesApiEndpoint}/files`,
  tasksEndpoint: `${awsEnvTasksApiEndpoint}/`,
  employmentsEndpoint: `${awsEnvEmploymentsApiEndpoint}/employments`,
  costsEndpoint: `${awsEnvCostsApiEndpoint}/costs`,
  reportsEndpoint: `${awsEnvReportsApiEndpoint}/reports`,
  amendmentsEndpoint: `${awsEnvAmendmentsApiEndpoint}/requests`,
  addressEndpoint: `${awsEnvOrganisationsApiEndpoint}/postcode`,
  feedbacksEndpoint: `${awsEnvFeedbackApiEndpoint}/feedback`,
  settingsEndpoint: `${awsEnvSettingsApiEndpoint}/settings`,
  auditsEndpoint: `${awsEnvAuditApiEndpoint}/audit`,
  dummyRecordsApiEndpoint: `${awsEnvDummyRecordsApiEndpoint}/dummys`,
  assurancesEndpoint: `${awsEnvFeedbackApiEndpoint}/feedback`,
  supportReferralsEndpoint: `${awsEnvSupportReferralsApiEndpoint}/supportReferrals`,
  ttgProgrammesEndpoint: `${awsEnvTtgProgrammesApiEndpoint}/ttg-programmes`,
  ttgEngagementsApiEndpoint: `${awsEnvTtgEngagementsApiEndpoint}/ttg-engagements`,
  newsEndpoint: `${awsEnvNewsApiEndpoint}/news`,
  caseloadEndpoint: `${awsEnvCaseloadApiEndpoint}/caseloads`,
  areaOfNeedEndpoint: `${awsEnvAreaOfNeedApiEndpoint}/needs`,
  historyEndpoint: `${awsEnvHistoryApiEndpoint}/audit`
};

const uat = {
  usersEndpoint: `${awsEnvUsersApiEndpoint}/users`,
  organisationsEndpoint: `${awsEnvOrganisationsApiEndpoint}/organisations`,
  customersEndpoint: `${awsEnvCustomersApiEndpoint}/customers`,
  referralsEndpoint: `${awsEnvReferralsApiEndpoint}/referrals`,
  activitiesEndpoint: `${awsEnvActivitiesApiEndpoint}/activities`,
  engagementsEndpoint: `${awsEnvEngagementsApiEndpoint}/engagements`,
  programmesEndpoint: `${awsEnvProgrammesApiEndpoint}/programmes`,
  assessmentsEndpoint: `${awsEnvAssessmentsApiEndpoint}/assessments`,
  filesEndpoint: `${awsEnvFilesApiEndpoint}/files`,
  tasksEndpoint: `${awsEnvTasksApiEndpoint}/`,
  employmentsEndpoint: `${awsEnvEmploymentsApiEndpoint}/employments`,
  costsEndpoint: `${awsEnvCostsApiEndpoint}/costs`,
  reportsEndpoint: `${awsEnvReportsApiEndpoint}/reports`,
  amendmentsEndpoint: `${awsEnvAmendmentsApiEndpoint}/requests`,
  addressEndpoint: `${awsEnvOrganisationsApiEndpoint}/postcode`,
  feedbacksEndpoint: `${awsEnvFeedbackApiEndpoint}/feedback`,
  settingsEndpoint: `${awsEnvSettingsApiEndpoint}/settings`,
  auditsEndpoint: `${awsEnvAuditApiEndpoint}/audit`,
  dummyRecordsApiEndpoint: `${awsEnvDummyRecordsApiEndpoint}/dummys`,
  assurancesEndpoint: `${awsEnvFeedbackApiEndpoint}/feedback`,
  supportReferralsEndpoint: `${awsEnvSupportReferralsApiEndpoint}/supportReferrals`,
  ttgProgrammesEndpoint: `${awsEnvTtgProgrammesApiEndpoint}/ttg-programmes`,
  ttgEngagementsApiEndpoint: `${awsEnvTtgEngagementsApiEndpoint}/ttg-engagements`,
  newsEndpoint: `${awsEnvNewsApiEndpoint}/news`,
  caseloadEndpoint: `${awsEnvCaseloadApiEndpoint}/caseloads`,
  areaOfNeedEndpoint: `${awsEnvAreaOfNeedApiEndpoint}/needs`,
  historyEndpoint: `${awsEnvHistoryApiEndpoint}/history`
};

const pp = {
  usersEndpoint: `${awsEnvUsersApiEndpoint}/users`,
  organisationsEndpoint: `${awsEnvOrganisationsApiEndpoint}/organisations`,
  customersEndpoint: `${awsEnvCustomersApiEndpoint}/customers`,
  referralsEndpoint: `${awsEnvReferralsApiEndpoint}/referrals`,
  activitiesEndpoint: `${awsEnvActivitiesApiEndpoint}/activities`,
  engagementsEndpoint: `${awsEnvEngagementsApiEndpoint}/engagements`,
  programmesEndpoint: `${awsEnvProgrammesApiEndpoint}/programmes`,
  assessmentsEndpoint: `${awsEnvAssessmentsApiEndpoint}/assessments`,
  filesEndpoint: `${awsEnvFilesApiEndpoint}/files`,
  tasksEndpoint: `${awsEnvTasksApiEndpoint}/`,
  employmentsEndpoint: `${awsEnvEmploymentsApiEndpoint}/employments`,
  costsEndpoint: `${awsEnvCostsApiEndpoint}/costs`,
  reportsEndpoint: `${awsEnvReportsApiEndpoint}/reports`,
  amendmentsEndpoint: `${awsEnvAmendmentsApiEndpoint}/requests`,
  addressEndpoint: `${awsEnvOrganisationsApiEndpoint}/postcode`,
  feedbacksEndpoint: `${awsEnvFeedbackApiEndpoint}/feedback`,
  settingsEndpoint: `${awsEnvSettingsApiEndpoint}/settings`,
  auditsEndpoint: `${awsEnvAuditApiEndpoint}/audit`,
  dummyRecordsApiEndpoint: `${awsEnvDummyRecordsApiEndpoint}/dummys`,
  assurancesEndpoint: `${awsEnvFeedbackApiEndpoint}/feedback`,
  supportReferralsEndpoint: `${awsEnvSupportReferralsApiEndpoint}/supportReferrals`,
  ttgProgrammesEndpoint: `${awsEnvTtgProgrammesApiEndpoint}/ttg-programmes`,
  ttgEngagementsApiEndpoint: `${awsEnvTtgEngagementsApiEndpoint}/ttg-engagements`,
  newsEndpoint: `${awsEnvNewsApiEndpoint}/news`,
  caseloadEndpoint: `${awsEnvCaseloadApiEndpoint}/caseloads`,
  areaOfNeedEndpoint: `${awsEnvAreaOfNeedApiEndpoint}/needs`,
  historyEndpoint: `${awsEnvHistoryApiEndpoint}/history`
};

const production = {
  usersEndpoint: `${awsEnvUsersApiEndpoint}/users`,
  organisationsEndpoint: `${awsEnvOrganisationsApiEndpoint}/organisations`,
  customersEndpoint: `${awsEnvCustomersApiEndpoint}/customers`,
  referralsEndpoint: `${awsEnvReferralsApiEndpoint}/referrals`,
  activitiesEndpoint: `${awsEnvActivitiesApiEndpoint}/activities`,
  engagementsEndpoint: `${awsEnvEngagementsApiEndpoint}/engagements`,
  programmesEndpoint: `${awsEnvProgrammesApiEndpoint}/programmes`,
  assessmentsEndpoint: `${awsEnvAssessmentsApiEndpoint}/assessments`,
  filesEndpoint: `${awsEnvFilesApiEndpoint}/files`,
  tasksEndpoint: `${awsEnvTasksApiEndpoint}/`,
  employmentsEndpoint: `${awsEnvEmploymentsApiEndpoint}/employments`,
  costsEndpoint: `${awsEnvCostsApiEndpoint}/costs`,
  reportsEndpoint: `${awsEnvReportsApiEndpoint}/reports`,
  amendmentsEndpoint: `${awsEnvAmendmentsApiEndpoint}/requests`,
  addressEndpoint: `${awsEnvOrganisationsApiEndpoint}/postcode`,
  feedbacksEndpoint: `${awsEnvFeedbackApiEndpoint}/feedback`,
  settingsEndpoint: `${awsEnvSettingsApiEndpoint}/settings`,
  auditsEndpoint: `${awsEnvAuditApiEndpoint}/audit`,
  dummyRecordsApiEndpoint: `${awsEnvDummyRecordsApiEndpoint}/dummys`,
  assurancesEndpoint: `${awsEnvFeedbackApiEndpoint}/feedback`,
  supportReferralsEndpoint: `${awsEnvSupportReferralsApiEndpoint}/supportReferrals`,
  ttgProgrammesEndpoint: `${awsEnvTtgProgrammesApiEndpoint}/ttg-programmes`,
  ttgEngagementsApiEndpoint: `${awsEnvTtgEngagementsApiEndpoint}/ttg-engagements`,
  newsEndpoint: `${awsEnvNewsApiEndpoint}/news`,
  caseloadEndpoint: `${awsEnvCaseloadApiEndpoint}/caseloads`,
  areaOfNeedEndpoint: `${awsEnvAreaOfNeedApiEndpoint}/needs`,
  historyEndpoint: `${awsEnvHistoryApiEndpoint}/history`
};

let environmentConfig = {};

switch (process.env.REACT_APP_STAGE) {
  case "development":
  default:
    environmentConfig = development;
    break;
  case "uat":
    environmentConfig = uat;
    break;
  case "pp":
    environmentConfig = pp;
    break;
  case "production":
    environmentConfig = production;
    break;
}

export const config = {
  ...environmentConfig
};
