const getUsers = userListData => {
  let inputData = [];
  if (userListData) {
    userListData.forEach((result, index) => {
      if (result["_source"]) {
        inputData.push(result["_source"]);
      }
    });
  }
  let dropdownFields = [];
  let i = 0;
  inputData.forEach((object, key) => {
    dropdownFields.push({
      key: i,
      text: object.firstName + " " + object.surname,
      value: object.userId,
      role: object.roleId
    });
    i++;
  });
  return dropdownFields;
};

export default getUsers;
