import React from "react";
import ModifyFeedback from "../../components/ModifyFeedback";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { feedbackActions } from "../../store/actions/feedback.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { authActions } from "../../../App/store/actions/auth.actions";
import { auditActions } from "../../../Audit/store/actions/audit.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  convertToIso,
  getOrganisations,
  canUserModifyRecord,
  incrementObject,
  handleLogout,
  textFormattingHelpers
} from "../../helpers";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";

class FeedbackEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableFeedbackModalOpen: false,
    enableDisableLockModalOpen: false,
    enableDisableUnlockModalOpen: false,
    initialFeedbackData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    deletionReason: undefined,
    fetchedPermissionStatus: false,
    permissionStatus: "",
    hasCancelled: false,
    showAuditLog: false,
    prePopulated: false
  };

  componentDidMount = async () => {
    try {
      await this.handleFetchData();
      await this.props.getFeedbackById(
        this.props.match.params.id,
        this.props.headers
      );
      if (!incrementObject(this.props.match.params.id)) {
        this.props.lockUser(this.props.header);
        handleLogout(
          this.props.userId,
          this.props.username,
          this.props.headers,
          this.props.createAudit,
          this.props.logout
        );
      }
      if (
        this.state.mode === "view" &&
        this.props.selectedFeedbackData.requestId
      ) {
        await this.props.getDeletePermissionStatus(
          this.props.selectedFeedbackData.requestId
        );
        await this.setState(
          { permissionStatus: this.props.permissionStatus },
          async () => {
            if (this.state.permissionStatus === strings.states.rejected) {
              await this.props.updateSelectedFeedback("requestId", null);
              this.props.updateFeedback(
                this.props.selectedFeedbackData.feedbackId,
                this.props.selectedFeedbackData,
                this.props.headers,
                this.state.initialFeedbackData,
                false
              );
            }
          }
        );
        this.props.clearAmendmentRequest();
      }
    } catch (ex) {
      throw ex;
    } finally {
      this.setState({
        fetchedPermissionStatus: true,
        globalProgrammeId: localStorage.getItem("programmeId"),
        initialFeedbackData: JSON.parse(
          JSON.stringify(this.props.selectedFeedbackData)
        )
      });
      let form = document.getElementById("engagementForm");
      if (form) {
        form.setAttribute("novalidate", true);
        //set the form to have the novalidate attribute to suppress the default html validation tooltips
      }
      this.props.getProgrammeById(
        this.state.globalProgrammeId,
        this.props.headers
      );
    }
  };

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  componentWillUnmount() {
    this.props.clearFeedbackRequest();
    this.props.clearSelectedFeedback();
    this.props.clearAmendmentRequest();
  }

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedFeedbackData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.feedback.modifyFeedback
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    const data = textFormattingHelpers.decodeData(
      this.props.selectedFeedbackData,
      this.state.initialFeedbackData,
      textFields
    );

    await this.props.updateFeedback(
      this.props.match.params.id,
      data,
      this.props.headers,
      this.state.initialFeedbackData
    );
    this.setState({
      initialFeedbackData: JSON.parse(
        JSON.stringify(this.props.selectedFeedbackData)
      )
    });
    this.handleModeSwitch();

    window.setTimeout(() => {
      this.props.clearFeedbackRequest();
    }, 10000);
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.cancelSelectedFeedbackUpdate(this.state.initialFeedbackData);
    this.props.history.goBack();
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedFeedbackData.feedbackId,
      this.state.deletionReason.value,
      strings.amendments.fullName,
      this.props.selectedFeedbackData.crmId,
      this.props.headers,
      this.props.selectedFeedbackData
    );
    this.props.updateSelectedFeedback("requestId", this.props.requestId);
    this.props.updateFeedback(
      this.props.selectedFeedbackData.feedbackId,
      this.props.selectedFeedbackData,
      this.props.headers,
      this.state.initialFeedbackData
    );
    this.setState({
      initialFeedbackData: JSON.parse(
        JSON.stringify(this.props.selectedFeedbackData)
      )
    });

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearFeedbackRequest();
    }, 10000);
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteFeedback(
      this.props.selectedFeedbackData.feedbackId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      await this.props.setAmendmentStatusActioned(
        this.props.selectedFeedbackData.requestId,
        this.props.headers,
        true,
        {
          entity: strings.amendments.entity,
          id: this.props.selectedFeedbackData.feedbackId,
          type: strings.amendments.deletion
        }
      );

      this.props.history.push("/feedback");
    }

    window.setTimeout(() => {
      this.props.clearAmendmentRequest();
      this.props.clearFeedbackRequest();
    }, 10000);
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedFeedback(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedFeedback(data.name, data.value);
    }
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedFeedbackData.active
    };
    await this.props.updateFeedback(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialFeedbackData
    );
  };

  handleToggleAudit = () => {
    const isAudit = this.state.showAuditLog;
    this.setState({ showAuditLog: !isAudit });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;

      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableFeedbackModalVisibility = () => {
    this.setState({
      enableDisableFeedbackModalOpen: !this.state.enableDisableFeedbackModalOpen
    });
  };

  toggleEnableDisableLockModalVisibility = () => {
    this.setState({
      enableDisableLockModalOpen: !this.state.enableDisableLockModalOpen
    });
  };

  toggleEnableDisableUnlockModalVisibility = () => {
    this.setState({
      enableDisableUnlockModalOpen: !this.state.enableDisableUnlockModalOpen
    });
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialFeedbackData) !==
        JSON.stringify(this.props.selectedFeedbackData)
      ) {
        await this.props.cancelSelectedFeedbackUpdate(
          this.state.initialFeedbackData
        );
        this.setState({ hasCancelled: true });
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialFeedbackData: JSON.parse(
          JSON.stringify(this.props.selectedFeedbackData)
        )
      });
    }
  };

  resetCancelledState = () => {
    this.setState(() => ({ hasCancelled: false }));
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      !this.state.fetchedPermissionStatus ||
      this.props.orgsLoadingPage ||
      this.props.programmeLoadingPage ||
      this.props.amendmentLoadingPage;

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={
                amendmentStrings.form.feedback.processing
              }
              unknownFeedbackMessage={strings.form.feedback.requestUnknown}
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          {this.props.showNotification && (
            <RequestFeedback
              className={
                this.props.amendmentRequestStatus ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.feedbackRequestStatus}
              requestMade={this.props.feedbackRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.feedbackRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}

          <ModifyFeedback
            mode={this.state.mode}
            requestStatus={this.props.feedbackRequestStatus}
            role={this.props.roleId}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editFeedback
                : strings.header.viewFeedback
            }
            selectedFeedbackData={this.props.selectedFeedbackData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            initialFeedbackData={this.state.initialFeedbackData}
            getOrganisations={this.getOrganisations}
            updateSelectedFeedback={this.props.updateSelectedFeedback}
            enableDisableFeedbackModalOpen={
              this.state.enableDisableFeedbackModalOpen
            }
            toggleEnableDisableFeedbackModalVisibility={
              this.toggleEnableDisableFeedbackModalVisibility
            }
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            isDisabled={this.isDisabled()}
            hasCancelled={this.state.hasCancelled}
            resetCancelledState={this.resetCancelledState}
            headers={this.props.headers}
            handleToggleAudit={this.handleToggleAudit}
            showAuditLog={this.state.showAuditLog}
            toggleEnableDisableLockModalVisibility={
              this.toggleEnableDisableLockModalVisibility
            }
            toggleEnableDisableUnlockModalVisibility={
              this.toggleEnableDisableUnlockModalVisibility
            }
          />
        </div>
      </div>
    );
  };
}

FeedbackEditContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedFeedbackData: PropTypes.object.isRequired,
  updateSelectedFeedback: PropTypes.func.isRequired,
  updateFeedback: PropTypes.func.isRequired,
  getFeedbackById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { feedback, auth, amendments, organisations, programmes } = state;
  const {
    loadingPage,
    selectedFeedbackData,
    feedbackRequestStatus,
    feedbackRequestMade,
    result,
    error,
    errorDetails,
    feedbackListData,
    deleteSuccessful,
    showNotification
  } = feedback;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const { roleId, organisationOfLoggedInUser, userId, username } = auth;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails,
    loadingPage: amendmentLoadingPage
  } = amendments;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  return {
    loadingPage,
    selectedFeedbackData,
    feedbackRequestStatus,
    feedbackRequestMade,
    result,
    error,
    roleId,
    errorDetails,
    feedbackListData,
    organisationListData,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    selectedProgrammeData,
    organisationOfLoggedInUser,
    programmeLoadingPage,
    orgsLoadingPage,
    amendmentRequestMade,
    amendmentRequestStatus,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails,
    userId,
    username,
    amendmentLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateFeedback: (
      id,
      data,
      headers,
      initialFeedbackData,
      showNotification = true
    ) => {
      dispatch(
        feedbackActions.updateFeedback(
          id,
          data,
          headers,
          initialFeedbackData,
          showNotification
        )
      );
    },
    getFeedbackById: async (id, headers) => {
      await dispatch(feedbackActions.getFeedbackById(id, headers));
    },
    updateSelectedFeedback: (key, value) => {
      dispatch(feedbackActions.updateSelectedFeedback(key, value));
    },
    cancelSelectedFeedbackUpdate: data => {
      dispatch(feedbackActions.cancelSelectedFeedbackUpdate(data));
    },
    clearFeedbackRequest: () => {
      dispatch(feedbackActions.clearFeedbackRequest());
    },
    clearSelectedFeedback: () => {
      dispatch(feedbackActions.clearSelectedFeedback());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    requestDeletePermission: async (
      id,
      reason,
      entity,
      recordIdentifier,
      headers,
      body
    ) => {
      await dispatch(
        amendmentActions.requestDeletePermission(
          id,
          reason,
          entity,
          recordIdentifier,
          headers,
          body
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteFeedback: async (id, headers) => {
      await dispatch(feedbackActions.deleteFeedback(id, headers));
    },
    setAmendmentStatusActioned: async (
      id,
      headers,
      sendNotification,
      entityDetails
    ) => {
      await dispatch(
        amendmentActions.setAmendmentStatusActioned(
          id,
          headers,
          sendNotification,
          entityDetails
        )
      );
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    lockUser: headers => {
      dispatch(userActions.lockUser(headers));
    },
    logout: hasExceeded => {
      dispatch(authActions.signOut(hasExceeded));
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackEditContainer);
