export const dummyRecordActionTypes = {
  CREATE_DUMMY_RECORD_REQUEST: "CREATE_DUMMY_RECORD_REQUEST",
  CREATE_DUMMY_RECORD_SUCCESS: "CREATE_DUMMY_RECORD_SUCCESS",
  CREATE_DUMMY_RECORD_FAILURE: "CREATE_DUMMY_RECORD_FAILURE",

  CLEAR_SELECTED_TTG_RECORD_REQUEST: "CLEAR_SELECTED_TTG_RECORD_REQUEST",
  CLEAR_SELECTED_TTG_RECORD_SUCCESS: "CLEAR_SELECTED_TTG_RECORD_SUCCESS",
  CLEAR_SELECTED_TTG_RECORD_FAILURE: "CLEAR_SELECTED_TTG_RECORD_FAILURE",

  CLEAR_CREATE_DUMMY_RECORD_REQUEST: "CLEAR_CREATE_DUMMY_RECORD_REQUEST",
  CLEAR_CREATE_DUMMY_RECORD_FAILURE: "CLEAR_CREATE_DUMMY_RECORD_FAILURE",
  CLEAR_CREATE_DUMMY_RECORD_SUCCESS: "CLEAR_CREATE_DUMMY_RECORD_SUCCESS"
};

export const ttgProgrammeActionTypes = {
  CREATE_TTG_PROGRAMME_REQUEST: "CREATE_TTG_PROGRAMME_REQUEST",
  CREATE_TTG_PROGRAMME_SUCCESS: "CREATE_TTG_PROGRAMME_SUCCESS",
  CREATE_TTG_PROGRAMME_FAILURE: "CREATE_TTG_PROGRAMME_FAILURE",

  CLEAR_CREATE_TTG_PROGRAMME_REQUEST: "CLEAR_CREATE_TTG_PROGRAMME_REQUEST",
  CLEAR_CREATE_TTG_PROGRAMME_FAILURE: "CLEAR_CREATE_TTG_PROGRAMME_FAILURE",
  CLEAR_CREATE_TTG_PROGRAMME_SUCCESS: "CLEAR_CREATE_TTG_PROGRAMME_SUCCESS"
};

export const ttgRecordActionTypes = {
  UPDATE_SELECTED_TTG_RECORD_REQUEST: "UPDATE_SELECTED_TTG_RECORD_REQUEST",
  UPDATE_SELECTED_TTG_RECORD_SUCCESS: "UPDATE_SELECTED_TTG_RECORD_SUCCESS",
  UPDATE_SELECTED_TTG_RECORD_FAILURE: "UPDATE_SELECTED_TTG_RECORD_FAILURE",

  DELETE_SELECTED_TTG_RECORD_PROPERTY_REQUEST:
    "DELETE_SELECTED_TTG_RECORD_PROPERTY_REQUEST",
  DELETE_SELECTED_TTG_RECORD_PROPERTY_SUCCESS:
    "DELETE_SELECTED_TTG_RECORD_PROPERTY_SUCCESS",
  DELETE_SELECTED_TTG_RECORD_PROPERTY_FAILURE:
    "DELETE_SELECTED_TTG_RECORD_PROPERTY_FAILURE"
};
