import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    amendments: {
      entity: "assurances",
      deletion: "deletion",
      fullName: "Assurance"
    },
    fieldValues: {
      improvementActions: "reviewed - improvement actions identified",
      improvementFeedback: "reviewed - improvement feedback identified",
      closed: "Closed"
    },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    header: {
      login: "Login",
      assuranceList: "Assurances",
      createAssurance: "New Assurance",
      editAssurance: "Edit Assurance",
      viewAssurance: "Assurance Details",
      loading: "Loading Information"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        administration: "Administration",
        assuranceDetails: "Assurance Details"
      },
      label: {
        crmId: "CRM ID",
        owner: "Owner",
        dateRaised: "Date raised",
        byWhom: "By whom",
        type: "Type",
        outcome: "Outcome",
        details: "Detail",
        action: "Action",
        actionCompletedBy: "Action to be completed by",
        actionStatus: "Action Status",
        notes: "Notes",
        dateActionClosed: "Date Action Closed",
        notes2: "Notes",
        feedbackAcknowledgedDate: "Feedback Acknowledged Date",
        feedbackComments: "Feedback Comments (Specialist)"
      },
      placeholder: {
        owner: "- select -",
        stateReason: "State your reason...",
        crmId: "",
        dateRaised: "",
        byWhom: "- select -",
        type: "- select -",
        outcome: "- select -",
        details: "",
        action: "",
        actionCompletedBy: "",
        actionStatus: "",
        notes: "",
        dateActionClosed: "",
        notes2: "",
        feedbackAcknowledgedDate: "",
        feedbackComments: ""
      },
      feedback: {
        processing: "Processing request...",
        assuranceRequestSuccess: "Assurance created successfully!",
        assuranceRequestRefreshPrompt:
          "Use the refresh function to see an updated assurance list.",
        assuranceRequestFailure: "Assurance creation failed.",
        assuranceRequestUnknown:
          "Something went wrong with the assurance request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        yes: "Yes",
        no: "No"
      }
    },
    multipleAssuranceActions: {
      addAction: "Add Action"
    },
    modal: {
      createAssuranceMessage: "Save assurance?",
      modifyAssuranceMessage: "Are you sure you want to save these changes?",
      deleteAssuranceMessage:
        "Are you sure you want to delete this assurance? (This action cannot be undone)",
      deleteActionMessage:
        "Are you sure you want to delete this action? (This deletion can't be undone).",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this assurance's status to active?",
      setInactiveMessage: "Set this assurance's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addAssuranceLabel: "New Assurance",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit",
      auditLabel: "Show Audit Log",
      formLabel: "Show Form"
    },
    tooltip: {},
    table: {
      header: {
        byWhom: "By Whom",
        type: "Type",
        outcome: "Outcome",
        dateRaised: "Date Raised"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    }
  }
});
