export const referralPrisonOptions = [
  { key: 1, text: "HMP Addiewell", value: "HMP Addiewell" },
  { key: 2, text: "HMP Barlinnie", value: "HMP Barlinnie" },
  { key: 3, text: "HMP Dumfries", value: "HMP Dumfries" },
  { key: 4, text: "HMP Edinburgh", value: "HMP Edinburgh" },
  { key: 5, text: "HMP Glenochil", value: "HMP Glenochil" },
  { key: 6, text: "HMP Grampian", value: "HMP Grampian" },
  { key: 7, text: "HMP Greenock", value: "HMP Greenock" },
  { key: 8, text: "HMP Inverness", value: "HMP Inverness" },
  { key: 9, text: "HMP Perth", value: "HMP Perth" },
  { key: 10, text: "HMP Kilmarnock", value: "HMP Kilmarnock" },
  { key: 11, text: "HMP Polmont", value: "HMP Polmont" },
  { key: 12, text: "HMP Low Moss", value: "HMP Low Moss" },
  { key: 13, text: "HMP Castle Huntly", value: "HMP Castle Huntly" }
];

export const referralSourceOptionsNrs = [
  { key: 1, text: "Prison self-referral", value: "Prison self-referral" },
  { key: 2, text: "Prison admissions list", value: "Prison admissions list" },
  { key: 3, text: "SPS identified customer", value: "SPS identified customer" }
];

export const referralSourceOptionsNrne = [
  { key: 1, text: "Wise Group", value: "Wise Group" },
  { key: 2, text: "CRC", value: "CRC" },
  { key: 3, text: "Thirteen", value: "Thirteen" },
  { key: 4, text: "NPS", value: "NPS" },
  { key: 5, text: "OMU", value: "OMU" },
  { key: 6, text: "NPS Rate Card Purchase", value: "NPS Rate Card Purchase" },
  { key: 7, text: "Other", value: "Other" }
];

export const outcomeNrsOptions = [
  { key: 1, text: "Follow Up", value: "Follow Up" },
  { key: 2, text: "Unsuitable", value: "Unsuitable" },
  { key: 3, text: "Declined Service", value: "Declined Service" },
  { key: 4, text: "Sign Up Scheduled", value: "Sign Up Scheduled" },
  { key: 5, text: "Customer Start", value: "Customer Start" }
];

export const outcomeNrneOptions = [
  { key: 1, text: "Suitable", value: "Suitable" },
  { key: 2, text: "Not Suitable", value: "Not Suitable" }
];

export const outcomeOfThresholdOptions = [
  { key: 1, text: "Suitable", value: "Suitable" },
  { key: 2, text: "Not Suitable", value: "Not Suitable" }
];

export const outcomeReasonEligibleOptions = [
  { key: 1, text: "Level 2 Service", value: "Level 2 Service" },
  { key: 2, text: "Level 3 Service", value: "Level 3 Service" }
];

export const relationshipOptions = [
  { key: 1, text: "Mother", value: "Mother" },
  { key: 2, text: "Father", value: "Father" },
  { key: 3, text: "Sibling", value: "Sibling" },
  { key: 4, text: "Partner", value: "Partner" },
  { key: 5, text: "Friend", value: "Friend" },
  { key: 6, text: "Other", value: "Other" }
];

export const yesNoOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" }
];

export const ageCategoryOptions = [
  { key: 1, text: "Male Prisoner 16-25", value: "Male Prisoner 16-25" },
  { key: 2, text: "Male Prisoner 26+", value: "Male Prisoner 26+" }
];

export const prisonNrneOptions = [
  { key: 1, text: "HMP Deerbolt", value: "HMP Deerbolt" },
  { key: 2, text: "HMP Durham", value: "HMP Durham" },
  { key: 3, text: "HMP Holme House", value: "HMP Holme House" },
  {
    key: 4,
    text: "HMP Kirklevington Grange",
    value: "HMP Kirklevington Grange"
  },
  { key: 5, text: "HMP Low Newton", value: "HMP Low Newton" },
  { key: 6, text: "HMP Frankland", value: "HMP Frankland" },
  { key: 7, text: "Other", value: "Other" }
];

export const prisonNrsOptions = [
  { key: 2, text: "HMP Addiewell", value: "HMP Addiewell" },
  { key: 3, text: "HMP Barlinnie", value: "HMP Barlinnie" },
  { key: 4, text: "HMP Dumfries", value: "HMP Dumfries" },
  { key: 5, text: "HMP Edinburgh", value: "HMP Edinburgh" },
  { key: 6, text: "HMP Glenochil", value: "HMP Glenochil" },
  { key: 7, text: "HMP Grampian", value: "HMP Grampian" },
  { key: 8, text: "HMP Greenock", value: "HMP Greenock" },
  { key: 9, text: "HMP Inverness", value: "HMP Inverness" },
  { key: 10, text: "HMP Kilmarnock", value: "HMP Kilmarnock" },
  { key: 11, text: "HMP Perth", value: "HMP Perth" },
  { key: 12, text: "HMP Low Moss", value: "HMP Low Moss" },
  { key: 13, text: "HMP Polmont", value: "HMP Polmont" },
  { key: 14, text: "HMP Castle Huntly", value: "HMP Castle Huntly" }
];

export const liberationLocalAreaOptions = [
  { key: 1, text: "Aberdeen City", value: "Aberdeen City" },
  { key: 2, text: "Aberdeenshire", value: "Aberdeenshire" },
  { key: 3, text: "Angus", value: "Angus" },
  { key: 4, text: "Argyll & Bute", value: "Argyll & Bute" },
  { key: 5, text: "Clackmannanshire", value: "Clackmannanshire" },
  { key: 6, text: "Dumfries & Galloway", value: "Dumfries & Galloway" },
  { key: 7, text: "Dundee City", value: "Dundee City" },
  { key: 8, text: "East Ayrshire", value: "East Ayrshire" },
  { key: 9, text: "East Dunbartonshire", value: "East Dunbartonshire" },
  { key: 10, text: "East Lothian", value: "East Lothian" },
  { key: 11, text: "East Renfrewshire", value: "East Renfrewshire" },
  { key: 12, text: "Edinburgh City", value: "Edinburgh City" },
  { key: 13, text: "Falkirk", value: "Falkirk" },
  { key: 14, text: "Fife", value: "Fife" },
  { key: 15, text: "Glasgow City", value: "Glasgow City" },
  { key: 16, text: "Highland", value: "Highland" },
  { key: 17, text: "Inverclyde", value: "Inverclyde" },
  { key: 18, text: "Midlothian", value: "Midlothian" },
  { key: 19, text: "Moray", value: "Moray" },
  { key: 20, text: "North Ayrshire", value: "North Ayrshire" },
  { key: 21, text: "North Lanarkshire", value: "North Lanarkshire" },
  { key: 22, text: "Orkney", value: "Orkney" },
  { key: 24, text: "Perth & Kinross", value: "Perth & Kinross" },
  { key: 25, text: "Renfrewshire", value: "Renfrewshire" },
  { key: 26, text: "Scottish Borders", value: "Scottish Borders" },
  { key: 27, text: "Shetland Islands", value: "Shetland Islands" },
  { key: 28, text: "South Ayrshire", value: "South Ayrshire" },
  { key: 29, text: "South Lanarkshire", value: "South Lanarkshire" },
  { key: 30, text: "Stirling", value: "Stirling" },
  { key: 31, text: "West Dunbartonshire", value: "West Dunbartonshire" },
  { key: 32, text: "West Lothian", value: "West Lothian" },
  { key: 33, text: "Western Isles", value: "Western Isles" }
];

export const yearsOptions = [
  { key: 0, text: "0", value: "0" },
  { key: 1, text: "1", value: "1" },
  { key: 2, text: "2", value: "2" },
  { key: 3, text: "3", value: "3" },
  { key: 4, text: "3+", value: "3+" }
];

export const monthsOptions = [
  { key: 0, text: "0", value: "0" },
  { key: 1, text: "1", value: "1" },
  { key: 2, text: "2", value: "2" },
  { key: 3, text: "3", value: "3" },
  { key: 4, text: "4", value: "4" },
  { key: 5, text: "5", value: "5" },
  { key: 6, text: "6", value: "6" },
  { key: 7, text: "7", value: "7" },
  { key: 8, text: "8", value: "8" },
  { key: 9, text: "9", value: "9" },
  { key: 10, text: "10", value: "10" },
  { key: 11, text: "11", value: "11" }
];

export const daysOptions = [
  { key: 0, text: "0", value: "0" },
  { key: 1, text: "1", value: "1" },
  { key: 2, text: "2", value: "2" },
  { key: 3, text: "3", value: "3" },
  { key: 4, text: "4", value: "4" },
  { key: 5, text: "5", value: "5" },
  { key: 6, text: "6", value: "6" },
  { key: 7, text: "7", value: "7" },
  { key: 8, text: "8", value: "8" },
  { key: 9, text: "9", value: "9" },
  { key: 10, text: "10", value: "10" },
  { key: 11, text: "11", value: "11" },
  { key: 12, text: "12", value: "12" },
  { key: 13, text: "13", value: "13" },
  { key: 14, text: "14", value: "14" },
  { key: 15, text: "15", value: "15" },
  { key: 16, text: "16", value: "16" },
  { key: 17, text: "17", value: "17" },
  { key: 18, text: "18", value: "18" },
  { key: 19, text: "19", value: "19" },
  { key: 20, text: "20", value: "20" },
  { key: 21, text: "21", value: "21" },
  { key: 22, text: "22", value: "22" },
  { key: 23, text: "23", value: "23" },
  { key: 24, text: "24", value: "24" },
  { key: 25, text: "25", value: "25" },
  { key: 26, text: "26", value: "26" },
  { key: 27, text: "27", value: "27" },
  { key: 28, text: "28", value: "28" },
  { key: 29, text: "29", value: "29" },
  { key: 30, text: "30", value: "30" }
];

export const signupMeetingOutcomeOptions = [
  { key: 1, text: "Follow Up", value: "Follow Up" },
  { key: 2, text: "Declined to Attend", value: "Declined to Attend" },
  { key: 3, text: "Declined Service", value: "Declined Service" },
  { key: 4, text: "Customer Start", value: "Customer Start" }
];

export const prisonOrganisationOptions = [
  { key: 1, text: "Wise Group", value: "Wise Group" },
  { key: 2, text: "SACRO", value: "SACRO" },
  { key: 3, text: "APEX", value: "APEX" },
  { key: 4, text: "Turning Point Scotland", value: "Turning Point Scotland" }
];

export const communityOrganisationOptions = [
  { key: 1, text: "Wise Group", value: "Wise Group" },
  { key: 2, text: "SACRO", value: "SACRO" },
  { key: 3, text: "APEX", value: "APEX" },
  { key: 4, text: "Turning Point Scotland", value: "Turning Point Scotland" },
  { key: 5, text: "Circle", value: "Circle" },
  { key: 6, text: "YCSA", value: "YCSA" }
];

export const outcomeOfIdentificationOptions = [
  { key: 1, text: "Eligible", value: "Eligible" }
];

export const birthGenderOptions = [
  { key: 1, text: "Male", value: "Male" },
  { key: 2, text: "Female", value: "Female" },
  { key: 3, text: "Prefer not to say", value: "Prefer not to say" }
];

export const disabilityOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" },
  { key: 3, text: "Prefer not to say", value: "Prefer not to say" }
];

export const maritalStatusOptions = [
  { key: 1, text: "Married/Living with", value: "Married/Living with" },
  { key: 2, text: "Civil Partnership", value: "Civil Partnership" },
  { key: 3, text: "Single", value: "Single" },
  { key: 4, text: "Divorced", value: "Divorced" },
  { key: 5, text: "Widowed", value: "Widowed" },
  { key: 6, text: "Separated", value: "Separated" },
  { key: 7, text: "Other (please state)", value: "Other (please state)" },
  { key: 8, text: "Prefer not to say", value: "Prefer not to say" }
];

export const currentGenderOptions = [
  { key: 1, text: "Male", value: "Male" },
  { key: 2, text: "Female", value: "Female" },
  { key: 3, text: "Transgender", value: "Transgender" },
  { key: 4, text: "Other (please state)", value: "Other (please state)" },
  { key: 5, text: "Prefer not to say", value: "Prefer not to say" }
];

export const religionOptions = [
  { key: 1, text: "No religion", value: "No religion" },
  { key: 2, text: "Christian", value: "Christian" },
  { key: 3, text: "Buddhist", value: "Buddhist" },
  { key: 4, text: "Muslim", value: "Muslim" },
  { key: 5, text: "Sikh", value: "Sikh" },
  { key: 6, text: "Hindu", value: "Hindu" },
  { key: 7, text: "Jewish", value: "Jewish" },
  { key: 8, text: "Prefer not to say", value: "Prefer not to say" },
  { key: 9, text: "Other state", value: "Other state" }
];

export const sexualOrientationOptions = [
  { key: 1, text: "Heterosexual (straight)", value: "Heterosexual (straight)" },
  { key: 2, text: "Lesbian/gay", value: "Lesbian/gay" },
  { key: 3, text: "Bisexual", value: "Bisexual" },
  { key: 4, text: "Prefer not to say", value: "Prefer not to say" }
];

export const ethnicityOptions = [
  { key: 1, text: "White Scottish", value: "White Scottish" },
  { key: 2, text: "White other British", value: "White other British" },
  { key: 3, text: "White Irish", value: "White Irish" },
  { key: 4, text: "White gypsy traveller", value: "White gypsy traveller" },
  { key: 5, text: "White Polish", value: "White Polish" },
  {
    key: 6,
    text: "Mixed/Multiple ethnic group",
    value: "Mixed/Multiple ethnic group"
  },
  {
    key: 7,
    text: "Asian - Pakistani, Pakistani Scottish, Pakistani British",
    value: "Asian - Pakistani, Pakistani Scottish, Pakistani British"
  },
  {
    key: 8,
    text: "Asian - Indian, Indian Scottish, Indian British",
    value: "Asian - Indian, Indian Scottish, Indian British"
  },
  {
    key: 9,
    text: "Asian - Bangladeshi, Bangladeshi Scottish, Bangladeshi British",
    value: "Asian - Bangladeshi, Bangladeshi Scottish, Bangladeshi British"
  },
  {
    key: 10,
    text: "Asian - Chinese, Chinese Scottish, Chinese British",
    value: "Asian - Chinese, Chinese Scottish, Chinese British"
  },
  { key: 11, text: "Asian - other", value: "Asian - other" },
  {
    key: 12,
    text: "African, African Scottish, African British",
    value: "African, African Scottish, African British"
  },
  { key: 13, text: "African other", value: "African other" },
  {
    key: 14,
    text: "Carribean, Carribean Scottish, Carribean British ",
    value: "Carribean, Carribean Scottish, Carribean British"
  },
  {
    key: 15,
    text: "Black, Black Scottish, Black British",
    value: "Black, Black Scottish, Black British"
  },
  {
    key: 16,
    text: "Carribean or Black Other",
    value: "Carribean or Black Other"
  },
  {
    key: 17,
    text: "Arab, Arab Scottish, Arab British",
    value: "Arab, Arab Scottish, Arab British"
  },
  { key: 18, text: "Other Ethnic Group", value: "Other Ethnic Group" },
  { key: 19, text: "Prefer not to say", value: "Prefer not to say" }
];

export const yesNoSensitiveOptions = [
  { key: 1, text: "N/A", value: "N/A" },
  { key: 2, text: "Yes", value: "Yes" },
  { key: 3, text: "No", value: "No" },
  { key: 4, text: "Prefer not to say", value: "Prefer not to say" }
];

export const areaOptions = [
  { key: 1, text: "NRS", value: "NRS" },
  { key: 2, text: "NRNE", value: "NRNE" }
];

export const customerLocalAuthorityAreaOptions = [
  { key: 1, text: "Darlington", value: "Darlington" },
  { key: 2, text: "Durham", value: "Durham" },
  { key: 3, text: "Hartlepool", value: "Hartlepool" },
  { key: 4, text: "Middlesbrough", value: "Middlesbrough" },
  { key: 5, text: "Redcar & Cleveland", value: "Redcar & Cleveland" },
  { key: 6, text: "Stockton", value: "Stockton" }
];

export const formTypeOptions = [
  { key: 1, text: "Referral", value: "Referral" },
  { key: 2, text: "Equal Opportunities", value: "Equal Opportunities" },
  { key: 3, text: "Support Service", value: "Support Service" }
];

export const engagementsNrs = [
  { key: 0, text: "Referral", value: "Referral" },
  { key: 1, text: "Sign-up", value: "Sign-up" },
  { key: 2, text: "Sign-up (warm)", value: "Sign-up (warm)" }
];

export const engagementsNrne = [
  { key: 0, text: "Sign-up", value: "Sign-up" },
  { key: 1, text: "Sign-up (warm)", value: "Sign-up (warm)" }
];
