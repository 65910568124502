import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      login: "Login",
      auditList: "Audit Log",
      editAudit: "Edit Audit",
      loading: "Loading Information"
    },
    table: {
      header: {
        actionType: "Action Type",
        username: "Username",
        table: "Table",
        oldDate: "Old Date",
        oldValues: "Old Values",
        newDate: "New Date",
        newValues: "New Values",
        dbActionType: "Database Action Type",
        created: "Date",
        page: "Page",
        crmId: "CRM ID"
      },
      text: {
        noResults: "No results found."
      }
    },
    feedback: {
      processing: "Processing request...",
      auditRequestSuccess: "Audit created successfully!",
      auditRequestRefreshPrompt:
        "Use the refresh function to see an updated audits list.",
      auditRequestFailure: "Audit creation failed.",
      auditRequestUnknown: "Something went wrong with the audit request.",
      referralRequestUnknown: "Something went wrong with the referral request.",
      status: "Status",
      success: "Success",
      failure: "Failure"
    },
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },

    modal: {
      pageHeader: "View this session",
      ipAddress: "IP Address: ",
      requestingUser: "Requesting User",
      entity: "Entity",
      recordIdentifier: "Record Identifier",
      statusOfRequest: "Status of Request",
      typeOfRequest: "Type of Request",
      history: "History:",
      reasonForDelete: "Reason for Deletion",
      reasonForAmendment: "Reason for Amendment"
    },
    fieldValues: {
      dbChange: "Database change"
    }
  }
});
