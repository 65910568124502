import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    areas: { NRS: "NRS", ALL: "ALL", NRNE: "NRNE", NE: "NE", TTG: "TTG" },
    text: {
      resultReportType: "result",
      templateReportType: "template",
      etsReportType: "ets",
      stpReportType: "stp",
      ALL: "ALL",
      NE: "NE",
      yes: "Yes",
      no: "No",
      both: "Both",
      uploadReferrals: "Referrals can now be saved",
      saveToUpload: "Save the report to store its referrals"
    },
    label: {
      reportViewType: "View",
      type: "Report Type",
      reportId: "Report",
      fromDate: "Date From",
      toDate: "Date To",
      partnership: "Partnership",
      partner: "Partner(s)",
      ageCategory: "Age Category",
      specialist: "Specialist",
      area: "Area",
      measure: "Measure",
      stage: "Stage",
      monthlyReport: "Monthly Report",
      yearlyReport: "Yearly Report",
      uploadSize: "Records to save",
      downloadCSVLabel: "Download CSV"
    },
    placeholder: {
      reportViewType: "- select -",
      type: "- select -",
      reportId: "- select -",
      fromDate: "- select -",
      toDate: "- select -",
      partnership: "- select -",
      partner: "- select -",
      ageCategory: "- select -",
      specialist: "- select -",
      area: "- select -",
      measure: "",
      stage: "",
      uploadSize: "- select -"
    },
    header: {
      login: "Login",
      reportList: "Reports",
      createReport: "New Report",
      editReport: "Edit Report",
      viewReport: "View Report",
      loading: "Loading Information",
      reportOptions: "Report Options",
      segmentation: "Segmentation",
      referralsList: "Referral Details"
    },
    form: {
      text: {
        dateSaved: "Date Saved"
      },
      header: {
        reportInfo: "Report Info",
        saveReport: "Save Report",
        reportDetails: "Report Details",
        savedReportDetails: "Saved Report Details"
      },
      label: {
        result: "Result",
        name: "Report Name",
        description: "Report Description",
        fromDate: "From Date",
        toDate: "To Date",
        hasReferralsSaved: "Has Referrals saved",
        totalReportReferrals: "Total Referrals saved"
      },
      placeholder: {
        result: "",
        name: "",
        description: "",
        fromDate: "- select -",
        toDate: "- select -",
        hasReferralsSaved: "",
        totalReportReferrals: ""
      },
      feedback: {
        processing: "Processing request...",
        reportRequestSuccess: "Report created successfully!",
        reportRequestRefreshPrompt:
          "Use the refresh function to see an updated reports list.",
        reportRequestFailure: "Report creation failed.",
        reportRequestUnknown: "Something went wrong with the report request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      }
    },
    modal: {
      createReportMessage: "Save report?",
      modifyReportMessage: "Are you sure you want to save these changes?",
      deleteReportMessage:
        "Are you sure you want to delete this report? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      uploadReferralsMessage:
        "Are you sure you want to save the referrals of this report?"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      addReportLabel: "New Report",
      saveAddNew: "Save & Add New",
      runLabel: "Run",
      saveReportLabel: "Save Report",
      clearLabel: "Clear",
      uploadReferrals: "Save Referrals",
      closeLabel: "Close"
    }
  }
});
