import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Icon, Popup, Input } from "semantic-ui-react";
import { dropdownHelper } from "../helpers";
import {
  strings,
  organisationTypeOptions,
  localAuthorityAreaOptions,
  prisonOptions,
  mainCategoryOptions,
  sicCodeOptions,
  socCodeOptions
} from "../resources";
import {
  ValidatedFormInput,
  ValidatedFormDropdown,
  ValidatedMultipleContacts
} from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from ".";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { AddressInput } from "../../App/components/";
import { AuditListContainer } from "../../Audit/containers/";

class ModifyOrganisation extends React.Component {
  returnToList = () => {
    this.props.history.push("/organisations");
  };

  renderModifyButtons = () => {
    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      ></Input>
    );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful && (
          <>
            <Button.Group>
              <Button
                className="editButton"
                primary
                onClick={() => {
                  this.props.handleModeSwitch("edit");
                }}
                disabled={this.props.showAuditLog}
              >
                {strings.button.editLabel}
              </Button>
            </Button.Group>
            <BasicModal
              confirmMessage={
                this.props.selectedOrganisationData.active
                  ? strings.modal.setInactiveMessage
                  : strings.modal.setActiveMessage
              }
              handleModalConfirm={this.props.handleModalConfirm}
              modalClassName="activationModal"
              modalOpen={this.props.enableDisableOrganisationModalOpen}
              showOkButton={false}
              showYesButton={true}
              showNoButton={true}
              toggleModal={
                this.props.toggleEnableDisableOrganisationModalVisibility
              }
              noLabel={strings.button.noLabel}
              yesLabel={strings.button.yesLabel}
              okLabel={strings.button.okLabel}
            />
          </>
        )}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful && (
            <Button.Group>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableOrganisationModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={
                  this.props.toggleEnableDisableOrganisationModalVisibility
                }
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderDeleteButton = () => {
    return (
      <div className="containerButtons">
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={
                  this.props.toggleEnableDisableOrganisationModalVisibility
                }
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteOrganisationMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableOrganisationModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={
                  this.props.toggleEnableDisableOrganisationModalVisibility
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };

  renderAuditButton = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="auditButton"
            onClick={() => {
              this.props.handleToggleAudit();
            }}
          >
            {!this.props.showAuditLog
              ? strings.button.auditLabel
              : strings.button.formLabel}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderCancelSaveButton = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={this.props.validateForm}
            disabled={
              JSON.stringify(this.props.selectedOrganisationData) ===
              JSON.stringify(this.props.initialOrganisationData)
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={this.props.confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={this.props.toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              this.props.mode === "create"
                ? this.props.handleModalConfirm
                : this.props.handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={this.props.cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };
  renderForm = () => {
    let showLocalAuthorityAndPrisonFields =
      this.props.selectedOrganisationData.mainType &&
      this.props.selectedOrganisationData.mainType !==
        strings.fieldValues.employer &&
      this.props.selectedOrganisationData.mainType !==
        strings.fieldValues.supportService;

    return (
      <>
        <div className="infoHeader">{strings.form.header.organisationInfo}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedOrganisationData.organisationName}
              inputLabel={strings.form.label.organisationName}
              inputName="organisationName"
              inputPlaceholder={strings.form.placeholder.organisationName}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.organisationName}
              mode={this.props.mode}
            />
          </Form.Group>
          {this.props.mode !== "view" && (
            <Form.Group>
              <AddressInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedOrganisationData.postcode}
                dropdownLabel={strings.form.label.postcodeSearch}
                dropdownName="postcode"
                dropdownOptions={this.props.addressSearchResults.postcode}
                dropdownPlaceholder={strings.form.placeholder.postcode}
                formFieldWidth={6}
                handlePostcodeChanged={this.props.handlePostcodeChanged}
                handlePostcodeClicked={this.props.handlePostcodeClicked}
                validationResult={this.props.validationResults.postcode}
                open={this.props.postcodeOpenStates.postcode}
                mode={this.props.mode}
              />
              <Button
                className="dropdownButton"
                onClick={this.props.handlePostcodeSearch}
                floated="left"
                name="postcode"
                compact
              >
                {strings.button.searchLabel}
              </Button>
            </Form.Group>
          )}
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedOrganisationData.address1}
              inputLabel={strings.form.label.address1}
              inputName="address1"
              inputPlaceholder={strings.form.placeholder.address1}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.address1}
              mode={this.props.mode}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedOrganisationData.address2}
              inputLabel={strings.form.label.address2}
              inputName="address2"
              inputPlaceholder={strings.form.placeholder.address2}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.address2}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedOrganisationData.postcode}
              inputLabel={strings.form.label.postcode}
              inputPlaceholder={strings.form.placeholder.postcode}
              inputName="postcode"
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.postcode}
              mode={this.props.mode}
              popUp={
                <Popup
                  content={strings.tooltip.postcodeFormatContent}
                  key="postcode"
                  header={strings.tooltip.postcodeFormatHeader}
                  trigger={<Icon className="info tooltip" name="info circle" />}
                />
              }
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedOrganisationData.phone}
              inputLabel={strings.form.label.phone}
              inputPlaceholder={strings.form.placeholder.phone}
              inputName="phone"
              formFieldWidth={8}
              onKeyPress={this.props.allowNumericalInput}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.phone}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedOrganisationData.website}
              inputLabel={strings.form.label.website}
              inputPlaceholder={strings.form.placeholder.website}
              inputName="website"
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.website}
              required={false}
              mode={this.props.mode}
              popUp={
                <Popup
                  content={strings.tooltip.websiteFormatContent}
                  key="website"
                  header={strings.tooltip.websiteFormatHeader}
                  trigger={<Icon className="info tooltip" name="info circle" />}
                />
              }
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedOrganisationData.email}
              inputLabel={strings.form.label.email}
              inputPlaceholder={strings.form.placeholder.email}
              inputName="email"
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.email}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedOrganisationData.mainType}
              viewData={this.props.selectedOrganisationData.mainType}
              dropdownLabel={strings.form.label.mainType}
              dropdownName="mainType"
              dropdownOptions={organisationTypeOptions}
              dropdownPlaceholder={strings.form.placeholder.mainType}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.mainType}
              mode={this.props.mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedOrganisationData.mainCategory}
              viewData={this.props.selectedOrganisationData.mainCategory}
              dropdownLabel={strings.form.label.mainCategory}
              dropdownName="mainCategory"
              dropdownOptions={mainCategoryOptions}
              dropdownPlaceholder={strings.form.placeholder.mainCategory}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.mainCategory}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          {this.props.selectedOrganisationData.mainType ===
            strings.fieldValues.employer && (
            <>
              <Form.Group>
                <ValidatedFormDropdown
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedOrganisationData.sicCode}
                  viewData={
                    dropdownHelper.getNameFromValues(
                      this.props.selectedOrganisationData.sicCode,
                      sicCodeOptions
                    ) || ""
                  }
                  dropdownLabel={strings.form.label.sicCode}
                  dropdownName="sicCode"
                  dropdownOptions={sicCodeOptions}
                  dropdownPlaceholder={strings.form.placeholder.sicCode}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={this.props.validationResults.sicCode}
                  mode={this.props.mode}
                />
                <ValidatedFormDropdown
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedOrganisationData.socCode}
                  viewData={
                    dropdownHelper.getNameFromValues(
                      this.props.selectedOrganisationData.socCode,
                      socCodeOptions
                    ) || ""
                  }
                  dropdownLabel={strings.form.label.socCode}
                  dropdownName="socCode"
                  dropdownOptions={socCodeOptions}
                  dropdownPlaceholder={strings.form.placeholder.socCode}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={this.props.validationResults.socCode}
                  mode={this.props.mode}
                />
              </Form.Group>
              <Form.Group>
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedOrganisationData.osCode}
                  inputLabel={strings.form.label.osCode}
                  inputPlaceholder={strings.form.placeholder.osCode}
                  inputName="osCode"
                  formFieldWidth={8}
                  onKeyPress={this.props.allowNumericalInput}
                  handleChange={this.props.handleChange}
                  validationResult={this.props.validationResults.osCode}
                  mode={this.props.mode}
                />
              </Form.Group>
            </>
          )}
          {showLocalAuthorityAndPrisonFields && (
            <>
              <Form.Group>
                <ValidatedFormDropdown
                  formInvalid={this.props.formInvalid}
                  inputData={
                    this.props.selectedOrganisationData.localAuthorityArea || []
                  }
                  viewData={
                    this.props.selectedOrganisationData.localAuthorityArea
                  }
                  dropdownLabel={strings.form.label.localAuthorityArea}
                  dropdownName="localAuthorityArea"
                  dropdownOptions={localAuthorityAreaOptions}
                  dropdownPlaceholder={
                    strings.form.placeholder.localAuthorityArea
                  }
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={
                    this.props.validationResults.localAuthorityArea
                  }
                  dropdownFluid={true}
                  dropdownMultiple={true}
                  required={false}
                  mode={this.props.mode}
                />
                <ValidatedFormDropdown
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedOrganisationData.prisons || []}
                  viewData={this.props.selectedOrganisationData.prisons}
                  dropdownLabel={strings.form.label.prisons}
                  dropdownName="prisons"
                  dropdownOptions={prisonOptions}
                  dropdownPlaceholder={strings.form.placeholder.prisons}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={this.props.validationResults.prisons}
                  dropdownFluid={true}
                  dropdownMultiple={true}
                  required={false}
                  mode={this.props.mode}
                />
              </Form.Group>
            </>
          )}
          <Form.Group>
            <ValidatedMultipleContacts
              selectedOrganisationData={this.props.selectedOrganisationData}
              validationResults={this.props.validationResults}
              formInvalid={this.props.formInvalid}
              handleContactChange={this.props.handleContactChange}
              allowNumericalInput={this.props.allowNumericalInput}
              mode={this.props.mode}
              toggleDeleteModal={this.props.toggleDeleteModal}
              addContact={this.props.addContact}
              handleModalConfirm={this.props.handleModalConfirm}
              deleteModalOpen={this.props.deleteModalOpen}
              contactNameString={strings.form.label.contactName}
              contactPhoneString={strings.form.label.contactPhone}
              contactEmailString={strings.form.label.contactEmail}
              contactNamePlaceholder={strings.form.placeholder.contactName}
              contactPhonePlaceholder={strings.form.placeholder.contactPhone}
              contactEmailPlaceholder={strings.form.placeholder.contactEmail}
              deleteContactMessage={strings.modal.deleteContactMessage}
              noLabel={strings.button.noLabel}
              yesLabel={strings.button.yesLabel}
              okLabel={strings.button.okLabel}
            />
          </Form.Group>
        </div>
      </>
    );
  };

  render = () => {
    return (
      <div className="container">
        <ComponentHeader
          returnToList={this.returnToList}
          pageTitle={this.props.pageTitle}
          mode={this.props.mode}
          requestStatus={this.props.requestStatus}
          modifyButtons={this.renderModifyButtons()}
          cancelSaveButtons={this.renderCancelSaveButton()}
          auditLog={this.renderAuditButton()}
          deleteButton={this.renderDeleteButton()}
          headerList={strings.header.organisationList}
          permissionsUpdate={permissions.organisations.update}
          permissionsCreate={permissions.organisations.create}
          permissionsDelete={permissions.organisations.delete}
          formInvalid={this.props.formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />
        {this.props.showAuditLog ? (
          <AuditListContainer
            embedded={true}
            headers={this.props.headers}
            history={this.props.history}
            entityId={this.props.selectedOrganisationData.organisationId}
            hideSearchBar={true}
            record={true}
          />
        ) : (
          <Form id="organisationForm" className="infoBlock stickyForm">
            <fieldset disabled={this.props.mode === "view"}>
              {this.renderForm()}
            </fieldset>
          </Form>
        )}
      </div>
    );
  };
}

ModifyOrganisation.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedOrganisationData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleContactChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validateForm: PropTypes.func.isRequired,
  validationResults: PropTypes.object,
  allowNumericalInput: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  addContact: PropTypes.func,
  removeContact: PropTypes.func,
  initialOrganisationData: PropTypes.object.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  deleteModalOpen: PropTypes.bool.isRequired,
  deletePermissionStatus: PropTypes.string,
  handleModalInputChange: PropTypes.func,
  deletionReason: PropTypes.object
};

export default withRouter(ModifyOrganisation);
