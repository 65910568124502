import React from "react";
import TableComponent from "../../components/AssuranceTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { assuranceActions } from "../../store/actions/assurance.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import { canUserModifyRecord } from "../../helpers";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";

const requestTimeout = 20000;

class AssuranceListContainer extends React.Component {
  state = {
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    searchCriteriaForAssurances: {
      size: 20,
      from: null,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false,
      feedbackType: "Assurance"
    },
    searchCriteriaForUsers: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false,
      programmeId: undefined
    },
    globalProgrammeId: localStorage.getItem("programmeId"),
    searchBarQuery: ""
  };

  componentDidMount = async () => {
    if (!localStorage.getItem("programmeId")) {
      this.props.history.goBack();
    }
    this.timerID = setTimeout(() => {
      if (
        this.props.assuranceRequestStatus === undefined &&
        this.props.assuranceRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearAssuranceRequest();
  }

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    this.props.clearAssuranceRequest();
    await this.handleFetchData(this.state);
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteriaForAssurances };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteriaForAssurances: searchCriteria });
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleFetchData = async state => {
    const sCritForAssurances = this.state.searchCriteriaForAssurances;
    const sCritForUsers = this.state.searchCriteriaForUsers;

    await this.props.getUsers(
      this.props.headers,
      sCritForUsers.query,
      sCritForUsers.size,
      state.page * sCritForUsers.size,
      sCritForUsers.clear,
      sCritForUsers.fields,
      sCritForUsers.sort,
      sCritForUsers.activeOnly
    );

    await this.props.getProgrammeById(this.state.globalProgrammeId);
    let sortObject = {};
    if (state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }
    sCritForAssurances.query = this.state.searchBarQuery;
    sCritForAssurances.fields = ["type.normalizer", "outcome.normalizer"];
    sCritForAssurances.programmeId = this.state.globalProgrammeId;
    await this.props.updateSearchCriteria(
      this.props.headers,
      sCritForAssurances.query,
      sCritForAssurances.size,
      sCritForAssurances.from,
      sCritForAssurances.clear,
      sCritForAssurances.fields,
      sCritForAssurances.sort,
      sCritForAssurances.activeOnly,
      sCritForAssurances.feedbackType,
      sCritForAssurances.programmeId
    );
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      default:
        break;
    }
  };

  isCreateDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.usersLoadingPage ||
      this.props.programmeLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.requestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          <RequestFeedback
            requestStatus={this.props.assuranceRequestStatus}
            requestMade={this.props.assuranceRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.assuranceRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={
              strings.form.feedback.assuranceRequestUnknown
            }
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            assuranceListData={this.props.assuranceListData}
            handleModalConfirm={this.handleModalConfirm}
            handleRefresh={this.handleRefresh}
            pageSize={this.state.searchCriteriaForAssurances.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalResults={this.props.totalResults}
            updatePageIndex={this.updatePageIndex}
            area={this.props.selectedProgrammeData.area}
            userListData={this.props.userListData}
            searchBarQuery={this.state.searchBarQuery}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            isCreateDisabled={this.isCreateDisabled()}
          />
        </div>
      </div>
    );
  };
}

AssuranceListContainer.propTypes = {
  assuranceListData: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { assurances, auth, programmes, users, amendments } = state;
  const { roleId, organisationOfLoggedInUser } = auth;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  const { userListData, loadingPage: usersLoadingPage } = users;

  const {
    assuranceListData,
    loadingPage,
    assuranceRequestStatus,
    assuranceRequestMade,
    result,
    error,
    totalResults,
    errorDetails
  } = assurances;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails
  } = amendments;
  return {
    assuranceListData,
    loadingPage,
    assuranceRequestStatus,
    assuranceRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails,
    selectedProgrammeData,
    userListData,
    organisationOfLoggedInUser,
    usersLoadingPage,
    programmeLoadingPage,
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSearchCriteria: (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      feedbackType,
      programmeId
    ) => {
      dispatch(
        assuranceActions.updateSearchCriteria(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          feedbackType,
          programmeId
        )
      );
    },

    clearAssuranceRequest: () => {
      dispatch(assuranceActions.clearAssuranceRequest());
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssuranceListContainer);
