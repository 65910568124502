import { assuranceActionTypes } from "../actions/assurance.actionTypes";

const initialState = {
  loadingPage: false,
  selectedAssuranceData: {},
  assuranceListData: [{}],
  totalResults: 0,
  assuranceToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  assuranceRequestStatus: undefined,
  assuranceRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let details;
  let newTotal;
  switch (action.type) {
    case assuranceActionTypes.CREATE_ASSURANCE_REQUEST:
      return {
        ...state,
        assuranceRequestStatus: undefined,
        assuranceRequestMade: true
      };
    case assuranceActionTypes.CREATE_ASSURANCE_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        assuranceRequestStatus: true
      };
    case assuranceActionTypes.CREATE_ASSURANCE_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        assuranceRequestStatus: false
      };

    case assuranceActionTypes.CLEAR_CREATE_ASSURANCE_REQUEST:
      return { ...state };
    case assuranceActionTypes.CLEAR_CREATE_ASSURANCE_SUCCESS:
      return {
        ...state,
        assuranceRequestMade: false,
        assuranceRequestStatus: undefined
      };
    case assuranceActionTypes.CLEAR_CREATE_ASSURANCE_FAILURE:
      return { ...state };

    case assuranceActionTypes.UPDATE_ASSURANCE_REQUEST:
      return {
        ...state,
        loadingPage: true,
        assuranceRequestStatus: undefined,
        assuranceRequestMade: true
      };
    case assuranceActionTypes.UPDATE_ASSURANCE_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        assuranceRequestStatus: true,
        selectedAssuranceData: action.response.data.Body,
        showNotification: action.showNotification
      };
    case assuranceActionTypes.UPDATE_ASSURANCE_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        assuranceRequestStatus: false,
        selectedAssuranceData: action.initialDetails,
        errorDetails: details
      };

    case assuranceActionTypes.DELETE_ASSURANCE_REQUEST:
      return {
        ...state,
        assuranceRequestStatus: undefined,
        assuranceRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case assuranceActionTypes.DELETE_ASSURANCE_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        assuranceRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case assuranceActionTypes.DELETE_ASSURANCE_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        assuranceRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case assuranceActionTypes.GET_ASSURANCES_REQUEST:
      return { ...state, loadingPage: true };
    case assuranceActionTypes.GET_ASSURANCES_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Body.hits.total) {
        newTotal = action.response.data.Body.hits.total;
        rawHits = action.response.data.Body.hits.hits.slice();
      } else {
        rawHits = [];
      }
      return {
        ...state,
        assuranceListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case assuranceActionTypes.GET_ASSURANCES_FAILURE:
      return { ...state, assuranceListData: [{}], loadingPage: false };

    case assuranceActionTypes.GET_ASSURANCE_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        assuranceRequestStatus: undefined
      };
    case assuranceActionTypes.GET_ASSURANCE_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        assuranceRequestStatus: true,
        selectedAssuranceData: action.response.Body,
        loadingPage: false
      };
    case assuranceActionTypes.GET_ASSURANCE_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedAssuranceData: {},
        assuranceRequestStatus: false,
        assuranceRequestMade: true
      };

    case assuranceActionTypes.UPDATE_SELECTED_ASSURANCE_REQUEST:
      return { ...state };
    case assuranceActionTypes.UPDATE_SELECTED_ASSURANCE_SUCCESS:
      return {
        ...state,
        selectedAssuranceData: {
          ...state.selectedAssuranceData,
          [action.key]: action.value
        }
      };
    case assuranceActionTypes.UPDATE_SELECTED_ASSURANCE_FAILURE:
      return { ...state };

    case assuranceActionTypes.CLEAR_SELECTED_ASSURANCE_REQUEST:
      return { ...state };
    case assuranceActionTypes.CLEAR_SELECTED_ASSURANCE_SUCCESS:
      return { ...state, selectedAssuranceData: {} };
    case assuranceActionTypes.CLEAR_SELECTED_ASSURANCE_FAILURE:
      return { ...state };

    case assuranceActionTypes.CANCEL_SELECTED_ASSURANCE_UPDATE_REQUEST:
      return { ...state };
    case assuranceActionTypes.CANCEL_SELECTED_ASSURANCE_UPDATE_SUCCESS:
      return { ...state, selectedAssuranceData: action.data };
    case assuranceActionTypes.CANCEL_SELECTED_ASSURANCE_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};
