// Maps data fetched from the files microservice to a stripped format required for remote/local comparison on submit
const mapToRemoteData = fetchedData => {
  let mappedRemoteData = [];
  if (Object.values(fetchedData).length > 0) {
    Object.values(fetchedData).forEach(file => {
      const remoteFileEntry = {
        fileName: file.fileName,
        description: file.metadata.description || ""
      };
      mappedRemoteData.push(remoteFileEntry);
    });
  }
  return mappedRemoteData;
};

// Maps data fetched from the files microservice to a display and change minded format required for remote/local comparison on submit
const mapToLocalData = fetchedData => {
  let mappedLocalData = [];
  if (Object.values(fetchedData).length > 0) {
    Object.values(fetchedData).forEach(file => {
      const localFileEntry = {
        fileName: file.fileName,
        url: file.url,
        description: file.metadata.description || "",
        fileContent: ""
      };
      mappedLocalData.push(localFileEntry);
    });
  }
  return mappedLocalData;
};

// Maps local data changes to three arrays with API requests to delete, update and add files
const getDiffs = (
  localData,
  remoteData,
  filePath,
  entity,
  entityId,
  folder = null
) => {
  let filesToUpload = [];
  let filesToModify = [];
  localData &&
    localData.forEach(localFile => {
      if (!localFile.new) {
        const matchToRemote = getFileWithName(remoteData, localFile.fileName);
        if (localFile.description !== matchToRemote.description) {
          const modification = {
            filePath: `${filePath}${entityId}/${folder}/${localFile.fileName}`,
            metadata: {
              description: localFile.description
            },
            entityId: entityId,
            entity: entity
          };
          filesToModify.push(modification);
        }
      } else {
        const upload = {
          filePath: `${filePath}${entityId}/${folder}/${localFile.fileName}`,
          fileContent: localFile.fileContent,
          metadata: {
            description: localFile.description
          },
          entityId: entityId,
          entity: entity,
          folder: folder
        };
        filesToUpload.push(upload);
        delete localFile.new;
      }
    });

  let filesToDelete = [];

  remoteData &&
    remoteData.forEach(remoteFile => {
      const matchToLocal = getFileWithName(localData, remoteFile.fileName);
      if (!matchToLocal) {
        filesToDelete.push({
          filePath: `${filePath}${entityId}/${folder}/${remoteFile.fileName}`,
          entityId: entityId,
          entity: entity
        });
      }
    });
  const diffActions = {
    uploads: filesToUpload,
    deletes: filesToDelete,
    updates: filesToModify
  };
  return diffActions;
};

// If file found in array with matching name, return that file object, otherwise return false
const getFileWithName = (fileData = [], fileName) => {
  let fileObj = {};
  Object.values(fileData).forEach(file => {
    if (file.fileName === fileName) {
      fileObj = Object.assign({}, file);
    }
  });
  if (Object.keys(fileObj).length > 0) {
    return fileObj;
  } else {
    return false;
  }
};

const fileHelper = {
  mapToLocalData,
  mapToRemoteData,
  getDiffs
};
export default fileHelper;
