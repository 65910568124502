import React from "react";
import ModifyProgramme from "../../components/ModifyProgramme";
import moment from "moment";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, textFields } from "../../resources";
import { programmeActions } from "../../store/actions/programme.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { authActions } from "../../../App/store/actions/auth.actions";
import { auditActions } from "../../../Audit/store/actions/audit.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  getOrganisations,
  convertToIso,
  getListOfMentors,
  canUserModifyRecord,
  commitFileChanges,
  diffObjects,
  arrayHelpers,
  incrementObject,
  handleLogout,
  textFormattingHelpers
} from "../../helpers/index";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { referralActions } from "../../../Referrals/store/actions/referral.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { fileActions } from "../../../File/store/actions/file.actions";
import { strings as amendmentStrings } from "../../../Amendments/resources/Strings";

class ProgrammeEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    confirmSubmitAmendmentModalOpen: false,
    cancelChangesModalOpen: false,
    shareProgrammeModalOpen: false,
    enableDisableProgrammeModalOpen: false,
    initialProgrammeData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    hasChoseForm: false,
    hasFileChanges: false,
    deletionReason: undefined,
    amendmentReason: undefined,
    permissionStatus: "",
    showAuditLog: false,
    showSupportReferral: false
  };

  chooseForm = async () => {
    this.setState({ hasChoseForm: true });
  };

  componentDidMount = async () => {
    try {
      await this.props.getReferralStats({ stat: "communityMentor" });
      await this.props.getProgrammeById(
        this.props.match.params.id,
        this.props.headers
      );
      localStorage.setItem("programmeId", this.props.selectedProgrammeData.programmeId);
      localStorage.setItem(
        "programmeName",
        `${this.props.selectedProgrammeData.firstName} ${this.props.selectedProgrammeData.surname}`
      );
      localStorage.setItem("programmeArea", this.props.selectedProgrammeData.area);
      localStorage.setItem(
        "parentReferralId",
        this.props.selectedProgrammeData.referralId
      );
      await this.props.getReferralById(
        this.props.selectedProgrammeData.referralId,
        this.props.headers
      );
      if (!incrementObject(this.props.match.params.id)) {
        this.props.lockUser(this.props.header);
        handleLogout(
          this.props.userId,
          this.props.username,
          this.props.headers,
          this.props.createAudit,
          this.props.logout
        );
      }
      if (this.props.selectedProgrammeData.communityMentor) {
        await this.props.getUserById(
          this.props.selectedProgrammeData.communityMentor
        );
      }

      if (this.props.selectedProgrammeData.communityMentor) {
        await this.props.getUserById(
          this.props.selectedProgrammeData.communityMentor
        );
      }
      if (this.props.selectedProgrammeData.prisonSpecialist) {
        await this.props.getPrisonMentorById(
          this.props.selectedProgrammeData.prisonSpecialist
        );
      }
      if (this.props.selectedProgrammeData.mentor) {
        await this.props.getUserById(this.props.selectedProgrammeData.mentor);
      }

      this.setState({
        initialProgrammeData: this.props.selectedProgrammeData
      });
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
      await this.handleFetchData();
      let form = document.getElementById("programmeForm");
      if (form) form.setAttribute("novalidate", true);
    } catch (ex) {
      throw ex;
    } finally {
      if (
        this.state.mode === "view" &&
        this.props.selectedProgrammeData.requestId
      ) {
        await this.props.getDeletePermissionStatus(
          this.props.selectedProgrammeData.requestId
        );
        await this.setState(
          { permissionStatus: this.props.permissionStatus },
          async () => {
            if (this.state.permissionStatus === strings.states.rejected) {
              await this.props.updateSelectedProgramme("requestId", null);
              this.props.updateProgramme(
                this.props.selectedProgrammeData.programmeId,
                this.props.selectedProgrammeData,
                this.props.headers,
                this.state.initialProgrammeData,
                false
              );
            }
          }
        );
        this.props.clearAmendmentRequest();
      }
      this.setState({ initialProgrammeData: this.props.selectedProgrammeData });
    }
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  componentWillUnmount() {
    this.props.clearProgrammeRequest();
    this.props.clearSelectedProgramme();
    this.props.clearSelectedUser();
    this.props.clearPrisonMentor();
  }

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      true
    );
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedProgrammeData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.programmes.modifyProgramme
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else if (!results && this.state.mode === "edit") {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    } else if (!results && this.state.mode === "amend") {
      this.setState({ formInvalid: false });
      this.toggleConfirmSubmitAmendmentModalVisibility();
    }
  };

  allowNumericalInput = e => {
    e = e || window.event;
    const charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedProgrammeData.programmeId,
      this.state.deletionReason.value,
      strings.amendments.fullName,
      this.props.selectedProgrammeData.crmId,
      this.props.headers,
      this.props.selectedProgrammeData
    );
    this.props.updateSelectedProgramme("requestId", this.props.requestId);
    this.props.updateProgramme(
      this.props.selectedProgrammeData.programmeId,
      this.props.selectedProgrammeData,
      this.props.headers,
      this.state.initialProgrammeData
    );
    this.setState({
      initialProgrammeData: this.props.selectedProgrammeData
    });

    window.setTimeout(() => {
      this.props.clearProgrammeRequest();
      this.props.clearAmendmentRequest();
    }, 10000);
  };

  handleChangeModal = (e, data) => {
    if (data.name === "amendmentReason") {
      this.setState({ amendmentReason: data.value });
    } else {
      this.setState({ deletionReason: data });
    }
  };

  handleDelete = async () => {
    await this.props.deleteProgramme(
      this.props.selectedProgrammeData.programmeId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      await this.props.setAmendmentStatusActioned(
        this.props.selectedProgrammeData.requestId,
        this.props.headers,
        true,
        {
          entity: strings.amendments.entity,
          id: this.props.selectedProgrammeData.programmeId,
          type: strings.amendments.deletion
        }
      );

      this.props.history.push("/programmes");
    }
  };

  handleSubmit = async () => {
    if (this.state.hasFileChanges) {
      await commitFileChanges(
        this.props.commitChanges,
        this.props.localData,
        this.props.remoteData,
        strings.folder.noteDocuments,
        strings.entity.programme,
        this.props.selectedProgrammeData.programmeId
      );
    }
    if (this.props.roleId === strings.roles.mentor) {
      if (
        (this.props.selectedProgrammeData.wantsCollected ===
          strings.fieldValues.yes &&
          this.state.initialProgrammeData.wantsCollected !==
            strings.fieldValues.yes &&
          this.props.selectedProgrammeData.overallEDL) ||
        (this.props.selectedProgrammeData.wantsCollected ===
          strings.fieldValues.yes &&
          this.props.selectedProgrammeData.overallEDL !==
            this.state.initialProgrammeData.overallEDL)
      ) {
        if (
          this.props.selectedProgrammeData.wantsCollected ===
            strings.fieldValues.yes &&
          this.props.selectedProgrammeData.overallEDL !==
            this.state.initialProgrammeData.overallEDL
        ) {
          this.deleteCalendarEvent();
        }

        // All day events must start at midnight and last at least 1 day
        let midnight = moment(
          this.props.selectedProgrammeData.overallEDL
        ).utcOffset(0);
        midnight.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        midnight.toISOString();
        midnight.format();

        const event = {
          subject:
            "Gate Liberation - " +
            this.props.selectedProgrammeData.firstName +
            " " +
            this.props.selectedProgrammeData.surname,
          body: {
            contentType: "HTML",
            content: "Collection reminder"
          },
          start: {
            dateTime: midnight,
            timeZone: "British Summer Time"
          },
          end: {
            dateTime: moment(midnight).add(1, "days"),
            timeZone: "British Summer Time"
          },
          location: {
            displayName: this.props.selectedReferralData
              ? this.props.selectedReferralData.prisonNrs
                ? this.props.selectedReferralData.prisonNrs
                : "No prison specified"
              : "No prison specified"
          },
          attendees: [
            {
              emailAddress: {
                address: this.props.microsoftUser.email,
                name: this.props.microsoftUser.displayName
              },
              type: "required"
            }
          ],
          allowNewTimeProposals: true,
          isOnlineMeeting: false,
          onlineMeetingProvider: "teamsForBusiness",
          isAllDay: true
        };
        const eventId = await this.props.createCalendarEvent(event);
        this.props.updateSelectedProgramme("outlookEventId", eventId);
      } else if (
        this.props.selectedProgrammeData.wantsCollected ===
          strings.fieldValues.no &&
        this.props.selectedProgrammeData.outlookEventId
      ) {
        this.deleteCalendarEvent();
      }
    }

    const data = textFormattingHelpers.decodeData(
      this.props.selectedProgrammeData,
      this.state.initialProgrammeData,
      textFields
    );

    await this.props.updateProgramme(
      this.props.match.params.id,
      data,
      this.props.headers,
      this.state.initialProgrammeData
    );
    this.handleModeSwitch();

    window.setTimeout(() => {
      this.props.clearProgrammeRequest();
    }, 10000);
  };

  deleteCalendarEvent = () => {
    this.props.removeCalendarEvent(
      this.props.selectedProgrammeData.outlookEventId
    );
    this.props.updateSelectedProgramme("outlookEventId", undefined);
  };

  handleAmendmentRequest = async () => {
    const initialProgrammeData = this.state.initialProgrammeData;
    const amendedProgrammeData = this.props.selectedProgrammeData;

    const { oldValues, newValues } = diffObjects(
      initialProgrammeData,
      amendedProgrammeData
    );

    this.props.createAmendmentRequest(
      this.props.selectedProgrammeData.programmeId,
      oldValues,
      newValues,
      this.props.selectedProgrammeData.crmId,
      "Programme",
      this.state.amendmentReason,
      this.props.headers
    );

    this.handleModeSwitch();
    this.resetReasonsOnModalClose();

    window.setTimeout(() => {
      this.props.clearProgrammeRequest();
      this.props.clearAmendmentRequest();
    }, 10000);
  };

  resetSharedDataOnModalClose = () => {
    this.props.updateSelectedProgramme(
      "sharedWith",
      this.state.initialProgrammeData.sharedWith
    );
  };

  resetReasonsOnModalClose = () => {
    this.setState({ deletionReason: undefined, amendmentReason: undefined });
  };

  handleShare = async () => {
    let body = { sharedWith: this.props.selectedProgrammeData.sharedWith };

    await this.props.updateProgramme(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialProgrammeData
    );
    this.setState({
      initialProgrammeData: this.props.selectedProgrammeData
    });
  };

  handleToggleAudit = () => {
    const isAudit = this.state.showAuditLog;
    this.setState({ showAuditLog: !isAudit });
  };

  handleChange = (event, data) => {
    // Prevents "whoMetAtGate" from showing when "wantsCollected" is "Yes"
    if (data.name === "wantsCollected") {
      this.props.updateSelectedProgramme("alternativeArrangements", "");
    // Prevents specialists from the wrong organisation being assigned
    } else if (data.name === "prisonOrganisation") {
      this.props.updateSelectedProgramme("prisonSpecialist", undefined);
    }
    if (data.type === "checkbox") {
      this.props.updateSelectedProgramme(data.name, data.checked);
    } else if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      this.props.updateSelectedProgramme(data.name, convertToIso(data.value));
    } else {
      this.props.updateSelectedProgramme(data.name, data.value);
    }
  };

  resetForm = async () => {
    this.setState({ hasChoseForm: false });
    await this.props.updateSelectedProgramme("engagementType", undefined);
    this.setState({ showSupportReferral: false });
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedProgrammeData.active
    };

    await this.props.updateProgramme(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialProgrammeData
    );
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "shareModal":
        this.handleShare();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;
      case "confirmSubmitAmendmentModal":
        this.handleAmendmentRequest();
        break;

      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleConfirmSubmitAmendmentModalVisibility = () => {
    this.setState({
      confirmSubmitAmendmentModalOpen: !this.state
        .confirmSubmitAmendmentModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleShareProgrammeModalVisibility = () => {
    this.setState({
      shareProgrammeModalOpen: !this.state.shareProgrammeModalOpen
    });
  };

  toggleEnableDisableProgrammeModalVisibility = () => {
    this.setState({
      enableDisableProgrammeModalOpen: !this.state
        .enableDisableProgrammeModalOpen
    });
  };

  handleModeSwitch = async mode => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialProgrammeData) !==
        JSON.stringify(this.props.selectedProgrammeData)
      ) {
        await this.props.cancelSelectedProgrammeUpdate(
          this.state.initialProgrammeData
        );
      }
      this.setState({ mode: "view", formInvalid: false });
    } else if (this.state.mode === "amend") {
      await this.props.cancelSelectedProgrammeUpdate(
        this.state.initialProgrammeData
      );
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialProgrammeData: JSON.parse(
          JSON.stringify(this.props.selectedProgrammeData)
        )
      });
    }
  };

  handleAmendmentMode = () => {
    this.setState({ mode: "amend" });
  };

  filesHaveChanged = () => {
    this.setState({ hasFileChanges: true });
  };

  getMentors = (area, organisation) => {
    let mentors = [];
    mentors = getListOfMentors(
      area,
      this.props.userListData,
      organisation,
      this.props.refStats,
      this.props.selectedUserData,
      this.props.prisonMentor
    );

    return arrayHelpers.removeDuplicates(mentors, mentor => mentor.text);
  };

  getMentorsAll = area => {
    return getListOfMentors(
      area,
      this.props.userListData,
      undefined,
      undefined,
      this.props.selectedUserData,
      this.props.prisonMentor
    );
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.state.initialProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  handleShowSupportReferral = () => {
    this.setState({ showSupportReferral: true });
  };

  pageTitle = () => {
    switch (this.state.mode) {
      case "edit":
        return strings.header.editProgramme;
      case "view":
        return strings.header.viewProgramme;
      case "amend":
        return strings.header.amendProgramme;
      default:
        return;
    }
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.orgsLoadingPage ||
      this.props.usersLoadingPage ||
      this.props.amendmentLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          {this.props.amendmentRequestStatus && (
            <RequestFeedback
              requestStatus={this.props.amendmentRequestStatus}
              requestMade={this.props.amendmentRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.amendmentResult}
              failureMessage={this.props.amendmentError}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                amendmentStrings.form.feedback.requestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.amendmentErrorDetails}
            />
          )}
          {this.props.showNotification && (
            <RequestFeedback
              className={
                this.props.amendmentRequestStatus ? "smallerFeedbackMargin" : ""
              }
              requestStatus={this.props.programmeRequestStatus}
              requestMade={this.props.programmeRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.programmeRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyProgramme
            requestStatus={this.props.programmeRequestStatus}
            mode={this.state.mode}
            pageTitle={this.pageTitle()}
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleShareProgrammeModalVisibility={
              this.toggleShareProgrammeModalVisibility
            }
            toggleEnableDisableProgrammeModalVisibility={
              this.toggleEnableDisableProgrammeModalVisibility
            }
            toggleConfirmSubmitAmendmentModalVisibility={
              this.toggleConfirmSubmitAmendmentModalVisibility
            }
            selectedProgrammeData={this.props.selectedProgrammeData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            shareProgrammeModalOpen={this.state.shareProgrammeModalOpen}
            confirmSubmitAmendmentModalOpen={
              this.state.confirmSubmitAmendmentModalOpen
            }
            enableDisableProgrammeModalOpen={
              this.state.enableDisableProgrammeModalOpen
            }
            initialProgrammeData={this.state.initialProgrammeData}
            formInvalid={this.state.formInvalid}
            allowNumericalInput={this.allowNumericalInput}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            getOrganisations={this.getOrganisations}
            getMentors={this.getMentors}
            getMentorsAll={this.getMentorsAll}
            area={this.props.selectedProgrammeData.area}
            userId={this.props.userId}
            roleId={this.props.roleId}
            chooseForm={this.chooseForm}
            hasChoseForm={this.state.hasChoseForm}
            headers={this.props.headers}
            history={this.props.history}
            resetForm={this.resetForm}
            filesHaveChanged={this.filesHaveChanged}
            hasFileChanges={this.state.hasFileChanges}
            deletePermissionStatus={this.state.permissionStatus}
            handleChangeModal={this.handleChangeModal}
            deletionReason={this.state.deletionReason}
            amendmentReason={this.state.amendmentReason}
            deleteSuccessful={this.props.deleteSuccessful}
            orgOfLoggedInUser={this.props.organisationOfLoggedInUser}
            resetDataOnModalClose={this.resetDataOnModalClose}
            isDisabled={this.isDisabled()}
            handleToggleAudit={this.handleToggleAudit}
            showAuditLog={this.state.showAuditLog}
            handleAmendmentMode={this.handleAmendmentMode}
            resetReasonsOnModalClose={this.resetReasonsOnModalClose}
            showSupportReferral={this.state.showSupportReferral}
            handleShowSupportReferral={this.handleShowSupportReferral}
          />
        </div>
      </div>
    );
  };
}

ProgrammeEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  updateSelectedProgramme: PropTypes.func.isRequired,
  updateProgramme: PropTypes.func.isRequired,
  getProgrammeById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const {
    auth,
    programmes,
    amendments,
    organisations,
    users,
    referrals,
    file
  } = state;
  const { roleId, organisationOfLoggedInUser, userId, username } = auth;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const {
    userListData,
    loadingPage: usersLoadingPage,
    selectedUserData,
    prisonMentor
  } = users;
  const { refStats, selectedReferralData } = referrals;
  const {
    loadingPage,
    selectedProgrammeData,
    programmeRequestStatus,
    programmeRequestMade,
    result,
    error,
    errorDetails,
    deleteSuccessful,
    showNotification
  } = programmes;
  const {
    requestId,
    permissionStatus,
    amendmentRequestStatus,
    amendmentRequestMade,
    result: amendmentResult,
    error: amendmentError,
    errorDetails: amendmentErrorDetails,
    loadingPage: amendmentLoadingPage
  } = amendments;
  const { localData, remoteData, loadingFileData, error: fileError } = file;
  return {
    loadingPage,
    selectedProgrammeData,
    programmeRequestStatus,
    programmeRequestMade,
    result,
    error,
    errorDetails,
    organisationListData,
    userListData,
    selectedUserData,
    roleId,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    organisationOfLoggedInUser,
    refStats,
    usersLoadingPage,
    orgsLoadingPage,
    prisonMentor,
    localData,
    remoteData,
    loadingFileData,
    fileError,
    amendmentRequestStatus,
    amendmentRequestMade,
    amendmentResult,
    amendmentError,
    amendmentErrorDetails,
    userId,
    username,
    selectedReferralData,
    amendmentLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProgramme: (
      id,
      data,
      headers,
      initialProgrammeData,
      showNotification = true
    ) => {
      dispatch(
        programmeActions.updateProgramme(
          id,
          data,
          headers,
          initialProgrammeData,
          showNotification
        )
      );
    },
    getProgrammeById: async (id, headers) => {
      await dispatch(programmeActions.getProgrammeById(id, headers));
    },
    updateSelectedProgramme: (key, value) => {
      dispatch(programmeActions.updateSelectedProgramme(key, value));
    },
    cancelSelectedProgrammeUpdate: data => {
      dispatch(programmeActions.cancelSelectedProgrammeUpdate(data));
    },
    clearProgrammeRequest: () => {
      dispatch(programmeActions.clearProgrammeRequest());
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    },
    clearSelectedUser: () => {
      dispatch(userActions.clearSelectedUser());
    },
    clearPrisonMentor: () => {
      dispatch(userActions.clearPrisonMentor());
    },
    getReferralById: (id, headers) => {
      dispatch(referralActions.getReferralById(id, headers));
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getOrganisations: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    requestDeletePermission: async (
      id,
      reason,
      entity,
      recordIdentifier,
      headers,
      body
    ) => {
      await dispatch(
        amendmentActions.requestDeletePermission(
          id,
          reason,
          entity,
          recordIdentifier,
          headers,
          body
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteProgramme: async (id, headers) => {
      await dispatch(programmeActions.deleteProgramme(id, headers));
    },
    setAmendmentStatusActioned: async (
      id,
      headers,
      showNotification,
      entityDetails
    ) => {
      await dispatch(
        amendmentActions.setAmendmentStatusActioned(
          id,
          headers,
          showNotification,
          entityDetails
        )
      );
    },
    getReferralStats: query => {
      dispatch(referralActions.getReferralStats(query));
    },
    getUserById: id => {
      dispatch(userActions.getUserById(id));
    },
    getPrisonMentorById: id => {
      dispatch(userActions.getPrisonMentorById(id));
    },
    clearSelectedReferral: () => {
      dispatch(referralActions.clearSelectedReferral());
    },
    commitChanges: (
      localData,
      remoteData,
      filePath,
      headers,
      entity,
      entityId,
      stage
    ) => {
      return dispatch(
        fileActions.commitChanges(
          localData,
          remoteData,
          filePath,
          headers,
          entity,
          entityId,
          stage
        )
      );
    },
    createAmendmentRequest: (
      id,
      oldValues,
      newValues,
      recordIdentifier,
      entity,
      reason,
      headers
    ) => {
      return dispatch(
        amendmentActions.createAmendmentRequest(
          id,
          oldValues,
          newValues,
          recordIdentifier,
          entity,
          reason,
          headers
        )
      );
    },
    clearAmendmentRequest: () => {
      dispatch(amendmentActions.clearAmendmentRequest());
    },
    lockUser: headers => {
      dispatch(userActions.lockUser(headers));
    },
    logout: hasExceeded => {
      dispatch(authActions.signOut(hasExceeded));
    },
    createAudit: async (details, headers) => {
      await dispatch(auditActions.createAudit(details, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgrammeEditContainer);
